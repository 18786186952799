import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchOrders:
      return { ...state, currentOrders: payload.orders };
    case ActionTypes.FetchOtherOrders:
      return { ...state, otherOrders: payload.otherOrders };
    case ActionTypes.FetchLastDayOrders:
      return { ...state, lastDayOrders: payload.lastDayOrders };
    case ActionTypes.FetchOrderHistory:
      return { ...state, orderHistory: payload.orderHistory };
    case ActionTypes.FetchStoreStats:
      return { ...state, storeOrderStats: payload.storeOrderStats };
    case ActionTypes.FetchStoreStatsSuburb:
      return { ...state, storeOrderStatsSuburb: payload.storeOrderStatsSuburb };
    case ActionTypes.FetchStoreStatsCategories:
      return {
        ...state,
        storeOrderStatsCategories: payload.storeOrderStatsCategories,
      };
    case ActionTypes.FetchReviewStats:
      return { ...state, reviewStats: payload.reviewStats };
    case ActionTypes.ResetStats:
      return {
        ...state,
        reviewStats: undefined,
        storeOrderStats: undefined,
        storeOrderStatsCategories: undefined,
        storeOrderStatsSuburb: undefined,
      };
    default:
      return state;
  }
};
