import React, { Component } from 'react';
import Spinner from '../../components/Spinner';
import { nextOrderConfig } from '../../configurations/storeSetup';

class SoldOutModal extends Component {
  constructor(props) {
    super(props);
    this.state = { isProcessing: false };
  }

  componentDidMount() {
    this.setState(
      {
        selectedSizes: this.props.item.selectedSizes,
      },
      () => {
        let allAvailable = [...this.state.selectedSizes].every(
          (selectedSize) => {
            return (
              selectedSize.isAvailable === true &&
              selectedSize.isAvailableGlutenFree === true
            );
          }
        );
        this.setState({
          isAllAvailable: allAvailable,
        });
      }
    );
  }

  handleChangeChk = (e, p, isGlutenFree) => {
    let newStatus = e.target.checked;
    e.stopPropagation();
    let newSelectedSizes = [...this.state.selectedSizes];
    newSelectedSizes.map((size) => {
      if (size._id === p._id) {
        if (isGlutenFree) size.isAvailableGlutenFree = newStatus;
        else size.isAvailable = newStatus;
      }
      return size;
    });
    this.setState({ isProcessing: true }, () => {
      let reqObj = {
        storeId: this.props.item.storeId,
        categoryId: this.props.item.categoryId,
        menuItemId: this.props.item._id,
        sizeId: p._id,
        isAvailable: isGlutenFree ? p.isAvailable : newStatus,
        isAvailableGlutenFree: isGlutenFree
          ? newStatus
          : p.isAvailableGlutenFree,
        isAllSizes: false,
      };
      fetch(`${nextOrderConfig.cloudFunctionUrl}/menuItemAvailability`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...reqObj,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            let isAllSelected = newSelectedSizes.every((selectedSize) => {
              return (
                selectedSize.isAvailable === true &&
                selectedSize.isAvailableGlutenFree === true
              );
            });
            this.setState({
              isProcessing: false,
              selectedSizes: newSelectedSizes,
              isAllAvailable: isAllSelected,
            });
          }
        });
    });
  };

  handleAll = (e) => {
    let newStatus = e.target.checked;
    e.stopPropagation();
    this.setState(
      {
        isAllAvailable: newStatus,
        isProcessing: true,
      },
      () => {
        // All the Sizes should be marked as Available
        let newSelectedSizes = [...this.state.selectedSizes];
        let reqObj = {
          storeId: this.props.item.storeId,
          categoryId: this.props.item.categoryId,
          menuItemId: this.props.item._id,
          isAvailable: newStatus,
          isAvailableGlutenFree: newStatus,
          isAllSizes: true,
        };
        fetch(`${nextOrderConfig.cloudFunctionUrl}/menuItemAvailability`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...reqObj,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.success) {
              newSelectedSizes.map((size) => {
                size.isAvailable = newStatus;
                size.isAvailableGlutenFree = newStatus;
                return size;
              });
              this.setState({
                isProcessing: false,
                selectedSizes: newSelectedSizes,
              });
            }
          });
      }
    );
  };

  render() {
    return (
      <div className='col-sm-12 col-md-12'>
        <form
          style={{ borderRadius: '0px' }}
          className={
            this.state.isProcessing
              ? 'form-horizontal group-border-dashed'
              : 'form-horizontal group-border-dashed'
          }
        >
          <div
            className={this.state.isProcessing ? 'form-group' : 'form-group'}
          >
            <div className='col-sm-12 col-md-12'>
              {this.state &&
                this.state.selectedSizes &&
                this.state.selectedSizes.length > 1 && (
                  <React.Fragment>
                    <div
                      key={`check${999}`}
                      className='be-checkbox be-checkbox-color inline'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '0px',
                      }}
                    >
                      <input
                        id={`check${999}`}
                        type='checkbox'
                        onChange={(e) => this.handleAll(e)}
                        checked={this.state.isAllAvailable}
                      />
                      <label htmlFor={`check${999}`}>ALL</label>
                    </div>
                  </React.Fragment>
                )}
              {this.state &&
                this.state.selectedSizes &&
                this.state.selectedSizes.map((size, i) => (
                  <div
                    key={`${i}-noGluteenFree`}
                    className='be-checkbox be-checkbox-color inline'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '0px',
                    }}
                  >
                    <input
                      id={`check${i}`}
                      type='checkbox'
                      onChange={(e) => this.handleChangeChk(e, size, false)}
                      checked={size.isAvailable}
                    />
                    <label htmlFor={`check${i}`}>{size.name}</label>
                  </div>
                ))}
              {this.state &&
                this.state.selectedSizes &&
                this.state.selectedSizes.filter((selectedSize) => {
                  return selectedSize.isGlutenFree === true;
                }).length > 0 && (
                  <div
                    className='be-checkbox be-checkbox-color inline'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '0px',
                      fontWeight: 'bold',
                    }}
                  >
                    (Gluten Free)
                  </div>
                )}
              {this.state &&
                this.state.selectedSizes &&
                this.state.selectedSizes.map(
                  (size, i) =>
                    size.isGlutenFree && (
                      <div
                        key={`${i}-gluteenFree`}
                        className='be-checkbox be-checkbox-color inline'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '0px',
                        }}
                      >
                        <input
                          id={`check${i}g`}
                          type='checkbox'
                          onChange={(e) => this.handleChangeChk(e, size, true)}
                          checked={size.isAvailableGlutenFree}
                        />
                        <label htmlFor={`check${i}g`}>{size.name}</label>
                      </div>
                    )
                )}
            </div>
          </div>
        </form>
        {this.state.isProcessing && (
          <div className='spinnerParent'>
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

SoldOutModal.propTypes = {};

export default SoldOutModal;
