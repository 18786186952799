import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getReservations(storeId, dateToday) {
  return (dispatch) => {
    let nextRef = firestore
      .collection('Reservations')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateToday)
      .orderBy('dateTimestamp', 'desc');
    nextRef.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(reservationsFetched(dataArray));
    });
  };
}

export function getOtherDeliveries(storeId, dateToday) {
  return (dispatch) => {
    let nextRef = firestore
      .collection('OtherDeliveries')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateToday)
      .orderBy('dateTimestamp', 'desc');
    nextRef.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(otherDeliveriesFetched(dataArray));
    });
  };
}

export function getOrders(storeId, dateToday) {
  return (dispatch) => {
    let nextRef = firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateToday)
      .orderBy('dateTimestamp', 'desc');
    nextRef.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(ordersFetched(dataArray));
    });
  };
}

function reservationsFetched(reservations) {
  return {
    type: ActionTypes.FetchReservations,
    reservations,
  };
}

function ordersFetched(realtimeOrders) {
  return {
    type: ActionTypes.FetchOrders,
    realtimeOrders,
  };
}

function otherDeliveriesFetched(otherDeliveries) {
  return {
    type: ActionTypes.FetchOtherDeliveries,
    otherDeliveries,
  };
}
