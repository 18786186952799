import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.DateRangeChanged:
      return { ...state, start: payload.start, end: payload.end };
    case ActionTypes.FetchStoreOrderStats:
      return { ...state, storeOrderStats: payload.storeOrderStats };
    case ActionTypes.FetchStoreReviewStats:
      return { ...state, storeReviewStats: payload.storeReviewStats };
    case ActionTypes.FetchStore:
      return { ...state, store: payload.store };
    case ActionTypes.FetchRefundReasons:
      return { ...state, reasons: payload.reasons };
    default:
      return state;
  }
};
