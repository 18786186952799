import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LeafletStore from '../../components/LeafletStore';
import LeafletDriver from '../../components/LeafletDriver';
import LeafletDeliveryAddress from '../../components/LeafletDeliveryAddress';
import '../../assets/css/leaflet.css';
import '../../assets/js/leafletGoogle';
import '../../assets/js/leafletMarkerSlideTo';
import 'leaflet-rotatedmarker';

class DriverTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return this.props.order &&
      (this.props.order.orderType === '1' ||
        this.props.order.orderStatus === '11') &&
      this.props.store &&
      this.props.store.addressLocation &&
      this.props.storeId ? (
      <LeafletStore
        store={this.props.store.addressLocation}
        order={this.props.order}
        storeId={this.props.storeId}
      />
    ) : this.props.order &&
      (this.props.order.orderType === '2' ||
        this.props.order.orderType === '3') &&
      (this.props.order.orderStatus === '0' ||
        this.props.order.orderStatus === '2' ||
        this.props.order.orderStatus === '4' ||
        this.props.order.orderStatus === '11' ||
        this.props.order.orderStatus === '25') &&
      this.props.order.addressLocation ? (
      <LeafletDeliveryAddress
        delivery={this.props.order.addressLocation}
        order={this.props.order}
        newLat={this.props.newLat}
        newLng={this.props.newLng}
      />
    ) : this.props.order &&
      (this.props.order.orderType === '2' ||
        this.props.order.orderType === '3') &&
      (this.props.order.orderStatus === '3' ||
        this.props.order.orderStatus === '13' ||
        this.props.order.orderStatus === '12') &&
      this.props.order.addressLocation &&
      this.props.driver &&
      this.props.driver.currentLocation &&
      this.props.driver.previousLocation ? (
      <LeafletDriver
        delivery={this.props.order.addressLocation}
        driver={this.props.driver}
      />
    ) : (
      <div />
    );
  }
}

DriverTracker.propTypes = {
  order: PropTypes.any,
  DriverTracker: PropTypes.any,
};

export default DriverTracker;
