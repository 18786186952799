import React, { Component } from 'react';
import { firestore } from '../../firebase';
import DragSortableList from 'react-drag-sortable';
import toaster from 'toasted-notes';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import { Modal } from 'react-bootstrap';

class SpecialsOrderDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialsManualOrder: [],
      processing: false,
    };
  }

  componentDidMount() {
    console.log('props', this.props);
    let sortedAllSpecials = orderBy(
      this.props.allSpecials,
      ['displayOrder'],
      ['asc']
    );
    this.setState({
      specialsManualOrder: sortedAllSpecials,
    });
  }

  onSort = (sortedList) => {
    clearTimeout(this.timeOutId);
    this.timeOutId = setTimeout(this.updateList(sortedList), 1000);
  };

  updateList = (sortedList) => {
    console.log(sortedList);
    let items = [];
    sortedList.map((item) => {
      items = this.state.specialsManualOrder.map((m) => {
        if (m._id === item.item._id) {
          m.displayOrder = item.rank;
        }
        return m;
      });
      return item;
    });
    let specials = orderBy(items, ['displayOrder'], ['asc']);
    this.setState({ specialsManualOrder: specials }, () => {});
  };

  updateSpecial = (item) => {
    return new Promise((resolve, reject) => {
      let docRef = firestore.collection('Specials').doc(item._id);
      firestore
        .runTransaction((t) => {
          return t.get(docRef).then((doc) => {
            t.update(docRef, { displayOrder: item.displayOrder });
          });
        })
        .then(() => {
          resolve(`${item.name}'s stats updated`);
        })
        .catch((error) => {
          console.log(error);
          reject(`Failed updating stats for ${item.name}`);
        });
    });
  };

  saveSpecialOrder = () => {
    this.setState(
      {
        processing: true,
      },
      () => {
        let specials = this.state.specialsManualOrder;
        let requests = specials.map((item) => {
          return this.updateSpecial(item);
        });
        Promise.all(requests)
          .then((values) => {
            console.log(values);
            this.setState({
              processing: false,
            });
            toaster.notify(`Specials display order is updated!`, {
              duration: 2000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              processing: false,
            });
          });
      }
    );
  };

  render() {
    var list = [];
    if (this.state.specialsManualOrder) {
      this.state.specialsManualOrder.map((item) => {
        list.push({
          content: <div className='dd-handle'>{item.name}</div>,
          item,
          classes: ['dd-item'],
        });
        return item;
      });
    }
    return (
      <Modal
        className='modal fade specials-order'
        backdrop='static'
        keyboard={false}
        show={this.props.openSpecialsOrderModal}
        onHide={this.props.closeSpecialsOrderModal}
        bsSize='medium'
        aria-labelledby='contained-modal-title'
      >
        <div className='modal-header'>
          <button
            onClick={this.props.closeSpecialsOrderModal}
            type='button'
            data-dismiss='modal'
            aria-hidden='true'
            className='close'
          >
            <span className='mdi mdi-close' />
          </button>
          <h3 className='modal-title'>Manage Display Order For Specials</h3>
        </div>
        <div className='model-body'>
          <div className='col-md-12 com-sm-12 sectionContiner'>
            <div className={'panel panel-default blueBorder'}>
              <div className='panel-heading panel-heading-divider  ptitle'>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>Specials By Display Order</span>
                  <button
                    type='submit'
                    className='btn btn-primary btn-space'
                    onClick={this.saveSpecialOrder}
                    disabled={this.state.processing}
                  >
                    Save
                  </button>
                </div>
                <span className='panel-subtitle psubtitle'>
                  {`Drag & drop the order of Specials as they will appear on the website.`}
                </span>
              </div>
              <p className='divider1' />
              <div className='panel-body'>
                <div id='list1' className='dd'>
                  <DragSortableList
                    classNmae='dd-list'
                    items={list}
                    onSort={this.onSort}
                    moveTransitionDuration={0.3}
                    type='vertical'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

SpecialsOrderDisplay.propTypes = {};

export default SpecialsOrderDisplay;
