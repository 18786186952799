import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { convertToMomentDate } from '../../helpers/timeHelper';
import OrderModal from '../../components/OrderModal';
import RefundModal from '../../components/RefundModal';
import InvoiceModal from '../../components/InvoiceModal';
import Spinner from '../../components/Spinner';

class TopCustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisplayOrderView: false,
      isDisplayOrder: false,
      selectedOrder: {},
    };
  }

  openOrderView = (userId) => {
    this.setState({ ...this.state, isDisplayOrderView: true }, () => {
      this.props.fetchUserOrdersHandler(userId);
    });
  };

  closeOrderView = () => {
    this.setState({ ...this.state, isDisplayOrderView: false, myOrders: [] });
  };

  openOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOrder: true });
  };

  closeOrderModal = () => {
    this.setState({ isDisplayOrder: false, selectedOrder: {} });
  };

  closeRefundPopup = () => {
    this.setState({
      openRefundPopup: false,
    });
  };

  refundOrder = (order) => {
    this.setState({
      openRefundPopup: true,
      refundOrder: order,
    });
  };

  openInvoiceModal = (order) => {
    this.setState({
      renderInvoiceModal: true,
      invoiceOrder: order,
    });
  };

  closeInvoiceModal = () => {
    this.setState({
      renderInvoiceModal: false,
      invoiceOrder: {},
    });
  };

  checkOrderDateDiff = (dateTimestamp) => {
    let now = new Date();
    let currentTimestamp = now.getTime();
    let timestampDiff = currentTimestamp - dateTimestamp;
    let daysDifference = Math.floor(timestampDiff / 1000 / 60 / 60 / 24);
    if (daysDifference > 7) return false;
    return true;
  };

  renderRefundStatus = (order) => {
    if (
      (order.orderStatus === '2' ||
        order.orderStatus === '1' ||
        order.orderStatus === '25' ||
        order.orderStatus === '3' ||
        order.orderStatus === '12' ||
        order.orderStatus === '13' ||
        order.orderStatus === '4') &&
      order.paymentType === '2' &&
      order.refundStatus !== 0 &&
      order.refundStatus !== 1 &&
      order.refundStatus !== 2 &&
      order.refundStatus !== 3 &&
      this.checkOrderDateDiff(order.dateTimestamp)
    ) {
      return (
        <button
          onClick={() => this.refundOrder(order)}
          className='btn btn-space btn-success'
        >
          Refund
        </button>
      );
    } else if (order.refundStatus === 0) {
      return (
        <React.Fragment>
          <span>Refund Initiated</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 1) {
      return (
        <React.Fragment>
          <span>Refunded</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 2) {
      return (
        <React.Fragment>
          <span>Refund Failed</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 3) {
      return (
        <React.Fragment>
          <span>Refund Rejected</span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span></span>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <div className='col-md-6 col-sm-12 col-xs-12'>
        <div className='panel panel-default panel-table'>
          <div className='panel-heading'>Top Customers</div>
          <div className='panel-body table-responsive'>
            <div
              id='table1_wrapper'
              className='dataTables_wrapper form-inline dt-bootstrap no-footer'
            >
              <div className='row be-datatable-body'>
                <div className='col-md-12 col-xs-12 col-sm-12 table-responsive noSwipe'>
                  <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date Joined</th>
                        <th>Total Spent</th>
                        <th>Total Orders</th>
                        <th className='actions' />
                      </tr>
                    </thead>
                    {!this.props.topCustomers ? (
                      <tbody>
                        <tr style={{ height: '100px' }}>
                          <div
                            style={{
                              alignSelf: 'center',
                              left: '0px',
                              right: '0px',
                              position: 'absolute',
                              width: 'auto',
                              height: '100px',
                            }}
                            className='be-loading be-loading-active'
                          >
                            <Spinner />
                          </div>
                        </tr>
                      </tbody>
                    ) : this.props.topCustomers.length > 0 ? (
                      <tbody className='no-border-x'>
                        {this.props.topCustomers.map((customer, index) => (
                          <tr key={index}>
                            <td>{`${customer.firstName} ${customer.lastName}`}</td>
                            <td>
                              {convertToMomentDate(
                                customer.date,
                                'YYYY-MM-DD HH:mm',
                                this.props.timeZone
                              ).format('MMM DD, YYYY')}
                            </td>
                            <td>
                              {customer.totalRevenue
                                ? `${this.props.currency}${Number(
                                    customer.totalRevenue
                                  ).toFixed(2)}`
                                : 'N/A'}
                            </td>
                            <td>
                              {customer.totalOrders
                                ? customer.totalOrders
                                : 'N/A'}
                            </td>
                            <td className='actions'>
                              {customer.totalOrders && (
                                <button
                                  onClick={() =>
                                    this.openOrderView(customer._id)
                                  }
                                  className='btn btn-space btn-default'
                                >
                                  View Orders
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>
                            <p> No records to display</p>
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.isDisplayOrderView && (
          <Modal
            aria-labelledby='modal-label'
            className='modal fade'
            show={this.state.isDisplayOrderView}
            onHide={this.closeOrderView}
            bsSize='md'
          >
            <div
              className='modal-content'
              style={{ maxHeight: '800px', overflowY: 'scroll' }}
            >
              <div className='panel panel-default panel-table'>
                <div className='panel-heading'>
                  Orders
                  <button
                    onClick={this.closeOrderView}
                    type='button'
                    data-dismiss='modal'
                    aria-hidden='true'
                    className='close md-close'
                  >
                    <span className='mdi mdi-close' />
                  </button>
                </div>
                <div className='panel-body'>
                  <table className='table table-condensed table-striped'>
                    <thead>
                      <tr>
                        <th>Order</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th className='number'>Order Amount</th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.userOrders ? (
                        this.props.userOrders.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item.dateTimestamp
                                ? convertToMomentDate(
                                    item.date,
                                    'YYYY-MM-DD HH:mm',
                                    this.props.timeZone
                                  ).format('MMM DD, YYYY')
                                : 'N/A'}
                            </td>
                            <td>
                              {item.orderType
                                ? item.orderType === '1'
                                  ? 'Pickup'
                                  : 'Delivery'
                                : 'N/A'}
                            </td>
                            <td className='number'>
                              {item.payableAmount
                                ? `${this.props.currency}${item.payableAmount}`
                                : 'N/A'}
                            </td>
                            <td>
                              <button
                                style={{ marginRight: '-5px', float: 'right' }}
                                onClick={() => this.openOrderModal(item)}
                                className='btn btn-space btn-primary'
                              >
                                View
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() => this.openInvoiceModal(item)}
                                className='btn btn-space btn-primary'
                              >
                                Send Invoice
                              </button>
                              {this.renderRefundStatus(item)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className=' be-loading be-loading-active'>
                          <div className='be-spinner'>
                            <svg
                              width='40px'
                              height='40px'
                              viewBox='0 0 66 66'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                fill='none'
                                strokeWidth='4'
                                strokeLinecap='round'
                                cx='33'
                                cy='33'
                                r='30'
                                className='circle'
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {this.state.isDisplayOrder && (
          <OrderModal
            isDisplayOrder={this.state.isDisplayOrder}
            closeOrderModal={this.closeOrderModal}
            selectedOrder={this.state.selectedOrder}
            storeId={this.props.storeId}
            timeZone={this.props.timeZone}
            currency={this.props.currency}
          />
        )}
        {this.state.openRefundPopup && (
          <RefundModal
            openRefundPopup={this.state.openRefundPopup}
            closeRefundPopup={this.closeRefundPopup}
            order={this.state.refundOrder}
            timeZone={this.props.timeZone}
            accessToken={this.props.accessToken}
            currency={this.props.currency}
            reasons={this.props.reasons}
          />
        )}
        {this.state.renderInvoiceModal && (
          <InvoiceModal
            renderInvoiceModal={this.state.renderInvoiceModal}
            closeInvoiceModal={this.closeInvoiceModal}
            order={this.state.invoiceOrder}
          />
        )}
        {this.state.renderInvoiceModal && (
          <InvoiceModal
            renderInvoiceModal={this.state.renderInvoiceModal}
            closeInvoiceModal={this.closeInvoiceModal}
            order={this.state.invoiceOrder}
          />
        )}
      </div>
    );
  }
}

TopCustomerList.propTypes = {
  fetchUserOrdersHandler: PropTypes.any,
  openOrderModalHelper: PropTypes.any,
};

export default TopCustomerList;
