const actionTypes = {
  FetchUserOrders: 'FETCH+USER_ORDERS',
  FetchTopCustomers: 'FETCH_TOP_CUSTOMER',
  FetchCustomers: 'FETCH_CUSTOMERS',
  CustomerCountFetched: 'CUSTOMER_COUNT_FETCHED',
  CustomerCountPickupFetched: 'CUSTOMER_COUNT_FETCHED_PICKUP',
  CustomerCountDeliveryFetched: 'CUSTOMER_COUNT_FETCHED_DELIVERY',
  FetchCustomerRetention: 'FETCH_CUSTOMER_RETENTION',
  FetchSystemStartDate: 'FETCH_SYSTEM_START_DATE',
};

export default actionTypes;
