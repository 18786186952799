import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import ShiftList from '../../components/ShiftList';
import HighestRevenueShifts from '../../components/HigestRevenueShifts';
import {
  getShifts,
  getShiftsCount,
  addShift,
  deleteShift,
  getHighRevenueShifts,
} from './actions';

import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Shifts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/settings/shifts'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getShiftsHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getShiftsCountHandler(doc.data().storeId);
                    this.props.getHighRevenueShiftsHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(props) {
    let shifts = props.shift.shifts ? props.shift.shifts : [];
    shifts.map((s) => {
      s.dayNumber = Number(s.day);
      return s;
    });
    shifts = orderBy(shifts, 'dayNumber');
    this.setState({
      shifts,
      totalCount: props.shift.totalCount,
      hRevenuehifts: props.shift.hRevenuehifts,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  addShiftHandler = (shift) => {
    shift.storeId = this.state.storeId;
    shift.restaurantId = this.state.restaurantId;
    shift.totalOrders = 0;
    shift.totalRevenue = 0;
    shift.averageRevenue = 0;
    this.props.addShiftHandler(shift);
  };

  deleteShiftHandler = (shift) => {
    this.props.deleteShiftHandler(shift);
  };

  handlePageChange = (e) => {
    this.setState(
      {
        shifts: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getShiftsHandler(this.state.storeId, this.state.pagination);
      }
    );
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <ShiftList
            shifts={this.state.shifts}
            pagination={this.state.pagination}
            totalCount={this.state.totalCount}
            handlePageChange={this.handlePageChange}
            deleteShiftHandler={this.deleteShiftHandler}
            addShiftHandler={this.addShiftHandler}
            timeZone={this.state.timeZone}
            currency={currency}
          />
          <div className='row'>
            <div className=' '>
              <HighestRevenueShifts
                shifts={this.state.hRevenuehifts}
                timeZone={this.state.timeZone}
                currency={currency}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    shift: state.shiftsReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getShiftsHandler: (storeId, pagination) =>
      dispatch(getShifts(storeId, pagination)),
    addShiftHandler: (shift) => dispatch(addShift(shift)),
    deleteShiftHandler: (shift) => dispatch(deleteShift(shift)),
    getShiftsCountHandler: (storeId) => dispatch(getShiftsCount(storeId)),
    getHighRevenueShiftsHandler: (storeId) =>
      dispatch(getHighRevenueShifts(storeId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Shifts);
