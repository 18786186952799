import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchOtherOrderHistory:
      return { ...state, otherOrderHistory: payload.otherOrderHistory };
    case ActionTypes.FetchUser:
      return { ...state, user: payload.user };
    case ActionTypes.FetchDriver:
      return { ...state, driver: payload.driver };
    case ActionTypes.FetchDeliveryAddresses:
      return { ...state, deliveryAddresses: payload.deliveryAddresses };
    default:
      return state;
  }
};
