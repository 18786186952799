import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getOrdersCount(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('orderType', '==', '2');
    ref.onSnapshot((querySnapshot) => {
      dispatch(fetchedOrdersCount(querySnapshot.docs.length));
    });
  };
}

export function getOrders(storeId, pagination) {
  return (dispatch) => {
    dispatch(ordersFetched(undefined));
    let currentRef = firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('orderType', '==', '2')
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('Orders')
          .where('storeId', '==', storeId)
          .where('orderType', '==', '2')
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(ordersFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('Orders')
          .where('storeId', '==', storeId)
          .where('orderType', '==', '2')
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(ordersFetched(dataArray));
        });
      }
    });
  };
}

export function getMenuItemsCount(storeId) {
  return (dispatch) => {
    let ref = firestore.collection('MenuItems').where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(menuItemsCountFetched(querySnapshot.docs.length));
    });
  };
}

export function getMenuItems(storeId, pagination, sortObj) {
  return (dispatch) => {
    dispatch(menuItemsFetched(undefined));
    let currentRef = firestore
      .collection('MenuItems')
      .where('storeId', '==', storeId)
      .orderBy(sortObj.name, sortObj.dir);
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('MenuItems')
          .where('storeId', '==', storeId)
          .orderBy(sortObj.name, sortObj.dir)
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(menuItemsFetched(dataArray, pagination));
        });
      } else {
        let nextRef = firestore
          .collection('MenuItems')
          .where('storeId', '==', storeId)
          .orderBy(sortObj.name, sortObj.dir)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(menuItemsFetched(dataArray, pagination));
        });
      }
    });
  };
}

function fetchedOrdersCount(totalCount) {
  return {
    type: ActionTypes.FetchOrderCount,
    totalCount,
  };
}

function ordersFetched(deliveryOrders) {
  return {
    type: ActionTypes.FetchOrders,
    deliveryOrders,
  };
}

function menuItemsFetched(menuItemsD) {
  return {
    type: ActionTypes.FetchMenuItems,
    menuItemsD,
  };
}

function menuItemsCountFetched(totalCountD) {
  return {
    type: ActionTypes.MenuItemsCountFetched,
    totalCountD,
  };
}
