import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import toaster from 'toasted-notes';
import Layout from '../../containers/Layout';
import TagsInput from 'react-tagsinput';
import { getStoreSetup } from './actions';
import moment from 'moment-timezone';
import {
  getCustomerCount,
  getCustomerCountDelivery,
  getCustomerCountPickup,
} from '../../containers/Customers/actions';
import { getStoreBillingCode } from '../../containers/Subscription/actions';
import { getAllSuburbs } from '../../containers/DeliveryAreas/actions';
import { nextOrderConfig } from '../../configurations/storeSetup';
import { Modal } from 'react-bootstrap';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class StoreSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      selectedCustomerNumbers: [],
      disableSendButton: true,
      selectedRadio: '1',
      renderSMSModal: false,
      isAllSuburb: true,
      inActiveSince: '1month',
      inActiveCustomers: 0,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/marketing/sms-marketing'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    productSetup: {
                      ...this.state.productSetup,
                      storeId: doc.data().storeId,
                      restaurantId: doc.data().storeId,
                    },
                    orderSetup: {
                      ...this.state.orderSetup,
                      storeId: doc.data().storeId,
                      restaurantId: doc.data().storeId,
                    },
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getStoreSetupHandler(
                      this.state,
                      doc.data().storeId
                    );
                    this.props.getCustomerCountHandler(this.state.storeId);
                    this.props.getCustomerCountDelivery(this.state.storeId);
                    this.props.getCustomerCountPickup(this.state.storeId);
                    this.props.getStoreBillingCodeHandler(this.state.storeId);
                    this.props.getAllSuburbsHandler(this.state.storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(props) {
    let allSuburbs = [];
    if (props.suburb && props.suburb.allSuburbs) {
      props.suburb.allSuburbs.forEach((suburb) => {
        if (Number(suburb.totalCustomers) > 0) {
          let obj = {
            _id: suburb._id,
            name: suburb.name,
            isActive: true,
            totalCustomers: suburb.totalCustomers
              ? Number(suburb.totalCustomers)
              : 0,
          };
          allSuburbs.push(obj);
        }
      });
    }
    this.setState({
      productSetup: props.storeSetting.productSetup,
      orderSetup: props.storeSetting.orderSetup,
      layout: props.layout,
      limitCustomer:
        props.customer &&
        props.customer.totalCount &&
        props.customer.totalCount < 100
          ? props.customer.totalCount
          : 100,
      allSuburbs,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  messageChange = (e) => {
    this.setState(
      {
        message: e.target.value,
      },
      () => {
        this.validationForSendButton();
      }
    );
  };

  radioButtonChanged = (e) => {
    this.setState(
      {
        selectedRadio: e.target.value,
      },
      () => {
        this.validationForSendButton();
        if (this.state.selectedRadio === '4') {
          this.setState({
            selectedCustomerNumbers: [],
          });
        }
        if (this.state.selectedRadio === '5') {
          this.calculateInActiveCustomers(this.state.inActiveSince);
        }
      }
    );
  };

  getTimestampForDateString = (dateString, timezone) => {
    let dateObj = moment.tz(dateString, timezone).toDate();
    return dateObj.getTime();
  };

  calculateInActiveCustomers = (interval) => {
    let timestamp;
    if (interval === '1month') {
      let momentDate = moment().add(-1, 'month');
      timestamp = this.getTimestampForDateString(
        momentDate.format('YYYY-MM-DD'),
        this.state.timeZone
      );
    } else if (interval === '2month') {
      let momentDate = moment().add(-2, 'months');
      timestamp = this.getTimestampForDateString(
        momentDate.format('YYYY-MM-DD'),
        this.state.timeZone
      );
    } else if (interval === '6month') {
      let momentDate = moment().add(-6, 'months');
      timestamp = this.getTimestampForDateString(
        momentDate.format('YYYY-MM-DD'),
        this.state.timeZone
      );
    } else if (interval === '1year') {
      let momentDate = moment().add(-1, 'year');
      timestamp = this.getTimestampForDateString(
        momentDate.format('YYYY-MM-DD'),
        this.state.timeZone
      );
    }

    firestore
      .collection('Users')
      .where('storeId', '==', this.state.storeId)
      .where('lastOrderDateTimestamp', '<', timestamp)
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.docs.length);
        this.setState({
          inActiveCustomers: querySnapshot.docs.length,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onSelectedCustomersNumbersChange = (e) => {
    if (e.length > this.state.selectedCustomerNumbers.length) {
      const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
      let tags = [...this.state.selectedCustomerNumbers];
      if (e[e.length - 1].length >= 7) {
        if (mobileRegex.test(e[e.length - 1])) {
          if (/^[0-9 +]*$/gm.test(e[e.length - 1])) {
            tags.push(e[e.length - 1]);
          }
        }
        this.setState({ selectedCustomerNumbers: tags }, () => {
          this.validationForSendButton();
        });
      }
    } else {
      this.setState({ selectedCustomerNumbers: e }, () => {
        this.validationForSendButton();
      });
    }
  };

  validationForSendButton = () => {
    if (
      this.state.selectedRadio === '1' &&
      this.state.message !== '' &&
      this.state.limitCustomer
    ) {
      this.setState({
        disableSendButton: false,
      });
    } else if (this.state.selectedRadio === '2' && this.state.message !== '') {
      this.setState({
        disableSendButton: false,
      });
    } else if (
      this.state.selectedRadio === '3' &&
      this.state.message !== '' &&
      this.state.allSuburbs &&
      this.state.allSuburbs.filter((f) => f.isActive).length > 0
    ) {
      this.setState({
        disableSendButton: false,
      });
    } else if (
      this.state.message !== '' &&
      this.state.selectedRadio === '4' &&
      this.state.selectedCustomerNumbers.length !== 0
    ) {
      this.setState({
        disableSendButton: false,
      });
    } else if (
      this.state.message !== '' &&
      this.state.selectedRadio === '5' &&
      this.state.inActiveSince
    ) {
      this.setState({
        disableSendButton: false,
      });
    } else {
      this.setState({
        disableSendButton: true,
      });
    }
  };

  sendMessages = () => {
    this.setState(
      {
        sendingMessage: true,
        disableSendButton: true,
      },
      () => {
        // API call
        let reqObj = {};
        reqObj.storeId = this.props.storeSetting.orderSetup.storeId;
        reqObj.restaurantId = this.props.storeSetting.orderSetup.restaurantId;
        reqObj.message = this.state.message;
        reqObj.filterType = this.state.selectedRadio;
        reqObj.timeZone = this.state.timeZone;
        reqObj.smsUnit = Math.floor(this.state.message.length / 160) + 1;

        if (this.state.selectedRadio === '4') {
          reqObj.contactNumbers = this.state.selectedCustomerNumbers;
        } else if (this.state.selectedRadio === '1') {
          reqObj.limitCustomer = this.state.limitCustomer;
        } else if (this.state.selectedRadio === '3') {
          reqObj.isAllSuburb = this.state.isAllSuburb;
          reqObj.suburbs = this.state.allSuburbs
            .filter((f) => f.isActive)
            .map((m) => m._id);
        } else if (this.state.selectedRadio === '5') {
          reqObj.inActiveSince = this.state.inActiveSince;
        }
        fetch(`${nextOrderConfig.cloudFunctionUrl}/sendBulkSMS`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...reqObj,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState(
              {
                sendingMessage: false,
                disableSendButton: false,
              },
              () => {
                if (result.success) {
                  toaster.notify('Messages Sent', {
                    duration: 2000,
                  });

                  setTimeout(() => {
                    this.closeSMSModal();
                    this.setState({
                      message: '',
                      selectedCustomerNumbers: [],
                      disableSendButton: true,
                      selectedRadio: '1',
                      renderSMSModal: false,
                    });
                  }, 2000);
                } else {
                  toaster.notify(
                    'Message Sending Failed. Please contact 1300317597',
                    {
                      duration: 20000,
                    }
                  );
                }
              }
            );
          })
          .catch((error) => {
            console.log('error', error);
            this.setState(
              {
                sendingMessage: false,
                disableSendButton: false,
              },
              () => {
                toaster.notify('Message Sending Failed', {
                  duration: 2000,
                });
              }
            );
          });
      }
    );
  };

  openSendMessageModal = () => {
    this.setState({
      renderSMSModal: true,
    });
  };

  closeSMSModal = () => {
    this.setState({
      renderSMSModal: false,
    });
  };

  getNumberOfCustomers = () => {
    console.log(this.state.selectedRadio);
    if (this.props.customer) {
      if (
        this.props.customer.totalCount &&
        this.state.selectedRadio === '1' &&
        this.state.limitCustomer
      )
        return this.state.limitCustomer;
      else if (
        this.props.customer.totalCountPickupOnly &&
        this.state.selectedRadio === '2'
      )
        return this.props.customer.totalCountPickupOnly;
      else if (
        this.props.customer.totalCountDeliveryOnly &&
        this.state.selectedRadio === '3'
      ) {
        let total = 0;
        if (this.state.isAllSuburb)
          return this.props.customer.totalCountDeliveryOnly;
        else {
          this.state.allSuburbs.forEach((m) => {
            if (m.isActive) {
              if (m.totalCustomers) total = total + m.totalCustomers;
            }
          });
          return total;
        }
      } else if (
        this.state.contactNumbers &&
        this.state.contactNumbers.length > 0 &&
        this.state.selectedRadio === '4'
      )
        return this.state.contactNumbers.length;
      else if (this.state.selectedRadio === '5') {
        return this.state.inActiveCustomers;
      } else return 0;
    } else {
      return 0;
    }
  };

  handleLimitCustomer = (e) => {
    let newNumber = e.target.value;
    newNumber = Number(newNumber);
    let totalCount = 0;
    if (this.props.customer && this.props.customer.totalCount)
      totalCount = this.props.customer.totalCount;
    if (newNumber > totalCount) newNumber = totalCount;
    this.setState(
      {
        limitCustomer: newNumber,
      },
      () => {
        this.validationForSendButton();
      }
    );
  };

  handleInActiveChange(_e, inActiveSince) {
    this.setState(
      {
        inActiveSince,
      },
      () => {
        this.calculateInActiveCustomers(this.state.inActiveSince);
        this.validationForSendButton();
      }
    );
  }

  handleSuburbChange = (e, suburbId) => {
    let allSuburbs = this.state.allSuburbs;
    if (suburbId == undefined) {
      allSuburbs.map((s) => {
        s.isActive = e.target.checked;
        return s;
      });
    } else {
      allSuburbs.map((s) => {
        if (s._id === suburbId) s.isActive = e.target.checked;
        return s;
      });
    }
    let isAllSuburb = !allSuburbs.some((s) => {
      return s.isActive === false;
    });
    this.setState(
      {
        allSuburbs,
        isAllSuburb,
      },
      () => {
        this.validationForSendButton();
      }
    );
  };

  render() {
    let totalCount = 0;
    if (this.props.customer && this.props.customer.totalCount)
      totalCount = this.props.customer.totalCount;
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='panel panel-default panel-border-color panel-border-color-primary form-horizontal'>
                <div
                  className='panel-heading panel-heading-divider'
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>SMS Marketing</div>

                  {this.props &&
                    this.props.layout &&
                    this.props.layout.store &&
                    this.props.layout.store.currency &&
                    this.props.subscription &&
                    this.props.subscription.billingCode &&
                    this.props.subscription.billingCode
                      .smsNotificationCommission && (
                      <div>
                        SMS Charges: {this.props.layout.store.currency}
                        {
                          this.props.subscription.billingCode
                            .smsNotificationCommission
                        }
                        / SMS
                      </div>
                    )}
                </div>
                <div className='divider1' />
                <div className='panel-body'>
                  <div className='form-group'>
                    <label className='col-sm-3 col-md-3 control-label'>
                      SMS Content
                    </label>
                    <div className='col-md-6 col-sm-6'>
                      <textarea
                        className='form-control'
                        rows='4'
                        cols='50'
                        onChange={(e) => this.messageChange(e)}
                        value={this.state.message}
                      />
                    </div>
                  </div>
                  <div className='form-group' style={{ display: 'flex' }}>
                    <div className='col-sm-3 col-md-3 control-label'></div>
                    <div
                      className='col-md-6 col-sm-6'
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <div></div>
                      <div style={{ position: 'relative', top: '-14px' }}>
                        {this.state.message.length !== 0 && (
                          <span>
                            {this.state.message.length} Characters (
                            {Math.floor(this.state.message.length / 160) + 1}
                            SMS Unit)
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='col-sm-3 col-md-3 control-label'>
                      SMS Recipient
                    </label>
                    <div
                      className='col-md-9 col-sm-9'
                      style={{ display: 'flex' }}
                    >
                      <div style={{ margin: '8px 0px' }}>
                        <input
                          type='radio'
                          name='filterType'
                          value={'1'}
                          checked={this.state.selectedRadio === '1'}
                          onChange={(e) => this.radioButtonChanged(e)}
                          className='invoice-radio'
                        />
                        <span className='invoice-sub-item'>Top Customers</span>
                      </div>
                      <div style={{ margin: '8px 14px' }}>
                        <input
                          type='radio'
                          name='filterType'
                          value={'2'}
                          checked={this.state.selectedRadio === '2'}
                          onChange={(e) => this.radioButtonChanged(e)}
                          className='invoice-radio'
                        />
                        <span className='invoice-sub-item'>
                          Pickup Customers Only
                        </span>
                      </div>
                      <div style={{ margin: '8px 20px' }}>
                        <input
                          type='radio'
                          name='filterType'
                          value={'3'}
                          checked={this.state.selectedRadio === '3'}
                          onChange={(e) => this.radioButtonChanged(e)}
                          className='invoice-radio'
                        />
                        <span className='invoice-sub-item'>
                          Delivery Customers Only
                        </span>
                      </div>
                      <div style={{ margin: '8px 20px' }}>
                        <input
                          type='radio'
                          name='filterType'
                          value={'5'}
                          checked={this.state.selectedRadio === '5'}
                          onChange={(e) => this.radioButtonChanged(e)}
                          className='invoice-radio'
                        />
                        <span className='invoice-sub-item'>
                          In-Active Users
                        </span>
                      </div>
                      <div style={{ margin: '8px 34px' }}>
                        <input
                          type='radio'
                          name='filterType'
                          value={'4'}
                          checked={this.state.selectedRadio === '4'}
                          onChange={(e) => this.radioButtonChanged(e)}
                          className='invoice-radio'
                        />
                        <span className='invoice-sub-item'>
                          Send To Specific Customers
                        </span>
                      </div>
                    </div>
                  </div>
                  {this.state.selectedRadio === '4' && (
                    <div className='form-group'>
                      <label className='col-sm-3 col-md-3 control-label'>
                        Contact Numbers
                      </label>
                      <div className='col-md-6 col-sm-6'>
                        <TagsInput
                          value={this.state.selectedCustomerNumbers}
                          onChange={this.onSelectedCustomersNumbersChange}
                          inputProps={{
                            placeholder: 'Enter number and press enter',
                          }}
                          class='form-control'
                        />
                      </div>
                    </div>
                  )}
                  {this.state.selectedRadio === '5' && (
                    <div className='form-group'>
                      <label className='col-sm-3 col-md-3 control-label'>
                        Inactive since?
                      </label>
                      <div
                        className='col-md-8 col-sm-13'
                        style={{ display: 'flex' }}
                      >
                        <React.Fragment>
                          <div style={{ margin: '8px 0px' }}>
                            <input
                              type='radio'
                              className='invoice-radio'
                              name='inActiveSince'
                              id={`1month`}
                              checked={this.state.inActiveSince === '1month'}
                              onChange={(e) =>
                                this.handleInActiveChange(e, '1month')
                              }
                            />
                            <span className='invoice-sub-item'>1 month</span>
                          </div>
                          <div style={{ margin: '8px 20px' }}>
                            <input
                              type='radio'
                              className='invoice-radio'
                              name='inActiveSince'
                              id={`2month`}
                              checked={this.state.inActiveSince === '2month'}
                              onChange={(e) =>
                                this.handleInActiveChange(e, '2month')
                              }
                            />

                            <span className='invoice-sub-item'>2 month</span>
                          </div>
                          <div style={{ margin: '8px 20px' }}>
                            <input
                              type='radio'
                              className='invoice-radio'
                              name='inActiveSince'
                              id={`6month`}
                              checked={this.state.inActiveSince === '6month'}
                              onChange={(e) =>
                                this.handleInActiveChange(e, '6month')
                              }
                            />

                            <span className='invoice-sub-item'>6 month</span>
                          </div>
                          <div style={{ margin: '8px 20px' }}>
                            <input
                              type='radio'
                              name='inActiveSince'
                              className='invoice-radio'
                              id={`1year`}
                              checked={this.state.inActiveSince === '1year'}
                              onChange={(e) =>
                                this.handleInActiveChange(e, '1year')
                              }
                            />
                            <span className='invoice-sub-item'>1 Year</span>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  )}
                  {this.state.selectedRadio === '3' && (
                    <div className='form-group'>
                      <label className='col-sm-3 col-md-3 control-label'>
                        Area wise customer ?
                      </label>
                      <div className='col-md-8 col-sm-13'>
                        <React.Fragment>
                          <div className='be-checkbox inline'>
                            <input
                              type='checkbox'
                              name='allAreas'
                              id={`allAreas`}
                              checked={this.state.isAllSuburb}
                              onChange={(e) =>
                                this.handleSuburbChange(e, undefined)
                              }
                            />
                            <label htmlFor='allAreas'>All Areas</label>
                          </div>
                          {this.state.allSuburbs &&
                            this.state.allSuburbs.map((suburb, index) => (
                              <div className='be-checkbox inline' key={index}>
                                <input
                                  type='checkbox'
                                  name={`${suburb._id}`}
                                  id={`${suburb._id}`}
                                  checked={suburb.isActive}
                                  onChange={(e) =>
                                    this.handleSuburbChange(e, suburb._id)
                                  }
                                />
                                <label htmlFor={suburb._id}>
                                  {suburb.name}
                                </label>
                              </div>
                            ))}
                        </React.Fragment>
                      </div>
                    </div>
                  )}

                  {this.state.selectedRadio === '1' && (
                    <div className='form-group'>
                      <label className='col-sm-3 col-md-3 control-label'>
                        Top Customers ?
                      </label>
                      <div className='col-md-3 col-sm-3'>
                        <input
                          type='number'
                          name='limitCustomer'
                          min='0'
                          max={`${totalCount}`}
                          value={this.state.limitCustomer}
                          className='form-control'
                          onChange={this.handleLimitCustomer}
                        />
                      </div>
                      <div className='col-md-2 col-sm-2'>
                        <span></span>
                      </div>
                    </div>
                  )}

                  <div className='form-group'>
                    <div className='col-sm-3 col-md-3 control-label'></div>
                    <div>
                      <button
                        onClick={() => this.openSendMessageModal()}
                        type='button'
                        className='btn btn-space btn-success active'
                        style={{ boxShadow: 'none', marginLeft: '16px' }}
                        disabled={this.state.disableSendButton}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.renderSMSModal && (
            <Modal
              aria-labelledby='modal-label'
              backdrop='static'
              keyboard={false}
              className='modal fade invoice-modal'
              show={this.state.renderSMSModal}
              onHide={this.closeSMSModal}
              bsSize='small'
            >
              <div style={{ border: '1px solid #c1c1c1' }}>
                <div className='modal-header' style={{ marginLeft: '18px' }}>
                  <button
                    onClick={this.closeSMSModal}
                    type='button'
                    data-dismiss='modal'
                    aria-hidden='true'
                    className='close md-close'
                  >
                    <span className='mdi mdi-close' />
                  </button>
                  <h3 className='modal-title'>SMS Marketing Confirmation</h3>
                </div>
                <div className='modal-body'>
                  <div className='invoice-container'>
                    <div className='invoice-item'>
                      <span className='invoice-sub-item'>
                        Charge :
                        {this.props &&
                          this.props.layout &&
                          this.props.layout.store &&
                          this.props.layout.store.currency &&
                          this.props.subscription &&
                          this.props.subscription.billingCode &&
                          this.props.subscription.billingCode
                            .smsNotificationCommission && (
                            <span>
                              {this.props.layout.store.currency}
                              {
                                this.props.subscription.billingCode
                                  .smsNotificationCommission
                              }
                              /SMS
                            </span>
                          )}
                      </span>
                    </div>

                    <div className='invoice-item'>
                      <span className='invoice-sub-item'>
                        Number of Customers :{this.getNumberOfCustomers()}
                      </span>
                    </div>

                    <div className='invoice-item'>
                      <span className='invoice-sub-item'>
                        Total Charge :
                        {this.props &&
                          this.props.customer &&
                          this.props.customer.totalCount &&
                          this.props.subscription &&
                          this.props.subscription.billingCode &&
                          this.props.subscription.billingCode
                            .smsNotificationCommission &&
                          this.props.layout &&
                          this.props.layout.store &&
                          this.props.layout.store.currency && (
                            <span>
                              {this.props.layout.store.currency}
                              <span>
                                {(
                                  this.getNumberOfCustomers() *
                                  Number(
                                    this.props.subscription.billingCode
                                      .smsNotificationCommission
                                  ) *
                                  Number(
                                    Math.floor(
                                      this.state.message.length / 160
                                    ) + 1
                                  )
                                ).toFixed(2)}
                              </span>
                            </span>
                          )}
                      </span>
                    </div>
                    <div className='invoice-item'>
                      <hr />
                      <span className='invoice-sub-item'>
                        SMS :
                        {this.state.message && (
                          <span>{this.state.message}</span>
                        )}
                      </span>
                      <hr />
                    </div>
                  </div>
                </div>
                <div className='invoice-footer'>
                  <div
                    style={{ marginLeft: '18px' }}
                    style={{ display: 'flex' }}
                  >
                    <div className='invoice-item'>
                      <span className='invoice-sub-item'>
                        Are you sure to send messages ?
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => this.sendMessages()}
                      type='button'
                      className='btn btn-space btn-success active'
                      style={{ boxShadow: 'none' }}
                      disabled={this.state.disableSendButton}
                    >
                      {this.state.sendingMessage ? 'Sending' : 'Send Messages'}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    storeSetting: state.storeSettingReducer,
    layout: state.layoutReducer,
    customer: state.customerReducer,
    subscription: state.subscriptionReducer,
    suburb: state.suburbReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStoreSetupHandler: (storeSetup, storeId) =>
      dispatch(getStoreSetup(storeSetup, storeId)),
    getCustomerCountHandler: (storeId, filterString) =>
      dispatch(getCustomerCount(storeId, filterString)),
    getCustomerCountDelivery: (storeId) =>
      dispatch(getCustomerCountDelivery(storeId)),
    getCustomerCountPickup: (storeId) =>
      dispatch(getCustomerCountPickup(storeId)),
    getStoreBillingCodeHandler: (storeId) =>
      dispatch(getStoreBillingCode(storeId)),
    getAllSuburbsHandler: (storeId) => dispatch(getAllSuburbs(storeId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreSetting);
