import React, { Component } from 'react';
import PropTypes from 'prop-types';
import avatar from '../../assets/images/avatar5.png';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserOpen: false,
      isNoteOpen: false,
    };
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutSideClick, true);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      (!this.props.user && nextProps.user) ||
      this.state.isUserOpen !== nextState.isUserOpen ||
      this.state.isNoteOpen !== nextState.isNoteOpen
    )
      return true;
    else return false;
  }

  handleOutSideClick = () => {
    this.setState({
      isUserOpen: false,
      isNoteOpen: false,
    });
  };

  openUser = () => {
    this.setState({
      isUserOpen: !this.state.isUserOpen,
      isNoteOpen: false,
    });
  };

  openNotifications = () => {
    this.setState({
      isNoteOpen: !this.state.isNoteOpen,
      isUserOpen: false,
    });
  };

  render() {
    return (
      <div className='be-right-navbar'>
        <ul className='nav navbar-nav navbar-right be-user-nav'>
          <li className={this.state.isUserOpen ? 'dropdown open' : 'dropdown'}>
            <a
              onClick={() => this.openUser()}
              data-toggle='dropdown'
              role='button'
              aria-expanded='true'
              className='dropdown-toggle'
            >
              <img src={avatar} alt='Avatar' />
              <span className='user-name'>
                {this.props.user && this.props.user.firstName}
              </span>
            </a>
            <ul role='menu' className='dropdown-menu'>
              <li>
                <div className='user-info'>
                  <div className='user-name'>
                    {this.props.user && this.props.user.firstName}
                  </div>
                </div>
              </li>
              <li>
                <a onClick={this.props.signOutHandler}>
                  <span className='icon mdi mdi-power' /> Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

Header.propTypes = {
  user: PropTypes.any,
  signOutHandler: PropTypes.any,
  allNotifications: PropTypes.any,
  allCustomers: PropTypes.any,
  allOrders: PropTypes.any,
  allOrderHistory: PropTypes.any,
  allReviews: PropTypes.any,
  allReservations: PropTypes.any,
  storeId: PropTypes.any,
};

export default Header;
