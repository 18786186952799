import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchReservations:
      return { ...state, reservations: payload.reservations };
    case ActionTypes.FetchReservationCount:
      return { ...state, totalCount: payload.totalCount };
    default:
      return state;
  }
};
