import { firestore } from '../../firebase';
import ActionTypes from './constants';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';

export function getReservationsCount(storeId, dateToday) {
  return (dispatch) => {
    let ref = firestore
      .collection('Reservations')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateToday);
    ref.onSnapshot((querySnapshot) => {
      dispatch(fetchedCurrentReservationsCount(querySnapshot.docs.length));
    });
  };
}

export function getReservations(storeId, pagination, dateToday) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('Reservations')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateToday)
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('Reservations')
          .where('storeId', '==', storeId)
          .where('dateTimestamp', '>=', dateToday)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);

        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(reservationsFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('Reservations')
          .where('storeId', '==', storeId)
          .where('dateTimestamp', '>=', dateToday)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);

        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(reservationsFetched(dataArray));
        });
      }
    });
  };
}

export function updateReservation(reservation, timeZone) {
  return () => {
    firestore
      .collection('Reservations')
      .doc(reservation._id)
      .update({ reservationStatus: reservation.reservationStatus })
      .then(() => {});
    //Make Reservation Status History entry
    let resHistory = {
      reservationId: reservation._id,
      userId: reservation.userId,
      userName: reservation.userName,
      firstName: reservation.firstName,
      lastName: reservation.lastName,
      reservationStatus: reservation.reservationStatus,
      children: reservation.children,
      adults: reservation.adults,
      infants: reservation.infants,
      storeId: reservation.storeId,
      restaurantId: reservation.restaurantId,
      date: getCurrentFormattedMomentDate(timeZone, 'YYYY-MM-DD HH:mm'),
      dateTimestamp: getCurrentTimestamp(),
      reservationDate: reservation.reservationDate,
      reservationDateTimestamp: reservation.reservationDateTimestamp
        ? reservation.reservationDateTimestamp
        : '',
    };
    firestore
      .collection('ReservationStatusHistory')
      .add(resHistory)
      .then(() => {});
  };
}

function reservationsFetched(reservations) {
  return {
    type: ActionTypes.FetchReservations,
    reservations,
  };
}

function fetchedCurrentReservationsCount(totalCount) {
  return {
    type: ActionTypes.FetchReservationCount,
    totalCount,
  };
}
