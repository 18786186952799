import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './containers/Dashboard';
import Menu from './containers/Menu';
import ItemStatistics from './containers/ItemStatistics';
import Vouchers from './containers/Vouchers';
import Specials from './containers/Specials';
import Reviews from './containers/Reviews';
import Customers from './containers/Customers';
import CurrentDeliveries from './containers/CurrentDeliveries';
import DeliveryStatistics from './containers/DeliveryStatistics';
import DeliveryAreas from './containers/DeliveryAreas';
import Drivers from './containers/Drivers';
import Driver from './containers/Driver';
import PickupStatistics from './containers/PickupStatistics';
import CurrentReservations from './containers/CurrentReservations';
import ReservationStatistics from './containers/ReservationStatistics';
import StoreSetting from './containers/StoreSetting';
import SMSMarketing from './containers/SMSMarketing';
import EmailMarketing from './containers/EmailMarketing';
import ProductAndIngredientSetup from './containers/ProductAndIngredientSetup';
import Shifts from './containers/Shifts';
import AuthenticationCode from './containers/AuthenticationCode';
import Login from './containers/Login';
import Subscription from './containers/Subscription';
import Migrations from './containers/Migrations';
import CloseDates from './containers/CloseDates';
import PaymentReports from './containers/PaymentReports';
import CurrentOtherDeliveries from './containers/CurrentOtherDeliveries';
import OtherDeliveryStatistics from './containers/OtherDeliveryStatistics';
import RealtimeOrders from './containers/RealtimeOrders';
import RefreshTV from './containers/RefreshTV';
import Refund from './containers/Refund';
import Offers from './containers/Offers';
import Memberships from './containers/Memberships';

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/restaurants/dashboard' component={Dashboard} />
        <Route path='/restaurants/menu/menu-items' component={Menu} />
        <Route path='/restaurants/menu/specials' component={Specials} />
        <Route
          path='/restaurants/menu/item-statistics'
          component={ItemStatistics}
        />
        <Route path='/restaurants/marketing/vouchers' component={Vouchers} />
        <Route path='/restaurants/marketing/reviews' component={Reviews} />
        <Route path='/restaurants/marketing/customers' component={Customers} />
        <Route
          path='/restaurants/marketing/sms-marketing'
          component={SMSMarketing}
        />
        <Route
          path='/restaurants/marketing/email-marketing'
          component={EmailMarketing}
        />
        <Route
          path='/restaurants/delivery/current-deliveries'
          component={CurrentDeliveries}
        />
        <Route
          path='/restaurants/other-delivery/current-other-deliveries'
          component={CurrentOtherDeliveries}
        />
        <Route
          path='/restaurants/delivery/delivery-statistics'
          component={DeliveryStatistics}
        />
        <Route
          path='/restaurants/other-delivery/other-delivery-statistics'
          component={OtherDeliveryStatistics}
        />
        <Route
          path='/restaurants/delivery/delivery-areas'
          component={DeliveryAreas}
        />
        <Route path='/restaurants/delivery/drivers' component={Drivers} />
        <Route path='/restaurants/delivery/driver' component={Driver} />
        <Route
          path='/restaurants/pickup/pickup-statistics'
          component={PickupStatistics}
        />
        <Route
          path='/restaurants/reservation/current-reservations'
          component={CurrentReservations}
        />
        <Route
          path='/restaurants/reservation/reservation-statistics'
          component={ReservationStatistics}
        />
        <Route
          path='/restaurants/settings/system-setting'
          component={StoreSetting}
        />
        <Route
          path='/restaurants/menu/half-half-setup'
          component={ProductAndIngredientSetup}
        />
        <Route path='/restaurants/settings/shifts' component={Shifts} />
        <Route path='/restaurants/auth-code' component={AuthenticationCode} />
        <Route path='/restaurants/login' component={Login} />
        <Route path='/restaurants/settings/migrations' component={Migrations} />
        <Route
          path='/restaurants/settings/close-dates'
          component={CloseDates}
        />
        <Route
          path='/restaurants/payment/payment-reports'
          component={PaymentReports}
        />
        <Route path='/restaurants/realtime-orders' component={RealtimeOrders} />
        <Route path='/restaurants/refreshtv' component={RefreshTV} />
        <Route path='/restaurants/refund' component={Refund} />
        <Route path='/restaurants/loyalty/offers' component={Offers} />
        <Route
          path='/restaurants/loyalty/memberships'
          component={Memberships}
        />
        <Route
          path='/restaurants/payment/subscription'
          component={Subscription}
        />
        {/* <Route
          path='/restaurant/settings/reset-password'
          component={ResetPassword}
        /> */}
        <Route exact path='/' component={Login} />
        <Route render={() => <Redirect to='/' />} />
      </Switch>
    </BrowserRouter>
  );
};
