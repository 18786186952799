import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import WarningModal from '../../components/WarningModal';
import ShiftForm from '../../components/ShiftForm';
import Spinner from '../../components/Spinner';
import './index.css';

const weekDays = [
  { id: '1', value: 'Monday' },
  { id: '2', value: 'Tuesday' },
  { id: '3', value: 'Wednesday' },
  { id: '4', value: 'Thursday' },
  { id: '5', value: 'Friday' },
  { id: '6', value: 'Saturday' },
  { id: '7', value: 'Sunday' },
];

class ShiftList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShiftWarning: false,
      isDisplayShift: false,
      isEditModeOn: false,
      shiftForEdit: {},
    };
  }

  closeWarnings = () => {
    this.setState({ showShiftWarning: false });
  };

  openShiftWarningModal = (Shift) => {
    this.setState({ showShiftWarning: true, shiftToBeDeleted: Shift });
  };

  openShiftModalForNew = (Shift) => {
    this.setState({
      isError: false,
      isDisplayShift: true,
      isEditModeOn: false,
      shiftForEdit: { ...Shift },
    });
  };

  openShiftModal = (key) => {
    let shifts = this.props.shifts.filter((shift) => {
      return shift._id === key;
    });
    if (shifts && shifts.length > 0)
      this.setState({
        isError: false,
        isDisplayShift: true,
        isEditModeOn: true,
        shiftForEdit: { ...shifts[0] },
      });
  };

  closeShiftModal = () => {
    this.setState({
      isError: false,
      isDisplayShift: false,
      shiftToBeDeleted: {},
      isEditModeOn: false,
      shiftForEdit: {},
      showShiftWarning: false,
    });
  };

  deleteShift = () => {
    this.props.deleteShiftHandler(this.state.shiftToBeDeleted);
    this.closeShiftModal();
  };

  getDay = (day) => {
    var Day = weekDays.filter(function (obj) {
      return obj.id === day;
    });
    if (Day && Day.length > 0) return Day[0].value;
    else return '';
  };

  render() {
    // const start =
    //   (this.props.pagination.currentPage - 1) * this.props.pagination.pageSize +
    //   1;
    // const end = this.props.shifts
    //   ? (this.props.pagination.currentPage - 1) *
    //       this.props.pagination.pageSize +
    //     1 +
    //     this.props.shifts.length -
    //     1
    //   : 0;
    return (
      <div className='row'>
        <div className='col-sm-12 col-xs-12 col-md-12'>
          <div className='panel panel-default panel-table '>
            <div className='panel-heading'>
              Shift Hours
              <div className='fright'>
                <button
                  onClick={() => this.openShiftModalForNew({})}
                  className='btn btn-space btn-default hover'
                >
                  Add Shift
                </button>
              </div>
            </div>
            <div className='panel-body table-responsive'>
              <div
                id='table1_wrapper'
                className='dataTables_wrapper form-inline dt-bootstrap no-footer'
              >
                <div className='row be-datatable-body'>
                  <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                    <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                      <thead>
                        <tr>
                          <th>Day</th>
                          <th>Dine-In</th>
                          <th>Pickup</th>
                          <th>Delivery</th>
                          <th>Average Order</th>
                          <th />
                        </tr>
                      </thead>
                      {!this.props.shifts ? (
                        <tbody>
                          <tr style={{ height: '100px' }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                left: '0px',
                                right: '0px',
                                position: 'absolute',
                                width: 'auto',
                                height: '100px',
                              }}
                              className='be-loading be-loading-active'
                            >
                              <Spinner />
                            </div>
                          </tr>
                        </tbody>
                      ) : this.props.shifts.length > 0 ? (
                        <tbody>
                          {this.props.shifts &&
                            this.props.shifts.map((shift, index) => (
                              <tr key={index}>
                                <td>{this.getDay(shift.day)}</td>
                                <td>
                                  {`${shift.restaurantStartDisplay} - ${shift.restaurantEndDisplay}`}
                                </td>
                                <td>
                                  {`${shift.openingStartDisplay} - ${shift.openingEndDisplay}`}
                                </td>
                                <td>
                                  {shift.deliveryType === '2'
                                    ? `Slots(${shift.slots
                                        .map(
                                          (s) =>
                                            `${s.slotStartDisplay} - ${s.slotEndDisplay}`
                                        )
                                        .join(',')})`
                                    : `${shift.deliveryStartDisplay} -  ${shift.deliveryEndDisplay}`}
                                </td>
                                <td>
                                  {shift.averageRevenue
                                    ? `${
                                        this.props.currency
                                      }${shift.averageRevenue.toFixed(2)}`
                                    : 'N/A'}
                                </td>
                                <td className='text-right'>
                                  <button
                                    onClick={() =>
                                      this.openShiftWarningModal(shift)
                                    }
                                    className='btn btn-space btn-default'
                                  >
                                    Delete
                                  </button>
                                  <button
                                    onClick={() =>
                                      this.openShiftModal(shift._id)
                                    }
                                    className='btn btn-space btn-primary'
                                  >
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <p> No records to display</p>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                {/* <div className='row be-datatable-footer'>
                  <div className='col-md-5 col-sm-12'>
                    <div
                      style={{ float: 'left' }}
                      className='dataTables_info'
                      id='table1_info'
                      role='status'
                      aria-live='polite'
                    >
                      {`Showing ${start} to ${end} of ${
                        this.props.totalCount ? this.props.totalCount : 0
                      } entries`}
                    </div>
                  </div>
                  <div className='col-md-7 col-sm-12'>
                    <div
                      className='dataTables_paginate paging_simple_numbers'
                      id='table1_paginate'
                    >
                      {this.props.pagination && (
                        <nav style={{ float: 'right' }}>
                          <Pagination
                            activePage={this.props.pagination.currentPage}
                            itemsCountPerPage={this.props.pagination.pageSize}
                            totalItemsCount={this.props.totalCount}
                            pageRangeDisplayed={
                              this.props.pagination.pageRangeDisplayed
                            }
                            onChange={(e) => this.props.handlePageChange(e)}
                            prevPageText='Previous'
                            nextPageText='Next'
                            itemClass='paginate_button'
                          />
                        </nav>
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {this.state.isDisplayShift && (
          <Modal
            aria-labelledby='modal-label'
            backdrop='static'
            keyboard={false}
            className='modal fade'
            show={this.state.isDisplayShift}
            onHide={this.closeShiftModal}
            bsSize='lg'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  onClick={this.closeShiftModal}
                  type='button'
                  data-dismiss='modal'
                  aria-hidden='true'
                  className='close md-close'
                >
                  <span className='mdi mdi-close' />
                </button>
              </div>
              <ShiftForm
                addShiftHandler={this.props.addShiftHandler}
                isEditModeOn={this.state.isEditModeOn}
                shiftForEdit={this.state.shiftForEdit}
                totalCount={this.props.shifts.length}
                closeShiftModal={this.closeShiftModal}
                timeZone={this.props.timeZone}
              />
            </div>
          </Modal>
        )}
        {this.state.showShiftWarning && (
          <WarningModal
            procced={this.deleteShift}
            closeModal={this.closeWarnings}
            message={`This will delete Shift. Click "proceed" to continue.`}
          />
        )}
      </div>
    );
  }
}

ShiftList.propTypes = {
  shifts: PropTypes.any,
  pagination: PropTypes.any,
  totalCount: PropTypes.any,
  handlePageChange: PropTypes.any,
  deleteShiftHandler: PropTypes.any,
  addShiftHandler: PropTypes.any,
};

export default ShiftList;
