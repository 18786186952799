import { firestore, firestoreother } from '../../firebase';
import orderBy from 'lodash/orderBy';

export function refreshTVs(_storeId) {
  return () => {
    let nextRef = firestore
      .collection('LoggedInDevices')
      // .where('storeId', '==', storeId)
      .where('isActive', '==', true);
    nextRef.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dataArray.map((d) => {
        firestore
          .collection('LoggedInDevices')
          .doc(d._id)
          .update({ refreshCount: d.refreshCount ? d.refreshCount + 1 : 1 })
          .then(() => {
            console.log('Value updated');
          });
      });
    });
  };
}

export function migScript1() {
  return () => {
    firestore
      .collection('Specials')
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          doc.ref.update({ isAvailableWithSpecialDiscount: false }).then(() => {
            console.log('Update 1');
          });
        });
      });
  };
}

export function migScript2() {
  return () => {
    firestore
      .collection('Stores')
      .where('isActive', '==', true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          firestore
            .collection('PaymentReportOrders')
            .where('storeId', '==', doc.id)
            .where('isProcessedForPayment', '==', true)
            .where('dateTimestamp', '>=', 1585679400000)
            .where('dateTimestamp', '<=', 1588271399999)
            .get()
            .then((querySnapshotOds) => {
              var reportOrders = []; // Have all orders for the report
              querySnapshotOds.forEach((docc) => {
                let dObjOdc = docc.data();
                dObjOdc._id = docc.id;
                reportOrders.push(dObjOdc);
              });
              let totalVolume = 0;
              reportOrders.map((m) => {
                totalVolume += Number(m.payableAmount);
                return m;
              });
              let totalDeliveries = reportOrders.filter((f) => {
                return f.orderType === '2';
              }).length;
              let totalPickps = reportOrders.filter((f) => {
                return f.orderType === '1';
              }).length;
              firestore
                .collection('PaymentReportTextMsgs')
                .where('storeId', '==', doc.id)
                .where('isProcessedForPayment', '==', true)
                .where('dateTimestamp', '>=', 1585679400000)
                .where('dateTimestamp', '<=', 1588271399999)
                .get()
                .then((querySnapshotMsgs) => {
                  firestore
                    .collection('PaymentReportOtherDeliveries')
                    .where('storeId', '==', doc.id)
                    .where('isProcessedForPayment', '==', true)
                    .where('dateTimestamp', '>=', 1585679400000)
                    .where('dateTimestamp', '<=', 1588271399999)
                    .get()
                    .then((querySnapshotOdh) => {
                      firestore
                        .collection('PaymentReportReservations')
                        .where('storeId', '==', doc.id)
                        .where('isProcessedForPayment', '==', true)
                        .where('dateTimestamp', '>=', 1585679400000)
                        .where('dateTimestamp', '<=', 1588271399999)
                        .get()
                        .then((querySnapshotRvn) => {
                          console.log(
                            doc.id,
                            totalVolume,
                            totalDeliveries,
                            totalPickps,
                            querySnapshotMsgs.docs.length,
                            querySnapshotOdh.docs.length,
                            querySnapshotRvn.docs.length
                          );
                        });
                    });
                });
            });
        });
      });
  };
}

export function migScript3() {
  return () => {
    // firestore
    //   .collection('DriverLocation5F')
    //   .get()
    //   .then((querySnapshot) => {
    //     var dataArray = [];
    //     querySnapshot.forEach((doc) => {
    //       dataArray.push({ _id: doc.id, ...doc.data() });
    //     });
    //     let finalList = [];
    //     dataArray.map((d) => {
    //       if (d.index > 4 && d.index < 15) {
    //       } else {
    //         finalList.push(d);
    //       }
    //     });
    //     finalList = orderBy(finalList, 'index', 'asc');
    //     finalList = finalList.map((m, index) => {
    //       m.index = index;
    //       return m;
    //     });
    //     console.log(dataArray.length, finalList);
    //     finalList.map((p) => {
    //       firestore
    //         .collection('DriverLocation5FF')
    //         .add(p)
    //         .then(() => {
    //           console.log('added');
    //         });
    //     });
    //   });
  };
}

export function migScript4() {
  return () => {
    firestore
      .collection('Stores')
      .where('isActive', '==', true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          let store = doc.data();
          console.log(
            `${store.name} ${store.addressLocation.lat} ${store.addressLocation.long}`
          );
        });
      });
  };
}
