import { firestore } from '../../firebase';
import ActionTypes from './constants';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';

export function getPaymentReports(storeId, pagination) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('PaymentReports')
      .where('storeId', '==', storeId)
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('PaymentReports')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(paymentReportsFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('PaymentReports')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(paymentReportsFetched(dataArray));
        });
      }
    });
  };
}

export function getPaymentReportsCount(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('PaymentReports')
      .where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(paymentReportsCountFetched(querySnapshot.docs.length));
    });
  };
}

export function getStore(storeId) {
  return (dispatch) => {
    let ref = firestore.collection('Stores').doc(storeId);
    ref.onSnapshot((doc) => {
      dispatch(storeFetched(doc.data()));
    });
  };
}

export function addOnlineProcessingFees() {
  return (dispatch) => {
    let createdOn = getCurrentFormattedMomentDate(
      'Australia/Melbourne',
      'YYYY-MM-DD'
    );
    let createdOnTimestamp = getCurrentTimestamp();
    let resObjs = [
      {
        cardType: 1,
        cardDescription: 'JCB',
        processingFees: 2.9,
        processingFeesInternationCard: 2.9,
        createdOn,
        createdOnTimestamp,
      },
      {
        cardType: 2,
        cardDescription: 'American Express(Amex)',
        processingFees: 2.9,
        processingFeesInternationCard: 2.9,
        createdOn,
        createdOnTimestamp,
      },
      {
        cardType: 3,
        cardDescription: 'Dinners Club',
        processingFees: 2.9,
        processingFeesInternationCard: 2.9,
        createdOn,
        createdOnTimestamp,
      },
      {
        cardType: 4,
        cardDescription: 'Discover',
        processingFees: 2.9,
        processingFeesInternationCard: 2.9,
        createdOn,
        createdOnTimestamp,
      },
      {
        cardType: 5,
        cardDescription: 'MasterCard',
        processingFees: 2.9,
        processingFeesInternationCard: 2.9,
        createdOn,
        createdOnTimestamp,
      },
      {
        cardType: 6,
        cardDescription: 'VISA',
        processingFees: 2.9,
        processingFeesInternationCard: 2.9,
        createdOn,
        createdOnTimestamp,
      },
      {
        cardType: 7,
        cardDescription: 'UnionPay',
        processingFees: 2.9,
        processingFeesInternationCard: 2.9,
        createdOn,
        createdOnTimestamp,
      },
      {
        cardType: 0,
        cardDescription: 'UnKnown',
        processingFees: 2.9,
        processingFeesInternationCard: 2.9,
        createdOn,
        createdOnTimestamp,
      },
    ];
    resObjs.map((ads) => {
      firestore
        .collection('CreditCardProcessingFees')
        .add(ads)
        .then(() => {
          console.log('OnlineProcessingFees code written');
        });
    });
  };
}

export function addStoreBillings() {
  return (dispatch) => {
    let createdOn = getCurrentFormattedMomentDate(
      'Australia/Melbourne',
      'YYYY-MM-DD'
    );
    let createdOnTimestamp = getCurrentTimestamp();
    let resObjs = [
      {
        nextOrderCommission: 6.5,
        otherDeliveryCommision: 0.07,
        smsRate: 0.07,
        returningToStoreRate: 1,
        createdOn,
        createdOnTimestamp,
      },
      {
        storeId: 'LUTh6KLzSMmqHDa22a8X',
        restaurantId: 'XXtGsqz9B4Pi7ZI4GKet',
        description: '',
        nextOrderCommission: 6.5,
        otherDeliveryCommision: 0.07,
        smsRate: 0.07,
        returningToStoreRate: 1,
        createdOn,
        createdOnTimestamp,
      },
      {
        storeId: '8Qr0iiwEbtUh2Gui8AU9',
        restaurantId: 'LNUpjrfJRjHPrtpiAbKO',
        description: '',
        nextOrderCommission: 6.5,
        otherDeliveryCommision: 0.07,
        smsRate: 0.07,
        returningToStoreRate: 1,
        createdOn,
        createdOnTimestamp,
      },
    ];
    resObjs.map((ads) => {
      firestore
        .collection('BillingCodes')
        .add(ads)
        .then(() => {
          console.log('Billing code written');
        });
    });
  };
}

export function addStoreMiscellaneousCharges() {
  return (dispatch) => {
    let resObjs = [
      {
        storeId: 'CTx3rEqrW5ImDJHttpSv',
        restaurantId: 'IqNmz0zGpxXXYPM4FrNa',
        description: 'Photography charges',
        title: 'Photography',
        charges: 200,
        remainingCharges: 200,
        typeOfRecovery: 2,
        installmentAmount: 50,
        isInstallmentInPercentage: false,
        isProcessedForPayment: false,
        date: getCurrentFormattedMomentDate(
          'Australia/Melbourne',
          'YYYY-MM-DD HH:mm'
        ),
        dateTimestamp: getCurrentTimestamp(),
      },
      {
        storeId: 'CTx3rEqrW5ImDJHttpSv',
        restaurantId: 'IqNmz0zGpxXXYPM4FrNa',
        description: 'Technical support',
        title: 'Technical support',
        charges: 200,
        remainingCharges: 200,
        typeOfRecovery: 2,
        installmentAmount: 10,
        isInstallmentInPercentage: true,
        isProcessedForPayment: false,
        date: getCurrentFormattedMomentDate(
          'Australia/Melbourne',
          'YYYY-MM-DD HH:mm'
        ),
        dateTimestamp: getCurrentTimestamp(),
      },
      {
        storeId: 'CTx3rEqrW5ImDJHttpSv',
        restaurantId: 'IqNmz0zGpxXXYPM4FrNa',
        description: 'Receipt Printer Charges for June',
        title: 'Receipt Printer Charges',
        charges: 50,
        remainingCharges: 50,
        typeOfRecovery: 1,
        installmentAmount: 50,
        isInstallmentInPercentage: false,
        isProcessedForPayment: false,
        date: getCurrentFormattedMomentDate(
          'Australia/Melbourne',
          'YYYY-MM-DD HH:mm'
        ),
        dateTimestamp: getCurrentTimestamp(),
      },
    ];
    resObjs.forEach((m) => {
      firestore
        .collection('MiscellaneousCharges')
        .add(m)
        .then(() => {
          console.log('Miscellaneous Charges written');
        });
    });
  };
}

export function addStoreCredit() {
  return (dispatch) => {
    let resObjs = [
      {
        storeId: '2M3c4c3lHRblmXiH1nYN',
        restaurantId: 'ONzvuKErKHXikT99KOym',
        description: 'Credit',
        title: 'Credit',
        amount: 200,
        remainingAmount: 200,
        typeOfPayment: 1,
        installmentAmount: 200,
        isInstallmentInPercentage: false,
        isProcessedForPayment: false,
        date: getCurrentFormattedMomentDate(
          'Australia/Melbourne',
          'YYYY-MM-DD HH:mm'
        ),
        dateTimestamp: getCurrentTimestamp(),
      },
    ];
    resObjs.forEach((m) => {
      firestore
        .collection('StoreCredits')
        .add(m)
        .then(() => {
          console.log('StoreCredits Charges written');
        });
    });
  };
}

function paymentReportsFetched(paymentReports) {
  return {
    type: ActionTypes.FetchPaymentReports,
    paymentReports,
  };
}

function paymentReportsCountFetched(totalCount) {
  return {
    type: ActionTypes.FetchedCountPaymentReport,
    totalCount,
  };
}

function storeFetched(store) {
  return {
    type: ActionTypes.FetchStore,
    store,
  };
}
