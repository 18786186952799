import React, { Component } from 'react';
import PropTypes from 'prop-types';

const L = window.L;
let iconDeliveryAddress = L.icon({
  iconUrl: `https://s3-ap-southeast-2.amazonaws.com/lacampa/assets/marker.svg`,
  iconSize: [40, 50], // size of the icon
  iconAnchor: [0, 50],
});
let icon = L.icon({
  iconUrl: 'https://s3-ap-southeast-2.amazonaws.com/lacampa/assets/car.svg',
  iconSize: [30, 40],
  iconAnchor: [15, 20],
});
let mapStyle = {
  minHeight: '220px !important',
};

class LeafletDriver extends Component {
  constructor(props) {
    super(props);
    this.map = {};
    this.marker = [];
  }
  componentDidMount() {
    if (
      this.props.driver &&
      this.props.driver.currentLocation &&
      this.props.driver.previousLocation &&
      this.props.delivery
    ) {
      L.mapbox.accessToken =
        'pk.eyJ1Ijoid2lsbG9yaWVudCIsImEiOiJjanZ6eDRqMXMwNTI4NDhvbmVsbjhhdmhzIn0.SXJqaMi8L08EwIHZf7pRIg';
      this.map = L.mapbox
        .map('map', null, {
          styles: mapStyle,
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          maxZoom: 16,
          scrollWheelZoom: false,
        })
        .setView(
          [
            Number(this.props.driver.currentLocation.lat),
            Number(this.props.driver.currentLocation.long),
          ],
          16
        )
        .addLayer(
          L.mapbox.styleLayer(
            'mapbox://styles/williamrichards/cjvc2w0fo6alc1fqxknvqjgoy',
            {
              tileSize: 256,
            }
          )
        );

      //Add google layer on that

      //Current polyline for driver to delivery address
      this.route = L.Routing.control({
        waypoints: [
          L.latLng(
            Number(this.props.driver.currentLocation.lat),
            Number(this.props.driver.currentLocation.long)
          ),
          L.latLng(this.props.delivery.lat, this.props.delivery.lng),
        ],
        show: true,
        waypointMode: 'snap',
        lineOptions: {
          styles: [{ color: 'rgb(226, 90, 40)', opacity: 1, weight: 5 }],
        },
        createMarker: () => {
          return false;
        },
        routeWhileDragging: true,
        reverseWaypoints: true,
        showAlternatives: false,
        altLineOptions: {
          styles: [
            { color: '#f47f02', opacity: 0.15, weight: 9 },
            { color: '#f4bb02', opacity: 0.8, weight: 6 },
            { color: '#f7de00', opacity: 0.5, weight: 2 },
          ],
        },
      }).addTo(this.map);

      //Driver marker on mpa
      var l1 = new window.google.maps.LatLng(
        this.props.driver.previousLocation.lat,
        this.props.driver.previousLocation.long
      );
      var l2 = new window.google.maps.LatLng(
        this.props.driver.currentLocation.lat,
        this.props.driver.currentLocation.long
      );
      var heading = window.google.maps.geometry.spherical.computeHeading(
        l1,
        l2
      );
      this.marker = L.marker(
        [
          this.props.driver.currentLocation.lat,
          this.props.driver.currentLocation.long,
        ],
        { rotationOrigin: 'center', rotationAngle: heading, icon }
      ).addTo(this.map);

      //Showing desination for delivery
      this.desination = L.marker(
        [this.props.delivery.lat, this.props.delivery.lng],
        {
          rotationOrigin: 'center',
          rotationAngle: heading,
          icon: iconDeliveryAddress,
        }
      ).addTo(this.map);
    }
    this.recenterMap(this.props.order);
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps &&
      newProps.driver &&
      newProps.driver.currentLocation &&
      newProps.delivery
    ) {
      if (newProps.driver.previousLocation && newProps.driver.currentLocation) {
        //Update driver location and rotate marker
        var newLatLng = new L.LatLng(
          newProps.driver.currentLocation.lat,
          newProps.driver.currentLocation.long
        );
        var l1 = new window.google.maps.LatLng(
          newProps.driver.previousLocation.lat,
          newProps.driver.previousLocation.long
        );
        var l2 = new window.google.maps.LatLng(
          newProps.driver.currentLocation.lat,
          newProps.driver.currentLocation.long
        );
        let heading = window.google.maps.geometry.spherical.computeHeading(
          l1,
          l2
        );
        let distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          l1,
          l2
        );
        if (heading < 0) heading = heading + 360;
        if (distance > 5) this.marker.setRotationAngle(heading);
        this.marker.slideTo(newLatLng, {
          duration: 2500,
          keepAtCenter: false,
        });

        //Update polyline
        this.route
          .getPlan()
          .setWaypoints([
            L.latLng(
              Number(newProps.driver.previousLocation.lat),
              Number(newProps.driver.previousLocation.long)
            ),
            L.latLng(newProps.delivery.lat, newProps.delivery.long),
          ]);
      }

      // Fitbounds for map
      this.recenterMap(newProps.order);
    }
  }
  recenterMap = (_order) => {
    var markerArray = [];
    markerArray.push(this.marker);
    //  Fitbound map to cover all markers
    if (markerArray && markerArray.length > 0) {
      var group = L.featureGroup(markerArray);
      this.map.fitBounds(group.getBounds(), {
        padding: [50, 50],
        maxZoom: 16,
        animate: true,
        pan: {
          duration: 100,
        },
      });
    }
    //L.Transition = null; // This is for removal of jurking effect
  };
  render() {
    return <div ref='map' id='map' />;
  }
}

LeafletDriver.propTypes = {
  store: PropTypes.any,
  driver: PropTypes.any,
  delivery: PropTypes.any,
  order: PropTypes.any,
};

export default LeafletDriver;
