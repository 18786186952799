import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import WarningModal from '../../components/WarningModal';
import Spinner from '../../components/Spinner';

class OfferList extends Component {
  constructor(props) {
    super(props);
    this.state = { isDisplayWarning: false, opType: 0 };
  }

  closeWarningModal = () => {
    this.setState({
      isDisplayWarning: false,
      offerTobeProcessed: {},
      opType: 0,
    });
  };

  openWarningModal = (offer, opType) => {
    this.setState({
      isDisplayWarning: true,
      opType,
      message:
        opType === 1
          ? 'This will de-activate offer. Click "proceed" to continue.'
          : 'This will delete offer. Click "proceed" to continue.',
      offerTobeProcessed: offer,
    });
  };

  deleteOffer = () => {
    this.props.deleteOffer(this.state.offerTobeProcessed, this.state.opType);
    this.setState({ isDisplayWarning: false });
  };

  getPathwayString = (offer) => {
    let returnString = '';
    if (offer.type === '1') {
      returnString = `${offer.quantity} ${offer.sizes
        .filter((f) => f.isActive)
        .map((m) => m.name)
        .join(' or ')} ${offer.categories
        .filter((f) => f.isActive)
        .map((m) => m.name)
        .join('/')}`;
      if (offer.menuItems.filter((f) => !f.isActive).length > 0)
        returnString = `${returnString} and items excluded from calculations are(${offer.menuItems
          .filter((f) => !f.isActive)
          .map((m) => m.name)
          .join(',')})`;
    } else {
      returnString = `${offer.noOfOrders} orders(Min. order value is ${this.props.currency}${offer.minOrderValue})`;
    }
    return returnString;
  };

  getOfferReward = (offer) => {
    let returnString = [];
    offer.rewardItems.map((m) => {
      let p =
        Number(m.discountedPrice) <= 0
          ? 'One free'
          : `One ${this.props.currency}${m.discountedPrice}`;
      p =
        p +
        ` ${m.sizes
          .filter((f) => f.isActive)
          .map((m) => m.name)
          .join(' or ')} ${m.categories
          .filter((f) => f.isActive)
          .map((m) => m.name)
          .join('/')}`;
      returnString.push(p);
    });
    return offer.isAnyOneItemAvailable
      ? returnString.join(' or ')
      : returnString.join(' and ');
  };

  render() {
    const start =
      (this.props.pagination.currentPage - 1) * this.props.pagination.pageSize +
      1;
    const end = this.props.offers
      ? (this.props.pagination.currentPage - 1) *
          this.props.pagination.pageSize +
        1 +
        this.props.offers.length -
        1
      : 0;
    return (
      <div className='row'>
        <div className='col-sm-12 col-xs-12 col-md-12'>
          <div className='panel panel-default panel-table'>
            <div className='panel-heading'> Offers </div>
            <div
              style={{ overflow: 'hidden' }}
              className='panel-body table-responsive'
            >
              <div
                id='table1_wrapper'
                className='dataTables_wrapper form-inline dt-bootstrap no-footer'
              >
                <div className='row be-datatable-body'>
                  <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                    <table
                      id='table1'
                      className='table table-striped table-hover table-fw-widget dataTable no-footer'
                      role='grid'
                      aria-describedby='table1_info'
                    >
                      <thead>
                        <tr role='row'>
                          <th>Name</th>
                          <th>Pathway To Unlock Offer</th>
                          <th>Offer Reward</th>
                          <th>Status</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      {!this.props.offers ? (
                        <tbody>
                          <tr style={{ height: '100px' }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                left: '0px',
                                right: '0px',
                                position: 'absolute',
                                width: 'auto',
                                height: '100px',
                              }}
                              className='be-loading be-loading-active'
                            >
                              <Spinner />
                            </div>
                          </tr>
                        </tbody>
                      ) : this.props.offers.length > 0 ? (
                        <tbody>
                          {this.props.offers &&
                            this.props.offers.map((offer, index) => (
                              <tr key={index} className='gradeX odd' role='row'>
                                <td className='sorting_1'>{offer.name}</td>
                                <td>{this.getPathwayString(offer)}</td>
                                <td>{this.getOfferReward(offer)}</td>
                                <td>
                                  {offer.isDeleted
                                    ? 'Deleted'
                                    : offer.isActive
                                    ? 'Active'
                                    : 'Deactivated'}
                                </td>
                                <td className='text-right'>
                                  {offer.isActive && !offer.isDeleted && (
                                    <button
                                      onClick={() =>
                                        this.props.setOfferForEdit(offer)
                                      }
                                      className='btn btn-space btn-default'
                                    >
                                      Edit
                                    </button>
                                  )}
                                  {offer.isActive && !offer.isDeleted && (
                                    <button
                                      onClick={() =>
                                        this.openWarningModal(offer, 1)
                                      }
                                      className='btn btn-space btn-primary hover'
                                      title='Users with progress can still unlock de-activated offer'
                                    >
                                      Deactivete
                                    </button>
                                  )}
                                  {!offer.isDeleted && (
                                    <button
                                      onClick={() =>
                                        this.openWarningModal(offer, 2)
                                      }
                                      className='btn btn-space btn-primary hover'
                                      title={`Users can't use deleted offer, their progress will become irrelevant`}
                                    >
                                      Delete
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <p> No records to display</p>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className='row be-datatable-footer'>
                  <div className='col-md-5 col-sm-12'>
                    <div
                      style={{ float: 'left' }}
                      className='dataTables_info'
                      id='table1_info'
                      role='status'
                      aria-live='polite'
                    >
                      {`Showing ${start} to ${end} of ${
                        this.props.totalCount ? this.props.totalCount : 0
                      } entries`}
                    </div>
                  </div>
                  <div className='col-md-7 col-sm-12'>
                    <div
                      className='dataTables_paginate paging_simple_numbers'
                      id='table1_paginate'
                    >
                      {this.props.pagination && (
                        <nav style={{ float: 'right' }}>
                          <Pagination
                            activePage={this.props.pagination.currentPage}
                            itemsCountPerPage={this.props.pagination.pageSize}
                            totalItemsCount={this.props.totalCount}
                            pageRangeDisplayed={
                              this.props.pagination.pageRangeDisplayed
                            }
                            onChange={this.props.handlePageChange}
                            prevPageText='Previous'
                            nextPageText='Next'
                            itemClass='paginate_button'
                          />
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.isDisplayWarning && (
          <WarningModal
            procced={this.deleteOffer}
            closeModal={this.closeWarningModal}
            message={`This will delete offer. Click "proceed" to continue.`}
          />
        )}
      </div>
    );
  }
}

OfferList.propTypes = {
  offers: PropTypes.any,
  totalCount: PropTypes.any,
  handlePageChange: PropTypes.any,
  pagination: PropTypes.any,
  timeZone: PropTypes.any,
  setOfferForEdit: PropTypes.any,
  deleteOffer: PropTypes.any,
};

export default OfferList;
