import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getStoreSetup(storeSetup, storeId) {
  return (dispatch) => {
    firestore
      .collection('OrderSetup')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        if (dataArray.length > 0) dispatch(orderSetupFetched(dataArray[0]));
        else dispatch(orderSetupFetched(storeSetup.orderSetup));
      });
    firestore
      .collection('ProductSetup')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        if (dataArray.length > 0) dispatch(productSetupFetched(dataArray[0]));
        else dispatch(productSetupFetched(storeSetup.productSetup));
      });
  };
}

export function saveOrderSetup(orderSetup) {
  return (dispatch) => {
    if (orderSetup._id) {
      firestore
        .collection('OrderSetup')
        .doc(orderSetup._id)
        .set(orderSetup)
        .then(() => {});
    } else {
      firestore
        .collection('OrderSetup')
        .add(orderSetup)
        .then(() => {});
    }
  };
}

export function saveProductSetup(productSetup) {
  return (dispatch) => {
    if (productSetup._id) {
      firestore
        .collection('ProductSetup')
        .doc(productSetup._id)
        .set(productSetup)
        .then(() => {});
    } else {
      firestore
        .collection('ProductSetup')
        .add(productSetup)
        .then(() => {});
    }
  };
}

function productSetupFetched(productSetup) {
  return {
    type: ActionTypes.ProductSetupSaved,
    productSetup,
  };
}

function orderSetupFetched(orderSetup) {
  return {
    type: ActionTypes.OrderSetupSaved,
    orderSetup,
  };
}
