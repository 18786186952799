import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchVouchers:
      return { ...state, vouchers: payload.vouchers };
    case ActionTypes.VoucherCountFetched:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.FetchExpiredVouchers:
      return { ...state, vouchersExpired: payload.vouchersExpired };
    case ActionTypes.VoucherCountExpiredFetched:
      return { ...state, totalCountExpired: payload.totalCountExpired };
    case ActionTypes.FetchStore:
      return { ...state, store: payload.store };
    default:
      return state;
  }
};
