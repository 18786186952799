import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

class Logo extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.store !== this.props.store) {
      window.intercomSettings['Store'] = this.props.store.name;
      let storeName = this.props.store.name;
      storeName = storeName.replace(' ', '');
      window.intercomSettings.name = this.props.store.name;
      window.Intercom('update');
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.store && nextProps.store && nextProps.store.logo)
      return true;
    else return false;
  }

  render() {
    return (
      <div className='navbar-header '>
        <Link
          to='/restaurants/dashboard'
          className='navbar-brand'
          style={{ paddingLeft: '0px' }}
        >
          {this.props.store && this.props.store.logo && (
            <img className='logo' src={this.props.store.logo} alt='logo' />
          )}
        </Link>
      </div>
    );
  }
}

Logo.propTypes = {
  storeId: PropTypes.any,
};

export default Logo;
