const actionTypes = {
  FetchUsers: 'FETCH_USERS',
  FetchDrivers: 'FETCH_DRIVERS',
  FetchDriverCount: 'FETCH_DRIVER_COUNT',
  DriverAdded: 'DRIVER_ADDED',
  DriverStatusChanged: 'DRIVER_STATUS_CHANGED',
  FetchDriverSelected: 'FETCH_DRIVER_SELECTED',
  FetchDriverStatsByDay: 'FETCH_DRIVER_STATS-BY_DAY',
  FetchDriverStats: 'FETCH_DRIVER_STATS',
  FetchDeliveryHistory: 'FETCH_DELIVERY_HISTORY',
};

export default actionTypes;
