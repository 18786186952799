import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.css';

class PreviewWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMainTab: 0,
      openTabIng: 0,
      openTabExtra: 0,
      file: '',
      imagePreviewUrl: '',
      menuItem: {
        name: '',
        photo: '',
        selectedSizes: [],
        selectedIngredients: [],
        selectedExtraIngredients: [],
      },
    };
  }

  componentDidMount() {
    const myCategories = this.props.ingredientCategories.filter((item) => {
      return item.categoryId === this.props.selectedCategory;
    });
    myCategories.map((item) => {
      if (this.props.menuItem.selectedIngredients) {
        item.ingredientList = this.props.menuItem.selectedIngredients.filter(
          function (obj) {
            return obj.ingredientCategory === item._id;
          }
        );
      }
      if (this.props.menuItem.selectedExtraIngredients) {
        item.extraList = this.props.menuItem.selectedExtraIngredients.filter(
          function (obj) {
            return obj.ingredientCategory === item._id;
          }
        );
      }
      return item;
    });
    this.setState({
      ingredientCategories: myCategories,
      ingredients: this.props.ingredients,
      menuItem: { ...this.props.menuItem },
    });
    setTimeout(this.readImage(), 500);
  }

  componentWillReceiveProps(props) {
    const myCategories = props.ingredientCategories.filter((item) => {
      return item.categoryId === props.selectedCategory;
    });
    myCategories.map((item) => {
      if (props.menuItem.selectedIngredients) {
        item.ingredientList = props.menuItem.selectedIngredients.filter(
          function (obj) {
            return obj.ingredientCategory === item._id;
          }
        );
      }
      if (props.menuItem.selectedExtraIngredients) {
        item.extraList = props.menuItem.selectedExtraIngredients.filter(
          function (obj) {
            return obj.ingredientCategory === item._id;
          }
        );
      }
      return item;
    });
    this.setState({
      ingredientCategories: myCategories,
      ingredients: props.ingredients,
      menuItem: { ...props.menuItem },
    });
    setTimeout(this.readImage(), 500);
  }

  readImage = () => {
    let reader = new FileReader();
    let file = this.props.menuItem.photo;
    if (this.props.menuItem && this.props.menuItem.photo) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  changeTabIndex = (index) => {
    this.setState({ openMainTab: index });
  };

  changeTabIng = (index) => {
    this.setState({ openTabIng: index });
  };

  changeTabExtra = (index) => {
    this.setState({ openTabExtra: index });
  };

  render() {
    const mySize = this.state.menuItem.selectedSizes.filter(function (obj) {
      return obj.isFeaturedSize === true;
    });
    const style14 = { float: 'right' };
    const style15 = { float: 'left' };
    return (
      <div>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='col-lg-5 col-md-5 col-xs-12 col-sm-5 proboxspace'>
                <div>
                  {this.state.imagePreviewUrl && (
                    <img
                      className='proimg'
                      alt=''
                      src={this.state.imagePreviewUrl}
                    />
                  )}
                  {this.state.menuItem &&
                    this.state.menuItem.urlS3 &&
                    !this.state.imagePreviewUrl && (
                      <img
                        className='proimg'
                        alt=''
                        src={this.state.menuItem.urlS3}
                      />
                    )}
                </div>
                <div className='hprodet'>
                  {mySize && mySize[0] && (
                    <div className='hpropri'>
                      {mySize[0].isAbbreviationShow
                        ? `${mySize[0].name[0]}`
                        : ''}
                      {` ${mySize[0].price}`}
                    </div>
                  )}
                  <div className='hproname'>{this.state.menuItem.name}</div>
                </div>
              </div>
              <div className='clear' />
              <div className='panel panel-default panel-table'>
                <div className='panel-heading'>Sizes</div>
                <div className='panel-body'>
                  <table className='table'>
                    <tbody>
                      {this.state.menuItem &&
                        this.state.menuItem.selectedSizes &&
                        this.state.menuItem.selectedSizes.map((item, index) => (
                          <tr key={index}>
                            <td className='width50'>{item.name}</td>
                            <td className='number'>{`${this.props.currency}${item.price}`}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='col-md-7'>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label>Category</label>
                    {this.props.categories &&
                      this.props.categories.map((category, index) => (
                        <div key={index} className='be-radio inline ml0 padd'>
                          <input
                            defaultChecked={category.isSelected}
                            disabled
                            type='radio'
                            value={category._id}
                            name='catsel'
                            id={`rad1${index}`}
                          />
                          <label htmlFor={`rad1${index}`}>
                            {category.catName}
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group xs-pt-10'>
                    <label>Description</label>
                    <p>{this.state.menuItem.description}</p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='panel panel-default'>
                    <div className='tab-container'>
                      <ul className='nav nav-tabs nav-tabs-warning'>
                        <li
                          onClick={() => this.changeTabIndex(0)}
                          className={
                            this.state.openMainTab === 0 ? 'active' : ''
                          }
                        >
                          <a data-toggle='tab'>Ingredients</a>
                        </li>
                        <li
                          onClick={() => this.changeTabIndex(1)}
                          className={
                            this.state.openMainTab === 1 ? 'active' : ''
                          }
                        >
                          <a data-toggle='tab'>Extras</a>
                        </li>
                      </ul>
                      <div
                        className='tab-content'
                        style={{ padding: '20px 0' }}
                      >
                        <div
                          className={
                            this.state.openMainTab === 0
                              ? 'tab-pane active cont'
                              : 'tab-pane cont'
                          }
                        >
                          <div className='tab-container'>
                            {this.state && this.state.ingredientCategories && (
                              <ul className='nav nav-tabs nav-tabs-warning'>
                                {this.state.ingredientCategories.map(
                                  (item, index) => {
                                    return (
                                      <React.Fragment>
                                        {item.ingredientList.length > 0 && (
                                          <li
                                            onClick={() =>
                                              this.changeTabIng(index)
                                            }
                                            key={index}
                                            className={
                                              this.state.openTabIng === index
                                                ? 'active'
                                                : ''
                                            }
                                          >
                                            <a data-toggle='tab'>{item.name}</a>
                                          </li>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </ul>
                            )}
                            {this.state && this.state.ingredientCategories && (
                              <div className='tab-content'>
                                {this.state.ingredientCategories.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={
                                        this.state.openTabIng === index
                                          ? 'tab-pane active cont'
                                          : 'tab-pane cont'
                                      }
                                    >
                                      {this.state.ingredientCategories[index] &&
                                        this.state.ingredientCategories[index]
                                          .ingredientList && (
                                          <div className=' '>
                                            {this.state.ingredientCategories[
                                              index
                                            ].ingredientList &&
                                              this.state.ingredientCategories[
                                                index
                                              ].ingredientList.map((m, i) => (
                                                <div
                                                  key={i}
                                                  className='be-checkbox'
                                                >
                                                  <label
                                                    htmlFor={`check${i}${index}`}
                                                  >
                                                    <span className='tebcheck'>
                                                      {m.ingredientName}
                                                    </span>
                                                    <span className='tebdo'>{`${m.price}`}</span>
                                                  </label>
                                                </div>
                                              ))}
                                          </div>
                                        )}
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={
                            this.state.openMainTab === 1
                              ? 'tab-pane active cont'
                              : 'tab-pane cont'
                          }
                        >
                          <div className='tab-container'>
                            {this.state && this.state.ingredientCategories && (
                              <ul className='nav nav-tabs nav-tabs-warning'>
                                {this.state.ingredientCategories.map(
                                  (item, index) => (
                                    <React.Fragment>
                                      {item.extraList.length > 0 && (
                                        <li
                                          onClick={() =>
                                            this.changeTabExtra(index)
                                          }
                                          key={index}
                                          className={
                                            this.state.openTabExtra === index
                                              ? 'active'
                                              : ''
                                          }
                                        >
                                          <a data-toggle='tab'>{item.name}</a>
                                        </li>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </ul>
                            )}
                            {this.state && this.state.ingredientCategories && (
                              <div className='tab-content'>
                                {this.state.ingredientCategories.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className={
                                        this.state.openTabExtra === index
                                          ? 'tab-pane active cont'
                                          : 'tab-pane cont'
                                      }
                                    >
                                      {this.state.ingredientCategories[index] &&
                                        this.state.ingredientCategories[index]
                                          .extraList && (
                                          <div className=' '>
                                            {this.state.ingredientCategories[
                                              index
                                            ].extraList &&
                                              this.state.ingredientCategories[
                                                index
                                              ].extraList.map((m, i) => (
                                                <div
                                                  key={i}
                                                  className='be-checkbox'
                                                >
                                                  <label
                                                    htmlFor={`check${i}${index}`}
                                                  >
                                                    <span className='tebcheck'>
                                                      {m.ingredientName}
                                                    </span>
                                                    <span className='tebdo'>{`${this.props.currency}${m.price}`}</span>
                                                  </label>
                                                </div>
                                              ))}
                                          </div>
                                        )}
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <button
              style={style15}
              onClick={this.props.previousPage}
              className='btn btn-default btn-space'
            >
              <i className='icon mdi mdi-chevron-left' />
              <i className='icon mdi mdi-chevron-left' /> Previous
            </button>
            <button
              style={style14}
              onClick={this.props.addMenuItemHandler}
              data-wizard='#wizard1'
              className='btn btn-primary btn-space wizard-next'
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PreviewWizard.propTypes = {
  nextPage: PropTypes.any,
  previousPage: PropTypes.any,
  menuItem: PropTypes.any,
  ingredientCategories: PropTypes.any,
  ingredients: PropTypes.any,
  selectedCategory: PropTypes.any,
  addMenuItemHandler: PropTypes.any,
  categories: PropTypes.any,
};

export default PreviewWizard;
