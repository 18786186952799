import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import SpecialList from '../../components/SpecialList';
import SpecialForm from '../../components/SpecialForm';
import {
  addSpecial,
  getSpecials,
  getSpecialsCount,
  deleteSpecial,
  getAllSpecials,
} from './actions';
import {
  getCategories,
  getSizes,
  getMenuItems,
} from '../../containers/Menu/actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Specials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModeOn: false,
      specialForEdit: {},
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/menu/specials'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getSpecialsHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getSpecialsCountHandler(doc.data().storeId);
                    this.props.getAllSpecialsHandler(doc.data().storeId);
                    this.props.getCategoriesHandler(doc.data().storeId);
                    this.props.getSizesHandler(doc.data().storeId);
                    this.props.getMenuItemsHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      specials: newProps.special.specials,
      totalCount: newProps.special.totalCount,
      categories: newProps.menu.categories,
      sizes: newProps.menu.allSizes,
      menuItems: newProps.menu.menuItems,
      allSpecials: newProps.special.allSpecials,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        specials: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getSpecialsHandler(
          this.state.storeId,
          this.state.pagination
        );
      }
    );
  };

  addSpecialHandler = (special) => {
    special.storeId = this.state.storeId;
    special.restaurantId = this.state.restaurantId;
    this.props.addSpecialHandler(special);
    this.setState({ ...this.state, isEditModeOn: false, specialForEdit: {} });
  };

  deleteSpecialHandler = (special) => {
    this.props.deleteSpecialHandler(special);
  };

  setSpecialForEdit = (special) => {
    this.setState({
      ...this.state,
      isEditModeOn: true,
      specialForEdit: special,
    });
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <SpecialList
            specials={this.state.specials}
            pagination={this.state.pagination}
            totalCount={this.state.totalCount}
            handlePageChange={this.handlePageChange}
            deleteSpecialHandler={this.deleteSpecialHandler}
            setSpecialForEdit={this.setSpecialForEdit}
            timeZone={this.state.timeZone}
            currency={currency}
            allSpecials={this.state.allSpecials}
          />
          <SpecialForm
            specials={this.state.specials}
            addSpecialHandler={this.addSpecialHandler}
            categories={this.state.categories}
            sizes={this.state.sizes}
            menuItems={this.state.menuItems}
            isEditModeOn={this.state.isEditModeOn}
            specialForEdit={this.state.specialForEdit}
            timeZone={this.state.timeZone}
            currency={currency}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    special: state.specialReducer,
    menu: state.menuReducer,
    order: state.orderReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSpecialsHandler: (storeId, pagination) =>
      dispatch(getSpecials(storeId, pagination)),
    getSpecialsCountHandler: (storeId) => dispatch(getSpecialsCount(storeId)),
    getAllSpecialsHandler: (storeId) => dispatch(getAllSpecials(storeId)),
    addSpecialHandler: (special) => dispatch(addSpecial(special)),
    deleteSpecialHandler: (special) => dispatch(deleteSpecial(special)),
    getCategoriesHandler: (storeId) => dispatch(getCategories(storeId)),
    getSizesHandler: (storeId) => dispatch(getSizes(storeId)),
    getMenuItemsHandler: (storeId) => dispatch(getMenuItems(storeId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Specials);
