import { firestore } from '../../firebase';
import moment from 'moment-timezone';
import ActionTypes from './constants';

export function getTopCustomers(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('Users')
      .where('storeId', '==', storeId)
      .orderBy('totalRevenue', 'desc')
      .limit(5);
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(topCustomerFetched(dataArray));
    });
  };
}

export function getSystemStartDate(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .orderBy('dateTimestamp')
      .limit(1);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(systemStartDateFetched(dataArray));
    });
  };
}

export function getCustomerRetention(storeId, year) {
  return (dispatch) => {
    let ref = firestore
      .collection('CustomerRetention')
      .where('storeId', '==', storeId)
      .where('year', '==', year);
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(customerRetentionFetched(dataArray));
    });
  };
}

export function fetchUserOrders(storeId, userId) {
  return (dispatch) => {
    let ref = firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('userId', '==', userId)
      .orderBy('dateTimestamp', 'desc');
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(userOrdersFetched(dataArray));
    });
  };
}

export function getCustomers(storeId, pagination, filterString) {
  return (dispatch) => {
    dispatch(customersFetched(undefined));
    if (!filterString || filterString === null) {
      let currentRef = firestore
        .collection('Users')
        .where('storeId', '==', storeId)
        .orderBy('totalRevenue', 'desc');
      currentRef.get().then((documentSnapshots) => {
        let lastVisible =
          documentSnapshots.docs[
            (pagination.currentPage - 1) * pagination.pageSize - 1
          ];
        if (pagination.currentPage !== 1) {
          let nextRef = firestore
            .collection('Users')
            .where('storeId', '==', storeId)
            .orderBy('totalRevenue', 'desc')
            .startAfter(lastVisible)
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(customersFetched(dataArray));
          });
        } else {
          let nextRef = firestore
            .collection('Users')
            .where('storeId', '==', storeId)
            .orderBy('totalRevenue', 'desc')
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(customersFetched(dataArray));
          });
        }
      });
    } else {
      let currentRef = firestore
        .collection('Users')
        .where('storeId', '==', storeId)
        .where('keywords', 'array-contains', filterString)
        .orderBy('totalRevenue', 'desc');
      currentRef.get().then((documentSnapshots) => {
        let lastVisible =
          documentSnapshots.docs[
            (pagination.currentPage - 1) * pagination.pageSize - 1
          ];
        if (pagination.currentPage !== 1) {
          let nextRef = firestore
            .collection('Users')
            .where('storeId', '==', storeId)
            .where('keywords', 'array-contains', filterString)
            .orderBy('totalRevenue', 'desc')
            .startAfter(lastVisible)
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(customersFetched(dataArray));
          });
        } else {
          let nextRef = firestore
            .collection('Users')
            .where('storeId', '==', storeId)
            .where('keywords', 'array-contains', filterString)
            .orderBy('totalRevenue', 'desc')
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(customersFetched(dataArray));
          });
        }
      });
    }
  };
}

export function getCustomerCount(storeId, filterString) {
  return (dispatch) => {
    if (!filterString || filterString === null) {
      let ref = firestore.collection('Users').where('storeId', '==', storeId);
      ref.onSnapshot((querySnapshot) => {
        dispatch(customerCountFetched(querySnapshot.docs.length));
      });
    } else {
      let ref = firestore
        .collection('Users')
        .where('storeId', '==', storeId)
        .where('keywords', 'array-contains', filterString);
      ref.onSnapshot((querySnapshot) => {
        dispatch(customerCountFetched(querySnapshot.docs.length));
      });
    }
  };
}

export function getCustomerCountPickup(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('Users')
      .where('totalDeliveryOrders', '==', 0)
      .where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(customerCountPickupFetched(querySnapshot.docs.length));
    });
  };
}

export function getCustomerCountDelivery(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('Users')
      .where('totalPickupOrders', '==', 0)
      .where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(customerCountDeliveryFetched(querySnapshot.docs.length));
    });
  };
}

function topCustomerFetched(topCustomers) {
  return {
    type: ActionTypes.FetchTopCustomers,
    topCustomers,
  };
}

function userOrdersFetched(userOrders) {
  return {
    type: ActionTypes.FetchUserOrders,
    userOrders,
  };
}

function customersFetched(customers) {
  return {
    type: ActionTypes.FetchCustomers,
    customers,
  };
}

function customerCountFetched(totalCount) {
  return {
    type: ActionTypes.CustomerCountFetched,
    totalCount,
  };
}

function customerCountPickupFetched(totalCountPickupOnly) {
  return {
    type: ActionTypes.CustomerCountPickupFetched,
    totalCountPickupOnly,
  };
}

function customerCountDeliveryFetched(totalCountDeliveryOnly) {
  return {
    type: ActionTypes.CustomerCountDeliveryFetched,
    totalCountDeliveryOnly,
  };
}

function customerRetentionFetched(customerRetention) {
  return {
    type: ActionTypes.FetchCustomerRetention,
    customerRetention,
  };
}

function systemStartDateFetched(orders) {
  let yearList = [];
  if (orders.length > 0) {
    let oDate = moment(orders[0].date, 'YYYY-MM-DD HH:mm');
    while (oDate.isSameOrBefore(moment(), 'year')) {
      yearList.push(oDate.format('YYYY'));
      oDate.add(1, 'year');
    }
  } else {
    yearList = [moment().format('YYYY')];
  }
  return {
    type: ActionTypes.FetchSystemStartDate,
    yearList,
  };
}
