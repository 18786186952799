const actionTypes = {
  FetchReviews: 'FETCH_REVIEW',
  FetchReviewCount: 'FETCH_REVIEW_COUNT',
  FetchRecentReviews: 'FETCH_RECENT_REVIEW',
  FetchUser: 'FETCH_USER',
  FetchReviewStats: 'FETCH_REVIEW_STATS',
  FetchStore: 'FETCH_STORE',
};

export default actionTypes;
