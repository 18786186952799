import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchSuburbs:
      return {
        ...state,
        suburbs: payload.suburbs,
        pagination: payload.pagination,
      };
    case ActionTypes.FetchAllSuburbs:
      return { ...state, allSuburbs: payload.allSuburbs };
    case ActionTypes.FetchAllUnsupportedSuburbs:
      return { ...state, unSupportedSuburbs: payload.allSuburbs };
    case ActionTypes.SuburbCountFetched:
      return { ...state, totalCount: payload.totalCount };
    default:
      return state;
  }
};
