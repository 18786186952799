import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { auth, firestore } from '../../firebase';
import avatar from '../../assets/images/avatar.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import moment from 'moment-timezone';
import {
  convertToMomentDate,
  getTimestampEndOfTheDay,
  getTimestampForDateStart,
  getTimestampForDateEnd,
} from '../../helpers/timeHelper';
import Layout from '../../containers/Layout';
import DeliveryHistory from '../../components/DeliveryHistory';
import DeliveryByDay from '../../components/DeliveryByDay';
import {
  getDriver,
  getDriverStats,
  getDriverStatsByDay,
  getDeliveryHistory,
} from '../../containers/Drivers/actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Driver extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let keyObj =
      this.props.location && this.props.location.search
        ? queryString.parse(this.props.location.search)
        : { key: '' };
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/delivery/driver'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                    driverId: keyObj.key,
                  },
                  () => {
                    let startDate = getTimestampForDateStart(
                      moment().add(-14, 'day'),
                      this.state.timeZone,
                      'YYYY-MM-DD HH:mm'
                    );
                    let endDate = getTimestampEndOfTheDay(doc.data().timeZone);
                    let startDate1 = getTimestampForDateStart(
                      moment().add(-7, 'day'),
                      this.state.timeZone,
                      'YYYY-MM-DD HH:mm'
                    );
                    this.props.getDriverHandler(keyObj.key);
                    this.props.getDriverStatsHandler(
                      this.state.storeId,
                      keyObj.key
                    );
                    this.props.getDeliveryHistoryHandler(
                      this.state.storeId,
                      keyObj.key,
                      startDate,
                      endDate
                    );
                    let requestObj = {
                      storeId: this.state.storeId,
                      restaurantId: this.state.restaurantId,
                      isResultExpectedForRestaurant: false,
                      startDateTimestamp: startDate1,
                      endDateTimestamp: endDate,
                      driverId: this.state.driverId,
                    };
                    this.props.getDriverStatsByDayHandler(requestObj);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      driver: newProps.driver.driver,
      driverStats: newProps.driver.driverStats,
      driverStatsByDay: newProps.driver.driverStatsByDay,
      deliveryHistory: newProps.driver.deliveryHistory,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  getDriverStatsByDayHandler = (driverId, startDate, endDate) => {
    let endDate1 = getTimestampForDateEnd(
      endDate,
      this.state.timeZone,
      'YYYY-MM-DD HH:mm'
    );
    let startDate1 = getTimestampForDateStart(
      startDate,
      this.state.timeZone,
      'YYYY-MM-DD HH:mm'
    );
    let requestObj = {
      storeId: this.state.storeId,
      restaurantId: this.state.restaurantId,
      isResultExpectedForRestaurant: false,
      startDateTimestamp: startDate1,
      endDateTimestamp: endDate1,
      driverId: driverId,
    };
    this.props.getDriverStatsByDayHandler(requestObj);
  };

  getDeliveryHistoryHandler = (driverId, startDate, endDate) => {
    let endDate1 = getTimestampForDateEnd(
      endDate,
      this.state.timeZone,
      'YYYY-MM-DD HH:mm'
    );
    let startDate1 = getTimestampForDateStart(
      startDate,
      this.state.timeZone,
      'YYYY-MM-DD HH:mm'
    );
    this.props.getDeliveryHistoryHandler(
      this.state.storeId,
      driverId,
      startDate1,
      endDate1
    );
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <div className='user-display'>
                <div className='user-display-bottom'>
                  <div style={{ minHeight: '30px' }} className='viewdriver1'>
                    <div className='user-display-avatar'>
                      <img src={avatar} alt='Avatar' />
                    </div>
                    <div className='user-display-info'>
                      <div className='name'>
                        {this.state.driver &&
                        this.state.driver.firstName &&
                        this.state.driver.lastName
                          ? `${this.state.driver.firstName} ${this.state.driver.lastName}`
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className='row user-display-details'>
                    <div className='col-md-3 xs-mb-20'>
                      <div className='title'>Date Joined</div>
                      <div className='counter'>
                        {this.state.driver && this.state.driver.date
                          ? convertToMomentDate(
                              this.state.driver.date,
                              'YYYY-MM-DD HH:mm',
                              this.state.timeZone
                            ).format('MM/DD/YYYY')
                          : ''}
                      </div>
                    </div>
                    <div className='col-md-3 xs-mb-20'>
                      <div className='title'>Mobile</div>
                      <div className='counter'>
                        {this.state.driver && this.state.driver.mobileNumber
                          ? this.state.driver.mobileNumber
                          : ''}
                      </div>
                    </div>
                    <div className='col-md-3 xs-mb-20'>
                      <div className='title'>No of Deliveries</div>
                      <div className='counter'>
                        {this.state.driverStats &&
                        this.state.driverStats.totalDeliveryOrders
                          ? this.state.driverStats.totalDeliveryOrders
                          : 'N/A'}
                      </div>
                    </div>
                    <div className='col-md-3 xs-mb-20'>
                      <div className='title'>Avg Delivery time</div>
                      <div className='counter'>
                        {this.state.driverStats &&
                        this.state.driverStats.averageDeliveryTime
                          ? this.state.driverStats.averageDeliveryTime
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                  <DeliveryByDay
                    driverId={this.state.driverId}
                    getDriverStatsByDayHandler={this.getDriverStatsByDayHandler}
                    driverStatsByDay={this.state.driverStatsByDay}
                    timeZone={this.state.timeZone}
                  />
                </div>
              </div>
            </div>
            <DeliveryHistory
              driverId={this.state.driverId}
              deliveryHistory={this.state.deliveryHistory}
              menuItems={this.state.menuItems}
              getDeliveryHistoryHandler={this.getDeliveryHistoryHandler}
              timeZone={this.state.timeZone}
              currency={currency}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

Driver.propTypes = {};

function mapStateToProps(state) {
  return {
    driver: state.driverReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDriverHandler: (driverId) => dispatch(getDriver(driverId)),
    getDriverStatsHandler: (storeId, driverId) =>
      dispatch(getDriverStats(storeId, driverId)),
    getDriverStatsByDayHandler: (requestObj) =>
      dispatch(getDriverStatsByDay(requestObj)),
    getDeliveryHistoryHandler: (storeId, driverId, startDate, endDate) =>
      dispatch(getDeliveryHistory(storeId, driverId, startDate, endDate)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Driver));
