import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getAllShifts(storeId) {
  return (dispatch) => {
    firestore
      .collection('Shifts')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(allShiftsFetched(dataArray));
      });
  };
}

export function getShiftsCount(storeId) {
  return (dispatch) => {
    let ref = firestore.collection('Shifts').where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(shiftsCountFetched(querySnapshot.docs.length));
    });
  };
}

export function getHighRevenueShifts(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('Shifts')
      .where('storeId', '==', storeId)
      .orderBy('totalRevenue', 'desc')
      .limit(5);
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(highRevenueShiftsFetched(dataArray));
    });
  };
}

export function getShifts(storeId, pagination) {
  return (dispatch) => {
    firestore
      .collection('Shifts')
      .where('storeId', '==', storeId)
      .orderBy('dateTimestamp', 'desc')
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(shiftsFetched(dataArray));
      });
    // let currentRef = firestore
    //   .collection('Shifts')
    //   .where('storeId', '==', storeId)
    //   .orderBy('dateTimestamp', 'desc');
    // currentRef.get().then((documentSnapshots) => {
    //   let lastVisible =
    //     documentSnapshots.docs[
    //       (pagination.currentPage - 1) * pagination.pageSize - 1
    //     ];
    //   if (pagination.currentPage !== 1) {
    //     let nextRef = firestore
    //       .collection('Shifts')
    //       .where('storeId', '==', storeId)
    //       .orderBy('dateTimestamp', 'desc')
    //       .startAfter(lastVisible)
    //       .limit(pagination.pageSize);
    //     nextRef.onSnapshot((querySnapshot) => {
    //       var dataArray = [];
    //       querySnapshot.forEach((doc) => {
    //         dataArray.push({ _id: doc.id, ...doc.data() });
    //       });
    //       dispatch(shiftsFetched(dataArray));
    //     });
    //   } else {
    //     let nextRef = firestore
    //       .collection('Shifts')
    //       .where('storeId', '==', storeId)
    //       .orderBy('dateTimestamp', 'desc')
    //       .limit(pagination.pageSize);
    //     nextRef.onSnapshot((querySnapshot) => {
    //       var dataArray = [];
    //       querySnapshot.forEach((doc) => {
    //         dataArray.push({ _id: doc.id, ...doc.data() });
    //       });
    //       dispatch(shiftsFetched(dataArray));
    //     });
    //   }
    // });
  };
}

export function addShift(shift) {
  return (_dispatch) => {
    if (shift._id && shift.deliveryType === '1') {
      firestore
        .collection('Shifts')
        .doc(shift._id)
        .update({
          day: shift.day,
          openingStart: shift.openingStart,
          openingStartDisplay: shift.openingStartDisplay,
          openingEnd: shift.openingEnd,
          openingEndDisplay: shift.openingEndDisplay,
          deliveryStart: shift.deliveryStart,
          deliveryStartDisplay: shift.deliveryStartDisplay,
          deliveryEnd: shift.deliveryEnd,
          deliveryEndDisplay: shift.deliveryEndDisplay,
          restaurantStart: shift.restaurantStart,
          restaurantStartDisplay: shift.restaurantStartDisplay,
          restaurantEnd: shift.restaurantEnd,
          restaurantEndDisplay: shift.restaurantEndDisplay,
          pickup24: shift.pickup24,
          delivery24: shift.delivery24,
          restaurant24: shift.restaurant24,
          noPickup: shift.noPickup,
          noDelivery: shift.noDelivery,
          noDineIn: shift.noDineIn,
        })
        .then(() => {});
    } else if (shift._id && shift.deliveryType === '2') {
      firestore
        .collection('Shifts')
        .doc(shift._id)
        .update({
          day: shift.day,
          deliveryType: shift.deliveryType,
          cutOff: shift.cutOff,
          cutOffDisplay: shift.cutOffDisplay,
          slots: shift.slots,
          openingStart: shift.openingStart,
          openingStartDisplay: shift.openingStartDisplay,
          openingEnd: shift.openingEnd,
          openingEndDisplay: shift.openingEndDisplay,
          restaurantStart: shift.restaurantStart,
          restaurantStartDisplay: shift.restaurantStartDisplay,
          restaurantEnd: shift.restaurantEnd,
          restaurantEndDisplay: shift.restaurantEndDisplay,
          pickup24: shift.pickup24,
          delivery24: shift.delivery24,
          restaurant24: shift.restaurant24,
          noPickup: shift.noPickup,
          noDelivery: shift.noDelivery,
          noDineIn: shift.noDineIn,
        })
        .then(() => {});
    } else {
      firestore
        .collection('Shifts')
        .add(shift)
        .then(() => {});
    }
  };
}

export function deleteShift(shift) {
  return (dispatch) => {
    firestore
      .collection('Shifts')
      .doc(shift._id)
      .delete()
      .then(() => {});
  };
}

function allShiftsFetched(allShifts) {
  return {
    type: ActionTypes.FetchAllShifts,
    allShifts,
  };
}

function shiftsFetched(shifts) {
  return {
    type: ActionTypes.FetchShifts,
    shifts,
  };
}

function highRevenueShiftsFetched(hRevenuehifts) {
  return {
    type: ActionTypes.HighRevenueShifts,
    hRevenuehifts,
  };
}

function shiftsCountFetched(totalCount) {
  return {
    type: ActionTypes.ShiftCountFetched,
    totalCount,
  };
}
