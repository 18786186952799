import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import CustomerList from '../../components/CustomerList';
import TopCustomerList from '../../components/TopCustomerList';
import CustomerPerRegion from '../../components/CustomerPerRegion';
import CustomerRetention from '../../components/CustomerRetention';
import { getCurrentFormattedMomentDate } from '../../helpers/timeHelper';
import {
  getCustomers,
  getCustomerCount,
  getTopCustomers,
  fetchUserOrders,
  getCustomerRetention,
  getSystemStartDate,
} from './actions';
import { getAllSuburbs } from '../../containers/DeliveryAreas/actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
      filterString: null,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/marketing/customers'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getTopCustomersHandler(this.state.storeId);
                    this.props.getAllSuburbsHandler(this.state.storeId);
                    this.props.getCustomersHandler(
                      this.state.storeId,
                      this.state.pagination,
                      this.state.filterString
                    );
                    this.props.getCustomerCountHandler(
                      this.state.storeId,
                      this.state.filterString
                    );
                    this.props.getSystemStartDateHandler(this.state.storeId);
                    this.props.getCustomerRetentionHandler(
                      this.state.storeId,
                      getCurrentFormattedMomentDate(this.state.timeZone, 'YYYY')
                    );
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      topCustomers: newProps.customer.topCustomers,
      allSuburbs: newProps.suburb.allSuburbs,
      customers: newProps.customer.customers,
      totalCount: newProps.customer.totalCount,
      userOrders: newProps.customer.userOrders,
      yearList: newProps.customer.yearList,
      customerRetention: newProps.customer.customerRetention,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        customers: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getCustomersHandler(
          this.state.storeId,
          this.state.pagination,
          this.state.filterString
        );
      }
    );
  };

  filterCustomers = (filterString) => {
    this.setState(
      {
        customers: undefined,
        filterString: filterString,
      },
      () => {
        this.props.getCustomersHandler(
          this.state.storeId,
          this.state.pagination,
          filterString
        );
        this.props.getCustomerCountHandler(this.state.storeId, filterString);
      }
    );
  };

  fetchUserOrdersHandler = (userId) => {
    this.setState(
      {
        userOrders: [],
      },
      () => {
        this.props.fetchUserOrdersHandler(this.state.storeId, userId);
      }
    );
  };

  getCustomerRetentionHandler = (year) => {
    this.props.getCustomerRetentionHandler(this.state.storeId, year);
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    let reasons =
      this.props.layout && this.props.layout.reasons
        ? this.props.layout.reasons
        : [];
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <CustomerPerRegion allSuburbs={this.state.allSuburbs} />
            <TopCustomerList
              topCustomers={this.state.topCustomers}
              fetchUserOrdersHandler={this.fetchUserOrdersHandler}
              userOrders={this.state.userOrders}
              timeZone={this.state.timeZone}
              storeId={this.state.storeId}
              currency={currency}
              reasons={reasons}
            />
          </div>
          <CustomerRetention
            customerRetention={this.state.customerRetention}
            yearList={this.state.yearList}
            getCustomerRetentionHandler={this.getCustomerRetentionHandler}
            timeZone={this.state.timeZone}
          />
          <CustomerList
            customers={this.state.customers}
            totalCount={this.state.totalCount}
            handlePageChange={this.handlePageChange}
            filterCustomers={this.filterCustomers}
            pagination={this.state.pagination}
            fetchUserOrdersHandler={this.fetchUserOrdersHandler}
            userOrders={this.state.userOrders}
            timeZone={this.state.timeZone}
            storeId={this.state.storeId}
            currency={currency}
            reasons={reasons}
          />
        </div>
      </Layout>
    );
  }
}

Customers.propTypes = {};

function mapStateToProps(state) {
  return {
    customer: state.customerReducer,
    suburb: state.suburbReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTopCustomersHandler: (storeId) => dispatch(getTopCustomers(storeId)),
    getAllSuburbsHandler: (storeId) => dispatch(getAllSuburbs(storeId)),
    getCustomersHandler: (storeId, pagination, filterString) =>
      dispatch(getCustomers(storeId, pagination, filterString)),
    getCustomerCountHandler: (storeId, filterString) =>
      dispatch(getCustomerCount(storeId, filterString)),
    fetchUserOrdersHandler: (storeId, userId) =>
      dispatch(fetchUserOrders(storeId, userId)),
    getCustomerRetentionHandler: (storeId, year) =>
      dispatch(getCustomerRetention(storeId, year)),
    getSystemStartDateHandler: (storeId) =>
      dispatch(getSystemStartDate(storeId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
