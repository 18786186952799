import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import Layout from '../../containers/Layout';
import { auth, firestore } from '../../firebase';
import { nextOrderConfig } from '../../configurations/storeSetup';

import {
  getStore,
  getStoreBillings,
  getCreditCardProcessingFees,
  getStoreBillingCode,
} from './actions';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storePricingType: '1',
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/payment/subscription'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.props.getStoreHandler(doc.data().storeId);
                this.props.getStoreBillingsHandler(doc.data().storeId);
                this.props.getStoreBillingCodeHandler(doc.data().storeId);
                this.props.getCreditCardProcessingFeesHandler(
                  doc.data().storeId
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      store: newProps.subscription.store,
      storePricingType:
        newProps.subscription.store &&
        newProps.subscription.store.storePricingType
          ? newProps.subscription.store.storePricingType
          : '0',
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  onstoreSubscriptionTypeChanged = (e) => {
    this.setState({
      storePricingType: e.currentTarget.value,
    });
  };

  handleSubmit = () => {
    if (
      this.props.subscription &&
      this.props.subscription.activeBilling &&
      this.props.subscription.activeBilling._id
    ) {
      let ref = firestore.collection('Stores').doc(this.state.store._id);
      ref.update({ storePricingType: this.state.storePricingType }).then(() => {
        this.setState({
          isError: false,
          isSuccess: true,
          validationMessage:
            'New subscription will be applicable once current subscription ends',
        });
      });
    } else {
      fetch(`${nextOrderConfig.cloudFunctionUrl}/publishStore`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          storeId: this.state.store._id,
          storePricingType: this.state.storePricingType,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            this.setState({
              isError: false,
              isSuccess: true,
              validationMessage:
                'New subscription will be applicable once current subscription ends',
            });
          }
        });
    }
  };

  getCardImageUrl = (c) => {
    if (c === 2)
      return 'https://s3-ap-southeast-2.amazonaws.com/next-order-finance/amex.jpg';
    else if (c === 5)
      return 'https://s3-ap-southeast-2.amazonaws.com/next-order-finance/mastercard.jpg';
    else if (c === 6)
      return 'https://s3-ap-southeast-2.amazonaws.com/next-order-finance/visa.jpg';
    else return '';
  };

  getCardDes = (c) => {
    if (c === 2) return 'AMEX';
    else if (c === 5) return 'MasterCard';
    else if (c === 6) return 'Visa';
    else return '';
  };

  getCardObj = (cardType) => {
    if (
      this.props.subscription &&
      this.props.subscription.creditCardProcessingFees
    ) {
      let cardObjs = this.props.subscription.creditCardProcessingFees.filter(
        (p) => {
          return p.cardType === cardType;
        }
      );
      if (cardObjs.length > 0) {
        let card = cardObjs[0];
        if (this.props.selectedReport.isExemptedForAdditionaCardFees) {
          return `D(${card.processingFees}%) , I(${card.processingFeesInternationCard}%)`;
        } else {
          return `D(${card.processingFees}% + ${
            card.additionalOnlineProcessingCharge * 100
          }c), I(${card.processingFeesInternationCard}% + ${
            card.additionalOnlineProcessingChargeInternationCard * 100
          }c)`;
        }
      } else return '';
    }
  };

  removeAlert = () => {
    this.setState({ isSuccess: false, isError: false, validationMessage: '' });
  };

  getDisplayString = (m) => {
    return `${m.storePricingType === '1' ? 'Monthly' : 'Annual'}, From ${
      m.startDate
    } to ${m.endDate}`;
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <form className='form-horizontal'>
                <div className='panel panel-default panel-border-color panel-border-color-primary'>
                  <div className='panel-heading panel-heading-divider'>
                    Active Subscription
                    <div className='fright'>
                      <button
                        onClick={this.handleSubmit}
                        type='button'
                        className='btn btn-space btn-primary'
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className='divider1' />
                  {this.state.store && (
                    <div className='panel-body'>
                      <div className='form-group'>
                        <label className='col-md-3 col-sm-3 control-label' />
                        <div className='col-sm-6 col-md-6'>
                          {this.state.isSuccess && (
                            <div
                              role='alert'
                              className='alert alert-contrast alert-success alert-dismissible'
                            >
                              <div className='icon'>
                                <span className='mdi mdi-check' />
                              </div>
                              <div className='message'>
                                <button
                                  onClick={this.removeAlert}
                                  type='button'
                                  data-dismiss='alert'
                                  aria-label='Close'
                                  className='close'
                                >
                                  <span
                                    aria-hidden='true'
                                    className='mdi mdi-close'
                                  />
                                </button>
                                <strong>Good!</strong>
                                {this.state.validationMessage}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>Type</label>
                        <div className='col-md-9'>
                          {this.state.storePricingType === '0' && (
                            <div className='be-checkbox inline'>
                              <input
                                name='storeSubscriptionType'
                                id='type_commision'
                                type='radio'
                                value='0'
                                checked={this.state.storePricingType === '0'}
                                onChange={this.onstoreSubscriptionTypeChanged}
                              />
                              <label htmlFor='type_commision'>Commision</label>
                            </div>
                          )}
                          <div className='be-checkbox inline'>
                            <input
                              name='storeSubscriptionType'
                              id='type_monthly'
                              component='input'
                              type='radio'
                              value='1'
                              checked={this.state.storePricingType === '1'}
                              onChange={this.onstoreSubscriptionTypeChanged}
                            />
                            <label htmlFor='type_monthly'>Monthly</label>
                          </div>
                          <div className='be-checkbox inline'>
                            <input
                              name='storeSubscriptionType'
                              id='type_annual'
                              type='radio'
                              value='2'
                              checked={this.state.storePricingType === '2'}
                              onChange={this.onstoreSubscriptionTypeChanged}
                            />
                            <label htmlFor='type_annual'>Annual</label>
                          </div>
                        </div>
                      </div>
                      <div className='divider1' />
                      <div className='subscription-container'>
                        <div className='subscription-group-container'>
                          {this.props.subscription &&
                            this.props.subscription.activeBilling &&
                            this.props.subscription.activeBilling._id && (
                              <div className='subscription-inner'>
                                <div>Current subscription :</div>
                                <div>
                                  {`${
                                    this.props.subscription.activeBilling
                                      .storePricingType === '1'
                                      ? 'Monthly '
                                      : this.props.subscription.activeBilling
                                          .storePricingType === '2'
                                      ? 'Annual'
                                      : 'Quarterly '
                                  } (From ${
                                    this.props.subscription.activeBilling
                                      .startDate
                                  } To ${
                                    this.props.subscription.activeBilling
                                      .endDate
                                  } )`}
                                </div>
                              </div>
                            )}
                        </div>
                        {this.state.storePricingType === '1' && (
                          <div className='subscription-group-container'>
                            {this.props.subscription &&
                              this.props.subscription.billingCode && (
                                <div className='subscription-inner'>
                                  <div>Monthly :</div>
                                  <div>
                                    {currency}
                                    {
                                      this.props.subscription.billingCode
                                        .monthlyFee
                                    }
                                    /month
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                        {this.state.storePricingType === '2' && (
                          <div className='subscription-group-container'>
                            {this.props.subscription &&
                              this.props.subscription.billingCode && (
                                <div className='subscription-inner'>
                                  <div>Annual :</div>
                                  <div>
                                    {currency}
                                    {
                                      this.props.subscription.billingCode
                                        .monthlyFeeAnnual
                                    }
                                    /month
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

Subscription.propTypes = {};

function mapStateToProps(state) {
  return {
    subscription: state.subscriptionReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStoreHandler: (storeId) => dispatch(getStore(storeId)),
    getStoreBillingsHandler: (storeId) => dispatch(getStoreBillings(storeId)),
    getCreditCardProcessingFeesHandler: (storeId) =>
      dispatch(getCreditCardProcessingFees(storeId)),
    getStoreBillingCodeHandler: (storeId) =>
      dispatch(getStoreBillingCode(storeId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
