import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchReportOrders:
      return { ...state, reportOrders: payload.reportOrders };
    case ActionTypes.FetchReportOtherOrders:
      return { ...state, reportOtherOrders: payload.reportOtherOrders };
    case ActionTypes.FetchTextMsgs:
      return { ...state, reportTxtMsgs: payload.reportTxtMsgs };
    case ActionTypes.FetchReportReservations:
      return {
        ...state,
        reportReservations: payload.reportReservations,
      };
    default:
      return state;
  }
};
