const actionTypes = {
  FetchOrders: 'FETCH_ORDERS',
  FetchOtherOrders: 'FETCH_OTHER_ORDERS',
  FetchLastDayOrders: 'FETCH_LAST_DAY_ORDERS',
  FetchOrderHistory: 'FETCH_ORDER_HISTORY',
  FetchStoreStats: 'FETCH_STORE_STATS',
  FetchStoreStatsSuburb: 'FETCH_STORE_STATS_SUBURB',
  FetchStoreStatsCategories: 'FETCH_STORE_STATS_CATEGORIES',
  FetchReviewStats: 'FETCH_REVIEW_STATS',
  ResetStats: 'RESET_STATS',
};

export default actionTypes;
