import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import { Modal } from 'react-bootstrap';
import { convertToMomentDate } from '../../helpers/timeHelper';
import getFormattedMobileNumber from '../../helpers/getFormattedMobileNumber';
import OrderModal from '../../components/OrderModal';
import RefundModal from '../../components/RefundModal';
import InvoiceModal from '../../components/InvoiceModal';
import Spinner from '../../components/Spinner';

class CustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisplayOrderView: false,
      myOrders: [],
      isDisplayOrder: false,
      selectedOrder: {},
    };
  }

  closeRefundPopup = () => {
    this.setState({
      openRefundPopup: false,
    });
  };

  refundOrder = (order) => {
    this.setState({
      openRefundPopup: true,
      refundOrder: order,
    });
  };

  openInvoiceModal = (order) => {
    this.setState({
      renderInvoiceModal: true,
      invoiceOrder: order,
    });
  };

  closeInvoiceModal = () => {
    this.setState({
      renderInvoiceModal: false,
      invoiceOrder: {},
    });
  };

  checkOrderDateDiff = (dateTimestamp) => {
    let now = new Date();
    let currentTimestamp = now.getTime();
    let timestampDiff = currentTimestamp - dateTimestamp;
    let daysDifference = Math.floor(timestampDiff / 1000 / 60 / 60 / 24);
    if (daysDifference > 7) return false;
    return true;
  };

  renderRefundStatus = (order) => {
    if (
      (order.orderStatus === '2' ||
        order.orderStatus === '1' ||
        order.orderStatus === '25' ||
        order.orderStatus === '3' ||
        order.orderStatus === '12' ||
        order.orderStatus === '13' ||
        order.orderStatus === '4') &&
      order.paymentType === '2' &&
      order.refundStatus !== 0 &&
      order.refundStatus !== 1 &&
      order.refundStatus !== 2 &&
      order.refundStatus !== 3 &&
      this.checkOrderDateDiff(order.dateTimestamp)
    ) {
      return (
        <button
          onClick={() => this.refundOrder(order)}
          className='btn btn-space btn-success'
        >
          Refund
        </button>
      );
    } else if (order.refundStatus === 0) {
      return (
        <React.Fragment>
          <span>Refund Initiated</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 1) {
      return (
        <React.Fragment>
          <span>Refunded</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 2) {
      return (
        <React.Fragment>
          <span>Refund Failed</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 3) {
      return (
        <React.Fragment>
          <span>Refund Rejected</span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span></span>
        </React.Fragment>
      );
    }
  };

  getAvgRatingText = (rating) => {
    if (parseInt(rating, 10) === 5) {
      return 'Excellent';
    } else if (rating < 5 && rating >= 4.5) {
      return 'Very Good';
    } else if (rating < 4.5 && rating >= 4.0) {
      return 'Good';
    } else if (rating < 3.5 && rating >= 3.0) {
      return 'Average';
    } else if (rating === 0) return '';
    else {
      return 'Bad';
    }
  };

  getAvgRatingClass = (rating) => {
    if (rating >= 4.0) {
      return 'text-success';
    } else if (rating < 4 && rating >= 3) {
      return 'text-warning';
    } else if (rating === 0) return '';
    else {
      return 'text-danger';
    }
  };

  openOrderView = (userId) => {
    this.setState({ isDisplayOrderView: true }, () => {
      this.props.fetchUserOrdersHandler(userId);
    });
  };

  closeOrderView = () => {
    this.setState({ isDisplayOrderView: false, myOrders: [] });
  };

  openOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOrder: true });
  };

  closeOrderModal = () => {
    this.setState({ isDisplayOrder: false, selectedOrder: {} });
  };

  filterCustomer = (e) => {
    this.setState(
      {
        filterString: e.target.value,
      },
      () => {
        if (this.state.filterString.length > 0) {
          let valFS = this.state.filterString;
          if (!isNaN(valFS)) valFS = getFormattedMobileNumber(valFS);
          if (valFS.length > 2) this.props.filterCustomers(valFS.toLowerCase());
        } else this.props.filterCustomers(undefined);
      }
    );
  };

  render() {
    const start =
      (this.props.pagination.currentPage - 1) * this.props.pagination.pageSize +
      1;
    const end = this.props.customers
      ? (this.props.pagination.currentPage - 1) *
          this.props.pagination.pageSize +
        1 +
        this.props.customers.length -
        1
      : 0;
    return (
      <div className='row'>
        <div className='col-sm-12 col-xs-12 col-md-12'>
          <div className='panel panel-default panel-table '>
            <div className='panel-heading'> Customers </div>
            <div
              style={{ overflow: 'hidden' }}
              className='panel-body table-responsive'
            >
              <div
                id='table2_wrapper'
                className='dataTables_wrapper form-inline dt-bootstrap no-footer'
              >
                <div className='row be-datatable-header'>
                  <div className='col-sm-6' />
                  <div className='col-sm-6'>
                    <div className='input-group input-search search-box'>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='Search'
                        value={this.state.filterString}
                        onChange={this.filterCustomer}
                      />
                      <span className='input-group-btn'>
                        <button className='btn btn-secondary'>
                          <i className='icon mdi mdi-search' />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='row be-datatable-body'>
                  <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                    <table
                      id='table2'
                      className='table table-striped table-hover table-fw-widget table22 dataTable no-footer'
                      role='grid'
                      aria-describedby='table2_info'
                    >
                      <thead>
                        <tr role='row'>
                          <th
                            tabIndex='0'
                            aria-controls='table2'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Customer: activate to sort column ascending'
                            style={{ width: '229px' }}
                          >
                            Customer
                          </th>
                          <th
                            tabIndex='0'
                            aria-controls='table2'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Contact: activate to sort column ascending'
                            style={{ width: '264px' }}
                          >
                            Contact
                          </th>
                          <th
                            tabIndex='0'
                            aria-controls='table2'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Orders: activate to sort column ascending'
                            style={{ width: '181px' }}
                          >
                            Orders
                          </th>
                          <th
                            tabIndex='0'
                            aria-controls='table2'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Average Rating: activate to sort column ascending'
                            style={{ width: '143px' }}
                          >
                            Average Rating
                          </th>
                          <th
                            tabIndex='0'
                            aria-controls='table2'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Last Order : activate to sort column ascending'
                            style={{ width: '105px' }}
                          >
                            Last Order
                          </th>
                          <th
                            tabIndex='0'
                            aria-controls='table2'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Join Date : activate to sort column ascending'
                            style={{ width: '102px' }}
                          >
                            Join Date
                          </th>
                          <th
                            className='nosort sorting_disabled'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='&amp;nbsp;  '
                            style={{ width: '144px' }}
                          >
                            &nbsp;
                          </th>
                        </tr>
                      </thead>
                      {!this.props.customers ? (
                        <tbody>
                          <tr style={{ height: '100px' }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                left: '0px',
                                right: '0px',
                                position: 'absolute',
                                width: 'auto',
                                height: '100px',
                              }}
                              className='be-loading be-loading-active'
                            >
                              <Spinner />
                            </div>
                          </tr>
                        </tbody>
                      ) : this.props.customers.length > 0 ? (
                        <tbody>
                          {this.props.customers.map((customer, index) => (
                            <tr key={index} className='gradeX odd' role='row'>
                              <td>
                                {`${customer.firstName} ${customer.lastName}`}
                                <br />
                                <span className='grayc'>
                                  {customer.address}
                                </span>
                              </td>
                              <td>
                                Mobile: {customer.mobileNumber} <br />
                                <span className='grayc'>{customer.email}</span>
                              </td>
                              <td>
                                {`Total Spend: ${
                                  customer.totalRevenue
                                    ? `${this.props.currency}${Number(
                                        customer.totalRevenue
                                      ).toFixed(2)}`
                                    : 'N/A'
                                }`}
                                <br />
                                <span className='grayc'>{`Number of orders : ${
                                  customer.totalOrders
                                    ? customer.totalOrders
                                    : 'N/A'
                                }`}</span>
                              </td>
                              <td>
                                {customer.averageRating
                                  ? customer.averageRating
                                  : 'N/A'}
                                <br />
                                {customer.totalRatings !== 0 ? (
                                  <span>
                                    <span className='grayc'>
                                      {customer.totalRatings &&
                                      customer.totalRatings !== 0
                                        ? `${customer.totalRatings} ${
                                            customer.totalRatings === 1
                                              ? 'Review'
                                              : 'Reviews'
                                          }`
                                        : ''}
                                    </span>
                                    <br />
                                    <span
                                      className={this.getAvgRatingClass(
                                        customer.averageRating
                                      )}
                                    >
                                      {this.getAvgRatingText(
                                        customer.averageRating
                                      )}
                                    </span>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td>
                                {customer.lastOrderDate
                                  ? convertToMomentDate(
                                      customer.lastOrderDate,
                                      'YYYY-MM-DD HH:mm',
                                      this.props.timeZone
                                    ).format('MMM DD, YYYY')
                                  : 'N/A'}
                                <br />
                                <span className='grayc'>
                                  {customer.lastOrderDate
                                    ? convertToMomentDate(
                                        customer.lastOrderDate,
                                        'YYYY-MM-DD HH:mm',
                                        this.props.timeZone
                                      ).format('HH:mm')
                                    : ''}
                                </span>
                              </td>
                              <td>
                                {convertToMomentDate(
                                  customer.date,
                                  'YYYY-MM-DD HH:mm',
                                  this.props.timeZone
                                ).format('MMM DD, YYYY')}
                              </td>
                              <td className='text-right'>
                                {customer.totalOrders > 0 && (
                                  <button
                                    onClick={() =>
                                      this.openOrderView(customer._id)
                                    }
                                    className='btn btn-space btn-default'
                                  >
                                    View Orders
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <p> No records to display</p>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className='row be-datatable-footer'>
                  <div className='col-md-5 col-sm-12'>
                    <div
                      style={{ float: 'left' }}
                      className='dataTables_info'
                      id='table2_info'
                      role='status'
                      aria-live='polite'
                    >
                      {`Showing ${start} to ${end} of ${
                        this.props.totalCount ? this.props.totalCount : 0
                      } entries`}
                    </div>
                  </div>
                  <div className='col-md-7 col-sm-12'>
                    <div
                      className='dataTables_paginate paging_simple_numbers'
                      id='table2_paginate'
                    >
                      {this.props.pagination && (
                        <nav style={{ float: 'right' }}>
                          <Pagination
                            activePage={this.props.pagination.currentPage}
                            itemsCountPerPage={this.props.pagination.pageSize}
                            totalItemsCount={this.props.totalCount}
                            pageRangeDisplayed={
                              this.props.pagination.pageRangeDisplayed
                            }
                            onChange={this.props.handlePageChange}
                            prevPageText='Previous'
                            nextPageText='Next'
                            itemClass='paginate_button'
                          />
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.isDisplayOrderView && (
          <Modal
            aria-labelledby='modal-label'
            className='modal fade'
            show={this.state.isDisplayOrderView}
            onHide={this.closeOrderView}
            bsSize='md'
          >
            <div
              className='modal-content'
              style={{ maxHeight: '800px', overflowY: 'scroll' }}
            >
              <div className='panel panel-default panel-table'>
                <div className='panel-heading'>
                  Orders
                  <button
                    onClick={this.closeOrderView}
                    type='button'
                    data-dismiss='modal'
                    aria-hidden='true'
                    className='close md-close'
                  >
                    <span className='mdi mdi-close' />
                  </button>
                </div>
                <div className='panel-body'>
                  <table className='table table-condensed table-striped'>
                    <thead>
                      <tr>
                        <th>Order</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th className='number'>Order Amount</th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.userOrders ? (
                        this.props.userOrders.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item.date
                                ? convertToMomentDate(
                                    item.date,
                                    'YYYY-MM-DD HH:mm',
                                    this.props.timeZone
                                  ).format('MMM DD, YYYY')
                                : 'N/A'}
                            </td>
                            <td>
                              {item.orderType
                                ? item.orderType === '1'
                                  ? 'Pickup'
                                  : 'Delivery'
                                : 'N/A'}
                            </td>
                            <td className='number'>
                              {item.payableAmount
                                ? `${this.props.currency}${item.payableAmount}`
                                : 'N/A'}
                            </td>
                            <td>
                              <button
                                style={{ marginRight: '-5px', float: 'right' }}
                                onClick={() => this.openOrderModal(item)}
                                className='btn btn-space btn-primary'
                              >
                                View
                              </button>
                            </td>
                            <td>
                              <button
                                onClick={() => this.openInvoiceModal(item)}
                                className='btn btn-space btn-primary'
                              >
                                Send Invoice
                              </button>
                              {this.renderRefundStatus(item)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className=' be-loading be-loading-active'>
                          <div className='be-spinner'>
                            <svg
                              width='40px'
                              height='40px'
                              viewBox='0 0 66 66'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                fill='none'
                                strokeWidth='4'
                                strokeLinecap='round'
                                cx='33'
                                cy='33'
                                r='30'
                                className='circle'
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {this.state.isDisplayOrder && (
          <OrderModal
            isDisplayOrder={this.state.isDisplayOrder}
            closeOrderModal={this.closeOrderModal}
            selectedOrder={this.state.selectedOrder}
            timeZone={this.props.timeZone}
            storeId={this.props.storeId}
            currency={this.props.currency}
          />
        )}
        {this.state.openRefundPopup && (
          <RefundModal
            openRefundPopup={this.state.openRefundPopup}
            closeRefundPopup={this.closeRefundPopup}
            order={this.state.refundOrder}
            timeZone={this.props.timeZone}
            accessToken={this.props.accessToken}
            currency={this.props.currency}
            reasons={this.props.reasons}
          />
        )}
        {this.state.renderInvoiceModal && (
          <InvoiceModal
            renderInvoiceModal={this.state.renderInvoiceModal}
            closeInvoiceModal={this.closeInvoiceModal}
            order={this.state.invoiceOrder}
          />
        )}
        {this.state.renderInvoiceModal && (
          <InvoiceModal
            renderInvoiceModal={this.state.renderInvoiceModal}
            closeInvoiceModal={this.closeInvoiceModal}
            order={this.state.invoiceOrder}
          />
        )}
      </div>
    );
  }
}

CustomerList.propTypes = {
  fetchUserOrdersHandler: PropTypes.any,
  openOrderModalHelper: PropTypes.any,
  filterCustomers: PropTypes.any,
};

export default CustomerList;
