import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import Spinner from '../../components/Spinner';
import { convertToMomentDate } from '../../helpers/timeHelper';

class CloseDateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisplayCloseDate: false,
      selectedCloseDate: {},
    };
  }

  deleteCloseDate = (closeDate) => {
    this.props.deleteCloseDateHandler(closeDate);
  };

  render() {
    const start =
      (this.props.pagination.currentPage - 1) * this.props.pagination.pageSize +
      1;
    const end = this.props.closeDates
      ? (this.props.pagination.currentPage - 1) *
          this.props.pagination.pageSize +
        1 +
        this.props.closeDates.length -
        1
      : 0;
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12 col-xs-12 col-md-12'>
            <div className='panel panel-default panel-table '>
              <div className='panel-heading'>Close Dates </div>
              <div className='panel-body table-responsive'>
                <div
                  id='table1_wrapper'
                  className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                >
                  <div className='row be-datatable-body'>
                    <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                      <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Created On</th>
                            <th />
                          </tr>
                        </thead>
                        {!this.props.closeDates ? (
                          <tbody>
                            <tr style={{ height: '100px' }}>
                              <div
                                style={{
                                  alignSelf: 'center',
                                  left: '0px',
                                  right: '0px',
                                  position: 'absolute',
                                  width: 'auto',
                                  height: '100px',
                                }}
                                className='be-loading be-loading-active'
                              >
                                <Spinner />
                              </div>
                            </tr>
                          </tbody>
                        ) : this.props.closeDates.length > 0 ? (
                          <tbody>
                            {this.props.closeDates &&
                              this.props.closeDates.map((d, index) => (
                                <tr key={index}>
                                  <td>
                                    {convertToMomentDate(
                                      d.closeDate,
                                      'YYYY-MM-DD HH:mm',
                                      this.props.timeZone
                                    ).format('MMM DD, YYYY')}
                                  </td>
                                  <td>
                                    {d.isAllClosed
                                      ? 'Closed'
                                      : d.isDeliveryClosed
                                      ? 'Just Delivery Closed'
                                      : ''}
                                  </td>
                                  <td>
                                    {convertToMomentDate(
                                      d.date,
                                      'YYYY-MM-DD HH:mm',
                                      this.props.timeZone
                                    ).format('MMM DD, YYYY')}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => this.deleteCloseDate(d)}
                                      className='btn btn-space btn-default'
                                      data-toggle='modal'
                                      data-target='#vouch'
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td>
                                <p> No records to display</p>
                              </td>
                              <td />
                              <td />
                              <td />
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className='row be-datatable-footer'>
                    <div className='col-md-5 col-sm-12'>
                      <div
                        style={{ float: 'left' }}
                        className='dataTables_info'
                        id='table1_info'
                        role='status'
                        aria-live='polite'
                      >
                        {`Showing ${start} to ${end} of ${
                          this.props.totalCount ? this.props.totalCount : 0
                        } entries`}
                      </div>
                    </div>
                    <div className='col-md-7 col-sm-12'>
                      <div
                        className='dataTables_paginate paging_simple_numbers'
                        id='table1_paginate'
                      >
                        {this.props.pagination && (
                          <nav style={{ float: 'right' }}>
                            <Pagination
                              activePage={this.props.pagination.currentPage}
                              itemsCountPerPage={this.props.pagination.pageSize}
                              totalItemsCount={this.props.totalCount}
                              pageRangeDisplayed={
                                this.props.pagination.pageRangeDisplayed
                              }
                              onChange={this.props.handlePageChange}
                              prevPageText='Previous'
                              nextPageText='Next'
                              itemClass='paginate_button'
                            />
                          </nav>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CloseDateList.propTypes = {
  closeDates: PropTypes.any,
  totalCount: PropTypes.any,
  deleteCloseDateHandler: PropTypes.any,
};

export default CloseDateList;
