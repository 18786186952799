import React, { Component } from 'react';
import { firestore } from '../../firebase';
import DragSortableList from 'react-drag-sortable';
import toaster from 'toasted-notes';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import { Modal } from 'react-bootstrap';

class MenuItemsDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItem: {
        selectedSizes: [],
        selectedIngredients: [],
        selectedExtraIngredients: [],
      },
      isProcessing: false,
      page: 1,
    };
  }

  componentDidMount() {
    this.setState({
      selectedCategoryDisplay: this.props.selectedCategoryDisplay,
      menuItemsManualOrder: this.props.menuItemsManualOrder,
      menuItemsPopularityOrder: this.props.menuItemsPopularityOrder,
    });
  }

  // componentWillReceiveProps(props) {
  //   this.setState({
  //     selectedCategoryDisplay: props.selectedCategoryDisplay,
  //     menuItemsManualOrder: props.menuItemsManualOrder,
  //   });
  // }

  onSort = (sortedList) => {
    clearTimeout(this.timeOutId);
    this.timeOutId = setTimeout(this.updateList(sortedList), 1000);
  };

  updateList = (sortedList) => {
    let items = [];
    sortedList.map((item) => {
      items = this.state.menuItemsManualOrder.map((m) => {
        if (m._id === item.item._id) {
          m.displayOrder = item.rank;
        }
        return m;
      });
      return item;
    });
    let menuItems = orderBy(items, ['displayOrder'], ['asc']);
    this.setState({ menuItemsManualOrder: menuItems }, () => {});
  };

  updateMenuItem = (item) => {
    return new Promise((resolve, reject) => {
      let docRef = firestore.collection('MenuItems').doc(item._id);
      firestore
        .runTransaction((t) => {
          return t.get(docRef).then((doc) => {
            t.update(docRef, { displayOrder: item.displayOrder });
          });
        })
        .then(() => {
          resolve(`${item.name}'s stats updated`);
        })
        .catch((error) => {
          console.log(error);
          reject(`Failed updating stats for ${item.name}`);
        });
    });
  };

  updateCategoryOnServer = (e) => {
    let radioValue = e.target.value;
    toaster.notify(`Updating!`, {
      duration: 2000,
    });
    if (this.state.isProcessing === false) {
      this.setState(
        {
          isProcessing: true,
        },
        () => {
          const valSort = radioValue;
          if (this.props.selectedCategoryDisplay) {
            firestore
              .collection('Categories')
              .doc(this.props.selectedCategoryDisplay._id)
              .get()
              .then((doc) => {
                doc.ref.update({ sortingType: valSort }).then(() => {
                  this.setState(
                    {
                      selectedCategoryDisplay: {
                        ...this.state.selectedCategoryDisplay,
                        sortingType: valSort,
                      },
                    },
                    () => {
                      toaster.notify(
                        `Category's menu items sorting type is updated!`,
                        {
                          duration: 2000,
                        }
                      );
                      this.setState({
                        isProcessing: false,
                      });
                    }
                  );
                });
              });
          }
        }
      );
    }
  };

  saveItemOrder = () => {
    let menuItems = this.state.menuItemsManualOrder;
    let requests = menuItems.map((item) => {
      return this.updateMenuItem(item);
    });
    Promise.all(requests)
      .then((values) => {
        console.log(values);
        toaster.notify(`Menu items sorting is updated!`, {
          duration: 2000,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    var list = [];
    if (this.state.menuItemsManualOrder) {
      this.state.menuItemsManualOrder.map((item) => {
        list.push({
          content: <div className='dd-handle'>{item.name}</div>,
          item,
          classes: ['dd-item'],
        });
        return item;
      });
    }
    var list1 = [];
    if (this.state.menuItemsPopularityOrder) {
      this.state.menuItemsPopularityOrder.map((item) => {
        list1.push({
          content: <div className='dd-handle no-drag'>{item.name}</div>,
          item,
          classes: ['dd-item'],
        });
        return item;
      });
    }
    return (
      <Modal
        className='modal fade'
        backdrop='static'
        keyboard={false}
        show={this.props.showMenuItemsDisplay}
        onHide={this.props.closeItemDisplayModel}
        bsSize='large'
        aria-labelledby='contained-modal-title'
      >
        <div className='modal-header'>
          <button
            onClick={this.props.closeItemDisplayModel}
            type='button'
            data-dismiss='modal'
            aria-hidden='true'
            className='close'
          >
            <span className='mdi mdi-close' />
          </button>
          <h3 className='modal-title'>Sorting Type</h3>
        </div>
        <div className='model-body'>
          <div className='raw'>
            <div className='form-group'>
              <div
                className='col-md-9 col-sm-9'
                style={{ display: 'flex', marginBottom: '20px' }}
              >
                <div style={{ margin: '8px 0px' }}>
                  <input
                    name='sortingType'
                    id='popularFirst'
                    component='input'
                    type='radio'
                    className='invoice-radio'
                    value='1'
                    checked={
                      this.state.selectedCategoryDisplay &&
                      this.state.selectedCategoryDisplay.sortingType === '1'
                    }
                    onChange={this.updateCategoryOnServer}
                  />
                  <span className='invoice-sub-item'>Popular First</span>
                </div>
                <div style={{ margin: '8px 14px' }}>
                  <input
                    name='sortingType'
                    id='manualSorting'
                    component='input'
                    type='radio'
                    className='invoice-radio'
                    checked={
                      this.state.selectedCategoryDisplay &&
                      this.state.selectedCategoryDisplay.sortingType === '2'
                    }
                    value='2'
                    onChange={this.updateCategoryOnServer}
                  />
                  <span className='invoice-sub-item'>Manual</span>
                </div>
              </div>
            </div>
          </div>
          <div className='raw'>
            <div className='col-md-6 com-sm-12 sectionContiner'>
              <div
                className={
                  this.state.selectedCategoryDisplay &&
                  this.state.selectedCategoryDisplay.sortingType === '1'
                    ? 'panel panel-default blueBorder'
                    : 'panel panel-default'
                }
              >
                <div className='panel-heading panel-heading-divider  ptitle'>
                  Menu Items By Popularity
                  <span className='panel-subtitle psubtitle'>
                    {`Menu items as they will appear on the website based on popularity(Auto generated).`}
                  </span>
                </div>
                <p className='divider1' />
                <div className='panel-body'>
                  <div id='list1' className='dd'>
                    <DragSortableList
                      classNmae='dd-list'
                      items={list1}
                      moveTransitionDuration={0.3}
                      type='vertical'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 com-sm-12 sectionContiner'>
              <div
                className={
                  this.state.selectedCategoryDisplay &&
                  this.state.selectedCategoryDisplay.sortingType === '2'
                    ? 'panel panel-default blueBorder'
                    : 'panel panel-default'
                }
              >
                <div className='panel-heading panel-heading-divider  ptitle'>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>Menu Items By Display Order</span>
                    <button
                      type='submit'
                      className='btn btn-primary btn-space'
                      onClick={this.saveItemOrder}
                      disabled={
                        this.state.selectedCategoryDisplay &&
                        this.state.selectedCategoryDisplay.sortingType === '1'
                      }
                    >
                      Save
                    </button>
                  </div>
                  <span className='panel-subtitle psubtitle'>
                    {`Drag & drop the order of menu items as they will appear on the website.`}
                  </span>
                </div>
                <p className='divider1' />
                <div className='panel-body'>
                  <div id='list1' className='dd'>
                    <DragSortableList
                      classNmae='dd-list'
                      items={list}
                      onSort={this.onSort}
                      moveTransitionDuration={0.3}
                      type='vertical'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

MenuItemsDisplay.propTypes = {};

export default MenuItemsDisplay;
