import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchOrders:
      return { ...state, deliveryOrders: payload.deliveryOrders };
    case ActionTypes.FetchOrderCount:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.MenuItemsCountFetched:
      return { ...state, totalCountD: payload.totalCountD };
    case ActionTypes.FetchMenuItems:
      return { ...state, menuItemsD: payload.menuItemsD };
    default:
      return state;
  }
};
