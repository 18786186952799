import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchPaymentReports:
      return { ...state, paymentReports: payload.paymentReports };
    case ActionTypes.FetchedCountPaymentReport:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.FetchStore:
      return { ...state, store: payload.store };
    default:
      return state;
  }
};
