import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import ImageCompressor from 'image-compressor.js';
import './index.css';
import './slider.css';
import './cropper.css';
const imageCompressor = new ImageCompressor();

let compressionObj = {
  quality: 0.8,
  convertSize: 10000,
  mimeType: 'jpeg',
  maxWidth: 700,
};

class PhotoWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidationError: false,
      validationMessage: '',
      isImageAnEmoji: false,
    };
    this.cropImage = this.cropImage.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        menuItem: this.props.menuItem,
      },
      () => {
        let reader = new FileReader();
        if (this.props.menuItem && this.props.menuItem.photo) {
          let file = this.props.menuItem.photo;
          reader.onloadend = () => {
            this.setState({
              file: file,
              src: reader.result,
            });
          };
          reader.readAsDataURL(file);
        }
        this.setState({
          isImageAnEmoji: this.props.menuItem.isImageAnEmoji
            ? this.props.menuItem.isImageAnEmoji
            : false,
        });
      }
    );
  }

  nextPage = () => {
    if (this.state.src || this.state.menuItem.urlS3) {
      this.cropImage();
    } else {
      this.setState({
        isValidationError: true,
        validationMessage: 'Item photo is required',
      });
    }
  };

  onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files && files[0] && files[0].size > 30000) {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          src: reader.result,
          showError: false,
          isValidationError: false,
        });
      };
      reader.readAsDataURL(files[0]);
    } else {
      this.setState({
        showError: true,
        isValidationError: false,
      });
    }
  };

  cropImage = () => {
    if (this.state.src) {
      if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
        return;
      }
      this.cropper.getCroppedCanvas().toBlob((blob) => {
        imageCompressor
          .compress(blob, compressionObj)
          .then((result) => {
            if (result.size > 140000) {
              imageCompressor
                .compress(blob, compressionObj)
                .then((result1) => {
                  this.setState({
                    file: new File([result1], 'name'),
                  });
                  this.props.setItemPhoto(
                    new File([result1], 'name'),
                    this.state.isImageAnEmoji
                  );
                })
                .catch((err) => {
                  // Handle the error
                });
            } else {
              this.setState({
                file: new File([result], 'name'),
              });
              this.props.setItemPhoto(
                new File([result], 'name'),
                this.state.isImageAnEmoji
              );
            }
          })
          .catch((err) => {
            // Handle the error
          });
      });
      //this.props.nextPage();
    } else if (this.state.menuItem.urlS3) {
      if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
        return;
      }
      if (this.cropper.getCroppedCanvas()) {
        this.cropper.getCroppedCanvas().toBlob((blob) => {
          imageCompressor
            .compress(blob, compressionObj)
            .then((result) => {
              if (result.size > 140000) {
                imageCompressor
                  .compress(blob, compressionObj)
                  .then((result1) => {
                    this.setState({
                      file: new File([result1], 'name'),
                    });
                    this.props.setItemPhoto(
                      new File([result1], 'name'),
                      this.state.isImageAnEmoji
                    );
                  })
                  .catch((err) => {
                    // Handle the error
                  });
              } else {
                this.setState({
                  file: new File([result], 'name'),
                });
                this.props.setItemPhoto(
                  new File([result], 'name'),
                  this.state.isImageAnEmoji
                );
              }
            })
            .catch((err) => {
              // Handle the error
            });
        });
      }
      //this.props.nextPage();
    }
  };

  zoom = (e) => {
    this.cropper.zoomTo(e.target.value);
  };

  zoomEvent = (e) => {
    this.refs.slider.value = e.detail.ratio;
    this.setState({ slider: e.detail.ratio });
  };

  removeAlert = () => {
    this.setState({ isValidationError: false, validationMessage: '' });
  };

  handleEmojiCheckbox = (e) => {
    this.setState({
      isImageAnEmoji: e.target.checked,
    });
  };

  render() {
    const style14 = { float: 'right' };
    const style15 = { float: 'left' };
    return (
      <div>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='col-md-5'>
              <div className='row'>
                <div className='col-lg-5 col-md-5 col-xs-12 col-sm-5 proboxspace'>
                  {this.state.menuItem &&
                  this.state.menuItem.urlS3 &&
                  !this.state.src ? (
                    <div className='proimg overflowHide'>
                      {
                        <img
                          className='proimg'
                          alt=''
                          src={this.state.menuItem.urlS3}
                        />
                      }
                    </div>
                  ) : (
                    <div className='img-preview proimg overflowHide'>
                      <img className='proimg' alt='' src={this.state.src} />
                    </div>
                  )}
                  <div className='hprodet' />
                </div>
                <div className='clear' />
                <div className='col-lg-5 col-md-5 col-xs-12 col-sm-5'>
                  <div className='form-group col-sm-12 text-center'>
                    <label className='control-label uploadlable'>
                      Upload Item Photo (.jpeg, .png, .svg)
                    </label>
                    <input
                      alt=''
                      onChange={this.onChange}
                      type='file'
                      accept='image/*'
                    />
                    <div
                      className='invoice-item'
                      style={{
                        position: 'relative',
                        top: '12px',
                        left: '-10px',
                      }}
                    >
                      <input
                        type='checkbox'
                        className='invoice-checkbox'
                        checked={this.state.isImageAnEmoji}
                        onChange={(e) => this.handleEmojiCheckbox(e)}
                      />
                      <span
                        className='invoice-sub-item'
                        style={{ fontSize: '16px' }}
                      >
                        Is image an emoji?
                      </span>
                    </div>
                    {this.state.showError === true && (
                      <label
                        className='control-label uploadlable'
                        style={{
                          fontSize: '12px',
                          marginTop: '10px',
                          color: 'red',
                        }}
                      >
                        Image Size Should be Greater than 30 Kb
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-7'>
              {(this.state.src ||
                (this.state.menuItem && this.state.menuItem.urlS3)) && (
                <div>
                  <div className='row'>
                    <div className='col-lg-10 col-md-10 col-xs-12 col-sm-5'>
                      <Cropper
                        autoCrop={true}
                        style={{ height: 350, width: '70%' }}
                        width='160'
                        height='90'
                        guides={false}
                        src={
                          this.state.src
                            ? this.state.src
                            : this.state.menuItem.urlS3
                        }
                        minWidth='256'
                        minHeight='256'
                        maxWidth='4096'
                        maxHeight='4096'
                        viewMode={1}
                        aspectRatio={16 / 16}
                        autoCropArea={1}
                        responsive={true}
                        cropBoxMovable={false}
                        cropBoxResizable={false}
                        checkCrossOrigin={true}
                        dragMode='move'
                        ref={(cropper) => {
                          this.cropper = cropper;
                        }}
                        wheelZoomRatio={0.3}
                        zoom={this.zoomEvent}
                        preview='.img-preview'
                      />
                    </div>
                  </div>
                  <div className='row' style={{ paddingTop: '20px' }}>
                    <div className='col-md-7'>
                      <div className='slidecontainer'>
                        <input
                          className='slider'
                          ref='slider'
                          onChange={this.zoom}
                          type='range'
                          defaultValue='0.30'
                          min='0'
                          max='1'
                          step='0.01'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='form-group'>
          <button
            style={style15}
            onClick={this.props.previousPage}
            className='btn btn-default btn-space'
          >
            <i className='icon mdi mdi-chevron-left' />
            <i className='icon mdi mdi-chevron-left' /> Previous
          </button>
          <button
            style={style14}
            onClick={this.nextPage}
            data-wizard='#wizard1'
            className='btn btn-primary btn-space wizard-next'
          >
            Next Step
            <i className='icon mdi mdi-chevron-right' />
            <i className='icon mdi mdi-chevron-right' />
          </button>
        </div>
        <div className='col-sm-12'>
          <div className='col-md-6' />
          <div className='col-md-6'>
            {this.state.isValidationError && (
              <div
                role='alert'
                className='alert alert-contrast alert-danger alert-dismissible'
              >
                <div className='icon'>
                  <span
                    className='mdi mdi-close-circle-o'
                    style={{ cursor: 'pointer' }}
                    onClick={this.removeAlert}
                  />
                </div>
                <div className='message'>
                  <strong>Danger!</strong>
                  {this.state.validationMessage}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PhotoWizard.propTypes = {
  nextPage: PropTypes.any,
  previousPage: PropTypes.any,
  menuItem: PropTypes.any,
  setItemPhoto: PropTypes.any,
};

export default PhotoWizard;
