import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchShifts:
      return { ...state, shifts: payload.shifts };
    case ActionTypes.FetchAllShifts:
      return { ...state, allShifts: payload.allShifts };
    case ActionTypes.ShiftCountFetched:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.HighRevenueShifts:
      return { ...state, hRevenuehifts: payload.hRevenuehifts };
    default:
      return state;
  }
};
