import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy';
import {
  getTimestampStartOfTheDay,
  convertToFormattedMomentDate,
} from '../../helpers/timeHelper';
import { Modal } from 'react-bootstrap';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import RealtimeOrdersList from '../../components/RealtimeOrdersList';
import OrderModal from '../../components/OrderModal';
import OtherOrderModal from '../../components/OtherOrderModal';
import WarningModal from '../../components/WarningModal';
import { getOrders, getReservations, getOtherDeliveries } from './actions';
import { updateReservation } from '../../containers/CurrentReservations/actions';
import { getStoreSetup } from '../../containers/StoreSetting/actions';
import { getAllShifts } from '../../containers/Shifts/actions';
import { saveOrderSetup } from '../../containers/StoreSetting/actions';
import { nextOrderConfig } from '../../configurations/storeSetup';
import downImage from '../../assets/images/down.svg';
import getOrderStatus from '../../helpers/getOrderStatus';
Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class RealtimeOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultIndex: 0,
      showDriverWarning: false,
      showDriverWarningForD: false,
      isDisplayReservation: false,
      isDisplayOrder: false,
      isDisplayOtherOrder: false,
      selectedItem: {},
      selectedItemType: 0,
      isPickUpOpen: true,
      isDeliveryOpen: true,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/realtime-orders'
        );
      } else {
        firestore
          .collection('SystemUsers')
          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                    userRole: doc.data().userRole,
                  },
                  () => {
                    this.props.getOrdersHandler(
                      this.state.storeId,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    this.props.getReservationsHandler(
                      this.state.storeId,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    this.props.getOtherDeliveriesHandler(
                      this.state.storeId,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    this.props.getAllShiftsHandler(doc.data().storeId);
                    this.props.getStoreSetupHandler({}, doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    if (
      newProps.inStore.realtimeOrders &&
      newProps.inStore.reservations &&
      newProps.inStore.otherDeliveries
    ) {
      let orderList = [];
      newProps.inStore.realtimeOrders.map((order) => {
        let item = {};
        item._id = order._id;
        item.orderType = order.orderType;
        item.userId = order.userId;
        item.firstName = order.firstName;
        item.lastName = order.lastName;
        item.address = order.address;
        item.orderDetails = `${currency}${order.payableAmount}`;
        item.status = order.orderStatus;
        item.deliveryDate = order.deliveryDate;
        item.deliveryCode = order.deliveryCode;
        item.date = order.date;
        item.dateTimestamp = order.dateTimestamp;
        item.refundStatus = order.refundStatus ? order.refundStatus : 1500;
        item.paymentType = order.paymentType;
        orderList.push(item);
        return order;
      });
      newProps.inStore.reservations.map((reservation) => {
        let item = {};
        item.orderType = '3';
        item._id = reservation._id;
        item.userId = reservation.userId;
        item.firstName = reservation.firstName;
        item.lastName = reservation.lastName;
        item.orderDetails = `${reservation.adults} Adults, ${reservation.children} Children, ${reservation.infants} Infants`;
        item.status = reservation.reservationStatus;
        item.date = reservation.date;
        item.dateTimestamp = reservation.dateTimestamp;
        item.reservationDate = reservation.reservationDate;
        orderList.push(item);
        return reservation;
      });
      newProps.inStore.otherDeliveries.map((otherDelivery) => {
        let item = {};
        item.orderType = '4';
        item._id = otherDelivery._id;
        item.userId = otherDelivery.userId ? otherDelivery.userId : '';
        item.status = otherDelivery.orderStatus;
        item.date = otherDelivery.date;
        item.dateTimestamp = otherDelivery.dateTimestamp;
        item.address = otherDelivery.address;
        orderList.push(item);
        return otherDelivery;
      });
      this.setState(
        {
          orderList: orderBy(orderList, 'dateTimestamp', 'desc'),
          realtimeOrders: newProps.inStore.realtimeOrders,
          reservations: newProps.inStore.reservations,
          otherDeliveries: newProps.inStore.otherDeliveries,
        },
        () => {
          if (this.state.selectedItem._id) {
            if (this.state.selectedItemType === 1) {
              let selectedOrder = this.state.realtimeOrders.filter((m) => {
                return m._id === this.state.selectedItem._id;
              });
              if (selectedOrder && selectedOrder.length > 0)
                this.openOrderModal(selectedOrder[0]);
            }
          }
        }
      );
    }
    this.setState({
      allShifts: newProps.shift.allShifts,
      orderSetup: newProps.storeSetting.orderSetup,
    });
    if (newProps.shift.allShifts) {
      let allShifts = newProps.shift.allShifts;
      let filteredShift = allShifts.filter((obj) => {
        return obj.isCurrent === true;
      });
      if (filteredShift.length > 0) {
        this.setState({
          isPickUpOpen: true,
        });
      } else {
        this.setState({
          isPickUpOpen: false,
        });
      }
      let filteredShift1 = allShifts.filter((obj) => {
        return obj.isDeliveryOpen === true;
      });
      if (filteredShift1.length > 0) {
        this.setState({
          isDeliveryOpen: true,
        });
      } else {
        this.setState({
          isDeliveryOpen: false,
        });
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  changeIndex = (index) => {
    this.setState({ defaultIndex: index });
  };

  timeChange = (type, dir) => {
    let orderSetup = JSON.parse(JSON.stringify(this.state.orderSetup));
    if (type === 'pickUpTime') {
      if (dir === 'up') {
        orderSetup.pickUpTime = (Number(orderSetup.pickUpTime) + 1).toString();
      } else {
        orderSetup.pickUpTime = (Number(orderSetup.pickUpTime) - 1).toString();
      }
    } else if (type === 'deliveryTime') {
      if (dir === 'up') {
        orderSetup.deliveryTime = (
          Number(orderSetup.deliveryTime) + 1
        ).toString();
      } else {
        orderSetup.deliveryTime = (
          Number(orderSetup.deliveryTime) - 1
        ).toString();
      }
    }
    this.props.saveOrderSetupHandler(orderSetup);
  };

  openModal = (_id, type) => {
    if (type === '1' || type === '2') {
      if (this.state.orderList) {
        let order = this.state.realtimeOrders.filter((obj) => {
          return obj._id === _id;
        });
        if (order && order.length > 0) {
          this.setState({ selectedItem: order[0], selectedItemType: 1 });
          this.openOrderModal(order[0]);
        }
      }
    } else if (type === '4') {
      if (this.state.otherDeliveries) {
        let order = this.state.otherDeliveries.filter((obj) => {
          return obj._id === _id;
        });
        if (order && order.length > 0) {
          this.setState({ selectedItem: order[0], selectedItemType: 3 });
          this.openOtherOrderModal(order[0]);
        }
      }
    } else if (type === '3') {
      let reservation = this.state.reservations.filter((obj) => {
        return obj._id === _id;
      });
      if (reservation && reservation.length > 0) {
        this.setState({ selectedItem: reservation[0], selectedItemType: 2 });
        this.openReservationModal(reservation[0]);
      }
    }
  };

  openOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOrder: true });
  };

  openOtherOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOtherOrder: true });
  };

  closeOrderModal = () => {
    this.setState({
      isDisplayOrder: false,
      isDisplayOtherOrder: false,
      selectedOrder: {},
      selectedItem: {},
    });
  };

  openReservationModal = (reservation) => {
    this.setState({
      selectedReservation: reservation,
      isDisplayReservation: true,
    });
  };

  closeReservationModal = () => {
    this.setState({
      isDisplayReservation: false,
      showDriverWarning: false,
      showDriverWarningForD: false,
      selectedReservation: {},
      selectedItem: {},
    });
  };

  getReservationStatusClass = (status) => {
    if (status === '1') return 'form-group has-warning';
    else if (status === '2') return 'form-group has-success';
    else if (status === '3') return 'form-group has-error';
  };

  updateReservationStatus = (status) => {
    let reservation = this.state.selectedReservation;
    reservation.reservationStatus = status;
    this.props.updateReservationHandler(reservation, this.state.timeZone);
    setTimeout(this.closeReservationModal(), 1000);
  };

  closeWarnings = () => {
    this.setState({ showDriverWarning: false, showDriverWarningForD: false });
  };

  openDriverWarningModal = () => {
    this.setState({ showDriverWarning: true });
  };

  openDriverWarningModalD = () => {
    this.setState({ showDriverWarningForD: true });
  };

  getDisplayTime = (timeInMinutes) => {
    let dur = '';
    if (Number(timeInMinutes) > 60) {
      dur = `${parseInt(timeInMinutes / 60, 10)}:${parseInt(
        timeInMinutes % 60,
        10
      )}`;
    } else {
      dur = timeInMinutes !== 0 ? `${timeInMinutes}` : 'N/A';
    }
    return dur;
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    const style = {
      backgroundImage: 'url(' + downImage + ')',
    };
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            {this.state.userRole && this.state.userRole === '1' && (
              <div className='col-md-12'>
                <div className='panel panel-default'>
                  <div style={{ padding: '0px' }} className='panel-body'>
                    {this.state.isPickUpOpen || this.state.isDeliveryOpen ? (
                      <React.Fragment>
                        {this.state.isPickUpOpen ? (
                          <React.Fragment>
                            <div className='incri1 bgreen col-md-1 '>
                              <input
                                style={{ paddingLeft: '10px' }}
                                readOnly
                                id='textBox'
                                type='text'
                                value={
                                  this.state.orderSetup
                                    ? this.state.orderSetup.pickUpTime
                                    : 0
                                }
                                className='text1'
                              />
                              <span style={{ left: '23px' }} className='mins1'>
                                Mins
                              </span>
                              <div className='incribtn'>
                                <input
                                  onClick={() =>
                                    this.timeChange('pickUpTime', 'up')
                                  }
                                  style={style}
                                  id='decreaseNumber'
                                  type='button'
                                  className='btnincri'
                                />
                                <input
                                  onClick={() =>
                                    this.timeChange('pickUpTime', 'down')
                                  }
                                  style={style}
                                  id='increaseNumber'
                                  type='button'
                                  className='btnincri1'
                                />
                              </div>
                            </div>
                            <div className='col-md-1 incri1 mr20'>
                              <div className='instoreu'>Pickup</div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className='incri1 bgreen col-md-1'>
                              <span
                                style={{ fontSize: '25px' }}
                                className='text1'
                              >
                                Pickup Closed
                              </span>
                            </div>
                          </React.Fragment>
                        )}
                        {this.state.isDeliveryOpen ? (
                          <React.Fragment>
                            <div className='incri1 bgblue col-md-1 '>
                              <input
                                style={{ paddingLeft: '10px' }}
                                readOnly
                                id='textBox1'
                                type='text'
                                value={
                                  this.state.orderSetup
                                    ? this.state.orderSetup.deliveryTime
                                    : 0
                                }
                                className='text1'
                              />
                              <span style={{ left: '23px' }} className='mins1'>
                                Mins
                              </span>
                              <div className='incribtn'>
                                <input
                                  onClick={() =>
                                    this.timeChange('deliveryTime', 'up')
                                  }
                                  style={style}
                                  id='decreaseNumber1'
                                  type='button'
                                  className='btnincri'
                                />
                                <input
                                  onClick={() =>
                                    this.timeChange('deliveryTime', 'down')
                                  }
                                  style={style}
                                  id='increaseNumber1'
                                  type='button'
                                  className='btnincri1'
                                />
                              </div>
                            </div>
                            <div className='col-md-1 incri1 mr20'>
                              <div className='instoreu'>Delivery</div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className='incri1 bgblue col-md-1'>
                              <span
                                style={{ fontSize: '25px' }}
                                className='text1'
                              >
                                Delivery Closed
                              </span>
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className='incri1 bgblue col-md-1'>
                          <span style={{ fontSize: '25px' }} className='text1'>
                            Store Closed
                          </span>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <RealtimeOrdersList
            orderList={this.state.orderList}
            realtimeOrders={this.state.realtimeOrders}
            orderSetup={this.state.orderSetup}
            openModal={this.openModal}
            timeZone={this.state.timeZone}
            currency={currency}
          />
        </div>
        {this.state.isDisplayOrder && (
          <OrderModal
            isDisplayOrder={this.state.isDisplayOrder}
            closeOrderModal={this.closeOrderModal}
            selectedOrder={this.state.selectedOrder}
            timeZone={this.state.timeZone}
            storeId={this.state.storeId}
            currency={currency}
          />
        )}
        {this.state.isDisplayOtherOrder && (
          <OtherOrderModal
            isDisplayOrder={this.state.isDisplayOtherOrder}
            closeOrderModal={this.closeOrderModal}
            selectedOrder={this.state.selectedOrder}
            timeZone={this.state.timeZone}
            storeId={this.state.storeId}
          />
        )}
        {this.state.isDisplayReservation && (
          <Modal
            aria-labelledby='modal-label'
            className='modal fade'
            show={this.state.isDisplayReservation}
            onHide={this.closeReservationModal}
            bsSize='sm'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  onClick={this.closeReservationModal}
                  type='button'
                  data-dismiss='modal'
                  aria-hidden='true'
                  className='close md-close'
                >
                  <span className='mdi mdi-close' />
                </button>
              </div>
              <div className='modal-body'>
                <div className='col-md-12'>
                  <div className='text-center'>
                    <form
                      action='#'
                      style={{ borderRadius: '0px' }}
                      className='form-horizontal group-border-dashed'
                    >
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>
                          Customer Name
                        </label>
                        <div className='col-md-8'>
                          <input
                            value={this.state.selectedReservation.userName}
                            readOnly='readonly'
                            className='form-control'
                            type='text'
                          />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>
                          Mobile Number
                        </label>
                        <div className='col-md-8'>
                          <input
                            readOnly='readonly'
                            value={this.state.selectedReservation.mobileNumber}
                            className='form-control'
                            type='text'
                          />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>Adults</label>
                        <div className='col-md-8'>
                          <input
                            readOnly='readonly'
                            value={this.state.selectedReservation.adults}
                            className='form-control'
                            type='text'
                          />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>
                          Children
                        </label>
                        <div className='col-md-8'>
                          <input
                            readOnly='readonly'
                            value={this.state.selectedReservation.children}
                            className='form-control'
                            type='text'
                          />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>Infant</label>
                        <div className='col-md-8'>
                          <input
                            readOnly='readonly'
                            value={this.state.selectedReservation.infants}
                            className='form-control'
                            type='text'
                          />
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>
                          Date and Time
                        </label>
                        <div className='col-md-8'>
                          <input
                            readOnly='readonly'
                            value={`${convertToFormattedMomentDate(
                              this.state.selectedReservation.reservationDate,
                              'YYYY-MM-DD HH:mm',
                              this.state.timeZone,
                              'MMM DD, YYYY'
                            )} - ${convertToFormattedMomentDate(
                              this.state.selectedReservation.reservationDate,
                              'YYYY-MM-DD HH:mm',
                              this.state.timeZone,
                              'HH:mm'
                            )}`}
                            className='form-control'
                            type='text'
                          />
                        </div>
                      </div>
                      <div
                        className={this.getReservationStatusClass(
                          this.state.selectedReservation.reservationStatus
                        )}
                      >
                        <label className='col-md-3 control-label'>Status</label>
                        <div className='col-md-8'>
                          <input
                            readOnly='readonly'
                            value={getOrderStatus(
                              this.state.selectedReservation.reservationStatus,
                              '3'
                            )}
                            className='form-control'
                            type='text'
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  onClick={this.closeReservationModal}
                  type='button'
                  data-dismiss='modal'
                  className='btn btn-default md-close'
                >
                  Cancel
                </button>
                <button
                  onClick={() => this.openDriverWarningModal()}
                  type='button'
                  className='btn btn-space btn-success active'
                >
                  Approve
                </button>
                <button
                  onClick={() => this.openDriverWarningModalD()}
                  type='button'
                  className='btn btn-space btn-danger active'
                >
                  Decline
                </button>
              </div>
            </div>
          </Modal>
        )}
        {this.state.showDriverWarning && (
          <WarningModal
            procced={() => this.updateReservationStatus('2')}
            closeModal={this.closeWarnings}
            message={`This will approve reservation. Click "proceed" to continue.`}
          />
        )}
        {this.state.showDriverWarningForD && (
          <WarningModal
            procced={() => this.updateReservationStatus('3')}
            closeModal={this.closeWarnings}
            message={`This will decline reservation. Click "proceed" to continue.`}
          />
        )}
      </Layout>
    );
  }
}

RealtimeOrders.propTypes = {};

function mapStateToProps(state) {
  return {
    inStore: state.realtimeOrdersReducer,
    shift: state.shiftsReducer,
    storeSetting: state.storeSettingReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveOrderSetupHandler: (orderSetup) => dispatch(saveOrderSetup(orderSetup)),
    getOrdersHandler: (storeId, dateToday) =>
      dispatch(getOrders(storeId, dateToday)),
    getReservationsHandler: (storeId, dateToday) =>
      dispatch(getReservations(storeId, dateToday)),
    getOtherDeliveriesHandler: (storeId, dateToday) =>
      dispatch(getOtherDeliveries(storeId, dateToday)),
    updateReservationHandler: (reservation, timezone) =>
      dispatch(updateReservation(reservation, timezone)),
    getAllShiftsHandler: (storeId) => dispatch(getAllShifts(storeId)),
    getStoreSetupHandler: (setup, storeId) =>
      dispatch(getStoreSetup(setup, storeId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RealtimeOrders);
