import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import AppRoutes from './routes';
import { unregister } from './registerServiceWorker';

import { Provider } from 'react-redux';
import Store from './store';

unregister();
ReactDOM.render(
  <Provider store={Store}>
    <AppRoutes />
  </Provider>,
  document.getElementById('root')
);
