import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getStoreBillings(storeId) {
  return (dispatch) => {
    firestore
      .collection(`StoreBillings`)
      .where('storeId', '==', storeId)
      .where('isActive', '==', true)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          let dObj = doc.data();
          dObj._id = doc.id;
          dataArray.push(dObj);
        });
        dispatch(storeBillinsFetched(dataArray.length > 0 ? dataArray[0] : {}));
      });
  };
}

export function getStore(storeId) {
  return (dispatch) => {
    let ref = firestore.collection('Stores').doc(storeId);
    ref.onSnapshot((doc) => {
      dispatch(storeFetched({ ...doc.data(), _id: doc.id }));
    });
  };
}

export function getStoreBillingCode(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('BillingCodes')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedBillingCode(dataArray.length > 0 ? dataArray[0] : {}));
    });
  };
}

export function getCreditCardProcessingFees(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('CreditCardProcessingFees')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedCreditCardProcessingFees(dataArray));
    });
  };
}

function storeBillinsFetched(activeBilling) {
  return {
    type: ActionTypes.FetchActiveBilling,
    activeBilling,
  };
}

function storeFetched(store) {
  return {
    type: ActionTypes.FetchStore,
    store,
  };
}

function fetchedBillingCode(billingCode) {
  return {
    type: ActionTypes.FetchBillingCode,
    billingCode,
  };
}

function fetchedCreditCardProcessingFees(creditCardProcessingFees) {
  return {
    type: ActionTypes.FetchCreditCardProcessingFees,
    creditCardProcessingFees,
  };
}
