import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

//Next order production
var config = {
  apiKey: 'AIzaSyB16esbVy74wJomLuDclV5twHb-6Xta580',
  authDomain: 'next-order.firebaseapp.com',
  databaseURL: 'https://next-order.firebaseio.com',
  projectId: 'next-order',
  storageBucket: 'next-order.appspot.com',
  messagingSenderId: '1095937248847',
};

var configOther = {
  apiKey: 'AIzaSyAjATKNQW1QE7P9yQBoIhSzlbdEv3mb-uo',
  authDomain: 'next-order-dev0.firebaseapp.com',
  databaseURL: 'https://next-order-dev0.firebaseio.com',
  projectId: 'next-order-dev0',
  storageBucket: 'next-order-dev0.appspot.com',
  messagingSenderId: '454725379668',
};

let app = firebase.initializeApp(config);
// Initialize another app
var otherApp = firebase.initializeApp(configOther, 'otherApp');
app.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION); //Set application's authentication session
export const firestore = firebase.firestore(); //Realtime database
export const storage = firebase.storage(); //Cloud storate for images
export const auth = firebase.auth(); //Authentication
export const firestoreother = firebase.firestore(otherApp);
