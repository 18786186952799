import React, { Component } from 'react';
import Spinner from '../../components/Spinner';
import { nextOrderConfig } from '../../configurations/storeSetup';

class CategorySoldOutModal extends Component {
  constructor(props) {
    super(props);
    this.state = { isProcessing: false, isProcessingOnLoad: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemSizes !== this.props.itemSizes) {
      this.setState(
        {
          categorySizes: this.props.itemSizes,
          isProcessingOnLoad: true,
        },
        () => {
          let reqObj = {
            storeId: this.state.categorySizes[0].storeId,
            categoryId: this.props.categoryId,
          };

          fetch(`${nextOrderConfig.cloudFunctionUrl}/glutenFreeSizes`, {
            method: 'post',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...reqObj,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((result) => {
              this.setState({
                isLoaded: true,
              });
              if (result.isDisplayGlutenFree) {
                this.setState({
                  showGlutenFree: true,
                  glutenFreeSizes: result.sizes,
                  isProcessingOnLoad: false,
                });
              } else {
                this.setState({
                  isProcessingOnLoad: false,
                });
              }
            });

          let isAllCategoriesAvailable = [...this.state.categorySizes].every(
            (categorySize) => {
              return (
                categorySize.isAvailable === true &&
                categorySize.isAvailableGlutenFree === true
              );
            }
          );
          this.setState({
            isAllCategoriesAvailable,
          });
        }
      );
    }
  }

  handleChangeChk = (e, p, isGlutenFree) => {
    let newStatus = e.target.checked;
    let newCategorySelectedSizes = [...this.state.categorySizes];
    newCategorySelectedSizes.forEach((size) => {
      if (size._id === p._id) {
        if (isGlutenFree) size.isAvailableGlutenFree = newStatus;
        else size.isAvailable = newStatus;
      }
    });

    this.setState({ isProcessing: true }, () => {
      let reqObj = {
        storeId: p.storeId,
        categoryId: p.categoryId,
        sizeId: p._id,
        isAvailable: isGlutenFree ? p.isAvailable : newStatus,
        isAvailableGlutenFree: isGlutenFree
          ? newStatus
          : p.isAvailableGlutenFree,
        isAllSizes: false,
      };
      fetch(`${nextOrderConfig.cloudFunctionUrl}/categoryAvailability`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...reqObj,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            let isAllCategoriesAvailable = newCategorySelectedSizes.every(
              (categorySize) => {
                return (
                  categorySize.isAvailable === true &&
                  categorySize.isAvailableGlutenFree === true
                );
              }
            );
            this.setState({
              isProcessing: false,
              categorySizes: newCategorySelectedSizes,
              isAllCategoriesAvailable,
            });
          }
        });
    });
  };

  handleAllCategories = (e) => {
    let newStatus = e.target.checked;
    this.setState(
      {
        isAllCategoriesAvailable: newStatus,
        isProcessing: true,
      },
      () => {
        // All the Sizes should be marked as Available
        let newSelectedSizes = [...this.state.categorySizes];
        let reqObj = {
          storeId: this.props.itemSizes[0].storeId,
          categoryId: this.props.categoryId,
          isAvailable: newStatus,
          isAvailableGlutenFree: newStatus,
          isAllSizes: true,
        };
        fetch(`${nextOrderConfig.cloudFunctionUrl}/categoryAvailability`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...reqObj,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.success) {
              newSelectedSizes.map((size) => {
                size.isAvailable = newStatus;
                size.isAvailableGlutenFree = newStatus;
                return size;
              });
              this.setState({
                isProcessing: false,
                categorySizes: newSelectedSizes,
              });
            }
          });
      }
    );
  };

  render() {
    return (
      <div className='col-sm-12 col-md-12'>
        <form
          style={{ borderRadius: '0px' }}
          className={
            this.state.isProcessingOnLoad
              ? 'form-horizontal group-border-dashed noClick'
              : 'form-horizontal group-border-dashed'
          }
        >
          <div
            className={
              this.state.isProcessingOnLoad
                ? 'form-group soldOutParent'
                : 'form-group'
            }
          >
            <div className='col-sm-12 col-md-12'>
              {this.state &&
                this.state.categorySizes &&
                this.state.categorySizes.length > 1 &&
                this.state.isLoaded === true && (
                  <React.Fragment>
                    <div
                      key={`check${999}`}
                      className='be-checkbox be-checkbox-color inline'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '0px',
                        maxWidth: 'fit-content',
                      }}
                    >
                      <input
                        id={`check${999}`}
                        type='checkbox'
                        onChange={(e) => this.handleAllCategories(e)}
                        checked={this.state.isAllCategoriesAvailable}
                      />
                      <label htmlFor={`check${999}`}>ALL</label>
                    </div>
                  </React.Fragment>
                )}
              {this.state &&
                this.state.categorySizes &&
                this.state.isLoaded === true &&
                this.state.categorySizes.map((size, i) => (
                  <div
                    key={`${i}-noGluteenFree`}
                    className='be-checkbox be-checkbox-color inline'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '0px',
                      maxWidth: 'fit-content',
                    }}
                  >
                    <input
                      id={`check${i}`}
                      type='checkbox'
                      onChange={(e) => this.handleChangeChk(e, size, false)}
                      checked={size.isAvailable}
                    />
                    <label htmlFor={`check${i}`}>{size.name}</label>
                  </div>
                ))}
              {this.state && this.state.showGlutenFree === true && (
                <div
                  className='be-checkbox be-checkbox-color inline'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '0px',
                    fontWeight: 'bold',
                  }}
                >
                  (Gluten Free)
                </div>
              )}
              {this.state &&
                this.state.categorySizes &&
                this.state.categorySizes.map((size, i) => (
                  <React.Fragment>
                    {this.state &&
                      this.state.glutenFreeSizes &&
                      this.state.glutenFreeSizes.map((glutenFreeSize) => {
                        if (glutenFreeSize._id === size._id) {
                          return (
                            <div
                              key={`${i}-gluteenFree`}
                              className='be-checkbox be-checkbox-color inline'
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '0px',
                              }}
                            >
                              <input
                                id={`check${i}-g`}
                                type='checkbox'
                                onChange={(e) =>
                                  this.handleChangeChk(e, size, true)
                                }
                                checked={size.isAvailableGlutenFree}
                              />
                              <label htmlFor={`check${i}-g`}>{size.name}</label>
                            </div>
                          );
                        }
                      })}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </form>
        {this.state.isProcessingOnLoad && (
          <div className='spinnerParent' style={{ marginBottom: '40px' }}>
            <Spinner />
          </div>
        )}
        {this.state.isProcessing && (
          <div className='spinnerParent'>
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

CategorySoldOutModal.propTypes = {};

export default CategorySoldOutModal;
