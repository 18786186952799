import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TimePicker } from 'antd';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';
import moment from 'moment-timezone';

const weekDays = [
  { id: '0', value: '--Select day--' },
  { id: '1', value: 'Monday' },
  { id: '2', value: 'Tuesday' },
  { id: '3', value: 'Wednesday' },
  { id: '4', value: 'Thursday' },
  { id: '5', value: 'Friday' },
  { id: '6', value: 'Saturday' },
  { id: '7', value: 'Sunday' },
];

class ShiftForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryType: '1',
      selectedDay: '',
      openingStartUI: '12:00',
      openingEndUI: '18:00',
      deliveryStartUI: '12:00',
      deliveryEndUI: '18:00',
      restaurantStartUI: '12:00',
      restaurantEndUI: '18:00',
      slotStartUI: '00:00',
      slotEndUI: '00:00',
      cutOffUI: '23:30',
      cutOff: 84600,
      cutOffDisplay: '11:30 PM',
      deliveryStartDisplay: '12:00 PM',
      deliveryStart: 43200,
      openingStartDisplay: '12:00 PM',
      openingStart: 43200,
      restaurantStartDisplay: '12:00 PM',
      restaurantStart: 43200,
      deliveryEndDisplay: '06:00 PM',
      deliveryEnd: 64800,
      openingEndDisplay: '06:00 PM',
      openingEnd: 64800,
      restaurantEndDisplay: '06:00 PM',
      restaurantEnd: 64800,
      pickup24: false,
      delivery24: false,
      restaurant24: false,
      noPickup: false,
      noDelivery: false,
      noDineIn: false,
      disabledOpeningHoursArray: [],
      disabledDeliveryHoursArray: [],
      disabledRestaurantHoursArray: [],
      slots: [],
    };
  }

  componentDidMount() {
    this.setState(
      {
        shiftForEdit: { ...this.props.shiftForEdit },
      },
      () => {
        if (this.props.isEditModeOn) {
          this.setState(
            {
              selectedDay: this.state.shiftForEdit.day,
              deliveryType: this.state.shiftForEdit.deliveryType,
              cutOffDisplay: this.state.shiftForEdit.cutOffDisplay
                ? this.state.shiftForEdit.cutOffDisplay
                : '11:30 PM',
              cutOff: this.state.shiftForEdit.cutOff
                ? this.state.shiftForEdit.cutOff
                : 84600,
              slots: this.state.shiftForEdit.slots
                ? this.state.shiftForEdit.slots
                : [],
              pickup24: this.state.shiftForEdit.pickup24,
              delivery24: this.state.shiftForEdit.delivery24,
              restaurant24: this.state.shiftForEdit.restaurant24,
              noPickup: this.state.shiftForEdit.noPickup,
              noDelivery: this.state.shiftForEdit.noDelivery,
              noDineIn: this.state.shiftForEdit.noDineIn,
              openingStartDisplay: this.state.shiftForEdit.openingStartDisplay,
              openingStart: this.state.shiftForEdit.openingStart,
              openingStartUI: moment(
                this.state.shiftForEdit.openingStartDisplay,
                'hh:mm A'
              ).format('HH:mm'),
              openingEndDisplay: this.state.shiftForEdit.openingEndDisplay,
              openingEnd: this.state.shiftForEdit.openingEnd,
              openingEndUI: moment(
                this.state.shiftForEdit.openingEndDisplay,
                'hh:mm A'
              ).format('HH:mm'),
              restaurantStartDisplay: this.state.shiftForEdit
                .restaurantStartDisplay,
              restaurantStart: this.state.shiftForEdit.restaurantStart,
              restaurantStartUI: moment(
                this.state.shiftForEdit.restaurantStartDisplay,
                'hh:mm A'
              ).format('HH:mm'),
              restaurantEndDisplay: this.state.shiftForEdit
                .restaurantEndDisplay,
              restaurantEnd: this.state.shiftForEdit.restaurantEnd,
              restaurantEndUI: moment(
                this.state.shiftForEdit.restaurantEndDisplay,
                'hh:mm A'
              ).format('HH:mm'),
              deliveryStartDisplay: this.state.shiftForEdit
                .deliveryStartDisplay,
              deliveryStart: this.state.shiftForEdit.deliveryStart,
              deliveryStartUI: moment(
                this.state.shiftForEdit.deliveryStartDisplay,
                'hh:mm A'
              ).format('HH:mm'),
              deliveryEndDisplay: this.state.shiftForEdit.deliveryEndDisplay,
              deliveryEnd: this.state.shiftForEdit.deliveryEnd,
              deliveryEndUI: moment(
                this.state.shiftForEdit.deliveryEndDisplay,
                'hh:mm A'
              ).format('HH:mm'),
            },
            () => {
              let openingString = this.state.openingStartUI;
              let hoursO = openingString.split(':')[0];
              let disabledOpeningHoursArray = [];
              for (let i = 0; i < hoursO; i++) {
                disabledOpeningHoursArray.push(i);
              }

              let deliveryString = this.state.deliveryStartUI;
              let hoursD = deliveryString.split(':')[0];
              let disabledDeliveryHoursArray = [];
              for (let i = 0; i < hoursD; i++) {
                disabledDeliveryHoursArray.push(i);
              }

              let restaurantString = this.state.restaurantStartUI;
              let hoursR = restaurantString.split(':')[0];
              let disabledRestaurantHoursArray = [];
              for (let i = 0; i < hoursR; i++) {
                disabledRestaurantHoursArray.push(i);
              }
              this.setState({
                disabledOpeningHoursArray: disabledOpeningHoursArray,
                disabledDeliveryHoursArray: disabledDeliveryHoursArray,
                disabledRestaurantHoursArray: disabledRestaurantHoursArray,
              });
            }
          );
        }
      }
    );
  }

  componentWillReceiveProps(props) {
    this.setState({
      shiftForEdit: { ...props.shiftForEdit },
    });
  }

  handleSubmit = (e) => {
    // Validation Start
    let errors = false;
    if (
      this.state.selectedDay === '' ||
      this.state.openingStartUI === this.state.openingEndUI ||
      this.state.restaurantStartUI === this.state.restaurantEndUI ||
      (this.state.deliveryType === '1' &&
        this.state.deliveryStartUI === this.state.deliveryEndUI) ||
      (this.state.deliveryType === '2' && this.state.slots.length <= 0)
    ) {
      if (this.state.selectedDay === '') {
        this.setState({
          showSelectedDayError: true,
        });
        errors = true;
      }
      if (this.state.openingStartUI === this.state.openingEndUI) {
        if (this.state.noPickup === false && this.state.pickup24 === false) {
          this.setState({
            showPickupTimeError: true,
          });
          errors = true;
        }
      }
      if (this.state.deliveryStartUI === this.state.deliveryEndUI) {
        if (
          this.state.noDelivery === false &&
          this.state.delivery24 === false
        ) {
          this.setState({
            showDeliveryTimeError: true,
          });
          errors = true;
        }
      }
      if (this.state.restaurantStartUI === this.state.restaurantEndUI) {
        if (
          this.state.noDineIn === false &&
          this.state.restaurant24 === false
        ) {
          this.setState({
            showRestaurantTimeError: true,
          });
          errors = true;
        }
      }
      if (this.state.slots.length <= 0) {
        if (
          this.state.noDelivery === false &&
          this.state.delivery24 === false
        ) {
          this.setState({
            showSlotLengthError: true,
          });
          errors = true;
        }
      }
    }
    let openingStartUI = this.state.openingStartUI;
    let openingEndUI = this.state.openingEndUI;
    let deliveryStartUI = this.state.deliveryStartUI;
    let deliveryEndUI = this.state.deliveryEndUI;
    let restaurantStartUI = this.state.restaurantStartUI;
    let restaurantEndUI = this.state.restaurantEndUI;

    if (
      parseInt(openingEndUI.split(':')[0]) <
        parseInt(openingStartUI.split(':')[0]) ||
      parseInt(deliveryEndUI.split(':')[0]) <
        parseInt(deliveryStartUI.split(':')[0]) ||
      parseInt(restaurantEndUI.split(':')[0]) <
        parseInt(restaurantStartUI.split(':')[0])
    ) {
      if (
        parseInt(openingEndUI.split(':')[0]) <
        parseInt(openingStartUI.split(':')[0])
      ) {
        if (this.state.noPickup === false && this.state.pickup24 === false) {
          this.setState({
            showPickupWrongTimeError: true,
          });
          errors = true;
        }
      }
      if (
        parseInt(deliveryEndUI.split(':')[0]) <
        parseInt(deliveryStartUI.split(':')[0])
      ) {
        if (
          this.state.noDelivery === false &&
          this.state.delivery24 === false
        ) {
          this.setState({
            showDeliveryWrongTimeError: true,
          });
          errors = true;
        }
      }
      if (
        parseInt(restaurantEndUI.split(':')[0]) <
        parseInt(restaurantStartUI.split(':')[0])
      ) {
        if (
          this.state.noDineIn === false &&
          this.state.restaurant24 === false
        ) {
          this.setState({
            showRestaurantWrongTimeError: true,
          });
          errors = true;
        }
      }
    }
    // Validation End
    if (errors === false) {
      // Submit
      let values = {};

      if (this.props.isEditModeOn) {
        values = { ...this.state.shiftForEdit };
        values._id = this.state.shiftForEdit._id;
      } else {
        values.date = getCurrentFormattedMomentDate(
          this.props.timeZone,
          'YYYY-MM-DD HH:mm'
        );
        //UTC time stamp of date
        values.dateTimestamp = getCurrentTimestamp();
        values.isCurrent = false;
        values.isDeliveryOn = false;
        values.isDeliveryOpen = false;
        values.totalOrders = 0;
        values.totalRevenue = 0;
        values.totalDeliveryOrders = 0;
        values.deliveryRevenue = 0;
        values.totalPickupOrders = 0;
        values.pickupRevenue = 0;
        values.averageRevenue = 0;
        values.averagePickupRevenue = 0;
        values.averageDeliveryRevenue = 0;
      }

      values.day = this.state.selectedDay;
      values.deliveryType = this.state.deliveryType;
      if (this.state.deliveryType === '2') {
        values.cutOff = this.state.cutOff;
        values.cutOffDisplay = this.state.cutOffDisplay;
        values.slots = this.state.slots;
      } else if (this.state.deliveryType === '1') {
        values.deliveryStartDisplay = this.state.deliveryStartDisplay;
        values.deliveryStart = this.state.deliveryStart;
        values.deliveryEndDisplay = this.state.deliveryEndDisplay;
        values.deliveryEnd = this.state.deliveryEnd;
      }
      values.openingStartDisplay = this.state.openingStartDisplay;
      values.openingStart = this.state.openingStart;
      values.openingEndDisplay = this.state.openingEndDisplay;
      values.openingEnd = this.state.openingEnd;
      values.restaurantStartDisplay = this.state.restaurantStartDisplay;
      values.restaurantStart = this.state.restaurantStart;
      values.restaurantEndDisplay = this.state.restaurantEndDisplay;
      values.restaurantEnd = this.state.restaurantEnd;

      values.pickup24 = this.state.pickup24;
      values.delivery24 = this.state.delivery24;
      values.restaurant24 = this.state.restaurant24;

      values.noPickup = this.state.noPickup ? this.state.noPickup : false;
      values.noDelivery = this.state.noDelivery ? this.state.noDelivery : false;
      values.noDineIn = this.state.noDineIn ? this.state.noDineIn : false;

      this.props.addShiftHandler(values);
      this.props.closeShiftModal();
    }
  };

  handleDropdownChange = (e) => {
    this.setState({
      selectedDay: e.target.value,
      showSelectedDayError: false,
    });
  };

  handleRdoTypeChange = (e) => {
    this.setState({
      deliveryType: e.target.value,
    });
  };

  handleOpeningStartTimeChange = (time, timeString) => {
    this.setState(
      {
        openingStartUI: timeString,
        showPickupTimeError: false,
        showPickupWrongTimeError: false,
      },
      () => {
        let openingString = this.state.openingStartUI;
        let hours = openingString.split(':')[0];
        let disabledOpeningHoursArray = [];
        for (let i = 0; i < hours; i++) {
          disabledOpeningHoursArray.push(i);
        }
        this.setState({
          openingStartDisplay: moment(
            this.state.openingStartUI,
            'HH:mm'
          ).format('hh:mm A'),
          openingStart:
            moment(this.state.openingStartUI, 'HH:mm').diff(
              moment().startOf('day'),
              'seconds'
            ) === 0
              ? 1
              : moment(this.state.openingStartUI, 'HH:mm').diff(
                  moment().startOf('day'),
                  'seconds'
                ),
          disabledOpeningHoursArray: disabledOpeningHoursArray,
        });
      }
    );
  };

  handleOpeningEndTimeChange = (time, timeString) => {
    this.setState(
      {
        openingEndUI: timeString,
        showPickupTimeError: false,
        showPickupWrongTimeError: false,
      },
      () => {
        this.setState({
          openingEndDisplay: moment(this.state.openingEndUI, 'HH:mm').format(
            'hh:mm A'
          ),
          openingEnd: moment(this.state.openingEndUI, 'HH:mm').diff(
            moment().startOf('day'),
            'seconds'
          ),
        });
      }
    );
  };

  handleDeliveryStartTimeChange = (time, timeString) => {
    this.setState(
      {
        deliveryStartUI: timeString,
        showDeliveryTimeError: false,
        showDeliveryWrongTimeError: false,
      },
      () => {
        let deliveryString = this.state.deliveryStartUI;
        let hours = deliveryString.split(':')[0];
        let disabledDeliveryHoursArray = [];
        for (let i = 0; i < hours; i++) {
          disabledDeliveryHoursArray.push(i);
        }
        this.setState({
          deliveryStartDisplay: moment(
            this.state.deliveryStartUI,
            'HH:mm'
          ).format('hh:mm A'),
          deliveryStart:
            moment(this.state.deliveryStartUI, 'HH:mm').diff(
              moment().startOf('day'),
              'seconds'
            ) === 0
              ? 1
              : moment(this.state.deliveryStartUI, 'HH:mm').diff(
                  moment().startOf('day'),
                  'seconds'
                ),
          disabledDeliveryHoursArray: disabledDeliveryHoursArray,
        });
      }
    );
  };

  handleDeliveryEndTimeChange = (time, timeString) => {
    this.setState(
      {
        deliveryEndUI: timeString,
        showDeliveryTimeError: false,
        showDeliveryWrongTimeError: false,
      },
      () => {
        this.setState({
          deliveryEndDisplay: moment(this.state.deliveryEndUI, 'HH:mm').format(
            'hh:mm A'
          ),
          deliveryEnd: moment(this.state.deliveryEndUI, 'HH:mm').diff(
            moment().startOf('day'),
            'seconds'
          ),
        });
      }
    );
  };

  handleRestaurantStartTimeChange = (time, timeString) => {
    this.setState(
      {
        restaurantStartUI: timeString,
        showRestaurantTimeError: false,
        showRestaurantWrongTimeError: false,
      },
      () => {
        let restaurantString = this.state.restaurantStartUI;
        let hours = restaurantString.split(':')[0];
        let disabledRestaurantHoursArray = [];
        for (let i = 0; i < hours; i++) {
          disabledRestaurantHoursArray.push(i);
        }
        this.setState({
          restaurantStartDisplay: moment(
            this.state.restaurantStartUI,
            'HH:mm'
          ).format('hh:mm A'),
          restaurantStart:
            moment(this.state.restaurantStartUI, 'HH:mm').diff(
              moment().startOf('day'),
              'seconds'
            ) === 0
              ? 1
              : moment(this.state.restaurantStartUI, 'HH:mm').diff(
                  moment().startOf('day'),
                  'seconds'
                ),
          disabledRestaurantHoursArray: disabledRestaurantHoursArray,
        });
      }
    );
  };

  handleRestaurantEndTimeChange = (time, timeString) => {
    this.setState(
      {
        restaurantEndUI: timeString,
        showRestaurantTimeError: false,
        showRestaurantWrongTimeError: false,
      },
      () => {
        this.setState({
          restaurantEndDisplay: moment(
            this.state.restaurantEndUI,
            'HH:mm'
          ).format('hh:mm A'),
          restaurantEnd: moment(this.state.restaurantEndUI, 'HH:mm').diff(
            moment().startOf('day'),
            'seconds'
          ),
        });
      }
    );
  };

  handleCutoffTimeChange = (time, timeString) => {
    this.setState(
      {
        cutOffUI: timeString,
      },
      () => {
        this.setState({
          cutOffDisplay: moment(this.state.cutOffUI, 'HH:mm').format('hh:mm A'),
          cutOff: moment(this.state.cutOffUI, 'HH:mm').diff(
            moment().startOf('day'),
            'seconds'
          ),
        });
      }
    );
  };

  handleSlotStartTimeChange = (time, timeString) => {
    this.setState(
      {
        slotStartUI: timeString,
        showSlotTimeError: false,
        showSlotWrongTimeError: false,
      },
      () => {
        let slotString = this.state.slotStartUI;
        let hours = slotString.split(':')[0];
        let disabledSlotHoursArray = [];
        for (let i = 0; i < hours; i++) {
          disabledSlotHoursArray.push(i);
        }
        this.setState({
          slotStartDisplay: moment(this.state.slotStartUI, 'HH:mm').format(
            'hh:mm A'
          ),
          slotStart:
            moment(this.state.slotStartUI, 'HH:mm').diff(
              moment().startOf('day'),
              'seconds'
            ) === 0
              ? 1
              : moment(this.state.slotStartUI, 'HH:mm').diff(
                  moment().startOf('day'),
                  'seconds'
                ),
          disabledSlotHoursArray: disabledSlotHoursArray,
        });
      }
    );
  };

  handleSlotEndTimeChange = (time, timeString) => {
    this.setState(
      {
        slotEndUI: timeString,
        showSlotTimeError: false,
        showSlotWrongTimeError: false,
      },
      () => {
        this.setState({
          slotEndDisplay: moment(this.state.slotEndUI, 'HH:mm').format(
            'hh:mm A'
          ),
          slotEnd: moment(this.state.slotEndUI, 'HH:mm').diff(
            moment().startOf('day'),
            'seconds'
          ),
        });
      }
    );
  };

  handlePickupCheckbox = (e) => {
    this.setState({
      pickup24: e.target.checked,
      showPickupTimeError: false,
    });
  };

  handleDeliveryCheckbox = (e) => {
    this.setState({
      delivery24: e.target.checked,
      showDeliveryTimeError: false,
    });
  };

  handleRestaurantCheckbox = (e) => {
    this.setState({
      restaurant24: e.target.checked,
      showRestaurantTimeError: false,
    });
  };

  handleNoPickupCheckbox = (e) => {
    this.setState({
      noPickup: e.target.checked,
      showPickupTimeError: false,
    });
  };

  handleNoDeliveryCheckbox = (e) => {
    this.setState({
      noDelivery: e.target.checked,
      showDeliveryTimeError: false,
    });
  };

  handleNoDineInCheckbox = (e) => {
    this.setState({
      noDineIn: e.target.checked,
      showRestaurantTimeError: false,
    });
  };

  addSlot = () => {
    // Validation Start
    let errors = false;
    if (this.state.slotStartUI === this.state.slotEndUI) {
      this.setState({
        showSlotTimeError: true,
      });

      errors = true;
    }

    let slotStartUI = this.state.slotStartUI;
    let slotEndUI = this.state.slotEndUI;

    if (
      parseInt(slotEndUI.split(':')[0]) < parseInt(slotStartUI.split(':')[0])
    ) {
      this.setState({
        showSlotWrongTimeError: true,
      });

      errors = true;
    }
    // Validation End

    if (errors === false) {
      let obj = {
        index: this.state.slots.length + 1,
        display: this.state.slots.length + 1,
        slotStart: this.state.slotStart,
        slotStartDisplay: this.state.slotStartDisplay,
        slotStartUI: this.state.slotStartUI,
        slotEnd: this.state.slotEndUI,
        slotEndDisplay: this.state.slotEndDisplay,
        slotEndUI: this.state.slotEndUI,
      };
      this.setState({
        slots: [...this.state.slots, obj],
        slotStartUI: '00:00',
        slotEndUI: '00:00',
        showSlotLengthError: false,
        showSlotTimeError: false,
      });
    }
  };

  render() {
    return (
      <div>
        <div className='modal-body'>
          <div className='col-md-12'>
            <div className='text-center'>
              <div className='form-horizontal group-border-dashed'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label className='col-sm-3 control-label'>{'Day'}</label>
                      <div className='col-md-8'>
                        <select
                          className='form-control'
                          value={this.state.selectedDay}
                          onChange={this.handleDropdownChange}
                        >
                          {weekDays &&
                            weekDays.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
                        </select>
                        {this.state.showSelectedDayError === true && (
                          <ul
                            className='parsley-errors-list filled'
                            id='parsley-id-7'
                          >
                            <li
                              style={{ fontSize: '13px' }}
                              className='parsley-type'
                            >
                              Empty Shift Cannot be Added
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-md-6' style={{ display: 'block' }}>
                    <div className='col-md-12' style={{ display: 'flex' }}>
                      <div className='form-group' style={{ marginLeft: '5%' }}>
                        <label className='col-md-6 control-label'>
                          {'Pickup Start'}
                        </label>
                        <div className='col-md-6 ant-picker'>
                          <TimePicker
                            format={'HH:mm'}
                            minuteStep={15}
                            onChange={this.handleOpeningStartTimeChange}
                            value={moment(this.state.openingStartUI, 'HH:mm')}
                            allowClear={false}
                          />
                        </div>
                      </div>
                      <div className='form-group' style={{ marginLeft: '5%' }}>
                        <label className='col-md-6 control-label'>
                          {'Pickup End'}
                        </label>
                        <div className='col-md-6 ant-picker'>
                          <TimePicker
                            format={'HH:mm'}
                            minuteStep={15}
                            onChange={this.handleOpeningEndTimeChange}
                            value={moment(this.state.openingEndUI, 'HH:mm')}
                            allowClear={false}
                            disabledHours={() => {
                              return this.state.disabledOpeningHoursArray;
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {this.state.showPickupTimeError === true && (
                      <div style={{ width: '100%' }} className='col-md-12'>
                        <ul
                          className='parsley-errors-list filled'
                          id='parsley-id-7'
                        >
                          <li
                            style={{ fontSize: '13px' }}
                            className='parsley-type'
                          >
                            Pickup Start and End time cannot be same
                          </li>
                        </ul>
                      </div>
                    )}
                    {this.state.showPickupWrongTimeError === true && (
                      <div style={{ width: '100%' }} className='col-md-12'>
                        <ul
                          className='parsley-errors-list filled'
                          id='parsley-id-7'
                          style={{ width: '65%', left: '26%' }}
                        >
                          <li
                            style={{ fontSize: '13px' }}
                            className='parsley-type'
                          >
                            Pickup End time cannot be before Start time
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6' style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '50%' }}>
                      <label className='control-label'>
                        Pickup open 24 hours?
                      </label>
                      <div style={{ paddingTop: '11px' }} className='col-xs-3'>
                        <div style={{ float: 'left' }}>
                          <input
                            name='pickup24'
                            id='pickup24'
                            checked={this.state.pickup24}
                            type='checkbox'
                            onChange={this.handlePickupCheckbox}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', width: '50%' }}>
                      <label className='control-label'>Pickup Closed?</label>
                      <div style={{ paddingTop: '11px' }} className='col-xs-3'>
                        <div style={{ float: 'left' }}>
                          <input
                            name='pickup24'
                            id='pickup24'
                            checked={this.state.noPickup}
                            type='checkbox'
                            onChange={this.handleNoPickupCheckbox}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>
                        Delivery Type
                      </label>
                      <div className='col-md-4'>
                        <div className='be-checkbox inline'>
                          <input
                            name='deliveryType'
                            id='type_prom0'
                            checked={this.state.deliveryType === '1'}
                            type='radio'
                            value='1'
                            onChange={this.handleRdoTypeChange}
                          />
                          <label htmlFor='type_prom0'>At intervals</label>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='be-checkbox inline'>
                          <input
                            name='deliveryType'
                            id='type_user'
                            checked={this.state.deliveryType === '2'}
                            type='radio'
                            value='2'
                            onChange={this.handleRdoTypeChange}
                          />
                          <label htmlFor='type_user'>In slots</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-6' style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '50%' }}>
                      <label className='control-label'>
                        Delivery open 24 hours?
                      </label>
                      <div style={{ paddingTop: '11px' }} className='col-xs-3'>
                        <div style={{ float: 'left' }}>
                          <input
                            name='delivery24'
                            id='delivery24'
                            type='checkbox'
                            checked={this.state.delivery24}
                            onChange={this.handleDeliveryCheckbox}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', width: '50%' }}>
                      <label className='control-label'>Delivery Closed?</label>
                      <div style={{ paddingTop: '11px' }} className='col-xs-3'>
                        <div style={{ float: 'left' }}>
                          <input
                            name='pickup24'
                            id='pickup24'
                            checked={this.state.noDelivery}
                            type='checkbox'
                            onChange={this.handleNoDeliveryCheckbox}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.deliveryType === '1' ? (
                  <React.Fragment>
                    <div className='row'>
                      <div className='col-md-6' style={{ display: 'block' }}>
                        <div className='col-md-12' style={{ display: 'flex' }}>
                          <div
                            className='form-group'
                            style={{ marginLeft: '4%' }}
                          >
                            <label className='col-md-6 control-label'>
                              {'Delivery Start'}
                            </label>
                            <div className='col-md-6 ant-picker'>
                              <TimePicker
                                format={'HH:mm'}
                                minuteStep={15}
                                onChange={this.handleDeliveryStartTimeChange}
                                value={moment(
                                  this.state.deliveryStartUI,
                                  'HH:mm'
                                )}
                                allowClear={false}
                              />
                            </div>
                          </div>
                          <div
                            className='form-group'
                            style={{ marginLeft: '4%' }}
                          >
                            <label className='col-md-6 control-label'>
                              {'Delivery End'}
                            </label>
                            <div className='col-md-6 ant-picker'>
                              <TimePicker
                                format={'HH:mm'}
                                minuteStep={15}
                                onChange={this.handleDeliveryEndTimeChange}
                                value={moment(
                                  this.state.deliveryEndUI,
                                  'HH:mm'
                                )}
                                allowClear={false}
                                disabledHours={() => {
                                  return this.state.disabledDeliveryHoursArray;
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {this.state.showDeliveryTimeError === true && (
                          <div style={{ width: '100%' }} className='col-md-12'>
                            <ul
                              className='parsley-errors-list filled'
                              id='parsley-id-7'
                            >
                              <li
                                style={{ fontSize: '13px' }}
                                className='parsley-type'
                              >
                                Delivery Start and End time cannot be same
                              </li>
                            </ul>
                          </div>
                        )}
                        {this.state.showDeliveryWrongTimeError === true && (
                          <div style={{ width: '100%' }} className='col-md-12'>
                            <ul
                              className='parsley-errors-list filled'
                              id='parsley-id-7'
                            >
                              <li
                                style={{ fontSize: '13px' }}
                                className='parsley-type'
                              >
                                Delivery End time cannot be before Start time
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className='row'>
                      <div className='col-md-6' style={{ display: 'flex' }}>
                        <div
                          className='form-group'
                          style={{ marginLeft: '3%' }}
                        >
                          <label className='col-md-6 control-label'>
                            {'Cut off time'}
                          </label>
                          <div className='col-md-6 ant-picker'>
                            <TimePicker
                              format={'HH:mm'}
                              minuteStep={30}
                              onChange={this.handleCutoffTimeChange}
                              value={moment(this.state.cutOffUI, 'HH:mm')}
                              allowClear={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6' style={{ display: 'block' }}>
                        <div className='col-md-12' style={{ display: 'flex' }}>
                          <div
                            className='form-group'
                            style={{ marginLeft: '3%' }}
                          >
                            <label className='col-md-6 control-label'>
                              {'Slot Start'}
                            </label>
                            <div className='col-md-6 ant-picker'>
                              <TimePicker
                                format={'HH:mm'}
                                minuteStep={30}
                                onChange={this.handleSlotStartTimeChange}
                                value={moment(this.state.slotStartUI, 'HH:mm')}
                                allowClear={false}
                              />
                            </div>
                          </div>
                          <div
                            className='form-group'
                            style={{ marginLeft: '1%' }}
                          >
                            <label className='col-md-6 control-label'>
                              {'Slot End'}
                            </label>
                            <div className='col-md-6 ant-picker'>
                              <TimePicker
                                format={'HH:mm'}
                                minuteStep={30}
                                onChange={this.handleSlotEndTimeChange}
                                value={moment(this.state.slotEndUI, 'HH:mm')}
                                allowClear={false}
                                disabledHours={() => {
                                  return this.state.disabledSlotHoursArray;
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.showSlotTimeError === true && (
                          <div style={{ width: '100%' }} className='col-md-12'>
                            <ul
                              className='parsley-errors-list filled'
                              id='parsley-id-7'
                              style={{ width: '65%', left: '26%' }}
                            >
                              <li
                                style={{ fontSize: '13px' }}
                                className='parsley-type'
                              >
                                Slot Start and End time cannot be same
                              </li>
                            </ul>
                          </div>
                        )}
                        {this.state.showSlotWrongTimeError === true && (
                          <div style={{ width: '100%' }} className='col-md-12'>
                            <ul
                              className='parsley-errors-list filled'
                              id='parsley-id-7'
                              style={{ width: '65%', left: '26%' }}
                            >
                              <li
                                style={{ fontSize: '13px' }}
                                className='parsley-type'
                              >
                                Slot End time cannot be before Start time
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className='col-md-6' style={{ display: 'flex' }}>
                        <div className='form-group'>
                          <button
                            onClick={this.addSlot}
                            type='button'
                            data-dismiss='modal'
                            className='btn btn-primary btn-space'
                          >
                            Add slot
                          </button>
                        </div>
                      </div>
                    </div>
                    {this.state.slots && this.state.slots.length > 0 && (
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='col-md-7 table-responcive col-md-offset-2'>
                            <table className='table'>
                              <thead>
                                <tr>
                                  <th>Index</th>
                                  <th>Slot</th>
                                  <th>&nbsp; </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.slots &&
                                this.state.slots.length > 0 ? (
                                  this.state.slots.map((slot, index) => (
                                    <tr key={index}>
                                      <td>{slot.index}</td>
                                      <td>{`${slot.slotStartDisplay} - ${slot.slotEndDisplay}`}</td>
                                      <td></td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan='4'>
                                      {this.state.isValidationError &&
                                      this.state.slots.length < 1 ? (
                                        <ul
                                          className='parsley-errors-list filled'
                                          id='parsley-id-7'
                                        >
                                          <li
                                            style={{ fontSize: '13px' }}
                                            className='parsley-type'
                                          >
                                            At least one slot required in shift
                                          </li>
                                        </ul>
                                      ) : (
                                        'No items available'
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.showSlotLengthError === true && (
                      <ul
                        className='parsley-errors-list filled'
                        id='parsley-id-7'
                        style={{ width: '48%' }}
                      >
                        <li
                          style={{ fontSize: '13px' }}
                          className='parsley-type'
                        >
                          Slots not added
                        </li>
                      </ul>
                    )}
                  </React.Fragment>
                )}
                <hr />
                <div className='row'>
                  <div className='col-md-6' style={{ display: 'block' }}>
                    <div className='col-md-12' style={{ display: 'flex' }}>
                      <div className='form-group' style={{ marginLeft: '3%' }}>
                        <label className='col-md-6 control-label'>
                          {'Dine In Start'}
                        </label>
                        <div className='col-md-6 ant-picker'>
                          <TimePicker
                            format={'HH:mm'}
                            minuteStep={15}
                            onChange={this.handleRestaurantStartTimeChange}
                            value={moment(
                              this.state.restaurantStartUI,
                              'HH:mm'
                            )}
                            allowClear={false}
                          />
                        </div>
                      </div>
                      <div className='form-group' style={{ marginLeft: '1%' }}>
                        <label className='col-md-6 control-label'>
                          {'Dine In End'}
                        </label>
                        <div className='col-md-6 ant-picker'>
                          <TimePicker
                            format={'HH:mm'}
                            minuteStep={15}
                            onChange={this.handleRestaurantEndTimeChange}
                            value={moment(this.state.restaurantEndUI, 'HH:mm')}
                            allowClear={false}
                            disabledHours={() => {
                              return this.state.disabledRestaurantHoursArray;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.showRestaurantTimeError === true && (
                      <div style={{ width: '100%' }} className='col-md-12'>
                        <ul
                          className='parsley-errors-list filled'
                          id='parsley-id-7'
                        >
                          <li
                            style={{ fontSize: '13px' }}
                            className='parsley-type'
                          >
                            Dine In Start and End time cannot be same
                          </li>
                        </ul>
                      </div>
                    )}
                    {this.state.showRestaurantWrongTimeError === true && (
                      <div style={{ width: '100%' }} className='col-md-12'>
                        <ul
                          className='parsley-errors-list filled'
                          id='parsley-id-7'
                        >
                          <li
                            style={{ fontSize: '13px' }}
                            className='parsley-type'
                          >
                            Dine In End time cannot be before Start time
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className='col-md-6' style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '50%' }}>
                      <label className='control-label'>
                        Dine In open 24 hours?
                      </label>
                      <div style={{ paddingTop: '11px' }} className='col-xs-3'>
                        <div style={{ float: 'left' }}>
                          <input
                            name='restaurant24'
                            id='restaurant24'
                            type='checkbox'
                            checked={this.state.restaurant24}
                            onChange={this.handleRestaurantCheckbox}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: 'flex', width: '50%' }}>
                      <label className='control-label'>Dine In Closed?</label>
                      <div style={{ paddingTop: '11px' }} className='col-xs-3'>
                        <div style={{ float: 'left' }}>
                          <input
                            name='pickup24'
                            id='pickup24'
                            checked={this.state.noDineIn}
                            type='checkbox'
                            onChange={this.handleNoDineInCheckbox}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className='row xs-pt-15'>
                  <div className='col-xs-6' />
                  <div className='col-xs-6'>
                    <p className='text-right'>
                      <button
                        onClick={this.props.closeShiftModal}
                        type='button'
                        data-dismiss='modal'
                        className='btn btn-default btn-space'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary btn-space'
                        onClick={this.handleSubmit}
                      >
                        Save
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ShiftForm.propTypes = {
  addShiftHandler: PropTypes.any,
  closeShiftModal: PropTypes.any,
  isEditModeOn: PropTypes.any,
  totalCount: PropTypes.any,
  shiftForEdit: PropTypes.any,
};

export default ShiftForm;
