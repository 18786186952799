import React, { Component } from 'react';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import { Modal } from 'react-bootstrap';
import { nextOrderConfig } from '../../configurations/storeSetup';
import './style.css';

class InvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendToCustomerMobileNumber: true,
      sendToEmailAddress: false,
      customerEmail: '',
      companyName: '',
      companyEmail: '',
      companyBusinessNumber: '',
      companyAddress: '',
      selectedRadio: 'customerEmail',
    };
  }

  componentDidMount() {}

  handleCustomerMobileNumberCheckbox = (e) => {
    this.setState(
      {
        sendToCustomerMobileNumber: e.target.checked,
      },
      () => {
        this.validateModal();
      }
    );
  };

  handleEmailCheckbox = (e) => {
    this.setState(
      {
        sendToEmailAddress: e.target.checked,
      },
      () => {
        this.validateModal();
      }
    );
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.validateModal();
      }
    );
  };

  validateModal = () => {
    let regex = /\S+@\S+\.\S+/;
    if (
      this.state.sendToCustomerMobileNumber === false &&
      this.state.sendToEmailAddress === false
    ) {
      this.setState({
        disableSendButton: true,
      });
      return false;
    } else {
      if (this.state.sendToEmailAddress === true) {
        if (
          this.state.selectedRadio === 'customerEmail' &&
          !regex.test(this.state.customerEmail)
        ) {
          this.setState({
            disableSendButton: true,
          });
          return false;
        }

        if (
          this.state.selectedRadio === 'companyEmail' &&
          (this.state.companyName === '' ||
            !regex.test(this.state.companyEmail) ||
            this.state.companyBusinessNumber === '' ||
            this.state.companyAddress === '')
        ) {
          this.setState({
            disableSendButton: true,
          });
          return false;
        }
      }
      this.setState({
        disableSendButton: false,
      });
      return true;
    }
  };

  sendInvoice = () => {
    let isAllowed = this.validateModal();
    if (isAllowed) {
      this.setState({
        sendingInvoice: true,
        disableSendButton: true,
      });
      let reqObj = {};
      reqObj.orderId = this.props.order._id;
      reqObj.sendSMS = false;
      reqObj.sendEmail = false;
      if (this.state.sendToCustomerMobileNumber) {
        reqObj.sendSMS = true;
      }
      if (this.state.sendToEmailAddress) {
        if (this.state.selectedRadio === 'customerEmail') {
          reqObj.sendEmail = true;
          reqObj.sendEmailTo = 'customer';
          reqObj.customerEmail = this.state.customerEmail;
        }

        if (this.state.selectedRadio === 'companyEmail') {
          reqObj.sendEmail = true;
          reqObj.sendEmailTo = 'company';
          reqObj.companyName = this.state.companyName;
          reqObj.companyEmail = this.state.companyEmail;
          reqObj.companyBusinessNumber = this.state.companyBusinessNumber;
          reqObj.companyAddress = this.state.companyAddress;
        }
      }

      fetch(`${nextOrderConfig.cloudFunctionUrl}/sendInvoice`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...reqObj,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          this.setState({
            sendingInvoice: false,
            disableSendButton: false,
          });
          toaster.notify('Invoice Sent', {
            duration: 2000,
          });
        });
    }
  };

  radioButtonChanged = (e) => {
    this.setState(
      {
        selectedRadio: e.target.value,
      },
      () => {
        this.validateModal();
      }
    );
  };

  render() {
    return (
      <Modal
        aria-labelledby='modal-label'
        backdrop='static'
        keyboard={false}
        className='modal fade invoice-modal'
        show={this.props.renderInvoiceModal}
        onHide={this.props.closeInvoiceModal}
        bsSize='small'
      >
        <div style={{ border: '1px solid #c1c1c1' }}>
          <div className='modal-header' style={{ marginLeft: '18px' }}>
            <button
              onClick={this.props.closeInvoiceModal}
              type='button'
              data-dismiss='modal'
              aria-hidden='true'
              className='close md-close'
            >
              <span className='mdi mdi-close' />
            </button>
            <h3 className='modal-title'>Send Invoice</h3>
          </div>
          <div className='modal-body'>
            <div className='invoice-container'>
              <div className='invoice-item'>
                <input
                  type='checkbox'
                  checked={this.state.sendToCustomerMobileNumber}
                  onChange={(e) => this.handleCustomerMobileNumberCheckbox(e)}
                  className='invoice-checkbox'
                />
                <span className='invoice-sub-item'>
                  Send Invoice to Customer's Mobile Number
                </span>
              </div>
              <div className='invoice-item'>
                <input
                  type='checkbox'
                  checked={this.state.sendToEmailAddress}
                  onChange={(e) => this.handleEmailCheckbox(e)}
                  className='invoice-checkbox'
                />
                <span className='invoice-sub-item'>
                  Send Invoice to Email Address
                </span>
              </div>

              {this.state.sendToEmailAddress === true && (
                <div>
                  <div style={{ marginBottom: '14px' }}>
                    <input
                      type='radio'
                      name='invoiceEmail'
                      value={'customerEmail'}
                      checked={this.state.selectedRadio === 'customerEmail'}
                      onChange={(e) => this.radioButtonChanged(e)}
                      className='invoice-radio'
                    />
                    <span className='invoice-sub-item'>
                      Customer's Email Address
                    </span>
                  </div>

                  {this.state.selectedRadio === 'customerEmail' && (
                    <React.Fragment>
                      <div className='invoice-email-container'>
                        <span>Email:</span>
                        <input
                          type='email'
                          className='invoice-textbox'
                          placeholder={`Customer's Email`}
                          value={this.state.customerEmail}
                          name='customerEmail'
                          onChange={this.handleChange}
                        />
                      </div>
                    </React.Fragment>
                  )}

                  <div style={{ marginBottom: '14px' }}>
                    <input
                      type='radio'
                      name='invoiceEmail'
                      value={'companyEmail'}
                      checked={this.state.selectedRadio === 'companyEmail'}
                      onChange={(e) => this.radioButtonChanged(e)}
                      className='invoice-radio'
                    />
                    <span className='invoice-sub-item'>
                      Company's Email Address
                    </span>
                  </div>

                  {this.state.selectedRadio === 'companyEmail' && (
                    <React.Fragment>
                      <div className='invoice-email-container'>
                        <span>Name:</span>
                        <input
                          type='text'
                          className='invoice-textbox'
                          placeholder='Company Name'
                          style={{ width: '41.5%' }}
                          value={this.state.companyName}
                          name='companyName'
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='invoice-email-container'>
                        <span>Email:</span>
                        <input
                          type='email'
                          className='invoice-textbox'
                          placeholder={`Company's Email`}
                          value={this.state.companyEmail}
                          name='companyEmail'
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='invoice-email-container'>
                        <span>Business Num:</span>
                        <input
                          type='text'
                          className='invoice-textbox'
                          placeholder={`Company Business Number`}
                          style={{ width: '32.5%' }}
                          value={this.state.companyBusinessNumber}
                          name='companyBusinessNumber'
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className='invoice-email-container'>
                        <span>Address:</span>
                        <input
                          type='text'
                          className='invoice-textbox'
                          placeholder={`Company's Address`}
                          style={{ width: '39%' }}
                          value={this.state.companyAddress}
                          name='companyAddress'
                          onChange={this.handleChange}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='invoice-footer'>
            <div
              style={{ marginLeft: '18px' }}
              style={{ display: 'flex' }}
            ></div>
            <div>
              <button
                onClick={() => this.sendInvoice()}
                type='button'
                className='btn btn-space btn-success active'
                style={{ boxShadow: 'none' }}
                disabled={this.state.disableSendButton}
              >
                {this.state.sendingInvoice ? 'Sending' : 'Send'}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

InvoiceModal.propTypes = {};

export default InvoiceModal;
