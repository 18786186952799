import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openItemNumber: 0,
      toggleLIs: [
        { id: 0, isOpen: false },
        { id: 1, isOpen: false },
        { id: 2, isOpen: false },
        { id: 3, isOpen: false },
        { id: 4, isOpen: false },
        { id: 5, isOpen: false },
        { id: 6, isOpen: false },
        { id: 7, isOpen: false },
        { id: 8, isOpen: false },
        {
          id: 9,
          isOpen: false,
        },
      ],
      isNavigationDropdownOpen: false,
    };
    this.keyObj =
      window.location && window.location.search
        ? queryString.parse(window.location.search)
        : { restaurant: '' };
  }

  componentDidMount() {
    const mainc = window.location.pathname.split('/')[2];
    let index = 0;
    switch (mainc) {
      case 'dashboard':
        index = 0;
        break;
      case 'menu':
        index = 1;
        break;
      case 'marketing':
        index = 2;
        break;
      case 'delivery':
        index = 3;
        break;
      case 'pickup':
        index = 4;
        break;
      case 'reservation':
        index = 5;
        break;
      case 'settings':
        index = 6;
        break;
      case 'other-delivery':
        index = 7;
        break;
      case 'loyalty':
        index = 8;
        break;
      case 'payment':
        index = 9;
        break;
      default:
        index = 0;
        break;
    }
    this.state.toggleLIs[index].isOpen = true;
    this.setState({ openItemNumber: index });
  }

  componentDidUpdate(preProps) {
    if (!this.state.user && !preProps.user && this.props.user) {
      this.setState({ user: this.props.user });
    }
  }

  changeOpenItemNumber = (number) => {
    this.state.toggleLIs.map((li) => {
      if (li.id === number) li.isOpen = !li.isOpen;
      else li.isOpen = false;
      return li;
    });
    this.setState({ ...this.state });
  };

  getClassName = (openItemNumber) => {
    if (
      openItemNumber === this.state.openItemNumber &&
      this.state.toggleLIs[openItemNumber].isOpen
    ) {
      return 'parent active open';
    } else if (
      openItemNumber === this.state.openItemNumber &&
      !this.state.toggleLIs[openItemNumber].isOpen
    ) {
      return 'parent active';
    } else if (
      openItemNumber !== this.state.openItemNumber &&
      this.state.toggleLIs[openItemNumber].isOpen
    ) {
      return 'parent open';
    } else {
      return 'parent';
    }
  };

  handleNavigatorClick = (e) => {
    this.setState((prevState) => {
      return {
        isNavigationDropdownOpen: !prevState.isNavigationDropdownOpen,
      };
    });
  };

  render() {
    const mainc = window.location.pathname.split('/')[2];
    const subc = window.location.pathname.split('/')[3];
    const style1 = { left: '0px', bottom: '0px' };
    const style2 = { top: '0px', right: '0px', height: '234px' };
    const style3 = { top: '0px', height: '138px' };
    const pointer = { cursor: 'pointer' };

    const navigationClass = classNames({
      'left-sidebar-spacer': true,
      'show-navigation': this.state.isNavigationDropdownOpen,
    });

    return (
      <div className='be-left-sidebar'>
        <div className='left-sidebar-wrapper'>
          <a
            className='left-sidebar-toggle'
            onClick={this.handleNavigatorClick}
          >
            {window.location.pathname.split('/')[2]
              ? window.location.pathname.split('/')[2].toLocaleUpperCase()
              : 'Dashboard'}
          </a>
          <div className={navigationClass}>
            <div
              className='left-sidebar-scroll ps-container ps-theme-default ps-active-y'
              data-ps-id='f8b0fdd0-fd4b-4912-9a92-25893f815b06'
            >
              <div className='left-sidebar-content'>
                <ul className='sidebar-elements'>
                  <li className={mainc === 'dashboard' ? 'active' : ''}>
                    <Link
                      to={`/restaurants/dashboard?restaurant=${this.keyObj.restaurant}`}
                    >
                      <i className='icon mdi mdi-home' />
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  {this.state.user && this.state.user.userRole === '1' && (
                    <li style={pointer} className={this.getClassName(1)}>
                      <a onClick={() => this.changeOpenItemNumber(1)}>
                        <i className='icon mdi mdi-pizza' />
                        <span>Menu</span>
                      </a>
                      <ul className='sub-menu'>
                        <li className={subc === 'menu-items' ? 'active' : ''}>
                          <Link
                            to={`/restaurants/menu/menu-items?restaurant=${this.keyObj.restaurant}`}
                          >
                            Menu Items
                          </Link>
                        </li>
                        <li className={subc === 'specials' ? 'active' : ''}>
                          <Link
                            to={`/restaurants/menu/specials?restaurant=${this.keyObj.restaurant}`}
                          >
                            Specials
                          </Link>
                        </li>
                        <li
                          className={subc === 'item-statistics' ? 'active' : ''}
                        >
                          <Link
                            to={`/restaurants/menu/item-statistics?restaurant=${this.keyObj.restaurant}`}
                          >
                            Item Statistics
                          </Link>
                        </li>
                        <li
                          className={subc === 'half-half-setup' ? 'active' : ''}
                        >
                          <Link
                            to={`/restaurants/menu/half-half-setup?restaurant=${this.keyObj.restaurant}`}
                          >
                            Half-Half Setup
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                  <li style={pointer} className={this.getClassName(2)}>
                    <a onClick={() => this.changeOpenItemNumber(2)}>
                      <i className='icon mdi mdi-notifications' />
                      <span>Marketing</span>
                    </a>
                    <ul className='sub-menu'>
                      {this.state.user && this.state.user.userRole === '1' && (
                        <React.Fragment>
                          <li className={subc === 'vouchers' ? 'active' : ''}>
                            <Link
                              to={`/restaurants/marketing/vouchers?restaurant=${this.keyObj.restaurant}`}
                            >
                              Vouchers
                            </Link>
                          </li>
                          <li className={subc === 'customers' ? 'active' : ''}>
                            <Link
                              to={`/restaurants/marketing/customers?restaurant=${this.keyObj.restaurant}`}
                            >
                              Customers
                            </Link>
                          </li>
                          <li
                            className={subc === 'sms-marketing' ? 'active' : ''}
                          >
                            <Link
                              to={`/restaurants/marketing/sms-marketing?restaurant=${this.keyObj.restaurant}`}
                            >
                              SMS Marketing
                            </Link>
                          </li>
                          <li
                            className={
                              subc === 'email-marketing' ? 'active' : ''
                            }
                          >
                            <Link
                              to={`/restaurants/marketing/email-marketing?restaurant=${this.keyObj.restaurant}`}
                            >
                              Email Marketing
                            </Link>
                          </li>
                        </React.Fragment>
                      )}
                      <li className={subc === 'reviews' ? 'active' : ''}>
                        <Link
                          to={`/restaurants/marketing/reviews?restaurant=${this.keyObj.restaurant}`}
                        >
                          Reviews
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {this.state.user && this.state.user.userRole === '1' && (
                    <li style={pointer} className={this.getClassName(8)}>
                      <a onClick={() => this.changeOpenItemNumber(8)}>
                        <i className='icon mdi mdi-account-box' />
                        <span>Loyalty</span>
                      </a>
                      <ul className='sub-menu'>
                        <li className={subc === 'offers' ? 'active' : ''}>
                          <Link
                            to={`/restaurants/loyalty/offers?restaurant=${this.keyObj.restaurant}`}
                          >
                            Offers
                          </Link>
                        </li>
                        {/* <li className={subc === 'memberships' ? 'active' : ''}>
                        <Link
                          to={`/restaurants/loyalty/memberships?restaurant=${this.keyObj.restaurant}`}
                        >
                          Memberships
                        </Link>
                      </li> */}
                      </ul>
                    </li>
                  )}
                  <li style={pointer} className={this.getClassName(3)}>
                    <a onClick={() => this.changeOpenItemNumber(3)}>
                      <i className='icon mdi mdi-car' />
                      <span>Delivery </span>
                    </a>
                    <ul className='sub-menu'>
                      {this.state.user && this.state.user.userRole === '1' && (
                        <React.Fragment>
                          <li
                            className={
                              subc === 'current-deliveries' ? 'active' : ''
                            }
                          >
                            <Link
                              to={`/restaurants/delivery/current-deliveries?restaurant=${this.keyObj.restaurant}`}
                            >
                              Current Deliveries
                            </Link>
                          </li>
                          <li
                            className={
                              subc === 'delivery-statistics' ? 'active' : ''
                            }
                          >
                            <Link
                              to={`/restaurants/delivery/delivery-statistics?restaurant=${this.keyObj.restaurant}`}
                            >
                              Delivery Statistics
                            </Link>
                          </li>
                          <li
                            className={
                              subc === 'delivery-areas' ? 'active' : ''
                            }
                          >
                            <Link
                              to={`/restaurants/delivery/delivery-areas?restaurant=${this.keyObj.restaurant}`}
                            >
                              Delivery Areas
                            </Link>
                          </li>
                        </React.Fragment>
                      )}
                      {((this.state.user && this.state.user.userRole === '1') ||
                        (this.state.user &&
                          this.state.user.userRole === '3')) && (
                        <li
                          className={
                            subc === 'drivers' || subc === 'driver'
                              ? 'active'
                              : ''
                          }
                        >
                          <Link
                            to={`/restaurants/delivery/drivers?restaurant=${this.keyObj.restaurant}`}
                          >
                            Drivers
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                  <li style={pointer} className={this.getClassName(7)}>
                    <a onClick={() => this.changeOpenItemNumber(7)}>
                      <i className='icon mdi mdi-car' />
                      <span>Other Delivery </span>
                    </a>
                    <ul className='sub-menu'>
                      <li
                        className={
                          subc === 'current-other-deliveries' ? 'active' : ''
                        }
                      >
                        <Link
                          to={`/restaurants/other-delivery/current-other-deliveries?restaurant=${this.keyObj.restaurant}`}
                        >
                          Current Other Deliveries
                        </Link>
                      </li>
                      <li
                        className={
                          subc === 'other-delivery-statistics' ? 'active' : ''
                        }
                      >
                        <Link
                          to={`/restaurants/other-delivery/other-delivery-statistics?restaurant=${this.keyObj.restaurant}`}
                        >
                          Other Delivery Statistics
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {this.state.user && this.state.user.userRole === '1' && (
                    <li style={pointer} className={this.getClassName(4)}>
                      <a onClick={() => this.changeOpenItemNumber(4)}>
                        <i className='icon mdi mdi-walk' />
                        <span>Pickup </span>
                      </a>
                      <ul className='sub-menu'>
                        <li
                          className={
                            subc === 'pickup-statistics' ? 'active' : ''
                          }
                        >
                          <Link
                            to={`/restaurants/pickup/pickup-statistics?restaurant=${this.keyObj.restaurant}`}
                          >
                            Pickup Statistics
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                  {this.state.user && this.state.user.userRole === '1' && (
                    <li style={pointer} className={this.getClassName(5)}>
                      <a onClick={() => this.changeOpenItemNumber(5)}>
                        <i className='icon mdi mdi-seat' />
                        <span>Restaurant </span>
                      </a>
                      <ul className='sub-menu'>
                        <li
                          className={
                            subc === 'current-reservations' ? 'active' : ''
                          }
                        >
                          <Link
                            to={`/restaurants/reservation/current-reservations?restaurant=${this.keyObj.restaurant}`}
                          >
                            Current Reservations
                          </Link>
                        </li>
                        <li
                          className={
                            subc === 'reservation-statistics' ? 'active' : ''
                          }
                        >
                          <Link
                            to={`/restaurants/reservation/reservation-statistics?restaurant=${this.keyObj.restaurant}`}
                          >
                            Reservations Statistics
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                  {this.state.user && this.state.user.userRole === '1' && (
                    <li style={pointer} className={this.getClassName(6)}>
                      <a onClick={() => this.changeOpenItemNumber(6)}>
                        <i className='icon mdi mdi-settings' />
                        <span>Settings </span>
                      </a>
                      <ul className='sub-menu'>
                        <li className={subc === 'shifts' ? 'active' : ''}>
                          <Link
                            to={`/restaurants/settings/shifts?restaurant=${this.keyObj.restaurant}`}
                          >
                            Shifts
                          </Link>
                        </li>

                        <li
                          className={subc === 'system-setting' ? 'active' : ''}
                        >
                          <Link
                            to={`/restaurants/settings/system-setting?restaurant=${this.keyObj.restaurant}`}
                          >
                            Store Settings
                          </Link>
                        </li>

                        <li className={subc === 'close-dates' ? 'active' : ''}>
                          <Link
                            to={`/restaurants/settings/close-dates?restaurant=${this.keyObj.restaurant}`}
                          >
                            Close Dates
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                  {this.state.user && this.state.user.userRole === '1' && (
                    <li style={pointer} className={this.getClassName(9)}>
                      <a onClick={() => this.changeOpenItemNumber(9)}>
                        <i className='icon mdi mdi-face' />
                        <span>Subscription & Payments </span>
                      </a>
                      <ul className='sub-menu'>
                        <li className={subc === 'subscription' ? 'active' : ''}>
                          <Link
                            to={`/restaurants/payment/subscription?restaurant=${this.keyObj.restaurant}`}
                          >
                            Subscription
                          </Link>
                        </li>
                        <li
                          className={subc === 'payment-reports' ? 'active' : ''}
                        >
                          <Link
                            to={`/restaurants/payment/payment-reports?restaurant=${this.keyObj.restaurant}`}
                          >
                            Payment Reports
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}

                  <li className={mainc === 'realtime-orders' ? 'active' : ''}>
                    <Link
                      to={`/restaurants/realtime-orders?restaurant=${this.keyObj.restaurant}`}
                    >
                      <i className='icon mdi mdi-desktop-windows' />
                      <span>Realtime Orders </span>
                    </Link>
                  </li>
                  <li className={mainc === 'auth-code' ? 'active' : ''}>
                    <Link
                      to={`/restaurants/auth-code?restaurant=${this.keyObj.restaurant}`}
                    >
                      <i className='icon mdi mdi-face' />
                      <span>Authentication Code </span>
                    </Link>
                  </li>
                  <li>
                    <a href='#' onClick={this.props.redirectToInstoreScreen}>
                      <i className='icon mdi mdi-desktop-windows' />
                      <span>In Store </span>
                    </a>
                  </li>
                </ul>
                <div className='ps-scrollbar-x-rail' style={style1}>
                  <div className='ps-scrollbar-x' tabIndex='0' style={style1} />
                </div>
                <div className='ps-scrollbar-y-rail' style={style2}>
                  <div className='ps-scrollbar-y' tabIndex='0' style={style3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navigation;
