import { firestore } from '../../firebase';
import ActionTypes from './constants';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';
import { nextOrderConfig } from '../../configurations/storeSetup';

export function getDriver(driverId) {
  return (dispatch) => {
    firestore
      .collection('Drivers')
      .doc(driverId)
      .onSnapshot((doc) => {
        let obj = doc.data();
        obj._id = doc.id;
        dispatch(driverFetched(obj));
      });
  };
}

export function getDriverStatsByDay(requestObj) {
  return (dispatch) => {
    dispatch(driverStatsByDayFetched(undefined));
    fetch(`${nextOrderConfig.cloudFunctionUrl}/getDriverStatsByDay`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestObj),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log(result);
        dispatch(driverStatsByDayFetched(result.weekDays));
      });
  };
}

export function getDriverStats(storeId, driverId) {
  return (dispatch) => {
    firestore
      .collection('DriverStoreStats')
      .where('storeId', '==', storeId)
      .where('driverId', '==', driverId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(driverStatsFetched(dataArray[0]));
      });
  };
}

export function getDeliveryHistory(storeId, driverId, startDate, endDate) {
  return (dispatch) => {
    firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('driverId', '==', driverId)
      .where('deliveryDateTimestamp', '>=', startDate)
      .where('deliveryDateTimestamp', '<=', endDate)
      .orderBy('deliveryDateTimestamp', 'desc')
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(deliveryHistoryFetched(dataArray));
      });
  };
}

export function getDriverCount(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('DriverStoreMapping')
      .where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(driverCountFetched(querySnapshot.docs.length));
    });
  };
}

export function getDrivers(storeId, pagination) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('DriverStoreMapping')
      .where('storeId', '==', storeId)
      .orderBy('dateTimestamp', 'desc');

    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];

      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('DriverStoreMapping')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(driversFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('DriverStoreMapping')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(driversFetched(dataArray));
        });
      }
    });
  };
}

export function changeDriverStatus(driver, timeZone) {
  return (dispatch) => {
    let ref = firestore.collection('DriverStoreMapping').doc(driver._id);
    firestore
      .runTransaction((t) => {
        return t.get(ref).then((doc) => {
          let obj = doc.data();
          t.update(doc.ref, {
            isActivated: !obj.isActivated,
            lastUpdated: getCurrentFormattedMomentDate(
              timeZone,
              'YYYY-MM-DD HH:mm'
            ),
            lastUpdatedTimestamp: getCurrentTimestamp(),
          });
        });
      })
      .then((_result) => {})
      .catch((err) => {
        console.log(err);
      });
  };
}

function driversFetched(drivers) {
  return {
    type: ActionTypes.FetchDrivers,
    drivers,
  };
}

function driverCountFetched(totalCount) {
  return {
    type: ActionTypes.FetchDriverCount,
    totalCount,
  };
}

function driverFetched(driver) {
  return {
    type: ActionTypes.FetchDriverSelected,
    driver,
  };
}

function driverStatsByDayFetched(driverStatsByDay) {
  return {
    type: ActionTypes.FetchDriverStatsByDay,
    driverStatsByDay,
  };
}

function driverStatsFetched(driverStats) {
  return {
    type: ActionTypes.FetchDriverStats,
    driverStats,
  };
}

function deliveryHistoryFetched(deliveryHistory) {
  return {
    type: ActionTypes.FetchDeliveryHistory,
    deliveryHistory,
  };
}
