import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';

class IngredientCategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredientCategory: { name: '' },
      isError: false,
      message: '',
    };
  }

  txtChangeHandler = (e) => {
    this.setState({
      isError: false,
      ingredientCategory: {
        name: e.target.value,
        date: getCurrentFormattedMomentDate(
          this.props.timeZone,
          'YYYY-MM-DD HH:mm'
        ),
        dateTimestamp: getCurrentTimestamp(),
      },
    });
  };

  addIngredientCategory = () => {
    if (
      this.state.ingredientCategory.name === undefined ||
      this.state.ingredientCategory.name === null ||
      this.state.ingredientCategory.name === ''
    ) {
      this.setState({ isError: true, message: 'Required' });
    } else {
      this.props.addIngredientCategoryHandler(this.state.ingredientCategory);
      this.setState({
        ingredientCategory: {},
        isError: false,
        message: 'Required',
      });
    }
  };

  render() {
    return (
      <div className='form-group mt3'>
        <div className='col-md-4'>
          <input
            onChange={this.txtChangeHandler}
            className='form-control input-xs xs-mb-20'
            type='text'
            placeholder='Category Name'
          />
          {this.state.isError && (
            <ul className='parsley-errors-list filled' id='parsley-id-7'>
              <li className='parsley-type'>{this.state.message}</li>
            </ul>
          )}
        </div>
        <div className='col-md-2 pp0'>
          <button
            onClick={this.addIngredientCategory}
            className='btn btn-space btn-primary active'
          >
            Add
          </button>
          <button
            onClick={this.props.closeCategoryForm}
            className='btn btn-space btn-primary active'
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

IngredientCategoryForm.propTypes = {
  addIngredientCategoryHandler: PropTypes.any,
  closeCategoryForm: PropTypes.any,
};

export default IngredientCategoryForm;
