import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import intersection from 'lodash/intersection';
import { firestore } from '../../firebase';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';

import ImageCompressor from 'image-compressor.js';

const imageCompressor = new ImageCompressor();

let compressionObj = {
  quality: 0.8,
  convertSize: 10000,
  mimeType: 'jpeg',
  maxWidth: 700,
};
let compressionObjLarge = {
  quality: 0.6,
  convertSize: 10000,
  mimeType: 'jpeg',
  maxWidth: 700,
};

class OfferForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      categoryListReward: [],
      sizeList: [],
      menuItemList: [],
      sizeListReward: [],
      menuItemListReward: [],
      isRewardUnDefined: false,
      isPathwayUnDefined: false,
      isPathwayDuplicate: false,
      isAnyOneItemAvailable: false,
      rewardItems: [],
      pathwayItem: {},
      discountedPrice: '0',
      quantityReward: '1',
      type: '1',
    };
  }

  componentWillReceiveProps(props) {
    if (
      this.props.categories &&
      this.props.categories.length > 0 &&
      this.props.sizes &&
      this.props.menuItems
    ) {
      const category =
        props.categories && props.categories.length > 0
          ? props.categories[0]
          : { _id: 0, catName: '' };
      let categoryList = [];

      this.props.categories.map((m, i) => {
        let obj = {
          _id: m._id,
          name: m.catName,
          isActive: i === 0 ? true : false,
        };
        categoryList.push(obj);
      });
      const sizes = this.props.sizes.filter((item) => {
        return item.categoryId === category._id;
      });
      let sizeList = [];
      sizes.map((m) => {
        let obj = {
          _id: m._id,
          name: m.name,
          categoryName: category.catName ? category.catName : '',
          categoryId: category._id,
          isActive: true,
        };
        sizeList.push(obj);
      });
      const menuItems = this.props.menuItems.filter((item) => {
        return item.categoryId === category._id;
      });
      let menuItemList = [];
      menuItems.map((m) => {
        let obj = {
          _id: m._id,
          name: m.name,
          categoryName: category.catName ? category.catName : '',
          categoryId: category._id,
          isActive: true,
        };
        menuItemList.push(obj);
      });
      this.setState(
        {
          categoryList,
          categoryDisplayName: category.catName,
          categoryListReward: categoryList,
          categoryDisplayNameReward: category.catName,
          sizeList,
          sizeListReward: sizeList,
          menuItemList,
          menuItemListReward: menuItemList,
        },
        () => {
          if (props.isEditModeOn) {
            //Load data from selected offer data
            this.setState({
              pathwayItem: {
                categories: props.offerForEdit.categories,
                categoryDisplayName: props.offerForEdit.categoryDisplayName,
                quantity: props.offerForEdit.quantity,
                menuItems: props.offerForEdit.menuItems,
                sizes: props.offerForEdit.sizes,
              },
              rewardItems: props.offerForEdit.rewardItems,
              name: props.offerForEdit.name,
              offerPhoto: props.offerForEdit.urlS3,
              type: props.offerForEdit.type,
              isAnyOneItemAvailable: props.offerForEdit.isAnyOneItemAvailable,
              minOrderValue: props.offerForEdit.minOrderValue
                ? props.offerForEdit.minOrderValue
                : '0',
              noOfOrders: props.offerForEdit.noOfOrders
                ? props.offerForEdit.noOfOrders
                : '0',
            });
          }
        }
      );
    }
  }

  checkDuplicate = (categoryId) => {
    return new Promise((resolve, _reject) => {
      firestore
        .collection('Offers')
        .where('storeId', '==', this.props.storeId)
        .where('activeCategoryKeys', 'array-contains', categoryId)
        .where('isDeleted', '==', false)
        .get()
        .then((querySnapshot) => {
          let matchingOffers = [];
          querySnapshot.docs.forEach((doc) => {
            matchingOffers.push({ _id: doc.id, ...doc.data() });
          });
          resolve(matchingOffers);
        });
    });
  };

  handleCategoryChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let categoryList = JSON.parse(JSON.stringify(this.state.categoryList));
    categoryList.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    let catIds = [];
    catIds = categoryList.filter((f) => f.isActive).map((m) => m._id);
    let menuItemList = [];
    let sizeList = [];
    const filteredMenuItems = this.props.menuItems
      ? this.props.menuItems.filter((item) => {
          return catIds.includes(item.categoryId);
        })
      : [];
    filteredMenuItems.map((m) => {
      let matchedCategories = categoryList.filter((f) => {
        return f._id === m.categoryId;
      });
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName:
          matchedCategories.length > 0 ? matchedCategories[0].name : '',
        categoryId: matchedCategories[0]._id,
        isActive: true,
      };
      menuItemList.push(obj);
    });
    const mySizes = this.props.sizes
      ? this.props.sizes.filter((item) => {
          return catIds.includes(item.categoryId);
        })
      : [];
    mySizes.map((m) => {
      let matchedCategories = categoryList.filter((f) => {
        return f._id === m.categoryId;
      });
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName:
          matchedCategories.length > 0 ? matchedCategories[0].name : '',
        categoryId: matchedCategories[0]._id,
        isActive: true,
      };
      sizeList.push(obj);
    });
    this.setState({
      categoryList,
      categoryDisplayName: categoryList
        .filter((f) => f.isActive)
        .map((m) => m.name)
        .join('/'),
      sizeList,
      menuItemList,
      isPathwayDuplicate: false,
    });
  };

  handleQuantityChange = (e) => {
    this.setState({
      quantity: e.target.value,
      isPathwayDuplicate: false,
    });
  };

  handleMinOrderValueChange = (e) => {
    this.setState({
      minOrderValue: e.target.value,
    });
  };

  handleNoOfOrdersChange = (e) => {
    this.setState({
      noOfOrders: e.target.value,
    });
  };

  handleCategoryDisplayNameChange = (e) => {
    this.setState({
      categoryDisplayName: e.target.value,
      isPathwayDuplicate: false,
    });
  };

  handleSizeSelectionChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let sizeList = JSON.parse(JSON.stringify(this.state.sizeList));
    sizeList.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    this.setState({
      sizeList,
      isPathwayDuplicate: false,
    });
  };

  handlemenuItemSelection = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let menuItemList = JSON.parse(JSON.stringify(this.state.menuItemList));
    menuItemList.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    this.setState({
      menuItemList,
    });
  };

  handleCategoryChangeReward = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id.split('-')[0];
    let categoryList = JSON.parse(
      JSON.stringify(this.state.categoryListReward)
    );
    categoryList.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    let catIds = [];
    catIds = categoryList.filter((f) => f.isActive).map((m) => m._id);
    let menuItemList = [];
    let sizeList = [];
    const filteredMenuItems = this.props.menuItems
      ? this.props.menuItems.filter((item) => {
          return catIds.includes(item.categoryId);
        })
      : [];
    filteredMenuItems.map((m) => {
      let matchedCategories = categoryList.filter((f) => {
        return f._id === m.categoryId;
      });
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName:
          matchedCategories.length > 0 ? matchedCategories[0].name : '',
        categoryId: matchedCategories[0]._id,
        isActive: true,
      };
      menuItemList.push(obj);
    });
    const mySizes = this.props.sizes
      ? this.props.sizes.filter((item) => {
          return catIds.includes(item.categoryId);
        })
      : [];
    mySizes.map((m) => {
      let matchedCategories = categoryList.filter((f) => {
        return f._id === m.categoryId;
      });
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName:
          matchedCategories.length > 0 ? matchedCategories[0].name : '',
        categoryId: matchedCategories[0]._id,
        isActive: true,
      };
      sizeList.push(obj);
    });
    this.setState({
      categoryListReward: categoryList,
      categoryDisplayNameReward: categoryList
        .filter((f) => f.isActive)
        .map((m) => m.name)
        .join('/'),
      sizeListReward: sizeList,
      menuItemListReward: menuItemList,
    });
  };

  handleQuantityChangeReqard = (e) => {
    this.setState({
      quantityReward: e.target.value,
    });
  };

  handleCategoryDisplayNameRewardChange = (e) => {
    this.setState({
      categoryDisplayNameReward: e.target.value,
    });
  };

  handleSizeSelectionChangeReward = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id.split('-')[0];
    let sizeListReward = JSON.parse(JSON.stringify(this.state.sizeListReward));
    sizeListReward.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    this.setState({
      sizeListReward,
    });
  };

  handlemenuItemSelectionReward = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id.split('-')[0];
    let menuItemListReward = JSON.parse(
      JSON.stringify(this.state.menuItemListReward)
    );
    menuItemListReward.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    this.setState({
      menuItemListReward,
    });
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleTypeChange = (e) => {
    this.setState({
      type: e.target.value,
    });
  };

  handleDiscountedPriceChange = (e) => {
    this.setState({
      discountedPrice: e.target.value,
    });
  };

  hanldeFileChange = (filesToUpload) => {
    filesToUpload.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    imageCompressor
      .compress(filesToUpload[0], compressionObj)
      .then((result) => {
        if (result.size > 140000) {
          imageCompressor
            .compress(result, compressionObjLarge)
            .then((resultOne) => {
              this.setState({
                photo: new File([resultOne], 'name'),
                imageFiles: filesToUpload,
              });
            })
            .catch(() => {});
        } else {
          this.setState({
            photo: new File([result], 'name'),
            imageFiles: filesToUpload,
          });
        }
      })
      .catch(() => {});
  };

  getSizesList = (category) => {
    return category.sizes
      .filter((f) => f.isActive)
      .map((f) => f.name)
      .join(',');
  };

  getMenuItemsList = (category) => {
    return category.menuItems
      .filter((f) => f.isActive)
      .map((f) => f.name)
      .join(',');
  };

  getCategoryList = (category) => {
    return category.categories
      .filter((f) => f.isActive)
      .map((f) => f.name)
      .join(',');
  };

  removeRewardItem = (index) => {
    let rewardItems = this.state.rewardItems.filter((obj) => {
      return !(obj.index === index);
    });
    this.setState({ rewardItems });
  };

  addToReward = () => {
    let rewardItems = this.state.rewardItems ? this.state.rewardItems : [];
    const rewardItem = {
      index: rewardItems.length,
      categoryDisplayName: this.state.categoryDisplayNameReward,
      discountedPrice: this.state.discountedPrice
        ? this.state.discountedPrice
        : '0',
      quantity: this.refs.quantityReward.value,
      menuItems: this.state.menuItemListReward,
      sizes: this.state.sizeListReward,
      categories: this.state.categoryListReward,
    };
    this.setState({
      rewardItems: [...rewardItems, rewardItem],
      isRewardUnDefined: false,
    });
  };

  removePathwayItem = () => {
    this.setState({ pathwayItem: {} });
  };

  addItemAsPathway = () => {
    let activeCategoryKeys = this.state.categoryList
      .filter((f) => f.isActive)
      .map((m) => m._id);
    let requests = activeCategoryKeys.map((categoryId) => {
      return this.checkDuplicate(categoryId);
    });
    Promise.all(requests)
      .then((values) => {
        let allActiveOffers = [];
        allActiveOffers = [].concat.apply([], values);
        let count = 0;
        allActiveOffers.map((o) => {
          if (
            !(
              this.props.isEditModeOn &&
              this.props.offerForEdit &&
              this.props.offerForEdit._id === o._id
            )
          ) {
            let keys = o.activeSizeKeys;
            let keysToBeAdded = this.state.sizeList
              .filter((f) => f.isActive)
              .map((m) => m._id);
            count = count + intersection(keys, keysToBeAdded).length;
          }
        });
        if (count === 0) {
          const pathwayItem = {
            categories: this.state.categoryList,
            categoryDisplayName: this.state.categoryDisplayName,
            quantity: this.refs.quantity.value,
            menuItems: this.state.menuItemList,
            sizes: this.state.sizeList,
          };
          this.setState({
            pathwayItem,
            isPathwayUnDefined: false,
            isPathwayDuplicate: false,
          });
        } else {
          this.setState({
            isPathwayDuplicate: true,
          });
        }
      })
      .catch((_error) => {
        this.setState({
          isPathwayDuplicate: true,
        });
      });
  };

  resetOfferForm = () => {
    this.setState({
      isRewardUnDefined: false,
      isPathwayUnDefined: false,
      isPathwayDuplicate: false,
      rewardItems: [],
      pathwayItem: {},
      name: '',
      discountedPrice: '0',
      quantity: '1',
      minOrderValue: '0',
      noOfOrders: '0',
      isAnyOneItemAvailable: false,
      type: '1',
      photo: null,
      imageFiles: [],
      EditModeOn: false,
    });
    this.props.resetOffer();
  };

  handleRewardAvlChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      isAnyOneItemAvailable: value,
    });
  };

  submitOffer = () => {
    let offerObj = {
      date: getCurrentFormattedMomentDate(
        this.props.timeZone,
        'YYYY-MM-DD HH:mm'
      ),
      dateTimestamp: getCurrentTimestamp(),
      name: this.state.name,
      type: this.state.type,
      rewardItems: this.state.rewardItems,
      photo: this.state.photo,
      isAnyOneItemAvailable: this.state.isAnyOneItemAvailable,
    };
    if (this.state.type === '1') {
      offerObj.categories = this.state.pathwayItem.categories;
      offerObj.categoryDisplayName = this.state.pathwayItem.categoryDisplayName;
      offerObj.activeCategoryKeys = this.state.pathwayItem.categories
        .filter((f) => f.isActive)
        .map((m) => m._id);
      offerObj.sizes = this.state.pathwayItem.sizes;
      offerObj.activeSizeKeys = this.state.pathwayItem.sizes
        .filter((f) => f.isActive)
        .map((m) => m._id);
      offerObj.quantity = this.state.pathwayItem.quantity;
      offerObj.menuItems = this.state.pathwayItem.menuItems;
      offerObj.activeMenuItemKeys = this.state.pathwayItem.menuItems
        .filter((f) => f.isActive)
        .map((m) => m._id);
    } else {
      offerObj.minOrderValue = this.state.minOrderValue;
      offerObj.noOfOrders = this.state.noOfOrders;
    }

    if (
      this.props.isEditModeOn &&
      this.props.offerForEdit &&
      this.props.offerForEdit._id
    )
      offerObj._id = this.props.offerForEdit._id;
    this.props.addOfferHandler(offerObj);
    this.resetOfferForm();
  };

  render() {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='panel panel-border-color panel-border-color-primary'>
            <div className='panel-body'>
              <hr />
              <div>
                <div>
                  <div className='form-group'>
                    <label
                      className='col-md-12 control-label'
                      style={{
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      Offer
                    </label>
                  </div>
                  <div className='form-group'>
                    <label className='col-md-2 control-label text-right  t10'>
                      Name
                    </label>
                    <div className='col-md-4'>
                      <input
                        type='text'
                        name='name'
                        value={this.state.name}
                        placeholder='name'
                        className='form-control input-sm'
                        onChange={this.handleNameChange}
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='col-md-1 control-label t10 text-right'>
                      Type
                    </label>
                    <div className='col-md-4' onChange={this.handleTypeChange}>
                      <div className='be-checkbox inline'>
                        <input
                          name='type'
                          id='type_per_item'
                          checked={this.state.type === '1'}
                          type='radio'
                          value='1'
                          disabled={this.props.isEditModeOn}
                        />
                        <label htmlFor='type_per_item'>Per Item</label>
                      </div>
                      <div className='be-checkbox inline'>
                        <input
                          name='type'
                          id='type_per_order'
                          checked={this.state.type === '2'}
                          type='radio'
                          value='2'
                          disabled={this.props.isEditModeOn}
                        />
                        <label htmlFor='type_per_order'>Per Order</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='clear' />
                <hr />
              </div>
              <hr />
              <div>
                {this.state.type === '1' ? (
                  <React.Fragment>
                    <div>
                      <div className='form-group'>
                        <label
                          className='col-md-12 control-label'
                          style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                          }}
                        >
                          Pathway To Unlock Offer
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className='form-group col-md-12 col-md-offset-1 t10'>
                        <label className='col-md-1 control-label text-right t10'>
                          Categories
                        </label>
                        <div className='col-md-10'>
                          {this.state.categoryList.map((m, index) => (
                            <div key={index} className='be-checkbox inline'>
                              <input
                                checked={m.isActive}
                                onChange={(e) => this.handleCategoryChange(e)}
                                name={m.name}
                                id={m._id}
                                type='checkbox'
                              />
                              <label htmlFor={m._id}>{m.name}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='form-group col-md-12 col-md-offset-1 t10'>
                        <label className='col-md-1 control-label text-right'>
                          Display Name
                        </label>
                        <div className='col-md-8'>
                          <input
                            type='text'
                            className='form-control'
                            value={this.state.categoryDisplayName}
                            onChange={(e) =>
                              this.handleCategoryDisplayNameChange(e)
                            }
                          />
                        </div>
                      </div>
                      <div className='form-group col-md-12 col-md-offset-1 t10'>
                        <label className='col-md-1 control-label text-right  t10'>
                          Quantity
                        </label>
                        <div className='col-md-1'>
                          <input
                            type='number'
                            min='1'
                            max='99'
                            defaultValue='1'
                            ref='quantity'
                            className='form-control'
                            id='select-quantity'
                            value={this.state.quantity}
                            onChange={(e) => this.handleQuantityChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='form-group col-md-12 col-md-offset-1 t10'>
                        <label
                          className='col-md-1 control-label t10 text-right'
                          style={{ paddingRight: '35px' }}
                        >
                          Sizes
                        </label>
                        <div className='col-md-10'>
                          {this.state.sizeList.map((m, index) => (
                            <div key={index} className='be-checkbox inline'>
                              <input
                                checked={m.isActive}
                                onChange={(e) =>
                                  this.handleSizeSelectionChange(e)
                                }
                                name={m.name}
                                id={m._id}
                                type='checkbox'
                              />
                              <label htmlFor={m._id}>{m.name}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='form-group col-md-12 col-md-offset-1 t10'>
                        <label className='col-md-1 control-label t10'>
                          Menu Items
                        </label>
                        <div className='col-md-10'>
                          {this.state.menuItemList.map((m, index) => (
                            <div key={index} className='be-checkbox inline'>
                              <input
                                checked={m.isActive}
                                onChange={this.handlemenuItemSelection}
                                name={m.name}
                                id={m._id}
                                type='checkbox'
                              />
                              <label htmlFor={m._id}>{m.name}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='clear' />
                    <hr />
                    <div className='clear' />
                    <div className='clear' />
                    <div className='form-group col-md-12 col-md-offset-2 t10'>
                      <button
                        type='button'
                        onClick={this.addItemAsPathway}
                        className='btn btn-space btn-primary'
                        disabled={
                          !(
                            this.state.menuItemList.some((menuItem) => {
                              return menuItem.isActive === true;
                            }) &&
                            this.state.sizeList.some((size) => {
                              return size.isActive === true;
                            }) &&
                            this.state.categoryList.some((cat) => {
                              return cat.isActive === true;
                            })
                          )
                        }
                      >
                        Add to Pathway
                      </button>
                    </div>
                    <div className='clear' />
                    <hr />
                    <div className='col-md-7 table-responcive col-md-offset-2'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>Categories</th>
                            <th>Display</th>
                            <th>Quantity</th>
                            <th>Size</th>
                            <th>Menu Items</th>
                            <th>&nbsp; </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.pathwayItem &&
                          this.state.pathwayItem.categories &&
                          this.state.pathwayItem.categories.length > 0 ? (
                            <tr>
                              <td>
                                {this.getCategoryList(this.state.pathwayItem)}
                              </td>
                              <td>
                                {this.state.pathwayItem.categoryDisplayName}
                              </td>
                              <td>{this.state.pathwayItem.quantity}</td>
                              <td>
                                {this.getSizesList(this.state.pathwayItem)}
                              </td>
                              <td>
                                {this.getMenuItemsList(this.state.pathwayItem)}
                              </td>
                              <td>
                                <button
                                  type='button'
                                  onClick={() => this.removePathwayItem()}
                                  className='btn btn-space btn-default hover'
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan='4'>
                                {this.state.isPathwayUnDefined && (
                                  <ul
                                    className='parsley-errors-list filled'
                                    id='parsley-id-7'
                                  >
                                    <li
                                      style={{ fontSize: '13px' }}
                                      className='parsley-type'
                                    >
                                      Pathway is not defined
                                    </li>
                                  </ul>
                                )}
                                {this.state.isPathwayDuplicate && (
                                  <ul
                                    className='parsley-errors-list filled'
                                    id='parsley-id-7'
                                  >
                                    <li
                                      style={{ fontSize: '13px' }}
                                      className='parsley-type'
                                    >
                                      Offer with similar pathway or pathway
                                      subset is already exist
                                    </li>
                                  </ul>
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='clear' />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div>
                      <div className='form-group'>
                        <label
                          className='col-md-12 control-label'
                          style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                          }}
                        >
                          Pathway To Unlock Offer
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className='form-group col-md-12 col-md-offset-1 t10'>
                        <label
                          className='col-md-1 control-label t10 text-right'
                          style={{ paddingRight: '35px' }}
                        >
                          Min. Order Value
                        </label>
                        <div className='col-md-5'>
                          <input
                            type='number'
                            min='0'
                            max='99'
                            defaultValue='0'
                            ref='minOrderValue'
                            className='form-control'
                            id='select-minOrderValue'
                            value={this.state.minOrderValue}
                            onChange={(e) => this.handleMinOrderValueChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='form-group col-md-12 col-md-offset-1 t10'>
                        <label className='col-md-1 control-label t10'>
                          No. Of Orders
                        </label>
                        <div className='col-md-5'>
                          <input
                            type='number'
                            min='0'
                            max='999'
                            defaultValue='0'
                            ref='noOfOrders'
                            className='form-control'
                            id='select-noOfOrders'
                            value={this.state.noOfOrders}
                            onChange={(e) => this.handleNoOfOrdersChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='clear' />
                  </React.Fragment>
                )}
              </div>
              <hr />
              <div>
                <div>
                  <div className='form-group'>
                    <label
                      className='col-md-12 control-label'
                      style={{
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                    >
                      Offer Reward
                    </label>
                  </div>
                </div>
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label className='col-md-1 control-label text-right t10'>
                      Categories
                    </label>
                    <div className='col-md-10'>
                      {this.state.categoryListReward.map((m, index) => (
                        <div key={index} className='be-checkbox inline'>
                          <input
                            checked={m.isActive}
                            onChange={(e) => this.handleCategoryChangeReward(e)}
                            name={`${m.name}-reward`}
                            id={`${m._id}-reward`}
                            type='checkbox'
                          />
                          <label htmlFor={`${m._id}-reward`}>{m.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label className='col-md-1 control-label text-right  t10'>
                      Display Name
                    </label>
                    <div className='col-md-8'>
                      <input
                        type='text'
                        className='form-control'
                        value={this.state.categoryDisplayNameReward}
                        onChange={(e) =>
                          this.handleCategoryDisplayNameRewardChange(e)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label className='col-md-1 control-label text-right  t10'>
                      Quantity
                    </label>
                    <div className='col-md-1'>
                      <input
                        type='number'
                        readOnly
                        min='1'
                        max='99'
                        defaultValue='1'
                        ref='quantityReward'
                        className='form-control'
                        id='select-quantity-reward'
                        value={this.state.quantityReward}
                        onChange={(e) => this.handleQuantityChangeReqard(e)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label
                      className='col-md-1 control-label t10 text-right'
                      style={{ paddingRight: '35px' }}
                    >
                      Sizes
                    </label>
                    <div className='col-md-10'>
                      {this.state.sizeListReward.map((m, index) => (
                        <div key={index} className='be-checkbox inline'>
                          <input
                            checked={m.isActive}
                            onChange={(e) =>
                              this.handleSizeSelectionChangeReward(e)
                            }
                            name={`${m.name}-reward`}
                            id={`${m._id}-reward`}
                            type='checkbox'
                          />
                          <label htmlFor={`${m._id}-reward`}>{m.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label className='col-md-1 control-label t10'>
                      Menu Items
                    </label>
                    <div className='col-md-10'>
                      {this.state.menuItemListReward.map((m, index) => (
                        <div key={index} className='be-checkbox inline'>
                          <input
                            checked={m.isActive}
                            onChange={this.handlemenuItemSelectionReward}
                            name={`${m.name}-reward`}
                            id={`${m._id}-reward`}
                            type='checkbox'
                          />
                          <label htmlFor={`${m._id}-reward`}>{m.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group'>
                    <label className='col-md-2 control-label text-right  t10'>
                      Discounted Price
                    </label>
                    <div className='col-md-2'>
                      <div className='input-group'>
                        <span className='input-group-addon'>
                          {this.props.currency}
                        </span>
                        <input
                          min='0'
                          max='9999'
                          defaultValue='0'
                          name='discountedPrice'
                          value={this.state.discountedPrice}
                          placeholder={`${this.props.currency}35`}
                          className='form-control'
                          onChange={this.handleDiscountedPriceChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='clear' />
                <hr />
                <div className='clear' />
                <div className='form-group col-md-12 col-md-offset-2 t10'>
                  <button
                    type='button'
                    onClick={this.addToReward}
                    className='btn btn-space btn-primary'
                    disabled={
                      !(
                        this.state.menuItemListReward.some((menuItem) => {
                          return menuItem.isActive === true;
                        }) &&
                        this.state.sizeListReward.some((size) => {
                          return size.isActive === true;
                        })
                      )
                    }
                  >
                    Add to Reward
                  </button>
                </div>
                <div className='clear' />
                <hr />
                <div className='col-md-7 table-responcive col-md-offset-2'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Categories</th>
                        <th>Display</th>
                        <th>Quantity</th>
                        <th>Size</th>
                        <th>Menu Itmes</th>
                        <th>&nbsp; </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.rewardItems &&
                      this.state.rewardItems.length > 0 ? (
                        this.state.rewardItems.map((category, index) => (
                          <tr key={index}>
                            <td>{this.getCategoryList(category)}</td>
                            <td>{category.categoryDisplayName}</td>
                            <td>{category.quantity}</td>
                            <td>
                              {Number(category.discountedPrice) !== 0
                                ? `${this.props.currency}${category.discountedPrice}`
                                : `Free`}
                            </td>
                            <td>{this.getSizesList(category)}</td>
                            <td>{this.getMenuItemsList(category)}</td>
                            <td>
                              <button
                                type='button'
                                onClick={() =>
                                  this.removeRewardItem(category.index)
                                }
                                className='btn btn-space btn-default hover'
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan='4'>
                            {this.state.isRewardUnDefined &&
                            this.state.rewardItems.length <= 0 ? (
                              <ul
                                className='parsley-errors-list filled'
                                id='parsley-id-7'
                              >
                                <li
                                  style={{ fontSize: '13px' }}
                                  className='parsley-type'
                                >
                                  At least one item required in reward
                                </li>
                              </ul>
                            ) : (
                              'No items available'
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='clear' />
                <div className='form-group'>
                  <label className='col-md-2 control-label text-right  t10'>
                    Any one item available ?
                  </label>
                  <div className='col-md-2'>
                    <div className='be-checkbox inline'>
                      <input
                        checked={this.state.isAnyOneItemAvailable}
                        onChange={(e) => this.handleRewardAvlChange(e)}
                        name={`rewardItemsAnyOR`}
                        id={`rewardItemsAnyOR`}
                        type='checkbox'
                      />
                      <label htmlFor='rewardItemsAnyOR'></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='clear' />
          </div>
          <hr />
          <div>
            <div className='form-group'>
              <label className={'col-md-1 control-label text-right t10'}>
                Offer Photo
              </label>
              <div className={'col-md-4 t10'}>
                <Dropzone
                  className='uplodedImg'
                  name={'photo'}
                  accept='image/*'
                  onDrop={(filesToUpload) =>
                    this.hanldeFileChange(filesToUpload)
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                  >
                    <label htmlFor='photo' className='btn-default'>
                      <i className='mdi mdi-upload' />
                      <span> Browse files...</span>
                    </label>
                    <div>
                      {this.state.offerPhoto && !this.state.imageFiles ? (
                        <React.Fragment>
                          <img
                            src={this.state.offerPhoto}
                            height={128}
                            width={128}
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {this.state.imageFiles &&
                            this.state.imageFiles.map((file, index) => {
                              return (
                                <img
                                  src={file.preview}
                                  key={index}
                                  height={128}
                                  width={128}
                                />
                              );
                            })}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </Dropzone>
              </div>
            </div>
            <div
              className='form-group col-md-10 t10 col-md-offset-1'
              style={{ marginTop: '20px' }}
            >
              <button
                onClick={this.resetOfferForm}
                type='button'
                className='btn btn-space'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='btn btn-space btn-primary'
                onClick={this.submitOffer}
                disabled={
                  !(
                    this.state.name &&
                    this.state.name !== '' &&
                    (this.state.photo || this.state.offerPhoto) &&
                    ((this.state.pathwayItem &&
                      this.state.pathwayItem.categories &&
                      this.state.pathwayItem.categories.filter(
                        (f) => f.isActive
                      ).length > 0) ||
                      (this.state.minOrderValue &&
                        this.state.noOfOrders &&
                        Number(this.state.minOrderValue) >= 0 &&
                        Number(this.state.noOfOrders) > 0)) &&
                    this.state.rewardItems.length > 0
                  )
                }
              >
                {this.props.isEditModeOn ? 'Edit Offer' : 'Add Offer'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OfferForm.propTypes = {
  addOfferHandler: PropTypes.any,
  resetOffer: PropTypes.any,
  categories: PropTypes.any,
  sizes: PropTypes.any,
  menuItemList: PropTypes.any,
  isEditModeOn: PropTypes.any,
};

export default OfferForm;
