import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getStoreAuthCode(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('StoreAuthCodes')
      .where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(authCodeFetched(dataArray.length > 0 ? dataArray[0] : {}));
    });
  };
}

export function getActiveDevices(storeId, pagination) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('LoggedInDevices')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true)
      .orderBy('deviceId', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('LoggedInDevices')
          .where('storeId', '==', storeId)
          .where('isActive', '==', true)
          .orderBy('deviceId', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(activeDevicesFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('LoggedInDevices')
          .where('storeId', '==', storeId)
          .where('isActive', '==', true)
          .orderBy('deviceId', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(activeDevicesFetched(dataArray));
        });
      }
    });
  };
}

export function getActiveDeviceCount(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('LoggedInDevices')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true);
    ref.onSnapshot((querySnapshot) => {
      dispatch(activeDeviceCountFetched(querySnapshot.docs.length));
    });
  };
}

export function updateDeviceInfo(deviceId) {
  return (dispatch) => {
    var batchForAuthCode = firestore.batch();
    batchForAuthCode.update(
      firestore.collection('LoggedInDevices').doc(deviceId),
      { isActive: false }
    );
    batchForAuthCode.commit().then(() => {
      console.log('Auth Code created and Updated');
    });
  };
}

export function resetPageData() {
  return (dispatch) => {
    dispatch(resetPageDataFun());
  };
}

function resetPageDataFun() {
  return {
    type: ActionTypes.ResetAuthPageData,
  };
}

function authCodeFetched(authCode) {
  return {
    type: ActionTypes.FetchAuthCode,
    authCode,
  };
}

function activeDevicesFetched(activeDevices) {
  return {
    type: ActionTypes.FetchActiveLogins,
    activeDevices,
  };
}

function activeDeviceCountFetched(totalCount) {
  return {
    type: ActionTypes.FetchTotalActiveDevice,
    totalCount,
  };
}
