import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchReservationsStatistics:
      return { ...state, reservations: payload.reservations };
    case ActionTypes.FetchReservationStatisticsCount:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.FetchReservationStats:
      return { ...state, reservationStats: payload.reservationStats };
    default:
      return state;
  }
};
