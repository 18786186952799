import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Line } from 'react-chartjs-2';
import Spinner from '../../components/Spinner';
import { getCurrentMomentDate } from '../../helpers/timeHelper';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';

class DeliveryByDay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().add(-7, 'day'),
      endDate: moment(),
      displayText: 'Past 7 days',
    };
  }

  onDatesChange = (startDate, endDate) => {
    this.setState({ startDate, endDate }, () => {
      this.props.getDriverStatsByDayHandler(
        this.props.driverId,
        startDate,
        endDate
      );
    });
  };

  processGraphData = () => {
    if (this.props.driverStatsByDay) {
      var data = {
        labels: ['Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'],
        datasets: [
          {
            label: 'Number of deliveries',
            fill: false,
            lineTension: 0.2,
            backgroundColor: '#4285f4',
            borderColor: '#4285f4',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#4285f4',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: '#4285f4',
            pointHoverBorderColor: '#4285f4;',
            pointHoverBorderWidth: 2,
            pointRadius: 3,
            pointHitRadius: 10,
            data: [],
          },
        ],
      };
      this.props.driverStatsByDay.map((day) => {
        data.datasets[0].data.push(day.orders);
        return day;
      });
      return data;
    }
  };

  render() {
    const chartData = this.processGraphData();
    return (
      <div className='row md-mt-40'>
        <div className='widget widget-fullwidth '>
          <div className='widget-head'>
            <div className='text-left col-md-4 col-xs-12'>
              <span className='title'>
                <b>Deliveries</b>
              </span>
              <span className='description'>{this.state.displayText}</span>
            </div>
            <div className='fright col-md-6 col-sm-12 col-xs-12'>
              <DateRangePicker
                startDateId='startDate'
                startDatePlaceholderText='From'
                endDateId='endDate'
                endDatePlaceholderText='To'
                showDefaultInputIcon={true}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={({ startDate, endDate }) =>
                  this.onDatesChange(startDate, endDate)
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                isOutsideRange={(day) =>
                  !isInclusivelyBeforeDay(
                    day,
                    getCurrentMomentDate(this.props.timeZone)
                  )
                }
                minimumNights={0}
              />
            </div>
          </div>
          <div className='widget-chart-container'>
            {this.props.driverStatsByDay ? (
              <Line data={chartData} />
            ) : (
              <div
                style={{ padding: '50px' }}
                className='be-loading be-loading-active'
              >
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

DeliveryByDay.propTypes = {
  getDriverStatsByDayHandler: PropTypes.any,
  driverStatsByDay: PropTypes.any,
};

export default DeliveryByDay;
