import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getAllSuburbs(storeId) {
  return (dispatch) => {
    firestore
      .collection('Suburbs')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(allSuburbsFetched(dataArray));
      });
  };
}

export function getAllUnsupportedSuburbs(storeId) {
  return (dispatch) => {
    firestore
      .collection('NotSupportedSuburb')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(allUnsupportedSuburbsFetched(dataArray));
      });
  };
}

export function getSuburbCount(storeId) {
  return (dispatch) => {
    let ref = firestore.collection('Suburbs').where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(suburbCountFetched(querySnapshot.docs.length));
    });
  };
}

export function getSuburbs(storeId, pagination, sortObj) {
  return (dispatch) => {
    dispatch(suburbsFetched(undefined));
    let currentRef = firestore
      .collection('Suburbs')
      .where('storeId', '==', storeId)
      .orderBy(sortObj.name, sortObj.dir);
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('Suburbs')
          .where('storeId', '==', storeId)
          .orderBy(sortObj.name, sortObj.dir)
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(suburbsFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('Suburbs')
          .where('storeId', '==', storeId)
          .orderBy(sortObj.name, sortObj.dir)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(suburbsFetched(dataArray));
        });
      }
    });
  };
}

export function addSuburb(obj) {
  return (dispatch) => {
    if (obj._id) {
      firestore
        .collection('Suburbs')
        .doc(obj._id)
        .update({
          name: obj.name,
          deliveryCost: obj.deliveryCost,
          minOrderValue: obj.minOrderValue,
        })
        .then(() => {});
    } else {
      firestore
        .collection('Suburbs')
        .add(obj)
        .then(() => {});
    }
  };
}

export function deleteSuburb(obj) {
  return (dispatch) => {
    firestore
      .collection('Suburbs')
      .doc(obj._id)
      .delete()
      .then(() => {});
  };
}

function allSuburbsFetched(allSuburbs) {
  return {
    type: ActionTypes.FetchAllSuburbs,
    allSuburbs,
  };
}

function allUnsupportedSuburbsFetched(allSuburbs) {
  return {
    type: ActionTypes.FetchAllUnsupportedSuburbs,
    allSuburbs,
  };
}

function suburbsFetched(suburbs) {
  return {
    type: ActionTypes.FetchSuburbs,
    suburbs,
  };
}

function suburbCountFetched(totalCount) {
  return {
    type: ActionTypes.SuburbCountFetched,
    totalCount,
  };
}
