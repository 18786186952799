import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from '../../assets/images/avatar.png';
import { Modal } from 'react-bootstrap';
import DriverTracker from '../DriverTracker';
import { convertToFormattedMomentDate } from '../../helpers/timeHelper';
import getOrderStatus from '../../helpers/getOrderStatus';
import './index.css';
import {
  getUser,
  getDriver,
  getVoucher,
  getOrderHistory,
  getDeliveryAddresses,
} from './actions';
import { getStoreSetup } from '../../containers/StoreSetting/actions';
class OrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTab: 0,
      goupedMenuItems: [],
    };
  }
  componentDidMount() {
    if (this.props.selectedOrder) {
      let goupedMenuItems = this.getGoupedMenuItems(this.props.selectedOrder);
      this.setState(
        {
          order: this.props.selectedOrder,
          isDisplayOrder: true,
          goupedMenuItems,
        },
        () => {
          if (this.state.order.userId) {
            //TODO: FetchUser orders stats
            this.props.getUserHandler(this.state.order.userId);
            this.props.getDeliveryAddressesHandler(
              this.props.storeId,
              this.state.order.userId
            );
          }
          if (this.state.order.driverId) {
            this.props.getDriverHandler(this.state.order.driverId);
          }
          if (this.state.order.voucherId) {
            this.props.getVoucherHandler(this.state.order.voucherId);
          }
          if (this.state.order._id) {
            this.props.getOrderHistoryHandler(
              this.props.storeId,
              this.state.order._id
            );
          }
          this.props.getStoreSetupHandler({}, this.props.storeId);
        }
      );
    }
  }
  getGoupedMenuItems = (order) => {
    let items = [];
    if (order.menuItems) {
      order.menuItems.map((item) => {
        if (item.isHalf) {
          let betterHalfs = order.menuItems.filter((obj) => {
            return obj.halfIndex === item.halfIndex && obj._id !== item._id;
          });
          let xBetterHalf = items.filter((obj) => {
            return obj.keyHalf === item._id;
          });
          if (
            betterHalfs &&
            betterHalfs.length > 0 &&
            xBetterHalf.length === 0
          ) {
            item.keyHalf = betterHalfs[0]._id;
            item.nameHalf = betterHalfs[0].name;
            item.selectedSizesHalf = betterHalfs[0].selectedSizes;
            item.selectedIngredientsHalf = betterHalfs[0].selectedIngredients;
            item.selectedExtraIngredientsHalf =
              betterHalfs[0].selectedExtraIngredients;
            items.push(item);
          }
        } else {
          items.push(item);
        }
        return item;
      });
      return items;
    }
  };
  changeTabIndex = (index) => {
    this.setState({ openTab: index });
  };
  getOrderFrom = (orderFrom) => {
    if (orderFrom === '1') {
      return 'Web';
    } else if (orderFrom === '2') {
      return 'iOS';
    } else if (orderFrom === '3') {
      return 'Android';
    } else {
      return '';
    }
  };
  getCardTypeName = (c) => {
    if (c === '0') return 'Unknown';
    else if (c === '1') return 'JCB';
    else if (c === '2') return 'American Express(Amex)';
    else if (c === '3') return 'Diners Club';
    else if (c === '5') return 'MasterCard';
    else if (c === '6') return 'Visa';
    else return 'Unknown';
  };
  getDriverLeftStoreTime = () => {
    let obj = {
      isAvailable: false,
      time: '',
    };
    if (this.state.orderHistory) {
      let odSt = this.state.orderHistory.filter((m) => {
        return m.orderStatus === '25';
      });
      if (odSt.length > 0) {
        obj.isAvailable = true;
        obj.time = convertToFormattedMomentDate(
          odSt[0].date,
          'YYYY-MM-DD HH:mm',
          this.props.timeZone,
          'MMM DD, YYYY, hh:mm a'
        );
      }
    }
    return obj;
  };
  getDriverDeliveredTime = () => {
    let obj = {
      isAvailable: false,
      time: '',
    };
    if (this.state.orderHistory) {
      let odSt = this.state.orderHistory.filter((m) => {
        return m.orderStatus === '4';
      });
      if (odSt.length > 0) {
        obj.isAvailable = true;
        obj.time = convertToFormattedMomentDate(
          odSt[0].date,
          'YYYY-MM-DD HH:mm',
          this.props.timeZone,
          'MMM DD, YYYY, hh:mm a'
        );
      }
    }
    return obj;
  };

  ordinalSuffixOf = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + 'st';
    }
    if (j === 2 && k !== 12) {
      return i + 'nd';
    }
    if (j === 3 && k !== 13) {
      return i + 'rd';
    }
    return i + 'th';
  };
  getItemCustomizedPrice = (itemObj, sizeObj) => {
    let itemPrice = 0;
    let quantity = 0;
    let halfQantity = 0;
    let extraPrice = 0;
    let currentItem = JSON.parse(JSON.stringify(itemObj));
    currentItem.selectedSizes &&
      currentItem.selectedSizes.map((item) => {
        if (item._id === sizeObj._id) {
          if (Number(item.quantity) > 0) {
            quantity = quantity + Number(item.quantity);
            if (item.isGlutenFree === true) {
              itemPrice =
                itemPrice +
                Number(item.quantity) * Number(item.price) +
                Number(item.glutenAdditionalCharges) * Number(item.quantity);
            } else {
              itemPrice =
                itemPrice + Number(item.quantity) * Number(item.price);
            }
          }
        }
        return item;
      });
    if (quantity > 0) {
      currentItem.selectedExtraIngredients &&
        currentItem.selectedExtraIngredients.map((item) => {
          if (item.quantity > 0) {
            extraPrice =
              extraPrice + Number(item.quantity) * Number(item.price);
          }
          return item;
        });
      itemPrice = extraPrice * quantity + itemPrice;
    }
    if (itemObj.isHalf) {
      currentItem.selectedSizesHalf &&
        currentItem.selectedSizesHalf.map((item) => {
          if (item.quantity > 0) {
            halfQantity = halfQantity + item.quantity;
            if (item.isGlutenFree === true) {
              itemPrice =
                itemPrice +
                item.quantity * item.price +
                Number(item.glutenAdditionalCharges) * item.quantity;
            } else {
              itemPrice = itemPrice + item.quantity * item.price;
            }
          }
          return item;
        });
      if (halfQantity > 0) {
        currentItem.selectedExtraIngredientsHalf &&
          currentItem.selectedExtraIngredientsHalf.map((item) => {
            if (item.quantity > 0) {
              extraPrice = extraPrice + item.quantity * item.price;
            }
            return item;
          });
        itemPrice = extraPrice * quantity + itemPrice;
      }
      itemPrice =
        itemPrice +
        (this.props.productSetup
          ? Number(this.props.productSetup.halfHalfAdditionalCharges)
          : 0) *
          (quantity + halfQantity);
    }
    return itemPrice.toFixed(2);
  };
  getItemCustomizedPriceOnly = (itemObj, sizeObj) => {
    let itemPrice = 0;
    let quantity = 0;
    let halfQantity = 0;
    let currentItem = JSON.parse(JSON.stringify(itemObj));
    currentItem.selectedSizes &&
      currentItem.selectedSizes.map((item) => {
        if (item._id === sizeObj._id) {
          if (Number(item.quantity) > 0) {
            quantity = quantity + Number(item.quantity);
            if (item.isGlutenFree === true) {
              itemPrice =
                itemPrice +
                Number(item.quantity) * Number(item.price) +
                Number(item.glutenAdditionalCharges) * Number(item.quantity);
            } else {
              itemPrice =
                itemPrice + Number(item.quantity) * Number(item.price);
            }
          }
        }
        return item;
      });
    if (itemObj.isHalf) {
      currentItem.selectedSizesHalf &&
        currentItem.selectedSizesHalf.map((item) => {
          if (item.quantity > 0) {
            halfQantity = halfQantity + item.quantity;
            if (item.isGlutenFree === true) {
              itemPrice =
                itemPrice +
                item.quantity * item.price +
                Number(item.glutenAdditionalCharges) * item.quantity;
            } else {
              itemPrice = itemPrice + item.quantity * item.price;
            }
          }
          return item;
        });
      itemPrice =
        itemPrice +
        (this.props.productSetup
          ? Number(this.props.productSetup.halfHalfAdditionalCharges)
          : 0) *
          (quantity + halfQantity);
    }
    return itemPrice.toFixed(2);
  };
  render() {
    return (
      <div>
        <Modal
          aria-labelledby='modal-label'
          className='modal fade'
          show={this.props.isDisplayOrder}
          onHide={this.props.closeOrderModal}
          bsSize='large'
          dialogClassName='delivery-dialog'
        >
          <div className='modal-content'>
            <div
              style={{ marginBottom: '0px' }}
              className='panel panel-default'
            >
              <div className='panel-body'>
                <div className='row mapMargin'>
                  <div className='content'>
                    <button
                      onClick={this.props.closeOrderModal}
                      type='button'
                      data-dismiss='modal'
                      aria-hidden='true'
                      className='close md-close buttonOnMap'
                    >
                      <span className='mdi mdi-close' />
                    </button>
                    <DriverTracker
                      store={this.props.layout.store}
                      driver={this.props.orderModal.driver}
                      order={this.state.order}
                      storeId={this.props.storeId}
                    />
                  </div>
                </div>
                <div className='row delPopUpMargin'>
                  <div className='col-md-8'>
                    <div className='order-status'>
                      <p className='delPopupTitle'>
                        <span className='text-warning'>
                          {this.state.order &&
                            getOrderStatus(
                              this.state.order.orderStatus,
                              this.state.order.orderType
                            )}
                        </span>
                        <span>
                          {this.state.order &&
                            (this.state.order.orderStatus === '3' ||
                              this.state.order.orderStatus === '4') &&
                            ` to ${
                              this.state.order.address
                                ? this.state.order.address
                                : 'N/A'
                            }`}
                        </span>
                        <span>
                          {this.state.order &&
                            this.state.order.orderStatus === '2' &&
                            ` going to ${
                              this.state.order.address
                                ? this.state.order.address
                                : 'N/A'
                            }`}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    {this.props.orderModal &&
                      this.props.orderModal.driver &&
                      this.state.order &&
                      this.state.order.orderType === '2' && (
                        <div>
                          <img
                            src={Avatar}
                            alt='Avatar'
                            className='user-avatarg'
                          />
                          <span className='delPopUpUser'>
                            {`${this.props.orderModal.driver.firstName} ${this.props.orderModal.driver.lastName}`}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div>
                      {this.state.order && this.state.order.orderType !== '1' && (
                        <p className='delPopUpSubText'>
                          {`Expected delivery time: ${convertToFormattedMomentDate(
                            this.state.order.deliveryDate,
                            'YYYY-MM-DD HH:mm',
                            this.props.timeZone,
                            'hh:mm a'
                          )} `}
                          <span style={{ fontFamily: 'Roboto-Light' }}>
                            (Currently running
                            <span className='text-success'>
                              {this.props.storeSetting.orderSetup
                                ? `${Number(
                                    this.props.storeSetting.orderSetup
                                      .deliveryTime
                                  )} minutes`
                                : 'N/A'}
                            </span>
                            )
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='tab-container'>
                      <ul className='nav nav-tabs'>
                        <li
                          onClick={() => this.changeTabIndex(0)}
                          className={this.state.openTab === 0 ? 'active' : ''}
                        >
                          <a hred='order' data-toggle='tab'>
                            Order
                          </a>
                        </li>
                        <li
                          onClick={() => this.changeTabIndex(1)}
                          className={this.state.openTab === 1 ? 'active' : ''}
                        >
                          <a hred='customer' data-toggle='tab'>
                            Customer
                          </a>
                        </li>
                        <li
                          onClick={() => this.changeTabIndex(2)}
                          className={this.state.openTab === 2 ? 'active' : ''}
                        >
                          <a hred='history' data-toggle='tab'>
                            Status history
                          </a>
                        </li>
                      </ul>
                      <div className='tab-content'>
                        <div
                          className={
                            this.state.openTab === 0
                              ? 'tab-pane active cont'
                              : 'tab-pane cont'
                          }
                        >
                          <div className='tab-container'>
                            {this.state.order && this.state.order.menuItems && (
                              <div className='row'>
                                <div className='col-md-7'>
                                  <div className='row detailHeader'>
                                    <p>Order</p>
                                    {this.state.goupedMenuItems.map(
                                      (item, index) => (
                                        <div key={index}>
                                          {!item.isHalf &&
                                            item.selectedSizes.map(
                                              (size, index) => (
                                                <div
                                                  key={index}
                                                  className='delItem'
                                                >
                                                  <p key={index}>
                                                    <span className='extaWidth'>{`${
                                                      size.quantity
                                                    } ${size.name} ${
                                                      item.name
                                                    } ${
                                                      size.isGlutenFree
                                                        ? '(Gluten Free)'
                                                        : ''
                                                    }`}</span>
                                                    <span className='priceFont'>
                                                      {`${
                                                        this.props.currency
                                                      }${this.getItemCustomizedPriceOnly(
                                                        item,
                                                        size
                                                      )}`}
                                                    </span>
                                                  </p>
                                                  {item.selectedExtraIngredients &&
                                                    item.selectedExtraIngredients.map(
                                                      (ingredient, index) => (
                                                        <div
                                                          key={index}
                                                          className='ingDelInn'
                                                        >
                                                          <span className='extaWidth'>{`Extra ${ingredient.ingredientName}`}</span>
                                                          <span className='priceFont'>
                                                            {`${
                                                              this.props
                                                                .currency
                                                            }${(
                                                              Number(
                                                                ingredient.price
                                                              ) *
                                                              Number(
                                                                ingredient.quantity
                                                              ) *
                                                              Number(
                                                                size.quantity
                                                              )
                                                            ).toFixed(2)}`}
                                                          </span>
                                                        </div>
                                                      )
                                                    )}
                                                  {item.removedIngredients &&
                                                    item.removedIngredients.map(
                                                      (ingredient, index) => (
                                                        <div
                                                          key={index}
                                                          className='ingDelInn'
                                                        >
                                                          <span>{`Remove ${ingredient.ingredientName}`}</span>
                                                          <span className='tebdo' />
                                                        </div>
                                                      )
                                                    )}
                                                  <div className='ingDelInn'>
                                                    <span className='extaWidth' />
                                                    <span className='aggrPrice'>{`${
                                                      this.props.currency
                                                    }${this.getItemCustomizedPrice(
                                                      item,
                                                      size
                                                    )}`}</span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          {item.isHalf &&
                                            item.selectedSizes.map(
                                              (size, index) => (
                                                <div
                                                  key={`${item.keyHalf}${index}`}
                                                  className='delItem'
                                                >
                                                  <p key={index}>
                                                    <span className='extaWidth'>{`${
                                                      size.quantity * 2
                                                    } ${size.name} ${
                                                      item.name
                                                    }/${item.nameHalf} ${
                                                      size.isGlutenFree
                                                        ? '(Gluten Free)'
                                                        : ''
                                                    }`}</span>
                                                    <span className='priceFont'>
                                                      {`${
                                                        this.props.currency
                                                      }${this.getItemCustomizedPriceOnly(
                                                        item,
                                                        size
                                                      )}`}
                                                    </span>
                                                  </p>
                                                  <div>
                                                    {((item.selectedExtraIngredients &&
                                                      item
                                                        .selectedExtraIngredients
                                                        .length > 0) ||
                                                      (item.removedIngredients &&
                                                        item.removedIngredients
                                                          .length > 0)) && (
                                                      <span className='halfItem'>
                                                        {item.name}
                                                      </span>
                                                    )}
                                                    {item.selectedExtraIngredients &&
                                                      item.selectedExtraIngredients.map(
                                                        (ingredient, index) => (
                                                          <div
                                                            key={index}
                                                            className='ingDelInn'
                                                          >
                                                            <span className='extaWidth'>{`Extra ${ingredient.ingredientName}`}</span>
                                                            <span className='priceFont'>
                                                              {`${
                                                                this.props
                                                                  .currency
                                                              }${(
                                                                Number(
                                                                  ingredient.price
                                                                ) *
                                                                Number(
                                                                  ingredient.quantity
                                                                ) *
                                                                Number(
                                                                  size.quantity
                                                                )
                                                              ).toFixed(2)}`}
                                                            </span>
                                                          </div>
                                                        )
                                                      )}
                                                    {item.removedIngredients &&
                                                      item.removedIngredients.map(
                                                        (ingredient, index) => (
                                                          <div
                                                            key={index}
                                                            className='ingDelInn'
                                                          >
                                                            <span>{`Remove ${ingredient.ingredientName}`}</span>
                                                            <span className='tebdo' />
                                                          </div>
                                                        )
                                                      )}
                                                  </div>
                                                  <div className='halfItem'>
                                                    {((item.selectedExtraIngredientsHalf &&
                                                      item
                                                        .selectedExtraIngredientsHalf
                                                        .length > 0) ||
                                                      (item.removedIngredientsHalf &&
                                                        item
                                                          .removedIngredientsHalf
                                                          .length > 0)) && (
                                                      <span>
                                                        {item.nameHalf}
                                                      </span>
                                                    )}
                                                    {item.selectedExtraIngredientsHalf &&
                                                      item.selectedExtraIngredientsHalf.map(
                                                        (ingredient, index) => (
                                                          <div
                                                            key={index}
                                                            className='ingDelInn'
                                                          >
                                                            <span className='extaWidth'>{`Extra ${ingredient.ingredientName}`}</span>
                                                            <span className='priceFont'>{`${
                                                              this.props
                                                                .currency
                                                            }${(
                                                              Number(
                                                                ingredient.price
                                                              ) *
                                                              Number(
                                                                ingredient.quantity
                                                              ) *
                                                              Number(
                                                                size.quantity
                                                              )
                                                            ).toFixed(
                                                              2
                                                            )}`}</span>
                                                          </div>
                                                        )
                                                      )}
                                                    {item.removedIngredientsHalf &&
                                                      item.removedIngredientsHalf.map(
                                                        (ingredient, index) => (
                                                          <div
                                                            key={index}
                                                            className='ingDelInn'
                                                          >
                                                            <span>{`Remove ${ingredient.ingredientName}`}</span>
                                                            <span className='tebdo' />
                                                          </div>
                                                        )
                                                      )}
                                                  </div>
                                                  <div className='ingDelInn'>
                                                    <span className='extaWidth' />
                                                    <span className='aggrPrice'>{`${
                                                      this.props.currency
                                                    }${this.getItemCustomizedPrice(
                                                      item,
                                                      size
                                                    )}`}</span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className='row' />
                                  {this.state.order &&
                                    this.state.order.specials &&
                                    this.state.order.specials.length > 0 && (
                                      <div className='row specialSec'>
                                        <p>Special</p>
                                        {this.state.order.specials.map(
                                          (m, index) => (
                                            <p key={index}>
                                              <span className='extaWidth lightFont'>
                                                {m.name}
                                              </span>
                                              <span>{`-${this.props.currency}${m.discount}`}</span>
                                            </p>
                                          )
                                        )}
                                      </div>
                                    )}
                                  {this.state.order &&
                                    this.state.order.rewards &&
                                    this.state.order.rewards.length > 0 && (
                                      <div className='row specialSec'>
                                        <p>Loyalty Offers</p>
                                        {this.state.order.rewards.map(
                                          (m, index) => (
                                            <p key={index}>
                                              <span className='extaWidth lightFont'>
                                                {m.name}
                                              </span>
                                              <span>{`-${this.props.currency}${m.discount}`}</span>
                                            </p>
                                          )
                                        )}
                                      </div>
                                    )}
                                  {this.state.order &&
                                    this.state.order.isDisplayGST &&
                                    Number(this.state.order.gst) > 0 && (
                                      <div className='row specialSec'>
                                        <p></p>
                                        <p>
                                          <span className='extaWidth lightFont'>
                                            GST
                                          </span>
                                          <span>{`${this.props.currency}${this.state.order.gst}`}</span>
                                        </p>
                                      </div>
                                    )}
                                </div>
                                <div className='col-md-5'>
                                  {(this.state.order &&
                                    this.state.order.orderType !== '1') ||
                                  (this.state.order.publicHolidaySurcharge &&
                                    Number(
                                      this.state.order.publicHolidaySurcharge
                                    ) !== 0) ? (
                                    <div className='row detailHeader'>
                                      <p>Additional charges</p>
                                      <div className='ingDel'>
                                        {this.state.order &&
                                          this.state.order.orderType !==
                                            '1' && (
                                            <p>
                                              <span className='extraWidth floatleft lightFont'>
                                                Delivery Fee
                                              </span>
                                              <span className='aggPrice'>{`${this.props.currency}${this.state.order.deliveryCost}`}</span>
                                            </p>
                                          )}
                                        {this.state.order &&
                                          this.state.order
                                            .publicHolidaySurcharge &&
                                          Number(
                                            this.state.order
                                              .publicHolidaySurcharge
                                          ) !== 0 && (
                                            <p>
                                              <span className='extraWidth floatleft lightFont'>
                                                Public Holiday Surcharge
                                                {`${
                                                  this.state.order &&
                                                  this.state.order
                                                    .publicHolidaySurchargePer &&
                                                  Number(
                                                    this.state.order
                                                      .publicHolidaySurchargePer
                                                  ) !== 0
                                                    ? `(${this.state.order.publicHolidaySurchargePer}%)`
                                                    : ``
                                                }`}
                                              </span>
                                              <span className='aggPrice'>{`${this.props.currency}${this.state.order.publicHolidaySurcharge}`}</span>
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                  {this.props.orderModal.voucher &&
                                    this.props.selectedOrder.voucherId !==
                                      '' && (
                                      <div className='row detailHeader'>
                                        <p>Voucher code</p>
                                        <div className='ingDel'>
                                          <p>
                                            <span className='extraWidth floatleft lightFont'>
                                              {
                                                this.props.orderModal.voucher
                                                  .voucherCode
                                              }
                                            </span>
                                            <span className='aggPrice'>{`-${this.props.currency}${this.state.order.voucherDiscount}`}</span>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  {this.state.order &&
                                    this.state.order
                                      .halfHalfAdditionalCharges &&
                                    Number(
                                      this.state.order.halfHalfAdditionalCharges
                                    ) !== 0 && (
                                      <div className='row detailHeader'>
                                        <p>Half / Half Charges</p>
                                        <div className='ingDel'>
                                          <p>
                                            <span className='extraWidth floatleft lightFont'>
                                              Half Half Additional Charge
                                            </span>
                                            <span className='aggPrice'>{`${this.props.currency}${this.state.order.halfHalfAdditionalCharges}`}</span>
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  {this.state.order &&
                                    this.state.order.paymentType === '2' && (
                                      <div className='row detailHeader'>
                                        <p>Payment</p>
                                        <div className='ingDel'>
                                          <p>
                                            <span className='extraWidth floatleft lightFont'>
                                              {this.getCardTypeName(
                                                this.state.order.cardType
                                              )}
                                            </span>
                                            {this.state.order.payableAmount &&
                                              `${this.props.currency}${this.state.order.payableAmount}`}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  {this.state.order.notes && (
                                    <div
                                      className='row detailHeader'
                                      style={{ marginTop: '15px' }}
                                    >
                                      <p>Delivery notes</p>
                                      <div className='ingDel'>
                                        <p style={{ overflowX: 'scroll' }}>
                                          <span>
                                            {this.state.order.notes
                                              ? this.state.order.notes
                                              : 'N/A'}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className='row'>
                              <div className='col-md-7'>
                                <div className='row detailHeader'>
                                  <p>
                                    <span className='extraWidth floatleft '>
                                      Total
                                    </span>
                                    <span className='aggPrice'>
                                      {this.state.order &&
                                        this.state.order.payableAmount &&
                                        `${this.props.currency}${this.state.order.payableAmount}`}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.openTab === 1
                              ? 'tab-pane active cont'
                              : 'tab-pane cont'
                          }
                        >
                          <div className='tab-container'>
                            <div className='row'>
                              <div className='col-md-6'>
                                {this.props.orderModal.user && (
                                  <div className='row detailHeaderC'>
                                    <p>Customer</p>
                                    <div className='ingDelCustomer'>
                                      <p>{`${this.props.orderModal.user.firstName} ${this.props.orderModal.user.lastName}`}</p>
                                      <p>
                                        {
                                          this.props.orderModal.user
                                            .mobileNumber
                                        }
                                      </p>
                                      <p>{this.props.orderModal.user.email}</p>
                                    </div>
                                  </div>
                                )}
                                {this.props.orderModal.deliveryAddresses &&
                                  this.props.orderModal.deliveryAddresses
                                    .length > 0 && (
                                    <div className='row detailHeaderC'>
                                      <p>Delivery addresses used</p>
                                      <div className='ingDelCustomerAddr'>
                                        {this.props.orderModal.deliveryAddresses.map(
                                          (address, index) => (
                                            <p key={index}>{address.address}</p>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <div className='col-md-6'>
                                <div className='row detailHeaderC'>
                                  <p>Customer history</p>
                                  <div className='ingDelCustomer'>
                                    <p>{`Customer since ${
                                      this.state.order &&
                                      this.props.orderModal.user &&
                                      this.props.orderModal.user.date
                                        ? convertToFormattedMomentDate(
                                            this.props.orderModal.user.date,
                                            'YYYY-MM-DD HH:mm',
                                            this.props.timeZone,
                                            'YYYY'
                                          )
                                        : 'N/A'
                                    }`}</p>
                                    {this.state.order &&
                                      this.state.order.nthOrder &&
                                      (this.state.order.orderType !== '1' ? (
                                        <p>{`${
                                          this.state.order &&
                                          this.state.order.nthOrder
                                            ? this.ordinalSuffixOf(
                                                this.state.order.nthOrder
                                              )
                                            : ''
                                        } Order (${
                                          this.state.order &&
                                          this.state.order.nthDeliveryOrder
                                            ? this.ordinalSuffixOf(
                                                this.state.order
                                                  .nthDeliveryOrder
                                              )
                                            : ''
                                        } Delivery)`}</p>
                                      ) : (
                                        <p>{`${
                                          this.state.order &&
                                          this.state.order.nthOrder
                                            ? this.ordinalSuffixOf(
                                                this.state.order.nthOrder
                                              )
                                            : ''
                                        } Order (${
                                          this.state.order &&
                                          this.state.order.nthPickupOrder
                                            ? this.ordinalSuffixOf(
                                                this.state.order.nthPickupOrder
                                              )
                                            : ''
                                        } Pickup)`}</p>
                                      ))}
                                    {this.state.order &&
                                      this.state.order.orderType !== '1' && (
                                        <p className='text-success'>{`${
                                          this.props.orderModal.user
                                            ? this.props.orderModal.user
                                                .averageDeliveryTime
                                            : ''
                                        } minutes average delivery time`}</p>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.openTab === 2
                              ? 'tab-pane active cont'
                              : 'tab-pane cont'
                          }
                        >
                          <div className='tab-container'>
                            <div className='row'>
                              <div className='col-md-12'>
                                <div className='row detailHeader'>
                                  <p>Status history</p>
                                  <div className='ingDel'>
                                    <p>
                                      {`Order placed on ${this.getOrderFrom(
                                        this.state.order &&
                                          this.state.order.orderFrom
                                          ? this.state.order.orderFrom
                                          : ''
                                      )}:${
                                        this.state.order &&
                                        this.state.order.date
                                          ? convertToFormattedMomentDate(
                                              this.state.order.date,
                                              'YYYY-MM-DD HH:mm',
                                              this.props.timeZone,
                                              'MMM DD, YYYY, hh:mm a'
                                            )
                                          : ''
                                      }`}
                                    </p>
                                    {this.state.orderHistory &&
                                      this.state.orderHistory.length > 0 &&
                                      this.getDriverLeftStoreTime()
                                        .isAvailable && (
                                        <p>
                                          {`
                                                                                            ${
                                                                                              this
                                                                                                .state
                                                                                                .driver
                                                                                                ? `${this.state.driver.firstName} ${this.state.driver.lastName}`
                                                                                                : ''
                                                                                            } left store: ${
                                            this.getDriverLeftStoreTime().time
                                          }
                                                                                        `}
                                        </p>
                                      )}
                                    {this.state.orderHistory &&
                                      this.state.orderHistory.length > 0 &&
                                      this.getDriverDeliveredTime()
                                        .isAvailable && (
                                        <p>
                                          {`
                                                                                          ${
                                                                                            this
                                                                                              .state
                                                                                              .driver
                                                                                              ? `${this.state.driver.firstName} ${this.state.driver.lastName}`
                                                                                              : ''
                                                                                          } has delivered order: ${
                                            this.getDriverDeliveredTime().time
                                          }
                                                                                            `}
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

OrderModal.propTypes = {
  isDisplayOrder: PropTypes.any,
  closeOrderModal: PropTypes.any,
  selectedOrder: PropTypes.any,
  timeZone: PropTypes.any,
  storeId: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    orderModal: state.orderModalReducer,
    storeSetting: state.storeSettingReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserHandler: (userId) => dispatch(getUser(userId)),
    getDriverHandler: (driverId) => dispatch(getDriver(driverId)),
    getVoucherHandler: (voucherId) => dispatch(getVoucher(voucherId)),
    getDeliveryAddressesHandler: (storeId, userId) =>
      dispatch(getDeliveryAddresses(storeId, userId)),
    getOrderHistoryHandler: (storeId, orderId) =>
      dispatch(getOrderHistory(storeId, orderId)),
    getStoreSetupHandler: (storeSetup, storeId) =>
      dispatch(getStoreSetup(storeSetup, storeId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderModal);
