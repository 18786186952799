import React, { Component } from 'react';
import { withRouter } from 'react-router';
import SlidingMarker from 'marker-animate-unobtrusive';
import '../../assets/css/fonts.css';
import '../../assets/lib/material-design-icons/css/material-design-iconic-font.min.css';
import '../../assets/lib/bootstrap/dist/css/bootstrap.css';
import '../../assets/css/style.css';

class Layout extends Component {
  componentDidMount() {
    SlidingMarker.initializeGlobally();
  }

  render() {
    return (
      <div
        style={{ paddingTop: '0px' }}
        className='be-wrapper be-nosidebar-left'
      >
        <div className='be-content'>{this.props.children}</div>
      </div>
    );
  }
}

export default withRouter(Layout);
