import React, { Component } from 'react';
import DateRangePickerField from '../../components/DateRangePickerField';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';
import {
  getCurrentFormattedMomentDate,
  convertToMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';

const validate = (values) => {
  const errors = {};
  if (values.dates) {
    if (!values.dates.startDate) {
      errors.dates = 'Start date is required';
    }
    if (!values.dates.endDate) {
      errors.dates = 'End date is required';
    }
  } else {
    errors.dates = 'Select proper date range';
  }
  return errors;
};

const warn = () => {
  const warnings = {};
  return warnings;
};
class ClosedDate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (values) => {
    const payload = {};
    let dateRangeObj = {};
    if (values) {
      //Date string in defined time zone
      payload.date = getCurrentFormattedMomentDate(
        this.props.timeZone,
        'YYYY-MM-DD HH:mm'
      );

      //UTC time stamp of date
      payload.dateTimestamp = getCurrentTimestamp();
      if (values.dates && values.dates.startDate)
        dateRangeObj.startDate = convertToMomentDate(
          values.dates.startDate,
          'YYYY-MM-DD',
          this.props.timeZone
        );
      if (values.dates && values.dates.endDate)
        dateRangeObj.endDate = convertToMomentDate(
          values.dates.endDate,
          'YYYY-MM-DD',
          this.props.timeZone
        );
      if (values.isAllClosed) payload.isAllClosed = values.isAllClosed;
      else payload.isAllClosed = false;
      if (values.isDeliveryClosed)
        payload.isDeliveryClosed = values.isDeliveryClosed;
      else payload.isDeliveryClosed = false;
      if (values.isAllClosed || values.isDeliveryClosed) {
        this.props.addCloseDateHandler(payload, dateRangeObj);
        this.props.reset('closedDate');
      }
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div className='col-md-4'>
        <div className='panel'>
          <div className='panel-heading panel-heading-divider bline'>
            Add new closed dates
          </div>
          <div className='panel-body'>
            <Form
              onSubmit={handleSubmit(this.handleSubmit)}
              className='form-horizontal'
            >
              <div className='form-group'>
                <label className='col-md-3 col-sm-12 control-label'>
                  Dates
                </label>
                <div className='col-md-9'>
                  <div className='input-group'>
                    <Field component={DateRangePickerField} name='dates' />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label className='col-md-3 control-label'> </label>
                <div className='col-md-9'>
                  <div className='be-checkbox'>
                    <Field
                      name='isAllClosed'
                      id='isAllClosed'
                      type='checkbox'
                      component='input'
                    />
                    <label htmlFor='isAllClosed'>
                      Close website(pickup, delivery and table booking)
                    </label>
                  </div>
                  <div className='be-checkbox'>
                    <Field
                      name='isDeliveryClosed'
                      id='isDeliveryClosed'
                      type='checkbox'
                      component='input'
                    />
                    <label htmlFor='isDeliveryClosed'>
                      Just close deliveries
                    </label>
                  </div>
                </div>
              </div>
              <div className='row xs-pt-15'>
                <div className='col-md-12'>
                  <p className='text-right'>
                    <button
                      disabled={submitting}
                      type='submit'
                      className='btn btn-space btn-primary'
                    >
                      Add Close Date
                    </button>
                  </p>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

ClosedDate.propTypes = {
  addCloseDateHandler: PropTypes.any,
};

ClosedDate = reduxForm({
  form: 'closedDate',
  enableReinitialize: true,
  validate,
  warn,
  initialValues: { isAllClosed: true, isDeliveryClosed: false },
})(ClosedDate);

ClosedDate = connect((_state) => ({
  initialValues: { isAllClosed: true, isDeliveryClosed: false },
}))(ClosedDate);

export default ClosedDate;
