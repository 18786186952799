import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchOrders:
      return { ...state, pickupOrders: payload.pickupOrders };
    case ActionTypes.FetchOrderCount:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.MenuItemsCountFetched:
      return { ...state, totalCountP: payload.totalCountP };
    case ActionTypes.FetchMenuItems:
      return { ...state, menuItemsP: payload.menuItemsP };
    default:
      return state;
  }
};
