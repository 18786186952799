import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import OrderSetup from '../../components/OrderSetup';
import { getStoreSetup, saveProductSetup, saveOrderSetup } from './actions';
import { getCategories, getSizes } from '../../containers/Menu/actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class StoreSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productSetup: { halfHalfAdditionalCharges: '0' },
      orderSetup: {
        minPickupOrderValue: '0',
        minDeliveryOrderValue: '0',
        isDeliveryFreeOver: false,
        deliveryFreeOver: '0',
        isDeliveryAllowed: false,
      },
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/settings/system-setting'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    productSetup: {
                      ...this.state.productSetup,
                      storeId: doc.data().storeId,
                      restaurantId: doc.data().storeId,
                    },
                    orderSetup: {
                      ...this.state.orderSetup,
                      storeId: doc.data().storeId,
                      restaurantId: doc.data().storeId,
                    },
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getStoreSetupHandler(
                      this.state,
                      doc.data().storeId
                    );
                    this.props.getSizesHandler(doc.data().storeId);
                    this.props.getCategoriesHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      productSetup: props.storeSetting.productSetup,
      orderSetup: props.storeSetting.orderSetup,
      sizes: props.menu.allSizes,
      categories: props.menu.categories,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  saveOrderSetupHandler = (obj) => {
    obj.storeId = this.state.storeId;
    obj.restaurantId = this.state.restaurantId;
    this.props.saveOrderSetupHandler(obj);
  };

  saveProductSetupHandler = (obj) => {
    obj.storeId = this.state.storeId;
    obj.restaurantId = this.state.restaurantId;
    this.props.saveProductSetupHandler(obj);
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <OrderSetup
              saveOrderSetupHandler={this.saveOrderSetupHandler}
              orderSetup={this.state.orderSetup}
              timeZone={this.state.timeZone}
              Sentry={Sentry}
              currency={currency}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    storeSetting: state.storeSettingReducer,
    menu: state.menuReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCategoriesHandler: (storeId) => dispatch(getCategories(storeId)),
    getSizesHandler: (storeId) => dispatch(getSizes(storeId)),
    getStoreSetupHandler: (storeSetup, storeId) =>
      dispatch(getStoreSetup(storeSetup, storeId)),
    saveOrderSetupHandler: (orderSetup) => dispatch(saveOrderSetup(orderSetup)),
    saveProductSetupHandler: (productSetup) =>
      dispatch(saveProductSetup(productSetup)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreSetting);
