import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';
import './index.css';

class SizesWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSizes: [],
      imagePreviewUrl: '',
      isError: false,
      message: '',
      isDisplay: false,
      isValidationError: false,
      validationMessage: '',
    };
  }

  componentDidMount() {
    this.setState(
      {
        selectedSizes:
          this.props.menuItem && this.props.menuItem.selectedSizes
            ? this.props.menuItem.selectedSizes
            : [],
      },
      () => {
        let reader = new FileReader();
        if (this.props.menuItem && this.props.menuItem.photo) {
          let file = this.props.menuItem.photo;
          reader.onloadend = () => {
            this.setState({
              file: file,
              imagePreviewUrl: reader.result,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    );
  }

  componentWillReceiveProps(props) {
    let reader = new FileReader();
    if (props.menuItem && props.menuItem.photo) {
      let file = props.menuItem.photo;
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  txtChangeHandler = (e) => {
    this.setState({
      isError: false,
      newSize: e.target.value,
    });
  };

  addSize = () => {
    if (
      this.state.newSize === undefined ||
      this.state.newSize === null ||
      this.state.newSize === ''
    ) {
      this.setState({
        isError: true,
        message: 'Size name should not be empty!',
      });
    } else {
      let size = {
        name: this.state.newSize,
        price: 0,
        isFeaturedSize: false,
        isAbbreviationShow: false,
        isGlutenFree: false,
        glutenAdditionalCharges: 0,
        date: getCurrentFormattedMomentDate(
          this.props.timeZone,
          'YYYY-MM-DD HH:mm'
        ),
        dateTimestamp: getCurrentTimestamp(),
      };
      this.setState(
        {
          isDisplay: false,
          newSize: '',
          isError: false,
          message: 'Size name should not be empty!',
        },
        () => {
          this.props.addSize(size);
        }
      );
    }
  };

  closeSection = () => {
    this.setState({ newSize: '', isDisplay: false });
  };

  openSection = () => {
    this.setState({ newSize: '', isDisplay: true });
  };

  sizeMenuChange = (e) => {
    if (e.target.value !== '0') {
      const mySize = this.props.sizes.filter((obj) => {
        return obj._id === e.target.value;
      });
      const avlSize = this.state.selectedSizes.filter((obj) => {
        return obj._id === mySize[0]._id;
      });
      if (avlSize && avlSize.length < 1) {
        this.setState({
          isValidationError: false,
          selectedSizes: [mySize[0], ...this.state.selectedSizes],
        });
      }
    }
  };

  setFeaturedSize = (key) => {
    this.state.selectedSizes.map((size) => {
      if (size._id === key) size.isFeaturedSize = true;
      else {
        size.isFeaturedSize = false;
        size.isAbbreviationShow = false;
      }
      return size;
    });
    this.setState({
      selectedSizes: this.state.selectedSizes,
    });
  };

  removeItem = (key) => {
    const mySize = this.state.selectedSizes.filter(function (obj) {
      return obj._id !== key;
    });
    this.setState({
      isValidationError: false,
      selectedSizes: mySize,
    });
  };

  priceChange = (e, size) => {
    if (!isNaN(e.target.value)) {
      this.state.selectedSizes.map((item) => {
        if (item._id === size._id) item.price = e.target.value;
        return item;
      });
      this.setState({
        isValidationError: false,
        selectedSizes: this.state.selectedSizes,
      });
    }
  };

  addPriceChange = (e, size) => {
    if (!isNaN(e.target.value)) {
      this.state.selectedSizes.map((item) => {
        if (item._id === size._id)
          item.glutenAdditionalCharges = e.target.value;
        return item;
      });
      this.setState({
        isValidationError: false,
        selectedSizes: this.state.selectedSizes,
      });
    }
  };

  handleChange = (e, size) => {
    this.state.selectedSizes.map((item) => {
      if (item._id === size._id) {
        if (item.isGlutenFree) item.isGlutenFree = false;
        else item.isGlutenFree = true;
      }
      return item;
    });
    this.setState({
      isValidationError: false,
      selectedSizes: this.state.selectedSizes,
    });
  };

  handleAbbrChange = (e) => {
    if (e.target.checked) {
      this.state.selectedSizes.map((item) => {
        if (item.isFeaturedSize === true) {
          if (item.isAbbreviationShow) item.isAbbreviationShow = false;
          else item.isAbbreviationShow = true;
        } else {
          item.isAbbreviationShow = false;
        }
        return item;
      });
    } else {
      this.state.selectedSizes.map((item) => {
        if (item.isFeaturedSize === true) {
          if (item.isAbbreviationShow) item.isAbbreviationShow = false;
          else item.isAbbreviationShow = false;
        } else {
          item.isAbbreviationShow = false;
        }
        return item;
      });
    }
    this.setState({
      isValidationError: false,
      selectedSizes: this.state.selectedSizes,
    });
  };

  nextPage = () => {
    if (this.state.selectedSizes && this.state.selectedSizes.length >= 1) {
      let error = 0;
      this.state.selectedSizes.map((item) => {
        if (!item.price || item.price === '' || isNaN(item.price)) {
          error++;
        }
        return item;
      });
      if (error === 0) {
        this.props.setItemSelectedSizes(this.state.selectedSizes);
      } else {
        this.setState({
          isValidationError: true,
          validationMessage: 'Selected sizes must have valid prices defined !',
        });
      }
    } else {
      this.setState({
        isValidationError: true,
        validationMessage: 'Select item sizes !',
      });
    }
  };

  removeAlert = () => {
    this.setState({ isValidationError: false, validationMessage: '' });
  };

  render() {
    const mySize = this.state.selectedSizes.filter(function (obj) {
      return obj.isFeaturedSize === true;
    });
    const style14 = { float: 'right' };
    const style15 = { float: 'left' };
    const style17 = { backgroundColor: 'rgb(241, 220, 220' };
    return (
      <div>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='col-lg-4 col-md-4 col-xs-12 col-sm-4 proboxspace'>
                <div>
                  {this.state.imagePreviewUrl && (
                    <img
                      className='proimg'
                      alt=''
                      src={this.state.imagePreviewUrl}
                    />
                  )}
                  {this.props.menuItem &&
                    this.props.menuItem.urlS3 &&
                    !this.state.imagePreviewUrl && (
                      <img
                        className='proimg'
                        alt=''
                        src={this.props.menuItem.urlS3}
                      />
                    )}
                </div>
                <div className='hprodet'>
                  {mySize.length > 0 && mySize[0] && (
                    <div className='hpropri'>
                      {mySize[0].isAbbreviationShow
                        ? `${mySize[0].name[0]}`
                        : ''}
                      {` ${mySize[0].price}`}
                    </div>
                  )}
                  <div className='hproname'>{this.props.menuItem.name}</div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                {this.props.sizes && (
                  <select
                    className='form-control'
                    onChange={this.sizeMenuChange}
                  >
                    <option value='0'>{`Select size`}</option>))
                    {this.props.sizes &&
                      this.props.sizes.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
              <div className='form-group'>
                <button
                  onClick={this.openSection}
                  type='button'
                  className='btn btn-default dropdown-toggle'
                  data-toggle='modal'
                  data-target='#addSizes'
                >
                  Add Size Name
                </button>
              </div>
              {this.state.isDisplay && (
                <div className='form-group'>
                  <input
                    onChange={this.txtChangeHandler}
                    className='form-control input-xs xs-mb-20'
                    type='text'
                    placeholder='Size Name'
                  />
                  {this.state.isError && (
                    <ul
                      className='parsley-errors-list filled'
                      id='parsley-id-7'
                    >
                      <li className='parsley-type'>{this.state.message}</li>
                    </ul>
                  )}
                  <button
                    onClick={this.addSize}
                    className='btn btn-space btn-primary active'
                  >
                    Add
                  </button>
                  <button
                    onClick={this.closeSection}
                    className='btn btn-space btn-primary active'
                  >
                    Cancel
                  </button>
                </div>
              )}
              <div className='form-group'>
                <label className='col-md-3 control-label pp0'>
                  Featured Size- Hide Abbreviation
                </label>
                {mySize.length > 0 && mySize[0] && (
                  <div className='be-checkbox'>
                    <input
                      checked={mySize[0].isAbbreviationShow}
                      onChange={(e) => this.handleAbbrChange(e)}
                      id='gluten_additional_2'
                      type='checkbox'
                    />
                    <label htmlFor='gluten_additional_2' />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {this.state.selectedSizes.map((size, index) => (
                <div key={index} className='col-md-3'>
                  <div className='panel panel-flat' style={style17}>
                    <div className='panel-heading'>
                      <button
                        onClick={() => this.removeItem(size._id)}
                        type='button'
                        className='close floatLeft'
                      >
                        <span className='mdi mdi-close' />
                      </button>
                      <button
                        onClick={() => this.setFeaturedSize(size._id)}
                        type='button'
                        className='close floatRight'
                      >
                        <span
                          className={
                            size.isFeaturedSize
                              ? 'mdi mdi-star'
                              : 'mdi mdi-star-outline'
                          }
                        />
                      </button>
                    </div>
                    <div className='panel-body'>
                      <div className='circle-badge'>
                        <strong>{size.name}</strong>
                      </div>
                      <div className='col-md-12 pp0'>
                        <div className='form-group'>
                          <label className='col-sm-3 control-label pp0 poti'>
                            Price
                          </label>
                          <div className='col-sm-9 pp0'>
                            <div className='input-group'>
                              <span className='input-group-addon'>
                                {this.props.currency}
                              </span>
                              <input
                                type='hidden'
                                id={`size${size._id}`}
                                value='Small'
                              />
                              <input
                                value={size.price}
                                onChange={(event) =>
                                  this.priceChange(event, size)
                                }
                                id={`price${size._id}`}
                                placeholder='Price'
                                className='form-control'
                                type='text'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-group mt6 xs-mt-10'>
                          <label className='col-md-3 control-label pp0'>
                            Gluten Free
                          </label>
                          <div className='col-md-9 pp0'>
                            <div className='input-group'>
                              <div className='input-group-addon'>
                                <div className='be-checkbox'>
                                  <input
                                    checked={size.isGlutenFree}
                                    onChange={(event) =>
                                      this.handleChange(event, size)
                                    }
                                    id={`gluten_additional_check${size._id}`}
                                    name={`gluten_additional_check${size._id}`}
                                    type='checkbox'
                                  />
                                  <label
                                    htmlFor={`gluten_additional_check${size._id}`}
                                  />
                                </div>
                              </div>
                              <input
                                value={size.glutenAdditionalCharges}
                                onChange={(event) =>
                                  this.addPriceChange(event, size)
                                }
                                className='form-control'
                                id={`gluten_price${size._id}`}
                                type='text'
                                placeholder='Additional ($)'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='form-group'>
          <button
            style={style15}
            onClick={this.props.previousPage}
            className='btn btn-default btn-space'
          >
            <i className='icon mdi mdi-chevron-left' />
            <i className='icon mdi mdi-chevron-left' /> Previous
          </button>
          <button
            style={style14}
            onClick={this.nextPage}
            data-wizard='#wizard1'
            className='btn btn-primary btn-space wizard-next'
          >
            Next Step <i className='icon mdi mdi-chevron-right' />
            <i className='icon mdi mdi-chevron-right' />
          </button>
        </div>
        <div className='col-sm-12'>
          <div className='col-md-6' />
          <div className='col-md-6'>
            {this.state.isValidationError && (
              <div
                role='alert'
                className='alert alert-contrast alert-danger alert-dismissible'
              >
                <div className='icon'>
                  <span className='mdi mdi-close-circle-o' />
                </div>
                <div className='message'>
                  <button
                    onClick={this.removeAlert}
                    type='button'
                    data-dismiss='alert'
                    aria-label='Close'
                    className='close'
                  >
                    <span aria-hidden='true' className='mdi mdi-close' />
                  </button>
                  <strong>Danger!</strong> {this.state.validationMessage}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SizesWizard.propTypes = {
  nextPage: PropTypes.any,
  previousPage: PropTypes.any,
  menuItem: PropTypes.any,
  addSize: PropTypes.any,
  sizes: PropTypes.any,
  setItemSelectedSizes: PropTypes.any,
  selectedCategory: PropTypes.any,
  updateSelectedMenuItem: PropTypes.any,
};

export default SizesWizard;
