import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchReviews:
      return { ...state, reviews: payload.reviews };
    case ActionTypes.FetchRecentReviews:
      return { ...state, recentReviews: payload.reviews };
    case ActionTypes.FetchReviewCount:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.FetchUser:
      return { ...state, user: payload.user };
    case ActionTypes.FetchReviewStats:
      return { ...state, reviewStats: payload.reviewStats };
    case ActionTypes.FetchStore:
      return { ...state, store: payload.store };
    default:
      return state;
  }
};
