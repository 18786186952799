import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Avatar from '../../assets/images/avatar.png';
import DriverTracker from '../../components/DriverTracker';
import getOrderStatus from '../../helpers/getOrderStatus';
import { convertToFormattedMomentDate } from '../../helpers/timeHelper';
import {
  getUser,
  getDriver,
  getOtherOrderHistory,
  getDeliveryAddresses,
} from './actions';
import { getStoreSetup } from '../../containers/StoreSetting/actions';
class OtherOrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTab: 2,
    };
  }

  componentDidMount() {
    if (this.props.selectedOrder) {
      if (this.props.selectedOrder.userId) {
        //TODO: FetchUser orders stats
        this.props.getUserHandler(this.props.selectedOrder.userId);
        this.props.getDeliveryAddressesHandler(
          this.props.storeId,
          this.props.selectedOrder.userId
        );
      }
      if (this.props.selectedOrder.driverId) {
        this.props.getDriverHandler(this.props.selectedOrder.driverId);
      }
      if (this.props.selectedOrder._id) {
        this.props.getOtherOrderHistoryHandler(
          this.props.storeId,
          this.props.selectedOrder._id
        );
      }
      this.props.getStoreSetupHandler({}, this.props.storeId);
    }
  }

  ordinalSuffixOf = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + 'st';
    }
    if (j === 2 && k !== 12) {
      return i + 'nd';
    }
    if (j === 3 && k !== 13) {
      return i + 'rd';
    }
    return i + 'th';
  };

  changeTabIndex = (index) => {
    this.setState({ openTab: index });
  };

  getDriverLeftStoreTime = () => {
    let obj = {
      isAvailable: false,
      time: '',
    };
    if (this.props.otherOrderModal.otherOrderHistory) {
      let odSt = this.props.otherOrderModal.otherOrderHistory.filter((m) => {
        return m.orderStatus === '25';
      });
      if (odSt.length > 0) {
        obj.isAvailable = true;
        obj.time = convertToFormattedMomentDate(
          odSt[0].date,
          'YYYY-MM-DD HH:mm',
          this.props.timeZone,
          'hh:mm a'
        );
      }
    }
    return obj;
  };

  getDriverDeliveredTime = () => {
    let obj = {
      isAvailable: false,
      time: '',
    };
    if (this.props.otherOrderModal.otherOrderHistory) {
      let odSt = this.props.otherOrderModal.otherOrderHistory.filter((m) => {
        return m.orderStatus === '4';
      });
      if (odSt.length > 0) {
        obj.isAvailable = true;
        obj.time = convertToFormattedMomentDate(
          odSt[0].date,
          'YYYY-MM-DD HH:mm',
          this.props.timeZone,
          'hh:mm a'
        );
      }
    }
    return obj;
  };

  render() {
    return (
      <div>
        <Modal
          aria-labelledby='modal-label'
          className='modal fade'
          show={this.props.isDisplayOrder}
          onHide={this.props.closeOrderModal}
          bsSize='large'
          dialogClassName='delivery-dialog'
        >
          <div className='modal-content'>
            <div
              style={{ marginBottom: '0px' }}
              className='panel panel-default'
            >
              <div className='panel-body'>
                <div className='row mapMargin'>
                  <div className='content'>
                    <button
                      onClick={this.props.closeOrderModal}
                      type='button'
                      data-dismiss='modal'
                      aria-hidden='true'
                      className='close md-close buttonOnMap'
                    >
                      <span className='mdi mdi-close' />
                    </button>
                    <DriverTracker
                      store={this.props.layout.store}
                      driver={this.props.otherOrderModal.driver}
                      order={this.props.selectedOrder}
                      storeId={this.props.storeId}
                    />
                  </div>
                </div>
                <div className='row delPopUpMargin'>
                  <div className='col-md-8'>
                    <div className='order-status'>
                      <p className='delPopupTitle'>
                        <span className='text-warning'>
                          {this.props.selectedOrder &&
                            getOrderStatus(
                              this.props.selectedOrder.orderStatus,
                              '4'
                            )}
                        </span>
                        <span>
                          {this.props.selectedOrder &&
                            (this.props.selectedOrder.orderStatus === '3' ||
                              this.props.selectedOrder.orderStatus === '4') &&
                            ` to ${
                              this.props.selectedOrder.address
                                ? this.props.selectedOrder.address
                                : 'N/A'
                            }`}
                        </span>
                        <span>
                          {this.props.selectedOrder &&
                            this.props.selectedOrder.orderStatus === '2' &&
                            ` going to ${
                              this.props.selectedOrder.address
                                ? this.props.selectedOrder.address
                                : 'N/A'
                            }`}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    {this.props.otherOrderModal.driver && (
                      <div>
                        <img
                          src={Avatar}
                          alt='Avatar'
                          className='user-avatarg'
                        />
                        <span className='delPopUpUser'>
                          {`${this.props.otherOrderModal.driver.firstName} ${this.props.otherOrderModal.driver.lastName}`}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div>
                      {this.props.selectedOrder &&
                        this.props.selectedOrder.orderType !== '1' && (
                          <p className='delPopUpSubText'>
                            <span style={{ fontFamily: 'Roboto-Light' }}>
                              (Currently running
                              <span className='text-success'>
                                {this.props.storeSetting.orderSetup
                                  ? `${Number(
                                      this.props.storeSetting.orderSetup
                                        .deliveryTime
                                    )} minutes`
                                  : 'N/A'}
                              </span>
                              )
                            </span>
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='tab-container'>
                      <ul className='nav nav-tabs'>
                        {this.props.otherOrderModal.user && (
                          <li
                            onClick={() => this.changeTabIndex(1)}
                            className={this.state.openTab === 1 ? 'active' : ''}
                          >
                            <a hred='customer' data-toggle='tab'>
                              Customer
                            </a>
                          </li>
                        )}
                        <li
                          onClick={() => this.changeTabIndex(2)}
                          className={this.state.openTab === 2 ? 'active' : ''}
                        >
                          <a hred='history' data-toggle='tab'>
                            Status history
                          </a>
                        </li>
                      </ul>
                      <div className='tab-content'>
                        <div
                          className={
                            this.state.openTab === 0
                              ? 'tab-pane active cont'
                              : 'tab-pane cont'
                          }
                        >
                          <div className='tab-container'>
                            {this.props.selectedOrder &&
                              this.props.selectedOrder.menuItems && (
                                <div className='row'>
                                  <div className='col-md-7'>
                                    <div className='row detailHeader'>
                                      <p>Order</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        {this.props.otherOrderModal.user && (
                          <div
                            className={
                              this.state.openTab === 1
                                ? 'tab-pane active cont'
                                : 'tab-pane cont'
                            }
                          >
                            <div className='tab-container'>
                              <div className='row'>
                                <div className='col-md-6'>
                                  {this.props.otherOrderModal.user ? (
                                    <div className='row detailHeaderC'>
                                      <p>Customer</p>
                                      <div className='ingDelCustomer'>
                                        <p>{`${this.props.otherOrderModal.user.firstName} ${this.props.otherOrderModal.user.lastName}`}</p>
                                        <p>
                                          {
                                            this.props.otherOrderModal.user
                                              .mobileNumber
                                          }
                                        </p>
                                        <p>
                                          {
                                            this.props.otherOrderModal.user
                                              .email
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className='row detailHeaderC'>
                                      <p>Customer</p>
                                      <div className='ingDelCustomer'>
                                        <p>{`Not A Next Order Customer Yet`}</p>
                                      </div>
                                    </div>
                                  )}
                                  {this.props.otherOrderModal
                                    .deliveryAddresses &&
                                    this.props.otherOrderModal.deliveryAddresses
                                      .length > 0 && (
                                      <div className='row detailHeaderC'>
                                        <p>Delivery addresses used</p>
                                        <div className='ingDelCustomerAddr'>
                                          {this.props.otherOrderModal.deliveryAddresses.map(
                                            (address, index) => (
                                              <p key={index}>
                                                {address.address}
                                              </p>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <div className='col-md-6'>
                                  {this.props.otherOrderModal.user && (
                                    <div className='row detailHeaderC'>
                                      <p>Customer history</p>
                                      <div className='ingDelCustomer'>
                                        <p>{`Customer since ${
                                          this.props.selectedOrder &&
                                          this.props.otherOrderModal.user &&
                                          this.props.otherOrderModal.user.date
                                            ? convertToFormattedMomentDate(
                                                this.props.otherOrderModal.user
                                                  .date,
                                                'YYYY-MM-DD HH:mm',
                                                this.props.timeZone,
                                                'YYYY'
                                              )
                                            : 'N/A'
                                        }`}</p>
                                        {this.props.selectedOrder &&
                                        this.props.selectedOrder.orderType !==
                                          '1' ? (
                                          <p>{`${
                                            this.props.otherOrderModal.user
                                              ? this.ordinalSuffixOf(
                                                  this.props.otherOrderModal
                                                    .user.totalOrders
                                                )
                                              : ''
                                          } Order (${
                                            this.props.otherOrderModal.user
                                              ? this.ordinalSuffixOf(
                                                  this.props.otherOrderModal
                                                    .user.totalDeliveryOrders
                                                )
                                              : ''
                                          } Delivery)`}</p>
                                        ) : (
                                          <p>{`${
                                            this.props.otherOrderModal.user
                                              ? this.ordinalSuffixOf(
                                                  this.props.otherOrderModal
                                                    .user.totalOrders
                                                )
                                              : ''
                                          } Order (${
                                            this.props.otherOrderModal.user
                                              ? this.ordinalSuffixOf(
                                                  this.props.otherOrderModal
                                                    .user.totalPickupOrders
                                                )
                                              : ''
                                          } Pickup)`}</p>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className={
                            this.state.openTab === 2
                              ? 'tab-pane active cont'
                              : 'tab-pane cont'
                          }
                        >
                          <div className='tab-container'>
                            <div className='row'>
                              <div className='col-md-12'>
                                <div className='row detailHeader'>
                                  <p>Status history</p>
                                  <div className='ingDel'>
                                    {this.props.otherOrderModal
                                      .otherOrderHistory &&
                                      this.props.otherOrderModal
                                        .otherOrderHistory.length > 0 &&
                                      this.getDriverLeftStoreTime()
                                        .isAvailable && (
                                        <p>
                                          {`
                                                                                            ${
                                                                                              this
                                                                                                .props
                                                                                                .otherOrderModal
                                                                                                .driver
                                                                                                ? `${this.props.otherOrderModal.driver.firstName} ${this.props.otherOrderModal.driver.lastName}`
                                                                                                : ''
                                                                                            } left store: ${
                                            this.getDriverLeftStoreTime().time
                                          }
                                                                                        `}
                                        </p>
                                      )}
                                    {this.props.otherOrderModal
                                      .otherOrderHistory &&
                                      this.props.otherOrderModal
                                        .otherOrderHistory.length > 0 &&
                                      this.getDriverDeliveredTime()
                                        .isAvailable && (
                                        <p>
                                          {`
                                                                                            ${
                                                                                              this
                                                                                                .props
                                                                                                .otherOrderModal
                                                                                                .driver
                                                                                                ? `${this.props.otherOrderModal.driver.firstName} ${this.props.otherOrderModal.driver.lastName}`
                                                                                                : ''
                                                                                            } has delivered order: ${
                                            this.getDriverDeliveredTime().time
                                          }
                                                                                            `}
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

OtherOrderModal.propTypes = {
  isDisplayOrder: PropTypes.any,
  closeOrderModal: PropTypes.any,
  selectedOrder: PropTypes.any,
  timeZone: PropTypes.any,
  storeId: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    otherOrderModal: state.otherOrderModalReducer,
    storeSetting: state.storeSettingReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserHandler: (userId) => dispatch(getUser(userId)),
    getDriverHandler: (driverId) => dispatch(getDriver(driverId)),
    getDeliveryAddressesHandler: (storeId, userId) =>
      dispatch(getDeliveryAddresses(storeId, userId)),
    getOtherOrderHistoryHandler: (storeId, orderId) =>
      dispatch(getOtherOrderHistory(storeId, orderId)),
    getStoreSetupHandler: (storeSetup, storeId) =>
      dispatch(getStoreSetup(storeSetup, storeId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherOrderModal);
