import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchStore:
      return { ...state, store: payload.store };
    case ActionTypes.FetchActiveBilling:
      return { ...state, activeBilling: payload.activeBilling };
    case ActionTypes.FetchBillingCode:
      return { ...state, billingCode: payload.billingCode };
    case ActionTypes.FetchCreditCardProcessingFees:
      return {
        ...state,
        creditCardProcessingFees: payload.creditCardProcessingFees,
      };
    default:
      return state;
  }
};
