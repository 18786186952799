import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import storeSettingReducer from './containers/StoreSetting/reducer';
import shiftsReducer from './containers/Shifts/reducer';
import menuReducer from './containers/Menu/reducer';
import itemReducer from './containers/ItemStatistics/reducer';
import voucherReducer from './containers/Vouchers/reducer';
import specialReducer from './containers/Specials/reducer';
import suburbReducer from './containers/DeliveryAreas/reducer';
import driverReducer from './containers/Drivers/reducer';
import reservationReducer from './containers/CurrentReservations/reducer';
import specialFormReducer from './components/SpecialForm/reducer';
import suburbFormReducer from './components/SuburbForm/reducer';
import driverFormReducer from './components/DriverForm/reducer';
import productSetupReducer from './components/ProductSetup/reducer';
import orderSetupReducer from './components/OrderSetup/reducer';
import customerReducer from './containers/Customers/reducer';
import reviewReducer from './containers/Reviews/reducer';
import orderReducer from './containers/CurrentDeliveries/reducer';
import otherOrderReducer from './containers/CurrentOtherDeliveries/reducer';
import loginReducer from './containers/Login/reducer';
import closeDateReducer from './containers/CloseDates/reducer';
import deliveryStatisticsReducer from './containers/DeliveryStatistics/reducer';
import otherDeliveryStatisticsReducer from './containers/OtherDeliveryStatistics/reducer';
import pickupStatisticsReducer from './containers/PickupStatistics/reducer';
import dashboardReducer from './containers/Dashboard/reducer';
import layoutReducer from './containers/Layout/reducer';
import authCodeReducer from './containers/AuthenticationCode/reducer';
import reservationsStatisticReducer from './containers/ReservationStatistics/reducer';
import paymentReportsReducer from './containers/PaymentReports/reducer';
import realtimeOrdersReducer from './containers/RealtimeOrders/reducer';
import offersReducer from './containers/Offers/reducer';
import membershipsReducer from './containers/Memberships/reducer';
import orderModalReducer from './components/OrderModal/reducer';
import otherOrderModalReducer from './components/OtherOrderModal/reducer';
import reportModalReducer from './components/ReportModal/reducer';
import refundReducer from './containers/Refund/reducer';
import subscriptionReducer from './containers/Subscription/reducer';

const rootReducer = combineReducers({
  storeSettingReducer,
  shiftsReducer,
  menuReducer,
  itemReducer,
  voucherReducer,
  specialReducer,
  suburbReducer,
  driverReducer,
  reservationReducer,
  specialFormReducer,
  suburbFormReducer,
  driverFormReducer,
  productSetupReducer,
  orderSetupReducer,
  customerReducer,
  reviewReducer,
  orderReducer,
  loginReducer,
  closeDateReducer,
  deliveryStatisticsReducer,
  pickupStatisticsReducer,
  dashboardReducer,
  layoutReducer,
  authCodeReducer,
  reservationsStatisticReducer,
  paymentReportsReducer,
  otherOrderReducer,
  otherDeliveryStatisticsReducer,
  realtimeOrdersReducer,
  orderModalReducer,
  otherOrderModalReducer,
  reportModalReducer,
  refundReducer,
  offersReducer,
  membershipsReducer,
  subscriptionReducer,
  form: reduxFormReducer,
});

export default rootReducer;
