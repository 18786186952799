import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { auth, firestore } from '../../firebase';
import Pagination from 'react-js-pagination';
import Layout from '../../containers/Layout';
import WarningModal from '../../components/WarningModal';
import DriverForm from '../../components/DriverForm';
import Spinner from '../../components/Spinner';
import './index.css';
import { getDrivers, getDriverCount, changeDriverStatus } from './actions';

import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Drivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      showDriverWarning: false,
      isDisplayDriver: false,
      driverForEdit: {},
      isEditModeOn: false,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/delivery/drivers'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getDriversHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getDriverCountHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      drivers: newProps.driver.drivers,
      totalCount: newProps.driver.totalCount,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        drivers: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getDriversHandler(this.state.storeId, this.state.pagination);
      }
    );
  };

  closeWarnings = () => {
    this.setState({ showDriverWarning: false });
  };

  openDriverWarningModal = (driver) => {
    let warning = !driver.isActivated
      ? 'This will activate account. Click "Proceed" to continue.'
      : '`This will de-activate. Click "Proceed" to continue.`';
    this.setState({
      showDriverWarning: true,
      driverToBeDeleted: driver,
      warning,
    });
  };

  openDriverModal = (driver) => {
    this.setState({
      isError: false,
      isDisplayDriver: true,
      isEditModeOn: true,
      driverForEdit: driver,
    });
  };

  openDriverModalNew = () => {
    this.setState({
      isError: false,
      isDisplayDriver: true,
      isEditModeOn: false,
      driverForEdit: {},
    });
  };

  closeDriverModal = () => {
    this.setState({
      isError: false,
      isDisplayDriver: false,
      driverToBeDeleted: {},
      driverForEdit: {},
      showDriverWarning: false,
    });
  };

  deleteDriver = () => {
    this.props.changeDriverStatusHandler(
      this.state.driverToBeDeleted,
      this.state.timeZone
    );
    this.closeDriverModal();
  };

  render() {
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.drivers
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.drivers.length -
        1
      : 0;
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-xs-12 col-md-12'>
              <div className='panel panel-default panel-table '>
                <div className='panel-heading'>
                  View Drivers
                  <div className='fright'>
                    <button
                      onClick={(e) => this.openDriverModalNew({})}
                      className='btn btn-space btn-primary active'
                    >
                      Add Driver
                    </button>
                  </div>
                </div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Mobile</th>
                              <th>Status</th>
                              <th
                                className='nosort sorting_disabled'
                                rowSpan='1'
                                colSpan='1'
                                aria-label='&amp;nbsp;'
                              >
                                &nbsp;
                              </th>
                            </tr>
                          </thead>
                          {!this.state.drivers ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : this.state.drivers.length > 0 ? (
                            <tbody>
                              {this.state.drivers.map((driver, index) => (
                                <tr key={index}>
                                  <td className='user-avatar'>{`${driver.firstName} ${driver.lastName}`}</td>
                                  <td>{driver.mobileNumber}</td>
                                  <td>
                                    {driver.isActivated
                                      ? `Active`
                                      : 'De-Activated'}
                                  </td>
                                  <td className='text-right'>
                                    <Link
                                      className='btn btn-space btn-primary'
                                      to={`/restaurants/delivery/driver?restaurant=${this.state.storeId}&key=${driver.driverId}`}
                                    >
                                      View
                                    </Link>
                                    <button
                                      onClick={() =>
                                        this.openDriverModal(driver)
                                      }
                                      className='btn btn-space btn-primary'
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() =>
                                        this.openDriverWarningModal(driver)
                                      }
                                      className='btn btn-space btn-default'
                                    >
                                      {driver.isActivated
                                        ? `De-Activate`
                                        : 'Activate'}
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td>
                                  <p> No records to display</p>
                                </td>
                                <td />
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.state.totalCount ? this.state.totalCount : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isDisplayDriver && (
            <Modal
              aria-labelledby='modal-label'
              backdrop='static'
              keyboard={false}
              className='modal fade'
              show={this.state.isDisplayDriver}
              onHide={this.closeDriverModal}
              bsSize='sm'
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    onClick={this.closeDriverModal}
                    type='button'
                    data-dismiss='modal'
                    aria-hidden='true'
                    className='close md-close'
                  >
                    <span className='mdi mdi-close' />
                  </button>
                </div>
                <DriverForm
                  closeDriverModal={this.closeDriverModal}
                  totalCount={this.state.totalCount}
                  driverForEdit={this.state.driverForEdit}
                  isEditModeOn={this.state.isEditModeOn}
                  timeZone={this.state.timeZone}
                  storeId={this.state.storeId}
                  restaurantId={this.state.restaurantId}
                />
              </div>
            </Modal>
          )}
          {this.state.showDriverWarning && (
            <WarningModal
              procced={this.deleteDriver}
              closeModal={this.closeWarnings}
              message={this.state.warning}
            />
          )}
        </div>
      </Layout>
    );
  }
}

Drivers.propTypes = {};

function mapStateToProps(state) {
  return {
    driver: state.driverReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDriversHandler: (storeId, pagination) =>
      dispatch(getDrivers(storeId, pagination)),
    getDriverCountHandler: (storeId) => dispatch(getDriverCount(storeId)),
    changeDriverStatusHandler: (driver, timeZone) =>
      dispatch(changeDriverStatus(driver, timeZone)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Drivers);
