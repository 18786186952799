exports.getMobileNumber = (phone) => {
  try {
    if (
      phone === '09687565963' ||
      phone === '09016185805' ||
      phone === '09099334421' ||
      phone === '08160381688' ||
      phone === '09724334158' ||
      phone === '09924274295' ||
      phone === '09724334158' ||
      phone === '08460756530' ||
      phone === '09925426058'
    ) {
      let number = phone;
      if (number[0] === '0') number = `+91${number.substr(1)}`;
      else if (number[0] === '9' && number[1] === '1') number = `+${number}`;
      else if (number[0] === '+') number = `${number}`;
      else number = `+91${number.substr(0)}`;
      return number;
    } else if (
      phone === '9687565963' ||
      phone === '9016185805' ||
      phone === '9099334421' ||
      phone === '8160381688' ||
      phone === '9724334158' ||
      phone === '9924274295' ||
      phone === '9724334158' ||
      phone === '8460756530' ||
      phone === '9925426058'
    ) {
      let number = phone;
      if (number[0] === '0') number = `+91${number.substr(1)}`;
      else if (number[0] === '9' && number[1] === '1') number = `+${number}`;
      else if (number[0] === '+') number = `${number}`;
      else number = `+91${number.substr(0)}`;
      return number;
    } else {
      let number = phone;
      if (number[0] === '0') number = `+61${number.substr(1)}`;
      else if (number[0] === '6' && number[1] === '1') number = `+${number}`;
      else if (number[0] === '+') number = `${number}`;
      else number = `+61${number.substr(0)}`;
      return number;
    }
  } catch (error) {
    console.log(error);
  }
};

exports.getFormattedMobileNumber = (mobileNumber) => {
  try {
    let number = mobileNumber;
    if (number[0] === '0') number = `${number}`;
    else if (number[0] === '6' && number[1] === '1')
      number = `0${number.substr(2)}`;
    else if (number[0] === '9' && number[1] === '1')
      number = `0${number.substr(2)}`;
    else if (number[0] === '+') number = `0${number.substr(3)}`;
    else number = `0${number}`;
    return number;
  } catch (error) {
    console.log(error);
  }
};
