import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getMenuItemsCount(storeId, filterString) {
  return (dispatch) => {
    if (!filterString || filterString == null) {
      let ref = firestore
        .collection('MenuItems')
        .where('storeId', '==', storeId);
      ref.onSnapshot((querySnapshot) => {
        dispatch(menuItemsCountFetched(querySnapshot.docs.length));
      });
    } else {
      let ref = firestore
        .collection('MenuItems')
        .where('storeId', '==', storeId)
        .where('keywords', 'array-contains', filterString);
      ref.onSnapshot((querySnapshot) => {
        dispatch(menuItemsCountFetched(querySnapshot.docs.length));
      });
    }
  };
}

export function getMenuItems(storeId, pagination, sortObj, filterString) {
  return (dispatch) => {
    dispatch(menuItemsFetched(undefined));
    if (!filterString || filterString == null) {
      let currentRef = firestore
        .collection('MenuItems')
        .where('storeId', '==', storeId)
        .orderBy(sortObj.name, sortObj.dir);
      currentRef.get().then((documentSnapshots) => {
        let lastVisible =
          documentSnapshots.docs[
            (pagination.currentPage - 1) * pagination.pageSize - 1
          ];
        if (pagination.currentPage !== 1) {
          let nextRef = firestore
            .collection('MenuItems')
            .where('storeId', '==', storeId)
            .orderBy(sortObj.name, sortObj.dir)
            .startAfter(lastVisible)
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(menuItemsFetched(dataArray));
          });
        } else {
          let nextRef = firestore
            .collection('MenuItems')
            .where('storeId', '==', storeId)
            .orderBy(sortObj.name, sortObj.dir)
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(menuItemsFetched(dataArray));
          });
        }
      });
    } else {
      let currentRef = firestore
        .collection('MenuItems')
        .where('storeId', '==', storeId)
        .where('keywords', 'array-contains', filterString)
        .orderBy(sortObj.name, sortObj.dir);
      currentRef.get().then((documentSnapshots) => {
        let lastVisible =
          documentSnapshots.docs[
            (pagination.currentPage - 1) * pagination.pageSize - 1
          ];
        if (pagination.currentPage !== 1) {
          let nextRef = firestore
            .collection('MenuItems')
            .where('storeId', '==', storeId)
            .where('keywords', 'array-contains', filterString)
            .orderBy(sortObj.name, sortObj.dir)
            .startAfter(lastVisible)
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(menuItemsFetched(dataArray));
          });
        } else {
          let nextRef = firestore
            .collection('MenuItems')
            .where('storeId', '==', storeId)
            .where('keywords', 'array-contains', filterString)
            .orderBy(sortObj.name, sortObj.dir)
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(menuItemsFetched(dataArray));
          });
        }
      });
    }
  };
}

function menuItemsFetched(menuItems) {
  return {
    type: ActionTypes.FetchMenuItems,
    menuItems,
  };
}

function menuItemsCountFetched(totalCount) {
  return {
    type: ActionTypes.MenuItemsCountFetched,
    totalCount,
  };
}
