import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchTopCustomers:
      return {
        ...state,
        topCustomers: payload.topCustomers,
      };
    case ActionTypes.FetchCustomers:
      return {
        ...state,
        customers: payload.customers,
      };
    case ActionTypes.CustomerCountFetched:
      return {
        ...state,
        totalCount: payload.totalCount,
      };
    case ActionTypes.CustomerCountPickupFetched:
      return {
        ...state,
        totalCountPickupOnly: payload.totalCountPickupOnly,
      };
    case ActionTypes.CustomerCountDeliveryFetched:
      return {
        ...state,
        totalCountDeliveryOnly: payload.totalCountDeliveryOnly,
      };
    case ActionTypes.FetchUserOrders:
      return {
        ...state,
        userOrders: payload.userOrders,
      };
    case ActionTypes.FetchCustomerRetention:
      return {
        ...state,
        customerRetention: payload.customerRetention,
      };
    case ActionTypes.FetchSystemStartDate:
      return {
        ...state,
        yearList: payload.yearList,
      };
    default:
      return state;
  }
};
