import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../components/Spinner';
import Pagination from 'react-js-pagination';

class TopSuburbs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const start =
      (this.props.pagination.currentPage - 1) * this.props.pagination.pageSize +
      1;
    const end = this.props.suburbs
      ? (this.props.pagination.currentPage - 1) *
          this.props.pagination.pageSize +
        1 +
        this.props.suburbs.length -
        1
      : 0;
    return (
      <div className='col-md-6'>
        <div className='panel panel-default panel-table '>
          <div className='panel-heading'>Total Amounts Spent by Suburb</div>
          <div className='panel-body table-responsive'>
            <div
              id='table1_wrapper'
              className='dataTables_wrapper form-inline dt-bootstrap no-footer'
            >
              <div className='row be-datatable-body'>
                <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                  <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                    <thead>
                      <tr>
                        <th
                          className={this.props.sorting[0].class}
                          onClick={() => this.props.sortMe(0)}
                        >
                          Suburb
                        </th>
                        <th
                          className={this.props.sorting[1].class}
                          onClick={() => this.props.sortMe(1)}
                        >
                          Orders
                        </th>
                        <th
                          className={this.props.sorting[2].class}
                          onClick={() => this.props.sortMe(2)}
                        >
                          Total Amount
                        </th>
                        <th>Rank</th>
                      </tr>
                    </thead>
                    {!this.props.suburbs ? (
                      <tbody>
                        <tr style={{ height: '100px' }}>
                          <div
                            style={{
                              alignSelf: 'center',
                              left: '0px',
                              right: '0px',
                              position: 'absolute',
                              width: 'auto',
                              height: '100px',
                            }}
                            className='be-loading be-loading-active'
                          >
                            <Spinner />
                          </div>
                        </tr>
                      </tbody>
                    ) : this.props.suburbs.length > 0 ? (
                      <tbody className='no-border-x'>
                        {this.props.suburbs.map((item, index) => (
                          <tr key={index} className='gradeX odd' role='row'>
                            <td>{item.name}</td>
                            <td>
                              {item.totalOrdersSub
                                ? `${parseInt(item.totalOrdersSub, 10)} Order`
                                : 'N/A'}
                            </td>
                            <td>
                              {item.totalRevenueSub
                                ? `${
                                    this.props.currency
                                  }${item.totalRevenueSub.toFixed(2)}`
                                : 'N/A'}
                            </td>
                            <td>{start + index}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>
                            <p> No records to display</p>
                          </td>
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className='row be-datatable-footer'>
                <div className='col-md-5 col-sm-12'>
                  <div
                    style={{ float: 'left' }}
                    className='dataTables_info'
                    id='table1_info'
                    role='status'
                    aria-live='polite'
                  >
                    {`Showing ${start} to ${end} of ${
                      this.props.totalCount ? this.props.totalCount : 0
                    } entries`}
                  </div>
                </div>
                <div className='col-md-7 col-sm-12'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='table1_paginate'
                  >
                    {this.props.pagination && (
                      <nav style={{ float: 'right' }}>
                        <Pagination
                          activePage={this.props.pagination.currentPage}
                          itemsCountPerPage={this.props.pagination.pageSize}
                          totalItemsCount={this.props.totalCount}
                          pageRangeDisplayed={
                            this.props.pagination.pageRangeDisplayed
                          }
                          onChange={this.props.handlePageChange}
                          prevPageText='Previous'
                          nextPageText='Next'
                          itemClass='paginate_button'
                        />
                      </nav>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TopSuburbs.propTypes = {
  suburbs: PropTypes.any,
  pagination: PropTypes.any,
  totalCount: PropTypes.any,
  sortMe: PropTypes.any,
  handlePageChange: PropTypes.any,
};

export default TopSuburbs;
