import React, { Component } from 'react';
import PropTypes from 'prop-types';
import demoFancyMapStyles from '../../assets/mapfile.json';
const L = window.L;
let iconDeliveryAddress = L.icon({
  iconUrl: `https://s3-ap-southeast-2.amazonaws.com/lacampa/assets/marker.svg`,
  iconSize: [40, 50], // size of the icon
  iconAnchor: [0, 50],
});
let mapStyle = {
  minHeight: '220px !important',
};

class LeafletDeliveryAddress extends Component {
  constructor(props) {
    super(props);
    this.map = {};
    this.marker = [];
  }
  componentDidMount() {
    if (this.props && this.props.delivery) {
      L.mapbox.accessToken =
        'pk.eyJ1Ijoid2lsbG9yaWVudCIsImEiOiJjanZ6eDRqMXMwNTI4NDhvbmVsbjhhdmhzIn0.SXJqaMi8L08EwIHZf7pRIg';
      this.map = L.mapbox
        .map('map', null, {
          styles: mapStyle,
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          maxZoom: 16,
          scrollWheelZoom: false,
        })
        .setView(
          [Number(this.props.delivery.lat), Number(this.props.delivery.lng)],
          16
        )
        .addLayer(
          L.mapbox.styleLayer(
            'mapbox://styles/williamrichards/cjvc2w0fo6alc1fqxknvqjgoy',
            {
              tileSize: 256,
            }
          )
        );

      //Showing only delivery address when order is delivered
      this.delivery = L.marker(
        [this.props.delivery.lat, this.props.delivery.lng],
        { rotationOrigin: 'center', icon: iconDeliveryAddress }
      ).addTo(this.map);
    }
  }
  render() {
    return <div ref='map' id='map' />;
  }
}

LeafletDeliveryAddress.propTypes = {
  delivery: PropTypes.any,
  order: PropTypes.any,
};

export default LeafletDeliveryAddress;
