import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchUsers:
      return { ...state, users: payload.users };
    case ActionTypes.FetchDrivers:
      return { ...state, drivers: payload.drivers };
    case ActionTypes.FetchDriverCount:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.FetchDriverSelected:
      return { ...state, driver: payload.driver };
    case ActionTypes.FetchDriverStats:
      return { ...state, driverStats: payload.driverStats };
    case ActionTypes.FetchDriverStatsByDay:
      return { ...state, driverStatsByDay: payload.driverStatsByDay };
    case ActionTypes.FetchDeliveryHistory:
      return { ...state, deliveryHistory: payload.deliveryHistory };
    default:
      return state;
  }
};
