import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Layout from '../../containers/InStoreLayout';
import { getStore, getRefund } from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';
import './style.css';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

let logo =
  'https://s3-ap-southeast-2.amazonaws.com/lacampa/assets/next+order.svg';

class Refund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processingStatus: 0,
      isRefunded: false,
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      let values = queryString.parse(window.location.search);
      if (values.restaurant) {
        this.props.getStoreHandler(values.restaurant);
      }
      if (values.refundId) {
        this.props.getRefundHandler(values.refundId);
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  refundOrder = (status, refund) => {
    if (!this.state.processingStatus) {
      //console.log('Inside Refund order', order);
      // API call for refunding the amount starts
      this.setState({ processingStatus: status === true ? 1 : 2 }, () => {
        fetch(`${nextOrderConfig.cloudFunctionUrl}/refund`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${this.props.accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orderId: refund.orderId,
            storeId: refund.storeId,
            refundId: refund._id,
            timeZone: this.props.refund.store.timeZone,
            isApproved: status,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((_result) => {
            console.log('Result', _result);
            this.setState({ processingStatus: 0, isRefunded: true });
          });
      });
      // API call for refunding the amount ends
    }
  };

  render() {
    let currency =
      this.props.refund &&
      this.props.refund.store &&
      this.props.refund.store.currency
        ? this.props.refund.store.currency
        : '$';
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='splash-container refund'>
            <div className='panel panel-default panel-border-color panel-border-color-primary'>
              <div className='panel-heading'>
                <img
                  src={logo}
                  alt='logo'
                  width='280'
                  height='auto'
                  className='logo-img'
                />
                <span className='splash-description'>
                  Approve/Reject Refund Request.
                </span>
              </div>
              <div className='panel-body'>
                {this.props.refund &&
                  this.props.refund.refund &&
                  this.props.refund.store && (
                    <div className='col-md-12'>
                      <div className='text-center'>
                        <form
                          action='#'
                          style={{ borderRadius: '0px' }}
                          className='form-horizontal group-border-dashed'
                        >
                          <div className='flex-container'>
                            <div>
                              <label>Store:</label>
                            </div>
                            <div>
                              <span>{this.props.refund.store.name}</span>
                            </div>
                          </div>
                          <div className='flex-container'>
                            <div>
                              <label>Order #:</label>
                            </div>
                            <div>
                              <span>{this.props.refund.refund.orderId}</span>
                            </div>
                          </div>
                          <div className='flex-container'>
                            <div>
                              <label>Refund Amount:</label>
                            </div>
                            <div>
                              <span>{`${currency}${this.props.refund.refund.amount}`}</span>
                            </div>
                          </div>
                          {this.props.refund.refund.refundStatus === 0 ? (
                            <div className='flex-container-button'>
                              <div className='action-buttons'>
                                <button
                                  onClick={() =>
                                    this.refundOrder(
                                      false,
                                      this.props.refund.refund
                                    )
                                  }
                                  type='button'
                                  data-dismiss='modal'
                                  className='btn btn-cancel md-close'
                                  disabled={
                                    this.state.refunded ||
                                    this.state.processingStatus === 1 ||
                                    this.state.processingStatus === 2
                                  }
                                >
                                  {this.state.processingStatus === 2
                                    ? 'Declining...'
                                    : this.state.refunded
                                    ? `Declined`
                                    : `Decline`}
                                </button>
                              </div>
                              <div>
                                <button
                                  onClick={() =>
                                    this.refundOrder(
                                      true,
                                      this.props.refund.refund
                                    )
                                  }
                                  type='button'
                                  data-dismiss='modal'
                                  className='btn btn-primary'
                                  disabled={
                                    this.state.refunded ||
                                    this.state.processingStatus === 1 ||
                                    this.state.processingStatus === 2
                                  }
                                >
                                  {this.state.processingStatus === 1
                                    ? 'Refunding...'
                                    : this.state.refunded
                                    ? `Refunded`
                                    : `Refund`}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </form>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    refund: state.refundReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRefundHandler: (refundId) => dispatch(getRefund(refundId)),
    getStoreHandler: (storeId) => dispatch(getStore(storeId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Refund);
