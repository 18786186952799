import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getRefund(refundId) {
  return (dispatch) => {
    let nextRef = firestore.collection('StoreRefunds').doc(refundId);
    nextRef.onSnapshot((doc) => {
      if (doc.exists)
        dispatch(
          refundFetched({ _id: doc.id, isRefundExist: true, ...doc.data() })
        );
      else dispatch(refundFetched({ isRefundExist: false }));
    });
  };
}

export function getStore(storeId) {
  return (dispatch) => {
    let ref = firestore.collection('Stores').doc(storeId);
    ref.onSnapshot((doc) => {
      dispatch(storeFetched(doc.data()));
    });
  };
}

function refundFetched(refund) {
  return {
    type: ActionTypes.FetchRefund,
    refund,
  };
}

function storeFetched(store) {
  return {
    type: ActionTypes.FetchStore,
    store,
  };
}
