import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import { withRouter } from 'react-router';
import '../../assets/css/fonts.css';
import '../../assets/lib/material-design-icons/css/material-design-iconic-font.min.css';
import '../../assets/lib/bootstrap/dist/css/bootstrap.css';
import '../../assets/css/dataTables.bootstrap.css';
import '../../assets/css/style.css';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import Logo from '../../components/Logo';

import { dateRangeChanged } from './actions';
import { signOut } from '../../containers/Login/actions';
import { getStoreAuthCode } from '../../containers/AuthenticationCode/actions';
import { getStore, getRefundReasons } from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
      } else {
        firestore
          .collection('SystemUsers')
          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              let systemUser = querySnapshot.docs[0].data();
              var start = moment();
              var end = moment();
              this.setState(
                {
                  storeId: systemUser.storeId,
                  restaurantId: systemUser.restaurantId,
                  start,
                  end,
                  user: systemUser,
                },
                () => {
                  this.props.getStoreAuthCodeHandler(systemUser.storeId);
                  this.props.getStoreHandler(systemUser.storeId);
                  this.props.getRefundReasonsHandler();
                }
              );
            }
          });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  dateRangePicker = (start, end) => {
    this.setState({
      start,
      end,
    });
  };

  redirectToInstoreScreen = () => {
    if (
      this.props.authCode &&
      this.props.authCode.authCode &&
      this.props.authCode.authCode.authCode
    ) {
      let url = `${nextOrderConfig.tvAppBaseUrl}/restaurant/instore?restaurant=${this.state.storeId}&code=${this.props.authCode.authCode.authCode}`;
      window.open(url, '_blank');
    }
  };

  render() {
    return (
      <div className='be-wrapper be-fixed-sidebar'>
        <nav className='navbar navbar-default navbar-fixed-top be-top-header'>
          <div className='container-fluid'>
            <Logo store={this.props.layout.store && this.props.layout.store} />
            <Header
              user={this.state.user}
              signOutHandler={this.props.signOutHandler}
              storeId={this.state.storeId}
            />
          </div>
        </nav>
        <div className='page-title'>
          <span>{this.props.location.pathname.split('/')[3]}</span>
        </div>
        <Navigation
          user={this.state.user}
          redirectToInstoreScreen={this.redirectToInstoreScreen}
        />
        <div className='be-content'>{this.props.children}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    layout: state.layoutReducer,
    driver: state.driverReducer,
    order: state.orderReducer,
    notification: state.notificationReducer,
    review: state.reviewReducer,
    reservation: state.reservationReducer,
    authCode: state.authCodeReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStoreHandler: (storeId) => dispatch(getStore(storeId)),
    dateRangeChangedHandler: (start, end) =>
      dispatch(dateRangeChanged(start, end)),
    signOutHandler: () => dispatch(signOut()),
    getStoreAuthCodeHandler: (storeId) => dispatch(getStoreAuthCode(storeId)),
    getRefundReasonsHandler: () => dispatch(getRefundReasons()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
