import { firestore } from '../../firebase';
import ActionTypes from './constants';
import { nextOrderConfig } from '../../configurations/storeSetup';

export function getOrders(storeId, dateToday) {
  return (dispatch) => {
    let nextRef = firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateToday)
      .orderBy('dateTimestamp', 'desc');
    nextRef.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(ordersFetched(dataArray));
    });
  };
}

export function getOtherOrders(storeId, dateToday) {
  return (dispatch) => {
    let nextRef = firestore
      .collection('OtherDeliveries')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateToday)
      .orderBy('dateTimestamp', 'desc');
    nextRef.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(otherOrdersFetched(dataArray));
    });
  };
}

export function getLastDayOrders(storeId, dateYesterday, dateToday) {
  return (dispatch) => {
    let nextRef = firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateYesterday)
      .where('dateTimestamp', '<', dateToday)
      .orderBy('dateTimestamp', 'desc');
    nextRef.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(lastDayOrdersFetched(dataArray));
    });
  };
}

export function getOrderHistory(storeId, dateToday) {
  return (dispatch) => {
    let ref = firestore
      .collection('OrderStatusHistory')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateToday);
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedOrderHistory(dataArray));
    });
  };
}

export function getStoreStats(requestOb, accessToken) {
  return (dispatch) => {
    fetch(`${nextOrderConfig.cloudFunctionUrl}/getSalesStats`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestOb),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        dispatch(orderStoreStatsFetched(result));
      });
  };
}

export function getReviewStats(requestOb, accessToken) {
  return (dispatch) => {
    fetch(`${nextOrderConfig.cloudFunctionUrl}/getReviewStats`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestOb),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        dispatch(reviewStatsFetched(result));
      });
  };
}

export function getSalesStatsSuburb(requestOb, accessToken) {
  return (dispatch) => {
    fetch(`${nextOrderConfig.cloudFunctionUrl}/getSalesStatsSuburb`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestOb),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        dispatch(orderStoreStatsSuburbFetched(result));
      });
  };
}

export function getSalesStatsCategories(requestOb, accessToken) {
  return (dispatch) => {
    fetch(`${nextOrderConfig.cloudFunctionUrl}/getSalesStatsCategories`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestOb),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        dispatch(orderStoreStatsCategoriesFetched(result));
      });
  };
}

export function getResetStats() {
  return (dispatch) => {
    dispatch(resetStats());
  };
}

function resetStats() {
  return {
    type: ActionTypes.ResetStats,
  };
}

function orderStoreStatsFetched(storeOrderStats) {
  return {
    type: ActionTypes.FetchStoreStats,
    storeOrderStats,
  };
}

function orderStoreStatsSuburbFetched(storeOrderStatsSuburb) {
  return {
    type: ActionTypes.FetchStoreStatsSuburb,
    storeOrderStatsSuburb,
  };
}

function orderStoreStatsCategoriesFetched(storeOrderStatsCategories) {
  return {
    type: ActionTypes.FetchStoreStatsCategories,
    storeOrderStatsCategories,
  };
}

function reviewStatsFetched(reviewStats) {
  return {
    type: ActionTypes.FetchReviewStats,
    reviewStats,
  };
}

function ordersFetched(orders) {
  return {
    type: ActionTypes.FetchOrders,
    orders,
  };
}

function otherOrdersFetched(otherOrders) {
  return {
    type: ActionTypes.FetchOtherOrders,
    otherOrders,
  };
}

function lastDayOrdersFetched(lastDayOrders) {
  return {
    type: ActionTypes.FetchLastDayOrders,
    lastDayOrders,
  };
}

function fetchedOrderHistory(orderHistory) {
  return {
    type: ActionTypes.FetchOrderHistory,
    orderHistory,
  };
}
