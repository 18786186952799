import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchOtherOrders:
      return { ...state, currentOtherOrders: payload.currentOtherOrders };
    case ActionTypes.FetchCurrentOtherOrderCount:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.FetchOtherOrderHistory:
      return { ...state, otherOrderHistory: payload.otherOrderHistory };
    default:
      return state;
  }
};
