import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';
import validate from './validate';

const renderField = ({
  input,
  value,
  placeholder,
  className,
  type,
  meta: { touched, error },
}) => (
  <div className='form-group'>
    <div className='col-sm-10'>
      <input
        value={value}
        className={className}
        {...input}
        placeholder={placeholder}
        type={type}
      />
      {touched && error && (
        <ul className='parsley-errors-list filled' id='parsley-id-7'>
          <li className='parsley-type'>{error}</li>
        </ul>
      )}
    </div>
  </div>
);

class NameWizard extends Component {
  categoryChange = (e, categoryId) => {
    this.props.changeSelectedCategory(categoryId);
  };

  onSubmitHandler = (values) => {
    this.props.setItemName(values);
  };

  render() {
    const { handleSubmit } = this.props;
    const style14 = { float: 'right' };
    const style15 = { float: 'left' };
    return (
      <div>
        <Form
          onSubmit={handleSubmit(this.onSubmitHandler)}
          data-parsley-namespace='data-parsley-'
          data-parsley-validate=''
          noValidate=''
          className='form-horizontal group-border-dashed'
        >
          <div className='form-group'>
            <div className='col-sm-8' id='popupcategoryList'>
              {this.props.categories &&
                this.props.categories.map((category, index) => (
                  <div
                    key={index}
                    className='be-radio inline ml0'
                    style={{ minWidth: '200px', textOverflow: 'hide' }}
                  >
                    <input
                      defaultChecked={category.isSelected}
                      onClick={(e) => this.categoryChange(e, category._id)}
                      type='radio'
                      value={category._id}
                      name='catsel'
                      id={`rad1${index}`}
                    />
                    <label htmlFor={`rad1${index}`}>{category.catName}</label>
                  </div>
                ))}
            </div>
          </div>
          <Field
            name='name'
            component={renderField}
            type='text'
            placeholder='Item name'
            className='form-control'
          />
          <Field
            name='description'
            component={renderField}
            type='text'
            placeholder='Item Description'
            className='form-control'
          />
          <div className='form-group'>
            <button
              style={style15}
              onClick={this.props.closeItemModal}
              className='btn btn-default btn-space'
            >
              Close
            </button>
            <button
              type='submit'
              style={style14}
              className='btn btn-primary btn-space wizard-next'
            >
              Next Step
              <i className='icon mdi mdi-chevron-right' />
              <i className='icon mdi mdi-chevron-right' />
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

NameWizard.propTypes = {
  menuItem: PropTypes.any,
  categories: PropTypes.any,
  previousPage: PropTypes.any,
  setItemName: PropTypes.any,
  closeItemModal: PropTypes.any,
  changeSelectedCategory: PropTypes.any,
};

export default reduxForm({
  form: 'nameWizard', // <------ same form name
  validate,
  enableReinitialize: true,
})(NameWizard);
