import { firestore } from '../../firebase';
import Amplify, { Storage } from 'aws-amplify';
import ActionTypes from './constants';

Amplify.configure({
  Auth: {
    identityPoolId: 'ap-southeast-2:2c00a523-e1e5-4ef4-99b4-38bb9e85a37a', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: 'lacampa', //REQUIRED -  Amazon S3 bucket
    region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
  },
});

export function getOffersCount(storeId) {
  return (dispatch) => {
    let ref = firestore.collection('Offers').where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(offersCountFetched(querySnapshot.docs.length));
    });
  };
}

export function getOffers(storeId, pagination) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('Offers')
      .where('storeId', '==', storeId)
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('Offers')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(offersFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('Offers')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(offersFetched(dataArray));
        });
      }
    });
  };
}

export function addOffer(offerObj) {
  return () => {
    if (offerObj._id) {
      if (offerObj && offerObj.photo && offerObj.photo.name) {
        let obj = offerObj;
        Storage.put(offerObj._id, offerObj.photo).then(() => {
          Storage.get(offerObj._id).then((result) => {
            obj.urlS3 = result.split('?')[0];
            delete obj.photo;
            let updateObj = {};
            updateObj.name = obj.name;
            updateObj.type = obj.type;
            updateObj.rewardItems = obj.rewardItems;
            updateObj.isAnyOneItemAvailable = obj.isAnyOneItemAvailable;
            updateObj.urlS3 = obj.urlS3;
            if (obj.type === '1') {
              updateObj.categories = obj.categories;
              updateObj.activeCategoryKeys = obj.categories
                .filter((f) => f.isActive)
                .map((m) => m._id);
              updateObj.categoryDisplayName = obj.categoryDisplayName;
              updateObj.sizes = obj.sizes;
              updateObj.activeSizeKeys = obj.sizes
                .filter((f) => f.isActive)
                .map((m) => m._id);
              updateObj.quantity = obj.quantity;
              updateObj.menuItems = obj.menuItems;
              updateObj.activeMenuItemKeys = obj.menuItems
                .filter((f) => f.isActive)
                .map((m) => m._id);
            } else {
              updateObj.minOrderValue = obj.minOrderValue;
              updateObj.noOfOrders = obj.noOfOrders;
            }
            firestore
              .collection('Offers')
              .doc(obj._id)
              .update({
                ...updateObj,
              })
              .then(() => {});
          });
        });
      } else {
        let obj = offerObj;
        let updateObj = {};
        updateObj.name = obj.name;
        updateObj.type = obj.type;
        updateObj.rewardItems = obj.rewardItems;
        updateObj.isAnyOneItemAvailable = obj.isAnyOneItemAvailable;
        if (obj.type === '1') {
          updateObj.categories = obj.categories;
          updateObj.activeCategoryKeys = obj.categories
            .filter((f) => f.isActive)
            .map((m) => m._id);
          updateObj.categoryDisplayName = obj.categoryDisplayName;
          updateObj.sizes = obj.sizes;
          updateObj.activeSizeKeys = obj.sizes
            .filter((f) => f.isActive)
            .map((m) => m._id);
          updateObj.quantity = obj.quantity;
          updateObj.menuItems = obj.menuItems;
          updateObj.activeMenuItemKeys = obj.menuItems
            .filter((f) => f.isActive)
            .map((m) => m._id);
        } else {
          updateObj.minOrderValue = obj.minOrderValue;
          updateObj.noOfOrders = obj.noOfOrders;
        }
        firestore
          .collection('Offers')
          .doc(obj._id)
          .update({
            ...updateObj,
          })
          .then(() => {});
      }
    } else {
      let obj = offerObj;
      var ref = firestore.collection('Offers').doc();
      let offerId = ref.id;
      if (offerObj && offerObj.photo && offerObj.photo.name) {
        Storage.put(offerId, offerObj.photo).then(() => {
          Storage.get(offerId).then((result) => {
            obj.urlS3 = result.split('?')[0];
            delete obj.photo;
            delete obj._id;
            ref.set(obj).then(() => {});
          });
        });
      }
    }
  };
}

export function deleteOffer(offer, type) {
  return () => {
    if (type === 1) {
      firestore.collection('Offers').doc(offer._id).update({ isActive: false });
    } else if (type === 2) {
      firestore.collection('Offers').doc(offer._id).update({ isDeleted: true });
    }
  };
}

function offersFetched(offers) {
  return {
    type: ActionTypes.FetchOffers,
    offers,
  };
}

function offersCountFetched(totalCount) {
  return {
    type: ActionTypes.FetchOffersCount,
    totalCount,
  };
}
