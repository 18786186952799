const actionTypes = {
  FetchSuburbs: 'FETCH_SUBURB',
  FetchAllSuburbs: 'FETCH_ALL_SUBURB',
  FetchAllUnsupportedSuburbs: 'FETCH_ALL_UNSUPPORTED_SUBURB',
  SuburbAdded: 'SUBURB_ADDED',
  SuburbStatusChanged: 'SUBURB_STATUS_CHANGED',
  SuburbCountFetched: 'SUBURB_COUNT_FETCHED',
};

export default actionTypes;
