import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import NameWizard from '../NameWizard';
import PhotoWizard from '../PhotoWizard';
import SizesWizard from '../SizesWizard';
import IngredientWizard from '../IngredientWizard';
import ExtrasWizard from '../ExtrasWizard';
import PreviewWizard from '../PreviewWizard';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';

class MenuItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItem: {
        selectedSizes: [],
        selectedIngredients: [],
        selectedExtraIngredients: [],
      },
      page: 1,
    };
  }

  componentDidMount() {
    this.setState({
      categories: this.props.categories,
      selectedCategory: this.props.selectedCategory,
      ingredientCategories: this.props.ingredientCategories,
      ingredients: this.props.ingredients,
      menuItem: this.props.selectedMenuItem,
      sizes: this.props.sizes,
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      categories: props.categories,
      selectedCategory: props.selectedCategory,
      ingredientCategories: props.ingredientCategories,
      ingredients: props.ingredients,
      sizes: props.sizes,
    });
  }

  setItemName = (values) => {
    let { name, description } = values;
    let date = getCurrentFormattedMomentDate(
      this.props.timeZone,
      'YYYY-MM-DD HH:mm'
    );
    let dateTimestamp = getCurrentTimestamp();
    let menuItem = this.state.menuItem;
    menuItem.name = name;
    menuItem.description = description ? description : '';
    menuItem.date = date;
    menuItem.dateTimestamp = dateTimestamp;
    this.setState(
      {
        menuItem,
      },
      () => {
        this.nextPage();
      }
    );
  };

  setItemPhoto = (photo, isImageAnEmoji) => {
    this.setState(
      {
        menuItem: { photo, isImageAnEmoji, ...this.state.menuItem },
      },
      () => {
        this.nextPage();
      }
    );
  };

  setItemSelectedSizes = (selectedSizes) => {
    let menuItem = this.state.menuItem;
    menuItem.selectedSizes = [];
    selectedSizes.map((m) => {
      menuItem.selectedSizes.push(m);
    });
    this.setState(
      {
        menuItem,
      },
      () => {
        this.nextPage();
      }
    );
  };

  setSelectedIngredients = (selectedIngredients) => {
    if (selectedIngredients && selectedIngredients.length > 0) {
      if (
        this.state.menuItem &&
        this.state.menuItem.description &&
        this.state.menuItem.description.length > 0
      ) {
        this.setState(
          {
            menuItem: {
              ...this.state.menuItem,
              selectedIngredients,
            },
          },
          () => {
            this.nextPage();
          }
        );
      } else {
        // const descriptions = selectedIngredients
        //   .map(ing => ing.ingredientName)
        //   .join(', ')
        // descriptions = descriptions.substring(0,descriptions.lastIndexOf(', '))
        let desc = '';
        selectedIngredients.forEach((ingredient, index) => {
          if (selectedIngredients.length === 1) {
            desc += `${ingredient.ingredientName}`;
          } else if (selectedIngredients.length === 2) {
            if (index !== selectedIngredients.length - 1) {
              desc += `${ingredient.ingredientName} `;
            } else {
              desc += `and ${ingredient.ingredientName}`;
            }
          } else {
            if (
              index !== selectedIngredients.length - 1 &&
              index !== selectedIngredients.length - 2
            ) {
              desc += `${ingredient.ingredientName}, `;
            } else if (index === selectedIngredients.length - 2) {
              desc += `${ingredient.ingredientName} `;
            } else {
              desc += `and ${ingredient.ingredientName}`;
            }
          }
        });
        this.setState(
          {
            menuItem: {
              ...this.state.menuItem,
              description: desc,
              selectedIngredients,
            },
          },
          () => {
            this.nextPage();
          }
        );
      }
    } else {
      this.setState(
        {
          menuItem: {
            ...this.state.menuItem,
            selectedIngredients: [],
          },
        },
        () => {
          this.nextPage();
        }
      );
    }
  };

  setSelectedExtraIngredients = (selectedExtraIngredients) => {
    if (selectedExtraIngredients && selectedExtraIngredients.length > 0) {
      this.setState(
        {
          menuItem: {
            ...this.state.menuItem,
            selectedExtraIngredients,
          },
        },
        () => {
          this.nextPage();
        }
      );
    } else {
      this.setState(
        {
          menuItem: {
            ...this.state.menuItem,
            selectedExtraIngredients: [],
          },
        },
        () => {
          this.nextPage();
        }
      );
    }
  };

  nextPage = () => {
    this.setState({ page: this.state.page + 1 });
  };

  previousPage = () => {
    this.setState({ page: this.state.page - 1 });
  };

  closeItemModal = () => {
    this.setState({
      menuItem: {
        selectedSizes: [],
        selectedIngredients: [],
        selectedExtraIngredients: [],
      },
      page: 1,
    });
    this.props.closeItemModal();
  };

  addSize = (size) => {
    size.categoryId = this.state.selectedCategory;
    this.props.addSizeHandler(size);
  };

  addIngredientCategory = (ingredientCategory) => {
    ingredientCategory.categoryId = this.state.selectedCategory;
    this.props.addIngredientCategoryHandler(ingredientCategory);
  };

  addMenuItemHandler = () => {
    this.props.addMenuItemHandler(this.state.menuItem);
    this.props.closeItemModal();
  };

  getclassName = (number) => {
    if (number === this.state.page) return 'active';
    else if (number < this.state.page) return 'complete';
    else return '';
  };

  render() {
    const { page } = this.state;
    const style1 = { marginLeft: '0' };
    return (
      <Modal
        className='modal fade'
        backdrop='static'
        keyboard={false}
        show={this.props.showItemModal}
        onHide={this.closeItemModal}
        bsSize='large'
        aria-labelledby='contained-modal-title'
      >
        <div className='modal-header'>
          <button
            onClick={this.closeItemModal}
            type='button'
            data-dismiss='modal'
            aria-hidden='true'
            className='close'
          >
            <span className='mdi mdi-close' />
          </button>
        </div>
        <div className='col-md-12 fuelux'>
          <div className='block-wizard panel panel-default'>
            <div id='wizard1' className='wizard wizard-ux no-steps-container'>
              <ul className='steps' style={style1}>
                <li data-step='1' className={this.getclassName(1)}>
                  Name
                  <span className='chevron' />
                </li>
                <li data-step='2' className={this.getclassName(2)}>
                  Photo
                  <span className='chevron' />
                </li>
                <li data-step='3' className={this.getclassName(3)}>
                  Sizes
                  <span className='chevron' />
                </li>
                <li data-step='4' className={this.getclassName(4)}>
                  Ingredients
                  <span className='chevron' />
                </li>
                <li data-step='5' className={this.getclassName(5)}>
                  Extras
                  <span className='chevron' />
                </li>
                <li data-step='6' className={this.getclassName(6)}>
                  Preview
                  <span className='chevron' />
                </li>
              </ul>
              <div className='step-content'>
                {page === 1 && (
                  <div data-step='1' className='step-pane active'>
                    <NameWizard
                      initialValues={this.state.menuItem}
                      menuItem={this.state.menuItem}
                      categories={this.state.categories}
                      previousPage={this.previousPage}
                      setItemName={this.setItemName}
                      closeItemModal={this.closeItemModal}
                      changeSelectedCategory={this.props.changeSelectedCategory}
                    />
                  </div>
                )}
                {page === 2 && (
                  <div data-step='2' className='step-pane active'>
                    <PhotoWizard
                      previousPage={this.previousPage}
                      setItemPhoto={this.setItemPhoto}
                      nextPage={this.nextPage}
                      menuItem={this.state.menuItem}
                    />
                  </div>
                )}
                {page === 3 && (
                  <div data-step='3' className='step-pane active'>
                    <SizesWizard
                      previousPage={this.previousPage}
                      nextPage={this.nextPage}
                      menuItem={this.state.menuItem}
                      addSize={this.addSize}
                      setItemSelectedSizes={this.setItemSelectedSizes}
                      sizes={this.state.sizes}
                      selectedCategory={this.props.selectedCategory}
                      updateSelectedMenuItem={this.props.updateSelectedMenuItem}
                      timeZone={this.props.timeZone}
                      currency={this.props.currency}
                    />
                  </div>
                )}
                {page === 4 && (
                  <div data-step='4' className='step-pane active'>
                    <IngredientWizard
                      previousPage={this.previousPage}
                      nextPage={this.nextPage}
                      menuItem={this.state.menuItem}
                      ingredients={this.state.ingredients}
                      ingredientCategories={this.state.ingredientCategories}
                      deleteIngredientCategoryHandler={
                        this.props.deleteIngredientCategoryHandler
                      }
                      addIngredientCategoryHandler={this.addIngredientCategory}
                      addIngredientHandler={this.props.addIngredientHandler}
                      deleteIngredientHandler={
                        this.props.deleteIngredientHandler
                      }
                      setSelectedIngredients={this.setSelectedIngredients}
                      selectedCategory={this.props.selectedCategory}
                      updateSelectedMenuItem={this.props.updateSelectedMenuItem}
                      timeZone={this.props.timeZone}
                      updatePriceHandler={this.props.updatePriceHandler}
                      currency={this.props.currency}
                    />
                  </div>
                )}
                {page === 5 && (
                  <div data-step='5' className='step-pane active'>
                    <ExtrasWizard
                      previousPage={this.previousPage}
                      nextPage={this.nextPage}
                      menuItem={this.state.menuItem}
                      ingredients={this.state.ingredients}
                      ingredientCategories={this.state.ingredientCategories}
                      deleteIngredientCategoryHandler={
                        this.props.deleteIngredientCategoryHandler
                      }
                      addIngredientCategoryHandler={this.addIngredientCategory}
                      addIngredientHandler={this.props.addIngredientHandler}
                      deleteIngredientHandler={
                        this.props.deleteIngredientHandler
                      }
                      setSelectedExtraIngredients={
                        this.setSelectedExtraIngredients
                      }
                      selectedCategory={this.props.selectedCategory}
                      updateSelectedMenuItem={this.props.updateSelectedMenuItem}
                      timeZone={this.props.timeZone}
                      updatePriceHandler={this.props.updatePriceHandler}
                      currency={this.props.currency}
                    />
                  </div>
                )}
                {page === 6 && (
                  <div data-step='6' className='step-pane active'>
                    <PreviewWizard
                      previousPage={this.previousPage}
                      nextPage={this.nextPage}
                      menuItem={this.state.menuItem}
                      ingredients={this.state.ingredients}
                      ingredientCategories={this.state.ingredientCategories}
                      selectedCategory={this.props.selectedCategory}
                      addMenuItemHandler={this.addMenuItemHandler}
                      categories={this.state.categories}
                      timeZone={this.props.timeZone}
                      currency={this.props.currency}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

MenuItemModal.propTypes = {
  selectedCategory: PropTypes.any,
  ingredientCategories: PropTypes.any,
  categories: PropTypes.any,
  closeItemModal: PropTypes.any,
  showItemModal: PropTypes.any,
  addIngredientCategoryHandler: PropTypes.any,
  addIngredientHandler: PropTypes.any,
  deleteIngredientHandler: PropTypes.any,
  addSizeHandler: PropTypes.any,
  deleteIngredientCategoryHandler: PropTypes.any,
  addMenuItemHandler: PropTypes.any,
  selectedMenuItem: PropTypes.any,
  updateSelectedMenuItem: PropTypes.any,
  changeSelectedCategory: PropTypes.any,
};

export default MenuItemModal;
