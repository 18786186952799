import { firestore } from '../../firebase';
import Amplify, { Storage } from 'aws-amplify';
import ActionTypes from './constants';

Amplify.configure({
  Auth: {
    identityPoolId: 'ap-southeast-2:2c00a523-e1e5-4ef4-99b4-38bb9e85a37a', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: 'lacampa', //REQUIRED -  Amazon S3 bucket
    region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
  },
});

export function getCategories(storeId) {
  return (dispatch) => {
    firestore
      .collection('Categories')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ ...doc.data(), _id: doc.id });
        });
        dispatch(categoriesFetched(dataArray));
      });
  };
}

export function getItemSizesForCategory(storeId, categoryId) {
  return (dispatch) => {
    firestore
      .collection('ItemSizes')
      .where('storeId', '==', storeId)
      .where('categoryId', '==', categoryId)
      .get()
      .then((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(itemSizesFetched(dataArray));
      });
  };
}

export function editCategory(categoryId, newCategoryName) {
  return (dispatch) => {
    firestore
      .collection('Categories')
      .doc(categoryId)
      .update({ catName: newCategoryName })
      .then(() => {});
  };
}

export function updatePrice(ingredient, newPrice, newName) {
  return (dispatch) => {
    firestore
      .collection('Ingredients')
      .where('storeId', '==', ingredient.storeId)
      .where('ingredientName', '==', ingredient.ingredientName)
      .get()
      .then((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dataArray.forEach((item) => {
          firestore
            .collection('Ingredients')
            .doc(item._id)
            .update({ price: newPrice, ingredientName: newName })
            .then(() => {});
        });

        firestore
          .collection('Populars')
          .where('storeId', '==', ingredient.storeId)
          .get()
          .then((querySnapshot) => {
            var dataArrayPopular = [];
            querySnapshot.forEach((doc) => {
              dataArrayPopular.push({ ...doc.data(), _id: doc.id });
            });

            dataArrayPopular.forEach((popular) => {
              let extraIngredientsNew = [];
              popular.selectedExtraIngredients.forEach((extraIngredient) => {
                if (
                  extraIngredient.ingredientName === ingredient.ingredientName
                ) {
                  extraIngredient.price = newPrice;
                  extraIngredient.ingredientName = newName;
                }
                extraIngredientsNew.push(extraIngredient);
              });

              firestore
                .collection('Populars')
                .doc(popular._id)
                .update({
                  selectedExtraIngredients: extraIngredientsNew,
                })
                .then(() => {});
            });
          });
      });
  };
}

export function deleteCategory(obj) {
  return (dispatch) => {
    //Deleting sizes of category
    firestore
      .collection('ItemSizes')
      .where('categoryId', '==', obj._id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((d) => {
          firestore
            .collection('ItemSizes')
            .doc(d.id)
            .delete()
            .then(() => {});
        });
      });

    //Deleting ingredient categories
    firestore
      .collection('IngredientCategories')
      .where('categoryId', '==', obj._id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((d) => {
          firestore
            .collection('IngredientCategories')
            .doc(d.id)
            .delete()
            .then(() => {});
        });
      });

    //Deleting Ingredients of category
    firestore
      .collection('Ingredients')
      .where('categoryId', '==', obj._id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((d) => {
          firestore
            .collection('Ingredients')
            .doc(d.id)
            .delete()
            .then(() => {});
        });
      });

    //Delete menu items
    firestore
      .collection('MenuItems')
      .where('categoryId', '==', obj._id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((d) => {
          firestore
            .collection('MenuItems')
            .doc(d.id)
            .delete()
            .then(() => {});
        });
      });

    //Delete Category
    firestore
      .collection('Categories')
      .doc(obj._id)
      .delete()
      .then(() => {});
  };
}

export function addCategory(obj) {
  return (dispatch) => {
    firestore
      .collection('Categories')
      .add(obj)
      .then(() => {});
  };
}

export function updateCategory(obj) {
  return (dispatch) => {
    firestore
      .collection('Categories')
      .doc(obj._id)
      .update({ order: obj.order })
      .then(() => {});
  };
}

export function getItemSizes(storeId, categoryId) {
  return (dispatch) => {
    firestore
      .collection('ItemSizes')
      .where('storeId', '==', storeId)
      .where('categoryId', '==', categoryId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(sizesFetched(dataArray));
      });
  };
}

export function getIngredientCategories(storeId, categoryId) {
  return (dispatch) => {
    firestore
      .collection('IngredientCategories')
      .where('storeId', '==', storeId)
      .where('categoryId', '==', categoryId)
      .onSnapshot(
        (querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(ingredientCategoriesFetched(dataArray));
        },
        (error) => {
          console.log(error);
        }
      );
  };
}

export function addIngredientCategory(obj) {
  return (dispatch) => {
    firestore
      .collection('IngredientCategories')
      .add(obj)
      .then(() => {
        console.log('IngredientCategories successfully written!');
      });
  };
}

export function deleteIngredientCategory(obj) {
  return (dispatch) => {
    //Deleting Ingredients of category
    firestore
      .collection('Ingredients')
      .where('ingredientCategory', '==', obj._id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((d) => {
          firestore
            .collection('Ingredients')
            .doc(d.id)
            .delete()
            .then(() => {
              console.log('Ingredients successfully deleted!');
              firestore
                .collection('MenuItems')
                .where('categoryId', '==', obj.categoryId)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((d) => {
                    let menuItem = d.data();
                    if (menuItem.selectedIngredients) {
                      menuItem.selectedIngredients = menuItem.selectedIngredients.filter(
                        (s) => {
                          return s.ingredientCategory !== obj._id;
                        }
                      );
                    }
                    if (menuItem.selectedExtraIngredients) {
                      menuItem.selectedExtraIngredients = menuItem.selectedExtraIngredients.filter(
                        (s) => {
                          return s.ingredientCategory !== obj._id;
                        }
                      );
                    }
                    firestore
                      .collection('MenuItems')
                      .doc(d.id)
                      .update({
                        selectedIngredients: menuItem.selectedIngredients,
                        selectedExtraIngredients:
                          menuItem.selectedExtraIngredients,
                      })
                      .then(() => {
                        console.log('MenuItems FK updated successfully!');
                      });
                  });
                });
            });
        });
      });

    firestore
      .collection('IngredientCategories')
      .doc(obj._id)
      .delete()
      .then(() => {
        console.log('IngredientCategories successfully deleted!');
      });
  };
}

export function getIngredients(storeId, categoryId) {
  return (dispatch) => {
    firestore
      .collection('Ingredients')
      .where('storeId', '==', storeId)
      .where('categoryId', '==', categoryId)
      .onSnapshot(
        (querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(ingredientsFetched(dataArray));
        },
        (error) => {
          console.log(error);
        }
      );
  };
}

export function addIngredient(obj) {
  return (dispatch) => {
    obj.totalOrders = 0;
    firestore
      .collection('Ingredients')
      .add(obj)
      .then(() => {
        console.log('IngredientCategories successfully written!');
      });
  };
}

export function deleteIngredient(obj) {
  return (dispatch) => {
    firestore
      .collection('Ingredients')
      .doc(obj._id)
      .delete()
      .then(() => {
        console.log('Ingredient successfully deleted!');
      });
    firestore
      .collection('MenuItems')
      .where('categoryId', '==', obj.categoryId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((d) => {
          let menuItem = d.data();
          if (menuItem.selectedIngredients) {
            menuItem.selectedIngredients = menuItem.selectedIngredients.filter(
              (s) => {
                return obj._id !== s._id;
              }
            );
          }
          if (menuItem.selectedExtraIngredients) {
            menuItem.selectedExtraIngredients = menuItem.selectedExtraIngredients.filter(
              (s) => {
                return obj._id !== s._id;
              }
            );
          }
          firestore
            .collection('MenuItems')
            .doc(d.id)
            .update({
              selectedIngredients: menuItem.selectedIngredients,
              selectedExtraIngredients: menuItem.selectedExtraIngredients,
            })
            .then(() => {
              console.log('MenuItems FK updated successfully!');
            });
        });
      });
  };
}

export function getSizes(storeId) {
  return (dispatch) => {
    firestore
      .collection('ItemSizes')
      .where('storeId', '==', storeId)
      .onSnapshot(
        (querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(allSizesFetched(dataArray));
        },
        (error) => {
          console.log(error);
        }
      );
  };
}

export function addSize(obj) {
  return (_dispatch) => {
    firestore
      .collection('ItemSizes')
      .add(obj)
      .then(() => {
        console.log('ItemSizes successfully written!');
      });
  };
}

export function getMenuItems(storeId) {
  return (dispatch) => {
    firestore
      .collection('MenuItems')
      .where('storeId', '==', storeId)
      .onSnapshot(
        (querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(menuItemsFetched(dataArray));
        },
        (error) => {
          console.log(error);
        }
      );
  };
}

export function addMenuItem(obj) {
  if (obj._id) {
    if (obj.name) {
      let keywords = [];
      if (obj.name) {
        let objString = obj.name.toLowerCase();
        let arr = objString.split(' ');
        let outcome = '';
        [...objString].forEach((c) => {
          outcome = outcome + c;
          keywords.push(outcome);
        });
        if (arr.length > 0) {
          arr.forEach((s, i) => {
            if (i !== 0) {
              let outcome1 = '';
              [...s].forEach((c) => {
                outcome1 = outcome1 + c;
                keywords.push(outcome1);
              });
            }
          });
        }
      }
      obj.keywords = keywords;
    }
    return (dispatch) => {
      if (obj && obj.photo && obj.photo.name) {
        Storage.put(obj._id, obj.photo).then(() => {
          Storage.get(obj._id).then((result) => {
            obj.urlS3 = result.split('?')[0];
            if (obj.photo) delete obj.photo;
            firestore
              .collection('MenuItems')
              .doc(obj._id)
              .update({
                keywords: obj.keywords,
                categoryId: obj.categoryId,
                name: obj.name,
                description: obj.description,
                selectedExtraIngredients: obj.selectedExtraIngredients,
                selectedIngredients: obj.selectedIngredients,
                selectedSizes: obj.selectedSizes,
                urlS3: obj.urlS3,
              })
              .then(() => {
                console.log('MenuItem successfully updated!');
              });
          });
        });
      } else {
        firestore
          .collection('MenuItems')
          .doc(obj._id)
          .update({
            keywords: obj.keywords,
            categoryId: obj.categoryId,
            name: obj.name,
            description: obj.description,
            selectedExtraIngredients: obj.selectedExtraIngredients,
            selectedIngredients: obj.selectedIngredients,
            selectedSizes: obj.selectedSizes,
          })
          .then(() => {
            console.log('MenuItem successfully updated!');
          });
      }
    };
  } else {
    return (dispatch) => {
      let photo = obj.photo;
      delete obj.photo;
      obj.totalOrders = 0;
      obj.totalRevenue = 0;
      obj.pickupRevenue = 0;
      obj.totalPickupOrders = 0;
      obj.deliveryRevenue = 0;
      obj.totalDeliveryOrders = 0;
      obj.agregateSales = 0;
      obj.day1 = 0;
      obj.day2 = 0;
      obj.day3 = 0;
      obj.day4 = 0;
      obj.day5 = 0;
      obj.day6 = 0;
      obj.day7 = 0;
      if (obj.name) {
        let keywords = [];
        if (obj.name) {
          let objString = obj.name.toLowerCase();
          let arr = objString.split(' ');
          let outcome = '';
          [...objString].forEach((c) => {
            outcome = outcome + c;
            keywords.push(outcome);
          });
          if (arr.length > 0) {
            arr.forEach((s, i) => {
              if (i !== 0) {
                let outcome1 = '';
                [...s].forEach((c) => {
                  outcome1 = outcome1 + c;
                  keywords.push(outcome1);
                });
              }
            });
          }
        }
        obj.keywords = keywords;
      }
      firestore
        .collection('MenuItems')
        .add(obj)
        .then((doc) => {
          Storage.put(doc.id, photo).then(() => {
            Storage.get(doc.id).then((result) => {
              obj.urlS3 = result.split('?')[0];
              if (obj.photo) delete obj.photo;
              firestore
                .collection('MenuItems')
                .doc(doc.id)
                .update({ urlS3: obj.urlS3 })
                .then(() => {
                  console.log('MenuItem successfully added!');
                });
            });
          });
        });
    };
  }
}

export function deleteMenuItem(obj) {
  return (dispatch) => {
    firestore
      .collection('MenuItems')
      .doc(obj._id)
      .delete()
      .then(() => {
        console.log('MenuItem successfully deleted!');
      });
  };
}

function categoriesFetched(categories) {
  return {
    type: ActionTypes.FetchCategories,
    categories,
  };
}

function itemSizesFetched(categories) {
  return {
    type: ActionTypes.FetchItemSizes,
    categories,
  };
}

function allSizesFetched(allSizes) {
  return {
    type: ActionTypes.SizesFetched,
    allSizes,
  };
}

function sizesFetched(sizes) {
  return {
    type: ActionTypes.FetchedSizes,
    sizes,
  };
}

function ingredientCategoriesFetched(ingredientCategories) {
  return {
    type: ActionTypes.FetchedIngredientCategories,
    ingredientCategories,
  };
}

function ingredientsFetched(ingredients) {
  return {
    type: ActionTypes.FetchedIngredients,
    ingredients,
  };
}

function menuItemsFetched(menuItems) {
  return {
    type: ActionTypes.FetchMenuItems,
    menuItems,
  };
}
