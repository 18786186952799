import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchSpecials:
      return { ...state, specials: payload.specials };
    case ActionTypes.FetchAllSpecials:
      return { ...state, allSpecials: payload.allSpecials };
    case ActionTypes.SpecialsCountFetched:
      return { ...state, totalCount: payload.totalCount };
    default:
      return state;
  }
};
