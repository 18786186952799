import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getOtherOrdersCount(storeId, dateToday) {
  return (dispatch) => {
    let ref = firestore
      .collection('OtherDeliveries')
      .where('storeId', '==', storeId)
      .where('orderType', '==', '3')
      .where('dateTimestamp', '>=', dateToday);
    ref.onSnapshot((querySnapshot) => {
      dispatch(fetchedCurrentOtherOrdersCount(querySnapshot.docs.length));
    });
  };
}

export function getOtherOrders(storeId, pagination, dateToday) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('OtherDeliveries')
      .where('storeId', '==', storeId)
      .where('orderType', '==', '3')
      .where('dateTimestamp', '>=', dateToday)
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('OtherDeliveries')
          .where('storeId', '==', storeId)
          .where('orderType', '==', '3')
          .where('dateTimestamp', '>=', dateToday)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(otherOrdersFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('OtherDeliveries')
          .where('storeId', '==', storeId)
          .where('orderType', '==', '3')
          .where('dateTimestamp', '>=', dateToday)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(otherOrdersFetched(dataArray));
        });
      }
    });
  };
}

export function getOtherOrderHistory(storeId, otherDeliveryId) {
  return (dispatch) => {
    let ref = firestore
      .collection('OtherDeliverieStatusHistory')
      .where('storeId', '==', storeId)
      .where('otherDeliveryId', '==', otherDeliveryId);
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedOtherOrderHistory(dataArray));
    });
  };
}

function fetchedCurrentOtherOrdersCount(totalCount) {
  return {
    type: ActionTypes.FetchCurrentOtherOrderCount,
    totalCount,
  };
}

function otherOrdersFetched(currentOtherOrders) {
  return {
    type: ActionTypes.FetchOtherOrders,
    currentOtherOrders,
  };
}

function fetchedOtherOrderHistory(otherOrderHistory) {
  return {
    type: ActionTypes.FetchOtherOrderHistory,
    otherOrderHistory,
  };
}
