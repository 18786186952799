import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import Suburbs from '../../components/Suburbs';
import {
  addSuburb,
  getSuburbs,
  getSuburbCount,
  deleteSuburb,
  getAllUnsupportedSuburbs,
} from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class DeliveryAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
      sorting: [
        {
          id: 0,
          name: 'name',
          dir: 'asc',
          class: 'sorting_asc',
          isSorted: true,
          disabled: false,
        },
        {
          id: 1,
          name: 'past24Hours',
          dir: 'both',
          class: 'sorting',
          isSorted: false,
          disabled: true,
        },
        {
          id: 2,
          name: 'totalOrdersSub',
          dir: 'both',
          class: 'sorting',
          isSorted: false,
          disabled: false,
        },
        {
          id: 3,
          name: 'averageOrderSpent',
          dir: 'both',
          class: 'sorting',
          isSorted: false,
          disabled: true,
        },
        {
          id: 4,
          name: 'averageDeliveryTime',
          dir: 'both',
          class: 'sorting',
          isSorted: false,
          disabled: true,
        },
        {
          id: 5,
          name: 'deliveryCost',
          dir: 'both',
          class: 'sorting',
          isSorted: false,
          disabled: true,
        },
        {
          id: 7,
          name: 'minOrderValue',
          dir: 'both',
          class: 'sorting',
          isSorted: false,
          disabled: true,
        },
        {
          id: 6,
          name: 'popularity',
          dir: 'both',
          class: 'sorting',
          isSorted: false,
          disabled: false,
        },
      ],
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/delivery/delivery-areas'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getSuburbsHandler(
                      doc.data().storeId,
                      this.state.pagination,
                      this.state.sorting[0]
                    );
                    this.props.getSuburbCountHandler(doc.data().storeId);
                    this.props.getUnsupportedSuburbsHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      suburbs: props.suburb.suburbs,
      totalCount: props.suburb.totalCount,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  addSuburbHandler = (suburb) => {
    suburb.storeId = this.state.storeId;
    suburb.restaurantId = this.state.restaurantId;
    this.props.addSuburbHandler(suburb);
  };

  deleteSuburbHandler = (suburb) => {
    this.props.deleteSuburbHandler(suburb);
  };

  handlePageChange = (e) => {
    this.setState(
      {
        suburbs: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        let sortC = this.state.sorting.filter((m) => {
          return m.isSorted === true;
        });
        if (sortC.length > 0) {
          this.props.getSuburbsHandler(
            this.state.storeId,
            this.state.pagination,
            sortC[0]
          );
        }
      }
    );
  };

  sortMe = (i) => {
    this.state.sorting.map((s) => {
      if (s.id === i && !s.disabled) {
        if (s.dir === 'both') {
          s.dir = 'desc';
          s.class = 'sorting_desc';
          s.isSorted = true;
        } else if (s.dir === 'asc') {
          s.dir = 'desc';
          s.class = 'sorting_desc';
          s.isSorted = true;
        } else if (s.dir === 'desc') {
          s.dir = 'asc';
          s.class = 'sorting_asc';
          s.isSorted = true;
        }
      } else {
        s.dir = 'both';
        s.class = 'sorting';
        s.isSorted = false;
      }
      return s;
    });
    let sortC = this.state.sorting.filter((m) => {
      return m.isSorted === true;
    });
    if (sortC.length > 0) {
      this.setState(
        {
          sorting: this.state.sorting,
          suburbs: undefined,
        },
        () => {
          this.props.getSuburbsHandler(
            this.state.storeId,
            this.state.pagination,
            sortC[0]
          );
        }
      );
    }
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <Suburbs
            suburbs={this.state.suburbs}
            totalCount={this.state.totalCount}
            pagination={this.state.pagination}
            deleteSuburbHandler={this.deleteSuburbHandler}
            addSuburbHandler={this.addSuburbHandler}
            handlePageChange={this.handlePageChange}
            sortMe={this.sortMe}
            sorting={this.state.sorting}
            timeZone={this.state.timeZone}
            unSupportedSuburbs={this.props.suburb.unSupportedSuburbs}
            currency={currency}
          />
        </div>
      </Layout>
    );
  }
}

DeliveryAreas.propTypes = {};

function mapStateToProps(state) {
  return {
    suburb: state.suburbReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addSuburbHandler: (suburb) => dispatch(addSuburb(suburb)),
    getSuburbsHandler: (storeId, pagination, sortObj) =>
      dispatch(getSuburbs(storeId, pagination, sortObj)),
    deleteSuburbHandler: (suburb) => dispatch(deleteSuburb(suburb)),
    getSuburbCountHandler: (storeId) => dispatch(getSuburbCount(storeId)),
    getUnsupportedSuburbsHandler: (storeId) =>
      dispatch(getAllUnsupportedSuburbs(storeId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryAreas);
