import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import Pagination from 'react-js-pagination';
import Spinner from '../../components/Spinner';
import { nextOrderConfig } from '../../configurations/storeSetup';
import {
  getStoreAuthCode,
  getActiveDevices,
  getActiveDeviceCount,
  updateDeviceInfo,
  resetPageData,
} from './actions';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class AuthenticationCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/auth-code'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getStoreAuthCodeHandler(doc.data().storeId);
                    this.props.getActiveDeviceCountHandler(doc.data().storeId);
                    this.props.getActiveDevicesHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  componentWillUnmount() {
    this.props.resetPageDataHandler();
  }

  handlePageChange = (e) => {
    this.setState(
      {
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getActiveDevicesHandler(
          this.state.storeId,
          this.state.pagination
        );
      }
    );
  };

  getPassCode = (authCodeId) => {
    let authCode = `${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`;
    if (authCode.length !== 4)
      authCode = `${Math.floor(Math.random() * (999 - 100 + 1) + 100)}`;
    firestore
      .collection('StoreAuthCodes')
      .doc(authCodeId)
      .update({
        passCode: authCode,
      })
      .then(() => {})
      .catch(() => {});
  };

  render() {
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end =
      this.state.authCode && this.state.authCode.activeDevices
        ? (this.state.pagination.currentPage - 1) *
            this.state.pagination.pageSize +
          1 +
          this.state.authCode.activeDevices.length -
          1
        : 0;

    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-xs-12 col-md-6 col-lg-6'>
              <div className='widget widget-tile'>
                <div className='data-info'>
                  <div className='desc'>Authentication Code</div>
                  <div className='value'>
                    <div
                      role='alert'
                      className='alert alert-success alert-dismissible'
                    >
                      {this.props.authCode.authCode &&
                      this.props.authCode.authCode.authCode
                        ? this.props.authCode.authCode.authCode
                        : 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xs-12 col-md-3 col-lg-3'>
              <div className='widget widget-tile'>
                <div className='data-info'>
                  <div className='desc'>Pass Code</div>
                  <div className='value'>
                    <div
                      role='alert'
                      className='alert alert-success alert-dismissible'
                    >
                      {this.props.authCode.authCode &&
                      this.props.authCode.authCode.passCode
                        ? this.props.authCode.authCode.passCode
                        : 'N/A'}
                      <span style={{ margin: '10px' }}>
                        <button
                          onClick={() =>
                            this.getPassCode(this.props.authCode.authCode._id)
                          }
                          className='btn btn-space btn-default'
                        >
                          Change
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-xs-12 col-md-12'>
              <div className='panel panel-default panel-table '>
                <div className='panel-heading'>Active Devices </div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                          <thead>
                            <tr>
                              <th>IP Address</th>
                              <th>Browser </th>
                              <th>OS</th>
                              <th>Last Login</th>
                              <th />
                            </tr>
                          </thead>
                          {!this.props.authCode.activeDevices ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : this.props.authCode.activeDevices.length > 0 ? (
                            <tbody>
                              {this.props.authCode.activeDevices &&
                                this.props.authCode.activeDevices.map(
                                  (d, index) => (
                                    <tr key={index}>
                                      <td>
                                        {d.deviceInfo.publicIp
                                          ? d.deviceInfo.publicIp
                                          : ''}
                                      </td>
                                      <td>
                                        {d.deviceInfo.browserInfo
                                          ? d.deviceInfo.browserInfo
                                          : ''}
                                      </td>
                                      <td>
                                        {d.deviceInfo.osInfo
                                          ? d.deviceInfo.osInfo.os +
                                            ' ' +
                                            d.deviceInfo.osInfo.osVersion
                                          : ''}
                                      </td>
                                      <td>
                                        {d.deviceInfo.loginTimestamp
                                          ? moment(d.deviceInfo.loginTimestamp)
                                              .tz('Australia/Melbourne')
                                              .format('YYYY-MM-DD HH:mm')
                                          : ''}
                                      </td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            this.props.updateDeviceInfoHandler(
                                              d._id
                                            )
                                          }
                                          className='btn btn-space btn-default'
                                        >
                                          Logout
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td>
                                  <p> No records to display</p>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.props.authCode &&
                            this.props.authCode.totalCount
                              ? this.props.authCode.totalCount
                              : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={
                                  this.props.authCode &&
                                  this.props.authCode.totalCount
                                    ? this.props.authCode.totalCount
                                    : 0
                                }
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

AuthenticationCode.propTypes = {};

function mapStateToProps(state) {
  return {
    authCode: state.authCodeReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStoreAuthCodeHandler: (storeId) => dispatch(getStoreAuthCode(storeId)),
    getActiveDeviceCountHandler: (storeId) =>
      dispatch(getActiveDeviceCount(storeId)),
    getActiveDevicesHandler: (storeId, pagination) =>
      dispatch(getActiveDevices(storeId, pagination)),
    updateDeviceInfoHandler: (deviceId) => dispatch(updateDeviceInfo(deviceId)),
    resetPageDataHandler: () => dispatch(resetPageData()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationCode);
