import { firestore } from '../../firebase';
import Amplify, { Storage } from 'aws-amplify';
import ActionTypes from './constants';

Amplify.configure({
  Auth: {
    identityPoolId: 'ap-southeast-2:2c00a523-e1e5-4ef4-99b4-38bb9e85a37a', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: 'lacampa', //REQUIRED -  Amazon S3 bucket
    region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
  },
});

export function getAllSpecials(storeId) {
  return (dispatch) => {
    firestore
      .collection('Specials')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        dispatch(allSpecialsFetched(dataArray));
      });
  };
}

export function getSpecialsCount(storeId) {
  return (dispatch) => {
    let ref = firestore.collection('Specials').where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(specialsCountFetched(querySnapshot.docs.length));
    });
  };
}

export function getSpecials(storeId, pagination) {
  return (dispatch) => {
    dispatch(specialsFetched(undefined));
    let currentRef = firestore
      .collection('Specials')
      .where('storeId', '==', storeId)
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('Specials')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(specialsFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('Specials')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(specialsFetched(dataArray));
        });
      }
    });
  };
}

export function addSpecial(special) {
  if (special._id) {
    return (dispatch) => {
      if (special && special.photo && special.photo.name) {
        let obj = special;
        Storage.put(special._id, special.photo).then(() => {
          Storage.get(special._id).then((result) => {
            obj.urlS3 = result.split('?')[0];
            delete obj.photo;
            firestore
              .collection('Specials')
              .doc(obj._id)
              .update({
                Friday: obj.Friday,
                Monday: obj.Monday,
                Saturday: obj.Saturday,
                Sunday: obj.Sunday,
                Thursday: obj.Thursday,
                Tuesday: obj.Tuesday,
                Wednesday: obj.Wednesday,
                cost: obj.cost,
                description: obj.description,
                isAvailableDelivery: obj.isAvailablePickup,
                isAvailablePickup: obj.isAvailablePickup,
                isAvailablePublicHoliday: obj.isAvailablePublicHoliday,
                isNameCanBeUsedInFrame: obj.isNameCanBeUsedInFrame,
                name: obj.name,
                displayName: obj.displayName,
                selectedCategories: obj.selectedCategories,
                urlS3: obj.urlS3,
                isAvailableFullDay: obj.isAvailableFullDay,
                startTime: obj.startTime,
                endTime: obj.endTime,
                startTimeDisplay: obj.startTimeDisplay,
                endTimeDisplay: obj.endTimeDisplay,
              })
              .then(() => {});
          });
        });
      } else {
        let obj = special;
        delete obj.photo;
        firestore
          .collection('Specials')
          .doc(obj._id)
          .update({
            Friday: obj.Friday,
            Monday: obj.Monday,
            Saturday: obj.Saturday,
            Sunday: obj.Sunday,
            Thursday: obj.Thursday,
            Tuesday: obj.Tuesday,
            Wednesday: obj.Wednesday,
            cost: obj.cost,
            description: obj.description,
            isAvailableDelivery: obj.isAvailableDelivery,
            isAvailablePickup: obj.isAvailablePickup,
            isAvailablePublicHoliday: obj.isAvailablePublicHoliday,
            isNameCanBeUsedInFrame: obj.isNameCanBeUsedInFrame,
            name: obj.name,
            displayName: obj.displayName,
            selectedCategories: obj.selectedCategories,
            isAvailableFullDay: obj.isAvailableFullDay,
            startTime: obj.startTime,
            endTime: obj.endTime,
            startTimeDisplay: obj.startTimeDisplay,
            endTimeDisplay: obj.endTimeDisplay,
          })
          .then(() => {});
      }
    };
  } else {
    return (dispatch) => {
      let obj = special;
      var newSpecialRef = firestore.collection('Specials').doc();
      let spId = newSpecialRef.id;
      if (special && special.photo && special.photo.name) {
        Storage.put(spId, special.photo).then(() => {
          Storage.get(spId).then((result) => {
            obj.urlS3 = result.split('?')[0];
            delete obj.photo;
            delete obj._id;
            newSpecialRef.set(obj).then(() => {});
          });
        });
      }
    };
  }
}

export function deleteSpecial(special) {
  return (dispatch) => {
    firestore
      .collection('Specials')
      .doc(special._id)
      .delete()
      .then(() => {});
  };
}

function specialsFetched(specials) {
  return {
    type: ActionTypes.FetchSpecials,
    specials,
  };
}

function allSpecialsFetched(allSpecials) {
  return {
    type: ActionTypes.FetchAllSpecials,
    allSpecials,
  };
}

function specialsCountFetched(totalCount) {
  return {
    type: ActionTypes.SpecialsCountFetched,
    totalCount,
  };
}
