import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import CloseDatesList from '../../components/CloseDateList';
import ClosedDate from '../../components/ClosedDate';
import {
  addCloseDate,
  getCloseDates,
  deleteCloseDate,
  getCloseDateCount,
  resetPageData,
} from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class CloseDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/close-dates'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getCloseDatesHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getCloseDateCountHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      closeDates: newProps.closeDate.closeDates,
      totalCount: newProps.closeDate.totalCount,
    });
  }

  componentWillUnmount() {
    this.props.resetPageDataHandler();
  }

  deleteCloseDateHandler = (closeDate) => {
    this.props.deleteCloseDateHandler(
      closeDate,
      this.state.storeId,
      this.state.pagination
    );
  };

  handlePageChange = (e) => {
    this.setState(
      {
        closeDates: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getCloseDatesHandler(
          this.state.storeId,
          this.state.pagination
        );
      }
    );
  };

  addCloseDateHandler = (closeDate, dateRangeObj) => {
    if (this.props.layout.store) {
      closeDate.storeId = this.state.storeId;
      closeDate.restaurantId = this.state.restaurantId;
      this.props.addCloseDateHandler(
        closeDate,
        dateRangeObj,
        this.props.layout.store
      );
    }
  };

  render() {
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <CloseDatesList
            closeDates={this.state.closeDates}
            totalCount={this.state.totalCount}
            handlePageChange={this.handlePageChange}
            pagination={this.state.pagination}
            deleteCloseDateHandler={this.deleteCloseDateHandler}
            timeZone={this.state.timeZone}
          />
          <ClosedDate
            addCloseDateHandler={this.addCloseDateHandler}
            timeZone={this.state.timeZone}
          />
        </div>
      </Layout>
    );
  }
}

CloseDates.propTypes = {};

function mapStateToProps(state) {
  return {
    closeDate: state.closeDateReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCloseDatesHandler: (storeId, pagination) =>
      dispatch(getCloseDates(storeId, pagination)),
    addCloseDateHandler: (closeDate, dateRangeObj, store) =>
      dispatch(addCloseDate(closeDate, dateRangeObj, store)),
    deleteCloseDateHandler: (closeDate) => dispatch(deleteCloseDate(closeDate)),
    getCloseDateCountHandler: (storeId) => dispatch(getCloseDateCount(storeId)),
    resetPageDataHandler: () => dispatch(resetPageData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseDates);
