import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import WarningModal from '../../components/WarningModal';
import SuburbForm from '../../components/SuburbForm';
import Spinner from '../../components/Spinner';
import './index.css';
import moment from 'moment-timezone';

class Suburbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuburbWarning: false,
      isDisplaySuburb: false,
      isEditModeOn: false,
      suburbForEdit: { name: '', deliveryCost: 0 },
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
    };
  }

  closeWarnings = () => {
    this.setState({ showSuburbWarning: false });
  };

  openSuburbWarningModal = (suburb) => {
    this.setState({ showSuburbWarning: true, suburbToBeDeleted: suburb });
  };

  openSuburbModalForNew = (suburb) => {
    this.setState({
      isError: false,
      isDisplaySuburb: true,
      isEditModeOn: false,
      suburbForEdit: suburb,
    });
  };

  openSuburbModal = (suburb) => {
    this.setState({
      isError: false,
      isDisplaySuburb: true,
      isEditModeOn: true,
      suburbForEdit: suburb,
    });
  };

  closeSuburbModal = () => {
    this.setState({
      isError: false,
      isDisplaySuburb: false,
      suburbToBeDeleted: {},
      isEditModeOn: false,
      suburbForEdit: {},
      showSuburbWarning: false,
    });
  };

  deleteSuburb = () => {
    this.props.deleteSuburbHandler(this.state.suburbToBeDeleted);
    this.closeSuburbModal();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.unSupportedSuburbs !== this.props.unSupportedSuburbs) {
      let suburbObj = {};
      console.log(this.props.unSupportedSuburbs);
      this.props.unSupportedSuburbs.forEach((suburb) => {
        if (suburbObj[suburb.suburb]) {
          suburbObj[suburb.suburb] = {
            count: suburbObj[suburb.suburb].count + 1,
            date:
              suburbObj[suburb.suburb].dateTimestamp > suburb.dateTimestamp
                ? suburbObj[suburb.suburb].date
                : suburb.date,
            dateTimestamp:
              suburbObj[suburb.suburb].dateTimestamp > suburb.dateTimestamp
                ? suburbObj[suburb.suburb].dateTimestamp
                : suburb.dateTimestamp,
          };
        } else {
          suburbObj[suburb.suburb] = {
            count: 1,
            date: suburb.date,
            dateTimestamp: suburb.dateTimestamp,
          };
        }
      });
      this.setState({
        unSupportedSuburb: suburbObj,
      });
    }
  }

  render() {
    const start =
      (this.props.pagination.currentPage - 1) * this.props.pagination.pageSize +
      1;
    const end = this.props.suburbs
      ? (this.props.pagination.currentPage - 1) *
          this.props.pagination.pageSize +
        1 +
        this.props.suburbs.length -
        1
      : 0;

    return (
      <div className='row'>
        <div className='col-sm-12 col-xs-12 col-md-12'>
          <div className='panel panel-default panel-table '>
            <div className='panel-heading'>
              Delivery Suburbs
              <div className='fright'>
                <button
                  onClick={() => this.openSuburbModalForNew({})}
                  className='btn btn-space btn-default'
                  data-toggle='modal'
                >
                  Add Suburb
                </button>
              </div>
            </div>
            <div className='panel-body table-responsive'>
              <div
                id='table1_wrapper'
                className='dataTables_wrapper form-inline dt-bootstrap no-footer'
              >
                <div className='row be-datatable-body'>
                  <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                    <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                      <thead>
                        <tr>
                          <th
                            className={this.props.sorting[0].class}
                            onClick={() => this.props.sortMe(0)}
                          >
                            Suburb
                          </th>
                          <th
                            className={this.props.sorting[1].class}
                            onClick={() => this.props.sortMe(1)}
                          >
                            Last 24 hours
                          </th>
                          <th
                            className={this.props.sorting[2].class}
                            onClick={() => this.props.sortMe(2)}
                          >
                            Total Orders
                          </th>
                          <th
                            className={this.props.sorting[3].class}
                            onClick={() => this.props.sortMe(3)}
                          >
                            Ave. Order Spend
                          </th>
                          <th
                            className={this.props.sorting[4].class}
                            onClick={() => this.props.sortMe(4)}
                          >
                            Ave. Delivery Times
                          </th>
                          <th
                            className={this.props.sorting[5].class}
                            onClick={() => this.props.sortMe(5)}
                          >
                            Delivery Cost
                          </th>
                          <th
                            className={this.props.sorting[7].class}
                            onClick={() => this.props.sortMe(7)}
                          >
                            Min. Order Value
                          </th>
                          <th
                            className={this.props.sorting[6].class}
                            onClick={() => this.props.sortMe(6)}
                          >
                            Popularity
                          </th>
                          <th
                            className='nosort sorting_disabled'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='&amp;nbsp;'
                          >
                            &nbsp;
                          </th>
                        </tr>
                      </thead>
                      {!this.props.suburbs ? (
                        <tbody>
                          <tr style={{ height: '100px' }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                left: '0px',
                                right: '0px',
                                position: 'absolute',
                                width: 'auto',
                                height: '100px',
                              }}
                              className='be-loading be-loading-active'
                            >
                              <Spinner />
                            </div>
                          </tr>
                        </tbody>
                      ) : this.props.suburbs.length > 0 ? (
                        <tbody>
                          {this.props.suburbs &&
                            this.props.suburbs.map((suburb, index) => (
                              <tr key={index}>
                                <td>{suburb.name}</td>
                                <td>
                                  {suburb.past24Hours
                                    ? `${suburb.past24Hours} order`
                                    : 'N/A'}
                                </td>
                                <td>
                                  {suburb.totalOrdersSub
                                    ? `${suburb.totalOrdersSub} order`
                                    : 'N/A'}
                                </td>
                                <td>
                                  {suburb.averageOrderSpent
                                    ? `${
                                        this.props.currency
                                      }${suburb.averageOrderSpent.toFixed(2)}`
                                    : 'N/A'}
                                </td>
                                <td>
                                  {suburb.averageDeliveryTime
                                    ? `${suburb.averageDeliveryTime} minutes`
                                    : 'N/A'}
                                </td>
                                <td>{`${this.props.currency}${suburb.deliveryCost}`}</td>
                                <td>{`${this.props.currency}${
                                  suburb.minOrderValue
                                    ? suburb.minOrderValue
                                    : '0'
                                }`}</td>
                                <td className='milestone'>
                                  <div className='progress'>
                                    <div
                                      style={{
                                        width: `${
                                          suburb.popularity
                                            ? suburb.popularity
                                            : 0
                                        }%`,
                                      }}
                                      className='progress-bar progress-bar-success'
                                    />
                                  </div>
                                </td>
                                <td className='text-right'>
                                  <button
                                    onClick={() => this.openSuburbModal(suburb)}
                                    className='btn btn-space btn-primary'
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() =>
                                      this.openSuburbWarningModal(suburb)
                                    }
                                    className='btn btn-space btn-default'
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          <tr />
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <p> No records to display</p>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className='row be-datatable-footer'>
                  <div className='col-md-5 col-sm-12'>
                    <div
                      style={{ float: 'left' }}
                      className='dataTables_info'
                      id='table1_info'
                      role='status'
                      aria-live='polite'
                    >
                      {`Showing ${start} to ${end} of ${
                        this.props.totalCount ? this.props.totalCount : 0
                      } entries`}
                    </div>
                  </div>
                  <div className='col-md-7 col-sm-12'>
                    <div
                      className='dataTables_paginate paging_simple_numbers'
                      id='table1_paginate'
                    >
                      {this.props.pagination && (
                        <nav style={{ float: 'right' }}>
                          <Pagination
                            activePage={this.props.pagination.currentPage}
                            itemsCountPerPage={this.props.pagination.pageSize}
                            totalItemsCount={this.props.totalCount}
                            pageRangeDisplayed={
                              this.props.pagination.pageRangeDisplayed
                            }
                            onChange={this.props.handlePageChange}
                            prevPageText='Previous'
                            nextPageText='Next'
                            itemClass='paginate_button'
                          />
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.isDisplaySuburb && (
          <Modal
            aria-labelledby='modal-label'
            backdrop='static'
            keyboard={false}
            className='modal fade'
            show={this.state.isDisplaySuburb}
            onHide={this.closeSuburbModal}
            bsSize='sm'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  onClick={this.closeSuburbModal}
                  type='button'
                  data-dismiss='modal'
                  aria-hidden='true'
                  className='close md-close'
                >
                  <span className='mdi mdi-close' />
                </button>
              </div>
              <SuburbForm
                addSuburbHandler={this.props.addSuburbHandler}
                isEditModeOn={this.state.isEditModeOn}
                suburbForEdit={this.state.suburbForEdit}
                closeSuburbModal={this.closeSuburbModal}
                timeZone={this.props.timeZone}
                currency={this.props.currency}
              />
            </div>
          </Modal>
        )}
        {this.state.showSuburbWarning && (
          <WarningModal
            procced={this.deleteSuburb}
            closeModal={this.closeWarnings}
            message={`This will delete Suburb. Click "proceed" to continue.`}
          />
        )}
        <div className='col-sm-12 col-xs-12 col-md-12'>
          <div className='panel panel-default panel-table '>
            <div className='panel-heading'>Not Supported Suburbs</div>
            <div className='panel-body table-responsive'>
              <div
                id='table1_wrapper'
                className='dataTables_wrapper form-inline dt-bootstrap no-footer'
              >
                <div className='row be-datatable-body'>
                  <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                    <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                      <thead>
                        <tr>
                          <th>Suburb</th>
                          <th>Requests</th>
                          <th>Last Requested Date</th>
                        </tr>
                      </thead>
                      {!this.state.unSupportedSuburb ? (
                        <tbody>
                          <tr style={{ height: '100px' }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                left: '0px',
                                right: '0px',
                                position: 'absolute',
                                width: 'auto',
                                height: '100px',
                              }}
                              className='be-loading be-loading-active'
                            >
                              <Spinner />
                            </div>
                          </tr>
                        </tbody>
                      ) : Object.entries(this.state.unSupportedSuburb).length >
                        0 ? (
                        <tbody>
                          {console.log(this.state.unSupportedSuburb)}
                          {Object.entries(this.state.unSupportedSuburb) &&
                            Object.entries(this.state.unSupportedSuburb).map(
                              (suburb, index) => (
                                <tr key={index}>
                                  <td>{suburb[0]}</td>
                                  <td>{suburb[1].count}</td>
                                  <td>
                                    {moment(suburb[1].date).format(
                                      'dddd, Do MMMM YYYY'
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          <tr />
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <p> No records to display</p>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className='row be-datatable-footer'>
                  <div className='col-md-5 col-sm-12'>
                    {/* <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.props.totalCount ? this.props.totalCount : 0
                          } entries`}
                        </div> */}
                  </div>
                  <div className='col-md-7 col-sm-12'>
                    <div
                      className='dataTables_paginate paging_simple_numbers'
                      id='table1_paginate'
                    >
                      {/* {this.props.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.props.pagination.currentPage}
                                itemsCountPerPage={2}
                                totalItemsCount={
                                  this.state.unSupportedSuburb
                                    ? Object.entries(
                                        this.state.unSupportedSuburb
                                      ).length
                                    : 0
                                }
                                pageRangeDisplayed={
                                  this.props.pagination.pageRangeDisplayed
                                }
                                onChange={this.props.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Suburbs.propTypes = {
  suburbs: PropTypes.any,
  totalCount: PropTypes.any,
  deleteSuburbHandler: PropTypes.any,
  addSuburbHandler: PropTypes.any,
  handlePageChange: PropTypes.any,
  sortMe: PropTypes.any,
  sorting: PropTypes.any,
};

export default Suburbs;
