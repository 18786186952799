import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Ratings extends Component {
  render() {
    const { rating, review } = this.props;
    return (
      <div>
        <fieldset style={this.props.style} className='rating hidden-xs'>
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='5'
            defaultChecked={rating === 5 ? 'checked' : ''}
          />
          <label className='full' htmlFor='star5' title='Awesome - 5 stars' />
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='4 and a half'
            defaultChecked={rating === 4.5 ? 'checked' : ''}
          />
          <label
            className='half'
            htmlFor='star4half'
            title='Pretty good - 4.5 stars'
          />
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='4'
            defaultChecked={rating === 4 ? 'checked' : ''}
          />
          <label
            className='full'
            htmlFor='star4'
            title='Pretty good - 4 stars'
          />
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='3 and a half'
            defaultChecked={rating === 3.5 ? 'checked' : ''}
          />
          <label className='half' htmlFor='star3half' title='Meh - 3.5 stars' />
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='3'
            defaultChecked={rating === 3 ? 'checked' : ''}
          />
          <label className='full' htmlFor='star3' title='Meh - 3 stars' />
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='2 and a half'
            defaultChecked={rating === 2.5 ? 'checked' : ''}
          />
          <label
            className='half'
            htmlFor='star2half'
            title='Kinda bad - 2.5 stars'
          />
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='2'
            defaultChecked={rating === 2 ? 'checked' : ''}
          />
          <label className='full' htmlFor='star2' title='Kinda bad - 2 stars' />
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='1 and a half'
            defaultChecked={rating === 1.5 ? 'checked' : ''}
          />
          <label className='half' htmlFor='star1half' title='Meh - 1.5 stars' />
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='1'
            defaultChecked={rating === 1 ? 'checked' : ''}
          />
          <label
            className='full'
            htmlFor='star1'
            title='Sucks big time - 1 star'
          />
          <input
            readOnly
            type='radio'
            id={`${Math.random().toString(36)}-id-${review.rating}`}
            name={`${Math.random().toString(36)}-id-${review.rating}`}
            value='half'
            defaultChecked={rating === 0.5 ? 'checked' : ''}
          />
          <label
            className='half'
            htmlFor='starhalf'
            title='Sucks big time - 0.5 stars'
          />
        </fieldset>
      </div>
    );
  }
}

Ratings.propTypes = {
  rating: PropTypes.any,
  review: PropTypes.any,
};

export default Ratings;
