import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchActiveLogins:
      return { ...state, activeDevices: payload.activeDevices };
    case ActionTypes.FetchAuthCode:
      return { ...state, authCode: payload.authCode };
    case ActionTypes.FetchTotalActiveDevice:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.ResetAuthPageData:
      return {
        ...state,
        totalCount: undefined,
        activeDevices: undefined,
      };
    default:
      return state;
  }
};
