import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getVoucherCount(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('Vouchers')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true);
    ref.onSnapshot((querySnapshot) => {
      dispatch(voucherCountFetched(querySnapshot.docs.length));
    });
  };
}

export function getVouchers(storeId, pagination) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('Vouchers')
      .where('storeId', '==', storeId)
      .where('isActive', '==', true)
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('Vouchers')
          .where('storeId', '==', storeId)
          .where('isActive', '==', true)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(vouchersFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('Vouchers')
          .where('storeId', '==', storeId)
          .where('isActive', '==', true)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(vouchersFetched(dataArray));
        });
      }
    });
  };
}

export function addVoucher(obj) {
  return (dispatch) => {
    if (obj.voucherCode) {
      let keywords = [];
      let objString = obj.voucherCode.toUpperCase();
      let arr = objString.split(' ');
      let outcome = '';
      [...objString].forEach((c) => {
        outcome = outcome + c;
        keywords.push(outcome);
      });
      if (arr.length > 0) {
        arr.forEach((s, i) => {
          if (i !== 0) {
            let outcome1 = '';
            [...s].forEach((c) => {
              outcome1 = outcome1 + c;
              keywords.push(outcome1);
            });
          }
        });
      }
      obj.keywords = keywords;
    }
    firestore
      .collection('Vouchers')
      .add(obj)
      .then(() => {});
  };
}

export function deleteVoucher(voucher) {
  return (dispatch) => {
    firestore
      .collection('Vouchers')
      .doc(voucher._id)
      .delete()
      .then(() => {});
  };
}

export function redeemVoucher(voucher) {
  return (dispatch) => {
    firestore
      .collection('Vouchers')
      .doc(voucher._id)
      .update({
        voucherUsageCount: voucher.maxOrders,
        isActive: false,
      })
      .then(() => {});
  };
}

export function getStore(storeId) {
  return (dispatch) => {
    let ref = firestore.collection('Stores').doc(storeId);
    ref.onSnapshot((doc) => {
      dispatch(storeFetched(doc.data()));
    });
  };
}

export function getVoucherCountExpired(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('Vouchers')
      .where('storeId', '==', storeId)
      .where('isActive', '==', false);
    ref.onSnapshot((querySnapshot) => {
      dispatch(voucherCountExpiredFetched(querySnapshot.docs.length));
    });
  };
}

export function getVouchersExpired(storeId, pagination) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('Vouchers')
      .where('storeId', '==', storeId)
      .where('isActive', '==', false)
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('Vouchers')
          .where('storeId', '==', storeId)
          .where('isActive', '==', false)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(vouchersExpiredFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('Vouchers')
          .where('storeId', '==', storeId)
          .where('isActive', '==', false)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(vouchersExpiredFetched(dataArray));
        });
      }
    });
  };
}

function voucherCountFetched(totalCount) {
  return {
    type: ActionTypes.VoucherCountFetched,
    totalCount,
  };
}

function vouchersFetched(vouchers) {
  return {
    type: ActionTypes.FetchVouchers,
    vouchers,
  };
}

function storeFetched(store) {
  return {
    type: ActionTypes.FetchStore,
    store,
  };
}

function voucherCountExpiredFetched(totalCountExpired) {
  return {
    type: ActionTypes.VoucherCountExpiredFetched,
    totalCountExpired,
  };
}

function vouchersExpiredFetched(vouchersExpired) {
  return {
    type: ActionTypes.FetchExpiredVouchers,
    vouchersExpired,
  };
}
