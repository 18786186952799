import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Spinner from '../../components/Spinner';
import VoucherForm from '../../components/VoucherForm';
import VoucherSMSBox from '../../components/VoucherSMSBox';
import {
  convertToMomentDate,
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';
import getFormattedMobileNumber from '../../helpers/getFormattedMobileNumber';
import WarningModal from '../../components/WarningModal';
import { nextOrderConfig } from '../../configurations/storeSetup';
import './index.css';

class VoucherList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVoucher: {},
      showVoucherWarning: false,
      isDisplayAddVoucher: false,
    };
  }

  openAddVoucherModal = () => {
    this.setState({ isDisplayAddVoucher: true });
  };

  closeVoucherModal = () => {
    this.setState({
      isDisplayAddVoucher: false,
      selectedVoucher: {},
      isDisplayVoucherBox: false,
    });
  };

  deleteVoucher = (voucher) => {
    this.props.deleteVoucherHandler(voucher);
    this.closeVoucherModal();
  };

  openVoucherWarningModal = (voucher) => {
    this.setState({ showVoucherWarning: true, selectedVoucher: voucher });
  };

  closeWarnings = () => {
    this.setState({ showVoucherWarning: false });
  };

  redeemVoucher = () => {
    if (this.state.selectedVoucher) {
      this.props.redeemVoucherHandler(this.state.selectedVoucher);
      this.closeWarnings();
    }
  };

  addVoucherHandler = (voucher) => {
    this.props.addVoucherHandler(voucher);
    this.closeVoucherModal();
  };

  openVoucherBox = (voucher) => {
    this.setState({ isDisplayVoucherBox: true, selectedVoucher: voucher });
  };

  sendSMS = (customerMobileNumber, message) => {
    if (this.state.selectedVoucher) {
      let smsObj = {
        sourceNumber: getFormattedMobileNumber(this.props.store.phoneNumber),
        customerNumber: getFormattedMobileNumber(customerMobileNumber),
        storeId: this.state.selectedVoucher.storeId,
        restaurantId: this.state.selectedVoucher.restaurantId,
        smsType: 11,
        message: message,
        date: getCurrentFormattedMomentDate(
          this.props.timeZone,
          'YYYY-MM-DD HH:mm'
        ),
        dateTimestamp: getCurrentTimestamp(),
      };
      fetch(`${nextOrderConfig.cloudFunctionUrl}/sendSMS`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...smsObj,
        }),
      })
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            this.setState({});
          }
        });
    }
  };

  getDiscountedItemsDescription = (voucher) => {
    if (voucher.type === '3' && voucher.selectedCategories) {
      let string = '';
      voucher.selectedCategories.map((cat, i) => {
        i === 0
          ? (string = cat.catName)
          : (string = `${string}, ${cat.catName}`);
        string = `${string} (${cat.menuSizes
          .filter((s) => s.isActive)
          .map((s) => s.name)
          .join(',')})`;
        return cat;
      });
      return string;
    } else return 'N/A';
  };

  render() {
    const start =
      (this.props.pagination.currentPage - 1) * this.props.pagination.pageSize +
      1;
    const end = this.props.vouchers
      ? (this.props.pagination.currentPage - 1) *
          this.props.pagination.pageSize +
        1 +
        this.props.vouchers.length -
        1
      : 0;
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12 col-xs-12 col-md-12'>
            <div className='panel panel-default panel-table '>
              {this.props.isActiveVoucherList ? (
                <div className='panel-heading'>
                  Current Vouchers
                  <div className='fright'>
                    <button
                      onClick={() => this.openAddVoucherModal({})}
                      className='btn btn-space btn-default'
                      data-toggle='modal'
                    >
                      Add Voucher
                    </button>
                  </div>
                </div>
              ) : (
                <div className='panel-heading'>Expired Vouchers</div>
              )}
              <div className='panel-body table-responsive'>
                <div
                  id='table1_wrapper'
                  className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                >
                  <div className='row be-datatable-body'>
                    <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                      <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                        <thead>
                          <tr>
                            <th>Vouchers Code</th>
                            <th>Discount</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th className='number'>Max. Use </th>
                            <th className='number'>Min. Order Value </th>
                            <th className='number'>Times used</th>
                            <th>Created On</th>
                            <th />
                          </tr>
                        </thead>
                        {!this.props.vouchers ? (
                          <tbody>
                            <tr style={{ height: '100px' }}>
                              <div
                                style={{
                                  alignSelf: 'center',
                                  left: '0px',
                                  right: '0px',
                                  position: 'absolute',
                                  width: 'auto',
                                  height: '100px',
                                }}
                                className='be-loading be-loading-active'
                              >
                                <Spinner />
                              </div>
                            </tr>
                          </tbody>
                        ) : this.props.vouchers.length > 0 ? (
                          <tbody className='no-border-x'>
                            {this.props.vouchers &&
                              this.props.vouchers.map((voucher, index) => (
                                <tr key={index}>
                                  <td>{voucher.voucherCode}</td>
                                  {voucher.type && voucher.type === '3' ? (
                                    <td>{`Discounted items@(${this.props.currency}${voucher.discountedPrice})`}</td>
                                  ) : (
                                    <td>
                                      {voucher.voucherType === '1'
                                        ? `${voucher.discount}% - ${
                                            voucher.type === '1'
                                              ? 'Promo code'
                                              : 'Voucher'
                                          }`
                                        : `${this.props.currency}${
                                            voucher.discount
                                          } - ${
                                            voucher.type === '1'
                                              ? 'Promo code'
                                              : 'Voucher'
                                          }`}
                                    </td>
                                  )}
                                  <td>
                                    {voucher.startDate
                                      ? convertToMomentDate(
                                          voucher.startDate,
                                          'YYYY-MM-DD HH:mm',
                                          this.props.timeZone
                                        ).format('MMM DD, YYYY')
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {voucher.endDate
                                      ? convertToMomentDate(
                                          voucher.endDate,
                                          'YYYY-MM-DD HH:mm',
                                          this.props.timeZone
                                        ).format('MMM DD, YYYY')
                                      : 'N/A'}
                                  </td>
                                  <td className='number'>{`${
                                    voucher.maxOrders
                                  } ${
                                    voucher.maxOrders > 1 ? 'orders' : 'order'
                                  }`}</td>
                                  <td className='number'>{`${
                                    voucher.minOrderValue
                                      ? voucher.minOrderValue
                                      : 'N/A'
                                  }`}</td>
                                  <td className='number'>
                                    {voucher.voucherUsageCount
                                      ? `${voucher.voucherUsageCount}  ${
                                          voucher.voucherUsageCount > 1
                                            ? 'orders'
                                            : 'order'
                                        }`
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {convertToMomentDate(
                                      voucher.date,
                                      'YYYY-MM-DD HH:mm',
                                      this.props.timeZone
                                    ).format('MMM DD, YYYY')}
                                  </td>
                                  <td>
                                    <React.Fragment>
                                      <button
                                        onClick={() =>
                                          this.openVoucherBox(voucher)
                                        }
                                        className='btn btn-space btn-primary'
                                        data-toggle='modal'
                                        data-target='#vouch'
                                      >
                                        View
                                      </button>
                                      {this.props.isActiveVoucherList && (
                                        <button
                                          onClick={() =>
                                            this.openVoucherWarningModal(
                                              voucher
                                            )
                                          }
                                          className='btn btn-space btn-default'
                                          data-toggle='modal'
                                          data-target='#vouch'
                                        >
                                          Redeem
                                        </button>
                                      )}
                                    </React.Fragment>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td>
                                <p> No records to display</p>
                              </td>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                              <td />
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className='row be-datatable-footer'>
                    <div className='col-md-5 col-sm-12'>
                      <div
                        style={{ float: 'left' }}
                        className='dataTables_info'
                        id='table1_info'
                        role='status'
                        aria-live='polite'
                      >
                        {`Showing ${start} to ${end} of ${
                          this.props.totalCount ? this.props.totalCount : 0
                        } entries`}
                      </div>
                    </div>
                    <div className='col-md-7 col-sm-12'>
                      <div
                        className='dataTables_paginate paging_simple_numbers'
                        id='table1_paginate'
                      >
                        {this.props.pagination && (
                          <nav style={{ float: 'right' }}>
                            <Pagination
                              activePage={this.props.pagination.currentPage}
                              itemsCountPerPage={this.props.pagination.pageSize}
                              totalItemsCount={this.props.totalCount}
                              pageRangeDisplayed={
                                this.props.pagination.pageRangeDisplayed
                              }
                              onChange={this.props.handlePageChange}
                              prevPageText='Previous'
                              nextPageText='Next'
                              itemClass='paginate_button'
                            />
                          </nav>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.isDisplayVoucherBox && this.state.selectedVoucher && (
          <Modal
            aria-labelledby='modal-label'
            className='modal fade'
            show={this.state.isDisplayVoucherBox}
            onHide={this.closeVoucherModal}
            bsSize='large'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  onClick={this.closeVoucherModal}
                  type='button'
                  data-dismiss='modal'
                  aria-hidden='true'
                  className='close md-close'
                >
                  <span className='mdi mdi-close' />
                </button>
              </div>
              <div className='modal-body'>
                <VoucherSMSBox
                  voucher={this.state.selectedVoucher}
                  sendSMS={this.sendSMS}
                  deleteVoucher={this.deleteVoucher}
                  store={this.props.store}
                  currency={this.props.currency}
                />
              </div>
              <div className='modal-footer' />
            </div>
          </Modal>
        )}
        {this.state.showVoucherWarning && (
          <WarningModal
            procced={() => this.redeemVoucher()}
            closeModal={this.closeWarnings}
            message={`Are you sure, you want to redeem a voucher ?`}
          />
        )}
        {this.state.isDisplayAddVoucher && (
          <Modal
            aria-labelledby='modal-label'
            className='modal fade'
            show={this.state.isDisplayAddVoucher}
            onHide={this.closeVoucherModal}
            bsSize='lg'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  onClick={this.closeVoucherModal}
                  type='button'
                  data-dismiss='modal'
                  aria-hidden='true'
                  className='close md-close'
                >
                  <span className='mdi mdi-close' />
                </button>
              </div>
              <div className='modal-body'>
                <VoucherForm
                  addVoucherHandler={this.addVoucherHandler}
                  timeZone={this.props.timeZone}
                  storeId={this.props.storeId}
                  openedFromReviews={false}
                  categories={this.props.categories}
                  sizes={this.props.sizes}
                  menuItems={this.props.menuItems}
                  storeId={this.props.storeId}
                  currency={this.props.currency}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

VoucherList.propTypes = {
  vouchers: PropTypes.any,
  totalCount: PropTypes.any,
  deleteVoucherHandler: PropTypes.any,
  redeemVoucherHandler: PropTypes.any,
};

export default VoucherList;
