import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import { Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import queryString from 'query-string';
import VoucherForm from '../../components/VoucherForm';
import Layout from '../../containers/Layout';
import Ratings from '../../components/Ratings';
import Spinner from '../../components/Spinner';
import OrderModal from '../../components/OrderModal';
import OtherOrderModal from '../../components/OtherOrderModal';
import {
  convertToMomentDate,
  convertToFormattedMomentDate,
} from '../../helpers/timeHelper';
import {
  getReviews,
  updateReview,
  getReviewCount,
  getStore,
  addVoucherAndSMS,
} from './actions';
import getOrderStatus from '../../helpers/getOrderStatus';

import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      filterString: null,
      isDisplayReview: false,
      user: {},
      showReviewPopup: false,
      isDisplayAddVoucher: false,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        if (this.props.location.search) {
          let values = queryString.parse(window.location.search);
          if (values.reviewId) {
            this.props.history.push(
              `/restaurants/login?redirect=/restaurants/marketing/reviews?reviewId=${values.reviewId}`
            );
          } else {
            this.props.history.push(
              '/restaurants/login?redirect=/restaurants/marketing/reviews'
            );
          }
        } else {
          this.props.history.push(
            '/restaurants/login?redirect=/restaurants/marketing/reviews'
          );
        }
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getReviewsHandler(
                      doc.data().storeId,
                      this.state.pagination,
                      this.state.filterString
                    );
                    this.props.getReviewCountHandler(
                      doc.data().storeId,
                      this.state.filterString
                    );
                    this.props.getStoreHandler(doc.data().storeId);
                  }
                );
              });
              if (this.props && this.props.location.search) {
                let values = queryString.parse(this.props.location.search);
                if (values.reviewId) {
                  firestore
                    .collection('Reviews')
                    .doc(values.reviewId)
                    .get()
                    .then((dcm) => {
                      let review = dcm.data();
                      this.openReviewViewModal(review);
                    });
                }
              }
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      reviews: newProps.review.reviews,
      suburb: newProps.review.suburb,
      totalCount: newProps.review.totalCount,
      store: newProps.review.store,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        reviews: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getReviewsHandler(
          this.state.storeId,
          this.state.pagination,
          this.state.filterString
        );
      }
    );
  };

  filterReviews = (e) => {
    this.setState(
      {
        filterString: e.target.value,
      },
      () => {
        this.props.getReviewsHandler(
          this.state.storeId,
          this.state.pagination,
          this.state.filterString
        );
        this.props.getReviewCountHandler(
          this.state.storeId,
          this.state.filterString
        );
      }
    );
  };

  openReviewViewModal = (review) => {
    this.setState({ showReviewPopup: true, reviewView: review });
  };

  closeReviewViewModal = () => {
    this.setState({ showReviewPopup: false, user: {} });
  };

  openModal = (_id, type) => {
    if (type === '1' || type === '2') {
      firestore
        .collection('Orders')
        .doc(_id)
        .get()
        .then((dcm) => {
          let order = dcm.data();
          if (order) {
            this.setState({ selectedItem: order, selectedItemType: 1 });
            this.openOrderModal(order);
          }
        });
    } else if (type === '4') {
      firestore
        .collection('OtherDeliveries')
        .doc(_id)
        .get()
        .then((dcm) => {
          let order = dcm.data();
          if (order) {
            this.setState({ selectedItem: order, selectedItemType: 3 });
            this.openOtherOrderModal(order);
          }
        });
    } else if (type === '3') {
      firestore
        .collection('Reservations')
        .doc(_id)
        .get()
        .then((dcm) => {
          let order = dcm.data();
          if (order) {
            this.setState({ selectedItem: order, selectedItemType: 1 });
            this.openReservationModal(order);
          }
        });
      // let reservation = this.state.reservations.filter(obj => {
      //   return obj._id === _id;
      // });
      // if (reservation && reservation.length > 0) {
      //   this.setState({ selectedItem: reservation[0], selectedItemType: 2 });
      //   this.openReservationModal(reservation[0]);
      // }
    }
  };

  openOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOrder: true });
  };

  openOtherOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOtherOrder: true });
  };

  closeOrderModal = () => {
    this.setState({
      isDisplayOrder: false,
      isDisplayOtherOrder: false,
      selectedOrder: {},
      selectedItem: {},
    });
  };

  openReservationModal = (reservation) => {
    this.setState({
      selectedReservation: reservation,
      isDisplayReservation: true,
    });
  };

  closeReservationModal = () => {
    this.setState({
      isDisplayReservation: false,
      showDriverWarning: false,
      showDriverWarningForD: false,
      selectedReservation: {},
      selectedItem: {},
    });
  };

  openAddVoucherModal = (review) => {
    this.setState({ isDisplayAddVoucher: true, selectedReview: review });
  };

  closeVoucherModal = () => {
    this.setState({
      isDisplayAddVoucher: false,
      selectedReview: {},
    });
  };

  getReservationStatusClass = (status) => {
    if (status === '1') return 'form-group has-warning';
    else if (status === '2') return 'form-group has-success';
    else if (status === '3') return 'form-group has-error';
  };

  updateReview = (review) => {
    this.props.updateReviewHandler(review);
  };

  toggleReview = (e, review) => {
    review.isVisible = e.target.checked;
    this.updateReview(review);
  };

  toggleAllReview = (e) => {
    this.state.reviews.map((review) => {
      review.isVisible = e.target.checked;
      this.updateReview(review);
      return review;
    });
  };

  getReviewType = (reviewType) => {
    if (reviewType === '1') return 'Pickup';
    else if (reviewType === '2') return 'Delivery';
    else if (reviewType === '3') return 'Table Booking';
    else if (reviewType === '4') return 'Other Delivery';
    else return 'N/A';
  };

  addVoucherAndSMS = (voucher) => {
    console.log(voucher, this.state.selectedReview);
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.reviews
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.reviews.length -
        1
      : 0;
    const notVisibleReview = this.state.reviews
      ? this.state.reviews.filter(function (obj) {
          return obj.isVisible === false;
        })
      : [{}];
    let style1 = {
      float: 'left',
      fontSize: '20px',
      marginRight: '10px',
      position: 'relative',
      top: '30px',
      marginBottom: '20px',
    };
    let style2 = {
      border: 'medium none',
      float: 'left',
      marginTop: '20px',
      letterSpacing: '-0.062em',
      top: '-37px',
      marginRight: '30px',
    };
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-xs-12 col-md-12'>
              <div className='panel panel-default panel-table '>
                <div className='panel-heading'>Reviews</div>
                <div className='row be-datatable-header'>
                  <div className='col-sm-6' />
                  <div className='col-sm-6'>
                    <div className='input-group input-search search-box'>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='Search'
                        value={this.state.filterString}
                        onChange={this.filterReviews}
                      />
                      <span className='input-group-btn'>
                        <button className='btn btn-secondary'>
                          <i className='icon mdi mdi-search' />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                          <thead>
                            <tr>
                              <th style={{ width: '10%' }}>
                                <div className='be-checkbox be-checkbox-sm'>
                                  <input
                                    checked={
                                      !(
                                        notVisibleReview &&
                                        notVisibleReview.length > 0
                                      )
                                    }
                                    onChange={(e) => this.toggleAllReview(e)}
                                    id='check1'
                                    type='checkbox'
                                  />
                                  <label htmlFor='check1' />
                                </div>
                              </th>
                              <th style={{ width: '10%' }}>Customer</th>
                              <th style={{ width: '25%' }}>Comment</th>
                              <th style={{ width: '25%' }}>Rating </th>
                              <th style={{ width: '5%' }}>Delivery</th>
                              <th style={{ width: '10%' }}>Order Date</th>
                              <th style={{ width: '15%' }}> </th>
                            </tr>
                          </thead>
                          {!this.state.reviews ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : this.state.reviews.length > 0 ? (
                            <tbody className='no-border-x'>
                              {this.state.reviews &&
                                this.state.reviews.map((review, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className='be-checkbox be-checkbox-sm'>
                                        <input
                                          onChange={(e) =>
                                            this.toggleReview(e, review)
                                          }
                                          checked={review.isVisible}
                                          id={`check${review._id}`}
                                          type='checkbox'
                                        />
                                        <label htmlFor={`check${review._id}`}>
                                          {review.isVisible
                                            ? 'Visible'
                                            : 'Not Visible'}
                                        </label>
                                      </div>
                                    </td>
                                    <td>
                                      {review.userName
                                        ? review.userName
                                        : 'N/A'}
                                    </td>
                                    <td>
                                      {review.comment ? review.comment : 'N/A'}
                                    </td>
                                    <td>
                                      <span
                                        style={style1}
                                        className='reviewlab'
                                      >
                                        {review.rating}
                                      </span>
                                      <Ratings
                                        style={style2}
                                        review={review}
                                        rating={review.rating}
                                      />
                                    </td>
                                    <td>
                                      {this.getReviewType(review.reviewType)}
                                    </td>
                                    <td>
                                      {convertToMomentDate(
                                        review.date,
                                        'YYYY-MM-DD HH:mm',
                                        this.state.timeZone
                                      ).format('MMM DD, YYYY')}
                                      <br />
                                      <span className='grayc'>
                                        {convertToMomentDate(
                                          review.date,
                                          'YYYY-MM-DD HH:mm',
                                          this.state.timeZone
                                        ).format('HH:mm')}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className='btn btn-space btn-default'
                                        onClick={() =>
                                          this.openReviewViewModal(review)
                                        }
                                      >
                                        View
                                      </button>
                                      {/* <button
                                        className='btn btn-space btn-primary'
                                        onClick={() =>
                                          this.openAddVoucherModal(review)
                                        }
                                      >
                                        Send Voucher
                                      </button> */}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td />
                                <td />
                                <td>
                                  <p> No records to display</p>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.state.totalCount ? this.state.totalCount : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showReviewPopup && (
            <Modal
              aria-labelledby='modal-label'
              className='modal fade'
              show={this.state.showReviewPopup}
              onHide={this.closeReviewViewModal}
              bsSize='sm'
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    onClick={this.closeReviewViewModal}
                    type='button'
                    data-dismiss='modal'
                    aria-hidden='true'
                    className='close md-close'
                  >
                    <span className='mdi mdi-close' />
                  </button>
                </div>
                <div className='modal-body'>
                  <div className='col-md-12'>
                    <div className='text-center md-mb-30'>
                      {this.state.reviewView ? (
                        <React.Fragment>
                          {this.state.reviewView.firstName && (
                            <div className='form-group'>
                              <label className='col-md-5 control-label text-left'>
                                Customer Name:
                              </label>
                              <div className='col-md-7 text-left'>
                                <label>
                                  {this.state.reviewView.firstName}
                                  {this.state.reviewView.lastName}
                                </label>
                              </div>
                            </div>
                          )}
                          {this.state.reviewView.mobileNumber && (
                            <div className='form-group'>
                              <label className='col-md-5 control-label text-left'>
                                Customer Mobile:
                              </label>
                              <div className='col-md-7 text-left'>
                                <label>
                                  {this.state.reviewView.mobileNumber}
                                </label>
                              </div>
                            </div>
                          )}
                          {this.state.reviewView.comment !== '' && (
                            <div className='form-group'>
                              <label className='col-md-5 control-label text-left'>
                                Comment:
                              </label>
                              <div className='col-md-7 text-left'>
                                <label>{this.state.reviewView.comment}</label>
                              </div>
                            </div>
                          )}
                          {this.state.reviewView.rating && (
                            <div className='form-group'>
                              <div
                                className='col-md-5 control-label text-left'
                                style={{ marginTop: '32px' }}
                              >
                                <label>Rating:</label>
                              </div>
                              <div className='col-md-7 text-left'>
                                <Ratings
                                  style={style2}
                                  review={this.state.reviewView}
                                  rating={this.state.reviewView.rating}
                                />
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      ) : (
                        <div>No details available.</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    onClick={() =>
                      this.openModal(
                        this.state.reviewView.orderId,
                        this.state.reviewView.reviewType
                      )
                    }
                    type='button'
                    data-dismiss='modal'
                    className='btn btn-default md-close'
                  >
                    View Order
                  </button>
                </div>
              </div>
            </Modal>
          )}
          {this.state.isDisplayOrder && (
            <OrderModal
              isDisplayOrder={this.state.isDisplayOrder}
              closeOrderModal={this.closeOrderModal}
              selectedOrder={this.state.selectedOrder}
              timeZone={this.state.timeZone}
              storeId={this.state.storeId}
              currency={currency}
            />
          )}
          {this.state.isDisplayOtherOrder && (
            <OtherOrderModal
              isDisplayOrder={this.state.isDisplayOtherOrder}
              closeOrderModal={this.closeOrderModal}
              selectedOrder={this.state.selectedOrder}
              timeZone={this.state.timeZone}
              storeId={this.state.storeId}
            />
          )}
          {this.state.isDisplayReservation && (
            <Modal
              aria-labelledby='modal-label'
              className='modal fade'
              show={this.state.isDisplayReservation}
              onHide={this.closeReservationModal}
              bsSize='sm'
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    onClick={this.closeReservationModal}
                    type='button'
                    data-dismiss='modal'
                    aria-hidden='true'
                    className='close md-close'
                  >
                    <span className='mdi mdi-close' />
                  </button>
                </div>
                <div className='modal-body'>
                  <div className='col-md-12'>
                    <div className='text-center'>
                      <form
                        action='#'
                        style={{ borderRadius: '0px' }}
                        className='form-horizontal group-border-dashed'
                      >
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Customer Name
                          </label>
                          <div className='col-md-8'>
                            <input
                              value={this.state.selectedReservation.userName}
                              readOnly='readonly'
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Mobile Number
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={
                                this.state.selectedReservation.mobileNumber
                              }
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Adults
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={this.state.selectedReservation.adults}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Children
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={this.state.selectedReservation.children}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Infant
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={this.state.selectedReservation.infants}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Date and Time
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={`${convertToFormattedMomentDate(
                                this.state.selectedReservation.reservationDate,
                                'YYYY-MM-DD HH:mm',
                                this.state.timeZone,
                                'MMM DD, YYYY'
                              )} - ${convertToFormattedMomentDate(
                                this.state.selectedReservation.reservationDate,
                                'YYYY-MM-DD HH:mm',
                                this.state.timeZone,
                                'HH:mm'
                              )}`}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div
                          className={this.getReservationStatusClass(
                            this.state.selectedReservation.reservationStatus
                          )}
                        >
                          <label className='col-md-3 control-label'>
                            Status
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={getOrderStatus(
                                this.state.selectedReservation
                                  .reservationStatus,
                                '3'
                              )}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {this.state.isDisplayAddVoucher && (
            <Modal
              aria-labelledby='modal-label'
              className='modal fade'
              show={this.state.isDisplayAddVoucher}
              onHide={this.closeVoucherModal}
              bsSize='large'
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    onClick={this.closeVoucherModal}
                    type='button'
                    data-dismiss='modal'
                    aria-hidden='true'
                    className='close md-close'
                  >
                    <span className='mdi mdi-close' />
                  </button>
                </div>
                <div className='modal-body'>
                  <VoucherForm
                    addVoucherHandler={this.addVoucherAndSMS}
                    timeZone={this.state.timeZone}
                    storeId={this.state.storeId}
                    openedFromReviews={true}
                    review={this.state.selectedReview}
                  />
                </div>
              </div>
            </Modal>
          )}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    review: state.reviewReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReviewsHandler: (storeId, pagination, filterString) =>
      dispatch(getReviews(storeId, pagination, filterString)),
    getReviewCountHandler: (storeId, filterString) =>
      dispatch(getReviewCount(storeId, filterString)),
    updateReviewHandler: (review) => dispatch(updateReview(review)),
    getStoreHandler: (storeId) => dispatch(getStore(storeId)),
    addVoucherAndSMSHandler: (voucher, smsObj, review) =>
      dispatch(addVoucherAndSMS(voucher, smsObj, review)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
