import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import DragSortableList from 'react-drag-sortable';
import _ from 'lodash';
import './index.css';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import MenuItemModal from '../../components/MenuItemModal';
import MenuItemsDisplay from '../../components/MenuItemsDisplay';
import CategorySoldOutModal from '../../components/CategorySoldOutModal';
import SoldOutModal from '../../components/SoldOutModal';
import WarningModal from '../../components/WarningModal';
import Spinner from '../../components/Spinner';
import './index.css';
import './card.css';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';
import {
  getCategories,
  deleteCategory,
  deleteIngredientCategory,
  addCategory,
  addIngredientCategory,
  getIngredientCategories,
  getIngredients,
  addIngredient,
  deleteIngredient,
  addSize,
  getItemSizes,
  getMenuItems,
  addMenuItem,
  deleteMenuItem,
  updateCategory,
  editCategory,
  updatePrice,
  getItemSizesForCategory,
} from './actions';

import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

var placeholder = document.createElement('li');
placeholder.className = 'placeholder';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCategoryWarning: false,
      showItemWarning: false,
      selectedMenuItem: {},
      showCategoryModal: false,
      showItemModal: false,
      showMenuItemsDisplay: false,
      showSoldOutModal: false,
      selectedItem: '',
      editCategoryInput: false,
      editedCategoryName: '',
      editCategoryId: '',
      showCategorySoldOutModal: false,
      menuItemsManualOrder: [],
      menuItemsPopularityOrder: [],
    };
    this.timeOutId = '';
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/menu/menu-items'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getCategoriesHandler(doc.data().storeId);
                    this.props.getMenuItemsHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(props) {
    if (props.menu.categories && props.menu.menuItems) {
      let myCategories = props.menu.categories;
      myCategories.map((item) => {
        item.menuItems = props.menu.menuItems.filter(function (obj) {
          return obj.categoryId === item._id;
        });
        return item;
      });
      myCategories = _.sortBy(myCategories, 'order');
      this.setState({
        myCategories,
        menuItems: props.menu.menuItems,
      });
    }
    this.setState({
      ingredientCategories: props.menu.ingredientCategories,
      ingredients: props.menu.ingredients,
      sizes: props.menu.sizes,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  closeWarnings = () => {
    this.setState({ showCategoryWarning: false, showItemWarning: false });
  };

  openCategoryWarningModal = (category) => {
    this.setState({ showCategoryWarning: true, categoryTobeDeleted: category });
  };

  deleteCategoryHandler = () => {
    this.props.deleteCategoryHandler(this.state.categoryTobeDeleted);
    this.setState({ showCategoryWarning: false, showItemWarning: false });
  };

  openItemWarningModal = (menuItem) => {
    this.setState({ showItemWarning: true, itemTobeDeleted: menuItem });
  };

  deleteMenuItemHandler = () => {
    this.props.deleteMenuItemHandler(this.state.itemTobeDeleted);
    this.setState({ showCategoryWarning: false, showItemWarning: false });
  };

  closeCategoryModal = () => {
    this.setState({ showCategoryModal: false });
  };

  openCategoryModal = () => {
    this.setState({ showCategoryModal: true });
  };

  closeSoldOutModal = () => {
    this.setState({ showSoldOutModal: false });
  };
  openSoldOutModal = (item) => {
    this.setState({ showSoldOutModal: true, selectedItem: item });
  };

  closeCategorySoldOutModal = () => {
    this.setState({
      showCategorySoldOutModal: false,
    });
  };

  closeItemModal = () => {
    this.setState({
      showItemModal: false,
      selectedMenuItem: {},
      selectedCategory: '',
    });
  };

  closeItemDisplayModel = () => {
    this.setState({ showMenuItemsDisplay: false });
  };

  openItemsDisplayModel = (category) => {
    if (this.props.menu.menuItems) {
      let menuItems = this.props.menu.menuItems.filter((obj) => {
        return obj.categoryId === category._id;
      });
      let menuItemsManualOrder = _.orderBy(
        menuItems,
        ['displayOrder'],
        ['asc']
      );
      let menuItemsPopularityOrder = _.orderBy(
        menuItems,
        ['totalRevenue'],
        ['desc']
      );

      this.setState({
        selectedCategoryDisplay: category,
        menuItemsManualOrder,
        menuItemsPopularityOrder,
        showMenuItemsDisplay: true,
      });
    }
  };

  openItemModal = (_id, itemkey) => {
    this.props.getItemSizesHandler(this.state.storeId, _id);
    this.props.getIngredientCategoriesHandler(this.state.storeId, _id);
    this.props.getIngredientsHandler(this.state.storeId, _id);
    const myCategories = this.state.myCategories.map((item) => {
      if (item._id === _id) item.isSelected = true;
      else item.isSelected = false;
      return item;
    });
    if (itemkey !== 0) {
      const myMenuItem = this.state.menuItems.filter((item) => {
        return item._id === itemkey;
      });
      this.setState({
        myCategories,
        showItemModal: true,
        selectedMenuItem: myMenuItem[0],
        selectedCategory: _id,
      });
    } else {
      this.setState({
        myCategories,
        showItemModal: true,
        selectedMenuItem: {
          selectedSizes: [],
          selectedIngredients: [],
          selectedExtraIngredients: [],
        },
        selectedCategory: _id,
      });
    }
  };

  changeSelectedCategory = (key) => {
    const myCategories = this.state.myCategories.map((item) => {
      if (item._id === key) item.isSelected = true;
      return item;
    });
    this.setState({
      myCategories,
      selectedMenuItem: {
        ...this.state.selectedMenuItem,
        selectedSizes: [],
        selectedIngredients: [],
        selectedExtraIngredients: [],
      },
      selectedCategory: key,
    });
  };

  categoryTxtChange = (e) => {
    let obj = {
      catId: this.state.myCategories ? this.state.myCategories.length + 1 : 1,
      catName: e.target.value,
      order: this.state.myCategories ? this.state.myCategories.length + 1 : 1,
      storeId: this.state.storeId,
      restaurantId: this.state.restaurantId,
      totalOrders: 0,
      totalDeliveryOrders: 0,
      totalPickupOrders: 0,
      totalRevenue: 0,
      totalDeliveryRevenue: 0,
      totalPickupRevenue: 0,
      date: getCurrentFormattedMomentDate(
        this.state.timeZone,
        'YYYY-MM-DD HH:mm'
      ),
      dateTimestamp: getCurrentTimestamp(),
    };
    this.setState({
      ...this.state,
      isCatEmpty: false,
      newCategory: obj,
    });
  };

  addCategoryHandler = () => {
    if (this.state.newCategory && this.state.newCategory.catName) {
      this.props.addCategoryHandler(this.state.newCategory);
      this.setState({ showCategoryModal: false });
    } else {
      this.setState({ ...this.state, isCatEmpty: true });
    }
  };

  addMenuItemHandler = (obj) => {
    obj.storeId = this.state.storeId;
    obj.restaurantId = this.state.restaurantId;
    obj.categoryId = this.state.selectedCategory;
    obj.isAvailable = true;
    this.props.addMenuItemHandler(_.omit(obj, _.isUndefined));
    this.closeItemModal();
  };

  updateSelectedMenuItem = (menuItem) => {
    this.setState({ selectedMenuItem: menuItem });
  };

  onSort = (sortedList) => {
    clearTimeout(this.timeOutId);
    this.timeOutId = setTimeout(this.updateList(sortedList), 1000);
  };

  updateList = (sortedList) => {
    let cats = [];
    sortedList.map((item) => {
      cats = this.state.myCategories.map((m) => {
        if (m._id === item.category._id) {
          m.order = item.rank;
        }
        return m;
      });
      return item;
    });
    let mainList = _.sortBy(cats, 'order');
    this.setState({ ...this.state, myCategories: mainList }, () => {
      this.updateCategoryOnServer();
    });
  };

  updateCategoryOnServer = () => {
    this.state.myCategories.map((m) => {
      delete m.menuItems;
      this.props.updateCategoryHandler(m);
      return m;
    });
  };

  addSizeHandler = (obj) => {
    obj.storeId = this.state.storeId;
    obj.restaurantId = this.state.restaurantId;
    obj.categoryId = this.state.selectedCategory;
    obj.isAvailable = true;
    obj.isAvailableGlutenFree = true;
    this.props.addSizeHandler(obj);
  };

  addIngredientCategoryHandler = (obj) => {
    obj.storeId = this.state.storeId;
    obj.restaurantId = this.state.restaurantId;
    obj.categoryId = this.state.selectedCategory;
    this.props.addIngredientCategoryHandler(obj);
  };

  addIngredientHandler = (obj) => {
    obj.storeId = this.state.storeId;
    obj.restaurantId = this.state.restaurantId;
    obj.categoryId = this.state.selectedCategory;
    obj.totalOrders = 0;
    obj.agregateSales = 0;
    obj.day1 = 0;
    obj.day2 = 0;
    obj.day3 = 0;
    obj.day4 = 0;
    obj.day5 = 0;
    obj.day6 = 0;
    obj.day7 = 0;
    this.props.addIngredientHandler(obj);
  };

  editCategory = (category) => {
    this.setState({
      editCategoryInput: true,
      editCategoryId: category._id,
      editedCategoryName: category.catName,
    });
  };

  handleEditedCategoryName = (e) => {
    this.setState({
      editedCategoryName: e.target.value,
    });
  };

  cancelEditCategory = () => {
    this.setState({
      editCategoryInput: false,
      editCategoryId: '',
      editedCategoryName: '',
    });
  };

  saveEditCategory = (category) => {
    let newCategoryName = this.state.editedCategoryName;
    this.props.editCategoryNameHandler(category._id, newCategoryName);
    this.setState({
      editCategoryInput: false,
      editCategoryId: '',
      editedCategoryName: '',
    });
  };

  sellOutCategory = (category) => {
    this.props.getCategoryItemSizesHandler(this.state.storeId, category._id);
    this.setState({
      showCategorySoldOutModal: true,
      categorySoldOutId: category._id,
    });
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    var list = [];
    if (this.state.myCategories) {
      this.state.myCategories.map((category) => {
        list.push({
          content: <div className='dd-handle'>{category.catName}</div>,
          category,
          classes: ['dd-item'],
        });
        return category;
      });
    }
    return (
      <Layout>
        <div>
          <div className='main-content container-fluid'>
            <div className='col-md-12 col-sm-12 '>
              <div className='col-md-6 com-sm-12 sectionContiner'>
                <div className='panel panel-default'>
                  <div className='panel-heading panel-heading-divider  ptitle'>
                    Categories
                    <span className='panel-subtitle psubtitle'>
                      {`Drag & drop the order of categories as they will appear on the website.`}
                      <button
                        onClick={this.openCategoryModal}
                        className='btn btn-space btn-default btn-space1 fright'
                      >
                        Add Category
                      </button>
                    </span>
                  </div>
                  <p className='divider1' />
                  <div className='panel-body'>
                    <div id='list1' className='dd'>
                      <DragSortableList
                        classNmae='dd-list'
                        items={list}
                        onSort={this.onSort}
                        moveTransitionDuration={0.3}
                        type='vertical'
                      />
                    </div>
                  </div>
                </div>
              </div>

              {this.state && this.state.myCategories ? (
                this.state.myCategories.map((category, index) => (
                  <div
                    key={index}
                    className='col-md-6 com-sm-12 sectionContiner '
                  >
                    <div className='panel panel-default'>
                      <div
                        className='panel-heading panel-heading-divider ptitle pb0'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '-6px',
                        }}
                      >
                        <div>
                          {this.state.editCategoryInput &&
                          this.state.editCategoryId === category._id ? (
                            <React.Fragment>
                              <input
                                type='text'
                                //className='form-control'
                                style={{
                                  fontSize: '15px',
                                  height: '40px',
                                  padding: '8px',
                                  minWidth: '300px',
                                }}
                                value={this.state.editedCategoryName}
                                onChange={this.handleEditedCategoryName}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div
                                onClick={() => this.editCategory(category)}
                                className='category-name'
                              >
                                {category.catName}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                        {this.state.editCategoryInput === false ||
                        this.state.editCategoryId !== category._id ? (
                          <div>
                            <button
                              onClick={() =>
                                this.openCategoryWarningModal(category)
                              }
                              className='btn btn-space btn-default fright'
                            >
                              Delete Category
                            </button>
                            <button
                              onClick={() =>
                                this.openItemsDisplayModel(category)
                              }
                              className='btn btn-space btn-default fright'
                            >
                              Display Order
                            </button>
                            <button
                              onClick={() =>
                                this.openItemModal(category._id, 0)
                              }
                              className='btn btn-space btn-default fright'
                            >
                              Add Item
                            </button>
                            <button
                              onClick={() => this.editCategory(category)}
                              className='btn btn-space btn-default fright'
                            >
                              Edit Category
                            </button>
                            <button
                              onClick={() => this.sellOutCategory(category)}
                              className='btn btn-space btn-default fright'
                            >
                              Sold Out
                            </button>
                          </div>
                        ) : (
                          <React.Fragment>
                            {this.state.editCategoryId === category._id && (
                              <div>
                                <button
                                  onClick={() =>
                                    this.cancelEditCategory(category)
                                  }
                                  className='btn btn-default'
                                  style={{
                                    height: '100%',
                                    margin: '0 20px',
                                    minWidth: '80px',
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={() =>
                                    this.saveEditCategory(category)
                                  }
                                  className='btn btn-primary'
                                  style={{
                                    height: '100%',
                                    margin: '0 20px',
                                    minWidth: '80px',
                                  }}
                                >
                                  Save
                                </button>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                      <p className='divider1' />
                      <div className='panel-body'>
                        <ul id='thumbails' className='list-unstyled '>
                          {category.menuItems &&
                            category.menuItems.map((item, index) => (
                              <div
                                key={index}
                                className={
                                  item.isAvailable
                                    ? 'thumbnail  col-md-4 col-sm-6 col-xs-12'
                                    : 'thumbnail item-Opacity col-md-4 col-sm-6 col-xs-12'
                                }
                              >
                                <img
                                  src={item.urlS3 ? item.urlS3 : item.url}
                                  alt={item.name}
                                />
                                <div className='caption text-center titleicon'>
                                  <h3 className='boxtitle11'>{item.name}</h3>
                                  <div>
                                    <button
                                      onClick={() =>
                                        this.openItemModal(
                                          category._id,
                                          item._id
                                        )
                                      }
                                      type='button'
                                      className='btn btn-default viewbtnb'
                                    >
                                      <span>View</span>
                                    </button>
                                    <button
                                      onClick={() =>
                                        this.openSoldOutModal(item)
                                      }
                                      type='button'
                                      className='btn btn-default viewbtnb'
                                    >
                                      <span>
                                        {item.isAvailable
                                          ? 'Sold Out'
                                          : 'Available'}
                                      </span>
                                    </button>
                                    <button
                                      onClick={() =>
                                        this.openItemWarningModal(item)
                                      }
                                      type='button'
                                      className='btn btn-default trash'
                                    >
                                      <span
                                        className='glyphicon glyphicon-trash f16 to4'
                                        aria-hidden='true'
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='col-md-12 com-sm-12 sectionContiner'>
                  <div className='panel panel-default'>
                    <div
                      style={{ padding: '50px' }}
                      className='be-loading be-loading-active'
                    >
                      <div className='panel-body'>
                        <Spinner />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby='modal-label'
          backdrop='static'
          keyboard={false}
          className='modal fade'
          show={this.state.showCategoryModal}
          onHide={this.closeCategoryModal}
          bsSize='small'
        >
          <div>
            <div className='modal-header'>
              <button
                onClick={this.closeCategoryModal}
                type='button'
                data-dismiss='modal'
                aria-hidden='true'
                className='close md-close'
              >
                <span className='mdi mdi-close' />
              </button>
              <h3 className='modal-title'>Add Category</h3>
            </div>
            <div className='modal-body'>
              <div className='form-group'>
                <label>Name</label>
                <input
                  onChange={this.categoryTxtChange}
                  type='text'
                  placeholder='Category name'
                  className='form-control'
                />
                {this.state.isCatEmpty && (
                  <ul className='parsley-errors-list filled' id='parsley-id-7'>
                    <li className='parsley-type'>{`Required!`}</li>
                  </ul>
                )}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                onClick={this.closeCategoryModal}
                type='button'
                data-dismiss='modal'
                className='btn btn-default md-close'
              >
                Cancel
              </button>
              <button
                onClick={this.addCategoryHandler}
                type='button'
                data-dismiss='modal'
                className='btn btn-primary md-close'
              >
                Add
              </button>
            </div>
          </div>
        </Modal>
        {this.state.showItemModal && (
          <MenuItemModal
            selectedCategory={this.state.selectedCategory}
            categories={this.state.myCategories}
            sizes={this.state.sizes}
            ingredientCategories={this.state.ingredientCategories}
            ingredients={this.state.ingredients}
            addIngredientCategoryHandler={this.addIngredientCategoryHandler}
            deleteIngredientCategoryHandler={
              this.props.deleteIngredientCategoryHandler
            }
            addIngredientHandler={this.addIngredientHandler}
            deleteIngredientHandler={this.props.deleteIngredientHandler}
            addSizeHandler={this.addSizeHandler}
            addMenuItemHandler={this.addMenuItemHandler}
            selectedMenuItem={this.state.selectedMenuItem}
            updateSelectedMenuItem={this.updateSelectedMenuItem}
            changeSelectedCategory={this.changeSelectedCategory}
            closeItemModal={this.closeItemModal}
            showItemModal={this.state.showItemModal}
            timeZone={this.state.timeZone}
            updatePriceHandler={this.props.updatePriceHandler}
            currency={currency}
          />
        )}
        {this.state.showMenuItemsDisplay && (
          <MenuItemsDisplay
            selectedCategoryDisplay={this.state.selectedCategoryDisplay}
            menuItemsManualOrder={this.state.menuItemsManualOrder}
            menuItemsPopularityOrder={this.state.menuItemsPopularityOrder}
            closeItemDisplayModel={this.closeItemDisplayModel}
            showMenuItemsDisplay={this.state.showMenuItemsDisplay}
          />
        )}
        {this.state.showCategoryWarning && (
          <WarningModal
            procced={this.deleteCategoryHandler}
            closeModal={this.closeWarnings}
            message={`This will delete category and all menu items under it. Click "proceed" to continue.`}
          />
        )}
        {this.state.showItemWarning && (
          <WarningModal
            procced={this.deleteMenuItemHandler}
            closeModal={this.closeWarnings}
            message={`This will delete menu item. Click "proceed" to continue.`}
          />
        )}
        {this.state.showSoldOutModal && (
          <Modal
            aria-labelledby='modal-label'
            backdrop='static'
            keyboard={false}
            className='modal fade'
            show={this.state.showSoldOutModal}
            onHide={this.closeSoldOutModal}
            bsSize='small'
          >
            <div>
              <div className='modal-header'>
                <button
                  onClick={this.closeSoldOutModal}
                  type='button'
                  data-dismiss='modal'
                  aria-hidden='true'
                  className='close md-close'
                >
                  <span className='mdi mdi-close' />
                </button>
                <h3 className='modal-title'>Availability</h3>
              </div>
              <div className='modal-body'>
                <SoldOutModal item={this.state.selectedItem} />
              </div>
            </div>
          </Modal>
        )}
        {this.state.showCategorySoldOutModal && (
          <Modal
            aria-labelledby='modal-label'
            backdrop='static'
            keyboard={false}
            className='modal fade'
            show={this.state.showCategorySoldOutModal}
            onHide={this.closeCategorySoldOutModal}
            bsSize='small'
          >
            <div>
              <div className='modal-header'>
                <button
                  onClick={this.closeCategorySoldOutModal}
                  type='button'
                  data-dismiss='modal'
                  aria-hidden='true'
                  className='close md-close'
                >
                  <span className='mdi mdi-close' />
                </button>
                <h3 className='modal-title'>Availability</h3>
              </div>
              <div className='modal-body'>
                <CategorySoldOutModal
                  itemSizes={this.props.menu.categoryItemSizes}
                  categories={this.state.myCategories}
                  categoryId={this.state.categorySoldOutId}
                />
              </div>
            </div>
          </Modal>
        )}
      </Layout>
    );
  }
}

Menu.propTypes = {};

function mapStateToProps(state) {
  return {
    menu: state.menuReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCategoryHandler: (key) => dispatch(updateCategory(key)),
    getCategoriesHandler: (storeId) => dispatch(getCategories(storeId)),
    getCategoryItemSizesHandler: (storeId, categoryId) =>
      dispatch(getItemSizesForCategory(storeId, categoryId)),
    deleteCategoryHandler: (category) => dispatch(deleteCategory(category)),
    addCategoryHandler: (category) => dispatch(addCategory(category)),
    addIngredientCategoryHandler: (ingredientCategory) =>
      dispatch(addIngredientCategory(ingredientCategory)),
    addIngredientHandler: (ingredient) => dispatch(addIngredient(ingredient)),
    getIngredientsHandler: (storeId, categoryId) =>
      dispatch(getIngredients(storeId, categoryId)),
    getIngredientCategoriesHandler: (storeId, categoryId) =>
      dispatch(getIngredientCategories(storeId, categoryId)),
    deleteIngredientHandler: (key) => dispatch(deleteIngredient(key)),
    addSizeHandler: (size) => dispatch(addSize(size)),
    getItemSizesHandler: (storeId, categoryId) =>
      dispatch(getItemSizes(storeId, categoryId)),
    deleteIngredientCategoryHandler: (key) =>
      dispatch(deleteIngredientCategory(key)),
    getMenuItemsHandler: (storeId) => dispatch(getMenuItems(storeId)),
    addMenuItemHandler: (menuItem) => dispatch(addMenuItem(menuItem)),
    deleteMenuItemHandler: (menuItem) => dispatch(deleteMenuItem(menuItem)),
    editCategoryNameHandler: (categoryId, newCategoryName) =>
      dispatch(editCategory(categoryId, newCategoryName)),
    updatePriceHandler: (ingredient, newPrice, newName) =>
      dispatch(updatePrice(ingredient, newPrice, newName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
