import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import randomColor from 'randomcolor'; // import the script

class CustomerPerRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      allSuburbs: this.props.allSuburbs,
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      allSuburbs: newProps.allSuburbs,
    });
  }

  processCustomersPerRegion = () => {
    const pdBreakdown = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };
    if (this.state.allSuburbs) {
      this.state.allSuburbs.map((item) => {
        pdBreakdown.labels.push(item.name);
        pdBreakdown.datasets[0].data.push(item.totalCustomers);
        let randomC = randomColor({});
        pdBreakdown.datasets[0].backgroundColor.push(randomC);
        pdBreakdown.datasets[0].hoverBackgroundColor.push(randomC);
        return item;
      });
    }
    return pdBreakdown;
  };

  render() {
    const pdBreakdown = this.processCustomersPerRegion();
    return (
      <div className='col-md-6'>
        <div className='panel panel-default '>
          <div className='panel-heading panel-heading-divider'>
            <span className='title'> Regions </span>
            <span className='panel-subtitle'>Customer size per region</span>
          </div>
          <p className='divider1' />
          <div className='panel-body table-responsive'>
            {this.state.allSuburbs ? (
              <Pie data={pdBreakdown} />
            ) : (
              <div
                style={{ padding: '50px' }}
                className='be-loading be-loading-active'
              >
                <div className='be-spinner'>
                  <svg
                    width='40px'
                    height='40px'
                    viewBox='0 0 66 66'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle
                      fill='none'
                      strokeWidth='4'
                      strokeLinecap='round'
                      cx='33'
                      cy='33'
                      r='30'
                      className='circle'
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CustomerPerRegion.propTypes = {
  allSuburbs: PropTypes.any,
};

export default CustomerPerRegion;
