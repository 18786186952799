import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import { CSVLink } from 'react-csv';
import Layout from '../Layout';
import Spinner from '../../components/Spinner';
import { getCustomerCount } from '../Customers/actions';
import template from './template';

import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class EmailMarketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
      filterString: null,
    };
    this.dataLoading = false;
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/marketing/email-marketing'
        );
      } else {
        firestore
          .collection('SystemUsers')
          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getCustomerCountHandler(
                      this.state.storeId,
                      this.state.filterString
                    );
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentDidUpdate() {
    if (
      !this.state.finalList &&
      this.props.customer &&
      this.props.customer.totalCount
    ) {
      this.downloadUsers(this.state.storeId);
    }
  }
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  downloadUsers = () => {
    this.dataLoading = true;
    let reqObj = {
      storeId: this.state.storeId,
      currentPage: 1,
      pageSize:
        this.props.customer && this.props.customer.totalCount
          ? this.props.customer.totalCount
          : 100,
    };
    fetch(`${nextOrderConfig.cloudFunctionUrl}/getCustomers`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...reqObj,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          this.setState(
            {
              finalList: result.finalList,
            },
            () => {
              this.dataLoading = false;
            }
          );
        }
      });
  };

  printPDF = () => {
    var printWindow = window.open('', '_blank');
    printWindow.document.write(
      template(this.state.finalList, this.state.storeId)
    );
    printWindow.document.close();
    printWindow.print();
  };

  render() {
    let headers = [
      { label: 'Name', key: 'name' },
      { label: 'Phone Number', key: 'mobileNumber' },
      { label: 'Email Address', key: 'emailAddress' },
    ];
    let finalList = this.state.finalList ? this.state.finalList : [];
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='panel panel-default '>
                <div className='panel-heading panel-heading-divider'>
                  <span className='title'> Customer Contacts </span>
                </div>
                <p className='divider1' />
                <div className='panel-body table-responsive'>
                  {this.state.finalList ? (
                    <div className='row'>
                      <div className='col-md-6'>
                        <CSVLink
                          filename={'customer_list.csv'}
                          className='btn btn-primary'
                          headers={headers}
                          data={finalList}
                        >
                          Download CSV
                        </CSVLink>
                      </div>
                      <div className='col-md-6'>
                        <button
                          className='btn btn-primary'
                          onClick={this.printPDF}
                        >
                          Download PDF
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ padding: '50px' }}
                      className='be-loading be-loading-active'
                    >
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

EmailMarketing.propTypes = {};

function mapStateToProps(state) {
  return {
    customer: state.customerReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCustomerCountHandler: (storeId, filterString) =>
      dispatch(getCustomerCount(storeId, filterString)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailMarketing);
