import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Field, reduxForm, Form } from 'redux-form';
import { load as loadSuburb } from './reducer';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.deliveryCost) {
    errors.deliveryCost = 'Required';
  } else if (isNaN(values.deliveryCost)) {
    errors.deliveryCost = 'Invalid value';
  }
  return errors;
};

const warn = () => {
  const warnings = {};
  return warnings;
};

const renderField = ({
  input,
  placeholder,
  type,
  labelText,
  meta: { touched, error, warning },
}) => (
  <div className='form-group'>
    <label className='col-md-3 control-label'>{labelText}</label>
    <div className='col-md-8'>
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        className='form-control'
      />
      {touched &&
        ((error && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li style={{ fontSize: '13px' }} className='parsley-type'>
              {error}
            </li>
          </ul>
        )) ||
          (warning && (
            <ul className='parsley-errors-list filled' id='parsley-id-7'>
              <li style={{ fontSize: '13px' }} className='parsley-type'>
                {warning}
              </li>
            </ul>
          )))}
    </div>
  </div>
);

const renderMoneyField = ({
  input,
  placeholder,
  type,
  labelText,
  currency,
  meta: { touched, error, warning },
}) => (
  <div className='form-group'>
    <label className='col-md-3 control-label'>{labelText}</label>
    <div className='col-md-8'>
      <div className='input-group'>
        <span className='input-group-addon'>{currency}</span>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          className='form-control'
        />
        {touched &&
          ((error && (
            <ul className='parsley-errors-list filled' id='parsley-id-7'>
              <li style={{ fontSize: '13px' }} className='parsley-type'>
                {error}
              </li>
            </ul>
          )) ||
            (warning && (
              <ul className='parsley-errors-list filled' id='parsley-id-7'>
                <li style={{ fontSize: '13px' }} className='parsley-type'>
                  {warning}
                </li>
              </ul>
            )))}
      </div>
    </div>
  </div>
);

class SuburbForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      suburbForEdit: this.props.suburbForEdit,
    });
    if (this.props.isEditModeOn) {
      this.props.load(this.props.suburbForEdit);
    } else {
      this.props.load({});
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      suburbForEdit: props.suburbForEdit,
    });
    if (props.isEditModeOn) {
      this.props.load(props.suburbForEdit);
    } else {
      this.props.load({});
    }
  }

  handleSubmit = (values) => {
    if (this.props.isEditModeOn) {
      values._id = this.state.suburbForEdit._id;
      values.totalOrders = this.state.suburbForEdit.totalOrders;
      values.popularity = this.state.suburbForEdit.popularity;
      values.totalDeliveryTime = this.state.suburbForEdit.totalDeliveryTime;
      values.averageDeliveryTime = this.state.suburbForEdit.averageDeliveryTime;
      values.averageOrderSpent = this.state.suburbForEdit.averageOrderSpent;
      values.currentDay = this.state.suburbForEdit.currentDay;
      values.past24Hours = this.state.suburbForEdit.past24Hours;
      values.totalCustomers = this.state.suburbForEdit.totalCustomers;
      values.totalRevenueSub = this.state.suburbForEdit.totalRevenueSub;
      values.totalOrdersSub = this.state.suburbForEdit.totalOrdersSub;
      values.totalDeliveryOrdersNow = this.state.suburbForEdit.totalDeliveryOrdersNow;
    } else {
      values.date = getCurrentFormattedMomentDate(
        this.props.timeZone,
        'YYYY-MM-DD HH:mm'
      );
      values.dateTimestamp = getCurrentTimestamp();
      values.totalOrders = 0;
      values.popularity = 0;
      values.averageDeliveryTime = 0;
      values.averageOrderSpent = 0;
      values.totalDeliveryTime = 0;
      values.currentDay = getCurrentFormattedMomentDate(
        this.props.timeZone,
        'YYYY-MM-DD'
      );
      values.past24Hours = 0;
      values.totalCustomers = 0;
      values.totalRevenueSub = 0;
      values.totalOrdersSub = 0;
      values.totalDeliveryOrdersNow = 0;
    }
    this.props.addSuburbHandler(_.omit(values, _.isUndefined));
    this.props.closeSuburbModal();
    this.props.load({});
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div>
        <div className='modal-body'>
          <div className='col-md-12'>
            <div className='text-center'>
              <Form
                className='form-horizontal group-border-dashed'
                onSubmit={handleSubmit(this.handleSubmit)}
              >
                <Field
                  type='text'
                  placeholder='Suburb Name'
                  labelText='Suburb Name'
                  name='name'
                  component={renderField}
                />
                <Field
                  type='text'
                  labelText='Delivery Cost'
                  placeholder='Delivery Cost'
                  name='deliveryCost'
                  currency={this.props.currency}
                  component={renderMoneyField}
                />
                <Field
                  type='text'
                  labelText='Min. Order'
                  placeholder='20'
                  name='minOrderValue'
                  currency={this.props.currency}
                  component={renderMoneyField}
                />
                <div className='row xs-pt-15'>
                  <div className='col-xs-6' />
                  <div className='col-xs-6'>
                    <p className='text-right'>
                      <button
                        onClick={this.props.closeSuburbModal}
                        disabled={submitting}
                        type='button'
                        data-dismiss='modal'
                        className='btn btn-default btn-space'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        disabled={submitting}
                        className='btn btn-primary btn-space'
                      >
                        Save
                      </button>
                    </p>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SuburbForm.propTypes = {
  addSuburbHandler: PropTypes.any,
  closeSuburbModal: PropTypes.any,
  isEditModeOn: PropTypes.any,
  suburbForEdit: PropTypes.any,
};

SuburbForm = reduxForm({
  form: 'suburbForm',
  enableReinitialize: true,
  validate,
  warn,
  initialValues: { deliveryCost: '0', minOrderValue: '0' },
})(SuburbForm);

// You have to connect() to any reducers that you wish to connect to yourself
SuburbForm = connect(
  (state) => ({
    initialValues: state.suburbFormReducer ? state.suburbFormReducer.data : {}, // pull initial values from account reducer
  }),
  { load: loadSuburb } // bind account loading action creator
)(SuburbForm);

export default SuburbForm;
