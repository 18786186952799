import React, { Component } from 'react';
import { convertToMomentDate } from '../../helpers/timeHelper';

class VoucherSMSBox extends Component {
  constructor(props) {
    super(props);
    this.state = { isValidFormData: true, message: '', isNumberValid: false };
  }

  componentDidMount() {
    if (this.props.voucher) {
      let discount =
        this.props.voucher.voucherType === '1'
          ? `${this.props.voucher.discount}%`
          : `${this.props.currency}${this.props.voucher.discount}`;
      let message = `Get flat ${discount} discount on ordering  food from ${
        this.props.store.name
      }. Your voucher code is ${this.props.voucher.voucherCode}. Visit ${
        this.props.store.baseUrl
      } to place an order. Valid till ${convertToMomentDate(
        this.props.voucher.endDate,
        'YYYY-MM-DD HH:mm',
        this.props.timeZone
      ).format('MMM DD, YYYY')}.`;
      this.setState({ message });
    }
  }

  mobileNumberChange = (e) => {
    const mobileRegex = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    if (mobileRegex.test(e.target.value)) {
      this.setState({
        customerMobileNumber: e.target.value,
        isNumberValid: true,
        isValidFormData: true,
      });
    } else {
      this.setState({
        customerMobileNumber: e.target.value,
        isNumberValid: false,
        isValidFormData: false,
      });
    }
  };

  messageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  sendSMS = () => {
    if (
      this.state.customerMobileNumber &&
      this.state.isNumberValid &&
      this.state.message
    ) {
      this.props.sendSMS(this.state.customerMobileNumber, this.state.message);
    } else {
      this.setState({
        isNumberValid: false,
        isValidFormData: false,
      });
    }
  };

  getDiscountedItemsDescription = (voucher) => {
    if (voucher.type === '3' && voucher.selectedCategories) {
      let string = '';
      voucher.selectedCategories.map((cat, i) => {
        i === 0
          ? (string = cat.catName)
          : (string = `${string}, ${cat.catName}`);
        string = `${string} (${cat.menuSizes
          .filter((s) => s.isActive)
          .map((s) => s.name)
          .join(',')})`;
        return cat;
      });
      return string;
    } else return 'N/A';
  };

  getVoucherAvailability = (item) => {
    let returnString = '';
    let dayStringArray = [];
    if (item.Monday) dayStringArray.push('Mon');
    if (item.Tuesday) dayStringArray.push('Tue');
    if (item.Wednesday) dayStringArray.push('Wed');
    if (item.Thursday) dayStringArray.push('Thu');
    if (item.Friday) dayStringArray.push('Fri');
    if (item.Saturday) dayStringArray.push('Sat');
    if (item.Sunday) dayStringArray.push('Sun');

    if (dayStringArray.length !== 7) {
      returnString =
        returnString.length !== 0
          ? returnString +
            dayStringArray[0] +
            ' To ' +
            dayStringArray[dayStringArray.length - 1]
          : returnString +
            dayStringArray[0] +
            ' To ' +
            dayStringArray[dayStringArray.length - 1];

      if (!item.isAvailableFullDay && item.type !== '2')
        returnString =
          returnString + ` (${item.startTimeDisplay} - ${item.endTimeDisplay})`;
    } else {
      returnString = 'Mon To Sun';
      if (!item.isAvailableFullDay && item.type !== '2')
        returnString =
          returnString + ` (${item.startTimeDisplay} - ${item.endTimeDisplay})`;
    }
    return returnString;
  };

  render() {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='panel'>
            <div className='panel-heading panel-heading-divider bline'>
              Voucher
            </div>
            <div className='panel-body'>
              <div className='col-md-12'>
                <form
                  action='#'
                  className='form-horizontal group-border-dashed'
                >
                  <div className='form-group'>
                    <label className='col-md-3 control-label'>
                      Voucher Code:
                    </label>
                    <div className='col-md-8' style={{ textAlign: 'left' }}>
                      <label className='control-label'>
                        {this.props.voucher.voucherCode}
                      </label>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='col-md-3 control-label'>Type:</label>
                    <div className='col-md-8' style={{ textAlign: 'left' }}>
                      <label className='control-label'>
                        {this.props.voucher.type === '1' ? `Promocode` : ''}
                        {this.props.voucher.type === '2'
                          ? `Voucher(${this.props.voucher.firstName} ${this.props.voucher.lastName}, ${this.props.voucher.mobileNumber})`
                          : ''}
                        {this.props.voucher.type === '3'
                          ? `Discounted Items - ${this.getDiscountedItemsDescription(
                              this.props.voucher
                            )}`
                          : ''}
                      </label>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='col-md-3 control-label'>
                      {this.props.voucher.type !== '3'
                        ? `Discount:`
                        : `Discounted Price:`}
                    </label>
                    <div className='col-md-8' style={{ textAlign: 'left' }}>
                      <label className='control-label'>
                        {this.props.voucher.type !== '3'
                          ? this.props.voucher.voucherType === '1'
                            ? `${this.props.voucher.discount}%`
                            : `${this.props.currency}${this.props.voucher.discount}`
                          : `${this.props.currency}${this.props.voucher.discountedPrice}`}
                      </label>
                    </div>
                  </div>
                  {this.props.voucher.type !== '3' && (
                    <React.Fragment>
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>
                          Validity:
                        </label>
                        <div className='col-md-8' style={{ textAlign: 'left' }}>
                          <label className='control-label'>
                            {`${convertToMomentDate(
                              this.props.voucher.startDate,
                              'YYYY-MM-DD HH:mm'
                            ).format('MMM DD, YYYY')} - 
                              ${convertToMomentDate(
                                this.props.voucher.endDate,
                                'YYYY-MM-DD HH:mm'
                              ).format('MMM DD, YYYY')} `}
                          </label>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className='form-group'>
                    <label className='col-md-3 control-label'>
                      Availability:
                    </label>
                    <div className='col-md-8' style={{ textAlign: 'left' }}>
                      <label className='control-label'>
                        {this.getVoucherAvailability(this.props.voucher)}
                      </label>
                    </div>
                  </div>
                  {(this.props.voucher.type === '1' ||
                    this.props.voucher.type === '3') &&
                  this.props.voucher.isExusiveForNewCustomers ? (
                    <React.Fragment>
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>
                          For New Customers Only?
                        </label>
                        <div className='col-md-8' style={{ textAlign: 'left' }}>
                          <label className='control-label'>
                            (Only for new customer)
                          </label>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-md-3 control-label'>
                          For Single Time Use(Per User)?
                        </label>
                        <div className='col-md-8' style={{ textAlign: 'left' }}>
                          <label className='control-label'>
                            (Single use per user)
                          </label>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  <div className='form-group'>
                    <label className='col-md-3 control-label'>
                      Min. Order Value:
                    </label>
                    <div className='col-md-8' style={{ textAlign: 'left' }}>
                      <label className='control-label'>
                        ${this.props.voucher.minOrderValue}
                      </label>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='col-md-3 control-label'>
                      Max. Users:
                    </label>
                    <div className='col-md-8' style={{ textAlign: 'left' }}>
                      <label className='control-label'>
                        {this.props.voucher.maxOrders}
                      </label>
                    </div>
                  </div>
                  {/* <div className='form-group'>
                    <label className='col-md-3 control-label'>Message</label>
                    <div className='col-md-8' style={{ textAlign: 'left' }}>
                      <textarea
                        className='form-control'
                        rows='4'
                        cols='50'
                        onChange={e => this.messageChange(e)}
                        value={this.state.message}
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='col-md-3 control-label'>
                      Mobile Number
                    </label>
                    <div className='col-md-4'>
                      <input
                        value={this.state.customerMobileNumber}
                        onChange={e => this.mobileNumberChange(e)}
                        className='form-control'
                        type='text'
                      />
                      {!this.state.isValidFormData && (
                        <ul
                          className='parsley-errors-list filled'
                          id='parsley-id-7'
                        >
                          <li
                            style={{ fontSize: '13px' }}
                            className='parsley-type'
                          >
                            Invalid mobile number.
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='col-md-3 control-label' />
                    <div className='col-md-8' style={{ textAlign: 'left' }}>
                      <button
                        onClick={this.sendSMS}
                        type='button'
                        data-dismiss='modal'
                        className='btn btn-primary md-close'
                      >
                        Send SMS
                      </button>
                    </div>
                  </div>*/}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VoucherSMSBox.propTypes = {};

export default VoucherSMSBox;
