import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
  getPaymentReportOrders,
  getPaymentReportOtherOrders,
  getPaymentReportTxtMsgs,
  getPaymentReportReservations,
} from './actions';
import { convertToFormattedMomentDate } from '../../helpers/timeHelper';

class ReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = { openTab: 0 };
  }

  componentDidMount() {
    if (this.props.selectedReport) {
      this.props.getPaymentReportOrdersHandler(this.props.selectedReport._id);
      this.props.getPaymentReportOtherOrdersHandler(
        this.props.selectedReport._id
      );
      this.props.getPaymentReportReservationsHandler(
        this.props.selectedReport._id
      );
      this.props.getPaymentReportTxtMsgsHandler(this.props.selectedReport._id);
    }
  }

  changeTabIndex = (index) => {
    this.setState({ openTab: index });
  };

  getCardDes = (c) => {
    if (c === 2) return 'AMEX';
    else if (c === 5) return 'MasterCard';
    else if (c === 6) return 'Visa';
    else return '';
  };
  render() {
    let _report = this.props.selectedReport;
    let _orders = this.props.reportModal.reportOrders
      ? this.props.reportModal.reportOrders
      : [];
    let _otherOrders = this.props.reportModal.reportOtherOrders
      ? this.props.reportModal.reportOtherOrders
      : [];
    let _txtMsgs = this.props.reportModal.reportTxtMsgs
      ? this.props.reportModal.reportTxtMsgs
      : [];
    return (
      <div>
        <Modal
          aria-labelledby='modal-label'
          className='modal fade'
          show={this.props.isDisplayReport}
          onHide={this.props.closeReportModal}
          bsSize='large'
          dialogClassName='payment-dialog'
        >
          <div className='modal-header'>
            <button
              onClick={this.props.closeReportModal}
              type='button'
              data-dismiss='modal'
              aria-hidden='true'
              className='close'
            >
              <span className='mdi mdi-close' />
            </button>
          </div>
          <div className='col-md-12 fuelux'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='invoice'>
                  <div className='row invoice-header'>
                    <div className='col-xs-7'>
                      <div className='invoice-logo' />
                    </div>
                    <div className='col-xs-5 invoice-order'>
                      <span className='invoice-id'>Payment Report</span>
                      <span className='incoice-date'>
                        {convertToFormattedMomentDate(
                          this.props.selectedReport.date,
                          'YYYY-MM-DD HH:mm',
                          this.props.timeZone,
                          'MMM DD, YYYY'
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='row invoice-data'>
                    <div className='col-xs-5 invoice-person'>
                      <span className='name'>
                        Total amount
                        <b className='paddingLeft'>${_report.totalAmount}</b>
                      </span>
                      <span>
                        Card payments
                        <b className='paddingLeft'>${_report.cardPayments}</b>
                      </span>
                      <span>
                        Cash payments
                        <b className='paddingLeft'>${_report.cashPayments}</b>
                      </span>
                      <span>
                        Total other deliveries
                        <b className='paddingLeft'>{_otherOrders.length}</b>
                      </span>
                      <span>
                        Total text messages
                        <b className='paddingLeft'>{_txtMsgs.length}</b>
                      </span>
                    </div>
                    <div className='col-xs-2 invoice-payment-direction'>
                      <i className='icon mdi mdi-chevron-right' />
                    </div>
                    <div className='col-xs-5 invoice-person'>
                      <span className='name'>
                        Payable amount
                        <b className='paddingLeft'>
                          ${_report.amountTransfered}
                        </b>
                      </span>
                      <span>
                        Commision
                        <b className='paddingLeft'>${_report.commision}</b>
                      </span>
                      <span>
                        Credit card processing
                        <b className='paddingLeft'>
                          ${_report.creditCardProcessingFees}
                        </b>
                      </span>
                      <span>
                        Other delivery charges
                        <b className='paddingLeft'>
                          ${_report.otherDeliveryCharges}
                        </b>
                      </span>
                      <span>
                        Text message charges
                        <b className='paddingLeft'>
                          ${_report.textMessagesFees}
                        </b>
                      </span>
                      <span>
                        Delivery Charges
                        <b className='paddingLeft'>
                          $
                          {_report.deliveryCharges
                            ? _report.deliveryCharges
                            : 0}
                        </b>
                      </span>
                      <span>
                        Reservation Charges
                        <b className='paddingLeft'>
                          $
                          {_report.reservationCharges
                            ? _report.reservationCharges
                            : 0}
                        </b>
                      </span>
                      <span>
                        Subscription Charges
                        <b className='paddingLeft'>
                          $
                          {_report.subscriptionCharge
                            ? _report.subscriptionCharge
                            : 0}
                        </b>
                      </span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      {_orders && (
                        <table className='invoice-details'>
                          <tbody>
                            <tr>
                              <th style={{ width: '10%' }}>Time</th>
                              <th style={{ width: '18%' }}>Customer</th>
                              <th style={{ width: '20%' }}>Order Type</th>
                              <th style={{ width: '12%' }}>Payment</th>
                              <th style={{ width: '12%' }}>Method</th>
                              <th style={{ width: '14%' }}>Commision</th>
                              <th style={{ width: '14%' }}>Online fees</th>
                            </tr>
                            {_orders.map((p) => (
                              <tr>
                                <td className='description'>{p.timeDisplay}</td>
                                <td className='amount'>{p.userName}</td>
                                <td className='amount'>{` ${
                                  p.orderType === '1' ? 'Pickup' : 'Delivery'
                                } - ${p.deliveryCode}`}</td>
                                <td className='amount'>
                                  ${p.payableAmountDisplay}
                                </td>
                                <td className='amount'>
                                  {p.paymentType == '2'
                                    ? `${this.getCardDes(p.cardType)}${
                                        _report.isInternationCard
                                          ? '( - I)'
                                          : ''
                                      }`
                                    : `Cash`}
                                </td>
                                <td className='amount'>
                                  ${Number(p.commision).toFixed(2)}
                                </td>
                                <td className='amount'>
                                  {p.paymentType == '2'
                                    ? `${this.props.currency}${Number(
                                        p.creditCardProcessingFees +
                                          (_report.isExemptedForAdditionaCardFees
                                            ? 0
                                            : p.additionalOnlineProcessingCharge
                                            ? p.additionalOnlineProcessingCharge
                                            : 0)
                                      ).toFixed(2)}`
                                    : ''}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td />
                              <td />
                              <td />
                              <td className='summary'>Total</td>
                              <td className='amount'>${_report.totalAmount}</td>
                              <td className='amount'>${_report.commision}</td>
                              <td className='amount'>
                                $
                                {(
                                  Number(_report.creditCardProcessingFees) +
                                  Number(
                                    _report.isExemptedForAdditionaCardFees
                                      ? 0
                                      : _report.additionalOnlineProcessingCharge
                                  )
                                ).toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ReportModal.propTypes = {
  isDisplayReport: PropTypes.any,
  closeReportModal: PropTypes.any,
  selectedReport: PropTypes.any,
  timeZone: PropTypes.any,
  storeId: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    reportModal: state.reportModalReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPaymentReportOrdersHandler: (reportId) =>
      dispatch(getPaymentReportOrders(reportId)),
    getPaymentReportOtherOrdersHandler: (reportId) =>
      dispatch(getPaymentReportOtherOrders(reportId)),
    getPaymentReportTxtMsgsHandler: (reportId) =>
      dispatch(getPaymentReportTxtMsgs(reportId)),
    getPaymentReportReservationsHandler: (storeId) =>
      dispatch(getPaymentReportReservations(storeId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportModal);
