import { firestore } from '../../firebase';
import ActionTypes from './constants';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';

export function getOrdersCount(storeId, dateToday) {
  return (dispatch) => {
    let ref = firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('orderType', '==', '2')
      .where('dateTimestamp', '>=', dateToday);
    ref.onSnapshot((querySnapshot) => {
      dispatch(fetchedCurrentOrdersCount(querySnapshot.docs.length));
    });
  };
}

export function getOrders(storeId, pagination, dateToday) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('Orders')
      .where('storeId', '==', storeId)
      .where('orderType', '==', '2')
      .where('dateTimestamp', '>=', dateToday)
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('Orders')
          .where('storeId', '==', storeId)
          .where('orderType', '==', '2')
          .where('dateTimestamp', '>=', dateToday)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(ordersFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('Orders')
          .where('storeId', '==', storeId)
          .where('orderType', '==', '2')
          .where('dateTimestamp', '>=', dateToday)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(ordersFetched(dataArray));
        });
      }
    });
  };
}

export function updateOrder(order, status, storeId, restaurantId, timeZone) {
  return (_dispatch) => {
    firestore
      .collection('Orders')
      .doc(order._id)
      .update({ orderStatus: status })
      .then(() => {});
    let orderHistory = {
      orderId: order._id,
      userId: order.userId,
      orderStatus: status,
      orderType: order.orderType,
      deliveryDate: order.deliveryDate,
      deliveryDateTimestamp: order.deliveryDateTimestamp,
      driverId: '',
      date: getCurrentFormattedMomentDate(timeZone, 'YYYY-MM-DD HH:mm'),
      dateTimestamp: getCurrentTimestamp(),
      storeId: storeId,
      restaurantId: restaurantId,
    };
    firestore
      .collection('OrderStatusHistory')
      .add(orderHistory)
      .then(() => {});
  };
}

export function getOrderStats(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('OrderStatsByDay')
      .where('storeId', '==', storeId);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedOrderStats(dataArray));
    });
  };
}

function fetchedCurrentOrdersCount(totalCount) {
  return {
    type: ActionTypes.FetchCurrentOrderCount,
    totalCount,
  };
}

function ordersFetched(currentOrders) {
  return {
    type: ActionTypes.FetchOrders,
    currentOrders,
  };
}

function fetchedOrderStats(orderStats) {
  return {
    type: ActionTypes.FetchOrderStats,
    orderStats,
  };
}
