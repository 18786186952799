import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IngredientCategoryForm from '../IngredientCategoryForm';
import IngredientForm from '../IngredientForm';
import WarningModal from '../WarningModal';
import './index.css';

class IngredientsWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIngredientModal: false,
      showIngredientCategoryModal: false,
      openCategoryIng: 0,
      showAddcategoryForm: false,
      isValidationError: false,
      validationMessage: '',
      selectedIngredientForEdit: '',
      selectedIngredients: [],
    };
  }

  componentDidMount() {
    let myCategories = JSON.parse(
      JSON.stringify(this.props.ingredientCategories)
    );
    myCategories.map((item) => {
      return (item.ingredients = this.props.ingredients.filter((obj) => {
        return obj.ingredientCategory === item._id;
      }));
    });
    let selectedIngredients = this.state.selectedIngredients;
    if (this.props.menuItem && this.props.menuItem.selectedIngredients) {
      this.props.menuItem.selectedIngredients.map((m) => {
        let existingItems = selectedIngredients.filter((p) => {
          return p._id === m._id;
        });
        if (existingItems.length <= 0) selectedIngredients.push(m);
      });
    }
    myCategories.map((item) => {
      item.ingredients.map((m) => {
        let filteredIngs = selectedIngredients.filter((obj) => {
          return obj._id === m._id;
        });
        if (filteredIngs.length > 0) m.isSelected = true;
        else m.isSelected = false;
        return m;
      });
      return item;
    });
    this.setState({
      ingredientCategories: myCategories,
      selectedIngredients,
    });
  }

  componentWillReceiveProps(props) {
    let myCategories = JSON.parse(JSON.stringify(props.ingredientCategories));
    myCategories.map((item) => {
      return (item.ingredients = props.ingredients.filter((obj) => {
        return obj.ingredientCategory === item._id;
      }));
    });
    let selectedIngredients = this.state.selectedIngredients;
    if (props.menuItem && props.menuItem.selectedIngredients) {
      props.menuItem.selectedIngredients.map((m) => {
        let existingItems = selectedIngredients.filter((p) => {
          return p._id === m._id;
        });
        if (existingItems.length <= 0) selectedIngredients.push(m);
      });
    }
    myCategories.map((item) => {
      item.ingredients.map((m) => {
        let filteredIngs = selectedIngredients.filter((obj) => {
          return obj._id === m._id;
        });
        if (filteredIngs.length > 0) m.isSelected = true;
        else m.isSelected = false;
        return m;
      });
      return item;
    });
    this.setState({
      ingredientCategories: myCategories,
      selectedIngredients,
    });
  }

  openIngredientDelete = (item) => {
    this.setState({ showIngredientModal: true, ingItemTobeDeleted: item });
  };

  openCategoryDelete = (e, item) => {
    e.stopPropagation();
    this.setState({ showIngredientCategoryModal: true, itemTobeDeleted: item });
  };

  closeWarnings = () => {
    this.setState({
      showIngredientModal: false,
      showIngredientCategoryModal: false,
    });
  };

  changeTabEx = (index) => {
    this.setState({ openCategoryEx: index });
  };

  changeTabIng = (index) => {
    this.setState({ openCategoryIng: index });
  };

  removeIngredient = () => {
    this.setState({ showIngredientModal: false }, () => {
      this.props.deleteIngredientHandler(this.state.ingItemTobeDeleted);
    });
  };

  deleteCategory = () => {
    this.setState(
      {
        showIngredientCategoryModal: false,
        openCategoryEx: 0,
      },
      () => {
        this.props.deleteIngredientCategoryHandler(this.state.itemTobeDeleted);
      }
    );
  };

  addCategory = () => {
    this.setState({ showAddcategoryForm: true });
  };

  closeCategoryForm = () => {
    this.setState({ showAddcategoryForm: false });
  };

  addIngredientCategoryHandler = (category) => {
    this.setState({ showAddcategoryForm: false });
    this.props.addIngredientCategoryHandler(category);
  };

  addRemoveIngredient = (e, mObj, itemObj) => {
    let myCategories = JSON.parse(
      JSON.stringify(this.state.ingredientCategories)
    );
    if (e.target.checked) {
      let list = [];
      itemObj.ingredients = itemObj.ingredients.map((p) => {
        let g = { ...p };
        if (p._id === mObj._id) g.isSelected = true;
        list.push(g);
        return p;
      });
      itemObj.ingredients = list;
      myCategories = myCategories.map((item) => {
        if (itemObj._id === item._id) {
          item = { ...itemObj };
        }
        return item;
      });
      let selectedIngredients = this.state.selectedIngredients;
      selectedIngredients.push(mObj);
      this.setState({
        ingredientCategories: myCategories,
        selectedIngredients,
      });
    } else {
      let list = [];
      itemObj.ingredients = itemObj.ingredients.map((p) => {
        let g = { ...p };
        if (p._id === mObj._id) g.isSelected = false;
        list.push(g);
        return p;
      });
      itemObj.ingredients = list;
      myCategories = myCategories.map((item) => {
        if (itemObj._id === item._id) {
          item = { ...itemObj };
        }
        return item;
      });
      let selectedIngredients = this.state.selectedIngredients.filter((m) => {
        return m._id !== mObj._id;
      });
      this.setState({
        ingredientCategories: myCategories,
        selectedIngredients,
      });
    }
  };

  nextPage = () => {
    let ingredientList = [];
    let myCategories = JSON.parse(
      JSON.stringify(this.state.ingredientCategories)
    );
    myCategories.map((item, index) => {
      item.ingredients = item.ingredients.map((m) => {
        if (m.isSelected === true) ingredientList.push(m);
        return m;
      });
      return item;
    });
    this.props.setSelectedIngredients(ingredientList);
  };

  removeAlert = () => {
    this.setState({ isValidationError: false, validationMessage: '' });
  };

  editPriceForIngredient = (ingredient) => {
    this.setState({
      selectedIngredientForEdit: ingredient._id,
      priceForEditedIngredient: ingredient.price,
      nameForEditedIngredient: ingredient.ingredientName,
    });
  };

  handlePriceChange = (e) => {
    this.setState({
      priceForEditedIngredient: e.target.value,
    });
  };

  handleNameChange = (e) => {
    this.setState({
      nameForEditedIngredient: e.target.value,
    });
  };

  cancelPriceChangeForIngredient = () => {
    this.setState({
      selectedIngredientForEdit: '',
    });
  };

  savePriceForIngredient = (ingredient) => {
    this.setState(
      {
        selectedIngredientForEdit: '',
      },
      () => {
        this.props.updatePriceHandler(
          ingredient,
          this.state.priceForEditedIngredient,
          this.state.nameForEditedIngredient
        );
      }
    );
  };

  render() {
    const style14 = { float: 'right' };
    const style15 = { float: 'left' };
    return (
      <div>
        <div className='form-group mt3'>
          <label className='col-sm-10 control-label sidepanellable'>
            <p>{`Select ingredients for "${this.props.menuItem.name}"`}</p>
          </label>
        </div>
        <div className='form-group mt3'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='tab-container'>
                {this.state && this.state.ingredientCategories && (
                  <ul className='nav nav-tabs navMheight'>
                    {this.state.ingredientCategories.map((item, index) => (
                      <li
                        onClick={() => this.changeTabIng(index)}
                        key={index}
                        className={
                          this.state.openCategoryIng === index
                            ? 'active alightop'
                            : 'alightop'
                        }
                      >
                        <p className='fleft'>
                          <a data-toggle='tab' style={{ borderBottom: 'none' }}>
                            <span className='lablemle'>{item.name}</span>
                          </a>
                        </p>
                        <button
                          onClick={(e) => this.openCategoryDelete(e, item)}
                          type='button'
                          className='close floatRight'
                        >
                          <span className='mdi mdi-close' />
                        </button>
                      </li>
                    ))}
                    <li className='alightop mleft'>
                      <button
                        onClick={() => this.addCategory()}
                        type='button'
                        className='close floatRight'
                      >
                        <span className='mdi mdi-plus' />
                      </button>
                    </li>
                  </ul>
                )}
                <div className='tab-content'>
                  {this.state.showAddcategoryForm && (
                    <div className='row'>
                      <IngredientCategoryForm
                        addIngredientCategoryHandler={
                          this.addIngredientCategoryHandler
                        }
                        closeCategoryForm={this.closeCategoryForm}
                        timeZone={this.props.timeZone}
                      />
                    </div>
                  )}
                  {this.state &&
                    this.state.ingredientCategories &&
                    this.state.ingredientCategories.map((item, index) => (
                      <div
                        key={index}
                        className={
                          this.state.openCategoryIng === index
                            ? 'tab-pane active cont'
                            : 'tab-pane cont'
                        }
                      >
                        {item.ingredients && (
                          <div>
                            {item.ingredients.map((m, i) => (
                              <div key={i} className='be-checkbox'>
                                <input
                                  checked={m.isSelected}
                                  onChange={(e) =>
                                    this.addRemoveIngredient(e, m, item)
                                  }
                                  id={`${m._id}`}
                                  name={`${m._id}`}
                                  type='checkbox'
                                />
                                <label htmlFor={`${m._id}`}>
                                  {this.state.selectedIngredientForEdit ===
                                  m._id ? (
                                    <React.Fragment>
                                      <span className='tebdo'>
                                        <input
                                          value={
                                            this.state.nameForEditedIngredient
                                          }
                                          onChange={this.handleNameChange}
                                          style={{
                                            maxWidth: '136px',
                                            marginRight: '29px',
                                          }}
                                        />
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <span className='tebcheck'>
                                      {m.ingredientName}
                                    </span>
                                  )}
                                </label>
                                {this.state.selectedIngredientForEdit ===
                                m._id ? (
                                  <React.Fragment>
                                    <span className='tebdo'>
                                      <input
                                        value={
                                          this.state.priceForEditedIngredient
                                        }
                                        onChange={this.handlePriceChange}
                                        style={{ maxWidth: '56px' }}
                                      />
                                    </span>
                                  </React.Fragment>
                                ) : (
                                  <span className='tebdo'>{`${this.props.currency}${m.price}`}</span>
                                )}
                                <span>
                                  <button
                                    onClick={() => this.openIngredientDelete(m)}
                                    type='button'
                                    className='btn teb-btn-space11 btn-default '
                                  >
                                    Delete
                                  </button>
                                </span>
                                <span style={{ margin: '0 15px' }}>
                                  <button
                                    onClick={() =>
                                      this.editPriceForIngredient(m)
                                    }
                                    type='button'
                                    className='btn teb-btn-space11 btn-default '
                                  >
                                    Edit
                                  </button>
                                </span>
                                {this.state.selectedIngredientForEdit ===
                                  m._id && (
                                  <React.Fragment>
                                    <span style={{ margin: '0 15px 0 20px' }}>
                                      <button
                                        onClick={() =>
                                          this.savePriceForIngredient(m)
                                        }
                                        type='button'
                                        className='btn teb-btn-space11 btn-primary '
                                      >
                                        Save
                                      </button>
                                    </span>
                                    <span>
                                      <button
                                        onClick={() =>
                                          this.cancelPriceChangeForIngredient(m)
                                        }
                                        type='button'
                                        className='btn teb-btn-space11 btn-default '
                                      >
                                        Cancel
                                      </button>
                                    </span>
                                  </React.Fragment>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='clear' />
        {this.state.ingredientCategories && (
          <IngredientForm
            addIngredientHandler={this.props.addIngredientHandler}
            ingredientCategories={this.state.ingredientCategories}
            timeZone={this.props.timeZone}
            currency={this.props.currency}
          />
        )}
        <div className='clear' />
        <div className='clear' />
        <div className='form-group'>
          <button
            style={style15}
            onClick={this.props.previousPage}
            className='btn btn-default btn-space'
          >
            <i className='icon mdi mdi-chevron-left' />
            <i className='icon mdi mdi-chevron-left' /> Previous
          </button>
          <button
            style={style14}
            onClick={this.nextPage}
            data-wizard='#wizard1'
            className='btn btn-primary btn-space wizard-next'
          >
            Next Step <i className='icon mdi mdi-chevron-right' />
            <i className='icon mdi mdi-chevron-right' />
          </button>
        </div>
        <div className='col-sm-12'>
          <div className='col-md-6' />
          <div className='col-md-6'>
            {this.state.isValidationError && (
              <div
                role='alert'
                className='alert alert-contrast alert-danger alert-dismissible'
              >
                <div className='icon'>
                  <span className='mdi mdi-close-circle-o' />
                </div>
                <div className='message'>
                  <button
                    onClick={this.removeAlert}
                    type='button'
                    data-dismiss='alert'
                    aria-label='Close'
                    className='close'
                  >
                    <span aria-hidden='true' className='mdi mdi-close' />
                  </button>
                  <strong>Danger!</strong> {this.state.validationMessage}
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.showIngredientModal && (
          <WarningModal
            procced={this.removeIngredient}
            closeModal={this.closeWarnings}
            message={`This will delete ingredient. Click "proceed" to continue.`}
          />
        )}
        {this.state.showIngredientCategoryModal && (
          <WarningModal
            procced={this.deleteCategory}
            closeModal={this.closeWarnings}
            message={`This will delete category. Click "proceed" to continue.`}
          />
        )}
      </div>
    );
  }
}

IngredientsWizard.propTypes = {
  nextPage: PropTypes.any,
  previousPage: PropTypes.any,
  menuItem: PropTypes.any,
  ingredientCategories: PropTypes.any,
  ingredients: PropTypes.any,
  deleteIngredientCategoryHandler: PropTypes.any,
  addIngredientCategoryHandler: PropTypes.any,
  addIngredientHandler: PropTypes.any,
  deleteIngredientHandler: PropTypes.any,
  setSelectedIngredients: PropTypes.any,
  selectedCategory: PropTypes.any,
  updateSelectedMenuItem: PropTypes.any,
};

export default IngredientsWizard;
