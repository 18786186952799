import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import Layout from '../../containers/Layout';
import { auth, firestore } from '../../firebase';
import OrderModal from '../../components/OrderModal';
import getOrderStatus from '../../helpers/getOrderStatus';
import {
  getTimestampStartOfTheDay,
  convertToMomentDate,
} from '../../helpers/timeHelper';
import Spinner from '../../components/Spinner';
import { getOrders, getOrdersCount, updateOrder } from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';
import InvoiceModal from '../../components/InvoiceModal';
import RefundModal from '../../components/RefundModal';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class CurrentDeliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      isDisplayOrder: false,
      selectedOrder: {},
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/delivery/current-deliveries'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getOrdersHandler(
                      doc.data().storeId,
                      this.state.pagination,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    this.props.getOrdersCountHandler(
                      doc.data().storeId,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      currentOrders: newProps.order.currentOrders,
      totalCount: newProps.order.totalCount,
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        currentOrders: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getOrdersHandler(
          this.state.storeId,
          this.state.pagination,
          getTimestampStartOfTheDay(this.state.timeZone)
        );
      }
    );
  };

  openOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOrder: true });
  };

  closeOrderModal = () => {
    this.setState({ isDisplayOrder: false, selectedOrder: {} });
  };

  closeRefundPopup = () => {
    this.setState({
      openRefundPopup: false,
    });
  };

  refundOrder = (order) => {
    this.setState({
      openRefundPopup: true,
      refundOrder: order,
    });
  };

  checkOrderDateDiff = (dateTimestamp) => {
    let now = new Date();
    let currentTimestamp = now.getTime();
    let timestampDiff = currentTimestamp - dateTimestamp;
    let daysDifference = Math.floor(timestampDiff / 1000 / 60 / 60 / 24);
    if (daysDifference > 7) return false;
    return true;
  };

  renderRefundStatus = (order) => {
    if (
      (order.orderStatus === '2' ||
        order.orderStatus === '25' ||
        order.orderStatus === '3' ||
        order.orderStatus === '12' ||
        order.orderStatus === '13' ||
        order.orderStatus === '4') &&
      order.paymentType === '2' &&
      order.refundStatus !== 0 &&
      order.refundStatus !== 1 &&
      order.refundStatus !== 2 &&
      order.refundStatus !== 3 &&
      this.checkOrderDateDiff(order.dateTimestamp)
    ) {
      return (
        <button
          onClick={() => this.refundOrder(order)}
          className='btn btn-space btn-success'
        >
          Refund
        </button>
      );
    } else if (order.refundStatus === 0) {
      return (
        <React.Fragment>
          <span>Refund Initiated</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 1) {
      return (
        <React.Fragment>
          <span>Refunded</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 2) {
      return (
        <React.Fragment>
          <span>Refund Failed</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 3) {
      return (
        <React.Fragment>
          <span>Refund Rejected</span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span></span>
        </React.Fragment>
      );
    }
  };

  closeInvoiceModal = () => {
    this.setState({
      renderInvoiceModal: false,
      invoiceOrder: {},
    });
  };

  openInvoiceModal = (order) => {
    this.setState({
      renderInvoiceModal: true,
      invoiceOrder: order,
    });
  };

  renderSendInvoice = (order) => {
    if (order.orderStatus === '0' || order.orderStatus === '11') {
      return <div></div>;
    } else {
      return (
        <button
          onClick={() => this.openInvoiceModal(order)}
          className='btn btn-space btn-primary'
        >
          Send Invoice
        </button>
      );
    }
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    let reasons =
      this.props.layout && this.props.layout.reasons
        ? this.props.layout.reasons
        : [];
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.currentOrders
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.currentOrders.length -
        1
      : 0;
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className='panel panel-default panel-table '>
                <div className='panel-heading'>Current Deliveries</div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                          <thead>
                            <tr>
                              <th>Order Address</th>
                              <th>Status</th>
                              <th>Order Spend</th>
                              <th>Expected Delivery Time</th>
                              <th>Code</th>
                              <th />
                            </tr>
                          </thead>
                          {!this.state.currentOrders ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : this.state.currentOrders.length > 0 ? (
                            <tbody>
                              {this.state.currentOrders &&
                                this.state.currentOrders.map((order, index) => (
                                  <tr key={index}>
                                    <td>{order.address}</td>
                                    <td>
                                      {getOrderStatus(order.orderStatus, '2')}
                                    </td>
                                    <td>{`${currency}${order.payableAmount}`}</td>
                                    <td>
                                      {convertToMomentDate(
                                        order.deliveryDate,
                                        'YYYY-MM-DD HH:mm',
                                        this.state.timeZone
                                      ).format('MMM DD, YYYY')}
                                      <br />
                                      <span className='grayc'>
                                        {convertToMomentDate(
                                          order.deliveryDate,
                                          'YYYY-MM-DD HH:mm',
                                          this.state.timeZone
                                        ).format('hh:mm a')}
                                      </span>
                                    </td>
                                    <td>{order.deliveryCode}</td>
                                    <td className='text-right'>
                                      <button
                                        onClick={() =>
                                          this.openOrderModal(order)
                                        }
                                        className='btn btn-space btn-default'
                                      >
                                        View
                                      </button>
                                      {order.orderStatus === '0' && (
                                        <button
                                          onClick={() =>
                                            this.props.updateOrderHandler(
                                              order,
                                              '2',
                                              this.state.storeId,
                                              this.state.restaurantId,
                                              this.state.timeZone
                                            )
                                          }
                                          className='btn btn-space btn-default'
                                        >
                                          Confirm
                                        </button>
                                      )}
                                      {order.orderStatus === '0' && (
                                        <button
                                          onClick={() =>
                                            this.props.updateOrderHandler(
                                              order,
                                              '11',
                                              this.state.storeId,
                                              this.state.restaurantId,
                                              this.state.timeZone
                                            )
                                          }
                                          className='btn btn-space btn-default'
                                        >
                                          Cancel
                                        </button>
                                      )}
                                      {this.renderSendInvoice(order)}
                                      {this.renderRefundStatus(order)}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td>
                                  <p> No records to display</p>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12 xy'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.state.totalCount ? this.state.totalCount : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isDisplayOrder && (
            <OrderModal
              isDisplayOrder={this.state.isDisplayOrder}
              closeOrderModal={this.closeOrderModal}
              selectedOrder={this.state.selectedOrder}
              timeZone={this.state.timeZone}
              storeId={this.state.storeId}
              currency={currency}
            />
          )}
          {this.state.openRefundPopup && (
            <RefundModal
              openRefundPopup={this.state.openRefundPopup}
              closeRefundPopup={this.closeRefundPopup}
              order={this.state.refundOrder}
              timeZone={this.state.timeZone}
              accessToken={this.state.accessToken}
              currency={currency}
              reasons={reasons}
            />
          )}
          {this.state.renderInvoiceModal && (
            <InvoiceModal
              renderInvoiceModal={this.state.renderInvoiceModal}
              closeInvoiceModal={this.closeInvoiceModal}
              order={this.state.invoiceOrder}
            />
          )}
        </div>
      </Layout>
    );
  }
}

CurrentDeliveries.propTypes = {};

function mapStateToProps(state) {
  return {
    order: state.orderReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrdersHandler: (storeId, pagination, dateToday) =>
      dispatch(getOrders(storeId, pagination, dateToday)),
    getOrdersCountHandler: (storeId, dateToday) =>
      dispatch(getOrdersCount(storeId, dateToday)),
    updateOrderHandler: (order, status, storeId, restaurantId, timeZone) =>
      dispatch(updateOrder(order, status, storeId, restaurantId, timeZone)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentDeliveries);
