import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchCategories:
      return { ...state, categories: payload.categories };
    case ActionTypes.FetchItemSizes:
      return { ...state, categoryItemSizes: payload.categories };
    case ActionTypes.FetchedIngredientCategories:
      return { ...state, ingredientCategories: payload.ingredientCategories };
    case ActionTypes.FetchedIngredients:
      return { ...state, ingredients: payload.ingredients };
    case ActionTypes.FetchedSizes:
      return { ...state, sizes: payload.sizes };
    case ActionTypes.FetchMenuItems:
      return { ...state, menuItems: payload.menuItems };
    case ActionTypes.MenuItemAdded:
      return { ...state, menuItems: [...state.menuItems] };
    case ActionTypes.SizesFetched:
      return { ...state, allSizes: payload.allSizes };
    default:
      return state;
  }
};
