import React, { Component } from 'react';
import $ from 'jquery';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { Pie, Line } from 'react-chartjs-2';
import randomColor from 'randomcolor';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import Ratings from '../../components/Ratings';
import Spinner from '../../components/Spinner';
import '../../assets/lib/daterangepicker/js/daterangepicker.js';
import '../../assets/lib/daterangepicker/css/daterangepicker.css';
import {
  convertToMomentDate,
  getTimestampStartOfTheDay,
  getTimestampStartOfTheYesterDay,
  getTimestampForDateStart,
  getTimestampForDateEnd,
} from '../../helpers/timeHelper';
import {
  getOrders,
  getOtherOrders,
  getLastDayOrders,
  getOrderHistory,
  getStoreStats,
  getReviewStats,
  getResetStats,
  getSalesStatsSuburb,
  getSalesStatsCategories,
} from './actions';
import { getAllSuburbs } from '../DeliveryAreas/actions';
import { getCategories } from '../Menu/actions';
import { getReviewsToday } from '../Reviews/actions';

import { nextOrderConfig } from '../../configurations/storeSetup';
import moment from 'moment-timezone';

import GooglePlay from '../../assets/images/google-play.png';
import AppStore from '../../assets/images/app-store.png';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateSelectionActive: false,
      isResultExpectedForRestaurant: false,
      focusedInput: null,
    };
  }

  componentDidMount() {
    $('#reportrange span').html(
      moment().format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY')
    );
    auth.onAuthStateChanged((user) => {
      if (!user) {
        window.location = '/restaurants/login';
      } else {
        firestore
          .collection('SystemUsers')
          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                    endDate: moment(),
                    startDate: moment(),
                    user: doc.data(),
                  },
                  () => {
                    this.props.getOrdersHandler(
                      doc.data().storeId,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    this.props.getOtherOrdersHandler(
                      doc.data().storeId,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    this.props.getLastDayOrdersHandler(
                      doc.data().storeId,
                      getTimestampStartOfTheYesterDay(doc.data().timeZone),
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    this.props.getCategoriesHandler(doc.data().storeId);
                    this.props.getAllSuburbsHandler(doc.data().storeId);
                    this.props.getReviewsTodayHandler(
                      doc.data().storeId,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    $('#reportrange').daterangepicker(
                      {
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                        ranges: {
                          Today: [moment(), moment()],
                          Yesterday: [
                            moment().add(-1, 'day'),
                            moment().add(-1, 'day'),
                          ],
                          'This Week': [
                            moment().startOf('week'),
                            moment().endOf('week'),
                          ],
                          'Last 7 Days': [
                            moment().subtract(6, 'days'),
                            moment(),
                          ],
                          'Last 30 Days': [
                            moment().subtract(29, 'days'),
                            moment(),
                          ],
                          'This Month': [
                            moment().startOf('month'),
                            moment().endOf('month'),
                          ],
                          'Last Month': [
                            moment().subtract(1, 'month').startOf('month'),
                            moment().subtract(1, 'month').endOf('month'),
                          ],
                        },
                      },
                      this.dateChangeFilter
                    );
                    this.dateChangeFilter(
                      this.state.startDate,
                      this.state.endDate
                    );
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
        user.getIdToken(true).then((idToken) => {
          this.setState({
            accessToken: idToken,
          });
        });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.dashboard.currentOrders) {
      let currentOrders = newProps.dashboard.currentOrders.filter((order) => {
        return order.orderStatus !== '0' && order.orderStatus !== '11';
      });
      let deliveryOrders = newProps.dashboard.currentOrders.filter((order) => {
        return (
          order.orderStatus !== '0' &&
          order.orderStatus !== '11' &&
          order.orderType === '2'
        );
      });
      let orderStats = {};
      let totalRevenue = 0,
        delCount = 0,
        totalMinutes = 0;
      let dur = 0;
      currentOrders.map((order) => {
        totalRevenue = totalRevenue + Number(order.payableAmount);
        if (
          order.orderStatus === '4' &&
          !order.isFutureDeliveryDate &&
          order.deliveredAt &&
          order.dateTimestamp
        ) {
          let duration = order.deliveredAt - order.dateTimestamp;
          if (duration > 0) {
            totalMinutes =
              totalMinutes + parseInt(Number(duration / 60000), 10);
            delCount = delCount + 1;
          }
        }
        return order;
      });
      let averageDeliveryTime =
        delCount > 0 ? parseInt(totalMinutes / delCount, 10) : 0;
      if (Number(averageDeliveryTime) > 60) {
        dur = `${parseInt(averageDeliveryTime / 60, 10)}h ${parseInt(
          averageDeliveryTime % 60,
          10
        )} minutes`;
      } else {
        dur =
          averageDeliveryTime !== 0 ? `${averageDeliveryTime} minutes` : 'N/A';
      }

      // Today's sell statistics
      orderStats = {
        totalRevenue: totalRevenue.toFixed(2),
        totalOrders: currentOrders.length,
        averageOrder:
          currentOrders.length !== 0
            ? (totalRevenue / currentOrders.length).toFixed(2)
            : 0,
        averageDeliveryTime: dur,
      };

      this.setState({
        orderStats,
        currentOrders,
      });
      if (newProps.suburb.allSuburbs) {
        let suburbs = newProps.suburb.allSuburbs;
        suburbs.map((s) => {
          s.orders = deliveryOrders.filter((c) => {
            return c.suburbId === s._id;
          });
          return s;
        });
        this.setState({
          allSuburbs: suburbs,
          deliveryOrders,
        });
      }
      if (newProps.menu.categories) {
        let allCategories = newProps.menu.categories;
        allCategories.map((item) => {
          item.orders = [];
          if (currentOrders) {
            currentOrders.map((obj) => {
              obj.menuItems.filter((m) => {
                if (m.categoryId === item._id) {
                  item.orders.push(m);
                }
                return m;
              });
              return obj;
            });
          }
          return item;
        });
        this.setState({
          categories: allCategories,
        });
      }
    }
    console.log(newProps.dashboard.otherOrders);
    this.setState({
      storeOrderStats: newProps.dashboard.storeOrderStats,
      otherOrders: newProps.dashboard.otherOrders,
      reviewStats: newProps.dashboard.reviewStats,
      storeOrderStatsCategories: newProps.dashboard.storeOrderStatsCategories,
      storeOrderStatsSuburb: newProps.dashboard.storeOrderStatsSuburb,
    });
  }

  isInt = (value) => {
    var er = /^-?[0-9]+$/;
    return er.test(value);
  };

  getAvgRating = () => {
    if (this.state.dateSelectionActive) {
      let reviewObj = { key: Math.random(), rating: 'N/A' };
      if (this.state.reviewStats) {
        reviewObj.rating = parseInt(this.state.reviewStats.averageRating, 10);
      } else {
        reviewObj.rating = 0;
      }
      return reviewObj;
    } else {
      if (this.props.review.recentReviews) {
        const reviews = this.props.review.recentReviews;
        let reviewObj = { key: Math.random(), rating: 'N/A' };
        if (reviews.length > 0) {
          reviewObj.key = reviews[0]._id;
          let rat = 0,
            avgRat = 0;
          reviews.map((item) => {
            rat = rat + Number(item.rating);
            return item;
          });
          avgRat = reviews.length !== 0 ? rat / reviews.length : 0;
          if (!this.isInt(avgRat)) {
            avgRat = avgRat.toFixed(1);
            avgRat = Math.ceil(avgRat);
          }
          reviewObj.rating = avgRat;
        }
        return reviewObj;
      }
    }
  };

  getAvgRatingPercentage = (rating) => {
    if (this.state.dateSelectionActive) {
      if (this.state.reviewStats && this.state.reviewStats.totalReviews > 0) {
        let ratings = 0;
        if (rating === 1) ratings = this.state.reviewStats.total1s;
        else if (rating === 2) ratings = this.state.reviewStats.total2s;
        else if (rating === 3) ratings = this.state.reviewStats.total3s;
        else if (rating === 4) ratings = this.state.reviewStats.total4s;
        else if (rating === 5) ratings = this.state.reviewStats.total5s;

        return parseInt(
          (ratings * 100) / this.state.reviewStats.totalReviews,
          10
        );
      } else return 0;
    } else {
      if (this.props.review.recentReviews) {
        const reviewPickups = this.props.review.recentReviews.filter((obj) => {
          return obj.rating === rating;
        });
        if (reviewPickups && reviewPickups.length > 0) {
          return parseInt(
            (reviewPickups.length * 100) /
              this.props.review.recentReviews.length,
            10
          );
        } else return 0;
      } else return 0;
    }
  };

  proccessPDGraph = () => {
    const pdBreakdown = {
      labels: ['Pickup', 'Delivery'],
      datasets: [
        {
          data: [],
          backgroundColor: ['#4285f4', '#f40cca'],
          hoverBackgroundColor: ['#4285f4', '#f40cca'],
        },
      ],
    };
    if (this.state.dateSelectionActive) {
      if (this.state.storeOrderStats) {
        pdBreakdown.datasets[0].data.push(
          this.state.storeOrderStats.totalPickupOrders
        );
        pdBreakdown.datasets[0].data.push(
          this.state.storeOrderStats.totalDeliveryOrders
        );
      }
    } else {
      if (this.state.currentOrders) {
        let pickUpds = this.state.currentOrders.filter((obj) => {
          return obj.orderType === '1';
        });
        let deliveries = this.state.currentOrders.filter((obj) => {
          return obj.orderType === '2';
        });
        pdBreakdown.datasets[0].data.push(pickUpds.length);
        pdBreakdown.datasets[0].data.push(deliveries.length);
      }
    }
    return pdBreakdown;
  };

  proccessENGraph = () => {
    const cBreakdown = {
      labels: ['Existing Customers(Orders)', 'New Customers(Orders)'],
      datasets: [
        {
          data: [],
          backgroundColor: ['#4BC0C0', '#f40cca'],
          hoverBackgroundColor: ['#4BC0C0', '#f40cca'],
        },
      ],
    };
    if (this.state.dateSelectionActive) {
      if (this.state.storeOrderStats) {
        cBreakdown.datasets[0].data.push(
          this.state.storeOrderStats.oldCustomers
        );
        cBreakdown.datasets[0].data.push(
          this.state.storeOrderStats.newCustomers
        );
      }
    } else {
      if (this.state.currentOrders) {
        let newCust = this.state.currentOrders.filter((obj) => {
          return obj.isNewCustomer === true;
        });
        let oldCust = this.state.currentOrders.filter((obj) => {
          return obj.isNewCustomer === false;
        });
        cBreakdown.datasets[0].data.push(oldCust.length);
        cBreakdown.datasets[0].data.push(newCust.length);
      }
    }
    return cBreakdown;
  };

  proccessCCGraph = () => {
    const ccBreakdown = {
      labels: ['Total Cash Amount', 'Total Card Amount'],
      datasets: [
        {
          data: [],
          backgroundColor: ['#FF6384', '#36A2EB'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB'],
        },
      ],
    };
    if (this.state.dateSelectionActive) {
      if (this.state.storeOrderStats) {
        ccBreakdown.datasets[0].data.push(
          this.state.storeOrderStats.totalCashOrders
        );
        ccBreakdown.datasets[0].data.push(
          this.state.storeOrderStats.totalCardOrders
        );
      }
    } else {
      if (this.state.currentOrders) {
        let cashPays = this.state.currentOrders.filter((obj) => {
          return obj.paymentType === '1';
        });
        if (cashPays.length > 0) {
          let cashAmt = 0;
          cashPays.map((ord) => {
            cashAmt = cashAmt + Number(ord.payableAmount);
            return ord;
          });
          ccBreakdown.datasets[0].data.push(Number(cashAmt).toFixed(2));
        } else {
          ccBreakdown.datasets[0].data.push(0);
        }
        let cardPays = this.state.currentOrders.filter((obj) => {
          return obj.paymentType === '2';
        });
        if (cardPays.length > 0) {
          let cardAmt = 0;
          cardPays.map((ord) => {
            cardAmt = cardAmt + Number(ord.payableAmount);
            return ord;
          });
          ccBreakdown.datasets[0].data.push(Number(cardAmt).toFixed(2));
        } else {
          ccBreakdown.datasets[0].data.push(0);
        }
      }
    }
    return ccBreakdown;
  };

  processMCGraph = () => {
    const mcBreakdown = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };
    if (this.state.dateSelectionActive) {
      if (
        this.state.storeOrderStatsCategories &&
        this.state.storeOrderStatsCategories.allCategories
      ) {
        this.state.storeOrderStatsCategories.allCategories.map((cat) => {
          mcBreakdown.labels.push(cat.catName);
          if (cat.orders && cat.orders.length > 0) {
            mcBreakdown.datasets[0].data.push(cat.orders.length);
          } else {
            mcBreakdown.datasets[0].data.push(0);
          }
          let randomC = randomColor({});
          mcBreakdown.datasets[0].backgroundColor.push(randomC);
          mcBreakdown.datasets[0].hoverBackgroundColor.push(randomC);
          return cat;
        });
      }
    } else {
      if (this.state.categories) {
        this.state.categories.map((cat) => {
          mcBreakdown.labels.push(cat.catName);
          if (cat.orders && cat.orders.length > 0) {
            mcBreakdown.datasets[0].data.push(cat.orders.length);
          } else {
            mcBreakdown.datasets[0].data.push(0);
          }
          let randomC = randomColor({});
          mcBreakdown.datasets[0].backgroundColor.push(randomC);
          mcBreakdown.datasets[0].hoverBackgroundColor.push(randomC);
          return cat;
        });
      }
    }
    return mcBreakdown;
  };

  processRevenueGraph = () => {
    let datas = [
      '0',
      '4',
      '8',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
    ];
    const lineCtd = {
      labels: [
        '0',
        '4 AM',
        '8 AM',
        '10 AM',
        '11 AM',
        '12 PM',
        '1 PM',
        '2 PM',
        '3 PM',
        '4 PM',
        '5 PM',
        '6 PM',
        '7 PM',
        '8 PM',
        '9 PM',
        '10 PM',
        '11 PM',
        '12 PM',
      ],
      datasets: [
        {
          label: 'Today',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgb(255, 206, 86)',
          borderColor: 'rgb(255, 206, 86)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(4, 5, 4)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(255, 206, 86)',
          pointHoverBorderColor: 'rgb(255, 206, 86)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [],
        },
        {
          label: 'Yesterday',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgb(255, 99, 132)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(4, 5, 4)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(255, 99, 132)',
          pointHoverBorderColor: 'rgb(255, 99, 132)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [],
        },
      ],
    };
    if (this.state.currentOrders) {
      datas.map((m) => {
        let ordersUptoMe = this.state.currentOrders.filter((obj) => {
          return (
            Number(
              convertToMomentDate(
                obj.date,
                'YYYY-MM-DD HH:mm',
                this.state.timeZone
              ).format('HH')
            ) < Number(m)
          );
        });
        let rev = 0;
        if (ordersUptoMe && ordersUptoMe.length > 0) {
          ordersUptoMe.map((p) => {
            rev = rev + Number(p.payableAmount);
            return rev;
          });
        }
        lineCtd.datasets[0].data.push(Number(rev).toFixed(2));
        return m;
      });
    }
    if (this.props.dashboard.lastDayOrders) {
      datas.map((m) => {
        let ordersUptoMe = this.props.dashboard.lastDayOrders.filter((obj) => {
          return (
            Number(
              convertToMomentDate(
                obj.date,
                'YYYY-MM-DD HH:mm',
                this.state.timeZone
              ).format('HH')
            ) < Number(m)
          );
        });
        let rev = 0;
        if (ordersUptoMe && ordersUptoMe.length > 0) {
          ordersUptoMe.map((p) => {
            rev = rev + Number(p.payableAmount);
            return rev;
          });
        }
        lineCtd.datasets[1].data.push(Number(rev).toFixed(2));
        return m;
      });
    }
    return lineCtd;
  };

  getLabel = (tooltipItems, data) => {
    return data.labels[tooltipItems[0].index];
  };

  getLabelData = (tooltipItem, data) => {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    return `${currency}${data.datasets[0].data[tooltipItem.index]}`;
  };

  getLabelLine = (tooltipItems, data) => {
    return data.labels[tooltipItems[0].index];
  };

  getLabelDataLine = (tooltipItem, data) => {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    return `Revenue:${currency}${
      data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
    }`;
  };

  goLive = () => {
    this.setState(
      {
        dateSelectionActive: false,
        storeOrderStats: undefined,
        reviewStats: false,
        startDate: moment(),
        endDate: moment(),
      },
      () => {
        $('#reportrange span').html(
          moment().format('MMMM D, YYYY') +
            ' - ' +
            moment().format('MMMM D, YYYY')
        );
        this.props.getResetStats();
      }
    );
  };

  dateChangeFilter = (startDate, endDate) => {
    this.setState(
      {
        dateSelectionActive:
          startDate &&
          endDate &&
          startDate.isSame(endDate, 'day') &&
          moment().isSame(endDate, 'day')
            ? false
            : true,
        startDate,
        endDate,
        storeOrderStats: undefined,
        reviewStats: undefined,
      },
      () => {
        this.props.getResetStats();
        if (startDate && endDate) {
          if (
            !(
              startDate.isSame(endDate, 'day') &&
              moment().isSame(endDate, 'day')
            )
          ) {
            let requestObj = {
              startDateTimestamp: getTimestampForDateStart(
                this.state.startDate
              ),
              endDateTimestamp: getTimestampForDateEnd(this.state.endDate),
              storeId: this.state.storeId,
              restaurantId: this.state.restaurantId,
              isResultExpectedForRestaurant: this.state
                .isResultExpectedForRestaurant,
            };
            let requestObjSub = {
              startDateTimestamp: getTimestampForDateStart(
                this.state.startDate
              ),
              endDateTimestamp: getTimestampForDateEnd(this.state.endDate),
              storeId: this.state.storeId,
              restaurantId: this.state.restaurantId,
              suburbs: this.props.suburb.allSuburbs,

              isResultExpectedForRestaurant: this.state
                .isResultExpectedForRestaurant,
            };
            let requestObjCat = {
              startDateTimestamp: getTimestampForDateStart(
                this.state.startDate
              ),
              endDateTimestamp: getTimestampForDateEnd(this.state.endDate),
              storeId: this.state.storeId,
              restaurantId: this.state.restaurantId,

              categories: this.props.menu.categories,
              isResultExpectedForRestaurant: this.state
                .isResultExpectedForRestaurant,
            };
            this.props.getStoreStatsHandler(requestObj, this.state.accessToken);
            this.props.getSalesStatsSuburbHandler(
              requestObjSub,
              this.state.accessToken
            );
            this.props.getStoreStatsCategoriesHandler(
              requestObjCat,
              this.state.accessToken
            );
            this.props.getReviewStatsHandler(
              requestObj,
              this.state.accessToken
            );
          }
          $('#reportrange span').html(
            startDate.format('MMMM D, YYYY') +
              ' - ' +
              endDate.format('MMMM D, YYYY')
          );
        }
      }
    );
  };

  getData = (type) => {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    if (type === 'totalRevenue') {
      if (this.state.dateSelectionActive) {
        if (
          this.state.storeOrderStats &&
          this.state.storeOrderStats.totalRevenue
        )
          return `${currency}${this.state.storeOrderStats.totalRevenue}`;
        else return 'N/A';
      } else {
        if (this.state.orderStats && this.state.orderStats.totalRevenue)
          return `${currency}${this.state.orderStats.totalRevenue}`;
        else return 'N/A';
      }
    } else if (type === 'averageOrder') {
      if (this.state.dateSelectionActive) {
        if (
          this.state.storeOrderStats &&
          this.state.storeOrderStats.averageOrder
        )
          return `${currency}${this.state.storeOrderStats.averageOrder}`;
        else return 'N/A';
      } else {
        if (this.state.orderStats && this.state.orderStats.averageOrder)
          return `${currency}${this.state.orderStats.averageOrder}`;
        else return 'N/A';
      }
    } else if (type === 'totalOrders') {
      if (this.state.dateSelectionActive) {
        if (
          this.state.storeOrderStats &&
          this.state.storeOrderStats.totalOrders
        )
          return `${this.state.storeOrderStats.totalOrders}`;
        else return 'N/A';
      } else {
        if (this.state.orderStats && this.state.orderStats.totalOrders)
          return `${this.state.orderStats.totalOrders}`;
        else return 'N/A';
      }
    } else if (type === 'averageDeliveryTime') {
      if (this.state.dateSelectionActive) {
        if (
          this.state.storeOrderStats &&
          this.state.storeOrderStats.averageDeliveryTime
        )
          return `${this.state.storeOrderStats.averageDeliveryTime}`;
        else return 'N/A';
      } else {
        if (this.state.orderStats && this.state.orderStats.averageDeliveryTime)
          return `${this.state.orderStats.averageDeliveryTime}`;
        else return 'N/A';
      }
    } else if (type === 'otherDeliveries') {
      if (this.state.dateSelectionActive) {
        if (
          this.state.storeOrderStats &&
          this.state.storeOrderStats.otherOrders
        )
          return `${this.state.storeOrderStats.otherOrders}`;
        else return 'N/A';
      } else {
        if (this.state.otherOrders) return `${this.state.otherOrders.length}`;
        else return 'N/A';
      }
    }
  };

  renderSuburbs = () => {
    if (this.state.dateSelectionActive) {
      if (
        this.state.storeOrderStatsSuburb &&
        this.state.storeOrderStatsSuburb.suburbs
      ) {
        return this.state.storeOrderStatsSuburb.suburbs.map((suburb, index) => (
          <div key={index} className='row user-progress user-progress-small'>
            <div className='col-md-4 col-sm-4'>
              <span className='title'>{suburb.name}</span>
            </div>
            <div className='col-md-8 col-sm-8'>
              <div className='progress'>
                <div
                  style={{
                    width: `${
                      suburb.orders &&
                      suburb.orders.length > 0 &&
                      this.state.storeOrderStatsSuburb.totalDeliveryOrders > 0
                        ? parseInt(
                            (suburb.orders.length * 100) /
                              this.state.storeOrderStatsSuburb
                                .totalDeliveryOrders,
                            10
                          )
                        : 0
                    }% `,
                  }}
                  className='progress-bar greemc'
                />
              </div>
            </div>
          </div>
        ));
      } else {
        return (
          <div className='row user-progress user-progress-small'>
            <div className='col-md-12 col-sm-12'>
              <div
                style={{ padding: '50px' }}
                className='be-loading be-loading-active'
              >
                <Spinner />
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (this.state.allSuburbs) {
        return this.state.allSuburbs.map((suburb, index) => (
          <div key={index} className='row user-progress user-progress-small'>
            <div className='col-md-4 col-sm-4'>
              <span className='title'>{suburb.name}</span>
            </div>
            <div className='col-md-8 col-sm-8'>
              <div className='progress'>
                <div
                  style={{
                    width: `${
                      suburb.orders &&
                      suburb.orders.length > 0 &&
                      this.state.deliveryOrders.length > 0
                        ? parseInt(
                            (suburb.orders.length * 100) /
                              this.state.deliveryOrders.length,
                            10
                          )
                        : 0
                    }% `,
                  }}
                  className='progress-bar greemc'
                />
              </div>
            </div>
          </div>
        ));
      } else {
        return (
          <div className='row user-progress user-progress-small'>
            <div className='col-md-12 col-sm-12'>
              <div
                style={{ padding: '50px' }}
                className='be-loading be-loading-active'
              >
                <Spinner />
              </div>
            </div>
          </div>
        );
      }
    }
  };

  addDriver = () => {
    let url = `/restaurants/delivery/drivers?restaurant==${this.state.storeId}`;
    window.open(url);
  };

  render() {
    const pdBreakdown = this.proccessPDGraph();
    const cBreakdown = this.proccessENGraph();
    const ccBreakdown = this.proccessCCGraph();
    const mcBreakdown = this.processMCGraph();
    const lineCtd = this.processRevenueGraph();
    let options = {
      responsive: true,
      tooltips: {
        callbacks: {
          title: this.getLabel,
          label: this.getLabelData,
        },
      },
    };
    let optionsLine = {
      responsive: true,
      tooltips: {
        callbacks: {
          title: this.getLabelLine,
          label: this.getLabelDataLine,
        },
      },
    };
    return (
      <Layout>
        <div className='main-content container-fluid'>
          {this.state.user ? (
            <React.Fragment>
              <div className='row'>
                <div className='col-xs-12 col-md-6 col-lg-3'>
                  <div className='widget widget-tile'>
                    <div className='data-info'>
                      <div
                        className='reportrange'
                        id='reportrange'
                        style={{
                          background: '#fff',
                          cursor: 'pointer',
                          padding: '5px 10px',
                          border: '1px solid #ccc',
                          width: '100%',
                        }}
                      >
                        <i className='mdi mdi-calendar' />
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: '300',
                            color: '#585858',
                          }}
                        />
                        <b className='caret' />
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.dateSelectionActive && (
                  <div className='col-xs-12 col-md-6 col-lg-3'>
                    <div className='widget widget-tile'>
                      <div className='data-info'>
                        <button
                          className='btn btn-rounded btn-space btn-success'
                          onClick={this.goLive}
                        >
                          Go Live!
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='row'>
                {this.state.user.userRole === '1' && (
                  <React.Fragment>
                    <div className='col-xs-12 col-md-4 col-lg-3'>
                      <div className='widget widget-tile'>
                        <div className='data-info'>
                          <div className='desc'>
                            {this.state.dateSelectionActive
                              ? `Total Sales`
                              : `Today's Sales`}
                          </div>
                          <div className='value'>
                            <span>
                              {(this.state.currentOrders &&
                                !this.state.dateSelectionActive) ||
                              (this.state.storeOrderStats &&
                                this.state.dateSelectionActive) ? (
                                this.getData('totalRevenue')
                              ) : (
                                <div
                                  style={{ padding: '20px' }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-md-4 col-lg-3'>
                      <div className='widget widget-tile'>
                        <div className='data-info'>
                          <div className='desc'>Average Order</div>
                          <div className='value'>
                            <span>
                              {(this.state.currentOrders &&
                                !this.state.dateSelectionActive) ||
                              (this.state.storeOrderStats &&
                                this.state.dateSelectionActive) ? (
                                this.getData('averageOrder')
                              ) : (
                                <div
                                  style={{ padding: '20px' }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-md-4 col-lg-3'>
                      <div className='widget widget-tile'>
                        <div className='data-info'>
                          <div className='desc'>Today's Total Orders</div>
                          <div className='value'>
                            <span>
                              {(this.state.currentOrders &&
                                !this.state.dateSelectionActive) ||
                              (this.state.storeOrderStats &&
                                this.state.dateSelectionActive) ? (
                                this.getData('totalOrders')
                              ) : (
                                <div
                                  style={{ padding: '20px' }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xs-12 col-md-4 col-lg-3'>
                      <div className='widget widget-tile'>
                        <div className='data-info'>
                          <div className='desc'>Average Delivery Time</div>
                          <div className='value'>
                            <span>
                              {(this.state.currentOrders &&
                                !this.state.dateSelectionActive) ||
                              (this.state.storeOrderStats &&
                                this.state.dateSelectionActive) ? (
                                this.getData('averageDeliveryTime')
                              ) : (
                                <div
                                  style={{ padding: '20px' }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className='col-xs-12 col-md-4 col-lg-3'>
                  <div className='widget widget-tile'>
                    <div className='data-info'>
                      <div className='desc'>
                        {this.state.dateSelectionActive
                          ? `Total Other Deliveries`
                          : `Today's Other Deliveries`}
                      </div>
                      <div className='value'>
                        <span>
                          {(this.state.otherOrders &&
                            !this.state.dateSelectionActive) ||
                          (this.state.storeOrderStats &&
                            this.state.dateSelectionActive) ? (
                            this.getData('otherDeliveries')
                          ) : (
                            <div
                              style={{ padding: '20px' }}
                              className='be-loading be-loading-active'
                            >
                              <Spinner />
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.user.userRole === '1' && (
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='panel panel-default '>
                      <div className='panel-heading panel-heading-divider'>
                        <span className='title'>Pick up vs Delivery</span>
                        <span className='panel-subtitle'>
                          {`Order breakdown for pickup and delivery over ${
                            this.state.dateSelectionActive
                              ? 'selected date range'
                              : 'the past 24 hours'
                          }.`}
                        </span>
                      </div>
                      <div className='divider1' />
                      <div className='panel-body'>
                        {(this.state.currentOrders &&
                          !this.state.dateSelectionActive) ||
                        (this.state.storeOrderStats &&
                          this.state.dateSelectionActive) ? (
                          <Pie data={pdBreakdown} />
                        ) : (
                          <div
                            style={{ padding: '50px' }}
                            className='be-loading be-loading-active'
                          >
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='panel panel-default'>
                      <div className='panel-heading panel-heading-divider'>
                        <span className='title'>
                          {this.state.dateSelectionActive
                            ? 'Customer Breakdown'
                            : `Today's Customer Breakdown`}
                        </span>
                        <span className='panel-subtitle'>
                          {`Order breakdown for new and existing customers over ${
                            this.state.dateSelectionActive
                              ? 'selected date range'
                              : 'the past 24 hours'
                          } .`}
                        </span>
                      </div>
                      <div className='divider1' />
                      <div className='panel-body'>
                        {(this.state.currentOrders &&
                          !this.state.dateSelectionActive) ||
                        (this.state.storeOrderStats &&
                          this.state.dateSelectionActive) ? (
                          <Pie data={cBreakdown} />
                        ) : (
                          <div
                            style={{ padding: '50px' }}
                            className='be-loading be-loading-active'
                          >
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.user.userRole === '1' && (
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <div className='panel panel-default'>
                      <div className='panel-heading panel-heading-divider xs-pb-15'>
                        <span className='title'>Revenue by Hour</span>
                        <span className='panel-subtitle'>
                          Revenue breakdown over the past 24 hours.
                        </span>
                      </div>
                      <div className='divider1' />
                      <div className='panel-body'>
                        {this.state.currentOrders ? (
                          <Line data={lineCtd} options={optionsLine} />
                        ) : (
                          <div
                            style={{ padding: '50px' }}
                            className='be-loading be-loading-active'
                          >
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='panel panel-default'>
                      <div className='panel-heading panel-heading-divider xs-pb-15'>
                        <span className='title'>Average Customer Rating</span>
                        <span className='panel-subtitle'>
                          {`Average customer rating over ${
                            this.state.dateSelectionActive
                              ? 'selected range'
                              : 'the past 24 hours'
                          }.`}
                        </span>
                      </div>
                      <div className='divider1' />
                      <div className='panel-body xs-pt-25'>
                        {(this.props.review.recentReviews &&
                          !this.state.dateSelectionActive) ||
                        (this.state.reviewStats &&
                          this.state.dateSelectionActive) ? (
                          <React.Fragment>
                            <div className='row user-progress user-progress-small'>
                              <div className='hreviewlab1'>
                                <span className='hreviewlab'>
                                  {this.getAvgRating()
                                    ? this.getAvgRating().rating
                                    : '0'}
                                </span>
                                {this.getAvgRating() && (
                                  <Ratings
                                    review={this.getAvgRating()}
                                    rating={
                                      this.getAvgRating()
                                        ? this.getAvgRating().rating
                                        : 0
                                    }
                                  />
                                )}
                              </div>
                              <div className='clear' />
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>5</span>
                              </div>
                              <div className='col-md-10 col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        5
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row user-progress user-progress-small'>
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>4</span>
                              </div>
                              <div className='col-md-10  col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        4
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row user-progress user-progress-small'>
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>3</span>
                              </div>
                              <div className='col-md-10  col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        3
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row user-progress user-progress-small'>
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>2</span>
                              </div>
                              <div className='col-md-10  col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        2
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row user-progress user-progress-small'>
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>1</span>
                              </div>
                              <div className='col-md-10  col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        1
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div
                            style={{ padding: '50px' }}
                            className='be-loading be-loading-active'
                          >
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.user.userRole === '1' && (
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <div className='panel panel-default '>
                      <div className='panel-heading panel-heading-divider'>
                        <span className='title'>Card vs Cash Breakdown </span>
                        <span className='panel-subtitle'>
                          {`Revenue breakdown for cash and card payment over ${
                            this.state.dateSelectionActive
                              ? 'selected date range'
                              : 'the past 24 hours'
                          }.`}
                        </span>
                      </div>
                      <div className='divider1' />
                      <div className='panel-body'>
                        {(this.state.currentOrders &&
                          !this.state.dateSelectionActive) ||
                        (this.state.storeOrderStats &&
                          this.state.dateSelectionActive) ? (
                          <Pie options={options} data={ccBreakdown} />
                        ) : (
                          <div
                            style={{ padding: '50px' }}
                            className='be-loading be-loading-active'
                          >
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='panel panel-default '>
                      <div className='panel-heading panel-heading-divider'>
                        <span className='title'>
                          Today's Menu Category Sales Breakdown
                        </span>
                        <span className='panel-subtitle'>
                          Category wise order breakdown over the past 24 hours.
                        </span>
                      </div>
                      <div className='divider1' />
                      <div className='panel-body'>
                        {(this.state.categories &&
                          !this.state.dateSelectionActive) ||
                        (this.state.storeOrderStatsCategories &&
                          this.state.dateSelectionActive) ? (
                          <Pie data={mcBreakdown} />
                        ) : (
                          <div
                            style={{ padding: '50px' }}
                            className='be-loading be-loading-active'
                          >
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.user.userRole === '1' && (
                <div className='row'>
                  <div className='col-md-6  col-sm-12'>
                    <div className='panel panel-default'>
                      <div className='panel-heading panel-heading-divider xs-pb-15'>
                        Suburb Popularity
                        <span className='panel-subtitle'>
                          Suburb wise delivery order breakdown over the past 24
                          hours.&#36;
                        </span>
                      </div>
                      <div className='divider1' />
                      <div className='panel-body xs-pt-25'>
                        {this.renderSuburbs()}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.user.userRole === '3' && (
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <div className='panel panel-default'>
                      <div className='panel-heading panel-heading-divider xs-pb-15'>
                        <span className='title'>Average Customer Rating</span>
                        <span className='panel-subtitle'>
                          {`Average customer rating over ${
                            this.state.dateSelectionActive
                              ? 'selected range'
                              : 'the past 24 hours'
                          }.`}
                        </span>
                      </div>
                      <div className='divider1' />
                      <div className='panel-body xs-pt-25'>
                        {(this.props.review.recentReviews &&
                          !this.state.dateSelectionActive) ||
                        (this.state.reviewStats &&
                          this.state.dateSelectionActive) ? (
                          <React.Fragment>
                            <div className='row user-progress user-progress-small'>
                              <div className='hreviewlab1'>
                                <span className='hreviewlab'>
                                  {this.getAvgRating()
                                    ? this.getAvgRating().rating
                                    : '0'}
                                </span>
                                {this.getAvgRating() && (
                                  <Ratings
                                    review={this.getAvgRating()}
                                    rating={
                                      this.getAvgRating()
                                        ? this.getAvgRating().rating
                                        : 0
                                    }
                                  />
                                )}
                              </div>
                              <div className='clear' />
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>5</span>
                              </div>
                              <div className='col-md-10 col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        5
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row user-progress user-progress-small'>
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>4</span>
                              </div>
                              <div className='col-md-10  col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        4
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row user-progress user-progress-small'>
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>3</span>
                              </div>
                              <div className='col-md-10  col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        3
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row user-progress user-progress-small'>
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>2</span>
                              </div>
                              <div className='col-md-10  col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        2
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='row user-progress user-progress-small'>
                              <div className='col-md-1 col-sm-1'>
                                <span className='title'>1</span>
                              </div>
                              <div className='col-md-10  col-sm-10'>
                                <div className='progress'>
                                  <div
                                    style={{
                                      width: `${this.getAvgRatingPercentage(
                                        1
                                      )}%`,
                                    }}
                                    className='progress-bar progress-bar-info'
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div
                            style={{ padding: '50px' }}
                            className='be-loading be-loading-active'
                          >
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='row'>
                      <div className='col-md-12 col-sm-12'>
                        <div className='panel panel-default'>
                          <div className='panel-heading panel-heading-divider xs-pb-15'>
                            <span className='title'>
                              Add Drivers & Continue Delivery
                            </span>
                          </div>
                          <div className='divider1' />
                          <div className='panel-body xs-pt-25'>
                            <button
                              type='button'
                              onClick={() => this.addDriver()}
                              className='btn btn-primary btn-xl'
                            >
                              ADD DRIVER
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12 col-sm-12'>
                        <div className='panel panel-default'>
                          <div className='panel-heading panel-heading-divider xs-pb-15'>
                            <span className='title'>
                              All your drivers need is our free app
                            </span>
                            <span className='panel-subtitle'>
                              {`Your drivers download our iOS or Android app and they are on their way. No additional hardware needed.`}
                            </span>
                          </div>
                          <div className='divider1' />
                          <div className='panel-body xs-pt-25'>
                            <ul
                              className='links download-list'
                              style={{ listStyleType: 'none', display: 'flex' }}
                            >
                              <li style={{ marginRight: 20 }}>
                                <a
                                  href='https://play.google.com/store/apps/details?id=com.nextorder.driver&amp;hl=en'
                                  target='_blank'
                                  rel='nofollow'
                                  className='download-google'
                                  title='Next Order Driver'
                                >
                                  <img
                                    alt='google-play'
                                    src={GooglePlay}
                                    style={{ width: 150 }}
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href='https://apps.apple.com/au/app/next-order-driver/id1473512106'
                                  target='_blank'
                                  rel='nofollow'
                                  className='download-app'
                                  title='Next Order Driver'
                                >
                                  <img
                                    alt='app-store'
                                    src={AppStore}
                                    style={{ width: 150 }}
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className='row user-progress user-progress-small'>
              <div className='col-md-12 col-sm-12'>
                <div
                  style={{ padding: '50px' }}
                  className='be-loading be-loading-active'
                >
                  <Spinner />
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

Dashboard.propTypes = {};

function mapStateToProps(state) {
  return {
    dashboard: state.dashboardReducer,
    suburb: state.suburbReducer,
    menu: state.menuReducer,
    review: state.reviewReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrdersHandler: (storeId, dateToday) =>
      dispatch(getOrders(storeId, dateToday)),
    getOtherOrdersHandler: (storeId, dateToday) =>
      dispatch(getOtherOrders(storeId, dateToday)),
    getOrderHistoryHandler: (storeId, dateToday) =>
      dispatch(getOrderHistory(storeId, dateToday)),
    getLastDayOrdersHandler: (storeId, dateYesterday, dateToday) =>
      dispatch(getLastDayOrders(storeId, dateYesterday, dateToday)),
    getAllSuburbsHandler: (storeId) => dispatch(getAllSuburbs(storeId)),
    getCategoriesHandler: (storeId) => dispatch(getCategories(storeId)),
    getReviewsTodayHandler: (storeId, dateToday) =>
      dispatch(getReviewsToday(storeId, dateToday)),
    getStoreStatsHandler: (requestObj, accessToken) =>
      dispatch(getStoreStats(requestObj, accessToken)),
    getSalesStatsSuburbHandler: (requestObj, accessToken) =>
      dispatch(getSalesStatsSuburb(requestObj, accessToken)),
    getStoreStatsCategoriesHandler: (requestObj, accessToken) =>
      dispatch(getSalesStatsCategories(requestObj, accessToken)),
    getReviewStatsHandler: (requestObj, accessToken) =>
      dispatch(getReviewStats(requestObj, accessToken)),
    getResetStats: () => dispatch(getResetStats()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
