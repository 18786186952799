import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.ProductSetupSaved:
      return { ...state, productSetup: payload.productSetup };
    case ActionTypes.OrderSetupSaved:
      return { ...state, orderSetup: payload.orderSetup };
    default:
      return state;
  }
};
