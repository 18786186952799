import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getOtherOrderHistory(storeId, otherDeliveryId) {
  return (dispatch) => {
    let ref = firestore
      .collection('OtherDeliverieStatusHistory')
      .where('storeId', '==', storeId)
      .where('otherDeliveryId', '==', otherDeliveryId);
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedOtherOrderHistory(dataArray));
    });
  };
}
export function getUser(userId) {
  return (dispatch) => {
    let ref = firestore.collection('Users').doc(userId);
    ref.get().then((doc) => {
      let obj = { _id: doc.id, ...doc.data() };
      dispatch(fetchedUser(obj));
    });
  };
}
export function getDriver(driverId) {
  return (dispatch) => {
    let ref = firestore
      .collection('DriverLocations')
      .where('driverId', '==', driverId);
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedDriver(dataArray[0]));
    });
  };
}
export function getDeliveryAddresses(userId) {
  return (dispatch) => {
    let ref = firestore
      .collection('DeliveryAddresses')
      .where('userId', '==', userId);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedDeliveryAddresses(dataArray));
    });
  };
}
function fetchedOtherOrderHistory(otherOrderHistory) {
  return {
    type: ActionTypes.FetchOtherOrderHistory,
    otherOrderHistory,
  };
}
function fetchedUser(user) {
  return {
    type: ActionTypes.FetchUser,
    user,
  };
}
function fetchedDriver(driver) {
  return {
    type: ActionTypes.FetchDriver,
    driver,
  };
}
function fetchedDeliveryAddresses(deliveryAddresses) {
  return {
    type: ActionTypes.FetchDeliveryAddresses,
    deliveryAddresses,
  };
}
