import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import { convertToMomentDate } from '../../helpers/timeHelper';
import getOrderStatus from '../../helpers/getOrderStatus';
import Spinner from '../../components/Spinner';
import Ratings from '../../components/Ratings';
import OtherOrderModal from '../../components/OtherOrderModal';
import { getOtherOrders, getOtherOrdersCount } from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';
import { getStoreReviewStats } from '../Layout/actions';
import { getReviewsStats } from '../../containers/Reviews/actions';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class OtherDeliveryStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      isDisplayOrder: false,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/other-delivery/other-delivery-statistics'
        );
      } else {
        firestore
          .collection('SystemUsers')
          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getOtherOrdersHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getOtherOrdersCountHandler(doc.data().storeId);
                    this.props.getReviewsStatsHandler(doc.data().storeId);
                    this.props.getStoreReviewStatsHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      totalCount: newProps.otherDeliveryStatisticsReducer.totalCount,
      otherDeliveryOrders:
        newProps.otherDeliveryStatisticsReducer.otherDeliveryOrders,
      reviewStats: newProps.review.reviewStats,
      storeReviewStats: newProps.layout.storeReviewStats,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        otherDeliveryOrders: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getOtherOrdersHandler(
          this.state.storeId,
          this.state.pagination
        );
      }
    );
  };

  getAvgRating = () => {
    let reviewObj = { key: '', rating: 'N/A' };
    if (
      this.state.storeReviewStats &&
      this.state.storeReviewStats.averageOtherDeliveryRating
    ) {
      reviewObj.rating = this.state.storeReviewStats.averageOtherDeliveryRating;
      reviewObj.rating = reviewObj.rating.toFixed(1);
      reviewObj.rating = parseInt(reviewObj.rating, 10);
      reviewObj.key = this.state.storeReviewStats.storeId;
    }
    return reviewObj;
  };

  getAvgRatingPercentage = (rating) => {
    if (this.state.reviewStats) {
      let tReviews = 0;
      this.state.reviewStats.map((item) => {
        tReviews = tReviews + item.totalOtherDeliveryReviews;
        return item;
      });
      const reviewPickups = this.state.reviewStats.filter((obj) => {
        return obj.rating === rating;
      });
      if (reviewPickups && reviewPickups.length > 0) {
        return parseInt(
          (reviewPickups[0].totalOtherDeliveryReviews * 100) / tReviews,
          10
        );
      } else return 0;
    } else return 0;
  };

  openOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOrder: true });
  };

  closeOrderModal = () => {
    this.setState({ isDisplayOrder: false, selectedOrder: {} });
  };

  addDriver = () => {
    let url = `/restaurants/delivery/drivers?restaurant==${this.state.storeId}`;
    window.open(url);
  };

  render() {
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.otherDeliveryOrders
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.otherDeliveryOrders.length -
        1
      : 0;

    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-xs-12 col-md-12'>
              <div className='panel panel-default panel-table'>
                <div className='panel-heading'>List of Other Deliveries</div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table2_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table
                          id='table2'
                          className='table table-striped table-hover table-fw-widget dataTable no-footer'
                          role='grid'
                          aria-describedby='table2_info'
                        >
                          <thead>
                            <tr>
                              <th>Order Address</th>
                              <th>Status</th>
                              <th>Mobile Number</th>
                              <th>Date</th>
                              <th />
                            </tr>
                          </thead>
                          {!this.state.otherDeliveryOrders ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : this.state.otherDeliveryOrders.length > 0 ? (
                            <tbody>
                              {this.state.otherDeliveryOrders &&
                                this.state.otherDeliveryOrders.map(
                                  (order, index) => (
                                    <tr key={index}>
                                      <td>{order.address}</td>
                                      <td>
                                        {getOrderStatus(order.orderStatus, '4')}
                                      </td>
                                      <td>{order.mobileNumber}</td>
                                      <td>
                                        {convertToMomentDate(
                                          order.date,
                                          'YYYY-MM-DD HH:mm',
                                          this.state.timeZone
                                        ).format('MMM DD, YYYY')}
                                        <br />
                                        <span className='grayc'>
                                          {convertToMomentDate(
                                            order.date,
                                            'YYYY-MM-DD HH:mm',
                                            this.state.timeZone
                                          ).format('hh:mm a')}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            this.openOrderModal(order)
                                          }
                                          className='btn btn-space btn-default'
                                        >
                                          View
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td>
                                  <p> No records to display</p>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table2_info'
                          role='status'
                          aria-live='polite'
                        >{`Showing ${start} to ${end} of ${
                          this.state.totalCount ? this.state.totalCount : 0
                        } entries`}</div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table2_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={(e) => this.handlePageChange(e)}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel-heading-divider xs-pb-15'>
                  <span className='title'>Average Customer Rating</span>
                </div>
                <div className='divider1' />
                <div className='panel-body xs-pt-25'>
                  <div className='row user-progress user-progress-small'>
                    <div className='hreviewlab1'>
                      <span className='hreviewlab'>
                        {this.state.storeReviewStats
                          ? this.getAvgRating().rating
                          : '0'}
                      </span>
                      {this.state.storeReviewStats && (
                        <Ratings
                          review={this.getAvgRating()}
                          rating={this.getAvgRating().rating}
                        />
                      )}
                    </div>
                    <div className='clear' />
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>5</span>
                    </div>
                    <div className='col-md-10 col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(5)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>4</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(4)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>3</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(3)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>2</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(2)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>1</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(1)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel-heading-divider xs-pb-15'>
                  <span className='title'>Add Drivers & Continue Delivery</span>
                </div>
                <div className='divider1' />
                <div className='panel-body xs-pt-25'>
                  <button
                    type='button'
                    onClick={() => this.addDriver()}
                    className='btn btn-primary btn-xl'
                  >
                    ADD DRIVER
                  </button>
                </div>
              </div>
            </div>
          </div>
          {this.state.isDisplayOrder && (
            <OtherOrderModal
              isDisplayOrder={this.state.isDisplayOrder}
              closeOrderModal={this.closeOrderModal}
              selectedOrder={this.state.selectedOrder}
              timeZone={this.state.timeZone}
              storeId={this.state.storeId}
            />
          )}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    otherDeliveryStatisticsReducer: state.otherDeliveryStatisticsReducer,
    layout: state.layoutReducer,
    review: state.reviewReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOtherOrdersCountHandler: (storeId) =>
      dispatch(getOtherOrdersCount(storeId)),
    getOtherOrdersHandler: (storeId, pagination) =>
      dispatch(getOtherOrders(storeId, pagination)),
    getReviewsStatsHandler: (storeId) => dispatch(getReviewsStats(storeId)),
    getStoreReviewStatsHandler: (storeId) =>
      dispatch(getStoreReviewStats(storeId)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherDeliveryStatistics);
