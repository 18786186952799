import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Amplify, { Storage } from 'aws-amplify';
import ImageCompressor from 'image-compressor.js';
import _ from 'lodash';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';
import './reactTags.css';

Amplify.configure({
  Auth: {
    identityPoolId: 'ap-southeast-2:2c00a523-e1e5-4ef4-99b4-38bb9e85a37a', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: 'lacampa', //REQUIRED -  Amazon S3 bucket
    region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
  },
});

const imageCompressor = new ImageCompressor();

let compressionObj = {
  quality: 0.8,
  convertSize: 10000,
  mimeType: 'jpeg',
  maxWidth: 700,
};

let compressionObjLarge = {
  quality: 0.6,
  convertSize: 10000,
  mimeType: 'jpeg',
  maxWidth: 700,
};

class ProductSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myCategories: [],
      selectedCategories: [],
      halfHalfAdditionalCharges: 0,
      isError: false,
    };
  }

  componentDidMount() {
    if (this.props.sizes && this.props.categories && this.props.productSetup) {
      let myCategories = this.props.categories;
      myCategories.map((cat) => {
        cat.sizes = this.props.sizes.filter((obj) => {
          return obj.categoryId === cat._id;
        });
        return cat;
      });
      this.setState({
        halfHalfAdditionalCharges: this.props.productSetup
          .halfHalfAdditionalCharges
          ? this.props.productSetup.halfHalfAdditionalCharges
          : 0,
        selectedCategories: this.props.productSetup.selectedCategories
          ? this.props.productSetup.selectedCategories
          : [],
        myCategories,
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.sizes && props.categories && props.productSetup) {
      let myCategories = props.categories;
      myCategories.map((cat) => {
        cat.sizes = props.sizes.filter((obj) => {
          return obj.categoryId === cat._id;
        });
        return cat;
      });
      this.setState({
        halfHalfAdditionalCharges: props.productSetup.halfHalfAdditionalCharges
          ? props.productSetup.halfHalfAdditionalCharges
          : 0,
        selectedCategories: props.productSetup.selectedCategories
          ? props.productSetup.selectedCategories
          : [],
        halfHalfPlaceholderImage: props.productSetup.halfHalfPlaceholderImage
          ? props.productSetup.halfHalfPlaceholderImage
          : '',
        myCategories,
      });
    }
  }

  handleDelete = (catKey, key, type) => {
    try {
      if (type === 1) {
        let xItem = this.state.selectedCategories.filter((obj) => {
          return obj._id === catKey;
        });
        if (xItem && xItem.length > 0) {
          let tags = xItem[0].selectedSizes.filter((m) => {
            return m._id !== key;
          });
          if (xItem[0].defaultSizes) {
            let dtags = xItem[0].defaultSizes.filter((m) => {
              return m._id === key;
            });
            if (dtags.length > 0) {
              xItem[0].defaultSizes = [];
            }
          }
          xItem[0].selectedSizes = tags;
          this.state.selectedCategories.map((obj) => {
            if (obj._id === xItem[0]._id) {
              obj = { ...xItem[0] };
            }
            return obj;
          });
          this.setState(this.state);
        }
      } else if (type === 2) {
        let xItem = this.state.selectedCategories.filter((obj) => {
          return obj._id === catKey;
        });
        if (xItem && xItem.length > 0) {
          let tags = xItem[0].defaultSizes.filter((m) => {
            return m._id !== key;
          });
          xItem[0].defaultSizes = tags;
          this.state.selectedCategories.map((obj) => {
            if (obj._id === xItem[0]._id) {
              obj = { ...xItem[0] };
            }
            return obj;
          });
          this.setState(this.state);
        }
      }
    } catch (error) {
      this.props.Sentry.captureException(error);
    }
  };

  handleAddition = (e, catKey, type) => {
    try {
      if (type === 1) {
        let xItem = this.state.selectedCategories.filter((obj) => {
          return obj._id === catKey;
        });
        if (xItem && xItem.length > 0) {
          let tags = xItem[0].selectedSizes;
          let xTag = xItem[0].sizes.filter((obj) => {
            return obj._id === e.target.value;
          });
          if (xTag && xTag.length > 0) {
            if (xItem[0].selectedSizes) {
              let eItem = xItem[0].selectedSizes.filter((m) => {
                return m._id === e.target.value;
              });
              if (eItem.length === 0) {
                xItem[0].selectedSizes = [...tags, xTag[0]];
                this.state.selectedCategories.map((obj) => {
                  if (obj._id === xItem[0]._id) {
                    obj = { ...xItem[0] };
                  }
                  return obj;
                });
                this.setState(this.state);
              }
            } else {
              xItem[0].selectedSizes = [xTag[0]];
              this.state.selectedCategories.map((obj) => {
                if (obj._id === xItem[0]._id) {
                  obj = { ...xItem[0] };
                }
                return obj;
              });
              this.setState(this.state);
            }
          }
        }
      } else {
        let xItem = this.state.selectedCategories.filter((obj) => {
          return obj._id === catKey;
        });
        if (xItem && xItem.length > 0) {
          let xTag = xItem[0].sizes.filter((obj) => {
            return obj._id === e.target.value;
          });
          if (xTag && xTag.length > 0) {
            if (xItem[0].defaultSizes) {
              let eItem = xItem[0].defaultSizes.filter((m) => {
                return m._id === e.target.value;
              });
              if (eItem.length === 0) {
                xItem[0].defaultSizes = [xTag[0]];
                this.state.selectedCategories.map((obj) => {
                  if (obj._id === xItem[0]._id) {
                    obj = { ...xItem[0] };
                  }
                  return obj;
                });
                this.setState(this.state);
              }
            } else {
              xItem[0].defaultSizes = [xTag[0]];
              this.state.selectedCategories.map((obj) => {
                if (obj._id === xItem[0]._id) {
                  obj = { ...xItem[0] };
                }
                return obj;
              });
              this.setState(this.state);
            }
          }
        }
      }
    } catch (error) {
      this.props.Sentry.captureException(error);
    }
  };

  handleSubmit = () => {
    let values = {};
    let errorCount = 0;
    if (
      this.state.selectedCategories &&
      this.state.selectedCategories.length > 0
    ) {
      this.state.selectedCategories.map((m) => {
        if (m.selectedSizes && m.selectedSizes.length <= 0) {
          errorCount = errorCount + 1;
        } else {
          if (m.defaultSizes && m.defaultSizes.length <= 0)
            errorCount = errorCount + 1;
        }
        return m;
      });
    }

    if (errorCount === 0) {
      values.lastUpdatedOn = getCurrentFormattedMomentDate(
        this.props.timeZone,
        'YYYY-MM-DD HH:mm'
      );
      values.lastUpdatedOnTimestamp = getCurrentTimestamp();
      values.selectedCategories = this.state.selectedCategories;
      values.halfHalfAdditionalCharges = parseFloat(
        Number(this.state.halfHalfAdditionalCharges).toFixed(2)
      );
      if (this.props.productSetup && this.props.productSetup._id) {
        values._id = this.props.productSetup._id;
      } else {
        values.date = getCurrentFormattedMomentDate(
          this.props.timeZone,
          'YYYY-MM-DD HH:mm'
        );
        values.dateTimestamp = getCurrentTimestamp();
      }

      if (this.state.halfHalfPlaceholderImage) {
        values.halfHalfPlaceholderImage = this.state.halfHalfPlaceholderImage;
      }

      if (this.state.imageFiles && this.state.imageFiles.length > 0) {
        Storage.put(
          `Half-${this.props.productSetup._id}`,
          this.state.photo
        ).then(() => {
          Storage.get(`Half-${this.props.productSetup._id}`)
            .then((result) => {
              values.halfHalfPlaceholderImage = result.split('?')[0];
              this.props.saveProductSetupHandler(_.omit(values, _.isUndefined));
              this.setState({
                isSuccess: true,
                isError: false,
                validationMessage: 'Product data updated successfully!',
                submitting: false,
              });
            })
            .catch(() => {
              this.setState({
                isError: true,
                isSuccess: false,
                validationMessage: 'Image uploading failed',
                submitting: false,
              });
            });
        });
      } else {
        // No images
        this.props.saveProductSetupHandler(_.omit(values, _.isUndefined));
        this.setState({
          isSuccess: true,
          isError: false,
          validationMessage: 'Product data updated successfully!',
          submitting: false,
        });
      }
    } else {
      this.setState({
        isError: true,
        isSuccess: false,
        validationMessage:
          'Select sizes and defaut size for each selected category',
        submitting: false,
      });
    }
  };

  removeAlert = () => {
    this.setState({ isSuccess: false, isError: false, validationMessage: '' });
  };

  dropDownChange = (e) => {
    let existCategory = this.state.selectedCategories.filter((obj) => {
      return obj._id === e.target.value;
    });
    if (existCategory && existCategory.length > 0) {
      // Category exist
    } else {
      let newCategory = this.props.categories.filter((obj) => {
        return obj._id === e.target.value;
      });
      if (newCategory && newCategory.length > 0) {
        if (newCategory[0].menuItems) delete newCategory[0].menuItems;
        newCategory[0].selectedSizes = [];
        this.state.selectedCategories.push(newCategory[0]);
        this.setState(this.state);
      }
    }
  };

  removeItem = (key) => {
    let existCategory = this.state.selectedCategories.filter((obj) => {
      return obj._id !== key;
    });
    this.setState({ selectedCategories: [...existCategory] });
  };

  valueChange = (e) => {
    this.setState({
      halfHalfAdditionalCharges: e.target.value,
    });
  };

  handleFileChange = (filesToUpload) => {
    filesToUpload.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    imageCompressor
      .compress(filesToUpload[0], compressionObj)
      .then((result) => {
        if (result.size > 140000) {
          imageCompressor
            .compress(result, compressionObjLarge)
            .then((resultOne) => {
              this.setState({
                photo: new File([resultOne], 'name'),
                imageFiles: filesToUpload,
                halfHalfPlaceholderImage: '',
              });
            })
            .catch(() => {});
        } else {
          this.setState({
            photo: new File([result], 'name'),
            imageFiles: filesToUpload,
            halfHalfPlaceholderImage: '',
          });
        }
      })
      .catch(() => {});
  };

  render() {
    return (
      <div className='col-md-12'>
        <form className='form-horizontal'>
          <div className='panel panel-default panel-border-color panel-border-color-primary'>
            <div className='panel-heading panel-heading-divider'>
              Half-Half Setup
              <div className='fright'>
                <button
                  onClick={() => {
                    this.setState({
                      submitting: true,
                    });
                    this.handleSubmit();
                  }}
                  type='button'
                  className='btn btn-space btn-primary'
                  disabled={this.state.submitting}
                >
                  {this.state.submitting ? `Saving...` : `Save`}
                </button>
              </div>
            </div>
            <div className='divider1' />
            <div className='panel-body'>
              <div className='form-group'>
                <label className='col-md-3 col-sm-3 control-label' />
                <div className='col-sm-6 col-md-6'>
                  {this.state.isError && (
                    <div
                      role='alert'
                      className='alert alert-contrast alert-danger alert-dismissible'
                    >
                      <div className='icon'>
                        <span className='mdi mdi-close-circle-o' />
                      </div>
                      <div className='message'>
                        <button
                          onClick={this.removeAlert}
                          type='button'
                          data-dismiss='alert'
                          aria-label='Close'
                          className='close'
                        >
                          <span aria-hidden='true' className='mdi mdi-close' />
                        </button>
                        <strong>Danger!</strong> {this.state.validationMessage}
                      </div>
                    </div>
                  )}
                  {this.state.isSuccess && (
                    <div
                      role='alert'
                      className='alert alert-contrast alert-success alert-dismissible'
                    >
                      <div className='icon'>
                        <span className='mdi mdi-check' />
                      </div>
                      <div className='message'>
                        <button
                          onClick={this.removeAlert}
                          type='button'
                          data-dismiss='alert'
                          aria-label='Close'
                          className='close'
                        >
                          <span aria-hidden='true' className='mdi mdi-close' />
                        </button>
                        <strong>Good!</strong> {this.state.validationMessage}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='form-group'>
                <label className='col-md-3 col-sm-3 control-label'>
                  Allow Half/Half in Categories
                </label>
                <div className='col-md-6 col-sm-6'>
                  <select
                    type='select'
                    onChange={(e) => this.dropDownChange(e)}
                    name='isHalfInCategories'
                    className='form-control'
                  >
                    <option value='0'>Select Category </option>
                    {this.state.myCategories &&
                      this.state.myCategories.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.catName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {this.state.selectedCategories &&
                this.state.selectedCategories.length > 0 && (
                  <div className='form-group'>
                    <label className='col-md-3 col-sm-3 control-label'>
                      Selected Categories
                    </label>
                    <div className='col-md-6 col-sm-6'>
                      {this.state.selectedCategories.map((item, index) => (
                        <div key={index} className='row'>
                          <div className='col-md-12 col-sm-12'>
                            <div className='panel panel-border panel-contrast'>
                              <div
                                style={{
                                  padding: '8px 10px 8px',
                                  fontSize: '15px',
                                }}
                                className='panel-heading panel-heading-contrast'
                              >
                                {item.catName}
                                <div className='tools'>
                                  <span
                                    style={{ fontSize: '15px' }}
                                    onClick={() => this.removeItem(item._id)}
                                    className='icon mdi mdi-close'
                                  />
                                </div>
                              </div>
                              <div className='panel-body'>
                                <div className='ReactTags__tags'>
                                  <div className='ReactTags__selected'>
                                    <div className='ReactTags__tagInput'>
                                      <select
                                        type='select'
                                        onChange={(e) =>
                                          this.handleAddition(e, item._id, 1)
                                        }
                                        name='isHalfInCategories'
                                        className='ReactTags__tagInputField form-control'
                                      >
                                        <option value='0'>Select size </option>
                                        {item.sizes &&
                                          item.sizes.map((item, p) => (
                                            <option key={p} value={item._id}>
                                              {item.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    {item.selectedSizes &&
                                      item.selectedSizes.map((m, i) => (
                                        <span
                                          key={i}
                                          className='ReactTags__tag'
                                          draggable='true'
                                          style={{ opacity: '1' }}
                                        >
                                          {m.name}
                                          <a
                                            onClick={() =>
                                              this.handleDelete(
                                                item._id,
                                                m._id,
                                                1
                                              )
                                            }
                                            className='ReactTags__remove'
                                          >
                                            ×
                                          </a>
                                        </span>
                                      ))}
                                  </div>
                                </div>
                                <div className='ReactTags__tags'>
                                  <div className='ReactTags__selected'>
                                    <div className='ReactTags__tagInput'>
                                      <select
                                        type='select'
                                        onChange={(e) =>
                                          this.handleAddition(e, item._id, 2)
                                        }
                                        name='isHalfInCategories'
                                        className='ReactTags__tagInputField form-control'
                                      >
                                        <option value='0'>
                                          Select default size
                                        </option>
                                        {item.selectedSizes &&
                                          item.selectedSizes.map((item, p) => (
                                            <option key={p} value={item._id}>
                                              {item.name}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    {item.defaultSizes &&
                                      item.defaultSizes.map((m, i) => (
                                        <span
                                          key={i}
                                          className='ReactTags__tag'
                                          draggable='true'
                                          style={{ opacity: '1' }}
                                        >
                                          {m.name}
                                          <a
                                            onClick={() =>
                                              this.handleDelete(
                                                item._id,
                                                m._id,
                                                2
                                              )
                                            }
                                            className='ReactTags__remove'
                                          >
                                            ×
                                          </a>
                                        </span>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              <div className='form-group'>
                <label className='col-md-3 col-sm-3 control-label'>
                  Half/Half Additional Charges
                </label>
                <div className='col-sm-6 col-md-6'>
                  <div className='input-group'>
                    <span className='input-group-addon'>
                      {this.props.currency}
                    </span>
                    <input
                      type='number'
                      min={0}
                      placeholder='Half/Half Additional Charges'
                      name='halfHalfAdditionalCharges'
                      className='form-control'
                      value={this.state.halfHalfAdditionalCharges}
                      onChange={(e) => this.valueChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label className='col-md-3 col-sm-3 control-label'>
                  Half/Half Placeholder Image
                </label>
                <div className='col-sm-6 col-md-6'>
                  <Dropzone
                    className='uplodedImg'
                    name={'photo'}
                    accept='image/*'
                    onDrop={(filesToUpload) => {
                      this.setState({
                        isEditModeOn: false,
                      });
                      this.handleFileChange(filesToUpload);
                    }}
                  >
                    <div
                      style={{
                        marginTop: '12px',
                        display: 'flex',
                      }}
                    >
                      <div>
                        <label
                          htmlFor='photo'
                          className='btn-default'
                          style={{ marginRight: 30, cursor: 'pointer' }}
                        >
                          <i className='mdi mdi-upload' />
                          <span> Browse files...</span>
                        </label>
                      </div>
                      <div>
                        {this.state.isEditModeOn ||
                        this.state.halfHalfPlaceholderImage !== '' ? (
                          <React.Fragment>
                            <img
                              src={this.state.halfHalfPlaceholderImage}
                              height={200}
                              width={200}
                            />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {this.state.imageFiles &&
                              this.state.imageFiles.map((file, index) => {
                                return (
                                  <img
                                    src={file.preview}
                                    key={index}
                                    height={200}
                                    width={200}
                                  />
                                );
                              })}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </Dropzone>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
ProductSetup.propTypes = {
  saveProductSetupHandler: PropTypes.any,
  productSetup: PropTypes.any,
  categories: PropTypes.any,
  sizes: PropTypes.any,
};

export default ProductSetup;
