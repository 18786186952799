import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Pagination from 'react-js-pagination';
import Layout from '../../containers/Layout';
import Spinner from '../../components/Spinner';
import { convertToMomentDate } from '../../helpers/timeHelper';
import { getMenuItems, getMenuItemsCount } from './actions';

import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class ItemStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      sorting: [
        { id: 0, name: 'name', dir: 'asc', class: 'sorting', isSorted: false },
        {
          id: 1,
          name: 'totalOrders',
          dir: 'asc',
          class: 'sorting',
          isSorted: false,
        },
        {
          id: 2,
          name: 'totalRevenue',
          dir: 'desc',
          class: 'sorting_desc',
          isSorted: true,
        },
      ],
      filterString: null,
    };
  }
  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/menu/item-statistics'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getMenuItemsHandler(
                      doc.data().storeId,
                      this.state.pagination,
                      this.state.sorting[2],
                      this.state.filterString
                    );
                    this.props.getMenuItemsCountHandler(
                      doc.data().storeId,
                      this.state.filterString
                    );
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      menuItems: props.item.menuItems,
      totalCount: props.item.totalCount,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        menuItems: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        let sortC = this.state.sorting.filter((m) => {
          return m.isSorted === true;
        });
        if (sortC.length > 0) {
          this.props.getMenuItemsHandler(
            this.state.storeId,
            this.state.pagination,
            sortC[0],
            this.state.filterString
          );
          this.props.getMenuItemsCountHandler(
            this.state.storeId,
            this.state.filterString
          );
        }
      }
    );
  };

  filterMenuItems = (e) => {
    this.setState(
      {
        filterString: e.target.value,
      },
      () => {
        let sortC = this.state.sorting.filter((m) => {
          return m.isSorted === true;
        });
        if (sortC.length > 0) {
          this.props.getMenuItemsHandler(
            this.state.storeId,
            this.state.pagination,
            sortC[0],
            this.state.filterString
          );
          this.props.getMenuItemsCountHandler(
            this.state.storeId,
            this.state.filterString
          );
        }
      }
    );
  };

  sortMe = (i) => {
    this.state.sorting.map((s) => {
      if (s.id === i) {
        if (s.dir === '') {
          s.dir = 'desc';
          s.class = 'sorting_desc';
          s.isSorted = true;
        } else if (s.dir === 'asc') {
          s.dir = 'desc';
          s.class = 'sorting_desc';
          s.isSorted = true;
        } else if (s.dir === 'desc') {
          s.dir = 'asc';
          s.class = 'sorting_asc';
          s.isSorted = true;
        }
      } else {
        s.dir = 'asc';
        s.class = 'sorting';
        s.isSorted = false;
      }
      return s;
    });
    let sortC = this.state.sorting.filter((m) => {
      return m.isSorted === true;
    });
    if (sortC.length > 0) {
      this.setState(
        {
          sorting: this.state.sorting,
          menuItems: undefined,
        },
        () => {
          this.props.getMenuItemsHandler(
            this.state.storeId,
            this.state.pagination,
            sortC[0],
            this.state.filterString
          );
          this.props.getMenuItemsCountHandler(
            this.state.storeId,
            this.state.filterString
          );
        }
      );
    }
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.menuItems
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.menuItems.length -
        1
      : 0;
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-12 col-sm-12'>
              <div className='panel panel-default panel-table'>
                <div className='panel-heading'> Most Sold Menu Items</div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-header'>
                      <div className='col-sm-6' />
                      <div className='col-sm-6'>
                        <div className='input-group input-search search-box'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='Search'
                            value={this.state.filterString}
                            onChange={this.filterMenuItems}
                          />
                          <span className='input-group-btn'>
                            <button className='btn btn-secondary'>
                              <i className='icon mdi mdi-search' />
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe '>
                        <table
                          id='table1'
                          className='table table-striped table-hover table-fw-widget dataTable no-footer'
                          role='grid'
                          aria-describedby='table1_info'
                        >
                          <thead>
                            <tr role='row'>
                              <th
                                onClick={() => this.sortMe(0)}
                                className={this.state.sorting[0].class}
                                aria-sort='ascending'
                                aria-label='Item: activate to sort column descending'
                              >
                                Item
                              </th>
                              <th
                                onClick={() => this.sortMe(1)}
                                className={this.state.sorting[1].class}
                                aria-label='Orders: activate to sort column ascending'
                              >
                                Orders
                              </th>
                              <th
                                onClick={() => this.sortMe(2)}
                                className={this.state.sorting[2].class}
                                aria-label='Sales: activate to sort column ascending'
                              >
                                Sales
                              </th>
                              <th aria-label='Sold Since: activate to sort column ascending'>
                                Sold Since
                              </th>
                            </tr>
                          </thead>
                          {!this.state.menuItems ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : this.state.menuItems.length > 0 ? (
                            <tbody>
                              {this.state.menuItems.map((item, index) => (
                                <tr
                                  key={index}
                                  className='gradeX odd'
                                  role='row'
                                >
                                  <td className='sorting_1'>{item.name}</td>
                                  <td>{`${parseInt(
                                    item.totalOrders,
                                    10
                                  )} Orders`}</td>
                                  <td>{`${currency}${Number(
                                    item.totalRevenue
                                  ).toFixed(2)}`}</td>
                                  <td className='center'>
                                    {convertToMomentDate(
                                      item.date,
                                      'YYYY-MM-DD HH:mm',
                                      this.state.timeZone
                                    ).format('MMM DD, YYYY')}
                                    <br />
                                    <span className='grayc'>
                                      {convertToMomentDate(
                                        item.date,
                                        'YYYY-MM-DD HH:mm',
                                        this.state.timeZone
                                      ).format('HH:mm')}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td>
                                  <p> No records to display</p>
                                </td>
                                <td />
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.state.totalCount ? this.state.totalCount : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

ItemStatistics.propTypes = {};

function mapStateToProps(state) {
  return {
    item: state.itemReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMenuItemsHandler: (storeId, pagination, sortObj, filterString) =>
      dispatch(getMenuItems(storeId, pagination, sortObj, filterString)),
    getMenuItemsCountHandler: (storeId, filterString) =>
      dispatch(getMenuItemsCount(storeId, filterString)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemStatistics);
