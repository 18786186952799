const getOrderStatus = (status, type) => {
  if (type === '1' || type === '2' || type === '4') {
    if (status === '0') return 'Unconfirmed';
    else if (status === '1') return 'Placed';
    else if (status === '2') return 'In kitchen';
    else if (status === '25') return 'Out for delivery';
    else if (status === '12') return 'Arriving';
    else if (status === '13') return 'Arrived';
    else if (status === '3') return 'On the way';
    else if (status === '4') return 'Delivered';
    else if (status === '11') return 'Cancelled';
  } else if (type === '3') {
    if (status === '1') return 'Unapproved';
    else if (status === '2') return 'Approved';
    else if (status === '3') return 'Declined';
  }
};

export default getOrderStatus;
