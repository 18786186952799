import React, { Component } from 'react';
import { auth, firestore } from '../../firebase';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Layout from '../../containers/Layout';

import { refreshTVs } from '../../containers/Migrations/actions';
class RefreshTV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrders: [],
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/refreshtv'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState({
                  storeId: doc.data().storeId,
                  restaurantId: doc.data().restaurantId,
                  timeZone: doc.data().timeZone,
                });
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  render() {
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-3 col-sm-12'>
              <div className='panel panel-default panel-border-color panel-border-color-primary'>
                <div className='panel-heading' />
                <div className='panel-body'>
                  <div className='form-group login-submit'>
                    <button
                      type='button'
                      onClick={() => this.props.refreshTVs(this.state.storeId)}
                      className='btn btn-primary btn-xl'
                    >
                      Refresh TV screens
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

RefreshTV.propTypes = {};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    refreshTVs: (storeId) => dispatch(refreshTVs(storeId)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RefreshTV));
