import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WarningModal extends Component {
  render() {
    const style = {
      display: 'block',
      paddingRight: '17px',
      zIndex: '1090',
    };
    const style1 = {
      position: 'relative',
      width: '30%',
    };
    return (
      <div
        id='mod-warning'
        role='dialog'
        className='modal fade in'
        style={style}
      >
        <div className='modal-dialog' style={style1}>
          <div
            style={{ backgroundColor: 'lightyellow' }}
            className='modal-content'
          >
            <div className='modal-header'>
              <button
                onClick={this.props.closeModal}
                type='button'
                data-dismiss='modal'
                aria-hidden='true'
                className='close'
              >
                <span className='mdi mdi-close' />
              </button>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='text-warning'>
                  <span className='modal-main-icon mdi mdi-alert-triangle' />
                </div>
                <h3>Warning!</h3>
                <p>{this.props.message}</p>
                <div className='xs-mt-50'>
                  <button
                    onClick={this.props.closeModal}
                    type='button'
                    data-dismiss='modal'
                    className='btn btn-space btn-default'
                  >
                    Cancel
                  </button>
                  <button
                    onClick={this.props.procced}
                    type='button'
                    data-dismiss='modal'
                    className='btn btn-space btn-warning'
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
            <div className='modal-footer' />
          </div>
        </div>
      </div>
    );
  }
}

WarningModal.propTypes = {
  closeModal: PropTypes.any,
  procced: PropTypes.any,
  message: PropTypes.any,
};

export default WarningModal;
