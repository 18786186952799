import React, { Component } from 'react';
import AWS from 'aws-sdk';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import Layout from '../../containers/Layout';
import { auth, firestore } from '../../firebase';
import Pagination from 'react-js-pagination';
import Spinner from '../../components/Spinner';
import ReportModal from '../../components/ReportModal';
import { convertToFormattedMomentDate } from '../../helpers/timeHelper';
import { nextOrderConfig } from '../../configurations/storeSetup';
import {
  getPaymentReportsCount,
  getPaymentReports,
  addStoreBillings,
  addOnlineProcessingFees,
  addStoreMiscellaneousCharges,
  getStore,
  addStoreCredit,
} from './actions';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

//s3 bucket configurations
const s3 = new AWS.S3({
  accessKeyId: 'AKIAIHHZWQ426J5E7QHQ',
  secretAccessKey: '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',
});

class PaymentReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      isDisplayReport: false,
      recreatingReportId: '',
      isReportDownloading: false,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/payment/payment-reports'
        );
      } else {
        firestore
          .collection('SystemUsers')
          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.props.getStoreHandler(doc.data().storeId);
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getPaymentReportsHandler(
                      this.state.storeId,
                      this.state.pagination
                    );
                    this.props.getPaymentReportsCountHandler(
                      this.state.storeId
                    );
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      totalCount: newProps.paymentReports.totalCount,
      paymentReports: newProps.paymentReports.paymentReports,
      store: newProps.paymentReports.store,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        paymentReports: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getPaymentReportsHandler(
          this.state.storeId,
          this.state.pagination
        );
      }
    );
  };

  viewReport = (reportObj) => {
    if (this.state.store) {
      this.setState(
        { recreatingReportId: reportObj._id, isReportDownloading: true },
        () => {
          const myBucket = 'next-order-finance';
          const myKey = this.state.store.isProductionEnv
            ? `production/${this.state.store.name}/${reportObj._id}.pdf`
            : `development/${this.state.store.name}/${reportObj._id}.pdf`;
          const signedUrlExpireSeconds = 60 * 5; // your expiry time in seconds.
          var params = {
            Bucket: myBucket,
            Key: myKey,
          };
          s3.getObject(params, (err, data) => {
            if (err) {
              //Recreate reports
              let reqObj = {
                storeId: this.state.storeId,
                reportId: reportObj._id,
              };
              fetch(
                `${nextOrderConfig.cloudFunctionUrl}/paymentReportReCreation`,
                {
                  method: 'post',
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    ...reqObj,
                  }),
                }
              )
                .then((response) => {
                  return response.json();
                })
                .then((result) => {
                  console.log(result);
                  const url = s3.getSignedUrl('getObject', {
                    Bucket: myBucket,
                    Key: myKey,
                    Expires: signedUrlExpireSeconds,
                  });
                  this.setState(
                    { recreatingReportId: '', isReportDownloading: false },
                    () => {
                      window.open(url, '_blank');
                    }
                  );
                });
            } else {
              const url = s3.getSignedUrl('getObject', {
                Bucket: myBucket,
                Key: myKey,
                Expires: signedUrlExpireSeconds,
              });
              this.setState(
                { recreatingReportId: '', isReportDownloading: false },
                () => {
                  window.open(url, '_blank');
                }
              );
            }
          });
        }
      );
    }
  };

  viewReportDetails = (reportObj) => {
    this.setState({ selectedReport: reportObj, isDisplayReport: true });
  };

  closeReportModal = () => {
    this.setState({ isDisplayReport: false, selectedReport: {} });
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.paymentReports
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.paymentReports.length -
        1
      : 0;
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='panel panel-default panel-table '>
                <div className='panel-heading'>Payment Reports</div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                          <thead>
                            <tr>
                              <th>Amount to be transfered</th>
                              <th>Total Amount</th>
                              <th>Card Payments</th>
                              <th>Cash Payments</th>
                              <th>Total Charges</th>
                              <th>Report Generetd On</th>
                              <th>Payment Status</th>
                              <th>Payment Settled On</th>
                              <th />
                            </tr>
                          </thead>
                          {!this.state.paymentReports ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : this.state.paymentReports.length > 0 ? (
                            <tbody className='no-border-x'>
                              {this.state.paymentReports.map(
                                (report, index) => (
                                  <tr key={index}>
                                    <td>{`${currency}${report.amountTransfered}`}</td>
                                    <td>{`${currency}${report.totalAmount}`}</td>
                                    <td>{`${currency}${report.cardPayments}`}</td>
                                    <td>{`${currency}${report.cashPayments}`}</td>
                                    <td>{`${currency}${report.totalCharges}`}</td>
                                    <td>
                                      {convertToFormattedMomentDate(
                                        report.date,
                                        'YYYY-MM-DD HH:mm',
                                        this.state.timeZone,
                                        'MMM DD, YYYY'
                                      )}
                                      <br />
                                      <span className='grayc'>
                                        {convertToFormattedMomentDate(
                                          report.date,
                                          'YYYY-MM-DD HH:mm',
                                          this.state.timeZone,
                                          'HH:mm'
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      {report.isAmountTransferred
                                        ? 'Settled'
                                        : 'To be settled'}
                                    </td>
                                    <td>
                                      {report.paymentSettledOn &&
                                        convertToFormattedMomentDate(
                                          report.paymentSettledOn,
                                          'YYYY-MM-DD HH:mm',
                                          this.state.timeZone,
                                          'MMM DD, YYYY'
                                        )}
                                      <br />
                                      <span className='grayc'>
                                        {report.paymentSettledOn &&
                                          convertToFormattedMomentDate(
                                            report.paymentSettledOn,
                                            'YYYY-MM-DD HH:mm',
                                            this.state.timeZone,
                                            'HH:mm'
                                          )}
                                      </span>
                                    </td>
                                    <td className='text-right'>
                                      {this.state.recreatingReportId ===
                                        report._id &&
                                      this.state.isReportDownloading ? (
                                        <button
                                          onClick={() =>
                                            this.viewReport(report)
                                          }
                                          className='btn btn-space btn-primary'
                                        >
                                          Downloading Report...
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            this.viewReport(report)
                                          }
                                          className='btn btn-space btn-primary'
                                        >
                                          View && Download
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td>
                                  <p> No records to display</p>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.state.totalCount ? this.state.totalCount : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='row'>
            <div className='col-md-4'>
              <div className='panel panel-default panel-border-color panel-border-color-primary'>
                <div className='panel-heading'>Finance</div>
                <div className='panel-body'>
                  <div className='form-group login-submit'>
                    <button
                      type='button'
                      onClick={() => this.props.addStoreBillings()}
                      className='btn btn-primary btn-xl'
                    >
                      Add Store Billing Codes
                    </button>
                  </div>
                  <div className='form-group login-submit'>
                    <button
                      type='button'
                      onClick={() => this.props.addOnlineProcessingFees()}
                      className='btn btn-primary btn-xl'
                    >
                      Add Online Processing Fees
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <div className='panel panel-default panel-border-color panel-border-color-primary'>
                <div className='panel-heading'>Finance</div>
                <div className='panel-body'>
                  <div className='form-group login-submit'>
                    <button
                      type='button'
                      onClick={() => this.props.addStoreMiscellaneousCharges()}
                      className='btn btn-primary btn-xl'
                    >
                      Add Store Miscellaneous Charges
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <div className='panel panel-default panel-border-color panel-border-color-primary'>
                <div className='panel-heading'>Finance</div>
                <div className='panel-body'>
                  <div className='form-group login-submit'>
                    <button
                      type='button'
                      onClick={() => this.props.addStoreCredit()}
                      className='btn btn-primary btn-xl'
                    >
                      Add Store Credit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
          {this.state.isDisplayReport && (
            <ReportModal
              isDisplayReport={this.state.isDisplayReport}
              closeReportModal={this.closeReportModal}
              selectedReport={this.state.selectedReport}
              timeZone={this.state.timeZone}
              storeId={this.state.storeId}
              currency={currency}
              store={this.props.store}
            />
          )}
        </div>
      </Layout>
    );
  }
}

PaymentReports.propTypes = {};

function mapStateToProps(state) {
  return {
    paymentReports: state.paymentReportsReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPaymentReportsHandler: (storeId, pagination) =>
      dispatch(getPaymentReports(storeId, pagination)),
    getPaymentReportsCountHandler: (storeId) =>
      dispatch(getPaymentReportsCount(storeId)),
    getStoreHandler: (storeId) => dispatch(getStore(storeId)),
    addStoreBillings: () => dispatch(addStoreBillings()),
    addOnlineProcessingFees: () => dispatch(addOnlineProcessingFees()),
    addStoreMiscellaneousCharges: () =>
      dispatch(addStoreMiscellaneousCharges()),
    addStoreCredit: () => dispatch(addStoreCredit()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReports);
