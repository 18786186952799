import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../components/Spinner';

const weekDays = [
  { id: '1', value: 'Monday' },
  { id: '2', value: 'Tuesday' },
  { id: '3', value: 'Wednesday' },
  { id: '4', value: 'Thursday' },
  { id: '5', value: 'Friday' },
  { id: '6', value: 'Saturday' },
  { id: '7', value: 'Sunday' },
];

class HighestRevenueShifts extends Component {
  getDay = (day) => {
    var Day = weekDays.filter(function (obj) {
      return obj.id === day;
    });
    if (Day && Day.length > 0) return Day[0].value;
    else return '';
  };

  render() {
    return (
      <div className='col-md-8'>
        <div className='panel panel-default panel-table '>
          <div className='panel-heading'>Highest Revenue Shifts</div>
          <div className='panel-body table-responsive noSwipe'>
            <table className='table table-striped table-borderless'>
              <thead>
                <tr>
                  <th> Shifts</th>
                  <th> Ave.Revenue</th>
                  <th> Orders</th>
                  <th> Rank</th>
                </tr>
              </thead>
              {!this.props.shifts ? (
                <tbody className='no-border-x'>
                  <tr style={{ height: '100px' }}>
                    <div
                      style={{
                        alignSelf: 'center',
                        left: '0px',
                        right: '0px',
                        position: 'absolute',
                        width: 'auto',
                        height: '100px',
                      }}
                      className='be-loading be-loading-active'
                    >
                      <Spinner />
                    </div>
                  </tr>
                </tbody>
              ) : this.props.shifts.length > 0 ? (
                <tbody className='no-border-x'>
                  {this.props.shifts.map((shift, index) => (
                    <tr key={index}>
                      <td>
                        {`${this.getDay(shift.day)} (${
                          shift.openingStartDisplay
                        } to ${shift.openingEndDisplay})`}
                      </td>
                      <td>
                        {shift.averageRevenue
                          ? `${
                              this.props.currency
                            }${shift.averageRevenue.toFixed(2)}`
                          : 'N/A'}
                      </td>
                      <td>{shift.totalOrders ? shift.totalOrders : 'N/A'}</td>
                      <td>{index + 1}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className='no-border-x'>
                  <tr>
                    <td>
                      <p> No records to display</p>
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    );
  }
}

HighestRevenueShifts.propTypes = {
  shifts: PropTypes.any,
};

export default HighestRevenueShifts;
