import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestore } from '../../firebase';
import PropTypes from 'prop-types';
import { nextOrderConfig } from '../../configurations/storeSetup';
import getFormattedMobileNumber from '../../helpers/getFormattedMobileNumber';
import _ from 'lodash';
import { Field, reduxForm, Form } from 'redux-form';
import { load as loadAccount } from './reducer';
import Spinner from '../Spinner';

const validate = (values) => {
  const mobileRegex = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.mobileNumber) {
    errors.mobileNumber = 'Required';
  } else if (!mobileRegex.test(values.mobileNumber)) {
    errors.mobileNumber = 'Invalid mobile';
  }
  return errors;
};

const warn = () => {
  const warnings = {};
  return warnings;
};

const renderField = ({
  input,
  placeholder,
  type,
  labelText,
  meta: { touched, error, warning },
}) => (
  <div className='form-group'>
    <label className='col-md-3 control-label'>{labelText}</label>
    <div className='col-md-7'>
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        autoComplete='off'
        className='form-control'
      />
      {touched &&
        ((error && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li style={{ fontSize: '13px' }} className='parsley-type'>
              {error}
            </li>
          </ul>
        )) ||
          (warning && (
            <ul className='parsley-errors-list filled' id='parsley-id-7'>
              <li style={{ fontSize: '13px' }} className='parsley-type'>
                {warning}
              </li>
            </ul>
          )))}
    </div>
  </div>
);

class DriverForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDuplicate: false,
      duplicateDriverStats: {},
      isSubmitting: false,
    };
  }

  componentDidMount() {
    this.setState({
      driverForEdit: { ...this.props.driverForEdit },
    });
    if (this.props.isEditModeOn) {
      this.props.load(this.props.driverForEdit);
    } else {
      this.props.load({});
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      driverForEdit: { ...props.driverForEdit },
    });
    if (props.isEditModeOn) {
      this.props.load(props.driverForEdit);
    } else {
      this.props.load({});
    }
  }

  handleSubmit = (values) => {
    if (!this.props.isEditModeOn) {
      firestore
        .collection('Drivers')
        .where(
          'mobileNumber',
          '==',
          getFormattedMobileNumber(values.mobileNumber)
        )
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length <= 0) {
            let obj = _.omit(values, _.isUndefined);
            obj.storeId = this.props.storeId;
            obj.restaurantId = this.props.restaurantId;
            obj.isLinkRequest = false;
            this.addDriver(obj);
            this.props.load({});
          } else {
            this.setState({
              isDuplicate: true,
              duplicateDriver: {
                _id: querySnapshot.docs[0].id,
                ...querySnapshot.docs[0].data(),
              },
            });
          }
        });
    } else {
      let obj = _.omit(values, _.isUndefined);
      obj.driverId = this.props.driverForEdit.driverId;
      obj.storeId = this.props.storeId;
      obj.restaurantId = this.props.restaurantId;
      obj.isLinkRequest = false;
      this.addDriver(obj);
      this.props.load({});
    }
  };

  addDriver(obj) {
    this.setState({ isSubmitting: true }, () => {
      fetch(`${nextOrderConfig.cloudFunctionUrl}/addDriver`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          this.setState({ isSubmitting: false }, () => {
            if (result.success) {
              this.props.closeDriverModal();
            } else {
              //Error occured
            }
          });
        });
    });
  }

  linkDriver = () => {
    let reqObj = {
      driverId: this.state.duplicateDriver._id,
      firstName: this.state.duplicateDriver.firstName,
      lastName: this.state.duplicateDriver.lastName,
      mobileNumber: this.state.duplicateDriver.mobileNumber,
      storeId: this.props.storeId,
      restaurantId: this.props.restaurantId,
      isLinkRequest: true,
    };
    this.addDriver(reqObj);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <div className='modal-body'>
          <div className='col-md-12'>
            <div className='text-center'>
              <Form
                onSubmit={handleSubmit(this.handleSubmit)}
                style={{ borderRadius: '0px' }}
                className='form-horizontal group-border-dashed'
              >
                <Field
                  type='text'
                  placeholder='Frist Name'
                  labelText='First Name'
                  name='firstName'
                  component={renderField}
                />
                <Field
                  type='text'
                  placeholder='Last Name'
                  labelText='Last Name'
                  name='lastName'
                  component={renderField}
                />
                <Field
                  type='text'
                  placeholder='Mobile Number'
                  labelText='Mobile'
                  name='mobileNumber'
                  component={renderField}
                />
                {!this.state.isDuplicate ? (
                  <div className='row xs-pt-15'>
                    <div className='col-xs-6'>
                      {this.state.isSubmitting && <Spinner></Spinner>}
                    </div>
                    <div className='col-xs-6'>
                      <p className='text-right'>
                        <button
                          onClick={this.props.closeDriverModal}
                          disabled={this.state.isSubmitting}
                          type='button'
                          data-dismiss='modal'
                          className='btn btn-default btn-space'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          disabled={this.state.isSubmitting}
                          className='btn btn-primary btn-space'
                        >
                          Save
                        </button>
                      </p>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div className='row xs-pt-15'>
                      <div className='col-xs-12' />
                      <div
                        role='alert'
                        className='alert alert-warning alert-dismissible'
                      >
                        <button
                          type='button'
                          data-dismiss='alert'
                          aria-label='Close'
                          className='close'
                        />
                        <span className='icon mdi mdi-alert-triangle' />
                        Driver has an existing account with other store. Click
                        on "Add Driver" button to link driver to your store!
                      </div>
                    </div>
                    <div className='row xs-pt-15'>
                      <div className='col-xs-4'>
                        {this.state.isSubmitting && <Spinner></Spinner>}
                      </div>
                      <div className='col-xs-8'>
                        <p className='text-right'>
                          <button
                            onClick={this.props.closeDriverModal}
                            disabled={this.state.isSubmitting}
                            type='button'
                            data-dismiss='modal'
                            className='btn btn-default btn-space'
                          >
                            Cancel
                          </button>
                          <button
                            onClick={this.linkDriver}
                            disabled={this.state.isSubmitting}
                            type='button'
                            className='btn btn-primary btn-space'
                          >
                            Add Driver
                          </button>
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DriverForm.propTypes = {
  closeDriverModal: PropTypes.any,
  isEditModeOn: PropTypes.any,
  driverForEdit: PropTypes.any,
};

DriverForm = reduxForm({
  form: 'driverForm',
  enableReinitialize: true,
  validate,
  warn,
})(DriverForm);

// You have to connect() to any reducers that you wish to connect to yourself
DriverForm = connect(
  (state) => ({
    initialValues: state.driverFormReducer ? state.driverFormReducer.data : {}, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(DriverForm);

export default DriverForm;
