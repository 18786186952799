import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchOffersCount:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.FetchOffers:
      return { ...state, offers: payload.offers };
    default:
      return state;
  }
};
