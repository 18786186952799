const actionTypes = {
  FetchCategories: 'FETCH_CATEGORIES',
  SizesFetched: 'SIZES_FETCHED',
  IngredientCategoryAdded: 'INGREIDENT_CATEGORY_ADDED',
  FetchedIngredientCategories: 'FETCHED_INGREDIENT_CATEGORIES',
  FetchedIngredients: 'FETCHED_INGREDIENTS',
  FetchedSizes: 'FETCHED_SIZES',
  FetchMenuItems: 'FETCH_MENU_ITEMS',
  MenuItemAdded: 'MENU_ITEM_ADDED',
  FetchItemSizes: 'FETCH_ITEM_SIZES',
};

export default actionTypes;
