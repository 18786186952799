import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchOrders:
      return { ...state, currentOrders: payload.currentOrders };
    case ActionTypes.FetchCurrentOrderCount:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.FetchOrderStats:
      return { ...state, orderStats: payload.orderStats };
    default:
      return state;
  }
};
