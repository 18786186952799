import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';
//import TimePicker from 'react-bootstrap-time-picker';
import { firestore } from '../../firebase';
import Autosuggest from 'react-autosuggest';
import DateRangePickerField from '../../components/DateRangePickerField';
import {
  Field,
  FieldArray,
  reduxForm,
  Form,
  formValueSelector,
} from 'redux-form';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
  getTimestampForMomentDate,
} from '../../helpers/timeHelper';
import moment from 'moment-timezone';
import { getFormattedMobileNumber } from '../../helpers/mobileNumberHelper';
import './index.css';

const getSuggestionValue = (suggestion) =>
  `${suggestion.firstName ? suggestion.firstName : ''} ${
    suggestion.lastName ? suggestion.lastName : ''
  }`;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => (
  <div>{`${suggestion.firstName ? suggestion.firstName : ''} ${
    suggestion.lastName ? suggestion.lastName : ''
  }`}</div>
);

const selector = formValueSelector('voucherForm');

const dayArray = [
  { id: 1, day: '1', dayText: 'Monday' },
  { id: 2, day: '2', dayText: 'Tuesday' },
  { id: 3, day: '3', dayText: 'Wednesday' },
  { id: 4, day: '4', dayText: 'Thursday' },
  { id: 5, day: '5', dayText: 'Friday' },
  { id: 6, day: '6', dayText: 'Saturday' },
  { id: 7, day: '7', dayText: 'Sunday' },
];

const renderFields = ({ days }) => (
  <div className='col-md-9'>
    {days.map((m, index) => (
      <div key={index} className='be-checkbox inline'>
        <Field name={m.dayText} id={m.id} component='input' type='checkbox' />
        <label htmlFor={m.id}>{m.dayText}</label>
      </div>
    ))}
  </div>
);

const renderField = ({
  input,
  placeholder,
  type,
  classNameforDiv,
  meta: { touched, error, warning },
}) => (
  <div className={classNameforDiv}>
    <input
      style={{ textTransform: 'uppercase' }}
      className='form-control'
      {...input}
      placeholder={placeholder}
      type={type}
    />
    {touched &&
      ((error && (
        <ul className='parsley-errors-list filled' id='parsley-id-7'>
          <li className='parsley-type'>{error}</li>
        </ul>
      )) ||
        (warning && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li className='parsley-type'>{warning}</li>
          </ul>
        )))}
  </div>
);

const renderFieldNumber = ({
  input,
  placeholder,
  type,
  classNameforDiv,
  meta: { touched, error, warning },
}) => (
  <div className={classNameforDiv}>
    <input
      style={{ textTransform: 'uppercase' }}
      className='form-control'
      {...input}
      placeholder={placeholder}
      type={type}
      min='0'
    />
    {touched &&
      ((error && (
        <ul className='parsley-errors-list filled' id='parsley-id-7'>
          <li className='parsley-type'>{error}</li>
        </ul>
      )) ||
        (warning && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li className='parsley-type'>{warning}</li>
          </ul>
        )))}
  </div>
);

const renderDropDown = ({
  input,
  placeholder,
  type,
  inputClass,
  meta: { touched, error, warning },
}) => (
  <div>
    <select
      {...input}
      placeholder={placeholder}
      type={type}
      className={inputClass}
    >
      <option value='1'>Discount Percentage </option>
      <option value='2'>Discount Amount</option>
    </select>
    {touched &&
      ((error && (
        <ul className='parsley-errors-list filled' id='parsley-id-7'>
          <li style={{ fontSize: '13px' }} className='parsley-type'>
            {error}
          </li>
        </ul>
      )) ||
        (warning && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li style={{ fontSize: '13px' }} className='parsley-type'>
              {warning}
            </li>
          </ul>
        )))}
  </div>
);

const validate = (values) => {
  const errors = {};
  if (!values.voucherCode) {
    errors.voucherCode = 'Required';
  }
  if (values.voucherCode && values.voucherCode.length < 3) {
    errors.voucherCode = 'Min. 3 char required.';
  }
  if (values.dates) {
    if (!values.dates.startDate) {
      errors.dates = 'Start date is required';
    }
    if (!values.dates.endDate) {
      errors.dates = 'End date is required';
    }
  } else {
    errors.dates = 'Select proper date range';
  }
  if (!values.voucherType) {
    errors.voucherType = 'Required';
  }
  if (!values.discount) {
    errors.discount = 'Required';
  } else if (isNaN(Number(values.discount))) {
    errors.discount = 'Must be a number';
  }
  if (!values.discountedPrice) {
    errors.discountedPrice = 'Required';
  } else if (isNaN(Number(values.discountedPrice))) {
    errors.discountedPrice = 'Must be a number';
  }
  if (!values.minOrderValue) {
    errors.minOrderValue = 'Required';
  } else if (isNaN(Number(values.minOrderValue))) {
    errors.minOrderValue = 'Must be a number';
  }
  if (!values.maxOrders) {
    errors.maxOrders = 'Required';
  } else if (isNaN(Number(values.maxOrders))) {
    errors.maxOrders = 'Must be a number';
  }

  return errors;
};

const warn = () => {
  const warnings = {};
  return warnings;
};

class VoucherForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVoucherExist: false,
      selectedCategories: [],
      value: '',
      suggestions: [],
      startTimeUI: '00:00',
      endTimeUI: '00:00',
    };
  }

  componentWillReceiveProps(props) {
    const category =
      props.categories && props.categories.length > 0
        ? props.categories[0]
        : { _id: 0 };
    let categories = [];
    if (this.props.categories && this.props.categories.length > 0) {
      this.props.categories.map((m, i) => {
        let obj = {
          _id: m._id,
          name: m.catName,
          isActive: i === 0 ? true : false,
        };
        categories.push(obj);
      });
    }
    const filteredMenuItems = props.menuItems
      ? props.menuItems.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuItems = [];
    filteredMenuItems.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
      };
      menuItems.push(obj);
    });
    const mySizes = this.props.sizes
      ? this.props.sizes.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuSizes = [];
    mySizes.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
      };
      menuSizes.push(obj);
    });
    this.setState({
      categories,
      menuItems,
      mySizes: menuSizes,
    });
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      isErrorSelectUser: false,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    if (value.length >= 3) {
      let valFS = value;
      if (!isNaN(valFS)) valFS = getFormattedMobileNumber(valFS);
      firestore
        .collection('Users')
        .where('storeId', '==', this.props.storeId)
        .where('keywords', 'array-contains', valFS.toLowerCase())
        .get()
        .then((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          this.setState({
            suggestions: dataArray,
          });
        });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleQuantityChange = (e) => {
    this.setState({
      quantity: e.target.value,
    });
  };

  handleChangeCatChk = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let categories = JSON.parse(JSON.stringify(this.state.categories));
    categories.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    let catIds = [];
    catIds = categories.filter((f) => f.isActive).map((m) => m._id);
    const filteredMenuItems = this.props.menuItems
      ? this.props.menuItems.filter((item) => {
          return catIds.includes(item.categoryId);
        })
      : [];
    let menuItems = [];
    let menuSizes = [];
    filteredMenuItems.map((m) => {
      let matchedCategories = categories.filter((f) => {
        return f._id === m.categoryId;
      });
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName:
          matchedCategories.length > 0 ? matchedCategories[0].name : '',
        categoryId: matchedCategories[0]._id,
        isActive: true,
      };
      menuItems.push(obj);
    });
    const mySizes = this.props.sizes
      ? this.props.sizes.filter((item) => {
          return catIds.includes(item.categoryId);
        })
      : [];
    mySizes.map((m) => {
      let matchedCategories = categories.filter((f) => {
        return f._id === m.categoryId;
      });
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName:
          matchedCategories.length > 0 ? matchedCategories[0].name : '',
        categoryId: matchedCategories[0]._id,
        isActive: true,
      };
      menuSizes.push(obj);
    });
    this.setState({
      categories,
      menuItems,
      mySizes: menuSizes,
    });
  };

  handleStartTimeChange = (time, timeString) => {
    this.setState({ startTimeUI: timeString }, () => {
      let openingString = this.state.startTimeUI;
      let hours = openingString.split(':')[0];
      let disabledOpeningHoursArray = [];
      for (let i = 0; i < hours; i++) {
        disabledOpeningHoursArray.push(i);
      }
      this.setState({
        startTimeDisplay: moment(this.state.startTimeUI, 'HH:mm').format(
          'hh:mm A'
        ),
        startTime: moment(this.state.startTimeUI, 'HH:mm').diff(
          moment().startOf('day'),
          'seconds'
        ),
        disabledOpeningHoursArray: disabledOpeningHoursArray,
      });
    });
  };

  handleEndTimeChange = (time, timeString) => {
    this.setState({ endTimeUI: timeString }, () => {
      this.setState({
        endTimeDisplay: moment(this.state.endTimeUI, 'HH:mm').format('hh:mm A'),
        endTime: moment(this.state.endTimeUI, 'HH:mm').diff(
          moment().startOf('day'),
          'seconds'
        ),
      });
    });
  };

  handleSubmit = (values) => {
    if (this.state.selectedCategories.length <= 0 && values.type === '3') {
      this.setState({
        isErrorSelectedCategory: true,
        errorMessageCat: 'Minimum one item required',
      });
    } else if (
      values.type === '2' &&
      (!this.state.userId || this.state.userId === '')
    ) {
      this.setState({
        isErrorSelectUser: true,
        errorMessageUser: 'Select user',
      });
    } else {
      if (!this.state.isVoucherExist) {
        values.date = getCurrentFormattedMomentDate(
          this.props.timeZone,
          'YYYY-MM-DD HH:mm'
        );

        //UTC time stamp of date
        values.dateTimestamp = getCurrentTimestamp();

        values.voucherCode = values.voucherCode.toUpperCase();
        if (values.dates && values.dates.startDate) {
          values.startDate = values.dates.startDate.format('YYYY-MM-DD');
          values.startDateTimestamp = getTimestampForMomentDate(
            values.dates.startDate,
            'YYYY-MM-DD'
          );
        }
        if (values.dates && values.dates.endDate) {
          values.endDate = values.dates.endDate.format('YYYY-MM-DD');
          values.endDateTimestamp = getTimestampForMomentDate(
            values.dates.endDate,
            'YYYY-MM-DD'
          );
        }
        values.selectedCategories = this.state.selectedCategories;
        values.isActive = true;
        if (values.type === '2') {
          values.userId = this.state.userId;
          values.firstName = this.state.firstName;
          values.lastName = this.state.lastName;
          values.mobileNumber = this.state.mobileNumber;
        } else if (values.type === '1' || values.type === '3') {
          if (values.isAvailableFullDay === false) {
            values.startTimeDisplay = this.state.startTimeDisplay;
            values.endTimeDisplay = this.state.endTimeDisplay;
            values.startTime = this.state.startTime;
            values.endTime = this.state.endTime;
          }
        }
        if (values.dates) delete values.dates;
        this.props.addVoucherHandler(values);
        this.props.reset();
      }
    }
  };

  handleOnChange = (e) => {
    if (e.target.value.length >= 3) {
      firestore
        .collection('Vouchers')
        .where('storeId', '==', this.props.storeId)
        .where('isActive', '==', true)
        .where('voucherCode', '==', e.target.value.toUpperCase())
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length <= 0) {
            this.setState({
              isVoucherExist: false,
            });
          } else {
            this.setState({
              isVoucherExist: true,
            });
          }
        });
    } else {
      this.setState({
        isVoucherExist: false,
      });
    }
  };

  secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var amPmDisplay = '';
    if (h > 12) {
      amPmDisplay = 'PM';
      h = h - 12;
    } else if (h === 12 && m === 0) {
      amPmDisplay = 'PM';
      h = 12;
    } else amPmDisplay = 'AM';
    var hDisplay = h > 0 ? (h >= 9 ? h : '0' + h) + ':' : '12:';
    var mDisplay = m > 0 ? m : '00';
    return hDisplay + mDisplay + ' ' + amPmDisplay;
  };

  addToVoucher = () => {
    const item = {
      categories: this.state.categories,
      quantity: this.refs.quantity.value,
      menuItems: this.state.menuItems,
      menuSizes: this.state.mySizes,
    };
    const category =
      this.props.categories && this.props.categories.length > 0
        ? this.props.categories[0]
        : { _id: 0 };
    let categories = [];
    if (this.props.categories && this.props.categories.length > 0) {
      this.props.categories.map((m, i) => {
        let obj = {
          _id: m._id,
          name: m.catName,
          isActive: i === 0 ? true : false,
        };
        categories.push(obj);
      });
    }
    const filteredMenuItems = this.props.menuItems
      ? this.props.menuItems.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuItems = [];
    filteredMenuItems.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
      };
      menuItems.push(obj);
    });
    const mySizes = this.props.sizes
      ? this.props.sizes.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuSizes = [];
    mySizes.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
      };
      menuSizes.push(obj);
    });

    let selectedCategories = [...this.state.selectedCategories, item];
    selectedCategories.map((c, index) => {
      c.index = index;
      return c;
    });
    this.setState(
      {
        isValidationError: false,
        selectedCategories,
        mySizes: menuSizes,
        menuItems,
        categories,
      },
      () => {
        document.getElementById('select-quantity').value = '1';
      }
    );
  };

  getSizesList = (category) => {
    return category.menuSizes
      .filter((f) => f.isActive)
      .map((f) => f.name)
      .join(',');
  };

  getMenuItemsList = (category) => {
    return category.menuItems
      .filter((f) => f.isActive)
      .map((f) => f.name)
      .join(',');
  };

  getCategoryList = (category) => {
    return category.categories
      .filter((f) => f.isActive)
      .map((f) => f.name)
      .join(',');
  };

  removeFromVoucher = (index) => {
    let selectedCategories = this.state.selectedCategories.filter((obj) => {
      return obj.index !== index;
    });
    selectedCategories.map((m, i) => {
      m.index = i;
      return m;
    });
    this.setState({ selectedCategories });
  };

  handleChangeChk = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let menuItems = JSON.parse(JSON.stringify(this.state.menuItems));
    menuItems.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    this.setState({
      menuItems,
    });
  };

  handleChangeSizeChk = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let menuSizes = JSON.parse(JSON.stringify(this.state.mySizes));
    menuSizes.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    this.setState({
      mySizes: menuSizes,
    });
  };

  mobileNumberChangeHandler = (e) => {
    let mobileNumber = e.target.value;
    const mobileRegex = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    if (mobileRegex.test(mobileNumber)) {
      firestore
        .collection('Users')
        .where('storeId', '==', this.props.storeId)
        .where('mobileNumber', '==', getFormattedMobileNumber(mobileNumber))
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length <= 0) {
            this.setState({ userId: '', firstName: '', lastName: '' });
          } else {
            let userDetails = querySnapshot.docs[0].data();
            this.setState({
              userId: querySnapshot.docs[0].id,
              firstName: userDetails.firstName,
              lastName: userDetails.lastName,
            });
          }
        });
    }
  };

  onSuggestionSelected = (_event, { suggestion }) => {
    this.setState({
      userId: suggestion._id,
      firstName: suggestion.firstName,
      lastName: suggestion.lastName,
      mobileNumber: suggestion.mobileNumber,
    });
  };

  render() {
    const { reset, handleSubmit, submitting } = this.props;
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Type user name',
      value,
      onChange: this.onChange,
    };
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='panel'>
            <div className='panel-heading panel-heading-divider bline'>
              Create New Voucher
            </div>
            <div className='panel-body'>
              <Form
                onSubmit={handleSubmit(this.handleSubmit)}
                className='form-horizontal'
              >
                {this.props.openedFromReviews && this.props.review && (
                  <React.Fragment>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>User</label>
                      <div className='col-md-8' style={{ textAlign: 'left' }}>
                        <label className='control-label'>
                          {this.props.review.userName}
                        </label>
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>Mobile</label>
                      <div className='col-md-8' style={{ textAlign: 'left' }}>
                        <label className='control-label'>
                          {this.props.review.mobileNumber}
                        </label>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className='form-group'>
                  <label
                    htmlFor='inputEmail3'
                    className='col-md-3 control-label'
                  >
                    Voucher Code
                  </label>
                  <Field
                    tabIndex='1'
                    name='voucherCode'
                    classNameforDiv='col-md-3'
                    component={renderField}
                    id='inputEmail3'
                    placeholder='CITY100'
                    className='form-control'
                    type='text'
                    onChange={(e) => this.handleOnChange(e)}
                  />
                </div>
                {this.state.isVoucherExist && (
                  <div className='form-group'>
                    <label className='col-md-3 control-label' />
                    <div className='col-md-3'>
                      <ul
                        className='parsley-errors-list filled'
                        id='parsley-id-7'
                      >
                        <li
                          style={{ fontSize: '13px' }}
                          className='parsley-type'
                        >
                          Voucher already exists.
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                <div className='form-group'>
                  <label className='col-md-3 control-label'>Type</label>
                  <div className='col-md-9'>
                    <div className='be-checkbox inline'>
                      <Field
                        name='type'
                        id='type_prom0'
                        component='input'
                        type='radio'
                        value='1'
                      />
                      <label htmlFor='type_prom0'>Promotional Code</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <Field
                        name='type'
                        id='type_user'
                        component='input'
                        type='radio'
                        value='2'
                      />
                      <label htmlFor='type_user'>User Voucher</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <Field
                        name='type'
                        id='type_free_item'
                        component='input'
                        type='radio'
                        value='3'
                      />
                      <label htmlFor='type_free_item'>Discounted Items</label>
                    </div>
                  </div>
                </div>
                {this.props.type !== '3' ? (
                  <React.Fragment>
                    <div className='form-group'>
                      <label
                        htmlFor='inputPassword3'
                        className='col-md-3 control-label padd-20'
                      >
                        Discount Type
                      </label>
                      <div className='col-md-3'>
                        <Field
                          name='voucherType'
                          component={renderDropDown}
                          type='select'
                          tabIndex='2'
                          inputClass='form-control'
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>Discount</label>
                      <Field
                        tabIndex='4'
                        name='discount'
                        classNameforDiv='col-md-3'
                        component={renderField}
                        placeholder='25'
                        className='form-control'
                        type='text'
                      />
                    </div>
                    <div className='form-group'>
                      <label className='col-sm-3 control-label'>
                        Valid Form/To
                      </label>
                      <div className='col-md-9'>
                        <Field
                          component={DateRangePickerField}
                          name='dates'
                          tabIndex='5'
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div />
                )}

                <div className='form-group'>
                  <label className='col-md-3 control-label'>Voucher for</label>
                  <div className='col-md-3'>
                    <div className='be-checkbox inline'>
                      <Field
                        name='isAvailablePickup'
                        id='isAvailablePickup'
                        component='input'
                        type='checkbox'
                      />
                      <label htmlFor='isAvailablePickup'>Pickup</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <Field
                        name='isAvailableDelivery'
                        id='isAvailableDelivery'
                        component='input'
                        type='checkbox'
                      />
                      <label htmlFor='isAvailableDelivery'>Delivery</label>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-md-3 control-label'>Available On</label>
                  <FieldArray
                    days={dayArray}
                    name='availableOn'
                    component={renderFields}
                  />
                </div>
                {this.props.type === '2' ? (
                  <React.Fragment>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>
                        Search User
                      </label>
                      <div className='col-md-3'>
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          getSuggestionValue={getSuggestionValue}
                          onSuggestionSelected={this.onSuggestionSelected}
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                          theme={{
                            input: 'form-control',
                            suggestionsList: 'react-autosuggest-dropdown',
                          }}
                        />
                      </div>
                    </div>
                    {this.state.userId && this.state.userId !== '' && (
                      <React.Fragment>
                        <div className='form-group'>
                          <label
                            htmlFor='inputEmail3'
                            className='col-md-3 control-label'
                          >
                            Mobile Number
                          </label>
                          <div className='col-md-3'>
                            <input
                              className='form-control'
                              type='text'
                              readOnly={true}
                              value={`${this.state.mobileNumber}`}
                            />
                          </div>
                          {/* <Field
                        tabIndex='1'
                        name='mobileNumber'
                        classNameforDiv='col-md-3'
                        component={renderField}
                        id='inputEmail3'
                        placeholder='0419685369'
                        className='form-control'
                        type='text'
                        onChange={e => this.mobileNumberChangeHandler(e)}
                      /> */}
                        </div>

                        <div className='form-group'>
                          <label
                            htmlFor='inputEmail3'
                            className='col-md-3 control-label'
                          >
                            User
                          </label>
                          <div className='col-md-3'>
                            <input
                              className='form-control'
                              type='text'
                              readOnly={true}
                              value={`${this.state.firstName} ${this.state.lastName}`}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    {this.state.isErrorSelectUser && (
                      <div className='form-group'>
                        <label className='col-md-3 control-label' />
                        <div className='col-md-3'>
                          <ul
                            className='parsley-errors-list filled'
                            id='parsley-id-7'
                          >
                            <li
                              style={{ fontSize: '13px' }}
                              className='parsley-type'
                            >
                              {this.state.errorMessageUser}
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <div />
                )}
                {this.props.type === '3' ? (
                  <React.Fragment>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>
                        Categories
                      </label>
                      <div className='col-md-9'>
                        {this.state.categories.map((m, index) => (
                          <div key={index} className='be-checkbox inline'>
                            <input
                              checked={m.isActive}
                              onChange={(e) => this.handleChangeCatChk(e)}
                              name={m.name}
                              id={m._id}
                              type='checkbox'
                            />
                            <label htmlFor={m._id}>{m.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='col-md-3 control-label text-right  t10'>
                        Quantity
                      </label>
                      <div className='col-md-3'>
                        <input
                          type='number'
                          min='1'
                          max='99'
                          defaultValue='1'
                          ref='quantity'
                          className='form-control'
                          id='select-quantity'
                          value={this.state.quantity}
                          onChange={(e) => this.handleQuantityChange(e)}
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>Sizes</label>
                      <div className='col-md-9'>
                        {this.state.mySizes.map((m, index) => (
                          <div key={index} className='be-checkbox inline'>
                            <input
                              checked={m.isActive}
                              onChange={(e) => this.handleChangeSizeChk(e)}
                              name={m.name}
                              id={m._id}
                              type='checkbox'
                            />
                            <label htmlFor={m._id}>{m.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>
                        Menu Items
                      </label>
                      <div className='col-md-9'>
                        {this.state.menuItems.map((m, index) => (
                          <div key={index} className='be-checkbox inline'>
                            <input
                              checked={m.isActive}
                              onChange={this.handleChangeChk}
                              name={m.name}
                              id={m._id}
                              type='checkbox'
                            />
                            <label htmlFor={m._id}>{m.name}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'></label>
                      <div className='col-md-9'>
                        <button
                          type='button'
                          onClick={this.addToVoucher}
                          className='btn btn-space btn-primary'
                          disabled={
                            !(
                              this.state.menuItems.some((menuItem) => {
                                return menuItem.isActive === true;
                              }) &&
                              this.state.mySizes.some((size) => {
                                return size.isActive === true;
                              }) &&
                              this.state.categories.some((cat) => {
                                return cat.isActive === true;
                              })
                            )
                          }
                        >
                          Add to Voucher
                        </button>
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>
                        Selected Items
                      </label>
                      <div className='col-md-9 table-responcive'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>Categories</th>
                              <th>Quantity</th>
                              <th>Size</th>
                              <th>Menu Items</th>
                              <th>&nbsp; </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.selectedCategories &&
                            this.state.selectedCategories.length > 0 ? (
                              this.state.selectedCategories.map(
                                (category, index) => (
                                  <tr key={index}>
                                    <td>{this.getCategoryList(category)}</td>
                                    <td>{category.quantity}</td>
                                    <td>{this.getSizesList(category)}</td>
                                    <td>{this.getMenuItemsList(category)}</td>
                                    <td>
                                      <button
                                        type='button'
                                        onClick={() =>
                                          this.removeFromVoucher(
                                            category.index ? category.index : 0
                                          )
                                        }
                                        className='btn btn-space btn-default hover'
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan='4'>
                                  {this.state.isValidationError &&
                                  this.state.selectedCategories.length < 1 ? (
                                    <ul
                                      className='parsley-errors-list filled'
                                      id='parsley-id-7'
                                    >
                                      <li
                                        style={{ fontSize: '13px' }}
                                        className='parsley-type'
                                      >
                                        At least one category required in
                                        special
                                      </li>
                                    </ul>
                                  ) : (
                                    'No items available'
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {this.state.isErrorSelectedCategory && (
                      <div className='form-group'>
                        <label className='col-md-3 control-label' />
                        <div className='col-md-3'>
                          <ul
                            className='parsley-errors-list filled'
                            id='parsley-id-7'
                          >
                            <li
                              style={{ fontSize: '13px' }}
                              className='parsley-type'
                            >
                              {this.state.errorMessageCat}
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>
                        Discounted Price
                      </label>
                      <Field
                        tabIndex='7'
                        name='discountedPrice'
                        classNameforDiv='col-md-3'
                        component={renderFieldNumber}
                        placeholder={`${this.props.currency}10`}
                        className='form-control'
                        type='number'
                        min='0'
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <div />
                )}
                {this.props.type === '1' || this.props.type === '3' ? (
                  <React.Fragment>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>
                        For New Customers Only?
                      </label>
                      <div className='col-md-3'>
                        <div className='be-checkbox'>
                          <Field
                            name='isExusiveForNewCustomers'
                            id='isExusiveForNewCustomers'
                            component='input'
                            type='checkbox'
                          />
                          <label htmlFor='isExusiveForNewCustomers' />
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>
                        For Single Time Use(Per User)?
                      </label>
                      <div className='col-md-3'>
                        <div className='be-checkbox'>
                          <Field
                            name='isExusiveForSingleTimeUse'
                            id='isExusiveForSingleTimeUse'
                            component='input'
                            type='checkbox'
                          />
                          <label htmlFor='isExusiveForSingleTimeUse' />
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='col-md-3 control-label'>
                        Available Full Day?
                      </label>
                      <div className='col-md-3'>
                        <div className='be-checkbox'>
                          <Field
                            name='isAvailableFullDay'
                            id='isAvailableFullDay'
                            component='input'
                            type='checkbox'
                          />
                          <label htmlFor='isAvailableFullDay' />
                        </div>
                      </div>
                    </div>
                    {!this.props.isAvailableFullDay && (
                      <React.Fragment>
                        <div className='form-group'>
                          <label className='col-md-3 control-label '>
                            Start Time
                          </label>
                          <div className='col-md-3'>
                            <TimePicker
                              format={'HH:mm'}
                              minuteStep={15}
                              onChange={this.handleStartTimeChange}
                              value={moment(this.state.startTimeUI, 'HH:mm')}
                              allowClear={false}
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label '>
                            End Time
                          </label>
                          <div className='col-md-3'>
                            <TimePicker
                              format={'HH:mm'}
                              minuteStep={15}
                              onChange={this.handleEndTimeChange}
                              value={moment(this.state.endTimeUI, 'HH:mm')}
                              allowClear={false}
                              disabledHours={() => {
                                return this.state.disabledOpeningHoursArray;
                              }}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <div />
                )}
                <div className='form-group'>
                  <label className='col-md-3 control-label'>
                    Available With Special Discount?
                  </label>
                  <div className='col-md-3'>
                    <div className='be-checkbox'>
                      <Field
                        name='isAvailableWithSpecialDiscount'
                        id='isAvailableWithSpecialDiscount'
                        component='input'
                        type='checkbox'
                      />
                      <label htmlFor='isAvailableWithSpecialDiscount' />
                    </div>
                  </div>
                </div>
                {this.props.type === '3' ? (
                  <div className='form-group'>
                    <label className='col-md-3 control-label'>
                      Available With Special Items?
                    </label>
                    <div className='col-md-3'>
                      <div className='be-checkbox'>
                        <Field
                          name='isAvailableWithSpecials'
                          id='isAvailableWithSpecials'
                          component='input'
                          type='checkbox'
                        />
                        <label htmlFor='isAvailableWithSpecials' />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                {this.props.type === '1' ? (
                  <div className='form-group'>
                    <label className='col-md-3 control-label'>
                      Auto-aply voucher?
                    </label>
                    <div className='col-md-3'>
                      <div className='be-checkbox'>
                        <Field
                          name='isAutoApply'
                          id='isAutoApply'
                          component='input'
                          type='checkbox'
                        />
                        <label htmlFor='isAutoApply' />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                <div className='form-group'>
                  <label className='col-md-3 control-label'>
                    Min. Order Value
                  </label>
                  <Field
                    tabIndex='7'
                    name='minOrderValue'
                    classNameforDiv='col-md-3'
                    component={renderFieldNumber}
                    placeholder={`${this.props.currency}10`}
                    className='form-control'
                    type='number'
                    min='0'
                  />
                </div>
                <div className='form-group'>
                  <label className='col-md-3 control-label'>
                    Max. Number of Uses
                  </label>
                  <Field
                    tabIndex='7'
                    name='maxOrders'
                    classNameforDiv='col-md-3'
                    component={renderFieldNumber}
                    placeholder='150 Orders'
                    className='form-control'
                    type='number'
                    min='0'
                  />
                </div>
                <div className='row xs-pt-15'>
                  <div className='col-md-6'>
                    <p className='text-right'>
                      <button
                        disabled={submitting}
                        onClick={reset}
                        type='button'
                        className='btn btn-space btn-default'
                      >
                        Cancel
                      </button>
                      <button
                        disabled={submitting}
                        type='submit'
                        className='btn btn-space btn-primary'
                      >
                        {this.props.openedFromReviews
                          ? 'Add Voucher and SMS'
                          : 'Add Voucher'}
                      </button>
                    </p>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VoucherForm.propTypes = {
  addVoucherHandler: PropTypes.any,
};

function mapStateToProps(state, _props) {
  const type = selector(state, 'type');
  const isAvailableFullDay = selector(state, 'isAvailableFullDay');
  const startTime = selector(state, 'startTime');
  const mobileNumber = selector(state, 'mobileNumber');
  return { type, mobileNumber, isAvailableFullDay, startTime };
}

export default reduxForm({
  form: 'voucherForm',
  validate,
  warn,
  initialValues: {
    isExusiveForNewCustomers: false,
    isExusiveForSingleTimeUse: true,
    isAvailableDelivery: true,
    isAvailablePickup: true,
    isAvailableFullDay: true,
    isAvailableWithSpecials: false,
    isAvailableWithSpecialDiscount: true,
    isAutoApply: false,
    Monday: true,
    Tuesday: true,
    Thursday: true,
    Wednesday: true,
    Friday: true,
    Saturday: true,
    Sunday: true,
    type: '1',
    voucherType: '1',
    minOrderValue: 0,
  },
})(connect(mapStateToProps)(VoucherForm));
