import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getUser(userId) {
  return (dispatch) => {
    let ref = firestore.collection('Users').doc(userId);
    ref.get().then((doc) => {
      let obj = { _id: doc.id, ...doc.data() };
      dispatch(fetchedUser(obj));
    });
  };
}
export function getVoucher(voucherId) {
  return (dispatch) => {
    let ref = firestore.collection('Vouchers').doc(voucherId);
    ref.get().then((doc) => {
      let obj = { _id: doc.id, ...doc.data() };
      dispatch(fetchedVoucher(obj));
    });
  };
}
export function getDriver(driverId) {
  return (dispatch) => {
    let ref = firestore
      .collection('DriverLocations')
      .where('driverId', '==', driverId);
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedDriver(dataArray[0]));
    });
  };
}
export function getDeliveryAddresses(userId) {
  return (dispatch) => {
    let ref = firestore
      .collection('DeliveryAddresses')
      .where('userId', '==', userId);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedDeliveryAddresses(dataArray));
    });
  };
}

export function getOrderHistory(storeId, orderId) {
  return (dispatch) => {
    let ref = firestore
      .collection('OrderStatusHistory')
      .where('storeId', '==', storeId)
      .where('orderId', '==', orderId);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedOrderHistory(dataArray));
    });
    ref.onSnapshot((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedOrderHistory(dataArray));
    });
  };
}

function fetchedUser(user) {
  return {
    type: ActionTypes.FetchUser,
    user,
  };
}
function fetchedDriver(driver) {
  return {
    type: ActionTypes.FetchDriver,
    driver,
  };
}
function fetchedVoucher(voucher) {
  return {
    type: ActionTypes.FetchVoucher,
    voucher,
  };
}
function fetchedDeliveryAddresses(deliveryAddresses) {
  return {
    type: ActionTypes.FetchDeliveryAddresses,
    deliveryAddresses,
  };
}
function fetchedOrderHistory(orderHistory) {
  return {
    type: ActionTypes.FetchOrderHistory,
    orderHistory,
  };
}
