import moment from 'moment-timezone';

const getCurrentMomentDateFun = (timeZone) => {
  return moment().tz(timeZone);
};

const getCurrentDateFun = (timeZone) => {
  return new Date(moment().tz(timeZone).format('YYYY-MM-DD'));
};

const getDateFun = (timeZone, offset, offsetType) => {
  return new Date(
    moment().add(offset, offsetType).tz(timeZone).format('YYYY-MM-DD')
  );
};

const getMomentDateFun = (timeZone, offset, offsetType) => {
  return moment().add(offset, offsetType).tz(timeZone);
};

const convertToMomentDateFun = (dateString, format, timeZone) => {
  return moment(dateString, format);
};

const getCurrentFormattedMomentDateFun = (timeZone, format) => {
  return moment().tz(timeZone).format(format);
};

const convertToFormattedMomentDateFun = (
  dateString,
  format,
  timeZone,
  requiredFormat
) => {
  return moment(dateString, format).format(requiredFormat);
};

const getMomentDateFromMillisFun = (utcTimestamp, timeZone) => {
  let dateObj = moment.tz(
    moment(utcTimestamp).format('YYYY-MM-DD hh:mm A'),
    timeZone
  );
  return dateObj;
};

const getCurrentTimestampFun = () => {
  var now = new Date();
  return now.getTime();
};

const getTimestampStartOfTheDayFun = () => {
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  return now.getTime();
};

const getTimestampEndOfTheDayFun = () => {
  var now = new Date();
  now.setHours(23, 59, 59, 999);
  return now.getTime();
};

const getTimestampStartOfTheYesterDayFun = () => {
  var now = new Date();
  now.setDate(now.getDate() - 1);
  now.setHours(0, 0, 0, 0);
  return now.getTime();
};

const getTimestampForMomentDateFun = (date, timeZone, format) => {
  let dateObj = moment.tz(date.format(format), timeZone).toDate();
  return dateObj.getTime();
};

const getTimestampForDateStringFun = (dateString, timezone) => {
  let dateObj = moment.tz(dateString, timezone).toDate();
  return dateObj.getTime();
};

const getTimestampForMomentDateStartFun = (date) => {
  let dateObj = date.toDate();
  dateObj.setHours(0, 0, 0, 0);
  return dateObj.getTime();
};
const getTimestampForMomentDateEndFun = (date) => {
  let dateObj = date.toDate();
  dateObj.setHours(23, 59, 59, 999);
  return dateObj.getTime();
};
export const getCurrentMomentDate = getCurrentMomentDateFun;
export const getMomentDate = getMomentDateFun;
export const convertToMomentDate = convertToMomentDateFun;
export const getCurrentDate = getCurrentDateFun;
export const getDate = getDateFun;
export const convertToFormattedMomentDate = convertToFormattedMomentDateFun;
export const getCurrentFormattedMomentDate = getCurrentFormattedMomentDateFun;
export const getMomentDateFromMillis = getMomentDateFromMillisFun;
export const getCurrentTimestamp = getCurrentTimestampFun;
export const getTimestampForMomentDate = getTimestampForMomentDateFun;
export const getTimestampForDateString = getTimestampForDateStringFun;
export const getTimestampStartOfTheDay = getTimestampStartOfTheDayFun;
export const getTimestampEndOfTheDay = getTimestampEndOfTheDayFun;
export const getTimestampStartOfTheYesterDay = getTimestampStartOfTheYesterDayFun;
export const getTimestampForDateStart = getTimestampForMomentDateStartFun;
export const getTimestampForDateEnd = getTimestampForMomentDateEndFun;
