import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import WarningModal from '../../components/WarningModal';
import Spinner from '../../components/Spinner';
import SpecialsOrderDisplay from '../SpecialsOrderDisplay';

class SpecialList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCategoryWarning: false,
    };
  }

  closeWarnings = () => {
    this.setState({ showCategoryWarning: false });
  };

  openSpecialWarningModal = (special) => {
    this.setState({ showCategoryWarning: true, specialTobeDeleted: special });
  };

  deleteSpecialHandler = () => {
    this.props.deleteSpecialHandler(this.state.specialTobeDeleted);
    this.setState({ ...this.state, showCategoryWarning: false });
  };

  getDisplayStatus = (special) => {
    if (special.isAvailablePickup && special.isAvailableDelivery) return 'Both';
    else if (!special.isAvailablePickup && special.isAvailableDelivery)
      return 'Only Delivery';
    else if (special.isAvailablePickup && !special.isAvailableDelivery)
      return 'Only Pickup';
    else return 'N/A';
  };

  openSpecialsOrderModal = () => {
    this.setState({
      showSpecialsOrderModal: true,
    });
  };

  closeSpecialsOrderModal = () => {
    this.setState({
      showSpecialsOrderModal: false,
    });
  };

  render() {
    const start =
      (this.props.pagination.currentPage - 1) * this.props.pagination.pageSize +
      1;
    const end = this.props.specials
      ? (this.props.pagination.currentPage - 1) *
          this.props.pagination.pageSize +
        1 +
        this.props.specials.length -
        1
      : 0;
    return (
      <div className='row'>
        <div className='col-sm-12 col-xs-12 col-md-12'>
          <div className='panel panel-default panel-table'>
            <div
              className='panel-heading'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div> Specials </div>
              <button
                onClick={() => {
                  this.openSpecialsOrderModal();
                }}
                className='btn btn-space btn-success fright'
              >
                Display Order
              </button>
            </div>
            <div
              style={{ overflow: 'hidden' }}
              className='panel-body table-responsive'
            >
              <div
                id='table1_wrapper'
                className='dataTables_wrapper form-inline dt-bootstrap no-footer'
              >
                <div className='row be-datatable-body'>
                  <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                    <table
                      id='table1'
                      className='table table-striped table-hover table-fw-widget dataTable no-footer'
                      role='grid'
                      aria-describedby='table1_info'
                    >
                      <thead>
                        <tr role='row'>
                          <th
                            tabIndex='0'
                            aria-controls='table1'
                            rowSpan='1'
                            colSpan='1'
                            aria-sort='ascending'
                            aria-label='Name: activate to sort column descending'
                            style={{ width: '405px' }}
                          >
                            Name
                          </th>
                          <th
                            tabIndex='0'
                            aria-controls='table1'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Price: activate to sort column ascending'
                            style={{ width: '101px' }}
                          >
                            Price
                          </th>
                          <th
                            tabIndex='0'
                            aria-controls='table1'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Pickup / Delivery: activate to sort column ascending'
                            style={{ width: '245px' }}
                          >
                            Pickup / Delivery
                          </th>
                          <th
                            tabIndex='0'
                            aria-controls='table1'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='Usage: activate to sort column ascending'
                            style={{ width: '238px' }}
                          >
                            Usage
                          </th>
                          <th
                            className='nosort sorting_disabled'
                            rowSpan='1'
                            colSpan='1'
                            aria-label='&amp;nbsp;  '
                            style={{ width: '259px' }}
                          >
                            &nbsp;
                          </th>
                        </tr>
                      </thead>
                      {!this.props.specials ? (
                        <tbody>
                          <tr style={{ height: '100px' }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                left: '0px',
                                right: '0px',
                                position: 'absolute',
                                width: 'auto',
                                height: '100px',
                              }}
                              className='be-loading be-loading-active'
                            >
                              <Spinner />
                            </div>
                          </tr>
                        </tbody>
                      ) : this.props.specials.length > 0 ? (
                        <tbody>
                          {this.props.specials &&
                            this.props.specials.map((special, index) => (
                              <tr key={index} className='gradeX odd' role='row'>
                                <td className='sorting_1'>{special.name}</td>
                                <td>{`${this.props.currency}${Number(
                                  special.cost
                                ).toFixed(2)}`}</td>
                                <td>{this.getDisplayStatus(special)}</td>
                                <td>
                                  {special.specialUsageCount
                                    ? `${special.specialUsageCount}  ${
                                        special.specialUsageCount > 1
                                          ? 'orders'
                                          : 'order'
                                      }`
                                    : 'N/A'}
                                </td>
                                <td className='text-right'>
                                  <button
                                    onClick={() =>
                                      this.props.setSpecialForEdit(special)
                                    }
                                    className='btn btn-space btn-primary'
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() =>
                                      this.openSpecialWarningModal(special)
                                    }
                                    className='btn btn-space btn-danger hover'
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <p> No records to display</p>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className='row be-datatable-footer'>
                  <div className='col-md-5 col-sm-12'>
                    <div
                      style={{ float: 'left' }}
                      className='dataTables_info'
                      id='table1_info'
                      role='status'
                      aria-live='polite'
                    >
                      {`Showing ${start} to ${end} of ${
                        this.props.totalCount ? this.props.totalCount : 0
                      } entries`}
                    </div>
                  </div>
                  <div className='col-md-7 col-sm-12'>
                    <div
                      className='dataTables_paginate paging_simple_numbers'
                      id='table1_paginate'
                    >
                      {this.props.pagination && (
                        <nav style={{ float: 'right' }}>
                          <Pagination
                            activePage={this.props.pagination.currentPage}
                            itemsCountPerPage={this.props.pagination.pageSize}
                            totalItemsCount={this.props.totalCount}
                            pageRangeDisplayed={
                              this.props.pagination.pageRangeDisplayed
                            }
                            onChange={this.props.handlePageChange}
                            prevPageText='Previous'
                            nextPageText='Next'
                            itemClass='paginate_button'
                          />
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showSpecialsOrderModal && (
          <SpecialsOrderDisplay
            openSpecialsOrderModal={this.openSpecialsOrderModal}
            closeSpecialsOrderModal={this.closeSpecialsOrderModal}
            allSpecials={this.props.allSpecials}
          />
        )}
        {this.state.showCategoryWarning && (
          <WarningModal
            procced={this.deleteSpecialHandler}
            closeModal={this.closeWarnings}
            message={`This will delete special. Click "proceed" to continue.`}
          />
        )}
      </div>
    );
  }
}

SpecialList.propTypes = {
  specials: PropTypes.any,
  totalCount: PropTypes.any,
  handlePageChange: PropTypes.any,
  deleteSpecialHandler: PropTypes.any,
  setSpecialForEdit: PropTypes.any,
};

export default SpecialList;
