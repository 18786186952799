import ActionTypes from './constants';
import { firestore } from '../../firebase';

export function dateRangeChanged(start, end) {
  return (dispatch) => {
    dispatch(dateRangeChangedFun(start, end));
  };
}

export function getStore(storeId) {
  return (dispatch) => {
    firestore
      .collection('Stores')
      .doc(storeId)
      .onSnapshot((doc) => {
        dispatch(storeFetched({ _id: doc.id, ...doc.data() }));
      });
  };
}

export function getStoreOrderStats(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('StoreOrderStats')
      .where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      if (querySnapshot.docs.length > 0)
        dispatch(storeOrderStatsFetched(querySnapshot.docs[0].data()));
    });
  };
}

export function getStoreReviewStats(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('StoreReviewStats')
      .where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      if (querySnapshot.docs.length > 0)
        dispatch(storeReviewStatsFetched(querySnapshot.docs[0].data()));
    });
  };
}

export function getRefundReasons() {
  return (dispatch) => {
    let ref = firestore.collection('RefundReasons');
    ref.onSnapshot((querySnapshot) => {
      let dataArray = [];
      querySnapshot.docs.forEach((doc) => {
        let obj = { _id: doc.id, ...doc.data() };
        dataArray.push(obj);
      });
      dispatch(refundReasons(dataArray));
    });
  };
}

function dateRangeChangedFun(start, end) {
  return {
    type: ActionTypes.DateRangeChanged,
    start,
    end,
  };
}

function storeOrderStatsFetched(storeOrderStats) {
  return {
    type: ActionTypes.FetchStoreOrderStats,
    storeOrderStats,
  };
}

function storeReviewStatsFetched(storeReviewStats) {
  return {
    type: ActionTypes.FetchStoreReviewStats,
    storeReviewStats,
  };
}

function refundReasons(reasons) {
  return {
    type: ActionTypes.FetchRefundReasons,
    reasons,
  };
}

function storeFetched(storeObj) {
  let {
    _id,
    name,
    city,
    addressLocation,
    area,
    areaCode,
    country,
    emailAddress,
    logo,
    logoStyle,
    logoWrapDesktopStyle,
    logoWrapMobileStyle,
    storeTheme,
    phoneNumber,
    state,
    stateIdentifier,
    street,
    timeZone,
    currency,
    currencyCode,
    connectId,
    isProductionEnv,
    restaurantId,
  } = storeObj;
  let store = {
    _id,
    storeId: _id,
    restaurantId,
    name,
    address: `${storeObj.street}, ${storeObj.area}`,
    addressLocation,
    area,
    areaCode,
    city,
    country,
    emailAddress,
    logo,
    logoStyle,
    logoWrapDesktopStyle,
    logoWrapMobileStyle,
    storeTheme,
    phoneNumber,
    phoneNumberDisplay: phoneNumber,
    state,
    stateIdentifier,
    street,
    timeZone,
    currency,
    currencyCode,
    connectId,
    isProductionEnv,
  };
  return {
    type: ActionTypes.FetchStore,
    store,
  };
}
