import { firestore } from '../../firebase';
import ActionTypes from './constants';

export function getPaymentReportOrders(reportId) {
  return (dispatch) => {
    let ref = firestore
      .collection('PaymentReportOrders')
      .where('reportId', '==', reportId);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedPaymentReportOrders(dataArray));
    });
  };
}
export function getPaymentReportOtherOrders(reportId) {
  return (dispatch) => {
    let ref = firestore
      .collection('PaymentReportOtherDeliveries')
      .where('reportId', '==', reportId);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedPaymentReportOtherOrders(dataArray));
    });
  };
}
export function getPaymentReportTxtMsgs(reportId) {
  return (dispatch) => {
    let ref = firestore
      .collection('PaymentReportTextMsgs')
      .where('reportId', '==', reportId);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedPaymentReportTxtMsgs(dataArray));
    });
  };
}
export function getPaymentReportReservations(reportId) {
  return (dispatch) => {
    let ref = firestore
      .collection('PaymentReportReservations')
      .where('reportId', '==', reportId);
    ref.get().then((querySnapshot) => {
      var dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(fetchedPaymentReportrReservations(dataArray));
    });
  };
}
function fetchedPaymentReportTxtMsgs(reportTxtMsgs) {
  return {
    type: ActionTypes.FetchTextMsgs,
    reportTxtMsgs,
  };
}
function fetchedPaymentReportOtherOrders(reportOtherOrders) {
  return {
    type: ActionTypes.FetchReportOtherOrders,
    reportOtherOrders,
  };
}
function fetchedPaymentReportOrders(reportOrders) {
  return {
    type: ActionTypes.FetchReportOrders,
    reportOrders,
  };
}
function fetchedPaymentReportrReservations(reportReservations) {
  return {
    type: ActionTypes.FetchReportReservations,
    reportReservations,
  };
}
