import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class MenuItemUpsalePrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editCategory: {},
      isProcessing: false,
      page: 1,
    };
  }

  componentDidMount() {
    if (this.props.editCategory) {
      this.setState({ editCategory: this.props.editCategory });
    }
  }

  changePrice = (e, menuItem) => {
    let editCategory = this.state.editCategory;
    editCategory.menuItems.map((m) => {
      if (m._id === menuItem._id) m.price = e.target.value;
      return m;
    });
    this.setState({ editCategory });
  };

  saveCategory = () => {
    this.props.saveCategory(this.state.editCategory);
  };

  render() {
    return (
      <Modal
        className='modal fade'
        backdrop='static'
        keyboard={false}
        show={this.props.showUpsaleModal}
        onHide={this.props.closeUpsaleModal}
        bsSize='large'
        aria-labelledby='contained-modal-title'
      >
        <div className='modal-header'>
          <button
            onClick={this.props.closeUpsaleModal}
            type='button'
            data-dismiss='modal'
            aria-hidden='true'
            className='close'
          >
            <span className='mdi mdi-close' />
          </button>
          <h3 className='modal-title'>Upsell items</h3>
          <hr />
        </div>
        <div className='model-body'>
          <div className='raw'>
            <div className='panel-heading panel-heading-divider  ptitle'>
              <div
                style={{
                  display: 'flex',
                  float: 'right',
                  justifyContent: 'space-between',
                }}
              >
                <button
                  type='submit'
                  className='btn btn-success btn-space'
                  onClick={this.saveCategory}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className='raw'>
            <div className='col-md-12'>
              {this.state.editCategory &&
                this.state.editCategory.menuItems &&
                this.state.editCategory.menuItems
                  .filter((f) => f.isActive)
                  .map((m) => (
                    <div
                      key={m._id}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        margin: '10px',
                      }}
                    >
                      <div key={`${m._id}`} className='col-md-4'>
                        <label>{m.name}</label>
                      </div>
                      <div className='col-md-4 input-group xs-mb-15'>
                        <span className='input-group-addon'>$</span>
                        <input
                          className='form-control'
                          id={`${m._id}`}
                          type='text'
                          placeholder='0'
                          value={m.price}
                          onChange={(e) => this.changePrice(e, m)}
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

MenuItemUpsalePrice.propTypes = {};

export default MenuItemUpsalePrice;
