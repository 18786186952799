import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchRefund:
      return { ...state, refund: payload.refund };
    case ActionTypes.FetchStore:
      return { ...state, store: payload.store };
    default:
      return state;
  }
};
