import React, { Component } from 'react';

class Spinner extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    return (
      <p className='be-spinner'>
        <svg
          width='40px'
          height='40px'
          viewBox='0 0 66 66'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            fill='none'
            strokeWidth='4'
            strokeLinecap='round'
            cx='33'
            cy='33'
            r='30'
            className='circle'
          />
        </svg>
      </p>
    );
  }
}

export default Spinner;
