import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchOrders:
      return { ...state, realtimeOrders: payload.realtimeOrders };
    case ActionTypes.FetchReservations:
      return { ...state, reservations: payload.reservations };
    case ActionTypes.FetchOtherDeliveries:
      return { ...state, otherDeliveries: payload.otherDeliveries };
    default:
      return state;
  }
};
