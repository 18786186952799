import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Field, reduxForm, Form } from 'redux-form';
import TagsInput from 'react-tagsinput';
import {
  getCurrentTimestamp,
  getCurrentFormattedMomentDate,
} from '../../helpers/timeHelper';
import { load as loadOrderSetup } from './reducer';

const renderNumberField = ({
  input,
  placeholder,
  type,
  labelText,
  min,
  max,
  meta: { touched, error, warning },
}) => (
  <div className='form-group'>
    <label className='col-sm-3 col-md-3 control-label'>{labelText}</label>
    <div className='col-md-6 col-sm-6'>
      <input
        {...input}
        min={min}
        max={max}
        autoComplete='off'
        placeholder={placeholder}
        type={type}
        className='form-control'
      />
      {touched &&
        ((error && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li style={{ fontSize: '13px' }} className='parsley-type'>
              {error}
            </li>
          </ul>
        )) ||
          (warning && (
            <ul className='parsley-errors-list filled' id='parsley-id-7'>
              <li style={{ fontSize: '13px' }} className='parsley-type'>
                {warning}
              </li>
            </ul>
          )))}
    </div>
  </div>
);

const renderMoneyField = ({
  input,
  placeholder,
  type,
  labelText,
  currency,
  meta: { touched, error, warning },
}) => (
  <div className='form-group'>
    <label className='col-sm-3 col-md-3 control-label'>{labelText}</label>
    <div className='col-sm-6 col-md-6'>
      <div className='input-group'>
        <span className='input-group-addon'>{currency}</span>
        <input
          {...input}
          autoComplete='off'
          placeholder={placeholder}
          type={type}
          className='form-control'
        />
        {touched &&
          ((error && (
            <ul className='parsley-errors-list filled' id='parsley-id-7'>
              <li style={{ fontSize: '13px' }} className='parsley-type'>
                {error}
              </li>
            </ul>
          )) ||
            (warning && (
              <ul className='parsley-errors-list filled' id='parsley-id-7'>
                <li style={{ fontSize: '13px' }} className='parsley-type'>
                  {warning}
                </li>
              </ul>
            )))}
      </div>
    </div>
  </div>
);

const renderFieldWithCheckBox = ({
  input,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => (
  <div>
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      className='form-control'
    />
    {touched &&
      ((error && (
        <ul className='parsley-errors-list filled' id='parsley-id-7'>
          <li style={{ fontSize: '13px' }} className='parsley-type'>
            {error}
          </li>
        </ul>
      )) ||
        (warning && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li style={{ fontSize: '13px' }} className='parsley-type'>
              {warning}
            </li>
          </ul>
        )))}
  </div>
);

const allowDeliveriesList = [
  { id: 'Yes', value: 'Yes' },
  { id: 'No', value: 'No' },
];

const validate = (values) => {
  //const mobileRegex = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
  const errors = {};
  if (!values.isDeliveryAllowed) {
    errors.isDeliveryAllowed = 'Required';
  }
  // if (!values.reservationNumber) {
  //   errors.reservationNumber = 'Required';
  // } else if (!mobileRegex.test(values.reservationNumber)) {
  //   errors.reservationNumber = 'Invalid mobile';
  // }

  // if (!values.orderConfirmationNumber) {
  //   errors.orderConfirmationNumber = 'Required';
  // } else if (!mobileRegex.test(values.orderConfirmationNumber)) {
  //   errors.orderConfirmationNumber = 'Invalid mobile';
  // }
  if (!values.defaultPickUpTime) {
    errors.defaultPickUpTime = 'Required';
  } else if (isNaN(values.defaultPickUpTime)) {
    errors.defaultPickUpTime = 'Invalid value';
  } else if (Number(values.defaultPickUpTime) > 200) {
    errors.defaultPickUpTime =
      'Max limit for default pickup time is 200 minues';
  }

  if (!values.defaultDeliveryTime) {
    errors.defaultDeliveryTime = 'Required';
  } else if (isNaN(values.defaultDeliveryTime)) {
    errors.defaultDeliveryTime = 'Invalid value';
  } else if (Number(values.defaultDeliveryTime) > 200) {
    errors.defaultDeliveryTime =
      'Max limit for default delivery time is 200 minues';
  }

  if (!values.pickUpTime) {
    errors.pickUpTime = 'Required';
  } else if (isNaN(values.pickUpTime)) {
    errors.pickUpTime = 'Invalid value';
  } else if (Number(values.pickUpTime) > 200) {
    errors.pickUpTime = 'Max limit for pickup time is 200 minues';
  }

  if (!values.deliveryTime) {
    errors.deliveryTime = 'Required';
  } else if (isNaN(values.deliveryTime)) {
    errors.deliveryTime = 'Invalid value';
  } else if (Number(values.deliveryTime) > 200) {
    errors.deliveryTime = 'Max limit for delivery time is 200 minues';
  }

  if (!values.minPickupOrderValue) {
    errors.minPickupOrderValue = 'Required';
  } else if (isNaN(values.minPickupOrderValue)) {
    errors.minPickupOrderValue = 'Invalid value';
  }
  if (!values.maxCashOrderValue) {
    errors.maxCashOrderValue = 'Required';
  } else if (isNaN(values.maxCashOrderValue)) {
    errors.maxCashOrderValue = 'Invalid value';
  }
  if (!values.minDeliveryOrderValue) {
    errors.minDeliveryOrderValue = 'Required';
  } else if (isNaN(values.minDeliveryOrderValue)) {
    errors.minDeliveryOrderValue = 'Invalid value';
  }
  if (!values.deliveryFreeOver) {
    errors.deliveryFreeOver = 'Required';
  } else if (isNaN(values.deliveryFreeOver)) {
    errors.deliveryFreeOver = 'Invalid value';
  }
  return errors;
};

const warn = () => {
  const warnings = {};
  return warnings;
};

class OrderSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationNumbers: [],
      orderConfirmationNumbers: [],
      reviewEmails: [],
      paymentReportEmails: [],
      useCustomTableBooking: false,
      useCustomSpecialTitle: false,
      enableUserEmail: false,
      customBookingURL: '',
      customSpecialTitle: '',
    };
  }

  componentDidMount() {
    this.setState({
      orderSetup: this.props.orderSetup,
      reservationNumbers:
        this.props.orderSetup && this.props.orderSetup.reservationNumbers
          ? this.props.orderSetup.reservationNumbers
          : [],
      orderConfirmationNumbers:
        this.props.orderSetup && this.props.orderSetup.orderConfirmationNumbers
          ? this.props.orderSetup.orderConfirmationNumbers
          : [],
    });
    this.props.load(this.props.orderSetup);
  }

  componentWillReceiveProps(props) {
    this.setState({
      orderSetup: props.orderSetup,
      reservationNumbers:
        props.orderSetup && props.orderSetup.reservationNumbers
          ? props.orderSetup.reservationNumbers
          : [],
      orderConfirmationNumbers:
        props.orderSetup && props.orderSetup.orderConfirmationNumbers
          ? props.orderSetup.orderConfirmationNumbers
          : [],
      reviewEmails:
        props.orderSetup && props.orderSetup.reviewEmails
          ? props.orderSetup.reviewEmails
          : [],
      paymentReportEmails:
        props.orderSetup && props.orderSetup.paymentReportEmails
          ? props.orderSetup.paymentReportEmails
          : [],
      useCustomTableBooking:
        props.orderSetup && props.orderSetup.useCustomTableBooking
          ? props.orderSetup.useCustomTableBooking
          : false,
      useCustomSpecialTitle:
        props.orderSetup && props.orderSetup.useCustomSpecialTitle
          ? props.orderSetup.useCustomSpecialTitle
          : false,
      enableUserEmail:
        props.orderSetup && props.orderSetup.enableUserEmail
          ? props.orderSetup.enableUserEmail
          : false,
      customBookingURL:
        props.orderSetup && props.orderSetup.customBookingURL
          ? props.orderSetup.customBookingURL
          : '',
      customSpecialTitle:
        props.orderSetup && props.orderSetup.customSpecialTitle
          ? props.orderSetup.customSpecialTitle
          : '',
    });
    this.props.load(props.orderSetup);
  }

  handleSubmit = (values) => {
    values.lastUpdatedOn = getCurrentFormattedMomentDate(
      this.props.timeZone,
      'YYYY-MM-DD HH:mm'
    );
    values.lastUpdatedOnTimestamp = getCurrentTimestamp();
    if (this.props.orderSetup && this.props.orderSetup._id) {
      values._id = this.state.orderSetup._id;
    } else {
      values.date = getCurrentFormattedMomentDate(
        this.props.timeZone,
        'YYYY-MM-DD HH:mm'
      );
      values.dateTimestamp = getCurrentTimestamp();
    }
    values.reservationNumbers = this.state.reservationNumbers;
    values.orderConfirmationNumbers = this.state.orderConfirmationNumbers;
    values.reviewEmails = this.state.reviewEmails;
    values.paymentReportEmails = this.state.paymentReportEmails;
    values.useCustomTableBooking = this.state.useCustomTableBooking;
    values.useCustomSpecialTitle = this.state.useCustomSpecialTitle;
    values.enableUserEmail = this.state.enableUserEmail;
    values.customBookingURL = this.state.customBookingURL;
    if (values.useCustomTableBooking === false) {
      values.customBookingURL = '';
    }
    values.customSpecialTitle = this.state.customSpecialTitle;
    if (values.useCustomSpecialTitle === false) {
      values.customSpecialTitle = '';
    }
    this.props.saveOrderSetupHandler(_.omit(values, _.isUndefined));
    this.setState({
      isSuccess: true,
      validationMessage: 'Order data updated successfully!',
    });
    this.props.load({});
  };

  onReservationTagsChange = (e) => {
    const mobileRegex = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    let tags = [];
    e.map((p) => {
      if (mobileRegex.test(p)) {
        tags.push(p);
      }
    });
    this.setState({ reservationNumbers: tags });
  };

  onOrderTagsChange = (e) => {
    const mobileRegex = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    let tags = [];
    e.forEach((tag) => {
      if (mobileRegex.test(tag)) {
        tags.push(tag);
      }
    });
    this.setState({ orderConfirmationNumbers: tags });
  };

  onOrderTagsChangeEmail = (e) => {
    const emailRegex = /\S+@\S+\.\S+/;
    let tags = [];
    e.map((p) => {
      if (emailRegex.test(p)) {
        tags.push(p);
      }
    });
    this.setState({ reviewEmails: tags });
  };

  onOrderTagsChangePaymentEmail = (e) => {
    const emailRegex = /\S+@\S+\.\S+/;
    let tags = [];
    e.map((p) => {
      if (emailRegex.test(p)) {
        tags.push(p);
      }
    });
    this.setState({ paymentReportEmails: tags });
  };

  removeAlert = () => {
    this.setState({ isSuccess: false, validationMessage: '' });
  };

  handleCheckbox = (e) => {
    this.setState({
      useCustomTableBooking: e.target.checked,
    });
  };

  handleCheckboxST = (e) => {
    this.setState({
      useCustomSpecialTitle: e.target.checked,
    });
  };

  handleUserEmailCheckbox = (e) => {
    this.setState({
      enableUserEmail: e.target.checked,
    });
  };

  handleChange = (e) => {
    this.setState({
      customBookingURL: e.target.value,
    });
  };

  handleChangeST = (e) => {
    this.setState({
      customSpecialTitle: e.target.value,
    });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div className='col-md-12'>
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          className='form-horizontal'
        >
          <div className='panel panel-default panel-border-color panel-border-color-primary'>
            <div className='panel-heading panel-heading-divider'>
              Store Settings
              <div className='fright'>
                <button
                  disabled={submitting}
                  type='submit'
                  className='btn btn-space btn-primary'
                >
                  Save
                </button>
              </div>
            </div>
            <div className='divider1' />
            <div className='panel-body'>
              <div className='form-group'>
                <label className='col-md-3 col-sm-3 control-label' />
                <div className='col-sm-6 col-md-6'>
                  {this.state.isSuccess && (
                    <div
                      role='alert'
                      className='alert alert-contrast alert-success alert-dismissible'
                    >
                      <div className='icon'>
                        <span className='mdi mdi-check' />
                      </div>
                      <div className='message'>
                        <button
                          onClick={this.removeAlert}
                          type='button'
                          data-dismiss='alert'
                          aria-label='Close'
                          className='close'
                        >
                          <span aria-hidden='true' className='mdi mdi-close' />
                        </button>
                        <strong>Good!</strong> {this.state.validationMessage}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Reservation Numbers
                </label>
                <div className='col-md-6 col-sm-6'>
                  <Field
                    name='reservationNumbers'
                    component={(_props) => (
                      <TagsInput
                        value={this.state.reservationNumbers}
                        onChange={this.onReservationTagsChange}
                        placeholder='Add number'
                        class='form-control'
                      />
                    )}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Order Confirmation Numbers
                </label>
                <div className='col-md-6 col-sm-6'>
                  <Field
                    name='orderConfirmationNumbers'
                    component={(_props) => (
                      <TagsInput
                        value={this.state.orderConfirmationNumbers}
                        onChange={this.onOrderTagsChange}
                        placeholder='Add number'
                        class='form-control'
                      />
                    )}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Review E-mails
                </label>
                <div className='col-md-6 col-sm-6'>
                  <Field
                    name='reviewEmails'
                    component={(_props) => (
                      <TagsInput
                        value={this.state.reviewEmails}
                        onChange={this.onOrderTagsChangeEmail}
                        placeholder='Add Emails'
                        class='form-control'
                      />
                    )}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Payment report E-mails
                </label>
                <div className='col-md-6 col-sm-6'>
                  <Field
                    name='paymentReportEmails'
                    component={(_props) => (
                      <TagsInput
                        value={this.state.paymentReportEmails}
                        onChange={this.onOrderTagsChangePaymentEmail}
                        placeholder='Add Emails'
                        class='form-control'
                      />
                    )}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Enable Pre-Ordering
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isPreOrderingEnabled'
                      id='isPreOrderingEnabled'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <Field
                type='number'
                min='1'
                max='30'
                placeholder='Number Of Days'
                labelText='Pre-Ordering(Days)'
                name='preOrderingDays'
                component={renderNumberField}
              />
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Enable Table Booking
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isTableBookingOn'
                      id='isTableBookingOn'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Collect User Email While Sign-In
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <input
                      type='checkbox'
                      checked={this.state.enableUserEmail}
                      onChange={(e) => this.handleUserEmailCheckbox(e)}
                    />
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Use Custom Table Booking Software
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <input
                      type='checkbox'
                      checked={this.state.useCustomTableBooking}
                      onChange={(e) => this.handleCheckbox(e)}
                    />
                  </div>
                </div>
              </div>

              {this.state.useCustomTableBooking === true && (
                <React.Fragment>
                  <div className='form-group'>
                    <label className='col-sm-3 col-md-3 control-label'>
                      Custom Table Booking Software URL
                    </label>
                    <div
                      className='col-sm-6 col-md-6'
                      style={{ marginTop: '12px' }}
                    >
                      <input
                        type='text'
                        className=''
                        placeholder='Custom Booking Software URL'
                        style={{
                          width: '100%',
                          height: '30px',
                          marginTop: '-5px',
                        }}
                        value={this.state.customBookingURL}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}

              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Use Custom Special Title
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <input
                      type='checkbox'
                      checked={this.state.useCustomSpecialTitle}
                      onChange={(e) => this.handleCheckboxST(e)}
                    />
                  </div>
                </div>
              </div>

              {this.state.useCustomSpecialTitle === true && (
                <React.Fragment>
                  <div className='form-group'>
                    <label className='col-sm-3 col-md-3 control-label'>
                      Custom Special Title
                    </label>
                    <div
                      className='col-sm-6 col-md-6'
                      style={{ marginTop: '12px' }}
                    >
                      <input
                        type='text'
                        className=''
                        placeholder='Custom Special Title'
                        style={{
                          width: '100%',
                          height: '30px',
                          marginTop: '-5px',
                        }}
                        value={this.state.customSpecialTitle}
                        onChange={this.handleChangeST}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}

              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Allow Table Booking For Current Day
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isAllowedTableBookingForCurrentDay'
                      id='isAllowedTableBookingForCurrentDay'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Display GST on Invoice ?
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isDisplayGST'
                      id='isDisplayGST'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Item Price includes GST ?
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isPriceIncludesGST'
                      id='isPriceIncludesGST'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Enable Pickup
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isPickupOn'
                      id='isPickupOn'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Enable Delivery
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isDeliveryOn'
                      id='isDeliveryOn'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <Field
                type='number'
                min='1'
                max='60'
                placeholder='35 Minutes'
                labelText='Pickup Time(Minutes)'
                name='pickUpTime'
                component={renderNumberField}
              />
              <Field
                type='number'
                min='1'
                max='60'
                placeholder='55 Minutes'
                labelText='Delivery Time(Minutes)'
                name='deliveryTime'
                component={renderNumberField}
              />
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Allow Reviews Through Printer
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isReviewAllowedThroughPrinter'
                      id='isReviewAllowedThroughPrinter'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <Field
                type='number'
                placeholder='3'
                max='5'
                min='1'
                labelText='Bad Review Limit'
                name='badReviewLimit'
                component={renderNumberField}
              />
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Opt-in for Public Holiday Surcharge
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isOptedInForHolidaySurcharge'
                      id='isOptedInForHolidaySurcharge'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <Field
                type='number'
                min='0'
                max='100'
                placeholder='10'
                labelText='Public Holiday Surcharge(%)'
                name='publicHolidaySurcharge'
                component={renderNumberField}
              />
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Enable Cash Payments
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isCashPaymentAccepted'
                      id='isCashPaymentAccepted'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Enable Card Payments
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isCardPaymentAccepted'
                      id='isCardPaymentAccepted'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <Field
                type='text'
                placeholder={`${this.props.currency}20`}
                labelText='Minimum Pickup Order Value'
                name='minPickupOrderValue'
                component={renderMoneyField}
                currency={this.props.currency}
              />
              <Field
                type='text'
                placeholder={`${this.props.currency}20`}
                labelText='Max Cash Order Value'
                name='maxCashOrderValue'
                component={renderMoneyField}
                currency={this.props.currency}
              />
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Is First Delivery Free ?
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isFirstDeliveryFree'
                      id='isFirstDeliveryFree'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <Field
                type='text'
                placeholder={`${this.props.currency}20`}
                labelText='Minimum Delivery Order Value'
                name='minDeliveryOrderValue'
                component={renderMoneyField}
                currency={this.props.currency}
              />
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Enable Skip for Driver(Other Delivery)
                </label>
                <div
                  className='col-sm-6 col-md-6'
                  style={{ marginTop: '12px' }}
                >
                  <div className='input-group'>
                    <Field
                      name='isSkipEnabledForOD'
                      id='isSkipEnabledForOD'
                      component='input'
                      type='checkbox'
                    />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-3 col-md-3 control-label'>
                  Delivery Free Over
                </label>
                <div className='col-md-6 col-sm-6'>
                  <div className='input-group xs-mb-15'>
                    <div className='input-group-addon'>
                      <Field
                        name='isDeliveryFreeOver'
                        id='isDeliveryFreeOver'
                        component='input'
                        type='checkbox'
                      />
                    </div>
                    <Field
                      type='text'
                      placeholder='$80'
                      labelText='Delivery Free Over'
                      name='deliveryFreeOver'
                      component={renderFieldWithCheckBox}
                    />
                  </div>
                </div>
              </div>
              <Field
                type='number'
                min='1'
                max='60'
                placeholder='35 Minutes'
                labelText='Default Pickup Time(Minutes)'
                name='defaultPickUpTime'
                component={renderNumberField}
              />
              <Field
                type='number'
                min='1'
                max='60'
                placeholder='55 Minutes'
                labelText='Default Delivery Time(Minutes)'
                name='defaultDeliveryTime'
                component={renderNumberField}
              />
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

OrderSetup.propTypes = {
  saveOrderSetupHandler: PropTypes.any,
  orderSetup: PropTypes.any,
};

OrderSetup = reduxForm({
  form: 'orderSetup',
  enableReinitialize: true,
  validate,
  warn,
  initialValues: { preOrderingDays: 5 },
})(OrderSetup);

// You have to connect() to any reducers that you wish to connect to yourself
OrderSetup = connect(
  (state) => ({
    initialValues: state.orderSetupReducer
      ? state.orderSetupReducer.orderData
      : {}, // pull initial values from account reducer
  }),
  { load: loadOrderSetup } // bind account loading action creator
)(OrderSetup);

export default OrderSetup;
