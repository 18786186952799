import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import Layout from '../../containers/Layout';
import { auth, firestore } from '../../firebase';
import getOrderStatus from '../../helpers/getOrderStatus';
import Spinner from '../../components/Spinner';
import OtherOrderModal from '../../components/OtherOrderModal';
import {
  getTimestampStartOfTheDay,
  convertToMomentDate,
} from '../../helpers/timeHelper';
import { getOtherOrders, getOtherOrdersCount } from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class CurrentOtherDeliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      isDisplayOrder: false,
      selectedOrder: {},
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/other-delivery/current-other-deliveries'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getOtherOrdersHandler(
                      doc.data().storeId,
                      this.state.pagination,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    this.props.getOtherOrdersCountHandler(
                      doc.data().storeId,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      currentOtherOrders: newProps.otherOrder.currentOtherOrders,
      totalCount: newProps.otherOrder.totalCount,
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        currentOtherOrders: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getOtherOrdersHandler(
          this.state.storeId,
          this.state.pagination,
          getTimestampStartOfTheDay(this.state.timeZone)
        );
      }
    );
  };

  openOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOrder: true });
  };

  closeOrderModal = () => {
    this.setState({ isDisplayOrder: false, selectedOrder: {} });
  };

  render() {
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.currentOtherOrders
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.currentOtherOrders.length -
        1
      : 0;
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className='panel panel-default panel-table '>
                <div className='panel-heading'>Current Other Deliveries</div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                          <thead>
                            <tr>
                              <th>Order Address</th>
                              <th>Status</th>
                              <th>Mobile Number</th>
                              <th>Date</th>
                              <th />
                            </tr>
                          </thead>
                          {!this.state.currentOtherOrders ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : this.state.currentOtherOrders.length > 0 ? (
                            <tbody>
                              {this.state.currentOtherOrders &&
                                this.state.currentOtherOrders.map(
                                  (order, index) => (
                                    <tr key={index}>
                                      <td>{order.address}</td>
                                      <td>
                                        {getOrderStatus(order.orderStatus, '4')}
                                      </td>
                                      <td>{order.mobileNumber}</td>
                                      <td>
                                        {convertToMomentDate(
                                          order.date,
                                          'YYYY-MM-DD HH:mm',
                                          this.state.timeZone
                                        ).format('MMM DD, YYYY')}
                                        <br />
                                        <span className='grayc'>
                                          {convertToMomentDate(
                                            order.date,
                                            'YYYY-MM-DD HH:mm',
                                            this.state.timeZone
                                          ).format('hh:mm a')}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          onClick={() =>
                                            this.openOrderModal(order)
                                          }
                                          className='btn btn-space btn-default'
                                        >
                                          View
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td>
                                  <p> No records to display</p>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.state.totalCount ? this.state.totalCount : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isDisplayOrder && (
            <OtherOrderModal
              isDisplayOrder={this.state.isDisplayOrder}
              closeOrderModal={this.closeOrderModal}
              selectedOrder={this.state.selectedOrder}
              timeZone={this.state.timeZone}
              storeId={this.state.storeId}
            />
          )}
        </div>
      </Layout>
    );
  }
}

CurrentOtherDeliveries.propTypes = {};

function mapStateToProps(state) {
  return {
    otherOrder: state.otherOrderReducer,
    order: state.orderReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOtherOrdersHandler: (storeId, pagination, dateToday) =>
      dispatch(getOtherOrders(storeId, pagination, dateToday)),
    getOtherOrdersCountHandler: (storeId, dateToday) =>
      dispatch(getOtherOrdersCount(storeId, dateToday)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentOtherDeliveries);
