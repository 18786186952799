import React, { Component } from 'react';
import PropTypes from 'prop-types';
const L = window.L;

let mapStyle = {
  minHeight: '220px !important',
};

class LeafletStore extends Component {
  constructor(props) {
    super(props);
    this.map = {};
    this.marker = [];
  }
  componentDidMount() {
    if (
      (this.props.order.orderStatus === '0' ||
        this.props.order.orderStatus === '1' ||
        this.props.order.orderStatus === '11') &&
      this.props.store &&
      this.props.storeId
    ) {
      let iconStore = L.icon({
        iconUrl: `https://s3-ap-southeast-2.amazonaws.com/lacampa/assets/S+P+I+con.svg`,
        iconSize: [40, 50], // size of the icon
        iconAnchor: [20, 50],
      });
      L.mapbox.accessToken =
        'pk.eyJ1Ijoid2lsbG9yaWVudCIsImEiOiJjanZ6eDRqMXMwNTI4NDhvbmVsbjhhdmhzIn0.SXJqaMi8L08EwIHZf7pRIg';
      this.map = L.mapbox
        .map('map', null, {
          styles: mapStyle,
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: false,
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          maxZoom: 16,
          scrollWheelZoom: false,
        })
        .setView(
          [Number(this.props.store.lat), Number(this.props.store.long)],
          16
        )
        .addLayer(
          L.mapbox.styleLayer(
            'mapbox://styles/williamrichards/cjvc2w0fo6alc1fqxknvqjgoy',
            {
              tileSize: 256,
            }
          )
        );

      //Adding google layer

      //Showing only store when order is in kitchen, on the way or cancelled
      this.store = L.marker([this.props.store.lat, this.props.store.long], {
        rotationOrigin: 'center',
        icon: iconStore,
      }).addTo(this.map);
    }
  }
  render() {
    return <div ref='map' id='map' />;
  }
}

LeafletStore.propTypes = {
  store: PropTypes.any,
  order: PropTypes.any,
};

export default LeafletStore;
