import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import Spinner from '../../components/Spinner';
import Pagination from 'react-js-pagination';
import { Line } from 'react-chartjs-2';
import Ratings from '../../components/Ratings';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import getOrderStatus from '../../helpers/getOrderStatus';
import {
  getCurrentFormattedMomentDate,
  convertToMomentDate,
  getCurrentMomentDate,
} from '../../helpers/timeHelper';
import {
  getReservationsStatisticsCount,
  getReservationsStatistics,
  getReservationStats,
} from '../../containers/ReservationStatistics/actions';
import { getStoreReviewStats } from '../Layout/actions';
import { getReviewsStats } from '../../containers/Reviews/actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class ReservationStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      isDisplayReservation: false,
      selectedReservation: {},
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/reservation/reservation-statistics'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getReservationsHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getReservationsCountHandler(doc.data().storeId);
                    this.props.getReservationStatsHandler(doc.data().storeId);
                    this.props.getReviewsStatsHandler(doc.data().storeId);
                    this.props.getStoreReviewStatsHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      reservations: newProps.reservation.reservations,
      totalCount: newProps.reservation.totalCount,
      reservationStats: newProps.reservation.reservationStats,
      reviewStats: newProps.review.reviewStats,
      storeReviewStats: newProps.layout.storeReviewStats,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        reservations: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getReservationsHandler(
          this.state.storeId,
          this.state.pagination
        );
      }
    );
  };

  getAvgRating = () => {
    let reviewObj = { key: '', rating: 'N/A' };
    if (
      this.state.storeReviewStats &&
      this.state.storeReviewStats.averageReservationRating
    ) {
      reviewObj.rating = this.state.storeReviewStats.averageReservationRating;
      reviewObj.rating = reviewObj.rating.toFixed(1);
      reviewObj.rating = parseInt(reviewObj.rating, 10);
      reviewObj.key = this.state.storeReviewStats.storeId;
    }
    return reviewObj;
  };

  getAvgRatingPercentage = (rating) => {
    if (this.state.reviewStats) {
      let tReviews = 0;
      this.state.reviewStats.map((item) => {
        tReviews = tReviews + item.totalReservationReviews;
        return item;
      });
      const reviewPickups = this.state.reviewStats.filter((obj) => {
        return obj.rating === rating;
      });
      if (reviewPickups && reviewPickups.length > 0) {
        return parseInt(
          (reviewPickups[0].totalReservationReviews * 100) / tReviews,
          10
        );
      } else return 0;
    } else return 0;
  };

  openReservationModal = (reservation) => {
    this.setState({
      selectedReservation: reservation,
      isDisplayReservation: true,
    });
  };

  closeReservationModal = () => {
    this.setState({
      isDisplayReservation: false,
      showDriverWarning: false,
      showDriverWarningForD: false,
      selectedReservation: {},
    });
  };

  closeWarnings = () => {
    this.setState({ showDriverWarning: false, showDriverWarningForD: false });
  };

  openDriverWarningModal = () => {
    this.setState({ showDriverWarning: true });
  };

  openDriverWarningModalD = () => {
    this.setState({ showDriverWarningForD: true });
  };

  getFormattedDate = (date) => {
    return moment(date).format('MMM DD, YYYY');
  };

  getTimeFromDate = (date) => {
    return moment(date).format('HH:mm');
  };

  getReservationStatusClass = (status) => {
    if (status === '1') return 'form-group has-warning';
    else if (status === '2') return 'form-group has-success';
    else if (status === '3') return 'form-group has-error';
  };

  updateReservationStatus = (status) => {
    let reservation = this.state.selectedReservation;
    reservation.reservationStatus = status;
    this.props.updateReservationHandler(reservation, this.state.pagination);
    this.props.getReservationsHandler();
    this.closeReservationModal();
  };

  getNumberOfDays = (date, day) => {
    let end = getCurrentMomentDate(this.state.timeZone);
    let start = convertToMomentDate(
      date,
      'YYYY-MM-DD HH:mm',
      this.state.timeZone
    );
    return end.diff(start, 'week');
    // var arr = [];
    // // Get "next" monday
    // let tmp = start.clone().day(day);
    // if (tmp.isSameOrAfter(start, 'd')) {
    //   arr.push(tmp.format('YYYY-MM-DD'));
    // }
    // while (tmp.isBefore(end)) {
    //   tmp.add(7, 'days');
    //   arr.push(tmp.format('YYYY-MM-DD'));
    // }
    // return arr.length;
  };

  processGraphData = () => {
    if (this.state.reservationStats) {
      let weekDays = [
        { index: 1, day: 'Mon' },
        { index: 2, day: 'Tue' },
        { index: 3, day: 'Wed' },
        { index: 4, day: 'Thu' },
        { index: 5, day: 'Fri' },
        { index: 6, day: 'Sat' },
        { index: 7, day: 'Sun' },
      ];
      weekDays.map((day) => {
        day.reservations = this.state.reservationStats.filter((obj) => {
          return obj.day === day.index;
        });
        if (day.reservations.length > 0)
          day.totalReservations = day.reservations[0].totalReservations;
        else day.totalReservations = 0;

        day.date =
          day.reservations.length > 0
            ? day.reservations[0].date
            : getCurrentFormattedMomentDate(
                this.state.timeZone,
                'YYYY-MM-DD HH:mm'
              );
        return day;
      });
      var data = {
        labels: ['Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'],
        datasets: [
          {
            label: 'Number of Reservations',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgb(255, 206, 86)',
            borderColor: 'rgb(255, 206, 86)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(4, 5, 4)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255, 206, 86)',
            pointHoverBorderColor: 'rgb(255, 206, 86)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [],
          },
        ],
      };
      weekDays.map((day) => {
        const avgRes =
          this.getNumberOfDays(day.date, day.index) !== 0
            ? day.totalReservations / this.getNumberOfDays(day.date, day.index)
            : day.totalReservations;
        data.datasets[0].data.push(parseInt(Math.round(Number(avgRes))));
        return day;
      });
      return data;
    }
  };

  render() {
    const chartData = this.processGraphData();
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.reservations
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.reservations.length -
        1
      : 0;
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-xs-12 col-md-12'>
              <div className='panel panel-default panel-table '>
                <div className='panel-heading'>Reservations Statistics</div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                          <thead>
                            <tr>
                              <th>Customer Name</th>
                              <th>Adults</th>
                              <th>Children </th>
                              <th>Infant</th>
                              <th>Date and time</th>
                              <th>Status</th>
                              <th />
                            </tr>
                          </thead>
                          {!this.state.reservations ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody className='no-border-x'>
                              {this.state.reservations &&
                                this.state.reservations.map((res, index) => (
                                  <tr key={index}>
                                    <td>{res.userName}</td>
                                    <td>{res.adults}</td>
                                    <td>{res.children}</td>
                                    <td>{res.infants}</td>
                                    <td>
                                      {this.getFormattedDate(
                                        res.reservationDate
                                      )}
                                      <br />
                                      <span className='grayc'>
                                        {this.getTimeFromDate(
                                          res.reservationDate
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      {getOrderStatus(
                                        res.reservationStatus,
                                        '3'
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          this.openReservationModal(res)
                                        }
                                        className='btn btn-space btn-default'
                                      >
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.state.totalCount ? this.state.totalCount : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel-heading-divider'>
                  <span className='title'>Average Reservations by Day</span>
                </div>
                <div className='divider1' />
                <div className='panel-body'>
                  {this.state.reservationStats ? (
                    <Line data={chartData} />
                  ) : (
                    <div
                      style={{ padding: '50px' }}
                      className='be-loading be-loading-active'
                    >
                      <div className='be-spinner'>
                        <svg
                          width='40px'
                          height='40px'
                          viewBox='0 0 66 66'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle
                            fill='none'
                            strokeWidth='4'
                            strokeLinecap='round'
                            cx='33'
                            cy='33'
                            r='30'
                            className='circle'
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel-heading-divider xs-pb-15'>
                  <span className='title'>Average Customer Rating</span>
                </div>
                <div className='divider1' />
                <div className='panel-body xs-pt-25'>
                  <div className='row user-progress user-progress-small'>
                    <div className='hreviewlab1'>
                      <span className='hreviewlab'>
                        {this.state.storeReviewStats
                          ? this.getAvgRating().rating
                          : '0'}
                      </span>
                      {this.state.storeReviewStats && (
                        <Ratings
                          review={this.getAvgRating()}
                          rating={this.getAvgRating().rating}
                        />
                      )}
                    </div>
                    <div className='clear' />
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>5</span>
                    </div>
                    <div className='col-md-10 col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(5)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>4</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(4)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>3</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(3)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>2</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(2)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>1</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(1)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isDisplayReservation && (
            <Modal
              aria-labelledby='modal-label'
              className='modal fade'
              show={this.state.isDisplayReservation}
              onHide={this.closeReservationModal}
              bsSize='sm'
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    onClick={this.closeReservationModal}
                    type='button'
                    data-dismiss='modal'
                    aria-hidden='true'
                    className='close md-close'
                  >
                    <span className='mdi mdi-close' />
                  </button>
                </div>
                <div className='modal-body'>
                  <div className='col-md-12'>
                    <div className='text-center'>
                      <form
                        action='#'
                        style={{ borderRadius: '0px' }}
                        className='form-horizontal group-border-dashed'
                      >
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Customer Name
                          </label>
                          <div className='col-md-8'>
                            <input
                              value={this.state.selectedReservation.userName}
                              readOnly='readonly'
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Mobile Number
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={
                                this.state.selectedReservation.mobileNumber
                              }
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Adults
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={this.state.selectedReservation.adults}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Children
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={this.state.selectedReservation.children}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Infant
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={this.state.selectedReservation.infants}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Date and Time
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={`${this.getFormattedDate(
                                this.state.selectedReservation.reservationDate
                              )} - ${this.getTimeFromDate(
                                this.state.selectedReservation.reservationDate
                              )}`}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div
                          className={this.getReservationStatusClass(
                            this.state.selectedReservation.reservationStatus
                          )}
                        >
                          <label className='col-md-3 control-label'>
                            Status
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={getOrderStatus(
                                this.state.selectedReservation
                                  .reservationStatus,
                                '3'
                              )}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    onClick={this.closeReservationModal}
                    type='button'
                    data-dismiss='modal'
                    className='btn btn-default md-close'
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </Layout>
    );
  }
}

ReservationStatistics.propTypes = {};

function mapStateToProps(state) {
  return {
    reservation: state.reservationsStatisticReducer,
    layout: state.layoutReducer,
    review: state.reviewReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReservationsHandler: (storeId, pagination) =>
      dispatch(getReservationsStatistics(storeId, pagination)),
    getReservationsCountHandler: (storeId) =>
      dispatch(getReservationsStatisticsCount(storeId)),
    getReservationStatsHandler: (storeId) =>
      dispatch(getReservationStats(storeId)),
    getReviewsStatsHandler: (storeId) => dispatch(getReviewsStats(storeId)),
    getStoreReviewStatsHandler: (storeId) =>
      dispatch(getStoreReviewStats(storeId)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationStatistics);
