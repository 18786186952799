import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import Spinner from '../../components/Spinner';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import WarningModal from '../../components/WarningModal';
import { getTimestampStartOfTheDay } from '../../helpers/timeHelper';
import {
  getReservations,
  getReservationsCount,
  updateReservation,
} from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';
import getOrderStatus from '../../helpers/getOrderStatus';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class CurrentReservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      isDisplayReservation: false,
      selectedReservation: {},
      showDriverWarning: false,
      showDriverWarningForD: false,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/reservation/current-reservations'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getReservationsHandler(
                      doc.data().storeId,
                      this.state.pagination,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                    this.props.getReservationsCountHandler(
                      doc.data().storeId,
                      getTimestampStartOfTheDay(doc.data().timeZone)
                    );
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      reservations: newProps.reservation.reservations,
      totalCount: newProps.reservation.totalCount,
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        reservations: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getReservationsHandler(
          this.state.storeId,
          this.state.pagination,
          getTimestampStartOfTheDay(this.state.timeZone)
        );
      }
    );
  };

  openReservationModal = (reservation) => {
    this.setState({
      selectedReservation: reservation,
      isDisplayReservation: true,
    });
  };

  closeReservationModal = () => {
    this.setState({
      isDisplayReservation: false,
      showDriverWarning: false,
      showDriverWarningForD: false,
      selectedReservation: {},
    });
  };

  getFormattedDate = (date) => {
    return moment(date).format('MMM DD, YYYY');
  };

  getTimeFromDate = (date) => {
    return moment(date).format('HH:mm');
  };

  getReservationStatusClass = (status) => {
    if (status === '1') return 'form-group has-warning';
    else if (status === '2') return 'form-group has-success';
    else if (status === '3') return 'form-group has-error';
  };

  updateReservationStatus = (status) => {
    let reservation = this.state.selectedReservation;
    reservation.reservationStatus = status;
    this.props.updateReservationHandler(reservation, this.state.timeZone);
    this.closeReservationModal();
  };

  closeWarnings = () => {
    this.setState({ showDriverWarning: false, showDriverWarningForD: false });
  };

  openDriverWarningModal = () => {
    this.setState({ showDriverWarning: true });
  };

  openDriverWarningModalD = () => {
    this.setState({ showDriverWarningForD: true });
  };

  render() {
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.reservations
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.reservations.length -
        1
      : 0;
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-xs-12 col-md-12'>
              <div className='panel panel-default panel-table '>
                <div className='panel-heading'>Current Reservations</div>
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                          <thead>
                            <tr>
                              <th>Customer Name</th>
                              <th>Adults</th>
                              <th>Children </th>
                              <th>Infant</th>
                              <th>Date and time</th>
                              <th>Status</th>
                              <th />
                            </tr>
                          </thead>
                          {!this.state.reservations ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody className='no-border-x'>
                              {this.state.reservations &&
                                this.state.reservations.map((res, index) => (
                                  <tr key={index}>
                                    <td>{res.userName}</td>
                                    <td>{res.adults}</td>
                                    <td>{res.children}</td>
                                    <td>{res.infants}</td>
                                    <td>
                                      {this.getFormattedDate(
                                        res.reservationDate
                                      )}
                                      <br />
                                      <span className='grayc'>
                                        {this.getTimeFromDate(
                                          res.reservationDate
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      {getOrderStatus(
                                        res.reservationStatus,
                                        '3'
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          this.openReservationModal(res)
                                        }
                                        className='btn btn-space btn-default'
                                      >
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.state.totalCount ? this.state.totalCount : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isDisplayReservation && (
            <Modal
              aria-labelledby='modal-label'
              className='modal fade'
              show={this.state.isDisplayReservation}
              onHide={this.closeReservationModal}
              bsSize='sm'
            >
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    onClick={this.closeReservationModal}
                    type='button'
                    data-dismiss='modal'
                    aria-hidden='true'
                    className='close md-close'
                  >
                    <span className='mdi mdi-close' />
                  </button>
                </div>
                <div className='modal-body'>
                  <div className='col-md-12'>
                    <div className='text-center'>
                      <form
                        action='#'
                        style={{ borderRadius: '0px' }}
                        className='form-horizontal group-border-dashed'
                      >
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Customer Name
                          </label>
                          <div className='col-md-8'>
                            <input
                              value={this.state.selectedReservation.userName}
                              readOnly='readonly'
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Mobile Number
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={
                                this.state.selectedReservation.mobileNumber
                              }
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Adults
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={this.state.selectedReservation.adults}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Children
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={this.state.selectedReservation.children}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Infant
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={this.state.selectedReservation.infants}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='col-md-3 control-label'>
                            Date and Time
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={`${this.getFormattedDate(
                                this.state.selectedReservation.reservationDate
                              )} - ${this.getTimeFromDate(
                                this.state.selectedReservation.reservationDate
                              )}`}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                        <div
                          className={this.getReservationStatusClass(
                            this.state.selectedReservation.reservationStatus
                          )}
                        >
                          <label className='col-md-3 control-label'>
                            Status
                          </label>
                          <div className='col-md-8'>
                            <input
                              readOnly='readonly'
                              value={getOrderStatus(
                                this.state.selectedReservation
                                  .reservationStatus,
                                '3'
                              )}
                              className='form-control'
                              type='text'
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    onClick={this.closeReservationModal}
                    type='button'
                    data-dismiss='modal'
                    className='btn btn-default md-close'
                  >
                    Cancel
                  </button>
                  {this.state.selectedReservation &&
                    this.state.selectedReservation.reservationStatus ===
                      '1' && (
                      <React.Fragment>
                        <button
                          onClick={() => this.openDriverWarningModal()}
                          type='button'
                          className='btn btn-space btn-success active'
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => this.openDriverWarningModalD()}
                          type='button'
                          className='btn btn-space btn-danger active'
                        >
                          Decline
                        </button>
                      </React.Fragment>
                    )}
                </div>
              </div>
            </Modal>
          )}
          {this.state.showDriverWarning && (
            <WarningModal
              procced={() => this.updateReservationStatus('2')}
              closeModal={this.closeWarnings}
              message={`This will approve reservation. Click "proceed" to continue.`}
            />
          )}
          {this.state.showDriverWarningForD && (
            <WarningModal
              procced={() => this.updateReservationStatus('3')}
              closeModal={this.closeWarnings}
              message={`This will decline reservation. Click "proceed" to continue.`}
            />
          )}
        </div>
      </Layout>
    );
  }
}

CurrentReservations.propTypes = {};

function mapStateToProps(state) {
  return {
    reservation: state.reservationReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReservationsHandler: (storeId, pagination, dateToday) =>
      dispatch(getReservations(storeId, pagination, dateToday)),
    getReservationsCountHandler: (storeId, dateToday) =>
      dispatch(getReservationsCount(storeId, dateToday)),
    updateReservationHandler: (reservation, timeZone) =>
      dispatch(updateReservation(reservation, timeZone)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentReservations);
