import { firestore } from '../../firebase';
import { nextOrderConfig } from '../../configurations/storeSetup';
import ActionTypes from './constants';

export function getReviews(storeId, pagination, filterString) {
  return (dispatch) => {
    if (!filterString || filterString == null) {
      let currentRef = firestore
        .collection('Reviews')
        .where('storeId', '==', storeId)
        .orderBy('dateTimestamp', 'desc');
      currentRef.get().then((documentSnapshots) => {
        let lastVisible =
          documentSnapshots.docs[
            (pagination.currentPage - 1) * pagination.pageSize - 1
          ];
        if (pagination.currentPage !== 1) {
          let nextRef = firestore
            .collection('Reviews')
            .where('storeId', '==', storeId)
            .orderBy('dateTimestamp', 'desc')
            .startAfter(lastVisible)
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(reviewsFetched(dataArray));
          });
        } else {
          let nextRef = firestore
            .collection('Reviews')
            .where('storeId', '==', storeId)
            .orderBy('dateTimestamp', 'desc')
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(reviewsFetched(dataArray));
          });
        }
      });
    } else {
      let currentRef = firestore
        .collection('Reviews')
        .where('storeId', '==', storeId)
        .where('keywords', 'array-contains', filterString)
        .orderBy('dateTimestamp', 'desc');
      currentRef.get().then((documentSnapshots) => {
        let lastVisible =
          documentSnapshots.docs[
            (pagination.currentPage - 1) * pagination.pageSize - 1
          ];
        if (pagination.currentPage !== 1) {
          let nextRef = firestore
            .collection('Reviews')
            .where('storeId', '==', storeId)
            .where('keywords', 'array-contains', filterString)
            .orderBy('dateTimestamp', 'desc')
            .startAfter(lastVisible)
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(reviewsFetched(dataArray));
          });
        } else {
          let nextRef = firestore
            .collection('Reviews')
            .where('storeId', '==', storeId)
            .where('keywords', 'array-contains', filterString)
            .orderBy('dateTimestamp', 'desc')
            .limit(pagination.pageSize);
          nextRef.onSnapshot((querySnapshot) => {
            var dataArray = [];
            querySnapshot.forEach((doc) => {
              dataArray.push({ _id: doc.id, ...doc.data() });
            });
            dispatch(reviewsFetched(dataArray));
          });
        }
      });
    }
  };
}

export function getReviewCount(storeId, filterString) {
  return (dispatch) => {
    if (!filterString || filterString == null) {
      let ref = firestore.collection('Reviews').where('storeId', '==', storeId);
      ref.onSnapshot((querySnapshot) => {
        dispatch(reviewCountFetched(querySnapshot.docs.length));
      });
    } else {
      let ref = firestore
        .collection('Reviews')
        .where('storeId', '==', storeId)
        .where('keywords', 'array-contains', filterString);
      ref.onSnapshot((querySnapshot) => {
        dispatch(reviewCountFetched(querySnapshot.docs.length));
      });
    }
  };
}

export function getReviewsToday(storeId, dateToday) {
  return (dispatch) => {
    let ref = firestore
      .collection('Reviews')
      .where('storeId', '==', storeId)
      .where('dateTimestamp', '>=', dateToday);
    ref.onSnapshot((querySnapshot) => {
      var reviewsArray = [];
      querySnapshot.forEach((doc) => {
        reviewsArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(recentReviewFetched(reviewsArray));
    });
  };
}

export function getReviewsStats(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('ReviewStatsByRating')
      .where('storeId', '==', storeId)
      .orderBy('rating');
    ref.onSnapshot((querySnapshot) => {
      var reviewsArray = [];
      querySnapshot.forEach((doc) => {
        reviewsArray.push({ _id: doc.id, ...doc.data() });
      });
      dispatch(reviewStatsFetched(reviewsArray));
    });
  };
}

export function getUser(userId) {
  return (dispatch) => {
    let ref = firestore.collection('Users').doc(userId);
    ref.get().then((doc) => {
      let obj = { _id: doc.id, ...doc.data() };
      dispatch(fetchedUser(obj));
    });
  };
}

export function updateReview(review) {
  return (dispatch) => {
    firestore
      .collection('Reviews')
      .doc(review._id)
      .set(review)
      .then(() => {});
  };
}

export function deleteReview(review) {
  return (dispatch) => {
    firestore
      .collection('Reviews')
      .doc(review._id)
      .delete()
      .then(() => {});
  };
}

export function addVoucherAndSMS(obj, smsObj, review) {
  return (dispatch) => {
    if (obj.voucherCode) {
      let keywords = [];
      let objString = obj.voucherCode.toUpperCase();
      let arr = objString.split(' ');
      let outcome = '';
      [...objString].forEach((c) => {
        outcome = outcome + c;
        keywords.push(outcome);
      });
      if (arr.length > 0) {
        arr.forEach((s, i) => {
          if (i !== 0) {
            let outcome1 = '';
            [...s].forEach((c) => {
              outcome1 = outcome1 + c;
              keywords.push(outcome1);
            });
          }
        });
      }
      obj.keywords = keywords;
    }
    let ref = firestore.collection('Vouchers').doc(obj);
    ref.set(obj).then(() => {
      fetch(`${nextOrderConfig.cloudFunctionUrl}/sendSMS`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...smsObj,
        }),
      })
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            // Get a new write batch
            var batchForSMS = firestore.batch();
            batchForSMS.update(
              firestore.collection('Reviews').doc(review._id),
              {
                isVoucherMsgSent: true,
              }
            );
            batchForSMS.update(firestore.collection('Vouchers').doc(ref.id), {
              isVoucherMsgSent: true,
            });
            // Commit the batch
            batchForSMS.commit().then(() => {});
          }
        });
    });
  };
}

export function getStore(storeId) {
  return (dispatch) => {
    firestore
      .collection('Stores')
      .doc(storeId)
      .onSnapshot((doc) => {
        dispatch(storeFetched({ _id: doc.id, ...doc.data() }));
      });
  };
}

function reviewsFetched(reviews) {
  return {
    type: ActionTypes.FetchReviews,
    reviews,
  };
}

function recentReviewFetched(reviews) {
  return {
    type: ActionTypes.FetchRecentReviews,
    reviews,
  };
}

function fetchedUser(user) {
  return {
    type: ActionTypes.FetchUser,
    user,
  };
}

function reviewCountFetched(totalCount) {
  return {
    type: ActionTypes.FetchReviewCount,
    totalCount,
  };
}

function reviewStatsFetched(reviewStats) {
  return {
    type: ActionTypes.FetchReviewStats,
    reviewStats,
  };
}

function storeFetched(store) {
  return {
    type: ActionTypes.FetchStore,
    store,
  };
}
