import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import { nextOrderConfig } from '../../configurations/storeSetup';
import OfferList from '../../components/OfferList';
import OfferForm from '../../components/OfferForm';
import { getOffers, getOffersCount, addOffer, deleteOffer } from './actions';
import {
  getCategories,
  getSizes,
  getMenuItems,
} from '../../containers/Menu/actions';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/loyalty/offers'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getOffersHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getOffersCountHandler(doc.data().storeId);
                    this.props.getCategoriesHandler(doc.data().storeId);
                    this.props.getSizesHandler(doc.data().storeId);
                    this.props.getMenuItemsHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        offers: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getOffersHandler(this.state.storeId, this.state.pagination);
      }
    );
  };

  addOfferHandler = (offerObj) => {
    offerObj.storeId = this.state.storeId;
    offerObj.restaurantId = this.state.restaurantId;
    offerObj.isActive = true;
    offerObj.isDeleted = false;
    this.props.addOfferHandler(offerObj);
    this.resetOffer();
  };

  setOfferForEdit = (offer) => {
    this.setState({
      isEditModeOn: true,
      offerForEdit: offer,
    });
  };

  resetOffer = () => {
    this.setState({
      isEditModeOn: false,
      offerForEdit: {},
    });
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    let totalCount = this.props.offers.totalCount
      ? this.props.offers.totalCount
      : 0;
    let offers = this.props.offers.offers ? this.props.offers.offers : [];
    let categories = this.props.menu.categories
      ? this.props.menu.categories
      : [];
    let sizes = this.props.menu.allSizes ? this.props.menu.allSizes : [];
    let menuItems = this.props.menu.menuItems ? this.props.menu.menuItems : [];
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <OfferList
            offers={offers}
            pagination={this.state.pagination}
            totalCount={totalCount}
            handlePageChange={this.handlePageChange}
            timeZone={this.state.timeZone}
            setOfferForEdit={this.setOfferForEdit}
            deleteOffer={this.props.deleteOfferHandler}
            currency={currency}
          />
          <OfferForm
            offers={offers}
            categories={categories}
            sizes={sizes}
            menuItems={menuItems}
            timeZone={this.state.timeZone}
            storeId={this.state.storeId}
            isEditModeOn={this.state.isEditModeOn}
            offerForEdit={this.state.offerForEdit}
            addOfferHandler={this.addOfferHandler}
            resetOffer={this.resetOffer}
            currency={currency}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    offers: state.offersReducer,
    menu: state.menuReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOffersHandler: (storeId, pagination) =>
      dispatch(getOffers(storeId, pagination)),
    getOffersCountHandler: (storeId) => dispatch(getOffersCount(storeId)),
    getCategoriesHandler: (storeId) => dispatch(getCategories(storeId)),
    getSizesHandler: (storeId) => dispatch(getSizes(storeId)),
    getMenuItemsHandler: (storeId) => dispatch(getMenuItems(storeId)),
    addOfferHandler: (obj) => dispatch(addOffer(obj)),
    deleteOfferHandler: (obj, type) => dispatch(deleteOffer(obj, type)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
