import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { getCurrentFormattedMomentDate } from '../../helpers/timeHelper';

class CustomerRetention extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year:
        this.props.timeZone &&
        getCurrentFormattedMomentDate(this.props.timeZone, 'YYYY'),
      yearList: [
        this.props.timeZone &&
          getCurrentFormattedMomentDate(this.props.timeZone, 'YYYY'),
      ],
    };
  }

  componentWillReceiveProps(props) {
    if (props.yearList) {
      this.setState({
        yearList: props.yearList,
      });
    }
  }

  processGraphData = () => {
    let list = [
      {
        month: 'Jan',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Feb',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Mar',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Apr',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'May',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Jun',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Jul',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Aug',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Sep',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Oct',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Nov',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
      {
        month: 'Dec',
        newUserOrders: 0,
        oldUserOrders: 0,
      },
    ];

    const custRetBreakdown = {
      labels: [],
      datasets: [
        {
          label: 'Existing Cutomers',
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(255, 99, 132)',
          hoverBorderColor: 'rgb(255, 99, 132)',
          data: [],
        },
        {
          label: 'New Customers',
          backgroundColor: 'rgb(40, 19, 132)',
          borderColor: 'rgb(40, 19, 132)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(40, 19, 132)',
          hoverBorderColor: 'rgb(40, 19, 132)',
          data: [],
        },
      ],
    };
    if (this.props.customerRetention) {
      let months = this.props.customerRetention;
      list.map((item) => {
        let matchingObj = months.filter((m) => {
          return m.month.toLowerCase() === item.month.toLowerCase();
        });
        if (matchingObj.length > 0) {
          custRetBreakdown.labels.push(item.month);
          custRetBreakdown.datasets[0].data.push(matchingObj[0].oldUserOrders);
          custRetBreakdown.datasets[1].data.push(matchingObj[0].newUserOrders);
        } else {
          custRetBreakdown.labels.push(item.month);
          custRetBreakdown.datasets[0].data.push(0);
          custRetBreakdown.datasets[1].data.push(0);
        }
        return item;
      });
      return custRetBreakdown;
    }
  };

  changeYear = (e) => {
    this.setState({ year: e.target.value }, () => {
      this.props.getCustomerRetentionHandler(this.state.year);
    });
  };

  render() {
    const custRetBreakdown = this.processGraphData();
    return (
      <div className='row'>
        <div className='col-md-6 col-sm-12 col-xs-12'>
          <div className='panel panel-default'>
            <div className='panel-heading panel-heading-divider'>
              <div className='title col-sm-12 col-md-5'>Customer Retention</div>
              <div className='col-md-7 col-sm-12'>
                <div className='form-group'>
                  <select
                    defaultValue={this.state.year}
                    onChange={this.changeYear}
                    className='form-control'
                  >
                    {this.state.yearList &&
                      this.state.yearList.map((item, index) => (
                        <option
                          selected={index === this.state.yearList.length - 1}
                          key={index}
                          value={item}
                        >
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='panel-body'>
              {this.props.customerRetention ? (
                <Bar data={custRetBreakdown} />
              ) : (
                <div
                  style={{ padding: '80px' }}
                  className='be-loading be-loading-active'
                >
                  <div className='be-spinner'>
                    <svg
                      width='40px'
                      height='40px'
                      viewBox='0 0 66 66'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <circle
                        fill='none'
                        strokeWidth='4'
                        strokeLinecap='round'
                        cx='33'
                        cy='33'
                        r='30'
                        className='circle'
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CustomerRetention.propTypes = {
  customerRetention: PropTypes.any,
  getCustomerRetentionHandler: PropTypes.any,
  yearList: PropTypes.any,
};

export default CustomerRetention;
