import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Layout from '../../containers/Layout';
import { Chart, Pie, Doughnut, Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import getOrderStatus from '../../helpers/getOrderStatus';
import OrderModal from '../../components/OrderModal';
import TopPickupDeliveryMenuItems from '../../components/TopPickupDeliveryMenuItems';
import Ratings from '../../components/Ratings';
import Pagination from 'react-js-pagination';
import {
  getCurrentMomentDate,
  convertToMomentDate,
  getCurrentFormattedMomentDate,
} from '../../helpers/timeHelper';
import Spinner from '../../components/Spinner';
import {
  getOrders,
  getOrdersCount,
  getMenuItems,
  getMenuItemsCount,
} from './actions';
import {
  getOrderStats,
  updateOrder,
} from '../../containers/CurrentDeliveries/actions';
import { getReviewsStats } from '../../containers/Reviews/actions';
import { getStoreOrderStats, getStoreReviewStats } from '../Layout/actions';
import { nextOrderConfig } from '../../configurations/storeSetup';
import RefundModal from '../../components/RefundModal';
import InvoiceModal from '../../components/InvoiceModal';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class PickupStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
      mPagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
      mTotalCount: 0,
      mSorting: [
        { id: 0, name: 'name', dir: 'both', class: 'sorting', isSorted: false },
        {
          id: 1,
          name: 'totalPickupOrders',
          dir: 'both',
          class: 'sorting',
          isSorted: false,
        },
        {
          id: 2,
          name: 'pickupRevenue',
          dir: 'desc',
          class: 'sorting_desc',
          isSorted: true,
        },
      ],
      isDisplayOrder: false,
      selectedOrder: {},
    };
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          //Get ctx from string
          var ctx = chart.chart.ctx;

          //Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || 'Arial';
          var txt = centerConfig.text;
          var color = centerConfig.color || '#000';
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated =
            (sidePadding / 100) * (chart.innerRadius * 2);
          //Start with a base font of 30px
          ctx.font = '40px ' + fontStyle;

          //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = chart.innerRadius * 2;

          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight);

          //Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.font = fontSizeToUse + 'px ' + fontStyle;
          ctx.fillStyle = color;

          //Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      },
    });
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/pickup/pickup-statistics'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getOrdersHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getOrdersCountHandler(doc.data().storeId);
                    this.props.getMenuItemsHandler(
                      doc.data().storeId,
                      this.state.mPagination,
                      this.state.mSorting[2]
                    );
                    this.props.getMenuItemsCountHandler(doc.data().storeId);
                    this.props.getReviewsStatsHandler(doc.data().storeId);
                    this.props.getOrderStatsHandler(doc.data().storeId);
                    this.props.getStoreOrderStatsHandler(doc.data().storeId);
                    this.props.getStoreReviewStatsHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
        user.getIdToken(true).then((idToken) => {
          this.setState({
            accessToken: idToken,
          });
        });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      totalCount: newProps.pickupStatistics.totalCount,
      pickupOrders: newProps.pickupStatistics.pickupOrders,
      menuItemsP: newProps.pickupStatistics.menuItemsP,
      mTotalCount: newProps.pickupStatistics.totalCountP,
      reviewStats: newProps.review.reviewStats,
      orderStats: newProps.order.orderStats,
      storeOrderStats: newProps.layout.storeOrderStats,
      storeReviewStats: newProps.layout.storeReviewStats,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handlePageChange = (e) => {
    this.setState(
      {
        pickupOrders: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getOrdersHandler(this.state.storeId, this.state.pagination);
      }
    );
  };

  mHandlePageChange = (e) => {
    this.setState(
      {
        menuItems: undefined,
        mPagination: { ...this.state.mPagination, currentPage: e },
      },
      () => {
        let sortC = this.state.mSorting.filter((m) => {
          return m.isSorted === true;
        });
        if (sortC.length > 0) {
          this.props.getMenuItemsHandler(
            this.state.storeId,
            this.state.mPagination,
            sortC[0]
          );
        }
      }
    );
  };

  mSortMe = (i) => {
    this.state.mSorting.map((s) => {
      if (s.id === i) {
        if (s.dir === 'both') {
          s.dir = 'desc';
          s.class = 'sorting_desc';
          s.isSorted = true;
        } else if (s.dir === 'asc') {
          s.dir = 'desc';
          s.class = 'sorting_desc';
          s.isSorted = true;
        } else if (s.dir === 'desc') {
          s.dir = 'asc';
          s.class = 'sorting_asc';
          s.isSorted = true;
        }
      } else {
        s.dir = 'both';
        s.class = 'sorting';
        s.isSorted = false;
      }
      return s;
    });
    let sortC = this.state.mSorting.filter((m) => {
      return m.isSorted === true;
    });
    if (sortC.length > 0) {
      this.setState(
        {
          mSorting: this.state.mSorting,
          menuItems: undefined,
        },
        () => {
          this.props.getMenuItemsHandler(
            this.state.storeId,
            this.state.mPagination,
            sortC[0]
          );
        }
      );
    }
  };

  openOrderModal = (order) => {
    this.setState({ selectedOrder: order, isDisplayOrder: true });
  };

  closeOrderModal = () => {
    this.setState({ isDisplayOrder: false, selectedOrder: {} });
  };

  processPieGraphData = () => {
    const ccBreakdown = {
      labels: ['Total Cash Orders', 'Total Card Orders'],
      datasets: [
        {
          data: [],
          backgroundColor: ['#FF6384', '#36A2EB'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB'],
        },
      ],
    };
    ccBreakdown.datasets[0].data.push(
      this.state.storeOrderStats && this.state.storeOrderStats.totalCashPickups
        ? this.state.storeOrderStats.totalCashPickups
        : 0
    );
    ccBreakdown.datasets[0].data.push(
      this.state.storeOrderStats && this.state.storeOrderStats.totalCardPickups
        ? this.state.storeOrderStats.totalCardPickups
        : 0
    );
    return ccBreakdown;
  };

  getNumberOfDays = (date, day) => {
    let end = getCurrentMomentDate(this.state.timeZone);
    let start = convertToMomentDate(
      date,
      'YYYY-MM-DD HH:mm',
      this.state.timeZone
    );
    return end.diff(start, 'week');
  };

  processLineGraphData = () => {
    if (this.state.orderStats) {
      let weekDays = [
        { index: 1, day: 'Mon' },
        { index: 2, day: 'Tue' },
        { index: 3, day: 'Wed' },
        { index: 4, day: 'Thu' },
        { index: 5, day: 'Fri' },
        { index: 6, day: 'Sat' },
        { index: 7, day: 'Sun' },
      ];
      weekDays.map((day) => {
        day.pickups = this.state.orderStats.filter((obj) => {
          return obj.day === day.index;
        });
        if (day.pickups.length > 0)
          day.totalPickupOrders = day.pickups[0].totalPickupOrders;
        else day.totalPickupOrders = 0;

        day.date =
          day.pickups.length > 0
            ? day.pickups[0].date
            : getCurrentFormattedMomentDate(
                this.state.timeZone,
                'YYYY-MM-DD HH:mm'
              );
        return day;
      });
      var data = {
        labels: ['Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'],
        datasets: [
          {
            label: 'Number of pickup',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgb(255, 206, 86)',
            borderColor: 'rgb(255, 206, 86)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(4, 5, 4)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgb(255, 206, 86)',
            pointHoverBorderColor: 'rgb(255, 206, 86)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [],
          },
        ],
      };
      weekDays.map((day) => {
        const avgRes =
          this.getNumberOfDays(day.date, day.index) !== 0
            ? day.totalPickupOrders / this.getNumberOfDays(day.date, day.index)
            : day.totalPickupOrders;
        data.datasets[0].data.push(parseInt(Math.round(Number(avgRes))));
        return day;
      });
      return data;
    }
  };

  isInt = (value) => {
    var er = /^-?[0-9]+$/;
    return er.test(value);
  };

  getAvgRating = () => {
    let reviewObj = { key: '', rating: 'N/A' };
    if (
      this.state.storeReviewStats &&
      this.state.storeReviewStats.averagePickupRating
    ) {
      reviewObj.rating = this.state.storeReviewStats.averagePickupRating;
      reviewObj.rating = reviewObj.rating.toFixed(1);
      reviewObj.rating = parseInt(reviewObj.rating, 10);
      reviewObj.key = this.state.storeReviewStats.storeId;
    }
    return reviewObj;
  };

  getAvgRatingPercentage = (rating) => {
    if (this.state.reviewStats) {
      let tReviews = 0;
      this.state.reviewStats.map((item) => {
        tReviews = tReviews + item.totalPickupReviews;
        return item;
      });
      const reviewPickups = this.state.reviewStats.filter(function (obj) {
        return obj.rating === rating;
      });
      if (reviewPickups && reviewPickups.length > 0) {
        return parseInt(
          (reviewPickups[0].totalPickupReviews * 100) / tReviews,
          10
        );
      } else return 0;
    } else return 0;
  };

  getLabel = (tooltipItems, data) => {
    return data.labels[tooltipItems[0].index];
  };

  getLabelData = (tooltipItem, data) => {
    return `${data.datasets[0].data[tooltipItem.index]}`;
  };

  processDonugtDataForCost = () => {
    const averagePickSpent = this.state.storeOrderStats
      ? this.state.storeOrderStats.averagePickupSpent
      : 0;
    const data = {
      labels: ['Average Pickup Spent'],
      datasets: [
        {
          data: [averagePickSpent],
          backgroundColor: ['#FBBC05'],
          hoverBackgroundColor: ['#FBBC05'],
        },
      ],
    };
    return data;
  };

  checkOrderDateDiff = (dateTimestamp) => {
    let now = new Date();
    let currentTimestamp = now.getTime();
    let timestampDiff = currentTimestamp - dateTimestamp;
    let daysDifference = Math.floor(timestampDiff / 1000 / 60 / 60 / 24);
    if (daysDifference > 7) return false;
    return true;
  };

  closeRefundPopup = () => {
    this.setState({
      openRefundPopup: false,
    });
  };

  refundOrder = (order) => {
    this.setState({
      openRefundPopup: true,
      refundOrder: order,
    });
  };

  renderRefundStatus = (order) => {
    if (
      order.orderStatus === '1' &&
      order.refundStatus !== 0 &&
      order.refundStatus !== 1 &&
      order.refundStatus !== 2 &&
      order.refundStatus !== 3 &&
      order.paymentType === '2' &&
      this.checkOrderDateDiff(order.dateTimestamp)
    ) {
      return (
        <button
          onClick={() => this.refundOrder(order)}
          className='btn btn-space btn-success'
        >
          Refund
        </button>
      );
    } else if (order.refundStatus === 0) {
      return (
        <React.Fragment>
          <span>Refund Initiated</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 1) {
      return (
        <React.Fragment>
          <span>Refunded</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 2) {
      return (
        <React.Fragment>
          <span>Refund Failed</span>
        </React.Fragment>
      );
    } else if (order.refundStatus === 3) {
      return (
        <React.Fragment>
          <span>Refund Rejected</span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span></span>
        </React.Fragment>
      );
    }
  };

  closeInvoiceModal = () => {
    this.setState({
      renderInvoiceModal: false,
      invoiceOrder: {},
    });
  };

  openInvoiceModal = (order) => {
    this.setState({
      renderInvoiceModal: true,
      invoiceOrder: order,
    });
  };

  renderSendInvoice = (order) => {
    if (order.orderStatus === '0' || order.orderStatus === '11') {
      return <div></div>;
    } else {
      return (
        <button
          onClick={() => this.openInvoiceModal(order)}
          className='btn btn-space btn-primary'
        >
          Send Invoice
        </button>
      );
    }
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    let reasons =
      this.props.layout && this.props.layout.reasons
        ? this.props.layout.reasons
        : [];
    const chartData = this.processLineGraphData();
    const ccBreakdown = this.processPieGraphData();
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.pickupOrders
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.pickupOrders.length -
        1
      : 0;
    let options = {
      responsive: true,
      tooltips: {
        callbacks: {
          title: this.getLabel,
          label: this.getLabelData,
        },
      },
    };
    const ttData = this.processDonugtDataForCost();
    let options1 = {
      elements: {
        center: {
          text: this.state.storeOrderStats
            ? `${currency}${this.state.storeOrderStats.averagePickupSpent}`
            : 'N/A',
          fontStyle: 'Roboto-Regular',
          sidePadding: 20,
        },
      },
    };

    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-xs-12 col-md-12'>
              <div className='panel panel-default panel-table '>
                <div className='panel-heading col-md-4'>
                  List of Pickup Orders
                </div>
                <div className='col-md-5 fright top10' />
                <div className='panel-body table-responsive'>
                  <div
                    id='table1_wrapper'
                    className='dataTables_wrapper form-inline dt-bootstrap no-footer'
                  >
                    <div className='row be-datatable-body'>
                      <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                        <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                          <thead>
                            <tr>
                              <th>Customer Name</th>
                              <th>Order Spend</th>
                              <th>Status</th>
                              <th>Order Date </th>
                              <th>Expected Pickup</th>
                              <th>Code</th>
                              <th />
                            </tr>
                          </thead>
                          {!this.state.pickupOrders ? (
                            <tbody>
                              <tr style={{ height: '100px' }}>
                                <div
                                  style={{
                                    alignSelf: 'center',
                                    left: '0px',
                                    right: '0px',
                                    position: 'absolute',
                                    width: 'auto',
                                    height: '100px',
                                  }}
                                  className='be-loading be-loading-active'
                                >
                                  <Spinner />
                                </div>
                              </tr>
                            </tbody>
                          ) : this.state.pickupOrders.length > 0 ? (
                            <tbody>
                              {this.state.pickupOrders &&
                                this.state.pickupOrders.map((order, index) => (
                                  <tr key={index}>
                                    <td>{`${order.firstName} ${order.lastName}`}</td>
                                    <td>{`${currency}${order.payableAmount}`}</td>
                                    <td>
                                      {getOrderStatus(order.orderStatus, '1')}
                                    </td>
                                    <td>
                                      {convertToMomentDate(
                                        order.date,
                                        'YYYY-MM-DD HH:mm',
                                        this.state.timeZone
                                      ).format('MMM DD, YYYY')}
                                      <br />
                                      <span className='grayc'>
                                        {convertToMomentDate(
                                          order.date,
                                          'YYYY-MM-DD HH:mm',
                                          this.state.timeZone
                                        ).format('hh:mm a')}
                                      </span>
                                    </td>
                                    <td>
                                      {convertToMomentDate(
                                        order.deliveryDate,
                                        'YYYY-MM-DD HH:mm',
                                        this.state.timeZone
                                      ).format('MMM DD, YYYY')}
                                      <br />
                                      <span className='grayc'>
                                        {convertToMomentDate(
                                          order.deliveryDate,
                                          'YYYY-MM-DD HH:mm',
                                          this.state.timeZone
                                        ).format('hh:mm a')}
                                      </span>
                                    </td>
                                    <td>{order.deliveryCode}</td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          this.openOrderModal(order)
                                        }
                                        className='btn btn-space btn-default'
                                      >
                                        View
                                      </button>
                                      {order.orderStatus === '0' && (
                                        <button
                                          onClick={() =>
                                            this.props.updateOrderHandler(
                                              order,
                                              '1',
                                              this.state.storeId,
                                              this.state.restaurantId,
                                              this.state.timeZone
                                            )
                                          }
                                          className='btn btn-space btn-default'
                                        >
                                          Confirm
                                        </button>
                                      )}
                                      {order.orderStatus === '0' && (
                                        <button
                                          onClick={() =>
                                            this.props.updateOrderHandler(
                                              order,
                                              '11',
                                              this.state.storeId,
                                              this.state.restaurantId,
                                              this.state.timeZone
                                            )
                                          }
                                          className='btn btn-space btn-default'
                                        >
                                          Cancel
                                        </button>
                                      )}
                                      {this.renderSendInvoice(order)}
                                      {this.renderRefundStatus(order)}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td>
                                  <p> No records to display</p>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    <div className='row be-datatable-footer'>
                      <div className='col-md-5 col-sm-12'>
                        <div
                          style={{ float: 'left' }}
                          className='dataTables_info'
                          id='table1_info'
                          role='status'
                          aria-live='polite'
                        >
                          {`Showing ${start} to ${end} of ${
                            this.state.totalCount ? this.state.totalCount : 0
                          } entries`}
                        </div>
                      </div>
                      <div className='col-md-7 col-sm-12'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='table1_paginate'
                        >
                          {this.state.pagination && (
                            <nav style={{ float: 'right' }}>
                              <Pagination
                                activePage={this.state.pagination.currentPage}
                                itemsCountPerPage={
                                  this.state.pagination.pageSize
                                }
                                totalItemsCount={this.state.totalCount}
                                pageRangeDisplayed={
                                  this.state.pagination.pageRangeDisplayed
                                }
                                onChange={this.handlePageChange}
                                prevPageText='Previous'
                                nextPageText='Next'
                                itemClass='paginate_button'
                              />
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4 col-sm-12 col-xs-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel-heading-divider xs-pb-15'>
                  <span className='title'>Average Customer Rating</span>
                </div>
                <div className='divider1' />
                <div className='panel-body xs-pt-25'>
                  <div className='row user-progress user-progress-small'>
                    <div className='hreviewlab1'>
                      <span className='hreviewlab'>
                        {this.state.storeReviewStats
                          ? this.getAvgRating().rating
                          : '0'}
                      </span>
                      {this.state.storeReviewStats && (
                        <Ratings
                          review={this.getAvgRating()}
                          rating={this.getAvgRating().rating}
                        />
                      )}
                    </div>
                    <div className='clear' />
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>5</span>
                    </div>
                    <div className='col-md-10 col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(5)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>4</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(4)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>3</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(3)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>2</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(2)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row user-progress user-progress-small'>
                    <div className='col-md-1 col-sm-1'>
                      <span className='title'>1</span>
                    </div>
                    <div className='col-md-10  col-sm-10'>
                      <div className='progress'>
                        <div
                          style={{
                            width: `${this.getAvgRatingPercentage(1)}%`,
                          }}
                          className='progress-bar progress-bar-info'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12 col-xs-12'>
              <div className='panel panel-defaul'>
                <div className='panel-heading panel-heading-divider'>
                  <div className='title'>Average Pickup by Day</div>
                </div>
                <div className='divider1' />
                <div className='panel-body'>
                  {this.state.orderStats ? (
                    <Line data={chartData} />
                  ) : (
                    <div
                      style={{ padding: '50px' }}
                      className='be-loading be-loading-active'
                    >
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12 col-xs-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel-heading-divider'>
                  <span className='title'>Average Pick Up Spend</span>
                </div>
                <div className='divider1' />
                <div className='panel-body'>
                  {this.state.storeOrderStats ? (
                    <Doughnut data={ttData} options={options1} />
                  ) : (
                    <div
                      style={{ padding: '50px' }}
                      className='be-loading be-loading-active'
                    >
                      <Spinner />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <TopPickupDeliveryMenuItems
              menuItems={this.state.menuItemsP}
              pagination={this.state.mPagination}
              totalCount={this.state.mTotalCount}
              handlePageChange={this.mHandlePageChange}
              sortMe={this.mSortMe}
              sorting={this.state.mSorting}
              type='1'
              timeZone={this.state.timeZone}
              currency={currency}
            />
            <div className='col-md-6 col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel-heading-divider'>
                  Cash vs Card Breakdown on Pickup
                  <span className='panel-subtitle'>
                    Pickup orders breakdown based on cash or card payment
                  </span>
                </div>
                <div className='divider1' />
                <div className='panel-body'>
                  <Pie options={options} data={ccBreakdown} />
                </div>
              </div>
            </div>
          </div>
          {this.state.isDisplayOrder && (
            <OrderModal
              isDisplayOrder={this.state.isDisplayOrder}
              closeOrderModal={this.closeOrderModal}
              selectedOrder={this.state.selectedOrder}
              timeZone={this.state.timeZone}
              storeId={this.state.storeId}
              currency={currency}
            />
          )}
          {this.state.openRefundPopup && (
            <RefundModal
              openRefundPopup={this.state.openRefundPopup}
              closeRefundPopup={this.closeRefundPopup}
              order={this.state.refundOrder}
              timeZone={this.state.timeZone}
              accessToken={this.state.accessToken}
              currency={currency}
              reasons={reasons}
            />
          )}
          {this.state.renderInvoiceModal && (
            <InvoiceModal
              renderInvoiceModal={this.state.renderInvoiceModal}
              closeInvoiceModal={this.closeInvoiceModal}
              order={this.state.invoiceOrder}
            />
          )}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    storeSetting: state.storeSettingReducer,
    pickupStatistics: state.pickupStatisticsReducer,
    order: state.orderReducer,
    review: state.reviewReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrdersCountHandler: (storeId) => dispatch(getOrdersCount(storeId)),
    getOrdersHandler: (storeId, pagination) =>
      dispatch(getOrders(storeId, pagination)),
    getMenuItemsHandler: (storeId, pagination, sortObj) =>
      dispatch(getMenuItems(storeId, pagination, sortObj)),
    getMenuItemsCountHandler: (storeId) => dispatch(getMenuItemsCount(storeId)),
    getReviewsStatsHandler: (storeId) => dispatch(getReviewsStats(storeId)),
    getOrderStatsHandler: (storeId) => dispatch(getOrderStats(storeId)),
    getStoreOrderStatsHandler: (storeId) =>
      dispatch(getStoreOrderStats(storeId)),
    getStoreReviewStatsHandler: (storeId) =>
      dispatch(getStoreReviewStats(storeId)),
    updateOrderHandler: (order, status, storeId, restaurantId, timeZone) =>
      dispatch(updateOrder(order, status, storeId, restaurantId, timeZone)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupStatistics);
