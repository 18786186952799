import { firestore } from '../../firebase';
import ActionTypes from './constants';
import { getTimestampForMomentDate } from '../../helpers/timeHelper';

export function getCloseDateCount(storeId) {
  return (dispatch) => {
    let ref = firestore
      .collection('CloseDates')
      .where('storeId', '==', storeId);
    ref.onSnapshot((querySnapshot) => {
      dispatch(closeDateCountFetched(querySnapshot.docs.length));
    });
  };
}

export function getCloseDates(storeId, pagination) {
  return (dispatch) => {
    let currentRef = firestore
      .collection('CloseDates')
      .where('storeId', '==', storeId)
      .orderBy('dateTimestamp', 'desc');
    currentRef.get().then((documentSnapshots) => {
      let lastVisible =
        documentSnapshots.docs[
          (pagination.currentPage - 1) * pagination.pageSize - 1
        ];
      if (pagination.currentPage !== 1) {
        let nextRef = firestore
          .collection('CloseDates')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .startAfter(lastVisible)
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(closeDatesFetched(dataArray));
        });
      } else {
        let nextRef = firestore
          .collection('CloseDates')
          .where('storeId', '==', storeId)
          .orderBy('dateTimestamp', 'desc')
          .limit(pagination.pageSize);
        nextRef.onSnapshot((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          dispatch(closeDatesFetched(dataArray));
        });
      }
    });
  };
}

export function addCloseDate(closeDate, dateRangeObj, store) {
  return (dispatch) => {
    let startDate = dateRangeObj.startDate;
    let endDate = dateRangeObj.endDate;
    let objs = [];
    while (startDate.isSameOrBefore(endDate)) {
      let cObh = {};
      cObh.closeDate = startDate.format('YYYY-MM-DD');
      cObh.closeDateTimestamp = getTimestampForMomentDate(
        startDate,
        store.timeZone,
        'YYYY-MM-DD HH:mm'
      );
      objs.push(cObh);
      startDate.add(1, 'days');
    }
    objs.map((obj) => {
      firestore
        .collection('CloseDates')
        .where('storeId', '==', closeDate.storeId)
        .where('closeDate', '==', obj.closeDate)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length <= 0) {
            firestore
              .collection('CloseDates')
              .add({
                ...closeDate,
                closeDate: obj.closeDate,
                closeDateTimestamp: obj.closeDateTimestamp,
              })
              .then(() => {});
          }
        });
    });
  };
}

export function deleteCloseDate(closeDate) {
  return (dispatch) => {
    firestore
      .collection('CloseDates')
      .doc(closeDate._id)
      .delete()
      .then(() => {});
  };
}

export function resetPageData() {
  return (dispatch) => {
    dispatch(resetPageDataFun());
  };
}

function resetPageDataFun() {
  return {
    type: ActionTypes.ResetCloseDatePageData,
  };
}

function closeDateCountFetched(totalCount) {
  return {
    type: ActionTypes.CloseDateCountFetched,
    totalCount,
  };
}

function closeDatesFetched(closeDates) {
  return {
    type: ActionTypes.FetchCloseDates,
    closeDates,
  };
}
