import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import { nextOrderConfig } from '../../configurations/storeSetup';
import { connect } from 'react-redux';
import './style.css';

class RefundModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      refundAmount: 0,
      priceObj: {},
      refunded: false,
    };
  }

  componentDidMount() {
    let groupedMenuItems = this.getGroupedMenuItems(this.props.order);
    this.setState(
      {
        order: this.props.order,
        groupedMenuItems,
        refundAmount: Number(this.props.order.payableAmount),
        deliveryCheckbox: true,
      },
      () => {
        let refundCheckboxes = document.querySelectorAll(
          'input.refund-ingredient'
        );
        refundCheckboxes.forEach((checkbox) => {
          checkbox.checked = true;
        });
      }
    );
  }

  componentDidUpdate() {
    if (this.props.reasons && !this.state.reasons) {
      let reasons = [];
      orderBy(this.props.reasons, 'index').map((m, i) => {
        let obj = {
          _id: m._id,
          reason: m.reason,
          index: m.index,
          isActive: i === 0 ? true : false,
        };
        reasons.push(obj);
      });
      this.setState({ reasons });
    }
  }

  getGroupedMenuItems = (order) => {
    let items = [];
    if (order.menuItems) {
      order.menuItems.map((item) => {
        if (item.isHalf) {
          let betterHalfs = order.menuItems.filter((obj) => {
            return obj.halfIndex === item.halfIndex && obj._id !== item._id;
          });
          let xBetterHalf = items.filter((obj) => {
            return obj.keyHalf === item._id;
          });
          if (
            betterHalfs &&
            betterHalfs.length > 0 &&
            xBetterHalf.length === 0
          ) {
            item.keyHalf = betterHalfs[0]._id;
            item.nameHalf = betterHalfs[0].name;
            item.selectedSizesHalf = betterHalfs[0].selectedSizes;
            item.selectedIngredientsHalf = betterHalfs[0].selectedIngredients;
            item.selectedExtraIngredientsHalf =
              betterHalfs[0].selectedExtraIngredients;
            items.push(item);
          }
        } else {
          items.push(item);
        }
        item.selectedSizes.forEach((size) => {
          size.isRefundable = true;
        });
      });
      items.forEach((item) => {
        item.selectedExtraIngredients.forEach((ingredient) => {
          ingredient.isIngredientRefundable = true;
        });
      });
      return items;
    }
  };

  refundOrder = (order) => {
    if (!this.state.isProcessing) {
      let activeRefundResons = this.state.reasons.filter((m) => m.isActive);
      let reasonObj = { _id: '', reason: '', message: '' };
      reasonObj =
        activeRefundResons.length > 0 ? activeRefundResons[0] : reasonObj;
      this.setState({ isProcessing: true }, () => {
        fetch(`${nextOrderConfig.cloudFunctionUrl}/refund`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${this.props.accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orderId: order._id,
            storeId: order.storeId,
            restaurantId: order.restaurantId,
            timeZone: this.props.timeZone,
            amount: String(this.state.refundAmount),
            reasonId: reasonObj._id,
            reasonForCredit: reasonObj.reason,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            console.log('Result', result);
            this.setState({ isProcessing: false, refunded: true });
          });
      });
    }
  };

  getItemCustomizedPriceOnly = (itemObj, sizeObj) => {
    let itemPrice = 0;
    let quantity = 0;
    let halfQantity = 0;
    let currentItem = JSON.parse(JSON.stringify(itemObj));
    currentItem.selectedSizes &&
      currentItem.selectedSizes.map((item) => {
        if (item._id === sizeObj._id) {
          if (Number(item.quantity) > 0) {
            quantity = quantity + Number(item.quantity);
            if (item.isGlutenFree === true) {
              itemPrice =
                itemPrice +
                Number(item.quantity) * Number(item.price) +
                Number(item.glutenAdditionalCharges) * Number(item.quantity);
            } else {
              itemPrice =
                itemPrice + Number(item.quantity) * Number(item.price);
            }
          }
        }
        return item;
      });
    if (itemObj.isHalf) {
      currentItem.selectedSizesHalf &&
        currentItem.selectedSizesHalf.map((item) => {
          if (item.quantity > 0) {
            halfQantity = halfQantity + item.quantity;
            if (item.isGlutenFree === true) {
              itemPrice =
                itemPrice +
                item.quantity * item.price +
                Number(item.glutenAdditionalCharges) * item.quantity;
            } else {
              itemPrice = itemPrice + item.quantity * item.price;
            }
          }
          return item;
        });
      itemPrice =
        itemPrice +
        (this.props.productSetup
          ? Number(this.props.productSetup.halfHalfAdditionalCharges)
          : 0) *
          (quantity + halfQantity);
    }
    return itemPrice.toFixed(2);
  };

  getCardTypeName = (c) => {
    if (c === '0') return 'Unknown';
    else if (c === '1') return 'JCB';
    else if (c === '2') return 'American Express(Amex)';
    else if (c === '3') return 'Diners Club';
    else if (c === '5') return 'MasterCard';
    else if (c === '6') return 'Visa';
    else return 'Unknown';
  };

  getItemCustomizedPrice = (itemObj, sizeObj) => {
    let itemPrice = 0;
    let quantity = 0;
    let halfQantity = 0;
    let extraPrice = 0;
    let currentItem = JSON.parse(JSON.stringify(itemObj));
    currentItem.selectedSizes &&
      currentItem.selectedSizes.map((item) => {
        if (item._id === sizeObj._id) {
          if (Number(item.quantity) > 0) {
            quantity = quantity + Number(item.quantity);
            if (item.isGlutenFree === true) {
              itemPrice =
                itemPrice +
                Number(item.quantity) * Number(item.price) +
                Number(item.glutenAdditionalCharges) * Number(item.quantity);
            } else {
              itemPrice =
                itemPrice + Number(item.quantity) * Number(item.price);
            }
          }
        }
        return item;
      });
    if (quantity > 0) {
      currentItem.selectedExtraIngredients &&
        currentItem.selectedExtraIngredients.map((item) => {
          if (item.quantity > 0) {
            extraPrice =
              extraPrice + Number(item.quantity) * Number(item.price);
          }
          return item;
        });
      itemPrice = extraPrice * quantity + itemPrice;
    }
    if (itemObj.isHalf) {
      currentItem.selectedSizesHalf &&
        currentItem.selectedSizesHalf.map((item) => {
          if (item.quantity > 0) {
            halfQantity = halfQantity + item.quantity;
            if (item.isGlutenFree === true) {
              itemPrice =
                itemPrice +
                item.quantity * item.price +
                Number(item.glutenAdditionalCharges) * item.quantity;
            } else {
              itemPrice = itemPrice + item.quantity * item.price;
            }
          }
          return item;
        });
      if (halfQantity > 0) {
        currentItem.selectedExtraIngredientsHalf &&
          currentItem.selectedExtraIngredientsHalf.map((item) => {
            if (item.quantity > 0) {
              extraPrice = extraPrice + item.quantity * item.price;
            }
            return item;
          });
        itemPrice = extraPrice * quantity + itemPrice;
      }
      itemPrice =
        itemPrice +
        (this.props.productSetup
          ? Number(this.props.productSetup.halfHalfAdditionalCharges)
          : 0) *
          (quantity + halfQantity);
    }
    return itemPrice.toFixed(2);
  };

  handleRefundCheckbox = (e, size, item, soloPrice) => {
    let newGroupedItems = [...this.state.groupedMenuItems];
    newGroupedItems.forEach((menuItem) => {
      if (menuItem._id === item._id) {
        menuItem.selectedSizes.forEach((menuSize) => {
          if (menuSize._id === size._id) {
            menuSize.isRefundable = e.target.checked;
          }
        });
      }
    });
    let priceNumber = Number(soloPrice);
    let aggregatePrice = Number(this.state.refundAmount);
    let finalPrice;
    if (!e.target.checked) {
      finalPrice = aggregatePrice - priceNumber;
    } else {
      finalPrice = aggregatePrice + priceNumber;
    }
    finalPrice = finalPrice.toFixed(2);
    if (finalPrice <= 0) {
      finalPrice = 0;
    }
    this.setState({
      groupedMenuItems: newGroupedItems,
      refundAmount: finalPrice,
    });
  };

  handleIngredientRefundCheckbox = (e, price) => {
    let priceNumber = Number(price);
    let aggregatePrice = Number(this.state.refundAmount);
    let finalPrice;
    if (!e.target.checked) {
      finalPrice = aggregatePrice - priceNumber;
    } else {
      finalPrice = aggregatePrice + priceNumber;
    }
    finalPrice = finalPrice.toFixed(2);
    if (finalPrice <= 0) {
      finalPrice = 0;
    }
    this.setState({
      refundAmount: finalPrice,
    });
  };

  handleRefundAmountChange = (e) => {
    if (Number(e.target.value) >= 0) {
      this.setState({
        refundAmount: Number(e.target.value),
      });
    }
  };

  handleDeliveryCheckbox = (e) => {
    let checkBoxValue = e.target.checked;
    this.setState(
      {
        deliveryCheckbox: e.target.checked,
      },
      () => {
        let deliveryCost = Number(this.props.order.deliveryCost);
        let aggregatePrice = Number(this.state.refundAmount);
        let finalPrice;
        if (checkBoxValue) {
          finalPrice = aggregatePrice + deliveryCost;
        } else {
          finalPrice = aggregatePrice - deliveryCost;
        }

        if (finalPrice >= 0) {
          this.setState({
            refundAmount: finalPrice,
          });
        } else {
          this.setState({
            refundAmount: 0,
          });
        }
      }
    );
  };

  handleChangeReasonChk = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let reasons = JSON.parse(JSON.stringify(this.state.reasons));
    reasons.map((m) => {
      if (m._id === id) m.isActive = value;
      else m.isActive = false;
      return m;
    });
    this.setState({ reasons });
  };

  render() {
    return (
      <Modal
        aria-labelledby='modal-label'
        backdrop='static'
        keyboard={false}
        className='modal fade refund-modal'
        show={this.props.openRefundPopup}
        onHide={this.props.closeRefundPopup}
        bsSize='small'
      >
        <div style={{ border: '1px solid #ccc' }}>
          <div className='modal-header' style={{ marginLeft: '18px' }}>
            <button
              onClick={this.props.closeRefundPopup}
              type='button'
              data-dismiss='modal'
              aria-hidden='true'
              className='close md-close'
            >
              <span className='mdi mdi-close' />
            </button>
            <h3 className='modal-title'>Refund</h3>
          </div>
          <div className='modal-body'>
            {this.state.order && this.state.order.menuItems && (
              <React.Fragment>
                <div className='col-md-12'>
                  <div className='detailHeader'>
                    <p>Order</p>
                    {this.state.groupedMenuItems.map((item, index) => (
                      <React.Fragment>
                        <div key={index}>
                          {!item.isHalf &&
                            item.selectedSizes.map((size, index) => (
                              <div key={index} className='refund-item'>
                                <div key={index} className='refund-container'>
                                  <div className='refund-checkbox-container'>
                                    <input
                                      type='checkbox'
                                      checked={size.isRefundable}
                                      onChange={(e) =>
                                        this.handleRefundCheckbox(
                                          e,
                                          size,
                                          item,
                                          this.getItemCustomizedPriceOnly(
                                            item,
                                            size
                                          )
                                        )
                                      }
                                      className='refund-checkbox'
                                    />
                                    <span className='refund-menu-item'>{`${
                                      size.quantity
                                    } ${size.name} ${item.name} ${
                                      size.isGlutenFree ? '(Gluten Free)' : ''
                                    }`}</span>
                                  </div>
                                  <div>
                                    <span className='priceFont refund-price'>
                                      {`${
                                        this.props.currency
                                      }${this.getItemCustomizedPriceOnly(
                                        item,
                                        size
                                      )}`}
                                    </span>
                                  </div>
                                </div>
                                {item.selectedExtraIngredients &&
                                  item.selectedExtraIngredients.map(
                                    (ingredient, index) => (
                                      <React.Fragment>
                                        <div
                                          key={index}
                                          className='ingDelInn refund-extras'
                                        >
                                          <div className='refund-extras-left'>
                                            <input
                                              type='checkbox'
                                              onChange={(e) =>
                                                this.handleIngredientRefundCheckbox(
                                                  e,
                                                  (
                                                    Number(ingredient.price) *
                                                    Number(
                                                      ingredient.quantity
                                                    ) *
                                                    Number(size.quantity)
                                                  ).toFixed(2)
                                                )
                                              }
                                              className='refund-checkbox refund-ingredient'
                                            />
                                            <span className='refund-extras-ingredient'>{`Extra ${ingredient.ingredientName}`}</span>
                                          </div>
                                          <div className='refund-extras-right'>
                                            <span className='priceFont'>
                                              {`${this.props.currency}${(
                                                Number(ingredient.price) *
                                                Number(ingredient.quantity) *
                                                Number(size.quantity)
                                              ).toFixed(2)}`}
                                            </span>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    )
                                  )}
                                {item.removedIngredients &&
                                  item.removedIngredients.map(
                                    (ingredient, index) => (
                                      <div key={index} className='ingDelInn'>
                                        <span>{`Remove ${ingredient.ingredientName}`}</span>
                                        <span className='tebdo' />
                                      </div>
                                    )
                                  )}
                                <div className='aggregate-item-price'>
                                  <div></div>
                                  <div>
                                    <span className='aggrPrice'>{`${
                                      this.props.currency
                                    }${this.getItemCustomizedPrice(
                                      item,
                                      size
                                    )}`}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {item.isHalf &&
                            item.selectedSizes.map((size, index) => (
                              <div
                                key={`${item.keyHalf}${index}`}
                                className='refund-item'
                              >
                                <div key={index} className='refund-container'>
                                  <div className='refund-checkbox-container'>
                                    <input
                                      type='checkbox'
                                      checked={size.isRefundable}
                                      onChange={(e) =>
                                        this.handleRefundCheckbox(
                                          e,
                                          size,
                                          item,
                                          this.getItemCustomizedPriceOnly(
                                            item,
                                            size
                                          )
                                        )
                                      }
                                      className='refund-checkbox'
                                    />
                                    <span className='refund-menu-item'>{`${
                                      size.quantity * 2
                                    } ${size.name} ${item.name}/${
                                      item.nameHalf
                                    } ${
                                      size.isGlutenFree ? '(Gluten Free)' : ''
                                    }`}</span>
                                  </div>
                                  <div>
                                    <span className='priceFont refund-price'>
                                      {`${
                                        this.props.currency
                                      }${this.getItemCustomizedPriceOnly(
                                        item,
                                        size
                                      )}`}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  {((item.selectedExtraIngredients &&
                                    item.selectedExtraIngredients.length > 0) ||
                                    (item.removedIngredients &&
                                      item.removedIngredients.length > 0)) && (
                                    <div
                                      className='halfItem'
                                      style={{ marginBottom: '20px' }}
                                    >
                                      {item.name}
                                    </div>
                                  )}
                                  {item.selectedExtraIngredients &&
                                    item.selectedExtraIngredients.map(
                                      (ingredient, index) => (
                                        <div
                                          key={index}
                                          className='ingDelInn refund-extras'
                                        >
                                          <div className='refund-extras-left'>
                                            <input
                                              type='checkbox'
                                              onChange={(e) =>
                                                this.handleIngredientRefundCheckbox(
                                                  e,
                                                  (
                                                    Number(ingredient.price) *
                                                    Number(
                                                      ingredient.quantity
                                                    ) *
                                                    Number(size.quantity)
                                                  ).toFixed(2)
                                                )
                                              }
                                              className='refund-checkbox refund-ingredient'
                                            />
                                            <span>{`Extra ${ingredient.ingredientName}`}</span>
                                          </div>
                                          <div className='refund-extras-right'>
                                            <span className='priceFont'>
                                              {`${this.props.currency}${(
                                                Number(ingredient.price) *
                                                Number(ingredient.quantity) *
                                                Number(size.quantity)
                                              ).toFixed(2)}`}
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  {item.removedIngredients &&
                                    item.removedIngredients.map(
                                      (ingredient, index) => (
                                        <div key={index} className='ingDelInn'>
                                          <span>{`Remove ${ingredient.ingredientName}`}</span>
                                          <span className='tebdo' />
                                        </div>
                                      )
                                    )}
                                </div>
                                <div
                                  className='halfItem'
                                  style={{ marginBottom: '20px' }}
                                >
                                  {((item.selectedExtraIngredientsHalf &&
                                    item.selectedExtraIngredientsHalf.length >
                                      0) ||
                                    (item.removedIngredientsHalf &&
                                      item.removedIngredientsHalf.length >
                                        0)) && <span>{item.nameHalf}</span>}
                                  {item.selectedExtraIngredientsHalf &&
                                    item.selectedExtraIngredientsHalf.map(
                                      (ingredient, index) => (
                                        <div key={index} className='ingDelInn'>
                                          <span className='extaWidth'>{`Extra ${ingredient.ingredientName}`}</span>
                                          <span className='priceFont'>{`${
                                            this.props.currency
                                          }${(
                                            Number(ingredient.price) *
                                            Number(ingredient.quantity) *
                                            Number(size.quantity)
                                          ).toFixed(2)}`}</span>
                                        </div>
                                      )
                                    )}
                                  {item.removedIngredientsHalf &&
                                    item.removedIngredientsHalf.map(
                                      (ingredient, index) => (
                                        <div key={index} className='ingDelInn'>
                                          <span>{`Remove ${ingredient.ingredientName}`}</span>
                                          <span className='tebdo' />
                                        </div>
                                      )
                                    )}
                                </div>
                                <div className='aggregate-item-price'>
                                  <div></div>
                                  <div>
                                    <span className='aggrPrice'>{`${
                                      this.props.currency
                                    }${this.getItemCustomizedPrice(
                                      item,
                                      size
                                    )}`}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                  {this.state.order &&
                    this.state.order.specials &&
                    this.state.order.specials.length > 0 && (
                      <div className='specialSec'>
                        <p>Special</p>
                        {this.state.order.specials.map((m, index) => (
                          <div key={index} className='aggregate-item-price'>
                            <div>
                              <span className='lightFont'>{m.name}</span>
                            </div>
                            <div>
                              <span className='refund-price'>{`-${this.props.currency}${m.discount}`}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
                <div className='col-md-12'>
                  {this.state.order && this.state.order.orderType !== '1' && (
                    <div className='refund-payment-section detailHeader'>
                      <p>Additional charges</p>
                      <div className='ingDel'>
                        <div className='refund-payment'>
                          <div
                            className='lightFont'
                            style={{ display: 'flex' }}
                          >
                            <input
                              type='checkbox'
                              checked={this.state.deliveryCheckbox}
                              onChange={(e) => this.handleDeliveryCheckbox(e)}
                              className='refund-checkbox'
                            />
                            <div className='refund-menu-item'>Delivery Fee</div>
                          </div>
                          <div className='refund-price'>
                            <span
                              style={{ fontSize: '20px' }}
                            >{`${this.props.currency}${this.state.order.deliveryCost}`}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.props.orderModal.voucher && (
                    <div className='detailHeader'>
                      <p>Voucher code</p>
                      <div className='ingDel'>
                        <div className='refund-payment'>
                          <div className='lightFont'>
                            {this.props.orderModal.voucher.voucherCode}
                          </div>
                          <div className='refund-price'>{`-${this.props.currency}${this.state.order.voucherDiscount}`}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.order && this.state.order.paymentType === '2' && (
                    <div className='refund-payment-section detailHeader'>
                      <p>Payment</p>
                      <div className='ingDel'>
                        <div className='refund-payment'>
                          <div className='lightFont'>
                            {this.getCardTypeName(this.state.order.cardType)}
                          </div>
                          <div className='refund-price'>
                            {this.state.order.payableAmount &&
                              `${this.props.currency}${this.state.order.payableAmount}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
            <div>
              <div className='col-md-12'>
                <div className='refund-payment-section detailHeader'>
                  <div className='refund-total-section'>
                    <div>
                      <span className=''>Total</span>
                    </div>
                    <div>
                      <span className='aggPrice'>
                        {this.state.order &&
                          this.state.order.payableAmount &&
                          `${this.props.currency}${this.state.order.payableAmount}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='refund-footer'>
              <div>
                <div className='detailHeader  '>
                  <p>Refund reasons</p>
                </div>
              </div>
            </div>
            <div className='refund-reasons-container'>
              {this.state.reasons &&
                this.state.reasons.map((m) => (
                  <div className='refund-reasons' style={{ display: 'flex' }}>
                    <div style={{ marginLeft: '18px', display: 'flex' }}>
                      <div style={{ display: 'flex', marginBottom: '10px' }}>
                        <input
                          type='checkbox'
                          className='refund-checkbox'
                          checked={m.isActive}
                          onChange={this.handleChangeReasonChk}
                          name={m.name}
                          id={m._id}
                        />
                        <span htmlFor={m._id} className='refund-menu-item'>
                          {m.reason}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className='refund-footer'>
            <div style={{ marginLeft: '18px' }} style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignSelf: 'center' }}>
                <label
                  className='refund-label'
                  style={{ marginLeft: '18px', marginRight: '10px' }}
                >
                  Refund Amount:
                </label>
              </div>
              <div className='input-group'>
                <span className='input-group-addon'>$</span>
                <input
                  type='number'
                  value={this.state.refundAmount}
                  onChange={this.handleRefundAmountChange}
                  min={0}
                  style={{ height: '37px' }}
                />
              </div>
            </div>
            <div>
              <button
                onClick={() => this.refundOrder(this.props.order)}
                type='button'
                className='btn btn-space btn-success active'
                style={{ boxShadow: 'none' }}
                disabled={
                  !(
                    this.state.refundAmount <= this.props.order.payableAmount
                  ) ||
                  parseInt(this.state.refundAmount) === 0 ||
                  this.state.refunded ||
                  this.state.isProcessing
                }
              >
                {this.state.isProcessing
                  ? 'Refunding...'
                  : this.state.refunded
                  ? `Refunded`
                  : `Refund ${this.props.currency}${this.state.refundAmount}`}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

RefundModal.propTypes = {};

function mapStateToProps(state) {
  return {
    orderModal: state.orderModalReducer,
  };
}

export default connect(mapStateToProps)(RefundModal);
