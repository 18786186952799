const actionTypes = {
  FetchVouchers: 'FETCH_VOUCHER',
  FetchExpiredVouchers: 'FETCH_EXPIRED_VOUCHER',
  VoucherAdded: 'VOUCHER_ADDED',
  VoucherStatusChanged: 'VOUCHER_STATUS_CHANGED',
  VoucherCountFetched: 'VOUCHER_COUNT_FETCHED',
  VoucherCountExpiredFetched: 'VOUCHER_COUNT_EXPIRED_FETCHED',
  FetchStore: 'FETCH_STORE',
};

export default actionTypes;
