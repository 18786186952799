import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, Form } from 'redux-form';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';

const validate = (values) => {
  const errors = {};
  if (!values.ingredientName) {
    errors.ingredientName = 'Required';
  }
  if (!values.ingredientCategory) {
    errors.ingredientCategory = 'Select category';
  } else if (values.ingredientCategory === '0') {
    errors.ingredientCategory = 'Select category';
  }

  if (!values.price) {
    errors.price = 'Required';
  } else if (isNaN(Number(values.price))) {
    errors.price = 'Must be a number';
  }
  return errors;
};

const warn = () => {
  const warnings = {};
  return warnings;
};

const renderField = ({
  input,
  placeholder,
  type,
  classNameforDiv,
  className,
  meta: { touched, error, warning },
}) => (
  <div className={classNameforDiv}>
    <input
      className={className}
      {...input}
      placeholder={placeholder}
      type={type}
    />
    {touched &&
      ((error && (
        <ul className='parsley-errors-list filled' id='parsley-id-7'>
          <li className='parsley-type'>{error}</li>
        </ul>
      )) ||
        (warning && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li className='parsley-type'>{warning}</li>
          </ul>
        )))}
  </div>
);

const renderFieldWithDollar = ({
  input,
  placeholder,
  type,
  classNameforDiv,
  className,
  currency,
  meta: { touched, error, warning },
}) => (
  <div className={classNameforDiv}>
    <div className='input-group xs-mb-20'>
      <span className='input-group-addon input-xs'>{currency}</span>
      <input
        className={className}
        {...input}
        placeholder={placeholder}
        type={type}
      />
    </div>
    {touched &&
      ((error && (
        <ul className='parsley-errors-list filled' id='parsley-id-7'>
          <li className='parsley-type'>{error}</li>
        </ul>
      )) ||
        (warning && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li className='parsley-type'>{warning}</li>
          </ul>
        )))}
  </div>
);

const renderFieldDropdown = ({
  input,
  label,
  type,
  classNameforDiv,
  ingredientCategories,
  className,
  meta: { touched, error, warning },
}) => (
  <div className={classNameforDiv}>
    <select className={className} {...input}>
      <option value='0' key='0m'>
        Default category
      </option>
      {ingredientCategories &&
        ingredientCategories.map((item, index) => (
          <option value={item._id} key={index}>
            {item.name}
          </option>
        ))}
    </select>
    {touched &&
      ((error && (
        <ul className='parsley-errors-list filled' id='parsley-id-7'>
          <li className='parsley-type'>{error}</li>
        </ul>
      )) ||
        (warning && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li className='parsley-type'>{warning}</li>
          </ul>
        )))}
  </div>
);

class IngredientForm extends Component {
  componentWillReceiveProps(props) {
    this.setState({
      ingredientCategories: props.ingredientCategories,
    });
  }

  handleSubmit = (values) => {
    values.date = getCurrentFormattedMomentDate(
      this.props.timeZone,
      'YYYY-MM-DD HH:mm'
    );
    values.dateTimestamp = getCurrentTimestamp();
    this.props.addIngredientHandler(values);
    this.props.reset();
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <div className='form-group mt3'>
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <label className='col-md-2 control-label sidepanellable'>
            Add Ingredients
          </label>
          <Field
            classNameforDiv='col-md-3'
            className='form-control input-xs xs-mb-20'
            type='text'
            placeholder='Ingredient Name'
            name='ingredientName'
            component={renderField}
          />
          <Field
            name='ingredientCategory'
            component={renderFieldDropdown}
            ingredientCategories={this.props.ingredientCategories}
            classNameforDiv='col-md-2 pp0'
            className='form-control input-xs xs-mb-20'
          />
          <Field
            classNameforDiv='col-md-2'
            className='form-control input-xs'
            type='text'
            placeholder='Price'
            name='price'
            component={renderFieldWithDollar}
            currency={this.props.currency}
          />
          <div className='col-md-2 pp0'>
            <button
              type='submit'
              disabled={submitting}
              className='btn btn-space btn-primary active'
            >
              Add
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

IngredientForm.propTypes = {
  ingredientCategories: PropTypes.any,
  addIngredientHandler: PropTypes.any,
};

export default reduxForm({
  form: 'ingredientForm',
  validate,
  warn,
})(IngredientForm);
