import React, { PureComponent } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

class DateRangePickerField extends PureComponent {
  state = { focusedInput: null };
  handleFocusChange = (focusedInput) => this.setState({ focusedInput });

  render() {
    const {
      meta: { error, touched, warning },
      input: {
        value: { startDate = null, endDate = null },
        onChange,
      },
    } = this.props;
    const { focusedInput = null } = this.state;
    return (
      <div>
        <DateRangePicker
          startDateId='startDate'
          startDatePlaceholderText='From'
          endDateId='endDate'
          endDatePlaceholderText='To'
          showDefaultInputIcon={true}
          endDate={endDate}
          focusedInput={focusedInput}
          onDatesChange={onChange}
          onFocusChange={this.handleFocusChange}
          startDate={startDate}
          minimumNights={0}
        />
        {touched &&
          ((error && (
            <ul className='parsley-errors-list filled' id='parsley-id-7'>
              <li style={{ fontSize: '13px' }} className='parsley-type'>
                {error}
              </li>
            </ul>
          )) ||
            (warning && (
              <ul className='parsley-errors-list filled' id='parsley-id-7'>
                <li style={{ fontSize: '13px' }} className='parsley-type'>
                  {warning}
                </li>
              </ul>
            )))}
      </div>
    );
  }
}

export default DateRangePickerField;
