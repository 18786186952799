import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Spinner from '../../components/Spinner';
import {
  getCurrentMomentDate,
  convertToMomentDate,
  getCurrentDate,
} from '../../helpers/timeHelper';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';

class DeliveryHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().add(-14, 'day'),
      endDate: moment(),
    };
  }

  onDatesChange = (startDate, endDate) => {
    this.setState({ startDate, endDate }, () => {
      this.props.getDeliveryHistoryHandler(
        this.props.driverId,
        startDate,
        endDate
      );
    });
  };

  getTimeLineText = (order) => {
    let timeLine = 'N/A';
    if (order.deliveryDate) {
      var start_date = convertToMomentDate(
        order.deliveryDate,
        'YYYY-MM-DD HH:mm',
        this.props.timeZone
      );
      var end_date = getCurrentMomentDate(this.props.timeZone);
      var duration = end_date.diff(start_date, 'minutes');
      if (Number(duration) < 15) {
        timeLine = 'Just Now';
      } else {
        if (start_date.isSame(end_date, 'day')) {
          timeLine = `TODAY - ${start_date.format('HH:mm')}`;
        } else if (start_date.isSame(end_date.add(-1, 'days'), 'day')) {
          timeLine = `YESTERDAY - ${start_date.format('HH:mm')}`;
        } else {
          timeLine = `${start_date.format('Do, MMM - HH:mm')}`;
        }
      }
    }
    return timeLine;
  };

  getOrderDetails = (order) => {
    let details = '';
    let menuItems = order.menuItems;
    menuItems.map((item, index) => {
      details =
        details +
        `${
          item.selectedSizes[0].quantity !== '0.5'
            ? item.selectedSizes[0].quantity
            : '1/2'
        }x ${item.selectedSizes[0].name} ${item.name}`;
      let ings = item.removedIngredients ? item.removedIngredients : [];
      if (ings.length > 0) {
        details = details + ' ( No ';
        ings.map((g, i) => {
          details = details + g.ingredientName;
          if (i + 1 !== ings.length) details = details + `,`;
          return g;
        });
        details = details + ') ';
      }
      if (
        item.selectedExtraIngredients &&
        item.selectedExtraIngredients.length > 0
      ) {
        details = details + ' Extra(';
        item.selectedExtraIngredients.map((g, i) => {
          details = details + g.ingredientName;
          if (i + 1 !== item.selectedExtraIngredients.length)
            details = details + `,`;
          return g;
        });
        details = details + ') ';
      }
      if (index + 1 !== menuItems.length) details = details + `,`;
      return item;
    });
    return details;
  };

  render() {
    return (
      <div className='col-md-6 col-sm-12'>
        <div className='panel panel-default'>
          <div className='panel-heading panel-heading-divider'>
            <span className='title'>Delivery History</span>
            <div
              style={{ marginBottom: '10px' }}
              className='col-md-6 col-xs-12 col-sm-12 fright'
            >
              <DateRangePicker
                startDateId='startDate'
                startDatePlaceholderText='From'
                endDateId='endDate'
                endDatePlaceholderText='To'
                showDefaultInputIcon={true}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={({ startDate, endDate }) =>
                  this.onDatesChange(startDate, endDate)
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                isOutsideRange={(day) =>
                  !isInclusivelyBeforeDay(
                    day,
                    getCurrentMomentDate(this.props.timeZone)
                  )
                }
                minimumNights={0}
              />
            </div>
          </div>
          <p className='divider1' />
          <div className='panel-body'>
            <div style={{ height: '650px', overflowY: 'scroll' }}>
              {this.props.deliveryHistory ? (
                <ul className='user-timeline'>
                  {this.props.deliveryHistory.map((order, index) => (
                    <li
                      key={index}
                      className={
                        convertToMomentDate(order.deliveredAt).isSame(
                          getCurrentDate(this.props.timeZone),
                          'day'
                        )
                          ? 'latest'
                          : ''
                      }
                    >
                      <div className='user-timeline-date'>
                        {this.getTimeLineText(order)}
                      </div>
                      <div className='user-timeline-description'>
                        <span>
                          <b>Customer </b>:
                          {order.firstName && order.lastName
                            ? `${order.firstName} ${order.lastName}`
                            : 'N/A'}
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        <span>
                          <b>Ph </b>:
                          {order.mobileNumber ? `${order.mobileNumber}` : 'N/A'}
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        <br />
                        <span>
                          <b>Delivery Address </b>: {order.address}
                        </span>
                        <br />
                        <span>
                          <b>Order Details </b>: {this.getOrderDetails(order)}
                        </span>
                        <br />
                        <span>
                          <b>Order Total </b>:
                          {`${this.props.currency}${order.payableAmount}`}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div
                  style={{ padding: '50px' }}
                  className='be-loading be-loading-active'
                >
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeliveryHistory.propTypes = {
  deliveryHistory: PropTypes.any,
  getDeliveryHistoryHandler: PropTypes.any,
};

export default DeliveryHistory;
