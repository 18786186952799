import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/Layout';
import VoucherList from '../../components/VoucherList';
import {
  addVoucher,
  getVouchers,
  getVoucherCountExpired,
  getVouchersExpired,
  deleteVoucher,
  getVoucherCount,
  redeemVoucher,
  getStore,
} from './actions';
import {
  getCategories,
  getSizes,
  getMenuItems,
} from '../../containers/Menu/actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

class Vouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      totalCount: 0,
      paginationExpired: {
        currentPage: 1,
        pageSize: 5,
        pageRangeDisplayed: 7,
      },
      totalCountExpired: 0,
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        this.props.history.push(
          '/restaurants/login?redirect=/restaurants/marketing/vouchers'
        );
      } else {
        firestore
          .collection('SystemUsers')

          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState(
                  {
                    storeId: doc.data().storeId,
                    restaurantId: doc.data().restaurantId,
                    timeZone: doc.data().timeZone,
                  },
                  () => {
                    this.props.getVouchersHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getVoucherCountHandler(doc.data().storeId);
                    this.props.getVouchersExpiredHandler(
                      doc.data().storeId,
                      this.state.pagination
                    );
                    this.props.getVoucherCountExpiredHandler(
                      doc.data().storeId
                    );
                    this.props.getStoreHandler(doc.data().storeId);
                    this.props.getCategoriesHandler(doc.data().storeId);
                    this.props.getSizesHandler(doc.data().storeId);
                    this.props.getMenuItemsHandler(doc.data().storeId);
                  }
                );
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      vouchers: newProps.voucher.vouchers,
      totalCount: newProps.voucher.totalCount,
      vouchersExpired: newProps.voucher.vouchersExpired,
      totalCountExpired: newProps.voucher.totalCountExpired,
      store: newProps.voucher.store,
      categories: newProps.menu.categories,
      sizes: newProps.menu.allSizes,
      menuItems: newProps.menu.menuItems,
    });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  deleteVoucherHandler = (voucher) => {
    this.props.deleteVoucherHandler(voucher);
  };

  handlePageChange = (e) => {
    this.setState(
      {
        vouchers: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        this.props.getVouchersHandler(
          this.state.storeId,
          this.state.pagination
        );
      }
    );
  };

  handlePageChangeExpired = (e) => {
    this.setState(
      {
        vouchersExpired: undefined,
        paginationExpired: { ...this.state.paginationExpired, currentPage: e },
      },
      () => {
        this.props.getVouchersExpiredHandler(
          this.state.storeId,
          this.state.paginationExpired
        );
      }
    );
  };

  addVoucherHandler = (voucher) => {
    try {
      voucher.storeId = this.state.storeId;
      voucher.restaurantId = this.state.restaurantId;
      voucher.voucherUsageCount = 0;
      this.props.addVoucherHandler(voucher);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  render() {
    let currency =
      this.props.layout &&
      this.props.layout.store &&
      this.props.layout.store.currency
        ? this.props.layout.store.currency
        : '$';
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <VoucherList
            vouchers={this.state.vouchers}
            totalCount={this.state.totalCount}
            handlePageChange={this.handlePageChange}
            pagination={this.state.pagination}
            deleteVoucherHandler={this.deleteVoucherHandler}
            timeZone={this.state.timeZone}
            store={this.state.store}
            storeId={this.state.storeId}
            Sentry={Sentry}
            redeemVoucherHandler={this.props.redeemVoucherHandler}
            addVoucherHandler={this.addVoucherHandler}
            isActiveVoucherList={true}
            categories={this.state.categories}
            sizes={this.state.sizes}
            menuItems={this.state.menuItems}
            storeId={this.state.storeId}
            currency={currency}
          />
          <VoucherList
            vouchers={this.state.vouchersExpired}
            totalCount={this.state.totalCountExpired}
            handlePageChange={this.handlePageChangeExpired}
            pagination={this.state.paginationExpired}
            deleteVoucherHandler={this.deleteVoucherHandler}
            timeZone={this.state.timeZone}
            store={this.state.store}
            storeId={this.state.storeId}
            Sentry={Sentry}
            redeemVoucherHandler={this.props.redeemVoucherHandler}
            addVoucherHandler={this.addVoucherHandler}
            isActiveVoucherList={false}
            categories={this.state.categories}
            sizes={this.state.sizes}
            menuItems={this.state.menuItems}
            storeId={this.state.storeId}
            currency={currency}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    voucher: state.voucherReducer,
    menu: state.menuReducer,
    layout: state.layoutReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVouchersHandler: (storeId, pagination) =>
      dispatch(getVouchers(storeId, pagination)),
    addVoucherHandler: (voucher) => dispatch(addVoucher(voucher)),
    deleteVoucherHandler: (voucher) => dispatch(deleteVoucher(voucher)),
    redeemVoucherHandler: (voucher) => dispatch(redeemVoucher(voucher)),
    getVoucherCountHandler: (storeId) => dispatch(getVoucherCount(storeId)),
    getStoreHandler: (storeId) => dispatch(getStore(storeId)),
    getVouchersExpiredHandler: (storeId, pagination) =>
      dispatch(getVouchersExpired(storeId, pagination)),
    getVoucherCountExpiredHandler: (storeId) =>
      dispatch(getVoucherCountExpired(storeId)),
    getCategoriesHandler: (storeId) => dispatch(getCategories(storeId)),
    getSizesHandler: (storeId) => dispatch(getSizes(storeId)),
    getMenuItemsHandler: (storeId) => dispatch(getMenuItems(storeId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Vouchers);
