import ActionTypes from './constants';
export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchCloseDates:
      return { ...state, closeDates: payload.closeDates };
    case ActionTypes.CloseDateCountFetched:
      return { ...state, totalCount: payload.totalCount };
    case ActionTypes.ResetCloseDatePageData:
      return {
        ...state,
        totalCount: undefined,
        closeDates: undefined,
      };
    default:
      return state;
  }
};
