import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { TimePicker } from 'antd';
import ImageCompressor from 'image-compressor.js';
import MenuItemUpsalePrice from '../MenuItemUpsalePrice';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import './index.css';

const imageCompressor = new ImageCompressor();

let compressionObj = {
  quality: 0.8,
  convertSize: 10000,
  mimeType: 'jpeg',
  maxWidth: 700,
};

let compressionObjLarge = {
  quality: 0.6,
  convertSize: 10000,
  mimeType: 'jpeg',
  maxWidth: 700,
};
class SpecialForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: [],
      isValidationError: false,
      isCategoryInEditmode: false,
      indexInEdit: -1,
      validationMessage: '',
      specialForEdit: {},
      categories: [],
      menuItems: [],
      quantity: '1',
      specialName: '',
      specialDisplayName: '',
      availableDays: {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
        Sunday: true,
      },
      availableForPickup: true,
      availableForDelivery: true,
      type: '1',
      cost: '0',
      isAvailableFullDay: true,
      isNameCanBeUsedInFrame: false,
      isAvailablePublicHoliday: true,
      startTime: 0,
      endTime: 900,
      startTimeDisplay: '00:00',
      endTimeDisplay: '00:00',
      isEditModeOn: false,
      mySizes: [],
      startTimeUI: '00:00',
      endTimeUI: '00:00',
      isFreeItem: false,
      discountedPrice: '0',
    };
  }

  componentDidMount() {
    const category =
      this.props.categories && this.props.categories.length > 0
        ? this.props.categories[0]
        : { _id: 0 };
    let categories = [];
    if (this.props.categories && this.props.categories.length > 0) {
      this.props.categories.map((m, i) => {
        let obj = {
          _id: m._id,
          name: m.catName,
          isActive: i === 0 ? true : false,
        };
        categories.push(obj);
      });
    }
    const filteredMenuItems = this.props.menuItems
      ? this.props.menuItems.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuItems = [];
    filteredMenuItems.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        price: '0',
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
      };
      menuItems.push(obj);
    });
    const mySizes = this.props.sizes
      ? this.props.sizes.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuSizes = [];
    mySizes.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
      };
      menuSizes.push(obj);
    });
    this.setState({
      categories,
      menuItems,
      mySizes: menuSizes,
    });
  }

  componentWillReceiveProps(props) {
    const category =
      props.categories && props.categories.length > 0
        ? props.categories[0]
        : { _id: 0 };
    let categories = [];
    if (props.categories && props.categories.length > 0) {
      props.categories.map((m, i) => {
        let obj = {
          _id: m._id,
          name: m.catName,
          isActive: i === 0 ? true : false,
        };
        categories.push(obj);
      });
    }
    const filteredMenuItems = props.menuItems
      ? props.menuItems.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuItems = [];
    filteredMenuItems.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        price: '0',
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
        selectedSizes: m.selectedSizes,
      };
      menuItems.push(obj);
    });
    const mySizes = props.sizes
      ? props.sizes.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuSizes = [];
    mySizes.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
        count: 0,
      };
      menuSizes.push(obj);
    });
    menuItems.forEach((menuItem) => {
      menuItem.selectedSizes.forEach((menuItemSize) => {
        menuSizes.forEach((menuSize) => {
          if (menuSize._id === menuItemSize._id) {
            menuSize.count += 1;
          }
        });
      });
    });

    menuSizes = menuSizes.filter((menuSize) => menuSize.count > 0);

    this.setState({
      categories,
      menuItems,
      mySizes: menuSizes,
    });

    if (props.isEditModeOn) {
      this.setState(
        {
          specialForEdit: props.specialForEdit,
          selectedCategories: props.specialForEdit.selectedCategories,
          availableDays: {
            Monday: props.specialForEdit.Monday,
            Tuesday: props.specialForEdit.Tuesday,
            Wednesday: props.specialForEdit.Wednesday,
            Thursday: props.specialForEdit.Thursday,
            Friday: props.specialForEdit.Friday,
            Saturday: props.specialForEdit.Saturday,
            Sunday: props.specialForEdit.Sunday,
          },
          availableForPickup: props.specialForEdit.isAvailablePickup,
          availableForDelivery: props.specialForEdit.isAvailableDelivery,
          isAvailableFullDay: props.specialForEdit.isAvailableFullDay,
          type: props.specialForEdit.type,
          isNameCanBeUsedInFrame: props.specialForEdit.isNameCanBeUsedInFrame,
          isAvailablePublicHoliday:
            props.specialForEdit.isAvailablePublicHoliday,
          startTime:
            props.specialForEdit.startTime !== ''
              ? props.specialForEdit.startTime
              : '',
          startTimeDisplay:
            props.specialForEdit.startTimeDisplay !== ''
              ? props.specialForEdit.startTimeDisplay
              : '',
          startTimeUI:
            props.specialForEdit.startTimeDisplay !== ''
              ? moment(props.specialForEdit.startTimeDisplay, 'hh:mm A').format(
                  'HH:mm'
                )
              : '00:00',
          endTime:
            props.specialForEdit.endTime !== ''
              ? props.specialForEdit.endTime
              : '',
          endTimeDisplay:
            props.specialForEdit.endTimeDisplay !== ''
              ? props.specialForEdit.endTimeDisplay
              : '',
          endTimeUI:
            props.specialForEdit.endTimeDisplay !== ''
              ? moment(props.specialForEdit.endTimeDisplay, 'hh:mm A').format(
                  'HH:mm'
                )
              : '00:00',
          cost: props.specialForEdit.cost,
          specialName: props.specialForEdit.name,
          specialDisplayName: props.specialForEdit.displayName,
          specialsPhoto: props.specialForEdit.urlS3,
          isEditModeOn: true,
        },
        () => {
          let openingString = this.state.startTimeUI;
          let hours = openingString.split(':')[0];
          let disabledOpeningHoursArray = [];
          for (let i = 0; i < hours; i++) {
            disabledOpeningHoursArray.push(i);
          }
          this.setState({
            disabledOpeningHoursArray: disabledOpeningHoursArray,
          });
        }
      );
    }
  }

  addToSpecial = () => {
    const menuItemsFiltered = cloneDeep(this.state.menuItems);
    menuItemsFiltered.forEach((menuItem) => {
      delete menuItem.selectedSizes;
    });

    const item = {
      categories: this.state.categories,
      quantity: this.state.quantity,
      displayName: this.state.displayName,
      menuItems: menuItemsFiltered,
      menuSizes: this.state.mySizes,
      isFreeItem: this.state.isFreeItem,
      discountedPrice: this.state.discountedPrice,
    };
    const category =
      this.props.categories && this.props.categories.length > 0
        ? this.props.categories[0]
        : { _id: 0 };
    let categories = [];
    if (this.props.categories && this.props.categories.length > 0) {
      this.props.categories.map((m, i) => {
        let obj = {
          _id: m._id,
          name: m.catName,
          isActive: i === 0 ? true : false,
        };
        categories.push(obj);
      });
    }
    const filteredMenuItems = this.props.menuItems
      ? this.props.menuItems.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuItems = [];
    filteredMenuItems.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        price: '0',
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
      };
      menuItems.push(obj);
    });
    const mySizes = this.props.sizes
      ? this.props.sizes.filter((item) => {
          return item.categoryId === category._id;
        })
      : [];
    let menuSizes = [];
    mySizes.map((m) => {
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName: category.catName ? category.catName : '',
        categoryId: category._id,
        isActive: true,
      };
      menuSizes.push(obj);
    });
    if (!this.state.isCategoryInEditmode) {
      let selectedCategories = [...this.state.selectedCategories, item];
      selectedCategories.map((c, index) => {
        c.index = index;
        return c;
      });
      this.setState(
        {
          isValidationError: false,
          selectedCategories,
          mySizes: menuSizes,
          menuItems,
          categories,
          isFreeItem: false,
          discountedPrice: '0',
        },
        () => {
          document.getElementById('select-quantity').value = '1';
          document.getElementById('select-displayName').value = '';
        }
      );
    } else {
      // Remove the category and instead add the new edited category
      let selectedCategories = [...this.state.selectedCategories];
      let indexToDelete = 0;
      selectedCategories.forEach((category, i) => {
        if (category.index === this.state.indexInEdit) {
          indexToDelete = i;
        }
      });
      selectedCategories.splice(indexToDelete, 1);
      selectedCategories.push(item);
      selectedCategories.map((c, index) => {
        c.index = index;
        return c;
      });
      this.setState(
        {
          selectedCategories,
          isValidationError: false,
          categories,
          menuItems,
          mySizes: menuSizes,
          isCategoryInEditmode: false,
          isFreeItem: false,
          discountedPrice: '0',
        },
        () => {
          document.getElementById('select-quantity').value = '1';
          document.getElementById('select-displayName').value = '';
        }
      );
    }
  };

  resetSpecials = () => {
    this.setState(
      {
        isValidationError: false,
        selectedCategories: [],
        validationMessage: '',
        specialForEdit: {},
        specialName: '',
        specialDisplayName: '',
        availableDays: {
          Monday: true,
          Tuesday: true,
          Wednesday: true,
          Thursday: true,
          Friday: true,
          Saturday: true,
          Sunday: true,
        },
        availableForPickup: true,
        availableForDelivery: true,
        isAvailableFullDay: true,
        type: '1',
        isNameCanBeUsedInFrame: false,
        isAvailablePublicHoliday: true,
        startTime: '00:00',
        endTime: '23:45',
        cost: '0',
        photo: null,
        imageFiles: [],
        isEditModeOn: false,
      },
      () => {
        document.getElementById('select-quantity').value = '1';
        document.getElementById('select-displayName').value = '';
      }
    );
  };

  removeFromSpecial = (index) => {
    let selectedCategories = this.state.selectedCategories.filter((obj) => {
      return obj.index !== index;
    });
    selectedCategories.map((m, i) => {
      m.index = i;
      return m;
    });
    this.setState({ selectedCategories });
  };

  editForSpecial = (category) => {
    this.setState({
      quantity: category.quantity,
      isCategoryInEditmode: true,
      indexInEdit: category.index,
      mySizes: category.menuSizes,
      menuItems: category.menuItems,
      categories: category.categories,
      isFreeItem: category.isFreeItem,
      discountedPrice: category.discountedPrice
        ? category.discountedPrice
        : '0',
    });
  };

  editForUpsalePrice = (category) => {
    this.setState({
      editCategory: category,
      showUpsaleModal: true,
    });
  };

  closeUpsaleModal = () => {
    this.setState({
      showUpsaleModal: false,
      editCategory: {},
    });
  };

  saveCategory = (editCategory) => {
    let selectedCategories = this.state.selectedCategories;
    selectedCategories.map((m) => {
      if (m.index === editCategory.index) m.menuItems = editCategory.menuItems;
      return m;
    });
    this.setState({
      selectedCategories,
      showUpsaleModal: false,
      editCategory: {},
    });
  };

  hanldeFileChange = (filesToUpload) => {
    filesToUpload.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    imageCompressor
      .compress(filesToUpload[0], compressionObj)
      .then((result) => {
        if (result.size > 140000) {
          imageCompressor
            .compress(result, compressionObjLarge)
            .then((resultOne) => {
              this.setState({
                photo: new File([resultOne], 'name'),
                imageFiles: filesToUpload,
              });
            })
            .catch(() => {});
        } else {
          this.setState({
            photo: new File([result], 'name'),
            imageFiles: filesToUpload,
          });
        }
      })
      .catch(() => {});
  };

  handleQuantityChange = (e) => {
    this.setState({
      quantity: e.target.value,
    });
  };

  handleDNameChange = (e) => {
    this.setState({
      displayName: e.target.value,
    });
  };

  generateMenuSizeString = (index, menuSize, description, menuSizes) => {
    let menuSizesArray = [];
    menuSizes.forEach((menuSize) => {
      if (menuSize.isActive) {
        menuSizesArray.push(menuSize);
      }
    });
    if (menuSizesArray.length > 1) {
      if (index === 0) {
        return `${description} ${menuSize.name}`;
      } else {
        return `${description} / ${menuSize.name}`;
      }
    } else if (menuSizesArray.length === 1) {
      return `${description} ${menuSize.name}`;
    }
  };

  saveSpecial = () => {
    if (this.state.selectedCategories.length > 0) {
      let description = '';
      let arrayDescriptions = [];
      this.state.selectedCategories.map((item) => {
        let arrayItemDescriptions = [];
        let activeCategories = item.categories.filter((f) => f.isActive);
        activeCategories.map((c) => {
          let itemDescription = '';
          itemDescription = `${item.quantity} `;
          let activeSizes = item.menuSizes.filter((f) => {
            return f.isActive && f.categoryId === c._id;
          });
          itemDescription =
            itemDescription + `${activeSizes.map((m) => m.name).join('/')}`;
          itemDescription = `${itemDescription} ${c.name}`;
          arrayItemDescriptions.push(itemDescription);
        });
        arrayDescriptions.push(arrayItemDescriptions.join(' or '));
      });
      description = arrayDescriptions.join(', ').replace(/,(?!.*,)/gim, ' and');
      let specialUsageCount;
      if (this.props.specialForEdit && this.props.specialForEdit._id) {
        //values.specialUsageCount = this.state.specialForEdit.specialUsageCount;
        specialUsageCount = this.props.specialForEdit.specialUsageCount;
      } else {
        specialUsageCount = 0;
      }
      let specialObj = {
        Friday: this.state.availableDays.Friday,
        Monday: this.state.availableDays.Monday,
        Saturday: this.state.availableDays.Saturday,
        Sunday: this.state.availableDays.Sunday,
        Thursday: this.state.availableDays.Thursday,
        Tuesday: this.state.availableDays.Tuesday,
        Wednesday: this.state.availableDays.Wednesday,
        cost: this.state.cost,
        date: getCurrentFormattedMomentDate(
          this.props.timeZone,
          'YYYY-MM-DD HH:mm'
        ),
        dateTimestamp: getCurrentTimestamp(),
        description: description,
        isAvailableDelivery: this.state.availableForDelivery,
        isAvailablePickup: this.state.availableForPickup,
        isAvailableFullDay: this.state.isAvailableFullDay,
        type: this.state.type,
        isNameCanBeUsedInFrame: this.state.isNameCanBeUsedInFrame,
        isAvailablePublicHoliday: this.state.isAvailablePublicHoliday,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        startTimeDisplay: this.state.startTimeDisplay,
        endTimeDisplay: this.state.endTimeDisplay,
        name: this.state.specialName,
        displayName: this.state.specialDisplayName,
        specialUsageCount: specialUsageCount,
        selectedCategories: this.state.selectedCategories,
        photo: this.state.photo,
        urlS3: this.state.specialsPhoto ? this.state.specialsPhoto : null,
        _id: this.props.specialForEdit ? this.props.specialForEdit._id : null,
      };
      this.props.addSpecialHandler(specialObj);
      this.setState(
        {
          selectedCategories: [],
          isValidationError: false,
          photo: '',
        },
        () => {
          this.resetSpecials();
        }
      );
    }
  };

  handleChangeCatChk = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let categories = JSON.parse(JSON.stringify(this.state.categories));
    categories.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    let catIds = [];
    catIds = categories.filter((f) => f.isActive).map((m) => m._id);
    const filteredMenuItems = this.props.menuItems
      ? this.props.menuItems.filter((item) => {
          return catIds.includes(item.categoryId);
        })
      : [];
    let menuItems = [];
    let menuSizes = [];
    filteredMenuItems.map((m) => {
      let matchedCategories = categories.filter((f) => {
        return f._id === m.categoryId;
      });
      let obj = {
        _id: m._id,
        name: m.name,
        price: '0',
        categoryName:
          matchedCategories.length > 0 ? matchedCategories[0].name : '',
        categoryId: matchedCategories[0]._id,
        selectedSizes: m.selectedSizes,
        isActive: true,
      };
      menuItems.push(obj);
    });
    const mySizes = this.props.sizes
      ? this.props.sizes.filter((item) => {
          return catIds.includes(item.categoryId);
        })
      : [];
    mySizes.map((m) => {
      let matchedCategories = categories.filter((f) => {
        return f._id === m.categoryId;
      });
      let obj = {
        _id: m._id,
        name: m.name,
        categoryName:
          matchedCategories.length > 0 ? matchedCategories[0].name : '',
        categoryId: matchedCategories[0]._id,
        isActive: true,
        count: 0,
      };
      menuSizes.push(obj);
    });

    menuItems.forEach((menuItem) => {
      menuItem.selectedSizes.forEach((menuItemSize) => {
        menuSizes.forEach((menuSize) => {
          if (menuSize._id === menuItemSize._id) {
            menuSize.count += 1;
          }
        });
      });
    });

    menuSizes = menuSizes.filter((menuSize) => menuSize.count > 0);

    this.setState({
      categories,
      menuItems,
      mySizes: menuSizes,
    });
  };

  handleChangeChk = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let menuItems = JSON.parse(JSON.stringify(this.state.menuItems));
    menuItems.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    this.setState({
      menuItems,
    });
  };

  handleChangeChkFree = (event) => {
    this.setState({
      isFreeItem: event.target.checked,
    });
  };

  handleChangeDisPrice = (event) => {
    this.setState({
      discountedPrice: event.target.value,
    });
  };

  handleChangeSizeChk = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;
    let menuSizes = JSON.parse(JSON.stringify(this.state.mySizes));
    menuSizes.map((m) => {
      if (m._id === id) m.isActive = value;
      return m;
    });
    this.setState({
      mySizes: menuSizes,
    });
  };

  handleSpecialName = (e) => {
    this.setState({
      specialName: e.target.value,
    });
  };

  handleDisplaySpecialName = (e) => {
    this.setState({ specialDisplayName: e.target.value });
  };

  handleChangeDay = (e, val) => {
    this.setState((prevState) => {
      return {
        availableDays: {
          ...this.state.availableDays,
          [val]: !prevState.availableDays[val],
        },
      };
    });
  };

  handlePickup = () => {
    this.setState((prevState) => {
      return {
        ...this.state,
        availableForPickup: !prevState.availableForPickup,
      };
    });
  };

  handleDayAvailability = () => {
    this.setState((prevState) => {
      return {
        ...this.state,
        isAvailableFullDay: !prevState.isAvailableFullDay,
      };
    });
  };

  handleNameDisplay = () => {
    this.setState((prevState) => {
      return {
        ...this.state,
        isNameCanBeUsedInFrame: !prevState.isNameCanBeUsedInFrame,
      };
    });
  };

  handlePublicHoliday = () => {
    this.setState((prevState) => {
      return {
        ...this.state,
        isAvailablePublicHoliday: !prevState.isAvailablePublicHoliday,
      };
    });
  };
  handleDelivery = () => {
    this.setState((prevState) => {
      return {
        ...this.state,
        availableForDelivery: !prevState.availableForDelivery,
      };
    });
  };

  handleCost = (e) => {
    this.setState({
      cost: e.target.value,
    });
  };

  handleStartTimeChange = (time, timeString) => {
    this.setState({ startTimeUI: timeString }, () => {
      let openingString = this.state.startTimeUI;
      let hours = openingString.split(':')[0];
      let disabledOpeningHoursArray = [];
      for (let i = 0; i < hours; i++) {
        disabledOpeningHoursArray.push(i);
      }
      this.setState({
        startTimeDisplay: moment(this.state.startTimeUI, 'HH:mm').format(
          'hh:mm A'
        ),
        startTime: moment(this.state.startTimeUI, 'HH:mm').diff(
          moment().startOf('day'),
          'seconds'
        ),
        disabledOpeningHoursArray: disabledOpeningHoursArray,
      });
    });
  };

  handleEndTimeChange = (time, timeString) => {
    this.setState({ endTimeUI: timeString }, () => {
      this.setState({
        endTimeDisplay: moment(this.state.endTimeUI, 'HH:mm').format('hh:mm A'),
        endTime: moment(this.state.endTimeUI, 'HH:mm').diff(
          moment().startOf('day'),
          'seconds'
        ),
      });
    });
  };

  getSizesList = (category) => {
    return category.menuSizes
      .filter((f) => f.isActive)
      .map((f) => f.name)
      .join(',');
  };

  getMenuItemsList = (category) => {
    return category.menuItems
      .filter((f) => f.isActive)
      .map((f) => f.name)
      .join(',');
  };

  getCategoryList = (category) => {
    return category.categories
      .filter((f) => f.isActive)
      .map((f) => f.name)
      .join(',');
  };

  secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var amPmDisplay = '';
    if (h > 12) {
      amPmDisplay = 'PM';
      h = h - 12;
    } else if (h === 12 && m === 0) {
      amPmDisplay = 'PM';
      h = 12;
    } else amPmDisplay = 'AM';
    var hDisplay = h > 0 ? (h >= 9 ? h : '0' + h) + ':' : '';
    var mDisplay = m > 0 ? m : '00';
    return hDisplay + mDisplay + ' ' + amPmDisplay;
  };

  getCategoryName = (m) => {
    let activeCategories = this.state.categories.filter((c) => {
      return c.isActive;
    });

    if (activeCategories.length <= 1) return '';
    else {
      let matchedCats = activeCategories.filter((c) => {
        return c.name === m.categoryName;
      });
      if (matchedCats.length > 0) return `(${matchedCats[0].name})`;
      else return '';
    }
  };

  handleTypeChange = (e) => {
    this.setState({
      type: e.target.value,
    });
  };

  render() {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='panel panel-border-color panel-border-color-primary'>
            <div className='panel-heading'>
              <div className='form-group'>
                <label className='col-md-2 control-label text-right t10'>
                  Add Special
                </label>
                <div className='col-md-4'>
                  <input
                    type='text'
                    name='name'
                    value={this.state.specialName}
                    placeholder='Special Name'
                    className='form-control input-sm'
                    onChange={this.handleSpecialName}
                  />
                </div>
              </div>
            </div>
            <div className='clear' />
            <div className='panel-body'>
              <hr />
              <div>
                <div>
                  <div className='form-group'>
                    <label className='col-md-2 control-label text-right  t10'>
                      Display Name
                    </label>
                    <div className='col-md-10'>
                      <input
                        type='text'
                        name='specialDisplayName'
                        value={this.state.specialDisplayName}
                        placeholder='Special Display Name'
                        className='form-control input-sm'
                        onChange={this.handleDisplaySpecialName}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group t10'>
                    <label className='col-md-2 control-label text-right t10'>
                      Type
                    </label>
                    <div className='col-md-4' onChange={this.handleTypeChange}>
                      <div className='be-checkbox inline'>
                        <input
                          name='typenew'
                          id='type_discount'
                          checked={this.state.type === '1' ? 'checked' : ''}
                          type='radio'
                          value='1'
                        />
                        <label htmlFor='type_discount'>Discount</label>
                      </div>
                      <div className='be-checkbox inline'>
                        <input
                          name='typenew'
                          id='type_free_items'
                          checked={this.state.type === '2' ? 'checked' : ''}
                          type='radio'
                          value='2'
                        />
                        <label htmlFor='type_free_items'>Free Items</label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label className='col-md-2 control-label text-right  t10'>
                      Categories
                    </label>
                    <div className='col-md-10'>
                      {this.state.categories.map((m, index) => (
                        <div key={index} className='be-checkbox inline'>
                          <input
                            checked={m.isActive}
                            onChange={this.handleChangeCatChk}
                            name={m.name}
                            id={m._id}
                            type='checkbox'
                          />
                          <label htmlFor={m._id}>{m.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label className='col-md-2 control-label text-right  t10'>
                      Quantity
                    </label>
                    <div className='col-md-2'>
                      <input
                        type='number'
                        min='1'
                        max='99'
                        defaultValue='1'
                        ref='quantity'
                        className='form-control'
                        id='select-quantity'
                        value={this.state.quantity}
                        onChange={(e) => this.handleQuantityChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label className='col-md-2 control-label text-right  t10'>
                      Display Name
                    </label>
                    <div className='col-md-2'>
                      <input
                        type='text'
                        name='displayName'
                        className='form-control'
                        id='select-displayName'
                        value={this.state.displayName}
                        onChange={(e) => this.handleDNameChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label className='col-md-2 control-label t10 text-right'>
                      Sizes
                    </label>
                    <div className='col-md-10'>
                      {this.state.mySizes.map((m, index) => (
                        <div key={index} className='be-checkbox inline'>
                          <input
                            checked={m.isActive}
                            onChange={(e) => this.handleChangeSizeChk(e)}
                            name={m.name}
                            id={m._id}
                            type='checkbox'
                          />
                          <label htmlFor={m._id}>{`${
                            m.name
                          } ${this.getCategoryName(m)}`}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='form-group col-md-12 col-md-offset-1 t10'>
                    <label className='col-md-2 control-label text-right t10'>
                      Menu Items
                    </label>
                    <div className='col-md-10'>
                      {this.state.menuItems.map((m, index) => (
                        <div key={index} className='be-checkbox inline'>
                          <input
                            checked={m.isActive}
                            onChange={this.handleChangeChk}
                            name={m.name}
                            id={m._id}
                            type='checkbox'
                          />
                          <label htmlFor={m._id}>{`${
                            m.name
                          } ${this.getCategoryName(m)}`}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.type === '2' && (
                    <React.Fragment>
                      <div className='form-group col-md-12 col-md-offset-1 t10'>
                        <label className='col-md-2 control-label text-right t10'>
                          Is item on discount ?
                        </label>
                        <div className='col-md-10'>
                          <div className='be-checkbox inline'>
                            <input
                              checked={this.state.isFreeItem}
                              onChange={this.handleChangeChkFree}
                              name='free item'
                              id='free-item'
                              type='checkbox'
                            />
                            <label htmlFor='free-item'></label>
                          </div>
                        </div>
                      </div>
                      <div className='form-group col-md-12 col-md-offset-1 t10'>
                        <label className='col-md-2 control-label text-right t10'>
                          Discounted price
                        </label>
                        <div className='col-md-2'>
                          <input
                            onChange={this.handleChangeDisPrice}
                            name='discountedPrice'
                            id='discountedPrice'
                            type='number'
                            defaultValue='1'
                            min='1'
                            max='1000'
                            className='form-control'
                            value={this.state.discountedPrice}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className='form-group col-md-12 col-md-offset-2 t10'>
                  <button
                    type='button'
                    onClick={this.addToSpecial}
                    className='btn btn-space btn-primary'
                    disabled={
                      !(
                        this.state.menuItems.some((menuItem) => {
                          return menuItem.isActive === true;
                        }) &&
                        this.state.mySizes.some((size) => {
                          return size.isActive === true;
                        }) &&
                        this.state.categories.some((cat) => {
                          return cat.isActive === true;
                        })
                      )
                    }
                  >
                    {!this.state.isCategoryInEditmode
                      ? 'Add to Special'
                      : 'Save changes'}
                  </button>
                </div>
                <div className='clear' />
                <hr />
                <div className='col-md-7 table-responcive col-md-offset-2'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Categories</th>
                        <th>Display Name</th>
                        <th>Quantity</th>
                        <th>Sizes</th>
                        <th>Menu Items</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>&nbsp; </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.selectedCategories &&
                      this.state.selectedCategories.length > 0 ? (
                        this.state.selectedCategories.map((category, index) => (
                          <tr key={index}>
                            <td>{this.getCategoryList(category)}</td>
                            <td>
                              {category.displayName ? category.displayName : ''}
                            </td>
                            <td>{category.quantity}</td>
                            <td>{this.getSizesList(category)}</td>
                            <td>{this.getMenuItemsList(category)}</td>
                            <td>{category.isFreeItem ? 'Free' : ''}</td>
                            <td>
                              {this.state.type === '2' &&
                              category.discountedPrice &&
                              category.discountedPrice !== '0'
                                ? `$${category.discountedPrice}`
                                : ''}
                            </td>
                            <td>
                              <button
                                type='button'
                                onClick={() =>
                                  this.editForUpsalePrice(category)
                                }
                                className='btn btn-space btn-success hover'
                              >
                                Upsell items
                              </button>
                            </td>
                            <td>
                              <button
                                type='button'
                                onClick={() => this.editForSpecial(category)}
                                className='btn btn-space btn-primary hover'
                              >
                                Edit
                              </button>
                            </td>
                            <td>
                              <button
                                type='button'
                                onClick={() =>
                                  this.removeFromSpecial(category.index)
                                }
                                className='btn btn-space btn-danger hover'
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan='4'>
                            {this.state.isValidationError &&
                            this.state.selectedCategories.length < 1 ? (
                              <ul
                                className='parsley-errors-list filled'
                                id='parsley-id-7'
                              >
                                <li
                                  style={{ fontSize: '13px' }}
                                  className='parsley-type'
                                >
                                  At least one category required in special
                                </li>
                              </ul>
                            ) : (
                              'No items available'
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className='clear' />
              </div>
              <hr />
              <div>
                <div className='clear' />
                <div className='clear' />
                <div className='form-group'>
                  <label className='col-md-2 control-label text-right t10'>
                    Available on
                  </label>
                  <div className='col-sm-10'>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='Monday'
                        value='true'
                        id='1'
                        checked={this.state.availableDays.Monday}
                        onChange={(e) => this.handleChangeDay(e, 'Monday')}
                      />
                      <label htmlFor='1'>Monday</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='Tuesday'
                        value='true'
                        id='2'
                        checked={this.state.availableDays.Tuesday}
                        onChange={(e) => this.handleChangeDay(e, 'Tuesday')}
                      />
                      <label htmlFor='2'>Tuesday</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='Wednesday'
                        value='true'
                        id='3'
                        checked={this.state.availableDays.Wednesday}
                        onChange={(e) => this.handleChangeDay(e, 'Wednesday')}
                      />
                      <label htmlFor='3'>Wednesday</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='Thursday'
                        value='true'
                        id='4'
                        checked={this.state.availableDays.Thursday}
                        onChange={(e) => this.handleChangeDay(e, 'Thursday')}
                      />
                      <label htmlFor='4'>Thursday</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='Friday'
                        value='true'
                        id='5'
                        checked={this.state.availableDays.Friday}
                        onChange={(e) => this.handleChangeDay(e, 'Friday')}
                      />
                      <label htmlFor='5'>Friday</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='Saturday'
                        value='true'
                        id='6'
                        checked={this.state.availableDays.Saturday}
                        onChange={(e) => this.handleChangeDay(e, 'Saturday')}
                      />
                      <label htmlFor='6'>Saturday</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='Sunday'
                        value='true'
                        id='7'
                        checked={this.state.availableDays.Sunday}
                        onChange={(e) => this.handleChangeDay(e, 'Sunday')}
                      />
                      <label htmlFor='7'>Sunday</label>
                    </div>
                  </div>
                </div>
                <div className='clear' />
                <div className='clear' />
              </div>
              <div>
                <div className='clear' />
                <div className='clear' />
                <div className='form-group'>
                  <label className='col-md-2 control-label text-right t10'>
                    Specials for
                  </label>
                  <div className='col-sm-10'>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='isAvailablePickup'
                        value='true'
                        id='isAvailablePickup'
                        onChange={this.handlePickup}
                        checked={this.state.availableForPickup}
                      />
                      <label htmlFor='isAvailablePickup'>Pickup</label>
                    </div>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='isAvailableDelivery'
                        value='true'
                        id='isAvailableDelivery'
                        onChange={this.handleDelivery}
                        checked={this.state.availableForDelivery}
                      />
                      <label htmlFor='isAvailableDelivery'>Delivery</label>
                    </div>
                  </div>
                </div>
                <div className='clear' />
                <div className='clear' />
              </div>
              <div>
                <div className='clear' />
                <div className='clear' />
                <div className='form-group'>
                  <label className='col-md-2 control-label text-right t10'>
                    Use special name on special frame ?
                  </label>
                  <div className='col-sm-10'>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='isNameCanBeUsedInFrame'
                        value='true'
                        id='isNameCanBeUsedInFrame'
                        onChange={this.handleNameDisplay}
                        checked={this.state.isNameCanBeUsedInFrame}
                      />
                      <label for='isNameCanBeUsedInFrame'></label>
                    </div>
                  </div>
                </div>
                <div className='clear' />
                <div className='clear' />
              </div>
              <div>
                <div className='clear' />
                <div className='clear' />
                <div className='form-group'>
                  <label className='col-md-2 control-label text-right t10'>
                    Available Full Day
                  </label>
                  <div className='col-sm-10'>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='isAvailableFullDay'
                        value='true'
                        id='isAvailableFullDay'
                        onChange={this.handleDayAvailability}
                        checked={this.state.isAvailableFullDay}
                      />
                      <label for='isAvailableFullDay'></label>
                    </div>
                  </div>
                </div>
                <div className='clear' />
                <div className='clear' />
              </div>
              {!this.state.isAvailableFullDay && (
                <React.Fragment>
                  <div>
                    <div className='clear' />
                    <div className='clear' />
                    <div className='form-group'>
                      <label className='col-md-2 control-label text-right t10'>
                        Start Time
                      </label>
                      <div className='col-sm-10'>
                        <div className='input-group'>
                          <TimePicker
                            format={'HH:mm'}
                            minuteStep={15}
                            onChange={this.handleStartTimeChange}
                            value={moment(this.state.startTimeUI, 'HH:mm')}
                            allowClear={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='clear' />
                    <div className='clear' />
                  </div>
                  <div>
                    <div className='clear' />
                    <div className='clear' />
                    <div className='form-group'>
                      <label className='col-md-2 control-label text-right t10'>
                        End Time
                      </label>
                      <div className='col-sm-10'>
                        <div className='input-group'>
                          <TimePicker
                            format={'HH:mm'}
                            minuteStep={15}
                            onChange={this.handleEndTimeChange}
                            value={moment(this.state.endTimeUI, 'HH:mm')}
                            allowClear={false}
                            disabledHours={() => {
                              return this.state.disabledOpeningHoursArray;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='clear' />
                    <div className='clear' />
                  </div>
                </React.Fragment>
              )}
              <div>
                <div className='clear' />
                <div className='clear' />
                <div className='form-group'>
                  <label className='col-md-2 control-label text-right t10'>
                    Available On Public Holidays
                  </label>
                  <div className='col-sm-10'>
                    <div className='be-checkbox inline'>
                      <input
                        type='checkbox'
                        name='isAvailablePublicHoliday'
                        value='true'
                        id='isAvailablePublicHoliday'
                        onChange={this.handlePublicHoliday}
                        checked={this.state.isAvailablePublicHoliday}
                      />
                      <label for='isAvailablePublicHoliday'></label>
                    </div>
                  </div>
                </div>
                <div className='clear' />
                <div className='clear' />
              </div>
              <div>
                {this.state.type === '1' && (
                  <div className='form-group'>
                    <label className='col-md-2 control-label text-right t10'>
                      Cost
                    </label>
                    <div className='col-md-2'>
                      <div className='input-group'>
                        <span className='input-group-addon'>
                          {this.props.currency}
                        </span>
                        <input
                          type='text'
                          name='cost'
                          value={this.state.cost}
                          placeholder={`${this.props.currency} 35`}
                          className='form-control'
                          onChange={this.handleCost}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className='form-group'>
                  <label className={'col-md-2 control-label text-right t10'}>
                    Special Photo
                  </label>
                  <div className={'col-md-4 t10'}>
                    <Dropzone
                      className='uplodedImg'
                      name={'photo'}
                      accept='image/*'
                      onDrop={(filesToUpload) => {
                        this.setState({
                          isEditModeOn: false,
                        });
                        this.hanldeFileChange(filesToUpload);
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <label htmlFor='photo' className='btn-default'>
                          <i className='mdi mdi-upload' />
                          <span> Browse files...</span>
                        </label>
                        <div>
                          {this.state.isEditModeOn ? (
                            <React.Fragment>
                              <img
                                src={this.state.specialsPhoto}
                                height={128}
                                width={128}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {this.state.imageFiles &&
                                this.state.imageFiles.map((file, index) => {
                                  return (
                                    <img
                                      src={file.preview}
                                      key={index}
                                      height={128}
                                      width={128}
                                    />
                                  );
                                })}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </Dropzone>
                  </div>
                </div>
                <div
                  className='form-group col-md-10 t10 col-md-offset-1'
                  style={{ marginTop: '20px' }}
                >
                  <button
                    onClick={this.resetSpecials}
                    type='button'
                    className='btn btn-space'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='btn btn-space btn-primary'
                    onClick={this.saveSpecial}
                    disabled={
                      !(
                        this.state.specialName !== '' &&
                        (this.state.availableForDelivery === true ||
                          this.state.availableForPickup === true) &&
                        this.state.cost !== '' &&
                        (this.state.photo || this.state.specialsPhoto) &&
                        (this.state.availableDays.Monday === true ||
                          this.state.availableDays.Tuesday === true ||
                          this.state.availableDays.Wednesday === true ||
                          this.state.availableDays.Thursday === true ||
                          this.state.availableDays.Friday === true ||
                          this.state.availableDays.Saturday === true ||
                          this.state.availableDays.Sunday === true) &&
                        this.state.selectedCategories.length >= 1
                      )
                    }
                  >
                    {this.props.isEditModeOn ? 'Edit Special' : 'Add Special'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showUpsaleModal && (
          <MenuItemUpsalePrice
            showUpsaleModal={this.state.showUpsaleModal}
            closeUpsaleModal={this.closeUpsaleModal}
            editCategory={this.state.editCategory}
            saveCategory={this.saveCategory}
          />
        )}
      </div>
    );
  }
}

SpecialForm.propTypes = {
  addSpecialHandler: PropTypes.any,
  categories: PropTypes.any,
  sizes: PropTypes.any,
  menuItems: PropTypes.any,
  isEditModeOn: PropTypes.any,
  specialForEdit: PropTypes.any,
};

export default SpecialForm;
