import React, { Component } from 'react';
import { auth, firestore } from '../../firebase';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Layout from '../../containers/Layout';

import { migScript1, migScript2, migScript3, migScript4 } from './actions';

class Migrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrders: [],
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        window.location = '/restaurants/login';
      } else {
        firestore
          .collection('SystemUsers')
          .where('type', '==', '1')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              querySnapshot.forEach((doc) => {
                this.setState({
                  storeId: doc.data().storeId,
                  restaurantId: doc.data().restaurantId,
                  timeZone: doc.data().timeZone,
                });
              });
            } else {
              window.location = '/restaurants/login';
            }
          });
      }
    });
  }

  render() {
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='row'>
            <div className='col-md-3 col-sm-12'>
              <div className='panel panel-default panel-border-color panel-border-color-primary'>
                <div className='panel-heading'>Menu Items data</div>
                <div className='panel-body'>
                  <div className='form-group login-submit'>
                    <button
                      type='button'
                      onClick={() => this.props.migScript1()}
                      className='btn btn-primary btn-xl'
                    >
                      Migration 1
                    </button>
                  </div>

                  <div className='form-group login-submit'>
                    <button
                      type='button'
                      onClick={() => this.props.migScript2()}
                      className='btn btn-primary btn-xl'
                    >
                      Migration 2
                    </button>
                  </div>
                  <div className='form-group login-submit'>
                    <button
                      type='button'
                      onClick={() => this.props.migScript3()}
                      className='btn btn-primary btn-xl'
                    >
                      Migration 3
                    </button>
                  </div>
                  <div className='form-group login-submit'>
                    <button
                      type='button'
                      onClick={() => this.props.migScript4()}
                      className='btn btn-primary btn-xl'
                    >
                      Migration 4
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

Migrations.propTypes = {};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    migScript1: () => dispatch(migScript1()),
    migScript2: () => dispatch(migScript2()),
    migScript3: () => dispatch(migScript3()),
    migScript4: () => dispatch(migScript4()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Migrations));
