import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Field, reduxForm, Form } from 'redux-form';
import { auth, firestore } from '../../firebase';
import Layout from '../../containers/InStoreLayout';
import { signIn, signOut } from './actions';
import { nextOrderConfig } from '../../configurations/storeSetup';

Sentry.init({
  dsn: 'https://9c8b2e37edef4f768b34addab27050a1@sentry.io/1362752',
  attachStacktrace: true,
  sendDefaultPii: true,
  environment: nextOrderConfig.environment,
  release: nextOrderConfig.release,
});

let logo =
  'https://s3-ap-southeast-2.amazonaws.com/lacampa/assets/next+order.svg';

const validate = (values) => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Invalid email';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

const warn = () => {
  const warnings = {};
  return warnings;
};

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => (
  <div className='form-group'>
    <input
      {...input}
      placeholder={placeholder}
      type={type}
      className='form-control'
    />
    {touched &&
      ((error && (
        <ul className='parsley-errors-list filled' id='parsley-id-7'>
          <li style={{ fontSize: '13px' }} className='parsley-type'>
            {error}
          </li>
        </ul>
      )) ||
        (warning && (
          <ul className='parsley-errors-list filled' id='parsley-id-7'>
            <li style={{ fontSize: '13px' }} className='parsley-type'>
              {warning}
            </li>
          </ul>
        )))}
  </div>
);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
    };
    this.noLogin = false;
  }

  componentDidMount() {
    this.props.signOutHandler();
    let values = queryString.parse(window.location.search);
    console.log(values);
    if (values.login_id) {
      this.noLogin = true;
      firestore
        .collection('SystemUsers')
        .where('storeId', '==', values.login_id)
        .where('userRole', '==', '3')
        .where('type', '==', '1')
        .get()
        .then((querySnapshot) => {
          var dataArray = [];
          querySnapshot.forEach((doc) => {
            dataArray.push({ _id: doc.id, ...doc.data() });
          });
          if (dataArray.length > 0) {
            auth
              .signInWithEmailAndPassword(
                dataArray[0].email,
                dataArray[0].password
              )
              .then(() => {
                window.location =
                  '/restaurants/dashboard?restaurant=' + values.login_id;
              });
          }
        });
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handleSubmit = (values) => {
    if (!this.state.isLoader) {
      this.setState({ isLoader: true }, () => {
        try {
          auth
            .signInWithEmailAndPassword(values.email, values.password)
            .then(() => {
              firestore
                .collection('SystemUsers')
                .where('type', '==', '1')
                .where('email', '==', values.email)
                .get()
                .then((querySnapshot) => {
                  var dataArray = [];
                  querySnapshot.forEach((doc) => {
                    dataArray.push({ _id: doc.id, ...doc.data() });
                  });

                  if (dataArray && dataArray.length > 0) {
                    let redirectUrls = queryString.parse(
                      window.location.search
                    );
                    if (redirectUrls && redirectUrls.redirect) {
                      if (redirectUrls.redirect.indexOf('?') === -1) {
                        window.location =
                          redirectUrls.redirect +
                          '?restaurant=' +
                          dataArray[0].storeId;
                      } else {
                        window.location =
                          redirectUrls.redirect +
                          '&restaurant=' +
                          dataArray[0].storeId;
                      }
                    } else {
                      let systemUser = dataArray[0];
                      console.log(systemUser);
                      if (systemUser.userRole === '3') {
                        window.location =
                          '/restaurants/dashboard?restaurant=' +
                          systemUser.storeId;
                      } else if (systemUser.userRole === '1') {
                        window.location =
                          '/restaurants/dashboard?restaurant=' +
                          systemUser.storeId;
                      } else {
                        this.setState({
                          isLoader: false,
                          error: {
                            error: '',
                            message: 'User not supported',
                          },
                        });
                      }
                    }
                  } else {
                    this.setState({
                      isLoader: false,
                      error: {
                        error: '',
                        message: 'Some error occured',
                      },
                    });
                  }
                })
                .catch(() => {
                  this.setState({
                    isLoader: false,
                    error: { error: '', message: 'Some error occured' },
                  });
                });
            })
            .catch((error) => {
              this.setState({ isLoader: false, error });
              Sentry.captureException(error);
            });
        } catch (error) {
          this.setState({ isLoader: false, error });
          Sentry.captureException(error);
        }
      });
    }
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <Layout>
        <div className='main-content container-fluid'>
          <div className='splash-container'>
            {!this.noLogin ? (
              <div className='panel panel-default panel-border-color panel-border-color-primary'>
                <div className='panel-heading'>
                  <img
                    src={logo}
                    alt='logo'
                    width='280'
                    height='auto'
                    className='logo-img'
                  />
                  <span className='splash-description'>
                    Please enter your user information.
                  </span>
                </div>
                <div className='panel-body'>
                  <Form onSubmit={handleSubmit(this.handleSubmit)}>
                    <Field
                      type='text'
                      autocomplete='off'
                      placeholder='Username'
                      name='email'
                      component={renderField}
                    />
                    <Field
                      type='password'
                      placeholder='Password'
                      name='password'
                      component={renderField}
                    />
                    <div className='form-group'>
                      {this.state.error && (
                        <ul
                          className='parsley-errors-list filled'
                          id='parsley-id-7'
                        >
                          <li
                            style={{ fontSize: '13px' }}
                            className='parsley-type'
                          >
                            {this.state.error.message}
                          </li>
                        </ul>
                      )}
                    </div>
                    <div className='form-group row login-tools'>
                      <div className='col-xs-6 login-remember' />
                      <div className='col-xs-6 login-forgot-password' />
                    </div>
                    <div className='form-group login-submit'>
                      {this.state.isLoader ? (
                        <div
                          style={{ padding: '50px' }}
                          className='be-loading be-loading-active'
                        >
                          <div className='be-spinner'>
                            <svg
                              width='40px'
                              height='40px'
                              viewBox='0 0 66 66'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <circle
                                fill='none'
                                strokeWidth='4'
                                strokeLinecap='round'
                                cx='33'
                                cy='33'
                                r='30'
                                className='circle'
                              />
                            </svg>
                          </div>
                        </div>
                      ) : (
                        <button
                          disabled={submitting}
                          type='submit'
                          className='btn btn-primary btn-xl'
                        >
                          Sign me in
                        </button>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            ) : (
              <div
                style={{ padding: '50px' }}
                className='be-loading be-loading-active'
              >
                <div className='be-spinner'>
                  <svg
                    width='40px'
                    height='40px'
                    viewBox='0 0 66 66'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle
                      fill='none'
                      strokeWidth='4'
                      strokeLinecap='round'
                      cx='33'
                      cy='33'
                      r='30'
                      className='circle'
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.loginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signInHandler: (email, password) => dispatch(signIn(email, password)),
    signOutHandler: () => dispatch(signOut()),
  };
}

const LoginForm = reduxForm({
  form: 'loginForm',
  validate,
  warn,
})(Login);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
