import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import getOrderStatus from '../../helpers/getOrderStatus';
import Spinner from '../../components/Spinner';
import {
  getCurrentMomentDate,
  convertToMomentDate,
  convertToFormattedMomentDate,
} from '../../helpers/timeHelper';
import InvoiceModal from '../../components/InvoiceModal';
import RefundModal from '../../components/RefundModal';

class RealtimeOrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageRangeDisplayed: 7,
      },
    };
  }

  componentDidMount() {
    if (this.props.orderList) {
      const start =
        (this.state.pagination.currentPage - 1) *
        this.state.pagination.pageSize;
      const end = start + this.state.pagination.pageSize;
      let totalCount = this.props.orderList.length;
      let realtimeOrders = this.props.orderList.slice(start, end);
      this.setState({
        totalCount,
        users: this.props.users,
        orderSetup: this.props.orderSetup,
        realtimeOrders,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.orderList) {
      const start =
        (this.state.pagination.currentPage - 1) *
        this.state.pagination.pageSize;
      const end = start + this.state.pagination.pageSize;
      let totalCount = newProps.orderList.length;
      let realtimeOrders = newProps.orderList.slice(start, end);
      this.setState({
        totalCount,
        users: newProps.users,
        orderSetup: newProps.orderSetup,
        realtimeOrders,
      });
    }
  }

  handlePageChange = (e) => {
    this.setState(
      {
        realtimeOrders: undefined,
        pagination: { ...this.state.pagination, currentPage: e },
      },
      () => {
        const start =
          (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize;
        const end = start + this.state.pagination.pageSize;
        let realtimeOrders = this.props.orderList.slice(start, end);
        this.setState({
          realtimeOrders,
        });
      }
    );
  };

  getOrderType = (type) => {
    if (type === '1') return 'Pick Up';
    else if (type === '2') return 'Delivery';
    else if (type === '3') return 'Restaurant Reseravtion';
    else if (type === '4') return 'Other Delivery';
  };

  getOrderExpectedFullDate = (obj) => {
    if (obj.orderType === '1' || obj.orderType === '2') {
      return convertToFormattedMomentDate(
        obj.deliveryDate,
        'YYYY-MM-DD HH:mm',
        this.props.timeZone,
        'MMM DD, YYYY'
      );
    } else if (obj.orderType === '3') {
      return convertToFormattedMomentDate(
        obj.reservationDate,
        'YYYY-MM-DD HH:mm',
        this.props.timeZone,
        'MMM DD, YYYY'
      );
    } else if (obj.orderType === '4') {
      return convertToFormattedMomentDate(
        obj.date,
        'YYYY-MM-DD HH:mm',
        this.props.timeZone,
        'MMM DD, YYYY'
      );
    }
  };

  getOrderExpectedTime = (obj) => {
    if (obj.orderType === '1' || obj.orderType === '2') {
      return convertToFormattedMomentDate(
        obj.deliveryDate,
        'YYYY-MM-DD HH:mm',
        this.props.timeZone,
        'HH:mm'
      );
    } else if (obj.orderType === '3') {
      return convertToFormattedMomentDate(
        obj.reservationDate,
        'YYYY-MM-DD HH:mm',
        this.props.timeZone,
        'HH:mm'
      );
    } else if (obj.orderType === '4') {
      return convertToFormattedMomentDate(
        obj.date,
        'YYYY-MM-DD HH:mm',
        this.props.timeZone,
        'HH:mm'
      );
    }
  };

  getOrderStatusClass = (status, type, date) => {
    if (type === '1' || type === '2' || type === '4') {
      return this.getTimeElpasedClass(status, date);
    } else {
      if (status === '1') return 'text-warning';
      else if (status === '2') return 'text-success';
      else if (status === '3') return 'text-danger';
    }
  };

  getTimeElpasedClass = (orderStatus, date) => {
    if (this.state.orderSetup) {
      let end_date = getCurrentMomentDate(this.props.timeZone);
      let start_date = convertToMomentDate(
        date,
        'YYYY-MM-DD HH:mm',
        this.props.timeZone
      );
      var duration = end_date.diff(start_date, 'minutes');
      if (orderStatus === '0' || orderStatus === '2' || orderStatus === '1') {
        if (duration > Number(this.state.orderSetup.pickUpTime)) {
          return 'text-danger';
        } else if (duration + 5 > Number(this.state.orderSetup.pickUpTime)) {
          return 'text-warning';
        } else {
          return 'text-success';
        }
      } else if (orderStatus === '3') {
        if (
          duration >
          Number(this.state.orderSetup.pickUpTime) +
            Number(this.state.orderSetup.deliveryTime)
        ) {
          return 'text-danger';
        } else if (
          duration + 5 >
          Number(this.state.orderSetup.pickUpTime) +
            Number(this.state.orderSetup.deliveryTime)
        ) {
          return 'text-warning';
        } else {
          return 'text-success';
        }
      } else if (orderStatus === '11') {
        return 'text-danger';
      } else {
        return 'text-success';
      }
    } else {
      return 'N/A';
    }
  };

  getFormattedTimeDiff = (date) => {
    let end_date = getCurrentMomentDate(this.props.timeZone);
    let start_date = convertToMomentDate(
      date,
      'YYYY-MM-DD HH:mm',
      this.props.timeZone
    );
    var duration = end_date.diff(start_date, 'minutes');
    if (Number(duration) > 60) {
      let dur = 0;
      dur = `${parseInt(duration / 60, 10)}h ${parseInt(
        duration % 60,
        10
      )} minutes ago`;
      return dur;
    } else {
      return `${duration} minutes ago`;
    }
  };

  closeInvoiceModal = () => {
    this.setState({
      renderInvoiceModal: false,
      invoiceOrder: {},
    });
  };

  openInvoiceModal = (order) => {
    this.setState({
      renderInvoiceModal: true,
      invoiceOrder: order,
    });
  };

  closeRefundPopup = () => {
    this.setState({
      openRefundPopup: false,
    });
  };

  refundOrder = (order) => {
    if (this.props.realtimeOrders) {
      let matchingOrder = this.props.realtimeOrders.filter((m) => {
        return m._id === order._id;
      });
      if (matchingOrder.length > 0) {
        this.setState({
          openRefundPopup: true,
          refundOrder: matchingOrder[0],
        });
      }
    }
  };

  renderRefundStatus = (order) => {
    if (order.orderType === '2' || order.orderType === '1') {
      if (
        (order.status === '2' ||
          order.status === '1' ||
          order.status === '25' ||
          order.status === '3' ||
          order.status === '12' ||
          order.status === '13' ||
          order.status === '4') &&
        order.paymentType === '2' &&
        order.refundStatus !== 0 &&
        order.refundStatus !== 1 &&
        order.refundStatus !== 2 &&
        order.refundStatus !== 3 &&
        this.checkOrderDateDiff(order.dateTimestamp)
      ) {
        return (
          <button
            onClick={() => this.refundOrder(order)}
            className='btn btn-space btn-success'
          >
            Refund
          </button>
        );
      } else if (order.refundStatus === 0) {
        return (
          <React.Fragment>
            <span>Refund Initiated</span>
          </React.Fragment>
        );
      } else if (order.refundStatus === 1) {
        return (
          <React.Fragment>
            <span>Refunded</span>
          </React.Fragment>
        );
      } else if (order.refundStatus === 2) {
        return (
          <React.Fragment>
            <span>Refund Failed</span>
          </React.Fragment>
        );
      } else if (order.refundStatus === 3) {
        return (
          <React.Fragment>
            <span>Refund Rejected</span>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <span></span>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <span></span>
        </React.Fragment>
      );
    }
  };

  checkOrderDateDiff = (dateTimestamp) => {
    let now = new Date();
    let currentTimestamp = now.getTime();
    let timestampDiff = currentTimestamp - dateTimestamp;
    let daysDifference = Math.floor(timestampDiff / 1000 / 60 / 60 / 24);
    if (daysDifference > 7) return false;
    return true;
  };

  renderSendInvoice = (order) => {
    if (order.orderType === '2' || order.orderType === '1') {
      if (order.status === '0' || order.status === '11') {
        return <div></div>;
      } else {
        return (
          <button
            onClick={() => this.openInvoiceModal(order)}
            className='btn btn-space btn-primary'
          >
            Send Invoice
          </button>
        );
      }
    } else {
      return <div></div>;
    }
  };

  render() {
    const start =
      (this.state.pagination.currentPage - 1) * this.state.pagination.pageSize +
      1;
    const end = this.state.realtimeOrders
      ? (this.state.pagination.currentPage - 1) *
          this.state.pagination.pageSize +
        1 +
        this.state.realtimeOrders.length -
        1
      : 0;
    return (
      <div className='row'>
        <div className='col-sm-12 col-xs-12 col-md-12'>
          <div className='panel panel-default panel-table '>
            <div className='panel-heading'>Real-Time Orders</div>
            <div className='panel-body table-responsive'>
              <div
                id='table1_wrapper'
                className='dataTables_wrapper form-inline dt-bootstrap no-footer'
              >
                <div className='row be-datatable-body'>
                  <div className='col-md-12 col-sm-12 table-responsive noSwipe'>
                    <table className='table table-striped table-hover table-fw-widget dataTable no-footer'>
                      <thead>
                        <tr>
                          <th>Order Type</th>
                          <th>Customer</th>
                          <th>Order Details</th>
                          <th>Status </th>
                          <th>Order Expected </th>
                          <th>Code</th>
                          <th />
                        </tr>
                      </thead>
                      {!this.state.realtimeOrders ? (
                        <tbody>
                          <tr style={{ height: '100px' }}>
                            <div
                              style={{
                                alignSelf: 'center',
                                left: '0px',
                                right: '0px',
                                position: 'absolute',
                                width: 'auto',
                                height: '100px',
                              }}
                              className='be-loading be-loading-active'
                            >
                              <Spinner />
                            </div>
                          </tr>
                        </tbody>
                      ) : this.state.realtimeOrders.length > 0 ? (
                        <tbody>
                          {this.state.realtimeOrders &&
                            this.state.realtimeOrders.map((order, index) => (
                              <tr key={index}>
                                <td>{this.getOrderType(order.orderType)}</td>
                                <td>
                                  {`${order.firstName ? order.firstName : ''} ${
                                    order.lastName ? order.lastName : ''
                                  }`}
                                  <br />
                                  {order.address && (
                                    <span className='grayc'>
                                      {order.address}
                                    </span>
                                  )}
                                </td>
                                {order.orderType !== '3' ? (
                                  <td>
                                    {order.orderDetails
                                      ? order.orderDetails
                                      : 'N/A'}
                                  </td>
                                ) : (
                                  <td>
                                    {convertToFormattedMomentDate(
                                      order.reservationDate,
                                      'YYYY-MM-DD HH:mm',
                                      this.props.timeZone,
                                      'HH:mm'
                                    )}
                                    <br />
                                    <span className='grayc'>
                                      {order.orderDetails}
                                    </span>
                                  </td>
                                )}
                                <td
                                  className={this.getOrderStatusClass(
                                    order.status,
                                    order.orderType,
                                    order.date
                                  )}
                                >
                                  {getOrderStatus(
                                    order.status,
                                    order.orderType
                                  )}
                                </td>
                                <td>
                                  {this.getOrderExpectedFullDate(order)}
                                  <br />
                                  <span className='grayc'>
                                    {this.getOrderExpectedTime(order)}
                                  </span>
                                </td>
                                <td>
                                  {order.deliveryCode
                                    ? order.deliveryCode
                                    : 'N/A'}
                                </td>
                                <td>
                                  <button
                                    onClick={() =>
                                      this.props.openModal(
                                        order._id,
                                        order.orderType
                                      )
                                    }
                                    className='btn btn-space btn-default'
                                  >
                                    View
                                  </button>
                                  {this.renderSendInvoice(order)}
                                  {this.renderRefundStatus(order)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <p> No records to display </p>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className='row be-datatable-footer'>
                  <div className='col-md-5 col-sm-12'>
                    <div
                      style={{ float: 'left' }}
                      className='dataTables_info'
                      id='table1_info'
                      role='status'
                      aria-live='polite'
                    >
                      {`Showing ${start} to ${end} of ${
                        this.state.totalCount ? this.state.totalCount : 0
                      } entries`}
                    </div>
                  </div>
                  <div className='col-md-7 col-sm-12'>
                    <div
                      className='dataTables_paginate paging_simple_numbers'
                      id='table1_paginate'
                    >
                      {this.state.pagination && (
                        <nav style={{ float: 'right' }}>
                          <Pagination
                            activePage={this.state.pagination.currentPage}
                            itemsCountPerPage={this.state.pagination.pageSize}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={
                              this.state.pagination.pageRangeDisplayed
                            }
                            onChange={this.handlePageChange}
                            prevPageText='Previous'
                            nextPageText='Next'
                            itemClass='paginate_button'
                          />
                        </nav>
                      )}
                    </div>
                    {this.state.openRefundPopup && (
                      <RefundModal
                        openRefundPopup={this.state.openRefundPopup}
                        closeRefundPopup={this.closeRefundPopup}
                        order={this.state.refundOrder}
                        timeZone={this.props.timeZone}
                        accessToken={this.props.accessToken}
                        currency={this.props.currency}
                        reasons={this.props.reasons}
                      />
                    )}
                    {this.state.renderInvoiceModal && (
                      <InvoiceModal
                        renderInvoiceModal={this.state.renderInvoiceModal}
                        closeInvoiceModal={this.closeInvoiceModal}
                        order={this.state.invoiceOrder}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RealtimeOrdersList.propTypes = {
  orderList: PropTypes.any,
  users: PropTypes.any,
  allOrderHistory: PropTypes.any,
  orderSetup: PropTypes.any,
  openModal: PropTypes.any,
};

export default RealtimeOrdersList;
